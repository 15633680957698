import { useState, useEffect } from "react";
import FormInfo from "../form/FormInfo";
import Button from "../attribute/Button";
import { useHistory } from "react-router-dom";
import api from "../../api";
import Loading from "../loading/Loading";
import { ageCalculator, getAge, UploadFileAws } from "../functions/Functions";
import { toast } from "aws-amplify";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

const Initial = ({
  nav,
  form,
  setField,
  setForm,
  onClickSave,
  saved = false,
  isNurse,
}) => {
  const router = useHistory();
  const [loading, setLoading] = useState(false);

  // console.log("saved", saved);

  // const history = router

  console.log("initial form", form);

  useEffect(() => {
    LoadPrv();
  }, [form?.hn]);

  const LoadPrv = async () => {
    setLoading(true);
    try {
      if (form?.hn?.length === 8) {
        const { data } = await api.get(
          `/tasks?hn=${form?.hn}&sort=createdAt:-1`,
          {
            params: {
              user_department: "All",
            },
          }
        );
        // console.log("data", data);
        const zeroData = data?.tasks[0];
        // console.log("zeroData", zeroData);
        setForm({
          ...form,
          full_name: zeroData.full_name,
          last_name: zeroData.last_name,
          dob: zeroData?.dob,
        });
      } else {
        setForm({});
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  return (
    <div>
      <ToastContainer />
      <FormInfo nav={nav} form={form} setField={setField} />

      {isNurse ? null : (
        <Button
          disabled={saved}
          onClickSave={
            () => onClickSave("INITIAL DATABASE", form?.status !== "waiting") //
          }
          onClickCancel={() => {
            setForm({});
            router.push("/");
          }}
        />
      )}
    </div>
  );
};
export default Initial;
