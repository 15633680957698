import React from "react";
import FunctionCheckType from "./Report.FunctionCheckType";
import { calculateAverage } from "../utils/useFunction";
import _ from "lodash";

const ReportCountAvgTimeAt = ({ list, setValue, year, type, setStatData }) => {
  const result = list?.reduce((acc, row) => {
    const attendingNameList =
      row?.attempts?.map((row) => row?.attending_name?.label) || [];
    return [...acc, ...attendingNameList];
  }, []);

  const filteredResult = result.filter((name) => name !== "ทดสอบ");
  const doctorList = _.uniq(_.compact(filteredResult));
  const doctorListObj = doctorList?.map((row) => ({
    value: row,
    label: row,
  }));

  let doctorCounts = doctorListObj.reduce((acc, doctor) => {
    acc[doctor.value] = {
      doctor: doctor.value,
      checkTime: [],
    };
    return acc;
  }, {});

  const TotalTime = (room_in, room_out) => {
    const diff_time =
      room_in &&
      room_out &&
      Math.abs(
        new Date().setHours(room_in?.split(":")[0], room_in?.split(":")[1]) -
          new Date().setHours(room_out?.split(":")[0], room_out?.split(":")[1])
      ) / 60000;
    return typeof diff_time === "undefined" ? 0 : diff_time;
  };

  if (list?.length > 0) {
    for (let row of list) {
      if (row?.final_date_success) {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);
        const useType = FunctionCheckType(
          row?.catheter?.label,
          row?.other_catheter_name,
          row?.check_catheter
        );
        let attempts = row?.attempts;
        if (year === "ALL" || year === useYear) {
          if (type === "NONE" || type === useType) {
            for (const row of attempts) {
              const doctorValue = row?.attending_name?.label;
              if (doctorCounts.hasOwnProperty(doctorValue)) {
                if (row?.room_in && row?.out_of_room) {
                  const Time = TotalTime(row?.room_in, row?.out_of_room);
                  doctorCounts[doctorValue].checkTime.push(Time);
                }
              }
            }
          }
        }
      }
    }
    const result2 = Object.values(doctorCounts);
    setValue(result2);
  }
};

export default ReportCountAvgTimeAt;
