import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountReason = ({ list, setValue, year, month, total }) => {
  // console.log("ReportCountReason", list);
  let No_loanger_needed = 0;
  let Death = 0;
  let CLABSI = 0;
  let Mechanical_problems = 0;
  let Self_dislodged = 0;
  // let Dislodged_by_others = 0;
  let Unresolved_occlusion = 0;
  let Exit_site_infection = 0;
  let other_reasons_for_discontinuation = 0;
  let Unintended_position = 0;

  const formattedMonth = month.length === 1 ? `0${month}` : month;

  if (list?.length > 0) {
    if (formattedMonth !== "ALL") {
      list = list.filter((row) => {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);
        const useMonth = thisYear.substring(5, 7);
        return useYear === year && useMonth === formattedMonth;
      });
    }

    for (let row of list) {
      if (
        row?.final_date_success !== "" &&
        FunctionCheckType(
          row?.catheter?.value,
          row?.other_catheter_name,
          row?.check_catheter
        ) === "PICC"
        // row?.status === "deceased"
      ) {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);

        console.log(`total: `, total);

        let follows = row?.follows;
        for (const row of follows) {
          if (year === "ALL") {
            if (row?.reasons_for_discontinuation) {
              if (
                row?.reasons_for_discontinuation?.value === "No loanger needed"
              ) {
                No_loanger_needed++;
              }
              if (row?.reasons_for_discontinuation?.value === "Death") {
                Death++;
              }
              if (row?.reasons_for_discontinuation?.value === "CLABSI") {
                CLABSI++;
              }
              if (
                row?.reasons_for_discontinuation?.value ===
                "Mechanical problems"
              ) {
                Mechanical_problems++;
              }
              if (
                row?.reasons_for_discontinuation?.value === "Self dislodged" ||
                row?.reasons_for_discontinuation?.value ===
                  "Dislodged by others"
              ) {
                Self_dislodged++;
              }
              // if (
              //   row?.reasons_for_discontinuation?.value ===
              //   "Dislodged by others"
              // ) {
              //   Dislodged_by_others++;
              // }
              if (
                row?.reasons_for_discontinuation?.value ===
                "Unresolved occlusion"
              ) {
                Unresolved_occlusion++;
              }
              if (
                row?.reasons_for_discontinuation?.value ===
                "Exit site infection"
              ) {
                Exit_site_infection++;
              }
              if (
                row?.reasons_for_discontinuation?.value ===
                "other_reasons_for_discontinuation"
              ) {
                other_reasons_for_discontinuation++;
              }

              if (
                row?.reasons_for_discontinuation?.value ===
                "Unintended position"
              ) {
                Unintended_position++;
              }
            }
          }
          if (year === useYear) {
            if (row?.reasons_for_discontinuation) {
              if (
                row?.reasons_for_discontinuation?.value === "No loanger needed"
              ) {
                No_loanger_needed++;
              }
              if (row?.reasons_for_discontinuation?.value === "Death") {
                Death++;
              }
              if (row?.reasons_for_discontinuation?.value === "CLABSI") {
                CLABSI++;
              }
              if (
                row?.reasons_for_discontinuation?.value ===
                "Mechanical problems"
              ) {
                Mechanical_problems++;
              }
              if (
                row?.reasons_for_discontinuation?.value === "Self dislodged" ||
                row?.reasons_for_discontinuation?.value ===
                  "Dislodged by others"
              ) {
                Self_dislodged++;
              }
              // if (
              //   row?.reasons_for_discontinuation?.value === "Self dislodged"
              // ) {
              //   Self_dislodged++;
              // }
              // if (
              //   row?.reasons_for_discontinuation?.value ===
              //   "Dislodged by others"
              // ) {
              //   Dislodged_by_others++;
              // }
              if (
                row?.reasons_for_discontinuation?.value ===
                "Unresolved occlusion"
              ) {
                Unresolved_occlusion++;
              }
              if (
                row?.reasons_for_discontinuation?.value ===
                "Exit site infection"
              ) {
                Exit_site_infection++;
              }
              if (
                row?.reasons_for_discontinuation?.value ===
                "other_reasons_for_discontinuation"
              ) {
                other_reasons_for_discontinuation++;
              }
              if (
                row?.reasons_for_discontinuation?.value ===
                "Unintended position"
              ) {
                Unintended_position++;
              }
            }
          }
          // if (year === "2023" && useYear === "2023") {
          //   if (row?.reasons_for_discontinuation) {
          //     if (
          //       row?.reasons_for_discontinuation?.value === "No loanger needed"
          //     ) {
          //       No_loanger_needed++;
          //     }
          //     if (row?.reasons_for_discontinuation?.value === "Death") {
          //       Death++;
          //     }
          //     if (row?.reasons_for_discontinuation?.value === "CLABSI") {
          //       CLABSI++;
          //     }
          //     if (
          //       row?.reasons_for_discontinuation?.value ===
          //       "Mechanical problems"
          //     ) {
          //       Mechanical_problems++;
          //     }
          //     if (
          //       row?.reasons_for_discontinuation?.value === "Self dislodged" ||
          //       row?.reasons_for_discontinuation?.value ===
          //         "Dislodged by others"
          //     ) {
          //       Self_dislodged++;
          //     }
          //     // if (
          //     //   row?.reasons_for_discontinuation?.value === "Self dislodged"
          //     // ) {
          //     //   Self_dislodged++;
          //     // }

          //     // if (
          //     //   row?.reasons_for_discontinuation?.value ===
          //     //   "Dislodged by others"
          //     // ) {
          //     //   Dislodged_by_others++;
          //     // }
          //     if (
          //       row?.reasons_for_discontinuation?.value ===
          //       "Unresolved occlusion"
          //     ) {
          //       Unresolved_occlusion++;
          //     }
          //     if (
          //       row?.reasons_for_discontinuation?.value ===
          //       "Exit site infection"
          //     ) {
          //       Exit_site_infection++;
          //     }
          //     if (
          //       row?.reasons_for_discontinuation?.value ===
          //       "other_reasons_for_discontinuation"
          //     ) {
          //       other_reasons_for_discontinuation++;
          //     }
          //     if (
          //       row?.reasons_for_discontinuation?.value ===
          //       "Unintended position"
          //     ) {
          //       Unintended_position++;
          //     }
          //   }
          // }
        }
      }
    }
    setValue({
      No_loanger_needed: No_loanger_needed,
      Death: Death,
      CLABSI: CLABSI,
      Mechanical_problems: Mechanical_problems,
      Self_dislodged: Self_dislodged,
      // Dislodged_by_others: Dislodged_by_others,
      Unresolved_occlusion: Unresolved_occlusion,
      Exit_site_infection: Exit_site_infection,
      other_reasons_for_discontinuation: other_reasons_for_discontinuation,
      Unintended_position: Unintended_position,
      total: total,
    });
  }
};

export default ReportCountReason;
