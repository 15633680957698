import React from "react";
import { useHistory } from "react-router-dom";

const HopperMain = () => {
  const router = useHistory();

  React.useEffect(() => {
    const hopperId = router.location.pathname.split("/")[2];
    router.push(`/info/${hopperId} `);
  }, [router]);
  return <React.Fragment />;
};

export default HopperMain;
