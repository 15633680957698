import Select from "react-select";

const DropdownReport = ({
  title,
  isMulti = false,
  disabled = false,
  className = " w-40",
  value,
  placeholder,
  name,
  setField,

  options = [{ label: "TEST ", value: "TEST" }],
  classNameTitle,
  setValue,
  setHomeStyle,
  defaultValue,
  sideFunction = null,
}) => {
  const ckeck_other_true =
    String(value?.value ? value?.value : value)?.slice(0, 5) === "other"
      ? true
      : false;

  const onChange = (e) => {
    if (sideFunction) {
      sideFunction(e);
    }

    if (setField) {
      setField(name)(e);
    } else if (setValue) {
      setValue(e);
    } else {
      return;
    }
  };

  return (
    <div className={className}>
      <div className={classNameTitle}>{title}</div>
      <div>
        <Select
          className=""
          defaultValue={defaultValue}
          isMulti={isMulti}
          isSearchable={true}
          isDisabled={disabled}
          options={options}
          value={
            options &&
            options[
              options?.length > 0
                ? options?.findIndex((option) =>
                    option?.value
                      ? option?.value === value?.value
                      : option === value
                  )
                : 0
            ]
          }
          onChange={onChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
export default DropdownReport;
