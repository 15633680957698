import ExcelExport from "export-xlsx";

export const ExcelGender = async (inputdata, year, type, setLoading) => {
  console.log("year", year);
  console.log("type", type);

  // const inputdata = { data: [{ test: 5 }] };
  const SETTINGS_FOR_EXPORT_GENDER = {
    // Table settings
    fileName: "ALiST_REPORT_GENDER",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `สรุปจำนวนผู้ป่วยเพศชายและเพศหญิงแยกตามปี ${year?.label} ตามประเภทสาย ${type?.label} `,
                key: "header_01",

                style: { fill: { fgColor: { rgb: "FF0000" } } },
              },
            ],
            headerDefinition: [
              {
                name: "ชาย",
                key: "men",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "หญิง",
                key: "women",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "รวม",
                key: "sum",
                groupKey: "header_01",
                width: 20,
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT_GENDER, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
// export const ExportGender = async (list) => {
//   // if (years === "") {
//   //   alert("กรุณาเลือกปี");
//   // }
//   try {
//     const tasks = list;
//     let newDatas = [];

//     for (let task of tasks) {
//       if (
//         task?.createdAt > `2021-12-31T23:59:59.999Z` &&
//         task?.createdAt <= `2022-12-31T23:59:59.999Z`
//       ) {
//         task["HN_2022_PICC_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.hn
//             : undefined;
//         task["NAME_2022_PICC_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.full_name
//             : undefined;
//         task["LAST_NAME_2022_PICC_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.last_name
//             : undefined;
//         task["GENDER_2022_PICC_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.gender
//             : undefined;
//         task["CATHETER_2022_PICC_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.other_catheter_name
//               ? task?.other_catheter_name
//               : task?.catheter?.label
//               ? task?.catheter?.label
//               : "-"
//             : undefined;
//         //
//         //
//         //
//         task["HN_2022_PICC_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.hn
//             : undefined;
//         task["NAME_2022_PICC_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.full_name
//             : undefined;
//         task["LAST_NAME_2022_PICC_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.last_name
//             : undefined;
//         task["GENDER_2022_PICC_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.gender
//             : undefined;
//         task["CATHETER_2022_PICC_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.other_catheter_name
//               ? task?.other_catheter_name
//               : task?.catheter?.label
//               ? task?.catheter?.label
//               : "-"
//             : undefined;
//         //
//         //
//         //
//         task["HN_2022_CENTRAL_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.hn
//             : undefined;
//         task["NAME_2022_CENTRAL_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.full_name
//             : undefined;
//         task["LAST_NAME_2022_CENTRAL_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.last_name
//             : undefined;
//         task["GENDER_2022_CENTRAL_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.gender
//             : undefined;
//         task["CATHETER_2022_CENTRAL_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.other_catheter_name
//               ? task?.other_catheter_name
//               : task?.catheter?.label
//               ? task?.catheter?.label
//               : "-"
//             : undefined;
//         //
//         //
//         //
//         task["HN_2022_CENTRAL_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.hn
//             : undefined;
//         task["NAME_2022_CENTRAL_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.full_name
//             : undefined;
//         task["LAST_NAME_2022_CENTRAL_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.last_name
//             : undefined;
//         task["GENDER_2022_CENTRAL_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.gender
//             : undefined;
//         task["CATHETER_2022_CENTRAL_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.other_catheter_name
//               ? task?.other_catheter_name
//               : task?.catheter?.label
//               ? task?.catheter?.label
//               : "-"
//             : undefined;

//         newDatas.push(task);
//       } else if (
//         task?.createdAt > `2022-12-31T23:59:59.999Z` &&
//         task?.createdAt <= `2023-12-31T23:59:59.999Z`
//       ) {
//         task["HN_2023_PICC_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.hn
//             : undefined;
//         task["NAME_2023_PICC_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.full_name
//             : undefined;
//         task["LAST_NAME_2023_PICC_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.last_name
//             : undefined;
//         task["GENDER_2023_PICC_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.gender
//             : undefined;
//         task["CATHETER_2023_PICC_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.other_catheter_name
//               ? task?.other_catheter_name
//               : task?.catheter?.label
//               ? task?.catheter?.label
//               : "-"
//             : undefined;
//         //
//         //
//         //
//         task["HN_2023_PICC_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.hn
//             : undefined;
//         task["NAME_2023_PICC_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.full_name
//             : undefined;
//         task["LAST_NAME_2023_PICC_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.last_name
//             : undefined;
//         task["GENDER_2023_PICC_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.gender
//             : undefined;
//         task["CATHETER_2023_PICC_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "PICC"
//             ? task?.other_catheter_name
//               ? task?.other_catheter_name
//               : task?.catheter?.label
//               ? task?.catheter?.label
//               : "-"
//             : undefined;
//         //
//         //
//         //
//         task["HN_2023_CENTRAL_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.hn
//             : undefined;
//         task["NAME_2023_CENTRAL_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.full_name
//             : undefined;
//         task["LAST_NAME_2023_CENTRAL_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.last_name
//             : undefined;
//         task["GENDER_2023_CENTRAL_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.gender
//             : undefined;
//         task["CATHETER_2023_CENTRAL_MEN"] =
//           task?.gender === "ชาย" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.other_catheter_name
//               ? task?.other_catheter_name
//               : task?.catheter?.label
//               ? task?.catheter?.label
//               : "-"
//             : undefined;
//         //
//         //
//         //
//         task["HN_2023_CENTRAL_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.hn
//             : undefined;
//         task["NAME_2023_CENTRAL_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.full_name
//             : undefined;
//         task["LAST_NAME_2023_CENTRAL_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.last_name
//             : undefined;
//         task["GENDER_2023_CENTRAL_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.gender
//             : undefined;
//         task["CATHETER_2023_CENTRAL_WOMEN"] =
//           task?.gender === "หญิง" &&
//           FunctionCheckType(
//             task?.catheter?.label,
//             task?.other_catheter_name
//           ) === "CENTRAL_LINE"
//             ? task?.other_catheter_name
//               ? task?.other_catheter_name
//               : task?.catheter?.label
//               ? task?.catheter?.label
//               : "-"
//             : undefined;

//         newDatas.push(task);
//       }

//       // }
//     }
//     const formatData = [{ data: newDatas }];
//     const excelExport = new ExcelExport();
//     await excelExport.downloadExcel(SETTINGS_FOR_EXPORT_GENDER, formatData);
//   } catch (error) {
//     console.log("ERROR TO LOAD EXCEL :", error);
//   }

//   return;
// };
