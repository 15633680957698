const NavClick = ({ className, navName, nav, setNav, undo, setUndo }) => {
  const ButtonNavStyle = " cursor-pointer  p-2  md:w-44 lg:w-52 xl:w-48";

  return (
    <div>
      <div className={className}></div>
      {/* <div className={` topnav grid  md:grid-cols-${navName?.length}`}> */}
      <div className={`topnav`}>
        {navName?.map((row) => {
          return (
            <a
              className={
                nav === row
                  ? `${
                      " active focus:outline-none font-bold  " + ButtonNavStyle
                    }`
                  : ButtonNavStyle
              }
              onClick={() => {
                if (window.location.pathname === "/") {
                  sessionStorage.setItem("last-tab", row);
                }

                setNav(row);
                if (setUndo) {
                  setUndo((prev) => {
                    let temp = [...prev];
                    temp.push(row);
                    return [...temp];
                  });
                }
              }}
            >
              {row}
            </a>
          );
        })}
      </div>
    </div>
  );
};
export default NavClick;
