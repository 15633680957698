import Select from "react-select";
import { titleStyle } from "../../hooks/useClassName";
import TextField from "./TextField";

const Dropdown = ({
  title,
  isMulti = false,
  disabled = false,
  className = "w-80 mx-10",
  value,
  placeholder,
  name,
  setField,
  other_name,
  other_value,
  options = [{ label: "TEST ", value: "TEST" }],
  classNameTitle,
  setValue = false,
  setOtherValue,
  setCheckChange,
  checkChange = false,
  disableOther = false,
  setStateObject = false,
  classNameOther = "text-base mx-0 md:mx-0 lg:mx-0 xl:mx-0 w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full",
  other_place,
}) => {
  const ckeck_other_true =
    String(value?.value ? value?.value : value)?.slice(0, 5) === "other"
      ? true
      : false;

  // console.log("ckeck_other_true", ckeck_other_true);

  const onChange = (e) => {
    if (setCheckChange) {
      setCheckChange(!checkChange);
    }

    if (setField) {
      setField(name)(e);
    }
    if (setValue && setStateObject) {
      setValue((prev) => {
        let temp = { ...prev };
        temp[name] = e;
        return { ...temp };
      });
    } else if (setValue) {
      setValue(e);
    } else {
      return;
    }
  };

  return (
    <div className={"mx-10 w-60"}>
      <div className={classNameTitle ? classNameTitle : titleStyle}>
        {title}
      </div>
      <div>
        <Select
          className="10"
          isMulti={isMulti}
          isSearchable={true}
          isDisabled={disabled}
          options={options}
          value={
            options &&
            options[
              options?.length > 0
                ? options?.findIndex((option) =>
                    option?.value
                      ? option?.value === value?.value
                      : option === value
                  )
                : 0
            ]
          }
          onChange={onChange}
          placeholder={placeholder}
        />
      </div>
      {!disableOther && ckeck_other_true && (
        <TextField
          className={classNameOther}
          name={other_name}
          setField={setField}
          value={other_value}
          setValue={setOtherValue ? setOtherValue : setValue}
          setStateObject={setStateObject}
          placeholder={other_place}
        />
      )}
    </div>
  );
};
export default Dropdown;
