import { titleStyle } from "../../hooks/useClassName";

const CheckBox = ({
  title,
  name,
  value,
  setField,
  primaryField,
  setFieldMulti,
  index,
  classNameTitle,

  label,
  disabled = false,
  fontLabelWeight = "font-semibold",

  className = "flex justify-start items-center  space-x-2 rounded-xl my-4 mx-11",
}) => {
  const onChangeCheckbox = (e) => {
    const value = e.target.checked;
    setField(name)(value);

    if (primaryField) {
      return setFieldMulti(name, primaryField, index)(value);
    }
  };
  return (
    <div>
      <div className={classNameTitle ? classNameTitle : titleStyle}>
        {title}
      </div>
      <div className={className}>
        <input
          type="checkbox"
          name={name}
          checked={value}
          onChange={onChangeCheckbox}
          disabled={disabled}
        />
        <div
          className={
            "md:text-lg text-base text-textblue mx-10" + fontLabelWeight
          }
        >
          {label}
        </div>
      </div>
    </div>
  );
};
export default CheckBox;
