import { ageDayCalculator } from "../../../components/functions/Functions";
import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountVisit = ({ list, setValue, year, taskList }) => {
  //a หน้าคือVist
  //a หลังคือphone

  let aa = 0; // 01
  let ab = 0; // 02
  let ac = 0; // 03
  let ad = 0; // 04
  let ae = 0; // 05
  let af = 0; // 06
  let ag = 0; // 07
  let ah = 0; // 08
  let ai = 0; // 09
  let aj = 0; // 10
  let ak = 0; // 11
  let al = 0; // 12

  let ba = 0; // 01
  let bb = 0; // 02
  let bc = 0; // 03
  let bd = 0; // 04
  let be = 0; // 05
  let bf = 0; // 06
  let bg = 0; // 07
  let bh = 0; // 08
  let bi = 0; // 09
  let bj = 0; // 10
  let bk = 0; // 11
  let bl = 0; // 12

  let level01 = 0;
  let level02 = 0;
  let level03 = 0;
  let level04 = 0;

  if (list?.length > 0) {
    for (let row of list) {
      const month = row?.date_follow ? row?.date_follow : "";
      const thisMonth = String(month);
      const useMont = thisMonth.substring(5, 7);
      const thisYear = row?.date_follow ? row?.date_follow : "";
      const useYear = thisYear.substring(0, 4);

      if (year === "ALL") {
        if (useMont === "01" && row?.by_actial_visit) {
          aa++;
        }
        if (useMont === "02" && row?.by_actial_visit) {
          ab++;
        }
        if (useMont === "03" && row?.by_actial_visit) {
          ac++;
        }
        if (useMont === "04" && row?.by_actial_visit) {
          ad++;
        }
        if (useMont === "05" && row?.by_actial_visit) {
          ae++;
        }
        if (useMont === "06" && row?.by_actial_visit) {
          af++;
        }
        if (useMont === "07" && row?.by_actial_visit) {
          ag++;
        }
        if (useMont === "08" && row?.by_actial_visit) {
          ah++;
        }
        if (useMont === "09" && row?.by_actial_visit) {
          ai++;
        }
        if (useMont === "10" && row?.by_actial_visit) {
          aj++;
        }
        if (useMont === "11" && row?.by_actial_visit) {
          ak++;
        }
        if (useMont === "12" && row?.by_actial_visit) {
          al++;
        }
        //
        if (useMont === "01" && row?.by_phone) {
          ba++;
        }
        if (useMont === "02" && row?.by_phone) {
          bb++;
        }
        if (useMont === "03" && row?.by_phone) {
          bc++;
        }
        if (useMont === "04" && row?.by_phone) {
          bd++;
        }
        if (useMont === "05" && row?.by_phone) {
          be++;
        }
        if (useMont === "06" && row?.by_phone) {
          bf++;
        }
        if (useMont === "07" && row?.by_phone) {
          bg++;
        }
        if (useMont === "08" && row?.by_phone) {
          bh++;
        }
        if (useMont === "09" && row?.by_phone) {
          bi++;
        }
        if (useMont === "10" && row?.by_phone) {
          bj++;
        }
        if (useMont === "11" && row?.by_phone) {
          bk++;
        }
        if (useMont === "12" && row?.by_phone) {
          bl++;
        }
      }
      if (year === useYear) {
        if (useMont === "01" && row?.by_actial_visit) {
          aa++;
        }
        if (useMont === "02" && row?.by_actial_visit) {
          ab++;
        }
        if (useMont === "03" && row?.by_actial_visit) {
          ac++;
        }
        if (useMont === "04" && row?.by_actial_visit) {
          ad++;
        }
        if (useMont === "05" && row?.by_actial_visit) {
          ae++;
        }
        if (useMont === "06" && row?.by_actial_visit) {
          af++;
        }
        if (useMont === "07" && row?.by_actial_visit) {
          ag++;
        }
        if (useMont === "08" && row?.by_actial_visit) {
          ah++;
        }
        if (useMont === "09" && row?.by_actial_visit) {
          ai++;
        }
        if (useMont === "10" && row?.by_actial_visit) {
          aj++;
        }
        if (useMont === "11" && row?.by_actial_visit) {
          ak++;
        }
        if (useMont === "12" && row?.by_actial_visit) {
          al++;
        }
        //
        if (useMont === "01" && row?.by_phone) {
          ba++;
        }
        if (useMont === "02" && row?.by_phone) {
          bb++;
        }
        if (useMont === "03" && row?.by_phone) {
          bc++;
        }
        if (useMont === "04" && row?.by_phone) {
          bd++;
        }
        if (useMont === "05" && row?.by_phone) {
          be++;
        }
        if (useMont === "06" && row?.by_phone) {
          bf++;
        }
        if (useMont === "07" && row?.by_phone) {
          bg++;
        }
        if (useMont === "08" && row?.by_phone) {
          bh++;
        }
        if (useMont === "09" && row?.by_phone) {
          bi++;
        }
        if (useMont === "10" && row?.by_phone) {
          bj++;
        }
        if (useMont === "11" && row?.by_phone) {
          bk++;
        }
        if (useMont === "12" && row?.by_phone) {
          bl++;
        }
      }
      // if (year === "2023" && useYear === "2023") {
      //   if (useMont === "01" && row?.by_actial_visit) {
      //     aa++;
      //   }
      //   if (useMont === "02" && row?.by_actial_visit) {
      //     ab++;
      //   }
      //   if (useMont === "03" && row?.by_actial_visit) {
      //     ac++;
      //   }
      //   if (useMont === "04" && row?.by_actial_visit) {
      //     ad++;
      //   }
      //   if (useMont === "05" && row?.by_actial_visit) {
      //     ae++;
      //   }
      //   if (useMont === "06" && row?.by_actial_visit) {
      //     af++;
      //   }
      //   if (useMont === "07" && row?.by_actial_visit) {
      //     ag++;
      //   }
      //   if (useMont === "08" && row?.by_actial_visit) {
      //     ah++;
      //   }
      //   if (useMont === "09" && row?.by_actial_visit) {
      //     ai++;
      //   }
      //   if (useMont === "10" && row?.by_actial_visit) {
      //     aj++;
      //   }
      //   if (useMont === "11" && row?.by_actial_visit) {
      //     ak++;
      //   }
      //   if (useMont === "12" && row?.by_actial_visit) {
      //     al++;
      //   }
      //   //
      //   if (useMont === "01" && row?.by_phone) {
      //     ba++;
      //   }
      //   if (useMont === "02" && row?.by_phone) {
      //     bb++;
      //   }
      //   if (useMont === "03" && row?.by_phone) {
      //     bc++;
      //   }
      //   if (useMont === "04" && row?.by_phone) {
      //     bd++;
      //   }
      //   if (useMont === "05" && row?.by_phone) {
      //     be++;
      //   }
      //   if (useMont === "06" && row?.by_phone) {
      //     bf++;
      //   }
      //   if (useMont === "07" && row?.by_phone) {
      //     bg++;
      //   }
      //   if (useMont === "08" && row?.by_phone) {
      //     bh++;
      //   }
      //   if (useMont === "09" && row?.by_phone) {
      //     bi++;
      //   }
      //   if (useMont === "10" && row?.by_phone) {
      //     bj++;
      //   }
      //   if (useMont === "11" && row?.by_phone) {
      //     bk++;
      //   }
      //   if (useMont === "12" && row?.by_phone) {
      //     bl++;
      //   }
      // }
    }

    for (const taskItem of taskList) {
      const date_final = taskItem?.final_date_success
        ? taskItem?.final_date_success
        : "";
      //
      const totalDay = ageDayCalculator(new Date(date_final), new Date());
      const follows = taskItem?.follows?.length;
      //
      const averageDayVisit = (totalday, totalFollow) => {
        const avgDay = Number(totalday) / Number(totalFollow);
        const useAvgDay = Math.round(avgDay);
        return Number(useAvgDay);
      };
      //
      const thisAvg = averageDayVisit(Number(totalDay), Number(follows));
      //
      const thisYear = date_final ? date_final : "";
      const useYear = thisYear.substring(0, 4);
      if (
        totalDay < 90 &&
        FunctionCheckType(
          taskItem?.catheter?.label,
          taskItem?.other_catheter_name,
          taskItem?.check_catheter
        ) === "PICC" &&
        year === "ALL"
      ) {
        if (thisAvg === 0 || thisAvg < 7) {
          level01++;
        } else if (thisAvg < 8 || thisAvg < 14) {
          level02++;
        } else if (thisAvg < 15 || thisAvg < 21) {
          level03++;
        } else if (thisAvg > 21) {
          level04++;
        }
      }
      if (
        totalDay < 90 &&
        FunctionCheckType(
          taskItem?.catheter?.label,
          taskItem?.other_catheter_name,
          taskItem?.check_catheter
        ) === "PICC" &&
        year === useYear
      ) {
        if (thisAvg === 0 || thisAvg < 7) {
          level01++;
        } else if (thisAvg < 8 || thisAvg < 14) {
          level02++;
        } else if (thisAvg < 15 || thisAvg < 21) {
          level03++;
        } else if (thisAvg > 21) {
          level04++;
        }
      }
      // if (
      //   totalDay < 90 &&
      //   FunctionCheckType(
      //     taskItem?.catheter?.label,
      //     taskItem?.other_catheter_name,
      //     taskItem?.check_catheter
      //   ) === "PICC" &&
      //   year === "2023" &&
      //   useYear === "2023"
      // ) {
      //   if (thisAvg === 0 || thisAvg < 7) {
      //     level01++;
      //   } else if (thisAvg < 8 || thisAvg < 14) {
      //     level02++;
      //   } else if (thisAvg < 15 || thisAvg < 21) {
      //     level03++;
      //   } else if (thisAvg > 21) {
      //     level04++;
      //   }
      // }
    }
    setValue({
      level01: level01,
      level02: level02,
      level03: level03,
      level04: level04,
      all_level: level01 + level02 + level03 + level04,
      jan: {
        visit: aa,
        phone: ba,
      },
      feb: {
        visit: ab,
        phone: bb,
      },
      mar: {
        visit: ac,
        phone: bc,
      },
      apr: {
        visit: ad,
        phone: bd,
      },
      may: {
        visit: ae,
        phone: be,
      },
      jun: {
        visit: af,
        phone: bf,
      },
      jul: {
        visit: ag,
        phone: bg,
      },
      aug: {
        visit: ah,
        phone: bh,
      },
      sep: {
        visit: ai,
        phone: bi,
      },
      oct: {
        visit: aj,
        phone: bj,
      },
      nov: {
        visit: ak,
        phone: bk,
      },
      dec: {
        visit: al,
        phone: bl,
      },
    });
  }
};
export default ReportCountVisit;
