import DashbordAll from "../../components/infomation/DashbordAll";

const DetailMain = () => {
  return (
    <div className="px-4 md:px-8 lg:px-16 ">
      <div>
        <DashbordAll />
      </div>{" "}
    </div>
  );
};
export default DetailMain;
