import ExcelExport from "export-xlsx";

export const ExceltPatient = async (inputdata, year, type, setLoading) => {
  // const inputdata = { data: [{ test: 5 }] };
  const SETTINGS_FOR_EXPORT = {
    // Table settings
    fileName: "ALiST_REPORT_PATIENT_TYPE",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `จำนวนผู้ป่วยตามประเภทผู้ป่วยแยกตามปี ของปี  ${year?.label} ตามประเภทสาย ${type?.label} `,
                key: "header_01",
                style: { fill: { fgColor: { rgb: "FF0000" } } },
              },
            ],
            headerDefinition: [
              {
                name: "อายุรกรรม",
                key: "type1",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "ศัลยกรรม",
                key: "type2",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "สูตินรีเวช",
                key: "type3",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "กุมารเวชศาสตร์",
                key: "type4",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "หออภิบาล",
                key: "type5",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "ผู้ป่วยนอก",
                key: "type6",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "อื่นๆ",
                key: "type7",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "รวม",
                key: "sum",
                groupKey: "header_01",
                width: 10,
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
