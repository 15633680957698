import { useState } from "react";
import { titleStyle } from "../../hooks/useClassName";
import { AddUrlToFile, getPathUrl, resizeFile } from "../functions/Functions";

const UploadImage = ({
  disabled = false,
  title,
  setField,
  value,
  name,
  classNameTitle,
}) => {
  // const onClickChangeUpdateCover = async ({ target: { files } }) => {
  //   setNewCover(null);
  //   setHaveSrc(false);
  //   const file = await files[0];
  //   setFile(file);
  //   if (file?.size < 500000) {
  //     let imageDataUrl = await readFile(file);
  //     setImageSrc(imageDataUrl);
  //   } else {
  //     const format_file = await resizeFile(file);
  //     let imageDataUrl = await readFile(format_file);
  //     setImageSrc(imageDataUrl);
  //   }
  // };
  const onClickUpLoadImage = async ({ target: { files } }) => {
    if (files?.length > 0) {
      const file = await files[0];
      // console.log("file : ", file);
      if (file?.size > 300000) {
        let newFile = await resizeFile(file);
        // console.log("newFile ; ", newFile);
        setField(name)(newFile);
      } else {
        setField(name)(file);
      }
    } else {
      return;
    }
  };

  const imageStyle = "mx-auto p-4";

  return (
    <div>
      <div className={classNameTitle ? classNameTitle : titleStyle}>
        {title}
      </div>
      <div
        className={value ? "my-4  border border-dashed  border-blue-500" : ""}
      >
        {value?.size > 0 ? (
          <img className={imageStyle} src={AddUrlToFile(value)} />
        ) : (
          [value ? <img className={imageStyle} src={getPathUrl(value)} /> : ""]
        )}
      </div>
      <div className=" bg-blue-600 py-3 w-60 md:my-2 my-4 rounded-md cursor-pointer mx-10">
        <label for="button-file">
          <div className=" flex justify-center items-center gap-2 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clip-rule="evenodd"
              />
            </svg>
            <div className=" cursor-pointe text-center text-white">
              Upload Image
            </div>
            <input
              disabled={disabled}
              accept="image/jpeg,image/gif,image/png"
              className="hidden"
              id="button-file"
              type="file"
              onChange={onClickUpLoadImage}
            />
          </div>
        </label>
      </div>
    </div>
  );
};
export default UploadImage;
