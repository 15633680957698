import ExcelExport from "export-xlsx";

export const ExcelWaitCat = async (
  inputdata,
  year,
  type,
  setLoading,
  month
) => {
  // const inputdata = { data: [{ test: 5 }] };
  console.log("inputdata", inputdata);
  const SETTINGS_FOR_EXPORT_WAIT_CAT = {
    // Table settings
    fileName: "ALiST_REPORT_PAPER_TO_CATHETER",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `สรุปจำนวนผู้ป่วยตามระยะเวลาตั้งแต่ที่ได้รับใบปรึกษา จนกระทั่งผู้ป่วยได้รับการใส่สายน้ำเกลือ ของปี  ${year?.label} ของเดือน ${month.label} ตามประเภทสาย ${type?.label} `,
                key: "header_01",

                style: { fill: { fgColor: { rgb: "FF0000" } } },
              },
            ],
            headerDefinition: [
              {
                name: "น้อยกว่า 2 วัน",
                key: "A",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "เปอร์เซ็นต์",
                key: "percentageA",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "3-5 วัน",
                key: "B",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "เปอร์เซ็นต์",
                key: "percentageB",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "6 วันขึ้นไป",
                key: "C",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "เปอร์เซ็นต์",
                key: "percentageC",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "รวม",
                key: "sum",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "เปอร์เซ็นต์",
                key: "All",
                groupKey: "header_01",
                width: 10,
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT_WAIT_CAT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
