import { ageDayCalculator } from "../../../components/functions/Functions";
import FunctionCheckType from "./Report.FunctionCheckType";

const CountSucAttempt = ({ list, setValue, year, type, setStatData }) => {
  let ad_01 = 0;
  let ad_02 = 0;
  let ad_03 = 0;
  let ad_04 = 0;
  //
  let kid_01 = 0;
  let kid_02 = 0;
  let kid_03 = 0;
  let kid_04 = 0;

  if (list?.length > 0) {
    for (let row of list) {
      if (row?.final_date_success) {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);
        const isAdult = Number(
          ageDayCalculator(
            new Date(row?.dob),
            new Date(row?.final_date_success)
          ) >= 6570
        );
        const isKid = Number(
          ageDayCalculator(
            new Date(row?.dob),
            new Date(row?.final_date_success)
          ) < 6570
        );

        if (year === "ALL") {
          if (type === "NONE") {
            if (row?.attempts?.length === 1 && isAdult) {
              ad_01++;
              // console.log("hn", row?.hn);
            }
            if (row?.attempts?.length === 2 && isAdult) {
              ad_02++;
            }
            if (row?.attempts?.length === 3 && isAdult) {
              ad_03++;
            }
            if (row?.attempts?.length === 4 && isAdult) {
              ad_04++;
            }
            if (row?.attempts?.length === 1 && isKid) {
              kid_01++;
            }
            if (row?.attempts?.length === 2 && isKid) {
              kid_02++;
            }
            if (row?.attempts?.length === 3 && isKid) {
              kid_03++;
            }
            if (row?.attempts?.length === 4 && isKid) {
              kid_04++;
            }
          }

          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (row?.attempts?.length === 1 && isAdult) {
              ad_01++;
            }
            if (row?.attempts?.length === 2 && isAdult) {
              ad_02++;
            }
            if (row?.attempts?.length === 3 && isAdult) {
              ad_03++;
            }
            if (row?.attempts?.length === 4 && isAdult) {
              ad_04++;
            }
            if (row?.attempts?.length === 1 && isKid) {
              kid_01++;
            }
            if (row?.attempts?.length === 2 && isKid) {
              kid_02++;
            }
            if (row?.attempts?.length === 3 && isKid) {
              kid_03++;
            }
            if (row?.attempts?.length === 4 && isKid) {
              kid_04++;
            }
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            if (row?.attempts?.length === 1 && isAdult) {
              ad_01++;
            }
            if (row?.attempts?.length === 2 && isAdult) {
              ad_02++;
            }
            if (row?.attempts?.length === 3 && isAdult) {
              ad_03++;
            }
            if (row?.attempts?.length === 4 && isAdult) {
              ad_04++;
            }
            if (row?.attempts?.length === 1 && isKid) {
              kid_01++;
            }
            if (row?.attempts?.length === 2 && isKid) {
              kid_02++;
            }
            if (row?.attempts?.length === 3 && isKid) {
              kid_03++;
            }
            if (row?.attempts?.length === 4 && isKid) {
              kid_04++;
            }
          }
        }
        if (year === useYear) {
          if (type === "NONE") {
            if (row?.attempts?.length === 1 && isAdult) {
              ad_01++;
              // console.log("hn", row?.hn);
            }
            if (row?.attempts?.length === 2 && isAdult) {
              ad_02++;
            }
            if (row?.attempts?.length === 3 && isAdult) {
              ad_03++;
            }
            if (row?.attempts?.length === 4 && isAdult) {
              ad_04++;
            }
            if (row?.attempts?.length === 1 && isKid) {
              kid_01++;
            }
            if (row?.attempts?.length === 2 && isKid) {
              kid_02++;
            }
            if (row?.attempts?.length === 3 && isKid) {
              kid_03++;
            }
            if (row?.attempts?.length === 4 && isKid) {
              kid_04++;
            }
          }

          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (row?.attempts?.length === 1 && isAdult) {
              ad_01++;
            }
            if (row?.attempts?.length === 2 && isAdult) {
              ad_02++;
            }
            if (row?.attempts?.length === 3 && isAdult) {
              ad_03++;
            }
            if (row?.attempts?.length === 4 && isAdult) {
              ad_04++;
            }
            if (row?.attempts?.length === 1 && isKid) {
              kid_01++;
            }
            if (row?.attempts?.length === 2 && isKid) {
              kid_02++;
            }
            if (row?.attempts?.length === 3 && isKid) {
              kid_03++;
            }
            if (row?.attempts?.length === 4 && isKid) {
              kid_04++;
            }
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            if (row?.attempts?.length === 1 && isAdult) {
              ad_01++;
            }
            if (row?.attempts?.length === 2 && isAdult) {
              ad_02++;
            }
            if (row?.attempts?.length === 3 && isAdult) {
              ad_03++;
            }
            if (row?.attempts?.length === 4 && isAdult) {
              ad_04++;
            }
            if (row?.attempts?.length === 1 && isKid) {
              kid_01++;
            }
            if (row?.attempts?.length === 2 && isKid) {
              kid_02++;
            }
            if (row?.attempts?.length === 3 && isKid) {
              kid_03++;
            }
            if (row?.attempts?.length === 4 && isKid) {
              kid_04++;
            }
          }
        }
        // if (year === "2023" && useYear === "2023") {
        //   if (type === "NONE") {
        //     if (row?.attempts?.length === 1 && isAdult) {
        //       ad_01++;
        //       // console.log("hn", row?.hn);
        //     }
        //     if (row?.attempts?.length === 2 && isAdult) {
        //       ad_02++;
        //     }
        //     if (row?.attempts?.length === 3 && isAdult) {
        //       ad_03++;
        //     }
        //     if (row?.attempts?.length === 4 && isAdult) {
        //       ad_04++;
        //     }
        //     if (row?.attempts?.length === 1 && isKid) {
        //       kid_01++;
        //     }
        //     if (row?.attempts?.length === 2 && isKid) {
        //       kid_02++;
        //     }
        //     if (row?.attempts?.length === 3 && isKid) {
        //       kid_03++;
        //     }
        //     if (row?.attempts?.length === 4 && isKid) {
        //       kid_04++;
        //     }
        //   }

        //   if (
        //     type === "PICC" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     if (row?.attempts?.length === 1 && isAdult) {
        //       ad_01++;
        //     }
        //     if (row?.attempts?.length === 2 && isAdult) {
        //       ad_02++;
        //     }
        //     if (row?.attempts?.length === 3 && isAdult) {
        //       ad_03++;
        //     }
        //     if (row?.attempts?.length === 4 && isAdult) {
        //       ad_04++;
        //     }
        //     if (row?.attempts?.length === 1 && isKid) {
        //       kid_01++;
        //     }
        //     if (row?.attempts?.length === 2 && isKid) {
        //       kid_02++;
        //     }
        //     if (row?.attempts?.length === 3 && isKid) {
        //       kid_03++;
        //     }
        //     if (row?.attempts?.length === 4 && isKid) {
        //       kid_04++;
        //     }
        //   }
        //   if (
        //     type === "CENTRAL_LINE" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     if (row?.attempts?.length === 1 && isAdult) {
        //       ad_01++;
        //     }
        //     if (row?.attempts?.length === 2 && isAdult) {
        //       ad_02++;
        //     }
        //     if (row?.attempts?.length === 3 && isAdult) {
        //       ad_03++;
        //     }
        //     if (row?.attempts?.length === 4 && isAdult) {
        //       ad_04++;
        //     }
        //     if (row?.attempts?.length === 1 && isKid) {
        //       kid_01++;
        //     }
        //     if (row?.attempts?.length === 2 && isKid) {
        //       kid_02++;
        //     }
        //     if (row?.attempts?.length === 3 && isKid) {
        //       kid_03++;
        //     }
        //     if (row?.attempts?.length === 4 && isKid) {
        //       kid_04++;
        //     }
        //   }
        // }
      }

      setValue({
        ad_01: ad_01,
        ad_02: ad_02,
        ad_03: ad_03,
        ad_04: ad_04,

        //
        kid_01: kid_01,
        kid_02: kid_02,
        kid_03: kid_03,
        kid_04: kid_04,
      });
    }
  }
};

export default CountSucAttempt;
