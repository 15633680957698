import React, { useEffect, useState } from "react";

const TimeDropdown = ({
  value,
  name,
  setField = () => {},
  disabled = false,
  classNameTitle = false,
  title,
  setValue = false,
  setStateObject = false,
}) => {
  const hours = [...Array(24).keys()].map((hour) =>
    hour.toString().padStart(2, "0")
  );
  const minutes = [...Array(60).keys()].map((minute) =>
    minute.toString().padStart(2, "0")
  );

  const [selectedHour, setSelectedHour] = useState("00");
  const [selectedMinute, setSelectedMinute] = useState("00");

  useEffect(() => {
    if (value) {
      const [hour, minute] = value.split(":");
      setSelectedHour(hour);
      setSelectedMinute(minute);
    }
  }, [value]);

  const handleChangeHour = (event) => {
    const newHour = event.target.value;
    setSelectedHour(newHour);
    updateField(newHour, selectedMinute);
  };

  const handleChangeMinute = (event) => {
    const newMinute = event.target.value;
    setSelectedMinute(newMinute);
    updateField(selectedHour, newMinute);
  };

  const updateField = (hour, minute) => {
    const newValue = `${hour}:${minute}`;
    setField(name)(newValue);
    if (setValue && setStateObject) {
      setValue((prev) => {
        let temp = { ...prev };
        temp[name] = newValue;
        return { ...temp };
      });
    }
  };

  return (
    <div className="w-52">
      <div className={classNameTitle ? classNameTitle : "my-2"}>{title}</div>
      <div className="border border-gray-300 bg-white p-2 rounded-md mx-10 ">
        <select
          value={selectedHour}
          onChange={handleChangeHour}
          disabled={disabled}
          w-20
        >
          {hours.map((hour) => (
            <option key={hour} value={hour}>
              {hour}
            </option>
          ))}
        </select>
        :
        <select
          value={selectedMinute}
          onChange={handleChangeMinute}
          disabled={disabled}
        >
          {minutes.map((minute) => (
            <option key={minute} value={minute}>
              {minute}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TimeDropdown;
