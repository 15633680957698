import ExcelExport from "export-xlsx";

export const ExcelHeight = async (
  inputdata,
  year,
  type,
  setLoading,
  height
) => {
  // const inputdata = { data: [{ test: 5 }] };
  const SETTINGS_FOR_EXPORT = {
    // Table settings
    fileName: "ALiST_REPORT",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `รายงานความสูง ความลึก(ซ.ม.) และข้างที่ใส่สาย (ซ้าย,ขวา) เลือกตามความสูง ${height?.label} ซ.ม. ของปี ${year?.label} ประเภทสาย  ${type?.label} `,
                key: "header_01",
                style: { fill: { fgColor: { rgb: "FF0000" } } },
              },
              {
                name: "ลึก 0-5",
                key: "deep_01",
                groupKey: "header_01",
              },
              {
                name: "ลึก 6-10",
                groupKey: "header_01",
                key: "deep_02",
              },
              {
                name: "ลึก 11-15",
                key: "deep_03",
                groupKey: "header_01",
              },
              {
                name: "ลึก 16-20",
                key: "deep_04",
                groupKey: "header_01",
              },
              {
                name: "ลึก 21-25",
                key: "deep_05",
                groupKey: "header_01",
              },
              {
                name: "ลึก 26-30",
                key: "deep_06",
                groupKey: "header_01",
              },
              {
                name: "ลึก 31-35",
                key: "deep_07",
                groupKey: "header_01",
              },
              {
                name: "ลึก 36-40",
                key: "deep_08",
                groupKey: "header_01",
              },
              {
                name: "ลึก 41-45",
                key: "deep_09",
                groupKey: "header_01",
              },
              {
                name: "ลึก 46-50",
                key: "deep_10",
                groupKey: "header_01",
              },
              {
                name: "ลึก 51-55",
                key: "deep_11",
                groupKey: "header_01",
              },
              {
                name: "ลึก 56-60",
                key: "deep_12",
                groupKey: "header_01",
              },
              {
                name: "รวม",
                key: "sum",
                groupKey: "header_01",
              },
            ],
            headerDefinition: [
              //
              {
                name: "ซ้าย",
                key: "lt_01",
                groupKey: "deep_01",
              },
              {
                name: "ขวา",
                key: "rt_01",
                groupKey: "deep_01",
              },
              //
              {
                name: "ซ้าย",
                key: "lt_02",
                groupKey: "deep_02",
              },
              {
                name: "ขวา",
                key: "rt_02",
                groupKey: "deep_02",
              },
              //
              //
              {
                name: "ซ้าย",
                key: "lt_03",
                groupKey: "deep_03",
              },
              {
                name: "ขวา",
                key: "rt_03",
                groupKey: "deep_03",
              },
              //
              //
              {
                name: "ซ้าย",
                key: "lt_04",
                groupKey: "deep_04",
              },
              {
                name: "ขวา",
                key: "rt_04",
                groupKey: "deep_04",
              },
              //
              //
              {
                name: "ซ้าย",
                key: "lt_05",
                groupKey: "deep_05",
              },
              {
                name: "ขวา",
                key: "rt_05",
                groupKey: "deep_05",
              },
              //
              //
              {
                name: "ซ้าย",
                key: "lt_06",
                groupKey: "deep_06",
              },
              {
                name: "ขวา",
                key: "rt_06",
                groupKey: "deep_06",
              },
              //
              //
              {
                name: "ซ้าย",
                key: "lt_07",
                groupKey: "deep_07",
              },
              {
                name: "ขวา",
                key: "rt_07",
                groupKey: "deep_07",
              },
              //
              //
              {
                name: "ซ้าย",
                key: "lt_08",
                groupKey: "deep_08",
              },
              {
                name: "ขวา",
                key: "rt_08",
                groupKey: "deep_08",
              },
              //
              //
              {
                name: "ซ้าย",
                key: "lt_09",
                groupKey: "deep_09",
              },
              {
                name: "ขวา",
                key: "rt_09",
                groupKey: "deep_09",
              },
              //
              //
              {
                name: "ซ้าย",
                key: "lt_10",
                groupKey: "deep_10",
              },
              {
                name: "ขวา",
                key: "rt_10",
                groupKey: "deep_10",
              },
              //
              //
              {
                name: "ซ้าย",
                key: "lt_11",
                groupKey: "deep_11",
              },
              {
                name: "ขวา",
                key: "rt_11",
                groupKey: "deep_11",
              },
              //
              //
              {
                name: "ซ้าย",
                key: "lt_12",
                groupKey: "deep_12",
              },
              {
                name: "ขวา",
                key: "rt_12",
                groupKey: "deep_12",
              },
              {
                name: "รวมซ้าย",
                key: "sum_lt",
                groupKey: "sum",
              },
              {
                name: "รวมขวา",
                key: "sum_rt",
                groupKey: "sum",
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
