import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const userDepStore = create(
  persist(
    (set) => ({
      userDepartmentFromSession: null,
      setUserDepartmentFromSession: (userDepartmentFromSession) =>
        set({ userDepartmentFromSession: userDepartmentFromSession }),
      clearUserDepartmentFromSession: () =>
        set({ userDepartmentFromSession: null }),
    }),
    {
      name: "userDepStore", // unique name
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default userDepStore;
