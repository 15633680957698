import { useEffect, useState } from "react";
import Dropdown from "../field/Dropdown";
import TextField from "../field/TextField";
import UploadImage from "../field/UploadImage";
import CheckBox from "../field/CheckBox";
import CheckBoxMulti from "../field/CheckBoxMulti";
import { TaskContext } from "../../contexts/TaskContext";
import { useContext } from "react";
import {
  lineStyle,
  subTitleStyle,
  titleStyle,
  WarningLabel,
  subTitTreament,
  titleStyle_Line_Infection,
  RemarkLabel,
} from "../../hooks/useClassName";
import CheckBoxWithDropDown from "../field/CheckBoxWithDropdown";
import CheckBoxWithTextField from "../field/CheckBoxWithTextField";
import DatePickerField from "../field/DatePickerField";
import {
  AttendingOptions,
  co_rtPAx,
  optionsDeath,
  optionsReasons,
  RNOptions,
  wo_rtPAx,
} from "../../contexts/DataOptions";
import { timeDifference, toReactSelect } from "../functions/Functions";
import CheckBoxSelectOne from "../field/CheckboxSelectOne";
import { ListDoctorAndNurseContext } from "../../contexts/ListDoctorAndNurseContext";
import { filterRole } from "../../components/functions/Functions";
import InputTime from "../field/InputTime";
import { WatDatePicker } from "thaidatepicker-react";
import dayjs from "dayjs";
import { fetchDoctors } from "../../api/doctorandnurse";
import axios from "axios";
import api from "../../api";
import { useSelector } from "react-redux";
import TimeDropdown from "../field/DropDownPickTime";

const Summary = ({
  setField,
  form,
  onClickFinalSave,
  disabled,
  seeDetail,
  onClickOutDetail,
  onClickCancle,
  summaryType,
  setSummaryType,
}) => {
  console.log("formSummary", form);
  // console.log("line_is_no_loangerrrr", form?.line_is_no_loanger);
  // console.log("form?.final_date_success", form?.final_date_success);
  const handleSum = (christDate, buddhistDate) => {
    // console.log(christDate)
    // console.log(buddhistDate);
    setField("line_is_no_loanger")(new Date(christDate).toISOString());
    // setSelectedDate(christDate)
    // setSelectedThaiDate(buddhistDate)
  };

  return (
    <div>
      <div className="">
        <div className={titleStyle}> Line removal / patient deceased date</div>
        <div className="text-base w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full  mx-10">
          <WatDatePicker
            value={form?.line_is_no_loanger ? form?.line_is_no_loanger : null}
            onChange={handleSum}
            dateFormat={"yyyy-MM-dd"}
            placeholder={"DD/MM/YYYY"}
            displayFormat={"DD MMMM YYYY"}
            inputStyle={{ color: "black", width: "190px" }}
            clearable={true}
            minDate={"1920-01-01"}
            maxDate={dayjs().add(20, "day")}
            disabled={false}
            readOnly={false}
          />
        </div>
      </div>

      <div className={"text-lg text-textinput font-semibold my-5 mx-10"}>
        Total catheter day
      </div>
      <TextField
        setField={setField}
        name="total_cather_day"
        value={
          form?.line_is_no_loanger
            ? timeDifference(
                new Date(`${form?.line_is_no_loanger}`),
                // new Date(
                //   `${form?.attempts_final_obj?.date}T${form?.attempts_final_obj?.line_finalized}:00`
                // )
                new Date(`${form?.final_date_success}`)
              )
            : "กรุณาเลือกวันที่ถอดสาย"
        }
        // unit={form?.attempt_final_date ? "days" : ""}
        disabled={true}
      />
      <CheckBoxMulti
        setField={setField}
        disabled={disabled}
        label={["ยังมีชีวิต", "เสียชีวิต"]}
        name={["summary_alive", "summary_deceased"]}
        value={[form?.summary_alive, form?.summary_deceased]}
        useDynamicDisable
        disableValue={[form?.summary_deceased, form?.summary_alive]}
      />

      <div className="">
        {(form?.summary_deceased || form?.summary_alive) && (
          <>
            <div className={"text-lg text-textinput font-semibold my-5 mx-10"}>
              Reasons for discontinuation
            </div>

            <RemarkLabel alive={form?.summary_alive} />
            <Dropdown
              placeholder={"ระบุเหตุผล"}
              disabled={disabled}
              name="reasons_for_discontinuation"
              value={form?.reasons_for_discontinuation}
              options={form?.summary_deceased ? optionsDeath : optionsReasons}
              setField={setField}
              other_name="other_reasons_for_discontinuation_name"
              other_value={form?.other_reasons_for_discontinuation_name}
            />
          </>
        )}
      </div>

      <CheckBoxSelectOne
        classNameTitle="  text-lg text-textinput font-semibold my-5 mx-10"
        title={"ความพึงพอใจของญาติ  (1 ไม่พอใจ ... 5 พอใจมากที่สุด)"}
        setField={setField}
        name="relative_satisfaction"
        value={form?.relative_satisfaction}
        disabled={disabled}
        label={["1", "2", "3", "4", "5"]}
      />
      <CheckBoxSelectOne
        classNameTitle="  text-lg text-textinput font-semibold my-5 mx-10"
        title={
          "ความพึงพอใจของบุคลากรดูแลผู้ป่วย (1 ไม่พอใจ ... 5 พอใจมากที่สุด)"
        }
        setField={setField}
        name="patient_care_personnel_satisfaction"
        value={form?.patient_care_personnel_satisfaction}
        disabled={disabled}
        label={["1", "2", "3", "4", "5"]}
      />
      {/*           
  
        <div className=" flex justify-center gap-5 my-10">
          <div
            onClick={() => onClickFinalSave()}
            className=" bg-white  hover:bg-white hover:shadow-lg shadow-md  w-64 p-2 rounded-md flex justify-center text-textgrayalist border hover:border-blue-600"
          >
            Final save
          </div>
          <div
            onClick={() => onClickCancle()}
            className="bg-white  hover:bg-white hover:shadow-lg shadow-md w-64 p-2 rounded-md flex justify-center text-textgrayalist border hover:border-blue-600"
          >
            Cancel
          </div>
        </div> */}

      {seeDetail ? (
        <div
          onClick={() => onClickOutDetail()}
          className="cursor-pointer my-8 bg-white w-full  hover:bg-white hover:shadow-lg shadow-md  p-2 rounded-md flex justify-center text-textgrayalist border hover:border-blue-600"
        >
          Cancel
        </div>
      ) : (
        <div className=" flex justify-center gap-10 my-10">
          <div
            onClick={() => onClickFinalSave()}
            className=" bg-white  hover:bg-white hover:shadow-lg shadow-md  w-36 p-2 rounded-md flex justify-center text-textgrayalist border hover:border-blue-600"
          >
            Final save
          </div>
          <div
            onClick={() => onClickCancle()}
            className="bg-white  hover:bg-white hover:shadow-lg shadow-md w-36 p-2 rounded-md flex justify-center text-textgrayalist border hover:border-blue-600"
          >
            Cancel
          </div>
        </div>
      )}
    </div>
  );
};
const FormSummary = ({
  onClickSaveFollow,
  onClickFinalSave,
  onClickUpdateFollow,
  disabled = false,
  seeDetail,
  onClickOutDetail,
  form,
  onClickCancle,
  count,
  seeDetailId,
  viewOnly,
  setNav,
  nav,
  setSeeDetailId,
  setCurrentFollow,
  currentFollow,
  tables,
  setSeeDetail,
  summaryType,
  setSummaryType,
  loadDateFollow = false,
  loadTimeFollow = false,
}) => {
  console.log("form in : ", form);
  // const count = 1;
  const { setField } = useContext(TaskContext);
  const [summary, setSummary] = useState(false);
  const { listName } = useContext(ListDoctorAndNurseContext);
  const [docandnurses, setDocAndNurses] = useState([]);

  // console.log("docandnurses", docandnurses);

  // console.log("seeDetail", seeDetail);

  const { user } = useSelector((state) => state.auth);

  const currentTime = new Date().toLocaleTimeString("th-TH", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const handleVisit = (christDate, buddhistDate) => {
    setField("date_follow")(christDate);
  };

  const loadDocAndNurse = async () => {
    try {
      const { data } = await api.get("/profiles", {
        // params: {
        //   role: "NURSE",
        // },
      });

      const { data: userData } = await api.get("/profiles/" + user._id);

      const temp = [userData.profile, ...data.profiles]?.map((el) => ({
        value: el.fullname?.replace(/ /g, ""),
        label: el.fullname,
        role: el.role,
      }));

      // console.log(`form: `, form);
      // console.log(`form?.follow_rn: `, form?.follow_rn);

      if (!form?.follow_rn && !seeDetail) {
        setField("follow_rn")(temp[0]);
      }

      const profileOption = [userData.profile]?.map((el) => ({
        value: el.fullname?.replace(/ /g, ""),
        label: el.fullname,
        role: el.role,
      }));

      const result = temp?.filter((el) => el.role === "NURSE");

      const realResult = [...profileOption, ...result];

      // console.log(`result: `, result);

      setDocAndNurses(realResult);
    } catch (error) {
      console.error("ERORR :", error);
    }
  };

  // useEffect(() => {
  //   if (form?.follow_rn) {
  //     console.log(`Running...`);
  //     console.log(`docandnurses: `, docandnurses);
  //   }
  // }, [form?.follow_rn]);

  useEffect(() => {
    if (form?.follow_rn && docandnurses.length > 0) {
      const thisValue = form?.follow_rn?.value;

      const isExist = docandnurses.find((el) => el.value === thisValue);

      if (!isExist) {
        setDocAndNurses((prev) => [form?.follow_rn, ...prev]);
      }
    }
  }, [form?.follow_rn, docandnurses.length]);

  useEffect(() => {
    loadDocAndNurse();
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && loadDateFollow) {
      // console.log(`In load_date_follow`);
      //2023-12-25"
      const currentDate = new Date().toISOString().split("T")[0];
      setField("date_follow")(currentDate);
    }

    return () => {
      isMounted = false;
    };
  }, [loadDateFollow]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && loadTimeFollow) {
      // console.log(`In load_time_follow`);

      //"16:00"
      setField("time_follow")(currentTime);
    }

    return () => {
      isMounted = false;
    };
  }, [loadTimeFollow]);

  return (
    <div className=" ">
      <div className=" md:text-2xl text-xl text-textinput font-bold mt-5 mx-28">
        Follow up
      </div>
      <div className="md:mx-20 mt-10">
        {seeDetail ? (
          <div className="flex justify-between">
            <div className="flex">
              <div className="mx-10 text-xl text-textinput font-semibold">
                Visit {count}
              </div>
            </div>
            <div
              onClick={() => {
                setCurrentFollow(null);
                setSeeDetailId("");
              }}
              className="text-sm cursor-pointer  text-gray-300 hover:text-gray-500  "
            >
              {" "}
              คลิกเพื่อกลับไปที่หน้า ALiST Follow up note
            </div>{" "}
          </div>
        ) : (
          <div className="mx-10 text-xl text-textinput font-semibold">
            Visit {count}
          </div>
        )}

        <div className="">
          <div className={titleStyle}> วัน/เวลา ที่ติดตามคนไข้</div>
          <div className="text-base w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full  mx-10">
            <WatDatePicker
              value={form?.date_follow}
              onChange={handleVisit}
              dateFormat={"yyyy-MM-dd"}
              displayFormat={"DD MMMM YYYY"}
              placeholder={"DD/MM/YYYY"}
              inputStyle={{ color: "black", width: "190px" }}
              clearable={true}
              minDate={"1920-01-01"}
              maxDate={dayjs().add(20, "day")}
              disabled={false}
              readOnly={false}
            />
          </div>
        </div>
        <TimeDropdown
          setField={setField}
          name="time_follow"
          value={form?.time_follow}
          disabled={disabled}
        />
        {/* <InputTime
          setField={setField}
          name="time_follow"
          value={form?.time_follow}
          disabled={disabled}
        /> */}
        <CheckBoxMulti
          disabled={disabled}
          title="By"
          name={["by_phone", "by_actial_visit"]}
          label={["โทรศัพท์", "เข้าเยี่ยม"]}
          value={[form?.by_phone, form?.by_actial_visit]}
          setField={setField}
        />
        <Dropdown
          disabled={true}
          title="RN"
          setField={setField}
          name={"follow_rn"}
          value={form?.follow_rn}
          options={docandnurses}
          // options={filterRole(listName, "NURSE")}
          // options={RNOptions}
        />

        <Dropdown
          disabled={disabled}
          title="Attending"
          setField={setField}
          name={"attending"}
          value={form?.attending}
          options={filterRole(listName, "DOCTOR")}
          // options={AttendingOptions}
        />
        <div className={titleStyle}>Line patency</div>
        <CheckBox
          disabled={disabled}
          label={"OK All"}
          setField={setField}
          name={"line_ok_all"}
          value={form?.line_ok_all}
        />
        <CheckBox
          disabled={disabled}
          label={"WO Port"}
          setField={setField}
          name={"line_wo_port"}
          value={form?.line_wo_port}
        />
        {form?.line_wo_port && (
          <div>
            <CheckBoxMulti
              disabled={disabled}
              setField={setField}
              label={["Distal", "Proximal", "Purple", "White", "Other"]}
              name={[
                "line_wo_distal",
                "line_wo_proximal",
                "line_wo_purple",
                "line_wo_white",
                "other_line_wo_port",
              ]}
              value={[
                form?.line_wo_distal,
                form?.line_wo_proximal,
                form?.line_wo_purple,
                form?.line_wo_white,
                form?.other_line_wo_port,
              ]}
              other_name={"other_line_wo_port_name"}
              other_value={form?.other_line_wo_port_name}
            />
            <div className={titleStyle}>Treatment</div>
            <CheckBox
              disabled={disabled}
              setField={setField}
              label={"Observe"}
              name={"line_wo_observe"}
              value={form?.line_wo_observe}
              fontLabelWeight="font-normal"
            />
            <CheckBoxWithDropDown
              disabled={disabled}
              setField={setField}
              title={"rtPA x"}
              name={"line_wo_rtpax"}
              value={form?.line_wo_rtpax}
              dropDownName={"line_wo_rtpax_name"}
              dropDownValue={form?.line_wo_rtpax_name}
              options={wo_rtPAx}
              placeholder={"times"}
            />
            <CheckBoxWithTextField
              disabled={disabled}
              setField={setField}
              label={"Other"}
              title={"Other"}
              name={"line_wo_other"}
              value={form?.line_wo_other}
              textFieldName={"line_wo_other_detail"}
              textFieldValue={form?.line_wo_other_detail}
            />
            <CheckBoxMulti
              disabled={disabled}
              title={"Result"}
              setField={setField}
              classNameTitle="text-lg text-textinput  my-5"
              // "md:text-2xl text-xl text-textinput font-semibold my-5";
              label={["Opened", "WO", "CO"]}
              name={[
                "line_wo_result_opened",
                "line_wo_result_wo",
                "line_wo_result_co",
              ]}
              value={[
                form?.line_wo_result_opened,
                form?.line_wo_result_wo,
                form?.line_wo_result_co,
              ]}
            />

            <div className={lineStyle} />
          </div>
        )}
        <CheckBox
          disabled={disabled}
          label={"CO Port"}
          setField={setField}
          name={"line_co_port"}
          value={form?.line_co_port}
        />
        {form?.line_co_port && (
          <div>
            <CheckBoxMulti
              disabled={disabled}
              setField={setField}
              label={["Distal", "Proximal", "Purple", "White", "Other"]}
              name={[
                "line_co_distal",
                "line_co_proximal",
                "line_co_purple",
                "line_co_white",
                "other_line_co_port",
              ]}
              value={[
                form?.line_co_distal,
                form?.line_co_proximal,
                form?.line_co_purple,
                form?.line_co_white,
                form?.other_line_co_port,
              ]}
              other_name={"other_line_co_port_name"}
              other_value={form?.other_line_co_port_name}
            />
            <div className={titleStyle}>Treatment</div>
            <CheckBox
              disabled={disabled}
              setField={setField}
              label={"Observe"}
              name={"line_co_observe"}
              value={form?.line_co_observe}
              fontLabelWeight="font-normal"
            />
            <CheckBoxWithDropDown
              disabled={disabled}
              setField={setField}
              title={"rtPA x"}
              name={"line_co_rtpax"}
              value={form?.line_co_rtpax}
              dropDownName={"line_co_rtpax_name"}
              dropDownValue={form?.line_co_rtpax_name}
              options={co_rtPAx}
              placeholder={"times"}
            />
            <CheckBoxWithTextField
              disabled={disabled}
              setField={setField}
              label={"Other"}
              title={"Other"}
              name={"line_co_other"}
              value={form?.line_co_other}
              textFieldName={"line_co_other_detail"}
              textFieldValue={form?.line_co_other_detail}
            />

            <CheckBoxMulti
              disabled={disabled}
              title={"Result"}
              classNameTitle="text-lg text-textinput  my-5"
              setField={setField}
              label={["Opened", "WO", "CO"]}
              name={[
                "line_co_result_opened",
                "line_co_result_wo",
                "line_co_result_co",
              ]}
              value={[
                form?.line_co_result_opened,
                form?.line_co_result_wo,
                form?.line_co_result_co,
              ]}
            />

            <div className={lineStyle} />
          </div>
        )}
        <div className={titleStyle}>Line Infection</div>
        <CheckBox
          disabled={disabled}
          label={"No"}
          setField={setField}
          fontLabelWeight="font-normal"
          name={"line_infection_nil"}
          value={form?.line_infection_nil}
        />
        <CheckBox
          disabled={disabled}
          label={"Line infection"}
          setField={setField}
          fontLabelWeight="font-normal"
          name={"line_infection_bug_found"}
          value={form?.line_infection_bug_found}
        />
        {form?.line_infection_bug_found && (
          <div>
            <CheckBoxMulti
              disabled={disabled}
              label={[
                "C/S {+} ve",
                "Distal",
                "Proximal",
                "Purple",
                "White",
                "Other",
              ]}
              setField={setField}
              name={[
                "line_infection_bug_found_cs_plus",
                "line_infection_bug_found_distal",
                "line_infection_bug_found_proximal",
                "line_infection_bug_found_purple",
                "line_infection_bug_found_white",
                "other_line_infection_bug_found",
              ]}
              value={[
                form?.line_infection_bug_found_cs_plus,
                form?.line_infection_bug_found_distal,
                form?.line_infection_bug_found_proximal,
                form?.line_infection_bug_found_purple,
                form?.line_infection_bug_found_white,
                form?.other_line_infection_bug_found,
              ]}
              other_name={"other_line_infection_bug_found_name"}
              other_value={form?.other_line_infection_bug_found_name}
            />
            <div className={subTitleStyle}>Organism</div>
            <TextField
              disabled={disabled}
              placeholder={"Organism"}
              setField={setField}
              name={"line_infection_bug_found_organism"}
              value={form?.line_infection_bug_found_organism}
            />
          </div>
        )}
        <CheckBoxMulti
          disabled={disabled}
          title={"Line displacement / dislodged"}
          setField={setField}
          label={["Nil"]}
          name={["line_disledge_nil"]}
          value={[form?.line_disledge_nil]}
        />
        <CheckBoxWithTextField
          disabled={disabled}
          setField={setField}
          title="Dislodge New exit mark at"
          name="line_disledge_new_exit_mark_at"
          value={form?.line_disledge_new_exit_mark_at}
          unit={"cm"}
          textFieldName={"line_disledge_new_exit_mark_at_name"}
          textFieldValue={form?.line_disledge_new_exit_mark_at_name}
          type={"number"}
        />

        {form?.line_disledge_new_exit_mark_at && (
          <TextField
            title="indwelled"
            // type="number"
            // dateType="Date"
            disabled={disabled}
            setField={setField}
            name={"line_disledge_indwellend_name"}
            value={
              form?.line_disledge_indwellend_name
                ? form?.line_disledge_indwellend_name
                : null
            }
          />
        )}
        <CheckBoxMulti
          disabled={disabled}
          setField={setField}
          label={["Completely dislodged"]}
          name={["complete_dislodge"]}
          value={[form?.complete_dislodge]}
        />
        <CheckBoxMulti
          title={"Catheter-related  limb edema "}
          disabled={disabled}
          setField={setField}
          label={["No ", "Yes"]}
          name={[
            "catheter_related_limb_edema_no ",
            "catheter_related_limb_edema_yes",
          ]}
          value={[
            form?.catheter_related_limb_edema_no,
            form?.catheter_related_limb_edema_yes,
          ]}
        />
        {form?.catheter_related_limb_edema_yes && (
          <div>
            <DatePickerField
              classNameTitle={
                "md:text-lg text-base  font-normal text-textinput"
              }
              title={"since date"}
              name={"catheter_related_limb_edema_date"}
              value={form?.catheter_related_limb_edema_date}
              setField={setField}
              disabled={disabled}
              dateType="Date"
            />

            <TextField
              classNameTitle={
                "md:text-lg text-base  font-normal text-textinput "
              }
              title={"explain"}
              disabled={disabled}
              name="remark"
              value={form?.remark}
              setField={setField}
            />
          </div>
        )}
        <div className="md:text-lg text-base  font-normal text-textinput mx-10">
          Presence of DVT
        </div>
        <CheckBoxMulti
          disabled={disabled}
          setField={setField}
          label={["No", "Yes", "N/A"]}
          name={[
            "presence_of_dvt_no",
            "presence_of_dvt_yes",
            "presence_of_dvt_na",
          ]}
          value={[
            form?.presence_of_dvt_no,
            form?.presence_of_dvt_yes,

            form?.presence_of_dvt_na,
          ]}
        />
        <div className="md:text-lg text-base  font-normal text-textinput mx-10">
          Management
        </div>
        <CheckBox
          setField={setField}
          disabled={disabled}
          label={"Observation"}
          name={"management_observation"}
          value={form?.management_observation}
          fontLabelWeight="font-normal"
        />
        <CheckBoxWithTextField
          label={"Anticoagulation"}
          title={"Anticoagulation"}
          disabled={disabled}
          setField={setField}
          name={"management_anticoagulation"}
          value={form?.management_anticoagulation}
          textFieldName={"management_anticoagulation_name"}
          textFieldValue={form?.management_anticoagulation_name}
        />
        <CheckBox
          setField={setField}
          disabled={disabled}
          label={"Catheter removal"}
          name={"management_catheter_removal"}
          value={form?.management_catheter_removal}
          fontLabelWeight="font-normal"
        />
        <CheckBoxMulti
          disabled={disabled}
          title={"Others"}
          setField={setField}
          label={["Breakage/leak port"]}
          name={["line_disledge_breakage_leak_port"]}
          value={[form?.line_disledge_breakage_leak_port]}
        />
        {form?.line_disledge_breakage_leak_port && (
          <CheckBoxMulti
            disabled={disabled}
            setField={setField}
            label={[
              "Distal",
              "Proximal",
              "Purple",
              "Red", //เมื่อก่อนเป็น white
              "Other",
            ]}
            name={[
              "line_disledge_distal",
              "line_disledge_proximal",
              "line_disledge_purple",
              "line_disledge_red",
              "other_line_disledge",
            ]}
            value={[
              form?.line_disledge_distal,
              form?.line_disledge_proximal,
              form?.line_disledge_purple,
              form?.line_disledge_red,
              form?.other_line_disledge,
            ]}
            other_name={"other_line_disledge_name"}
            other_value={form?.other_line_disledge_name}
          />
        )}
        {/* <div className={lineStyle} /> */}
        <CheckBoxMulti
          disabled={disabled}
          setField={setField}
          label={["Retained FB Port"]}
          name={["line_retained_fb_port"]}
          value={[form?.line_retained_fb_port]}
        />

        {form?.line_retained_fb_port && (
          <CheckBoxMulti
            disabled={disabled}
            setField={setField}
            label={["Distal", "Proximal", "Purple", "Red", "Other"]}
            name={[
              "line_retained_fb_distal",
              "line_retained_fb_proximal",
              "line_retained_fb_purple",
              "line_retained_fb_red",
              "other_line_retained_fb",
            ]}
            value={[
              form?.line_retained_fb_distal,
              form?.line_retained_fb_proximal,
              form?.line_retained_fb_purple,
              form?.line_retained_fb_red,
              form?.other_line_retained_fb,
            ]}
            other_name={"other_line_retained_fb_name"}
            other_value={form?.other_line_retained_fb_name}
          />
        )}
        {form?.other_line_disledge && (
          <>
            <div className={subTitleStyle}>Since Date</div>
            <DatePickerField
              disabled={disabled}
              setField={setField}
              name={"line_disledge_since_date"}
              dateType="Date"
              value={
                form?.line_disledge_since_date
                  ? new Date(form?.line_disledge_since_date)
                  : null
              }
            />
          </>
        )}
        {form?.other_line_retained_fb && (
          <>
            {" "}
            <div className={subTitleStyle}>Since Date</div>
            <DatePickerField
              disabled={disabled}
              setField={setField}
              dateType="Date"
              name={"line_retained_fb_since_date"}
              value={
                form?.line_retained_fb_since_date
                  ? new Date(form?.line_retained_fb_since_date)
                  : null
              }
            />
            <div className={lineStyle} />
          </>
        )}

        <CheckBoxMulti
          disabled={disabled}
          setField={setField}
          label={["Limb ischemia related to arterial puncture"]}
          name={["line_limb_ischemia_related_to_arterial_puncture"]}
          value={[form?.line_limb_ischemia_related_to_arterial_puncture]}
          classnameSelect={
            "grid md:grid-cols-1  lg:grid-cols-1  xl:grid-cols-1 my-5 md:mx-24 lg:mx-24 xl:mx-10"
          }
        />
        <CheckBoxMulti
          disabled={disabled}
          setField={setField}
          label={["Nerve injury (weakness or paresthesia)"]}
          name={["line_nerve_injury_weakness_or_paresthesia"]}
          value={[form?.line_nerve_injury_weakness_or_paresthesia]}
          classnameSelect={
            "grid md:grid-cols-1  lg:grid-cols-1  xl:grid-cols-1 my-5 md:mx-24 lg:mx-24 xl:mx-10"
          }
        />
        <div className={titleStyle}>Exit site</div>
        <UploadImage
          disabled={disabled}
          setField={setField}
          name={"picture_exit_site_summary"}
          value={form?.picture_exit_site_summary}
        />
        <CheckBoxMulti
          disabled={disabled}
          setField={setField}
          label={["OK", "Others"]}
          name={["exit_site_ok", "other_exit_site"]}
          value={[form?.exit_site_ok, form?.other_exit_site]}
          other_name={"other_exit_site_name"}
          other_value={form?.other_exit_site_name}
        />
        <CheckBoxWithTextField
          disabled={disabled}
          setField={setField}
          label={"Exit site infection"}
          title={"Exit site infection"}
          name={"exit_site_infection"}
          value={form?.exit_site_infection}
          textFieldName={"exit_site_infection_name"}
          textFieldValue={form?.exit_site_infection_name}
        />
        <TextField
          title="Note"
          className="text-base p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full  mx-10  w-full "
          setField={setField}
          name="node_etc"
          value={form?.node_etc}
          disabled={disabled}
        />
        {/* <WarningLabel label="หากสายมีปัญหา หรือถอดสาย กรุณาโทรแจ้ง 41151 หรือ 065-3911216 ขอบคุณค่ะ" /> */}

        {/* {!summary && !seeDetail && ( */}
        {viewOnly ? (
          <div className="text-sm text-center p-4">
            *เนื่องจากลงวันที่ติดตามผู้ป่วยเกิน 24 ชั่วโมงแล้ว
            จึงไม่สามารถแก้ไขรายละเอียดได้อีก
          </div>
        ) : (
          <div className=" flex justify-center gap-5 my-10">
            <div
              onClick={() => onClickSaveFollow()}
              className=" bg-white  hover:bg-white hover:shadow-lg shadow-md  w-64 p-2 rounded-md flex justify-center text-textgrayalist border hover:border-blue-600"
            >
              Save
            </div>
            <div
              onClick={() => onClickCancle()}
              className="bg-white  hover:bg-white hover:shadow-lg shadow-md w-64 p-2 rounded-md flex justify-center text-textgrayalist border hover:border-blue-600"
            >
              Cancel
            </div>
          </div>
        )}

        <WarningLabel label="สำหรับการ Follow up ครั้งสุดท้าย กรุณากรอกข้อมูล Summary แล้วคลิก Final save เพื่อสรุปข้อมูล " />
        <WarningLabel label=" * หากกลับเข้ามาแก้ไข visit แล้วไม่มีปุ่ม final save ให้เพิ่ม F/U details อันใหม่ เพื่อกรอก Summay แล้วคลิก Final save * " />
        <div className=" mb-4">
          <div
            onClick={() => setSummary(!summary)}
            class="  cursor-pointer p-2 flex justify-between items-center bg-white "
          >
            <div className="md:text-xl text-base font-bold  text-bgblue mx-12">
              Summary
            </div>
          </div>

          {summary && (
            <div className="bg-white p-5">
              <Summary
                onClickOutDetail={onClickOutDetail}
                disabled={disabled}
                setField={setField}
                form={form}
                onClickFinalSave={onClickFinalSave}
                seeDetail={seeDetail}
                onClickCancle={onClickCancle}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default FormSummary;
