export const titleStyle =
  "  md:text-2xl text-xl text-textinput font-semibold my-5 mx-10";

export const titleStyle_Line_Infection =
  " md:text-2xl text-xl text-textinput font-semibold my-5 mx-10";

export const headerStyle =
  "md:text-2xl text-3xl text-textinput font-semibold mb-5 mx-10";

export const lineStyle = "border-b border-gray-300 w-full my-5 ";

export const subTitleStyle = " text-lg text-textinput font-semibold my-5";
export const subTitTreament = " text-lg text-textinput  my-5";

export const getStyleByStatus = (status) => {
  if (status === "on_iv_tube") return "  bg-yellowstatus text-textyellow";
  if (status === "waiting") return "bg-bluestatus text-textblue";
  if (status === "waiting_full") return "bg-bluestatus text-textblue";
  if (status === "completed") return "bg-greenstatus text-textgreen";
  if (status === "deceased") return "bg-redstatus text-textred";
  if (status === "cancelled") return "bg-redstatus text-textred";
  if (status === "unfollow") return "bg-purple-100 text-purple-500";
  if (status === "keep_for_report") return "bg-gray-100 text-gray-500";
  return "no status";
};

export const getStatusByStatus = (status) => {
  if (status === "on_iv_tube") return "Indwelling line";
  if (status === "waiting") return "Waiting";
  if (status === "waiting_full") return "Waiting";
  if (status === "completed") return "Completed";
  if (status === "deceased") return "Deceased";
  if (status === "cancelled") return "Cancelled";
  if (status === "unfollow") return "No F/U";
  if (status === "keep_for_report") return "-";
  return "no status";
};

export const WarningLabel = ({ label }) => {
  return (
    <div>
      <div
        className="flex bg-yellow-100 rounded-lg p-4 mb-4 text-base text-yellow-700 mx-10"
        role="alert"
      >
        <svg
          className="w-5 h-5 inline mr-3"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <div>
          <span className="font-medium">Warning !</span>
          {label}
        </div>
      </div>
    </div>
  );
};

export const RemarkLabel = ({ alive = false }) => {
  return (
    <div>
      <div className=" bg-blue-50 rounded-lg p-4 mb-4  text-textblue mx-10 text-sm ">
        {alive ? (
          <>
            {" "}
            <div>No longer needed = หมดข้อบ่งชี้, ให้ยาครบ, กลับบ้าน</div>
            <div>
              CLABSI = สงสัยสายติดเชื้อ ทั้งที่เพาะเชื้อขึ้น
              และยังเพาะเชื้อไม่ขึ้น
            </div>
            <div>Mechanical problems = สายรั่ว แตก</div>
            <div>Dislodge = สายเลื่อนจนใช้งานไม่ได้ หรือสายหลุด</div>
            <div>Unresolved occlusion = สายตัน</div>
            <div>Exit site infection = แผลติดเชื้อ</div>
            <div>
              Unintended position = ปลายสายอยู่ผิดที่ตั้งแต่ตอนใส่ ใช้สายไม่ได้
              ต้องเอาออก
            </div>
            <div> Other = อื่นๆ ที่ไม่ใช่ข้างบนนี้</div>{" "}
          </>
        ) : (
          <div>Death = เสียชีวิตทุกกรณี</div>
        )}
      </div>
    </div>
  );
};
