import { ageDayCalculator } from "../../../components/functions/Functions";
import FunctionCheckType from "./Report.FunctionCheckType";

const CountCatheterAll = ({ list, setValue, type, year }) => {
  let piccAdult = 0;
  let piccKid = 0;
  let centralAdult = 0;
  let centralKid = 0;
  let missingDataDOB = 0;
  let missingDataDOBtask = [];

  let piccMissingDataDOB = 0;
  let centralMissingDataDOB = 0;

  let filteredRows = {
    piccAdult: [],
    piccKid: [],
    centralAdult: [],
    centralKid: [],
  };

  if (list?.length > 0) {
    for (let row of list) {
      if (row?.final_date_success) {
        const isAdult =
          ageDayCalculator(
            new Date(row?.dob),
            new Date(row?.final_date_success)
          ) >= 6570;
        const isKid =
          ageDayCalculator(
            new Date(row?.dob),
            new Date(row?.final_date_success)
          ) < 6570;

        const finalDate = row?.final_date_success || "";
        const useYear = finalDate.substring(0, 4);

        if (
          row?.final_date_success &&
          row?.dob &&
          (year === "ALL" || year === useYear)
        ) {
          const catheterType = FunctionCheckType(
            row?.catheter?.value,
            row?.other_catheter_name,
            row?.check_catheter
          );

          if (isAdult) {
            if (catheterType === "PICC") {
              piccAdult++;
              filteredRows.piccAdult.push(row._id);
            } else if (catheterType === "CENTRAL_LINE") {
              centralAdult++;
              filteredRows.centralAdult.push(row._id);
            }
          } else if (isKid) {
            if (catheterType === "PICC") {
              piccKid++;
              filteredRows.piccKid.push(row._id);
            } else if (catheterType === "CENTRAL_LINE") {
              centralKid++;
              filteredRows.centralKid.push(row._id);
            }
          }
        }
        if (
          row?.final_date_success &&
          !row?.dob &&
          (year === "ALL" || year === useYear)
        ) {
          missingDataDOB++;
          missingDataDOBtask.push(row);

          // Counting piccMissingDataDOB and centralMissingDataDOB
          const catheterType = FunctionCheckType(
            row?.catheter?.value,
            row?.other_catheter_name,
            row?.check_catheter
          );

          if (catheterType === "PICC") {
            piccMissingDataDOB++;
          } else if (catheterType === "CENTRAL_LINE") {
            centralMissingDataDOB++;
          }
        }
      }
    }
  }

  setValue({
    picc: {
      adult: piccAdult,
      kid: piccKid,
    },
    central: {
      adult: centralAdult,
      kid: centralKid,
    },
    missingDataDOB: missingDataDOB,
    piccMissingDataDOB: piccMissingDataDOB,
    centralMissingDataDOB: centralMissingDataDOB,
    filteredRows: filteredRows,
    missingDataDOBtask: missingDataDOBtask,
  });
};

export default CountCatheterAll;
