import Dropdown from "../field/Dropdown";
import TextField from "../field/TextField";
import UploadImage from "../field/UploadImage";
import { headerStyle, lineStyle } from "../../hooks/useClassName";
import CheckBoxMulti from "../field/CheckBoxMulti";
import { titleStyle } from "../../hooks/useClassName";
import {
  OptionECG,
  OptionsPatientType,
  OptionWard,
  OptionWard72years,
  OptionWard84years,
  OptionWardAccident,
  OptionWardBuilding,
  OptionWardChalerm,
  OptionWardDek,
  OptionWardHeart,
  OptionWardNavamin,
  OptionWardOther,
  OptionWardOtherRoom,
  OptionWardOutPatient,
  OptionWardPICU,
  OptionWardPrakan,
  OptionWardPraSri,
  OptionWardSalak,
  OptionWardSayamin,
  OptionWardShortStay,
} from "../../contexts/DataOptions";
import { ageCalculator, checkDisable } from "../functions/Functions";
import DatePickerYear from "../field/DatePickerYear";
import ButtonAddContact from "../attribute/buttonAddContact";
import React, { useContext } from "react";
import { TaskContext } from "../../contexts/TaskContext";
import { useEffect } from "react";
import { useState } from "react";
import InputTime from "../field/InputTime";
import DatePickerField from "../field/DatePickerField";
import CheckBoxSelectOne from "../field/CheckboxSelectOne";
import { useSelector } from "react-redux";
import { WatDatePicker } from "thaidatepicker-react";
import dayjs from "dayjs";
import TimeDropdown from "../field/DropDownPickTime";

const FormInfo = ({ nav, form, setField, disabled }) => {
  // console.log("FORM Nee : ", form);
  const { user } = useSelector((state) => state.auth);
  const { setFieldMulti, addArray, removeArray, setForm } =
    useContext(TaskContext);

  const [checkChangeWard, setCheckChangeWard] = useState(false);

  const handleWatDatePickerChange = (christDate, buddhistDate) => {
    // console.log(christDate)
    // console.log(buddhistDate);
    setField("dob")(new Date(christDate).toISOString());
    // setSelectedDate(christDate)
    // setSelectedThaiDate(buddhistDate)
  };
  const handlePaper = (christDate, buddhistDate) => {
    // console.log(christDate)
    // console.log(buddhistDate);
    setField("paper_date")(christDate);
    // setSelectedDate(christDate)
    // setSelectedThaiDate(buddhistDate)
  };
  const handlePromise = (christDate, buddhistDate) => {
    // console.log(christDate)
    // console.log(buddhistDate);
    setField("promise")(christDate);
    // setSelectedDate(christDate)
    // setSelectedThaiDate(buddhistDate)
  };

  useEffect(() => {
    // if (form?.option_ward?.value === "other_location") {
    //   setForm({ ...form, phone_number: "" });
    // }
    if (form?.option_ward?.value === "other_location" && form?.ward) {
      setForm({ ...form, phone_number: form?.phone_number });
    }
    if (form?.option_ward?.value !== "other_location" && form?.ward) {
      setForm({ ...form, phone_number: form?.ward?.phone });
    }
  }, [checkChangeWard]);

  // console.log("ward", form?.ward);

  useEffect(() => {
    if (checkChangeWard) {
      if (!form?.option_ward?.value || !form?.option_ward_other?.value) return;
      if (form?.option_ward?.value === "ตึกอื่น ๆ") {
        setForm({
          ...form,
          phone_number: form?.ward?.phone,
          ward: getOptionOther()[0],
        });
      } else {
        setForm({
          ...form,
          phone_number: form?.ward?.phone,
          ward: getOptionWard()[0],
        });
      }
    }
  }, [checkChangeWard]);

  const getOptionWard = () => {
    if (form?.option_ward?.value === "72 ปี") {
      return OptionWard72years;
    } else if (form?.option_ward?.value === "84 ปี") {
      return OptionWard84years;
    } else if (form?.option_ward?.value === "เฉลิมพระเกียรติ") {
      return OptionWardChalerm;
    } else if (form?.option_ward?.value === "พระศรี") {
      return OptionWardPraSri;
    } else if (form?.option_ward?.value === "เด็ก") {
      return OptionWardDek;
    } else if (form?.option_ward?.value === "นวมินทรบพิตร") {
      return OptionWardNavamin;
    } else if (form?.option_ward?.value === "ตึกผู้ป่วยนอก") {
      return OptionWardOutPatient;
    } else if (form?.option_ward?.value === "สยามินทร์") {
      return OptionWardSayamin;
    } else if (form?.option_ward?.value === "ศูนย์โรคหัวใจ") {
      return OptionWardHeart;
    } else if (form?.option_ward?.value === "สลาก") {
      return OptionWardSalak;
    } else if (form?.option_ward?.value === "อุบัติเหตุ") {
      return OptionWardAccident;
    } else if (form?.option_ward?.value === "Short stay") {
      return OptionWardShortStay;
    }
  };

  const getOptionOther = () => {
    if (form?.option_ward_other?.value === "ประกันสังคม") {
      return OptionWardPrakan;
    } else if (form?.option_ward_other?.value === "PICU") {
      return OptionWardPICU;
    } else if (form?.option_ward_other?.value === "ห้องตรวจอื่นๆ") {
      return OptionWardOtherRoom;
    }
  };

  return (
    <div className=" p-4 md:p-10 lg:p-20">
      <div className={headerStyle}>
        {nav === "FULL DATABASE" ? "Full database" : "Initial patient database"}
      </div>
      <TextField
        title="HN"
        subtitle="( แก้ไขได้เฉพาะ admin )"
        setField={setField}
        name="hn"
        value={form?.hn}
        disabled={form?._id ? user.role != "ADMIN" : form?._id}
        // disabled={form?._id}
        type="Number"
        max={9}
      />
      <TextField
        title="ชื่อ"
        setField={setField}
        name="full_name"
        value={form?.full_name}
        disabled={checkDisable(form?.status)}
      />
      <TextField
        title="นามสกุล"
        setField={setField}
        name="last_name"
        value={form?.last_name}
        disabled={checkDisable(form?.status)}
      />

      <CheckBoxSelectOne
        title={"เพศ"}
        disabled={disabled ? disabled : checkDisable(form?.status)}
        setField={setField}
        name="gender"
        value={form?.gender}
        label={["ชาย", "หญิง"]}
      />

      {/* <DatePickerYear
        title="DOB"
        setField={setField}
        name="dob"
        value={form?.dob ? new Date(form?.dob) : null}
        year
      /> */}

      <div className="">
        <div className={titleStyle}> DOB</div>
        <div className="text-base w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full  mx-10">
          <WatDatePicker
            value={form?.dob ? form?.dob : null}
            onChange={handleWatDatePickerChange}
            dateFormat={"yyyy-MM-dd"}
            placeholder={"DD/MM/YYYY"}
            displayFormat={"DD MMMM YYYY"}
            inputStyle={{ color: "black", width: "190px" }}
            clearable={true}
            minDate={"1920-01-01"}
            maxDate={dayjs().add(0, "day")}
            disabled={checkDisable(form?.status)}
            readOnly={false}
          />
        </div>
      </div>
      {form?.dob && (
        <TextField
          title="อายุ"
          disabled={true}
          setField={setField}
          name="age"
          value={
            form?.dob
              ? ageCalculator(
                  form?.dob,
                  form._id ? new Date(form.createdAt) : new Date()
                )
              : form?.age
          }
        />
      )}

      <Dropdown
        title={"ประเภทผู้ป่วย"}
        setField={setField}
        name="patient_type"
        value={form?.patient_type}
        options={OptionsPatientType}
        className={"mx-auto"}
        // disableOther
        other_name={`other_patient_type_name`}
        other_value={form?.other_patient_type_name}
        disabled={checkDisable(form?.status)}
      />

      {form?.patient_type?.value !== "ผู้ป่วยนอก" && (
        <div>
          <Dropdown
            disabled={
              form.status === "on_iv_tube"
                ? form.status === "on_iv_tube"
                : checkDisable(form?.status)
            }
            title="ตึกผู้ป่วย"
            className={"mx-auto"}
            // title="หอผู้ป่วย"
            setField={setField}
            name="option_ward"
            value={form?.option_ward}
            options={OptionWardBuilding}
            other_name={`other_ward_name`}
            other_value={form?.other_ward_name}
          />

          {form?.option_ward?.value && (
            <div>
              {form?.option_ward?.value === "ตึกอื่น ๆ" ? (
                <div>
                  <Dropdown
                    // title="ตึกผู้ป่วย"
                    disabled={
                      form.status === "on_iv_tube"
                        ? form.status === "on_iv_tube"
                        : checkDisable(form?.status)
                    }
                    title="หอผู้ป่วย"
                    setField={setField}
                    name="option_ward_other"
                    value={form?.option_ward_other}
                    options={OptionWardOther}
                    // other_name={`other_ward_name`}
                    // other_value={form?.other_ward_name}
                  />
                  {form?.option_ward_other?.value && (
                    <Dropdown
                      disabled={
                        form.status === "on_iv_tube"
                          ? form.status === "on_iv_tube"
                          : checkDisable(form?.status)
                      }
                      setField={setField}
                      name="ward"
                      value={form?.ward}
                      checkChange={checkChangeWard}
                      setCheckChange={setCheckChangeWard}
                      options={getOptionOther()}
                      // other_name={`other_ward_name`}
                      // other_value={form?.other_ward_name}
                    />
                  )}
                </div>
              ) : (
                form?.option_ward?.value !== "other_location" && (
                  <Dropdown
                    disabled={
                      form.status === "on_iv_tube"
                        ? form.status === "on_iv_tube"
                        : checkDisable(form?.status)
                    }
                    title="หอผู้ป่วย"
                    setField={setField}
                    name="ward"
                    value={form?.ward}
                    checkChange={checkChangeWard}
                    setCheckChange={setCheckChangeWard}
                    options={getOptionWard()}
                    // other_name={`other_ward_name`}
                    // other_value={form?.other_ward_name}
                  />
                )
              )}
            </div>
          )}

          <TextField
            disabled={
              form.status === "on_iv_tube"
                ? form.status === "on_iv_tube"
                : checkDisable(form?.status)
            }
            title="เบอร์โทร"
            setField={setField}
            name="phone_number"
            placeholder="เบอร์โทร"
            value={form?.phone_number}
            // type="Number"
            // max={11}
          />
        </div>
      )}

      <div className="">
        <div className={titleStyle}> วัน/เวลา ที่ได้รับใบปรึกษา</div>
        <div className="text-base w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full  mx-10">
          <WatDatePicker
            value={form?.paper_date ? form?.paper_date : null}
            onChange={handlePaper}
            dateFormat={"yyyy-MM-dd"}
            placeholder={"DD/MM/YYYY"}
            displayFormat={"DD MMMM YYYY"}
            inputStyle={{ color: "black", width: "190px" }}
            clearable={true}
            minDate={"1920-01-01"}
            maxDate={dayjs().add(20, "day")}
            disabled={checkDisable(form?.status)}
            readOnly={false}
          />
        </div>
      </div>
      <TimeDropdown
        setField={setField}
        name="paper_time"
        value={form?.paper_time}
        disabled={disabled ? disabled : checkDisable(form?.status)}
      />

      {/* <InputTime
        setField={setField}
        name="paper_time"
        value={form?.paper_time}
        disabled={disabled ? disabled : checkDisable(form?.status)}
      /> */}

      <div className="">
        <div className={titleStyle}> วันที่นัดใส่สาย</div>
        <div className="text-base w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full  mx-10">
          <WatDatePicker
            value={form?.promise ? form?.promise : null}
            onChange={handlePromise}
            dateFormat={"yyyy-MM-dd"}
            placeholder={"DD/MM/YYYY"}
            displayFormat={"DD MMMM YYYY"}
            inputStyle={{ color: "black", width: "190px" }}
            clearable={true}
            minDate={"1920-01-01"}
            maxDate={dayjs().add(200, "day")}
            disabled={checkDisable(form?.status)}
            readOnly={false}
          />
        </div>
      </div>

      <UploadImage
        title="Upload image (jpg/png)"
        setField={setField}
        name="picture_consultation"
        value={form?.picture_consultation}
        disabled={checkDisable(form?.status)}
      />

      <TextField
        title="ชื่อแพทย์ผู้ส่งปรึกษา  "
        small_title={"(เฉพาะหน่วย hemato ที่ไม่ผ่าน sivwork)"}
        setField={setField}
        name="doctor_request"
        value={form?.doctor_request}
        disabled={checkDisable(form?.status)}
      />
      <TextField
        title="ชื่อพยาบาลผู้ส่งใบปรึกษา "
        small_title={"(เฉพาะหน่วย hemato ที่ไม่ผ่าน sivwork)"}
        setField={setField}
        name="nurse_request"
        value={form?.nurse_request}
        disabled={checkDisable(form?.status)}
      />

      <CheckBoxSelectOne
        classNameTitle="  text-lg text-textinput font-semibold my-5 mx-10"
        classNameCheckAttemp=""
        className="mx-12"
        classInside="flex  space-x-2"
        title={"ชนิดของสายที่ต้องการส่งปรึกษา "}
        setField={setField}
        name="initital_line"
        value={form?.initital_line}
        disabled={disabled}
        label={["DLC 12 Fr", "TLC 7 Fr", "PICC", "อื่นๆ"]}
      />
      {form?.initital_line === "อื่นๆ" && (
        <TextField
          title="โปรดระบุ"
          setField={setField}
          name="initital_line_other"
          value={form?.initital_line_other}
          disabled={checkDisable(form?.status)}
        />
      )}

      {nav === "FULL DATABASE" && (
        <div>
          <div className={lineStyle} />
          <div className={headerStyle}> Patient's Relative</div>
          <TextField
            title="เบอร์โทร"
            setField={setField}
            name="patient_phone_number"
            value={form?.patient_phone_number}
            // type="Number"
            // max={11}
          />
          <TextField
            title="เกี่ยวข้องเป็น"
            setField={setField}
            name="involved"
            value={form?.involved}
          />

          {form?.more_patient?.length > 0 &&
            form?.more_patient
              // ?.slice(1, form?.more_patient?.length)
              ?.map((o, i) => {
                if (i === 0) return null;
                return (
                  <React.Fragment key={i}>
                    <TextField
                      title="เบอร์โทร"
                      name="phone_number"
                      value={o?.phone_number}
                      index={i}
                      primaryField={"more_patient"}
                      setFieldMulti={setFieldMulti}
                      // type="Number"
                      // max={11}
                    />
                    <TextField
                      title="เกี่ยวข้องเป็น"
                      name="relation"
                      index={i}
                      value={o?.relation}
                      primaryField={"more_patient"}
                      setFieldMulti={setFieldMulti}
                    />
                    <div className=" flex justify-center gap-5 py-10">
                      <div
                        onClick={() => removeArray("more_patient", i)}
                        className="cursor-pointer bg-white border-red-600 border-2 hover:bg-red-600 
                        hover:text-gray-50 hover:shadow-lg shadow-md
                          w-full p-2 rounded-md flex justify-center text-red-600 font-bold mt-8"
                      >
                        Delete
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}

          <ButtonAddContact
            action={() =>
              addArray("more_patient", {
                relation: "",
                phone_number: "",
              })
            }
          />

          <div className={lineStyle} />
          <div className={headerStyle}>Main diagnosis</div>
          <TextField
            title="Diagnosis"
            setField={setField}
            name="diagnosis"
            value={form?.diagnosis}
          />
          <TextField
            title="น้ำหนัก"
            setField={setField}
            name="weight"
            value={form?.weight}
            unit="กก."
            type="Number"
          />
          <TextField
            title="ส่วนสูง"
            setField={setField}
            name="height"
            value={form?.height}
            unit="ซม."
            type="Number"
          />
          <CheckBoxMulti
            title="โรคร่วม"
            setField={setField}
            label={[
              "DM",
              "HTN",
              "Dyslipidemia",
              "ESRD",
              "CAD",
              "Malignancy",
              "other",
            ]}
            name={[
              "dm",
              "htn",
              "dyslipidemia",
              "esrd",
              "cad",
              "mailignancy",
              "other_disease",
            ]}
            value={[
              form?.dm,
              form?.htn,
              form?.dyslipidemia,
              form?.esrd,
              form?.cad,
              form?.mailignancy,
              form?.other_disease,
            ]}
            other_name="other_disease_name"
            other_value={form?.other_disease_name}
          />

          <CheckBoxMulti
            title="Indications"
            setField={setField}
            // labelCustom={" md:text-lg xl:text-sm font-normal text-textinput"}
            label={[
              "TPN",
              "Prolonged IV meds",
              "Chemotherapy",
              "Vasopressor",
              "เจาะเลือดบ่อย",
              "Stem cell collection",
              "Stem cell transplant",
              "Other",
            ]}
            name={[
              "tpn",
              "prolonged_iv_meds",
              "chemotherapy",
              "vasopressor",
              "frequent_blood_sampling",
              "stem_cell_collection_plasma_exchange",
              "stem_cell_transplantation",
              "other_indication",
            ]}
            value={[
              form?.tpn,
              form?.prolonged_iv_meds,
              form?.chemotherapy,
              form?.vasopressor,
              form?.frequent_blood_sampling,
              form?.stem_cell_collection_plasma_exchange,
              form?.stem_cell_transplantation,
              form?.other_indication,
            ]}
            other_name="other_indication_name"
            other_value={form?.other_indication_name}
          />

          <Dropdown
            title="Baseline ECG"
            setField={setField}
            name="ecg"
            value={form?.ecg}
            options={OptionECG}
            other_name="other_ecg_name"
            other_value={form?.other_ecg_name}
          />
        </div>
      )}
    </div>
  );
};
export default FormInfo;
