import { titleStyle } from "../../hooks/useClassName";

const CheckBoxSelectOne = ({
  title,
  name,
  value,
  setField,
  primaryField,
  setFieldMulti,
  index,
  classNameTitle,
  label = [],
  disabled = false,
  classNameCheckAttemp,
  setValue = false,
  setStateObject = false,
  classNameCustom,
  className = "md:grid  md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-9 gap-4 my-5 mx-10 ",
  classInside,
}) => {
  const onChangeCheckbox = (e, row) => {
    const thisValue = e.target.checked;
    console.log("THISVALUE", {
      thisValue,
      title,
      name,
      value,
      setField,
      primaryField,
      setFieldMulti,
      index,
      setStateObject,
      label,
    });

    if (setValue && setStateObject) {
      console.log("IN 1");
      setValue((prev) => {
        let temp = { ...prev };
        console.log("temp", temp);
        temp[name] = row;
        return { ...temp };
      });
    } else if (setValue && !setStateObject) {
      console.log("IN 2");

      setValue(name);
    } else if (setField) {
      console.log("IN 3");

      setField(name)(row);
    } else if (primaryField) {
      console.log("IN 4");

      return setFieldMulti(name, primaryField, index)(thisValue);
    }
  };

  return (
    <div>
      <div className={classNameTitle ? classNameTitle : titleStyle}>
        {title}
      </div>
      <div className={classNameCheckAttemp ? classNameCheckAttemp : className}>
        {label?.map((row, index) => {
          return (
            <div className={classInside ? classInside : ""}>
              <input
                key={index}
                type="checkbox"
                name={name}
                checked={row === value}
                onChange={(e) => {
                  onChangeCheckbox(e, row);
                }}
                disabled={disabled}
              />
              <div
                className={
                  classNameCustom
                    ? classNameCustom
                    : " md:text-lg text-base text-textblue "
                }
              >
                {row}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default CheckBoxSelectOne;
