import axios from "axios";

const instance = axios.create({
  // baseURL:
  //   process.env.NODE_ENV === "development"
  //     ? "http://localhost:1337"
  //     : "https://8bubrtk2ac.execute-api.ap-southeast-1.amazonaws.com/dev",
  baseURL: "https://8bubrtk2ac.execute-api.ap-southeast-1.amazonaws.com/dev",
  // baseURL: "http://localhost:1337",
});

instance.interceptors.request.use(async (config) => {
  const newDepartment = localStorage.getItem("department");

  if (newDepartment) {
    config.headers["department"] = newDepartment;
  }
  return config;
});

// export const getJwtBearer = async () => {
//   const {
//     accessToken: { jwtToken },
//   } = await Auth.currentSession();
//   return `Bearer ${jwtToken}`;
// };

export default instance;
