import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import Amplify, { Auth } from "aws-amplify";
import { Storage } from "aws-amplify";
import api from "./api";
import reportWorkerFile from "./pages/report/functions/reportWorker";

//COMPONENT
import Navbar from "./components/attribute/Navbar";

//PAGES
import Info from "./pages/info/InfoMain";
import Login from "./pages/login/Login";
import DetailMain from "./pages/details/DetailMain";
import HomeMain from "./pages/home/HomeMain";

//CONTEXT
import { TaskProvider } from "./contexts/TaskContext";
import { ListDoctorAndNurseProvider } from "./contexts/ListDoctorAndNurseContext";
import { AppProvider } from "./contexts/AppContext";
import ProtectedRoute from "./components/route/protectedRoute";
import { useSelector } from "react-redux";
import { AdminMain } from "./pages/admin/adminMain";
import ForgotPassword from "./pages/login/ForgotPassword";

import JSZip from "jszip";
import { saveAs } from "file-saver";
import HopperMain from "./pages/hopper/HopperMain";
import ExportMain from "./pages/export/Export.Main";
import ReportMain from "./pages/report/report.main";
import TestBot from "./pages/test/test";

Amplify.configure({
  Auth: {
    // identityPoolId: "ap-southeast-1:7f0258fb-1053-4cf4-b8d1-a5e08c340725",
    // region: "ap-southeast-1",
    // userPoolId: "ap-southeast-1_2QI8Ou0gO",
    // userPoolWebClientId: "1f4am34ae9huna91jcsq9q4dpq",
    identityPoolId: "ap-southeast-1:4e7d8e32-60b4-4ddd-82cf-daf37d2bde91",
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_Cq5UPsZPH",
    userPoolWebClientId: "4ds6couto1hjttf524u6khpmtd",
  },
  Storage: {
    // bucket: "alist8d5269d71d0442049bcf82f4e49cf9b2110913-dev",
    bucket: "alist617c86a5c46946738f841c5f0f25dbbf102331-dev",
    region: "ap-southeast-1",
  },
});

function App() {
  const { user } = useSelector((state) => state.auth);

  const userDept = user?.user_department;

  // console.log("user", user);

  // React.useEffect(async () => {
  //   const { data } = await api.get("/profiles/all");
  //   for (const profile of data.profiles) {
  //     await Auth.signUp({
  //       username: `${profile.username}`,
  //       password: `123456qaZ`,
  //       attributes: {
  //         email: `${profile.email}`,
  //         phone_number: `${profile.phone_number}`,
  //         ["custom:fullname"]: `${profile.fullname}`,
  //         ["custom:role"]: `${profile.role}`,
  //       },
  //     });
  //   }
  //   console.log("data", data);
  // });

  // await Auth.signUp({
  //   username: `${form.username}`,
  //   password: `${form.password}`,
  //   attributes: {
  //     email: `${form.email}`,
  //     phone_number: `${format_phone_number}`,
  //     ["custom:fullname"]: `${form.fullname}`,
  //     ["custom:role"]: `${form.role.value}`,
  //   },
  // });

  // React.useEffect(() => {
  //   var zip = new JSZip();
  //   var listUrls = [];
  //   Storage.list("", { level: "public" })
  //     .then(async (items) => {
  //       for (const item of items) {
  //         const file = await Storage.get(`${item.key}`, { download: true });
  //         zip.file(item.key, file.Body);
  //       }
  //       const zipFile = await zip.generateAsync({ type: "blob" });
  //       saveAs(zipFile, "images.zip");
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // }, []);

  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  return (
    <div>
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <meta name="viewport" content="width=device-width" />
      <meta charSet="utf-8" />
      <title>ALiST</title>
      <meta property="og:title" content="ALiST" />
      <meta property="og:description" content="ALiST" />
      <meta property="og:image" content="../Logo/Logo.png" />
      <Router>
        <AppProvider>
          {user && (
            <React.Fragment>
              <Navbar userDept={userDept} />
            </React.Fragment>
          )}
          <Switch>
            <Route exact path="/Login" component={Login} />
            <Route exact path="/ForgotPassword" component={ForgotPassword} />
            <ProtectedRoute exact path="/" component={HomeMain} />
            <TaskProvider>
              <ListDoctorAndNurseProvider>
                <ProtectedRoute exact path="/Admin" component={AdminMain} />
                <ProtectedRoute exact path="/Info" component={Info} />
                <ProtectedRoute exact path="/Info/:id" component={Info} />
                <ProtectedRoute exact path="/Datails" component={DetailMain} />
                <ProtectedRoute exact path="/Report" component={ReportMain} />
                <ProtectedRoute exact path="/Export" component={ExportMain} />
                <Route exact path="/Test" component={TestBot} />

                <ProtectedRoute
                  exact
                  path="/Hopper/:id"
                  component={HopperMain}
                />
              </ListDoctorAndNurseProvider>
            </TaskProvider>
          </Switch>
        </AppProvider>
      </Router>
    </div>
  );
}

export default App;
