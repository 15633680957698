import { Fragment, useEffect, useMemo, useRef, useState, memo } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";

export const CelesTableView = memo(
  ({
    isLoading,
    columns,
    data = [],
    rowAction,
    py = "3",
    sortTable,
    onClickUseIndex = false,
    bgcolor = " text-gray-500 bg-bgseccion",
    bgseccion = "",
    bglightseccion = "",
    hoverbg = " hover:bg-gray-100",
    textcolor = " text-gray-400 ",
    // border = " border border-separate ",
  }) => {
    const tableInstance = useTable({ columns: columns, data: data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      tableInstance;
    const onClickRow = (row, index) => {
      if (rowAction !== undefined) {
        if (onClickUseIndex) {
          return rowAction(index);
        } else {
          return rowAction(row);
        }
      }
    };

    const onClickHeader = (field) => {
      if (sortTable !== undefined) {
        sortTable(field);
      }
    };

    return (
      <div className="bg-white mb-6  flex-shrink-0   ">
        <table
          {...getTableProps()}
          className={[textcolor] + " table md:space-y-6  text-sm thisisatable"}
        >
          <thead className="">
            {headerGroups?.map((headerGroup) => (
              <tr className="  text-sm text-red-500">
                {headerGroup.headers?.map((column, index) => (
                  <th
                    onClick={() => onClickHeader(column.id)}
                    className={
                      "py-3 px-3 " +
                      (index === 0 ? "" : "") +
                      " " +
                      (index === columns.length - 1 ? "" : "") +
                      [bgcolor]
                    }
                  >
                    {
                      <div className=" line-clamp-1">
                        {column.render("Header")}
                      </div>
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="text-textgray text-sm font-medium "
          >
            {rows?.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => onClickRow(row, index)}
                  className={
                    "border-b-2 border-gray-100    " +
                    (index % 2 === 0 ? [bglightseccion] : [bgseccion]) +
                    [hoverbg]
                  }
                >
                  {row.cells?.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          "py-" +
                          [py] +
                          " text-center whitespace-nowrap h-12  px-1 text-xs md:text-base "
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
);
