import ExcelExport from "export-xlsx";

export const ExcelPaperConsult = async (inputdata, year, type, setLoading) => {
  console.log("inputdata", inputdata);
  const SETTINGS_FOR_EXPORT_PAPER = {
    fileName: "ALiST_REPORT_PAPER",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `สรุปจำนวนใบปรึกษาที่ได้รับทั้งหมดต่อปี แยกตามเดือน แยกเป็น เด็กและผู้ใหญ่ ของปี${year?.label} ตามประเภทสาย ${type?.label}   `,
                key: "header_01",
              },
              {
                name: `มกราคม`,
                groupKey: "header_01",
                key: "month_01",
              },
              {
                name: `กุมภาพันธ์`,
                groupKey: "header_01",
                key: "month_02",
              },
              {
                name: `มีนาคม`,
                groupKey: "header_01",
                key: "month_03",
              },
              {
                name: `เมษายน`,
                groupKey: "header_01",
                key: "month_04",
              },
              {
                name: `พฤษภาคม`,
                groupKey: "header_01",
                key: "month_05",
              },
              {
                name: `มิถุนายน`,
                groupKey: "header_01",
                key: "month_06",
              },
              {
                name: `กรกฎาคม`,
                groupKey: "header_01",
                key: "month_07",
              },
              {
                name: `สิงหาคม`,
                groupKey: "header_01",
                key: "month_08",
              },
              {
                name: `กันยายน`,
                groupKey: "header_01",
                key: "month_09",
              },
              {
                name: `ตุลาคม`,
                groupKey: "header_01",
                key: "month_10",
              },
              {
                name: `พฤศจิกายน`,
                groupKey: "header_01",
                key: "month_11",
              },
              {
                name: `ธันวาคม`,
                groupKey: "header_01",
                key: "month_12",
              },
              {
                name: `รวม`,
                groupKey: "header_01",
                key: "total",
              },
            ],

            headerDefinition: [
              {
                name: "ผู้ใหญ่",
                key: "jan_adult",
                groupKey: "month_01",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "jan_kid",
                groupKey: "month_01",
                width: 7,
              },
              {
                name: "ผู้ใหญ่",
                key: "feb_adult",
                groupKey: "month_02",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "feb_kid",
                groupKey: "month_02",
                width: 7,
              },
              {
                name: "ผู้ใหญ่",
                key: "mar_adult",
                groupKey: "month_03",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "mar_kid",
                groupKey: "month_03",
                width: 7,
              },
              {
                name: "ผู้ใหญ่",
                key: "apr_adult",
                groupKey: "month_04",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "apr_kid",
                groupKey: "month_04",
                width: 7,
              },
              {
                name: "ผู้ใหญ่",
                key: "may_adult",
                groupKey: "month_05",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "may_kid",
                groupKey: "month_05",
                width: 7,
              },
              {
                name: "ผู้ใหญ่",
                key: "jun_adult",
                groupKey: "month_06",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "jun_kid",
                groupKey: "month_06",
                width: 7,
              },
              {
                name: "ผู้ใหญ่",
                key: "jul_adult",
                groupKey: "month_07",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "jul_kid",
                groupKey: "month_07",
                width: 7,
              },
              {
                name: "ผู้ใหญ่",
                key: "aug_adult",
                groupKey: "month_08",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "aug_kid",
                groupKey: "month_08",
                width: 7,
              },
              {
                name: "ผู้ใหญ่",
                key: "sep_adult",
                groupKey: "month_09",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "sep_kid",
                groupKey: "month_09",
                width: 7,
              },
              {
                name: "ผู้ใหญ่",
                key: "oct_adult",
                groupKey: "month_10",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "oct_kid",
                groupKey: "month_10",
                width: 7,
              },
              {
                name: "ผู้ใหญ่",
                key: "nov_adult",
                groupKey: "month_11",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "nov_kid",
                groupKey: "month_11",
                width: 7,
              },
              {
                name: "ผู้ใหญ่",
                key: "dec_adult",
                groupKey: "month_12",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "dec_kid",
                groupKey: "month_12",
                width: 7,
              },
              {
                name: "ผู้ใหญ่",
                key: "sum_adult",
                groupKey: "total",
                width: 7,
              },
              {
                name: "เด็ก",
                key: "sum_kid",
                groupKey: "total",
                width: 7,
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT_PAPER, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
