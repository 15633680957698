import { Controllers } from "radium-omnibus";
import React from "react";

const ICON_CHEVRON_LEFT = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  );
};

const ICON_CHEVRON_RIGHT = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.25 4.5l7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};

const StylePegination =
  "  md:flex justify-center text-sm p-3 items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded ";

const RenderEachButton = ({ count, value, setValue }) => {
  return (
    <div
      onClick={() => {
        setValue(count);
      }}
      className={`${
        count === value ? " text-white bg-blue-500" : ""
      } ${StylePegination}`}
    >
      {count}
    </div>
  );
};

const PaginationControllerMemo = React.memo(({ setValue, pageCount }) => {
  return (
    <div className="flex">
      <Controllers
        classNameSuperCore="my-auto flex"
        showError={false}
        showErrorAlert={false}
        fields={[
          {
            name: "page",
            type: "number",
            classNameInput:
              "w-16 mt-auto border h-8 border-gray-400 rounded px-2",
            classNameWrapper: "my-auto",
            limit: pageCount,
            classNameCore: "my-auto",
            min: 1,
            onKeyPress: ({ event, value }) => {
              if (value > 0 && value <= pageCount && event.key === "Enter") {
                setValue(Number(value));
              }
              return;
            },
          },
          {
            type: "submit",
            labelSubmit: "Go",
            classNameSubmit:
              " text-gray-500 border border-gray-400 text-sm px-4 rounded  my-auto ml-1",
            classNameCore: "col-span-1",
            onSubmit: (data) => {
              if (data?.page > 0 && data?.page <= pageCount) {
                setValue(data?.page);
              } else {
                return;
              }
            },
          },
        ]}
      />
    </div>
  );
});

const PaginationMain = ({
  count = 10,
  amount = 10,
  value = 1,
  setValue = () => {},
}) => {
  const pageCount = Math.ceil(count / amount);
  const thisArray = new Array(pageCount >= 6 ? 6 : pageCount).fill("")

  const isDisabled = value === pageCount;

  // console.log("value", value);
  // console.log("thisArray", thisArray);

  return (
    <div className="flex justify-between mx-4 md:mx-8 my-10">
      <div className=" text-xs md:text-sm my-auto">{count} Tasks</div>
      <div className="flex md:flex-row">
        <div className=" flex md:flex-col items-center text-xs md:text-sm text-gray-500">
          <div className="md:flex ">
            <button
              onClick={() => {
                setValue(value - 1);
              }}
              disabled={value === 1}
              className={
                "md:p-1 flex justify-center items-center  cursor-pointer border border-gray-300  text-sm rounded-md " +
                (value === 1 ? "opacity-50" : "")
              }
            >
              <ICON_CHEVRON_LEFT />
            </button>
            <div className="flex rounded-full ">
              {thisArray?.map((_, i) => {
                return (
                  <RenderEachButton
                    key={i}
                    count={i + 1}
                    value={value}
                    setValue={setValue}
                  />
                );
              })}
            </div>
            <button
              onClick={() => {
                setValue(value + 1);
              }}
              disabled={isDisabled}
              className={`${
                isDisabled
                  ? "md:p-1 flex justify-center items-center  cursor-pointer border border-gray-300 text-sm rounded-md opacity-50 "
                  : "text-sm flex justify-center items-center cursor-pointer border border-gray-300 md:p-1   rounded "
              }`}
            >
              <div className="my-auto"> </div>
              <ICON_CHEVRON_RIGHT />
            </button>
          </div>
        </div>
        <div className="my-auto mx-2 text-xs md:text-sm">
          Total {pageCount} pages
        </div>
        <div className="flex space-x-2">
          <div className="my-auto text-xs md:text-sm ml-2"> Go to Page</div>
          <PaginationControllerMemo setValue={setValue} pageCount={pageCount} />
        </div>
      </div>
    </div>
  );
};

export default PaginationMain;
