import ExcelExport from "export-xlsx";

export const ExcelWeight = async (inputdata, year, type, setLoading) => {
  // const inputdata = { data: [{ test: 5 }] };
  const SETTINGS_FOR_EXPORT = {
    // Table settings
    fileName: "ALiST_REPORT_WEIGHT",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `น้ำหนักตัวผู้ป่วย แยกตามปี ${year?.label} ตามประเภทสาย ${type?.label} `,
                key: "header_01",

                style: { fill: { fgColor: { rgb: "FF0000" } } },
              },
            ],
            headerDefinition: [
              {
                name: "Mean",
                key: "mean",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "Median",
                key: "median",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "Min",
                key: "min",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "Max",
                key: "max",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "SD",
                key: "sd",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "n",
                key: "number",
                groupKey: "header_01",
                width: 20,
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
