import React, { useMemo } from "react";
import { Doughnut, defaults } from "react-chartjs-2";

const DonutChart = ({ dataChart, chartName, position = "right" }) => {
  const options = {
    title: {
      display: chartName ? true : false,
      text: chartName ? chartName : "ChartName",
      fontSize: 20,
    },
    plugins: {
      legend: {
        position: position,
      },
    },
  };

  const data = () => {
    return {
      labels: [
        "รอใส่สาย",
        "ใส่สายอยู่",
        "ถอดสายแล้ว",
        "เสียชีวิตหรือขาดการติดต่อ",
        "Central Line",
        "ไม่ดำเนินการต่อ",
      ],
      datasets: [
        {
          label: "Job Status",
          data: [
            dataChart?.waiting,
            dataChart?.on_iv_tube,
            dataChart?.completed,
            dataChart?.deceased,
            dataChart?.unfollow,
            dataChart?.keep_for_report,
          ],
          backgroundColor: [
            "rgb(54, 123, 245)",
            "rgb(255,185,70)",
            "rgb(46, 212, 122)",
            "rgb(234, 45, 47)",
            "rgb(148,0,211)",
            "rgb(199,21,133)",
          ],
          hoverOffset: 4,
        },
      ],
    };
  };

  return (
    <div className=" max-w-md lg:max-w-lg mx-auto w-full p-4  md:p-1 lg:p-1 xl:p-0">
      <Doughnut data={data} options={options} />
    </div>
  );
};
export default DonutChart;
