import { set } from "lodash";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const followUpStore = create(
  persist(
    (set) => ({
      followUpTask: null,
      setFollowUpTask: (followUpTask) => set({ followUpTask: followUpTask }),
      clearFollowUpTask: () => set({ followUpTask: null }),
    }),
    {
      name: "followUpStore",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default followUpStore;
