import ExcelExport from "export-xlsx";

export const ExcelReasonByStatus = async (inputdata, year, setLoading) => {
  console.log("inputdata", inputdata);
  // const inputdata = { data: [{ test: 5 }] };
  const SETTINGS_FOR_EXPORT = {
    // Table settings
    fileName: "ALiST_REPORT",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: ` จำนวนสถานะผู้ป่วยที่ถอดสายแล้ว ของปี ${year?.label} `,
                key: "header_1",
              },
            ],
            headerDefinition: [
              {
                name: `Alive`,
                groupKey: "header_1",
                key: "alive",
              },
              {
                name: `Alive`,
                groupKey: "header_1",
                key: "alive_percent",
              },
              {
                name: `Deceased`,
                groupKey: "header_1",
                key: "dead",
              },
              {
                name: `Deceased`,
                groupKey: "header_1",
                key: "dead_percent",
              },
              {
                name: `รวม`,
                groupKey: "header_1",
                key: "sum",
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
