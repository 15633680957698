export const generateFunctionFromData = (
  maxAttempt,
  startMonthNew,
  startYearNew,
  endMonthNew,
  endYearNew,
  type
) => {
  const generateColumnAttempt = () => {
    const items = [];
    for (let i = 0; i < maxAttempt; i++) {
      items.push(
        {
          name: `#${i + 1} Attending`,
          key: `attempt_name_${i + 1}`,
          groupKey: "header_01",
          width: 18,
        },
        {
          name: `#${i + 1} Fellow`,
          key: `use_fellow_${i + 1}`,
          groupKey: "header_01",
          width: 18,
        },
        {
          name: `#${i + 1} Resident`,
          key: `use_resident_${i + 1}`,
          groupKey: "header_01",
          width: 18,
        },
        {
          name: `#${i + 1} Other operator`,
          key: `use_operater_${i + 1}`,
          groupKey: "header_01",
          width: 18,
        },
        {
          name: `#${i + 1} Side`,
          key: `use_side_${i + 1}`,
          groupKey: "header_01",
          width: 5,
        },
        {
          name: `#${i + 1} Limb`,
          key: `use_limb_${i + 1}`,
          groupKey: "header_01",
          width: 10,
        },
        {
          name: `#${i + 1} Pneumothorax`,
          key: `use_pneumothorax_${i + 1}`,
          groupKey: "header_01",
          width: 5,
        },
        {
          name: `#${i + 1} Hemothorax`,
          key: `use_hemothorax_${i + 1}`,
          groupKey: "header_01",
          width: 5,
        },
        {
          name: `#${i + 1} Arterial puncture`,
          key: `use_arterial_${i + 1}`,
          groupKey: "header_01",
          width: 5,
        },
        {
          name: `#${i + 1} Paresthesia`,
          key: `use_paresthesia_${i + 1}`,
          groupKey: "header_01",
          width: 5,
        },
        {
          name: `#${i + 1} Nerve injury`,
          key: `use_nerve_${i + 1}`,
          groupKey: "header_01",
          width: 5,
        },
        // {
        //   name: `#${i + 1} Limb ischemia related to arterial puncture`,
        //   key: `use_limbischemia_${i + 1}`,
        //   groupKey: "header_01",
        //   width: 5,
        // },
        {
          name: `#${i + 1} other complication`,
          key: `use_other_com_${i + 1}`,
          groupKey: "header_01",
          width: 5,
        },
        {
          name: `#${i + 1}other complication detail`,
          key: `use_other_com_detail_${i + 1}`,
          groupKey: "header_01",
          width: 10,
        },
        {
          name: `#${i + 1} Date`,
          key: `use_date_${i + 1}`,
          groupKey: "header_01",
          width: 10,
        },
        {
          name: `#${i + 1} Attempt start`,
          key: `attempt_start_${i + 1}`,
          groupKey: "header_01",
          width: 10,
        },
        {
          name: `#${i + 1} Attempt end`,
          key: `attempt_end_${i + 1}`,
          groupKey: "header_01",
          width: 10,
        },
        {
          name: `#${i + 1} Time start-end (min)`,
          key: `start_to_end_${i + 1}`,
          groupKey: "header_01",
          width: 10,
        },
        {
          name: `#${i + 1} Puncture`,
          key: `puncture_${i + 1}`,
          groupKey: "header_01",
          width: 10,
        },
        {
          name: `#${i + 1} Successful`,
          key: `successful_${i + 1}`,
          groupKey: "header_01",
          width: 10,
        },
        {
          name: `#${i + 1} Line finalized`,
          key: `line_finalized_${i + 1}`,
          groupKey: "header_01",
          width: 10,
        },
        {
          name: `#${i + 1} Time puncture-line finalized (min)`,
          key: `punct_to_final_${i + 1}`,
          groupKey: "header_01",
          width: 10,
        },
        {
          name: `#${i + 1} Procedure Note`,
          key: `procedure_note_${i + 1}`,
          groupKey: "header_01",
          width: 10,
        }
      );
    }

    return items;
  };

  //   console.log("generateColumnAttempt", generateColumnAttempt(2));
  let countIndex = 0;

  return {
    // Table settings
    fileName: "ALiST_INDIVIDUAL",
    workSheets: [
      {
        sheetName: "Report1",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              {
                name: `ข้อมูลกลุ่ม ${type?.label} (full v.1) ตั้งแต่ ${startMonthNew?.label} ${startYearNew?.label} ถึง ${endMonthNew?.label} ${endYearNew?.label} `,
                key: "header_01",

                style: { fill: { fgColor: { rgb: "FF0000" } } },
              },
            ],
            headerDefinition: [
              {
                name: "No.",
                key: "id_picc_new",
                groupKey: "header_01",
                width: 15,
              },
              {
                name: "ID",
                key: "id",
                groupKey: "header_01",
                width: 25,
              },
              {
                name: "HN",
                key: "hn",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "ชื่อ",
                key: "full_name",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "นามสกุล",
                key: "last_name",
                groupKey: "header_01",
                width: 20,
              },

              {
                name: "เพศ",
                key: "gender",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "DOB",
                key: "DOB",
                groupKey: "header_01",
                width: "15",
              },
              {
                name: "อายุ",
                key: "age_old",
                groupKey: "header_01",
                width: 17,
              },
              {
                name: "อายุ(ปี)",
                key: "age",
                groupKey: "header_01",
                width: 10,
              },

              {
                name: "สถานะ",
                key: "format_status",
                groupKey: "header_01",
                width: 25,
              },
              {
                name: "ประเภทผู้ป่วย",
                key: "patient_type",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "ตึกผู้ป่วย",
                key: "ward1",
                groupKey: "header_01",
                width: 15,
              },
              {
                name: "หอผู้ป่วย",
                // key: "ward2",
                key: "first_ward",
                groupKey: "header_01",
                width: 10,
              },

              {
                name: "วันที่ได้รับใบปรึกษา",
                key: "paper_date",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "เวลาที่ได้รับใบปรึกษา",
                key: "paper_time",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "วันที่นัดใส่สาย",
                key: "promise_date",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "เบอร์โทรญาติ",
                key: "phone_more",
                groupKey: "header_01",
                width: 15,
              },
              {
                name: "ญาติเกี่ยวข้องเป็น",
                key: "ralation_more",
                groupKey: "header_01",
                width: 10,
              },

              {
                name: "Main diagnosis",
                key: "diagnosis",
                groupKey: "header_01",
                width: 30,
              },
              {
                name: "น้ำหนัก",
                key: "weight",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "ส่วนสูง",
                key: "height",
                groupKey: "header_01",
                width: 10,
              },
              // โรคร่วม
              {
                name: "DM",
                key: "DM",
                groupKey: "header_01",
                width: 5,
              },
              {
                name: "HTN",
                key: "HTN",
                groupKey: "header_01",
                width: 5,
              },
              {
                name: "Dyslipidemia",
                key: "Dyslipidemia",
                groupKey: "header_01",
                width: 5,
              },
              {
                name: "ESRD",
                key: "ESRD",
                groupKey: "header_01",
                width: 5,
              },
              {
                name: "CAD",
                key: "CAD",
                groupKey: "header_01",
                width: 5,
              },
              {
                name: "Malignancy",
                key: "Malignancy",
                groupKey: "header_01",
                width: 5,
              },
              {
                name: "other_UD",
                key: "other_disease",
                groupKey: "header_01",
                width: 5,
              },
              {
                name: "other_UD_detail",
                key: "other_disease_name",
                groupKey: "header_01",
                width: 15,
              },
              //  Indications

              {
                name: "TPN",
                key: "TPN",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Prolonged IV meds",
                key: "Prolonged IV meds",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Chemotherapy",
                key: "Chemotherapy",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Vasopressor",
                key: "Vasopressor",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "เจาะเลือดบ่อย",
                key: "เจาะเลือดบ่อย",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Stem cell/Plaxma Exc",
                key: "Stem cell/ Plasma Exc.",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Stem cell Tx",
                key: "Stem cell Tx",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Other_indication",
                key: "other_indication",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Other_indication_detail",
                key: "other_indication_name",
                groupKey: "header_01",
                width: 15,
              },
              {
                name: "Baseline ECG",
                key: "ECG",
                groupKey: "header_01",
                width: 15,
              },
              {
                name: "Baseline ECG_other",
                key: "ECG_NAME",
                groupKey: "header_01",
                width: 10,
              },

              //
              {
                name: "location",
                key: "location_porcidure",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "ชนิดของสาย",
                key: "catheter",
                groupKey: "header_01",
                width: 20,
              },
              //
              {
                name: "Final catheter in (Lt/Rt)",
                key: "side",
                groupKey: "header_01",
                width: 10,
              },
              //
              {
                name: "Final catheter in ",
                key: "catheter_in",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "Indwelled ",
                key: "use_indwelled",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "จำนวนครั้งที่ทำหัตการ",
                key: "count_of_attempts",
                groupKey: "header_01",
                width: 10,
              },
              // คอลัมน์ attempt
              ...generateColumnAttempt(),
              //

              {
                name: "Total Procedure time (min)",
                key: "total_time_room",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "เวลาตั้งแต่เริ่มจนเสร็จ (นาที)",
                key: "total_all_time",
                groupKey: "header_01",
                width: 10,
              },

              /////////////////

              {
                name: "confirmation",
                key: "confirmation",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Exit site mark",
                key: "exit_site_mark",
                groupKey: "header_01",
                width: 10,
              },

              {
                name: "insertion technique",
                key: "insertion_technique",
                groupKey: "header_01",
                width: 25,
              },
              {
                name: "X-Rays Date",
                key: "x_ray_date",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Upper/Lower torso",
                key: "upper_lower_torso",
                groupKey: "header_01",
                width: 10,
              },

              {
                name: "Tip of catheter (Upper torso catheter)",
                key: "tip_of_catheter",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Tip of catheter (Lower torso catheter)",
                key: "tip_of_catheter_lower",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Unintended position",
                key: "unintended_position",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "ภาวะแทรกซ้อน",
                key: "complications",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Other details related to procedure",
                key: "related_produre",
                groupKey: "header_01",
                width: 15,
              },

              {
                name: "Attending",
                key: "use_doctor",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "Nurse",
                key: "use_nurse",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "Post insertion CXR checked by",
                key: "post_cxr",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "ระยะเวลารอใส่สาย",
                // key: "total_time",
                key: "cal_total_wait_hour_min",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "ระยะเวลารอใส่สาย(ชั่วโมง)",
                // key: "total_time",
                key: "cal_total_wait_hour",
                groupKey: "header_01",
                width: 10,
              },

              {
                name: "จำนวนครั้งที่ follow up",
                key: "count_of_follow",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "การติดตาม",
                key: "by",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Line Patency",
                key: "line_patency",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Line Infection",
                key: "line_infection",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Line displacement /dislodged",
                key: "line_displacement",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Catheter-related limb edema",
                key: "catheter_related",
                groupKey: "header_01",
                width: 10,
              },

              {
                name: "Presence of DVT",
                key: "DVT",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "management",
                key: "management",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "other",
                key: "other",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Exit Site Infection",
                key: "exit_site_infection",
                groupKey: "header_01",
                width: 10,
              },

              {
                name: "Total(days)",
                key: "totalDay",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "วันที่ใส่สาย",
                key: "use_final_date",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "วันที่ถอดสาย",
                key: "use_done",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Outcome (Alive/deceased)",
                key: "outcome",
                groupKey: "header_01",
                width: 10,
              },

              {
                name: "Reasons for discontinuation",
                key: "reason",
                groupKey: "header_01",
                width: 10,
              },

              {
                name: "ความพึงพอใจของญาติ",
                key: "relative_satisfaction",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "ความพึงพอใจของบุคลากรดูแลผู้ป่วย",
                key: "satisfaction",
                groupKey: "header_01",
                width: 10,
              },
            ],
          },
        },
      },
    ],
  };
};

export const formatExcelKeep = (startMonth, startYear, endMonth, endYear) => {
  return {
    // Table settings
    fileName: "ALiST_INDIVIDUAL",
    workSheets: [
      {
        sheetName: "Report1",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              {
                name: `ข้อมูลกลุ่มไม่ดำเนินการต่อ ตั้งแต่ ${startMonth?.label} ${startYear?.label} ถึง ${endMonth?.label} ${endYear?.label} `,
                key: "header_01",

                style: { fill: { fgColor: { rgb: "FF0000" } } },
              },
            ],
            headerDefinition: [
              {
                name: "No.",
                key: "number",
                groupKey: "header_01",
                width: 6,
              },
              {
                name: "ID",
                key: "id",
                groupKey: "header_01",
                width: 25,
              },
              {
                name: "HN",
                key: "hn",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "ชื่อ",
                key: "full_name",
                groupKey: "header_01",
                width: 20,
              },
              {
                name: "นามสกุล",
                key: "last_name",
                groupKey: "header_01",
                width: 20,
              },

              {
                name: "สถานะ",
                key: "status",
                groupKey: "header_01",
                width: 16,
              },
              {
                name: "ประเภทผู้ป่วย",
                key: "patient_type",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "ตึกผู้ป่วย",
                key: "ward1",
                groupKey: "header_01",
                width: 15,
              },
              {
                name: "หอผู้ป่วย",
                key: "ward2",
                // key: "first_ward",
                groupKey: "header_01",
                width: 20,
              },

              {
                name: "วันที่ได้รับใบปรึกษา",
                key: "paper_date",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "เวลาที่ได้รับใบปรึกษา",
                key: "paper_time",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "วันที่นัดใส่สาย",
                key: "promise_date",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "วันที่ยกเลิก",
                key: "cancel_date",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Other",
                key: "other_des",
                groupKey: "header_01",
                width: 40,
              },
              {
                name: "เหตุผลที่ยกเลิก",
                key: "reasons",
                groupKey: "header_01",
                width: 25,
              },
            ],
          },
        },
      },
    ],
  };
};
