export const formatColorFlag = (number, setCodeColor, setIDFlag) => {
  console.log("number", number);
  if (number === -1) {
    //none
    setCodeColor("none");
    setIDFlag("63bd37518649caa59294c009");
    return {
      codeColor: "TEST",
      idFlag: "63bd37518649caa59294c009",
    };
  } else if (number === 0) {
    setCodeColor("#5ec268");
    setIDFlag("63bd37118649caa59294bff7");
    return {
      codeColor: "#5ec268",
      idFlag: "63bd37118649caa59294bff7",
    };
  } else if (number === 1) {
    setCodeColor("#FFCC00");
    setIDFlag("63bd371f8649caa59294bff9");
    return {
      codeColor: "#FFCC00",
      idFlag: "63bd371f8649caa59294bff9",
    };
  } else if (number === 2) {
    setCodeColor("#FF6600");
    setIDFlag("63bd37248649caa59294bffb");
    return {
      codeColor: "#FF6600",
      idFlag: "63bd37248649caa59294bffb",
    };
  } else if (number === 3) {
    setCodeColor("#FF0000");
    setIDFlag("63bd372a8649caa59294bffd");
    return {
      codeColor: "#FF0000",
      idFlag: "63bd372a8649caa59294bffd",
    };
  } else if (number === 4) {
    setCodeColor("#660099");
    setIDFlag("63bd372f8649caa59294bfff");
    return {
      codeColor: "#660099",
      idFlag: "63bd372f8649caa59294bfff",
    };
  } else if (number === 5) {
    setCodeColor("#0000FF");
    setIDFlag("63bd37348649caa59294c001");
    return {
      codeColor: "#0000FF",
      idFlag: "63bd37348649caa59294c001",
    };
  } else if (number === 6) {
    setCodeColor("#66CCFF");
    setIDFlag("63bd37398649caa59294c003");
    return {
      codeColor: "#66CCFF",
      idFlag: "63bd37398649caa59294c003",
    };
  } else if (number === 7) {
    setCodeColor("#FF0099");
    setIDFlag("63bd37418649caa59294c005");
    return {
      codeColor: "#FF0099",
      idFlag: "63bd37418649caa59294c005",
    };
  } else if (number === 8) {
    setCodeColor("#FF99FF");
    setIDFlag("63bd37478649caa59294c007");
    return {
      codeColor: "#FF99FF",
      idFlag: "63bd37478649caa59294c007",
    };
  } else if (number === 9) {
    setCodeColor("#000000");
    setIDFlag("63bd37518649caa59294c009");
    return {
      codeColor: "#000000",
      idFlag: "63bd37518649caa59294c009",
    };
  } else if (number === 10) {
    //none
    setCodeColor("none");
    setIDFlag("63bd37578649caa59294c00b");
    return {
      codeColor: "none",
      idFlag: "63bd37578649caa59294c00b",
    };
  } else {
    return;
  }
};

export const formatColorFlagNew = (number, setCodeColor, setIDFlag) => {
  console.log("number", number);
  if (number === -1) {
    //none
    setCodeColor("none");
    setIDFlag("63bd37518649caa59294c009");
    return {
      codeColor: "TEST",
      idFlag: "63bd37518649caa59294c009",
    };
  } else if (number === 0) {
    setCodeColor("#FFCC00");
    setIDFlag("63bd371f8649caa59294bff9");
    return {
      codeColor: "#FFCC00",
      idFlag: "63bd371f8649caa59294bff9",
    };
  } else if (number === 1) {
    setCodeColor("#FF0000");
    setIDFlag("63bd372a8649caa59294bffd");
    return {
      codeColor: "#FF0000",
      idFlag: "63bd372a8649caa59294bffd",
    };
  } else if (number === 2) {
    setCodeColor("#FF0099");
    setIDFlag("63bd37418649caa59294c005");
    return {
      codeColor: "#FF0099",
      idFlag: "63bd37418649caa59294c005",
    };
  } else if (number === 3) {
    setCodeColor("#66CCFF");
    setIDFlag("63bd37398649caa59294c003");
    return {
      codeColor: "#66CCFF",
      idFlag: "63bd37398649caa59294c003",
    };
  } else if (number === 4) {
    setCodeColor("#0000FF");
    setIDFlag("63bd37348649caa59294c001");
    return {
      codeColor: "#0000FF",
      idFlag: "63bd37348649caa59294c001",
    };
  } else if (number === 5) {
    setCodeColor("none");
    setIDFlag("63bd37578649caa59294c00b");
    return {
      codeColor: "none",
      idFlag: "63bd37578649caa59294c00b",
    };
  } else {
    return;
  }
};
