import { titleStyle } from "../../hooks/useClassName";

const TextField = ({
  value,
  title,
  disabled,
  type = "text",
  className = " text-base mx-10 md:mx-10 lg:mx-10 xl:mx-10 w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full  ",
  placeholder,
  setField,
  name,
  classNameTitle,
  unit,
  setFieldMulti,
  primaryField,
  index = 0,
  max = 256,
  setValue = false,
  setStateObject = false,
  subtitle,
  small_title = "",
}) => {
  const onChange = (e) => {
    const value = e.target.value;
    // console.log("POTOPS : ", {
    //   value,
    //   name,
    // });
    // console.log(value);
    // console.log(max);
    if (value.length >= max) return;

    if (setValue && setStateObject) {
      setValue((prev) => {
        let temp = { ...prev };
        temp[name] = value;
        return { ...temp };
      });
    } else if (setFieldMulti && primaryField && index) {
      setFieldMulti(name, primaryField, index)(value);
    } else if (setField) {
      setField(name)(value);
    } else if (setValue) {
      setValue(value);
    } else {
      return;
    }
  };
  return (
    <div className="my-4">
      <div className={classNameTitle ? classNameTitle : titleStyle}>
        <div>
          {title} <span className="text-base">{small_title}</span>
        </div>
        <div className="md:text-lg text-lg text-textinput font-semibold my-2">
          {subtitle}
        </div>
      </div>
      <div className={unit ? "flex" : ""}>
        <div className=" rounded-md ">
          <input
            className={className}
            disabled={disabled}
            value={value}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
          />
        </div>
        {unit && <div className="my-auto -ml-7 text-textinput ">{unit}</div>}
      </div>
    </div>
  );
};
export default TextField;
