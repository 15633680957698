import { ageDayCalculator } from "../../../components/functions/Functions";
import { getTotalDaysInMonth } from "../../../utils/dateFormater";
import { getUserDepartment } from "../../../utils/formatUserDepartment";
import FunctionCheckType from "./Report.FunctionCheckType";

const CountDepartment = ({
  list,
  setValue,
  startYear,
  endYear,
  startMonth,
  endMonth,
}) => {
  let anAdult = 0;
  let anKid = 0;

  let irAdult = 0;
  let irKid = 0;

  let trAdult = 0;
  let trKid = 0;

  let pedAdult = 0;
  let pedKid = 0;

  const endDay = getTotalDaysInMonth(Number(endMonth), Number(endYear));

  const startDateValue = new Date(
    `${startYear}-${startMonth.padStart(2, "0")}-01`
  ).getTime();
  const endDateValue = new Date(
    `${endYear}-${endMonth.padStart(2, "0")}-${endDay}`
  ).getTime();

  const temp = [...list];

  const filterList = temp.filter((item) => {
    const date = new Date(item?.final_date_success).getTime(); // item?.final_date_success;
    return date >= startDateValue && date <= endDateValue;
  });

  const isAll =
    startYear === "ALL" ||
    endYear === "ALL" ||
    startMonth === "ALL" ||
    endMonth === "ALL";

  const finalList = isAll ? temp : filterList;

  if (finalList?.length > 0) {
    for (let row of finalList) {
      const isAdult =
        ageDayCalculator(new Date(row?.dob), new Date(row?.paper_date)) >= 6570;
      const isKid = !isAdult; // Simplified to the negation of isAdult

      const catheterType = FunctionCheckType(
        row?.catheter?.value,
        row?.other_catheter_name,
        row?.check_catheter
      );

      if (
        catheterType === "PICC" &&
        row?.final_date_success &&
        row?.dob
        // && (year === "ALL" || year === useYear)
      ) {
        const userDepartment = row?.user_department;

        if (isAdult && userDepartment === "AN") {
          anAdult++;
        }
        if (isKid && userDepartment === "AN") {
          anKid++;
        }
        if (isAdult && userDepartment === "IR") {
          irAdult++;
        }
        if (isKid && userDepartment === "IR") {
          irKid++;
        }
        if (isAdult && userDepartment === "TR") {
          trAdult++;
        }
        if (isKid && userDepartment === "TR") {
          trKid++;
        }
        if (isAdult && userDepartment === "PED") {
          pedAdult++;
        }
        if (isKid && userDepartment === "PED") {
          pedKid++;
        }
      }
    }
  }

  setValue({
    an: {
      adult: anAdult,
      kid: anKid,
      total: anAdult + anKid,
    },
    ir: {
      adult: irAdult,
      kid: irKid,
      total: irAdult + irKid,
    },
    tr: {
      adult: trAdult,
      kid: trKid,
      total: trAdult + trKid,
    },
    ped: {
      adult: pedAdult,
      kid: pedKid,
      total: pedAdult + pedKid,
    },
    total: {
      adult: anAdult + irAdult + trAdult + pedAdult,
      kid: anKid + irKid + trKid + pedKid,
      total:
        anAdult + anKid + irAdult + irKid + trAdult + trKid + pedAdult + pedKid,
      an: anAdult + anKid,
      ir: irAdult + irKid,
      tr: trAdult + trKid,
      ped: pedAdult + pedKid,
    },
  });
};

export default CountDepartment;
