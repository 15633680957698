import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { titleStyle } from "../../hooks/useClassName";

function range() {
  const size = 120;
  const objDate = new Date().getFullYear();
  const startAt = objDate - size + 1;
  return [...Array(size).keys()].map((i) => i + startAt);
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const years = range();

const DatePickerYear = ({
  name,
  value,
  setField,
  showTime,
  title,
  classNameTitle,
  disabled = false,
}) => {
  const onChange = (date) => {
    setField(name)(date);
  };
  const RenderPicker = forwardRef((props, ref) => {
    const { onClick, value, label } = props;

    return (
      <div className="w-full flex-1 mb-2">
        <div className={classNameTitle ? classNameTitle : titleStyle}>
          {label}
        </div>
        <div
          onClick={onClick}
          className="bg-white  my-0 p-2 mt-1 flex border border-gray-200 rounded  appearance-none outline-none w-60 text-gray-800 mx-10"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          {value}
        </div>
      </div>
    );
  });
  return (
    // <DatePicker
    // renderCustomHeader={({
    //   changeYear
    // })}
    //   locale="th"
    //   dateFormat={"dd/MM/yyyy" + (showTime ? " HH:mm " : "")}
    //   showTimeSelect={showTime}
    //   timeFormat="HH:mm"
    //   readOnly={disabled}
    //   timeIntervals={15}
    //   selected={

    //     value === undefined
    //       ? null
    //       : typeof value === "object"
    //       ? value
    //       : new Date(value)
    //   }
    //   onChange={onChange}
    //   customInput={<RenderPicker label={title} />}
    // />
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="flex justify-center my-2">
          <button
            className="mx-2 border border-gray-400 px-2"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            {"<"}
          </button>
          <select
            value={new Date(date).getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[new Date(date).getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            className="mx-2 border border-gray-400 px-2"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            {">"}
          </button>
        </div>
      )}
      selected={value}
      readOnly={disabled}
      onChange={onChange}
      customInput={<RenderPicker label={title} />}
    />
  );
};

export default DatePickerYear;
