export const calculateSum = (numbers) => {
  const number = numbers.filter((value) => value !== 0);
  const sum = number.reduce((acc, num) => acc + num, 0);
  return sum !== "NaN" ? sum : 0;
};

export const calculateAverage = (numbers) => {
  const number = numbers.filter((value) => value !== 0);
  const sum = number.reduce((acc, num) => acc + num, 0);
  const ans = sum / number.length;
  const thisAns = ans.toFixed(2);
  // console.log("numbers", numbers);

  return thisAns !== "NaN" ? Number(thisAns) : 0;
};

export const calculateMedian = (numbers) => {
  const number = numbers.filter((value) => value !== 0);

  const sortedNumbers = number.sort((a, b) => a - b);
  const length = sortedNumbers.length;

  if (length === 0) {
    return null;
  }

  const middleIndex = Math.floor(length / 2);

  if (length % 2 === 0) {
    const median =
      (sortedNumbers[middleIndex - 1] + sortedNumbers[middleIndex]) / 2;
    return median.toFixed(2);
  } else {
    const median = sortedNumbers[middleIndex];
    return median.toFixed(2);
  }
};
export const calculateMode = (numbers) => {
  const arr = numbers.filter((value) => value !== 0);
  const frequency = {};
  let maxFrequency = 0;
  let mode = [];

  // Count the frequency of each element
  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];
    frequency[element] = frequency[element] ? frequency[element] + 1 : 1;
    if (frequency[element] > maxFrequency) {
      maxFrequency = frequency[element];
    }
  }

  // Find the elements with the maximum frequency
  for (const key in frequency) {
    if (frequency.hasOwnProperty(key)) {
      if (frequency[key] === maxFrequency) {
        mode.push(key);
      }
    }
  }

  return mode;
};
export const calculatePercentile25 = (numbers) => {
  const data = numbers.filter((value) => value !== 0);
  // Step 1: Sort the data in ascending order
  const sortedData = data.sort((a, b) => a - b);

  // Step 2: Calculate the position of the 25th percentile
  const position = Math.ceil((25 / 100) * (sortedData.length + 1));

  // Step 3: Calculate the 25th percentile value
  let percentileValue;
  if (Number.isInteger(position)) {
    percentileValue = sortedData[position - 1];
  } else {
    const lowerValue = sortedData[Math.floor(position) - 1];
    const upperValue = sortedData[Math.ceil(position) - 1];
    percentileValue = (lowerValue + upperValue) / 2;
  }

  return percentileValue;
};
export const calculatePercentile75 = (numbers) => {
  const data = numbers.filter((value) => value !== 0);

  // Step 1: Sort the data in ascending order
  const sortedData = data.sort((a, b) => a - b);

  // Step 2: Calculate the position of the 75th percentile
  const position = Math.ceil((75 / 100) * (sortedData.length + 1));

  // Step 3: Calculate the 75th percentile value
  let percentileValue;
  if (Number.isInteger(position)) {
    percentileValue = sortedData[position - 1];
  } else {
    const lowerValue = sortedData[Math.floor(position) - 1];
    const upperValue = sortedData[Math.ceil(position) - 1];
    percentileValue = (lowerValue + upperValue) / 2;
  }

  return percentileValue;
};

export const calculateStandardDeviation = (numbers) => {
  const data = numbers.filter((value) => value !== 0);

  // Step 1: Calculate the mean
  const mean = data.reduce((sum, value) => sum + value, 0) / data.length;

  // Step 2: Calculate the sum of squared differences from the mean
  const squaredDifferences = data.map((value) => Math.pow(value - mean, 2));
  const sumOfSquaredDifferences = squaredDifferences.reduce(
    (sum, value) => sum + value,
    0
  );

  // Step 3: Calculate the variance
  const variance = sumOfSquaredDifferences / data.length;

  // Step 4: Calculate the standard deviation
  const standardDeviation = Math.sqrt(variance);

  return standardDeviation.toFixed(2);
};
