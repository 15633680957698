const FollowUpPopUp = ({
  wellReceived,
  setWellReceived,
  totalAbnormalFollow,
  selectedNames,
  onConfirmAcknowlege = () => {},
  handleCancel = () => {},
}) => {
  return (
    <div>
      {wellReceived && (
        <div
          class="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div class="bg-white py-12 px-4  ">
                  <div class=" text-center sm:ml-4 sm:mt-0 sm:text-left text-[#2E4255]">
                    <h3
                      class="text-base font-semibold leading-6 w-full text-center"
                      id="modal-title"
                    >
                      {totalAbnormalFollow === 1
                        ? `คุณรับทราบเเล้วว่าผู้ป่วย  ${selectedNames[0]} `
                        : `คุณรับทราบเเล้วว่าผู้ป่วยที่เลือกไว้ทั้งหมด ${totalAbnormalFollow} ราย`}
                    </h3>
                    <h3 className="text-base font-semibold w-full text-center">
                      {" "}
                      มีการแจ้งสายผิดปกติ
                    </h3>
                    {/* <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          Are you sure you want to deactivate your account? All
                          of your data will be permanently removed. This action
                          cannot be undone.
                        </p>
                      </div> */}
                  </div>
                </div>
                <div class=" px-4 py-3 sm:flex space-x-2 justify-center items-center sm:px-6 pb-10">
                  <button
                    type="button"
                    class="inline-flex w-full justify-center  bg-bgbluealist px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={onConfirmAcknowlege}
                  >
                    รับทราบ
                  </button>
                  <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center r bg-white px-3 py-2 text-sm font-semibold text-bgbluealist shadow-sm ring-1 ring-inset ring-bgbluealist hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={handleCancel}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FollowUpPopUp;
