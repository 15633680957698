import { ageDayCalculator } from "../../../components/functions/Functions";
import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountConsultPapers = ({
  list,
  setValue,
  type,
  year,
  // setReportLoading,
}) => {
  //a หน้าคือผู้ใหญ่
  //a หลังคือเดือน

  // setReportLoading(true);

  let aa = 0; // 01
  let ab = 0; // 02
  let ac = 0; // 03
  let ad = 0; // 04
  let ae = 0; // 05
  let af = 0; // 06
  let ag = 0; // 07
  let ah = 0; // 08
  let ai = 0; // 09
  let aj = 0; // 10
  let ak = 0; // 11
  let al = 0; // 12

  let ba = 0; // 01
  let bb = 0; // 02
  let bc = 0; // 03
  let bd = 0; // 04
  let be = 0; // 05
  let bf = 0; // 06
  let bg = 0; // 07
  let bh = 0; // 08
  let bi = 0; // 09
  let bj = 0; // 10
  let bk = 0; // 11
  let bl = 0; // 12

  if (list?.length > 0) {
    for (let row of list) {
      const isAdult = Number(
        ageDayCalculator(new Date(row?.dob), new Date(row?.paper_date)) >= 6570
      );
      const isKid = Number(
        ageDayCalculator(new Date(row?.dob), new Date(row?.paper_date)) < 6570
      );

      if (
        row?.picture_consultation !== "" &&
        row?.paper_date !== "" &&
        row?.dob !== ""
      ) {
        const month = row?.paper_date ? row?.paper_date : "";
        const thisMonth = String(month);
        const useMont = thisMonth.substring(5, 7);

        // แยกตามปีของวันที่ได้รับใบปรึกษา
        const thisYear = row?.paper_date ? row?.paper_date : "";
        const useYear = thisYear.substring(0, 4);

        if (year === "ALL") {
          if (type === "NONE") {
            if (isAdult) {
              if (useMont === "01") {
                aa++;
              } else if (useMont === "02") {
                ab++;
              } else if (useMont === "03") {
                ac++;
              } else if (useMont === "04") {
                ad++;
              } else if (useMont === "05") {
                ae++;
              } else if (useMont === "06") {
                af++;
              } else if (useMont === "07") {
                ag++;
              } else if (useMont === "08") {
                ah++;
              } else if (useMont === "09") {
                ai++;
              } else if (useMont === "10") {
                aj++;
              } else if (useMont === "11") {
                ak++;
              } else if (useMont === "12") {
                al++;
              }
            } else if (isKid) {
              if (useMont === "01") {
                ba++;
              } else if (useMont === "02") {
                bb++;
              } else if (useMont === "03") {
                bc++;
              } else if (useMont === "04") {
                bd++;
              } else if (useMont === "05") {
                be++;
              } else if (useMont === "06") {
                bf++;
              } else if (useMont === "07") {
                bg++;
              } else if (useMont === "08") {
                bh++;
              } else if (useMont === "09") {
                bi++;
              } else if (useMont === "10") {
                bj++;
              } else if (useMont === "11") {
                bk++;
              } else if (useMont === "12") {
                bl++;
              }
            }
            // else {
            // }
          } else if (type === "PICC") {
            if (
              isAdult &&
              FunctionCheckType(
                row?.catheter?.label,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "PICC"
            ) {
              if (useMont === "01") {
                aa++;
              } else if (useMont === "02") {
                ab++;
              } else if (useMont === "03") {
                ac++;
              } else if (useMont === "04") {
                ad++;
              } else if (useMont === "05") {
                ae++;
              } else if (useMont === "06") {
                af++;
              } else if (useMont === "07") {
                ag++;
              } else if (useMont === "08") {
                ah++;
              } else if (useMont === "09") {
                ai++;
              } else if (useMont === "10") {
                aj++;
              } else if (useMont === "11") {
                ak++;
              } else if (useMont === "12") {
                al++;
              }
            } else if (
              isKid &&
              FunctionCheckType(
                row?.catheter?.label,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "PICC"
            ) {
              if (useMont === "01") {
                ba++;
              } else if (useMont === "02") {
                bb++;
              } else if (useMont === "03") {
                bc++;
              } else if (useMont === "04") {
                bd++;
              } else if (useMont === "05") {
                be++;
              } else if (useMont === "06") {
                bf++;
              } else if (useMont === "07") {
                bg++;
              } else if (useMont === "08") {
                bh++;
              } else if (useMont === "09") {
                bi++;
              } else if (useMont === "10") {
                bj++;
              } else if (useMont === "11") {
                bk++;
              } else if (useMont === "12") {
                bl++;
              }
            }
            //  else {
            // }
          } else if (type === "CENTRAL_LINE") {
            if (
              isAdult &&
              FunctionCheckType(
                row?.catheter?.label,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "CENTRAL_LINE"
            ) {
              if (useMont === "01") {
                aa++;
              } else if (useMont === "02") {
                ab++;
              } else if (useMont === "03") {
                ac++;
              } else if (useMont === "04") {
                ad++;
              } else if (useMont === "05") {
                console.log("LLL", row?.hn);
                ae++;
              } else if (useMont === "06") {
                af++;
              } else if (useMont === "07") {
                ag++;
              } else if (useMont === "08") {
                ah++;
              } else if (useMont === "09") {
                ai++;
              } else if (useMont === "10") {
                aj++;
              } else if (useMont === "11") {
                ak++;
              } else if (useMont === "12") {
                al++;
              }
            } else if (
              isKid &&
              FunctionCheckType(
                row?.catheter?.label,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "CENTRAL_LINE"
            ) {
              if (useMont === "01") {
                ba++;
              } else if (useMont === "02") {
                bb++;
              } else if (useMont === "03") {
                bc++;
              } else if (useMont === "04") {
                bd++;
              } else if (useMont === "05") {
                be++;
              } else if (useMont === "06") {
                bf++;
              } else if (useMont === "07") {
                bg++;
              } else if (useMont === "08") {
                bh++;
              } else if (useMont === "09") {
                bi++;
              } else if (useMont === "10") {
                bj++;
              } else if (useMont === "11") {
                bk++;
              } else if (useMont === "12") {
                bl++;
              }
            }
          }

          // return;
          // return "1";
        }
        if (year === useYear) {
          if (type === "NONE") {
            if (isAdult) {
              if (useMont === "01") {
                aa++;
              } else if (useMont === "02") {
                ab++;
              } else if (useMont === "03") {
                ac++;
              } else if (useMont === "04") {
                ad++;
              } else if (useMont === "05") {
                ae++;
              } else if (useMont === "06") {
                af++;
              } else if (useMont === "07") {
                ag++;
              } else if (useMont === "08") {
                ah++;
              } else if (useMont === "09") {
                ai++;
              } else if (useMont === "10") {
                aj++;
              } else if (useMont === "11") {
                ak++;
              } else if (useMont === "12") {
                al++;
              }
            } else if (isKid) {
              if (useMont === "01") {
                ba++;
              } else if (useMont === "02") {
                bb++;
              } else if (useMont === "03") {
                bc++;
              } else if (useMont === "04") {
                bd++;
              } else if (useMont === "05") {
                be++;
              } else if (useMont === "06") {
                bf++;
              } else if (useMont === "07") {
                bg++;
              } else if (useMont === "08") {
                bh++;
              } else if (useMont === "09") {
                bi++;
              } else if (useMont === "10") {
                bj++;
              } else if (useMont === "11") {
                bk++;
              } else if (useMont === "12") {
                bl++;
              }
            }
            // else {
            // }
          } else if (type === "PICC") {
            if (
              isAdult &&
              FunctionCheckType(
                row?.catheter?.label,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "PICC"
            ) {
              if (useMont === "01") {
                aa++;
              } else if (useMont === "02") {
                ab++;
              } else if (useMont === "03") {
                ac++;
              } else if (useMont === "04") {
                ad++;
              } else if (useMont === "05") {
                ae++;
              } else if (useMont === "06") {
                af++;
              } else if (useMont === "07") {
                ag++;
              } else if (useMont === "08") {
                ah++;
              } else if (useMont === "09") {
                ai++;
              } else if (useMont === "10") {
                aj++;
              } else if (useMont === "11") {
                ak++;
              } else if (useMont === "12") {
                al++;
              }
            } else if (
              isKid &&
              FunctionCheckType(
                row?.catheter?.label,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "PICC"
            ) {
              if (useMont === "01") {
                ba++;
              } else if (useMont === "02") {
                bb++;
              } else if (useMont === "03") {
                bc++;
              } else if (useMont === "04") {
                bd++;
              } else if (useMont === "05") {
                be++;
              } else if (useMont === "06") {
                bf++;
              } else if (useMont === "07") {
                bg++;
              } else if (useMont === "08") {
                bh++;
              } else if (useMont === "09") {
                bi++;
              } else if (useMont === "10") {
                bj++;
              } else if (useMont === "11") {
                bk++;
              } else if (useMont === "12") {
                bl++;
              }
            }
            //  else {
            // }
          } else if (type === "CENTRAL_LINE") {
            if (
              isAdult &&
              FunctionCheckType(
                row?.catheter?.label,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "CENTRAL_LINE"
            ) {
              if (useMont === "01") {
                aa++;
              } else if (useMont === "02") {
                ab++;
              } else if (useMont === "03") {
                ac++;
              } else if (useMont === "04") {
                ad++;
              } else if (useMont === "05") {
                ae++;
              } else if (useMont === "06") {
                af++;
              } else if (useMont === "07") {
                ag++;
              } else if (useMont === "08") {
                ah++;
              } else if (useMont === "09") {
                ai++;
              } else if (useMont === "10") {
                aj++;
              } else if (useMont === "11") {
                ak++;
              } else if (useMont === "12") {
                al++;
              }
            } else if (
              isKid &&
              FunctionCheckType(
                row?.catheter?.label,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "CENTRAL_LINE"
            ) {
              if (useMont === "01") {
                ba++;
              } else if (useMont === "02") {
                bb++;
              } else if (useMont === "03") {
                bc++;
              } else if (useMont === "04") {
                bd++;
              } else if (useMont === "05") {
                be++;
              } else if (useMont === "06") {
                bf++;
              } else if (useMont === "07") {
                bg++;
              } else if (useMont === "08") {
                bh++;
              } else if (useMont === "09") {
                bi++;
              } else if (useMont === "10") {
                bj++;
              } else if (useMont === "11") {
                bk++;
              } else if (useMont === "12") {
                bl++;
              }
            }
          }
        }
        // if (year === "2023") {
        //   if (type === "NONE") {
        //     if (isAdult && useYear === "2023") {
        //       if (useMont === "01") {
        //         aa++;
        //       } else if (useMont === "02") {
        //         ab++;
        //       } else if (useMont === "03") {
        //         ac++;
        //       } else if (useMont === "04") {
        //         ad++;
        //       } else if (useMont === "05") {
        //         ae++;
        //       } else if (useMont === "06") {
        //         af++;
        //       } else if (useMont === "07") {
        //         ag++;
        //       } else if (useMont === "08") {
        //         ah++;
        //       } else if (useMont === "09") {
        //         ai++;
        //       } else if (useMont === "10") {
        //         aj++;
        //       } else if (useMont === "11") {
        //         ak++;
        //       } else if (useMont === "12") {
        //         al++;
        //       }
        //     } else if (isKid && useYear === "2023") {
        //       if (useMont === "01") {
        //         ba++;
        //       } else if (useMont === "02") {
        //         bb++;
        //       } else if (useMont === "03") {
        //         bc++;
        //       } else if (useMont === "04") {
        //         bd++;
        //       } else if (useMont === "05") {
        //         be++;
        //       } else if (useMont === "06") {
        //         bf++;
        //       } else if (useMont === "07") {
        //         bg++;
        //       } else if (useMont === "08") {
        //         bh++;
        //       } else if (useMont === "09") {
        //         bi++;
        //       } else if (useMont === "10") {
        //         bj++;
        //       } else if (useMont === "11") {
        //         bk++;
        //       } else if (useMont === "12") {
        //         bl++;
        //       }
        //     }
        //     // else {
        //     // }
        //   } else if (type === "PICC") {
        //     if (
        //       isAdult &&
        //       FunctionCheckType(
        //         row?.catheter?.label,
        //         row?.other_catheter_name,
        //         row?.check_catheter
        //       ) === "PICC" &&
        //       useYear === "2023"
        //     ) {
        //       if (useMont === "01") {
        //         aa++;
        //       } else if (useMont === "02") {
        //         ab++;
        //       } else if (useMont === "03") {
        //         ac++;
        //       } else if (useMont === "04") {
        //         ad++;
        //       } else if (useMont === "05") {
        //         ae++;
        //       } else if (useMont === "06") {
        //         af++;
        //       } else if (useMont === "07") {
        //         ag++;
        //       } else if (useMont === "08") {
        //         ah++;
        //       } else if (useMont === "09") {
        //         ai++;
        //       } else if (useMont === "10") {
        //         aj++;
        //       } else if (useMont === "11") {
        //         ak++;
        //       } else if (useMont === "12") {
        //         al++;
        //       }
        //     } else if (
        //       isKid &&
        //       FunctionCheckType(
        //         row?.catheter?.label,
        //         row?.other_catheter_name,
        //         row?.check_catheter
        //       ) === "PICC" &&
        //       useYear === "2023"
        //     ) {
        //       if (useMont === "01") {
        //         ba++;
        //       } else if (useMont === "02") {
        //         bb++;
        //       } else if (useMont === "03") {
        //         bc++;
        //       } else if (useMont === "04") {
        //         bd++;
        //       } else if (useMont === "05") {
        //         be++;
        //       } else if (useMont === "06") {
        //         bf++;
        //       } else if (useMont === "07") {
        //         bg++;
        //       } else if (useMont === "08") {
        //         bh++;
        //       } else if (useMont === "09") {
        //         bi++;
        //       } else if (useMont === "10") {
        //         bj++;
        //       } else if (useMont === "11") {
        //         bk++;
        //       } else if (useMont === "12") {
        //         bl++;
        //       }
        //     }
        //     //  else {
        //     // }
        //   } else if (type === "CENTRAL_LINE") {
        //     if (
        //       isAdult &&
        //       FunctionCheckType(
        //         row?.catheter?.label,
        //         row?.other_catheter_name,
        //         row?.check_catheter
        //       ) === "CENTRAL_LINE" &&
        //       useYear === "2023"
        //     ) {
        //       if (useMont === "01") {
        //         aa++;
        //       } else if (useMont === "02") {
        //         ab++;
        //       } else if (useMont === "03") {
        //         ac++;
        //       } else if (useMont === "04") {
        //         ad++;
        //       } else if (useMont === "05") {
        //         ae++;
        //       } else if (useMont === "06") {
        //         af++;
        //       } else if (useMont === "07") {
        //         ag++;
        //       } else if (useMont === "08") {
        //         ah++;
        //       } else if (useMont === "09") {
        //         ai++;
        //       } else if (useMont === "10") {
        //         aj++;
        //       } else if (useMont === "11") {
        //         ak++;
        //       } else if (useMont === "12") {
        //         console.log("ผู้ใหญ่ central line 2023", row?.hn);
        //         console.log("KKKK");
        //         al++;
        //       }
        //     } else if (
        //       isKid &&
        //       FunctionCheckType(
        //         row?.catheter?.label,
        //         row?.other_catheter_name,
        //         row?.check_catheter
        //       ) === "CENTRAL_LINE" &&
        //       useYear === "2023"
        //     ) {
        //       if (useMont === "01") {
        //         ba++;
        //       } else if (useMont === "02") {
        //         bb++;
        //       } else if (useMont === "03") {
        //         bc++;
        //       } else if (useMont === "04") {
        //         bd++;
        //       } else if (useMont === "05") {
        //         be++;
        //       } else if (useMont === "06") {
        //         bf++;
        //       } else if (useMont === "07") {
        //         bg++;
        //       } else if (useMont === "08") {
        //         bh++;
        //       } else if (useMont === "09") {
        //         bi++;
        //       } else if (useMont === "10") {
        //         bj++;
        //       } else if (useMont === "11") {
        //         bk++;
        //       } else if (useMont === "12") {
        //         bl++;
        //       }
        //     }
        //   }
        // }
      } else {
        // return;
        // return "1";
      }
    }
  }
  setValue({
    jan: {
      adult: aa,
      kid: ba,
    },
    feb: {
      adult: ab,
      kid: bb,
    },
    mar: {
      adult: ac,
      kid: bc,
    },
    apr: {
      adult: ad,
      kid: bd,
    },
    may: {
      adult: ae,
      kid: be,
    },
    jun: {
      adult: af,
      kid: bf,
    },
    jul: {
      adult: ag,
      kid: bg,
    },
    aug: {
      adult: ah,
      kid: bh,
    },
    sep: {
      adult: ai,
      kid: bi,
    },
    oct: {
      adult: aj,
      kid: bj,
    },
    nov: {
      adult: ak,
      kid: bk,
    },
    dec: {
      adult: al,
      kid: bl,
    },
  });
  // setReportLoading(false);
};

export default ReportCountConsultPapers;
