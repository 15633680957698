import ExcelExport from "export-xlsx";

export const ExcelInfectionAt = async (inputdata, year, type, setLoading) => {
  // const inputdata = { data: [{ test: 5 }] };
  console.log("inputdata", inputdata);
  const SETTINGS_FOR_EXPORT = {
    // Table settings
    fileName: "ALiST_REPORT",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `การเกิดภาวะแทรกซ้อนระหว่างใส่ แยก Pneumothorax, Hemothorax, Paresthesia, Arterial puncture,  Nerve injury ของปี ${year?.label} ตามประเภทสาย ${type?.label}`,
                key: "header_1",
                style: { fill: { fgColor: { rgb: "FF0000" } } },
              },
            ],
            headerDefinition: [
              {
                name: "Pneumothorax",
                key: "complications_pneumothorax",
                groupKey: "header_1",
              },
              {
                name: "Pneumothorax %",
                key: "pneumothorax_percent",
                groupKey: "header_1",
              },
              {
                name: "Hemothorax",
                key: "complications_hemothorax",
                groupKey: "header_1",
              },
              {
                name: "Hemothorax %",
                key: "hemothorax_percent",
                groupKey: "header_1",
              },
              {
                name: "Arterial puncture",
                key: "complications_arterial_puncture",
                groupKey: "header_1",
              },
              {
                name: "Arterial puncture %",
                key: "arterial_puncture_percent",
                groupKey: "header_1",
              },
              {
                name: "Paresthesia",
                key: "complications_paresthesia",
                groupKey: "header_1",
              },
              {
                name: "Paresthesia %",
                key: "paresthesia_percent",
                groupKey: "header_1",
              },
              {
                name: "Nerve injury",
                key: "complications_nerve_injury",
                groupKey: "header_1",
              },
              {
                name: "Nerve injury %",
                key: "nerve_injury_percent",
                groupKey: "header_1",
              },
              {
                name: "Limb ischemia",
                key: "complications_limb_ischemia_related_to_arterial_puncture",
                groupKey: "header_1",
              },
              {
                name: "Other",
                key: "other_complications",
                groupKey: "header_1",
              },
              {
                name: "Other %",
                key: "other_complications_percent",
                groupKey: "header_1",
              },
              {
                name: "รวม",
                key: "sum",
                groupKey: "header_1",
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
