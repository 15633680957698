import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountConfirmation = ({ list, setValue, type, year, month }) => {
  // console.log("list", list);
  let po_1 = 0;
  let po_2 = 0;
  let po_3 = 0;
  let po_4 = 0;
  let po_5 = 0;
  let po_6 = 0;
  let po_7 = 0;
  let po_8 = 0;

  let con_good_flu = 0;
  let con_good_iecg = 0;
  let con_good_land = 0;

  //
  let con_short_flu = 0;
  let con_short_iecg = 0;
  let con_short_land = 0;

  //
  let con_long_flu = 0;
  let con_long_iecg = 0;
  let con_long_land = 0;

  //
  let con_un_flu = 0;
  let con_un_iecg = 0;
  let con_un_land = 0;

  //
  let in_ivc = 0;
  let no_ivc = 0;

  const formattedMonth = month.length === 1 ? `0${month}` : month;

  if (list?.length > 0) {
    //#filter final_date_success by month
    if (formattedMonth !== "ALL") {
      list = list.filter((row) => {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);
        const useMonth = thisYear.substring(5, 7);
        return useYear === year && useMonth === formattedMonth;
      });
    }

    for (let row of list) {
      if (row?.final_date_success !== "") {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);

        if (year === "ALL" || year === useYear) {
          if (type === "NONE") {
            if (row?.tip_of_catheter?.value === "-3") {
              po_1++;
            }
            if (row?.tip_of_catheter?.value === "-2") {
              po_2++;
            }
            if (row?.tip_of_catheter?.value === "-1") {
              po_3++;
            }
            if (row?.tip_of_catheter?.value === "0") {
              po_4++;
            }
            if (row?.tip_of_catheter?.value === "1") {
              po_5++;
            }
            if (row?.tip_of_catheter?.value === "2") {
              po_6++;
            }
            if (row?.tip_of_catheter?.value === "3") {
              po_7++;
            }
            if (row?.other_tip_of_catheter_lower) {
              po_8++;
            }
            // กลุ่ม ดี
            if (
              (row?.tip_of_catheter?.value === "-1" ||
                row?.tip_of_catheter?.value === "0" ||
                row?.tip_of_catheter?.value === "1") &&
              row?.fluoroscopy
            ) {
              con_good_flu++;
            }
            if (
              (row?.tip_of_catheter?.value === "-1" ||
                row?.tip_of_catheter?.value === "0" ||
                row?.tip_of_catheter?.value === "1") &&
              row?.iecg
            ) {
              con_good_iecg++;
            }
            if (
              (row?.tip_of_catheter?.value === "-1" ||
                row?.tip_of_catheter?.value === "0" ||
                row?.tip_of_catheter?.value === "1") &&
              row?.landmark
            ) {
              con_good_land++;
            }
            if (
              (row?.tip_of_catheter?.value === "-1" ||
                row?.tip_of_catheter?.value === "0" ||
                row?.tip_of_catheter?.value === "1") &&
              !row?.landmark &&
              !row?.iecg &&
              !row?.fluoroscopy
            ) {
              con_good_land++;
            }

            // กลุ่ม ตื้น
            if (
              (row?.tip_of_catheter?.value === "-2" ||
                row?.tip_of_catheter?.value === "-3") &&
              row?.fluoroscopy
            ) {
              con_short_flu++;
            }
            if (
              (row?.tip_of_catheter?.value === "-2" ||
                row?.tip_of_catheter?.value === "-3") &&
              row?.iecg
            ) {
              con_short_iecg++;
            }
            if (
              (row?.tip_of_catheter?.value === "-2" ||
                row?.tip_of_catheter?.value === "-3") &&
              row?.landmark
            ) {
              con_short_land++;
            }
            if (
              (row?.tip_of_catheter?.value === "-2" ||
                row?.tip_of_catheter?.value === "-3") &&
              !row?.landmark &&
              !row?.iecg &&
              !row?.fluoroscopy
            ) {
              con_short_land++;
            }
            // กลุ่ม ลึก
            if (
              (row?.tip_of_catheter?.value === "2" ||
                row?.tip_of_catheter?.value === "3") &&
              row?.fluoroscopy
            ) {
              con_long_flu++;
            }
            if (
              (row?.tip_of_catheter?.value === "2" ||
                row?.tip_of_catheter?.value === "3") &&
              row?.iecg
            ) {
              con_long_iecg++;
            }
            if (
              (row?.tip_of_catheter?.value === "2" ||
                row?.tip_of_catheter?.value === "3") &&
              row?.landmark
            ) {
              con_long_land++;
            }
            if (
              (row?.tip_of_catheter?.value === "2" ||
                row?.tip_of_catheter?.value === "3") &&
              !row?.landmark &&
              !row?.iecg &&
              !row?.fluoroscopy
            ) {
              con_long_land++;
            }

            // กลุ่ม un
            if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
              con_un_flu++;
            }
            if (row?.other_tip_of_catheter_lower && row?.iecg) {
              con_un_iecg++;
            }
            if (row?.other_tip_of_catheter_lower && row?.landmark) {
              con_un_land++;
            }
            if (
              row?.other_tip_of_catheter_lower &&
              !row?.landmark &&
              !row?.iecg &&
              !row?.fluoroscopy
            ) {
              con_un_land++;
            }

            if (row?.in_ivc) {
              in_ivc++;
            }
            if (row?.not_in_ivc) {
              no_ivc++;
            }
          }

          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (row?.tip_of_catheter?.value === "-3") {
              po_1++;
            }
            if (row?.tip_of_catheter?.value === "-2") {
              po_2++;
            }
            if (row?.tip_of_catheter?.value === "-1") {
              po_3++;
            }
            if (row?.tip_of_catheter?.value === "0") {
              po_4++;
            }
            if (row?.tip_of_catheter?.value === "1") {
              po_5++;
            }
            if (row?.tip_of_catheter?.value === "2") {
              po_6++;
            }
            if (row?.tip_of_catheter?.value === "3") {
              po_7++;
            }
            if (row?.other_tip_of_catheter_lower) {
              po_8++;
            }
            // กลุ่ม ดี
            if (
              (row?.tip_of_catheter?.value === "-1" ||
                row?.tip_of_catheter?.value === "0" ||
                row?.tip_of_catheter?.value === "1") &&
              row?.fluoroscopy
            ) {
              con_good_flu++;
            }
            if (
              (row?.tip_of_catheter?.value === "-1" ||
                row?.tip_of_catheter?.value === "0" ||
                row?.tip_of_catheter?.value === "1") &&
              row?.iecg
            ) {
              con_good_iecg++;
            }
            if (
              (row?.tip_of_catheter?.value === "-1" ||
                row?.tip_of_catheter?.value === "0" ||
                row?.tip_of_catheter?.value === "1") &&
              row?.landmark
            ) {
              con_good_land++;
            }
            if (
              (row?.tip_of_catheter?.value === "-1" ||
                row?.tip_of_catheter?.value === "0" ||
                row?.tip_of_catheter?.value === "1") &&
              !row?.landmark &&
              !row?.iecg &&
              !row?.fluoroscopy
            ) {
              con_good_land++;
            }

            // กลุ่ม ตื้น
            if (
              (row?.tip_of_catheter?.value === "-2" ||
                row?.tip_of_catheter?.value === "-3") &&
              row?.fluoroscopy
            ) {
              con_short_flu++;
            }
            if (
              (row?.tip_of_catheter?.value === "-2" ||
                row?.tip_of_catheter?.value === "-3") &&
              row?.iecg
            ) {
              con_short_iecg++;
            }
            if (
              (row?.tip_of_catheter?.value === "-2" ||
                row?.tip_of_catheter?.value === "-3") &&
              row?.landmark
            ) {
              con_short_land++;
            }
            if (
              (row?.tip_of_catheter?.value === "-2" ||
                row?.tip_of_catheter?.value === "-3") &&
              !row?.landmark &&
              !row?.iecg &&
              !row?.fluoroscopy
            ) {
              con_short_land++;
            }
            // กลุ่ม ลึก
            if (
              (row?.tip_of_catheter?.value === "2" ||
                row?.tip_of_catheter?.value === "3") &&
              row?.fluoroscopy
            ) {
              con_long_flu++;
            }
            if (
              (row?.tip_of_catheter?.value === "2" ||
                row?.tip_of_catheter?.value === "3") &&
              row?.iecg
            ) {
              con_long_iecg++;
            }
            if (
              (row?.tip_of_catheter?.value === "2" ||
                row?.tip_of_catheter?.value === "3") &&
              row?.landmark
            ) {
              con_long_land++;
            }
            if (
              (row?.tip_of_catheter?.value === "2" ||
                row?.tip_of_catheter?.value === "3") &&
              !row?.landmark &&
              !row?.iecg &&
              !row?.fluoroscopy
            ) {
              con_long_land++;
            }

            // กลุ่ม un
            if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
              con_un_flu++;
            }
            if (row?.other_tip_of_catheter_lower && row?.iecg) {
              con_un_iecg++;
            }
            if (row?.other_tip_of_catheter_lower && row?.landmark) {
              con_un_land++;
            }
            if (
              row?.other_tip_of_catheter_lower &&
              !row?.landmark &&
              !row?.iecg &&
              !row?.fluoroscopy
            ) {
              con_un_land++;
            }
            if (row?.in_ivc) {
              in_ivc++;
            }
            if (row?.not_in_ivc) {
              no_ivc++;
            }
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            if (row?.tip_of_catheter?.value === "-3") {
              po_1++;
            }
            if (row?.tip_of_catheter?.value === "-2") {
              po_2++;
            }
            if (row?.tip_of_catheter?.value === "-1") {
              po_3++;
            }
            if (row?.tip_of_catheter?.value === "0") {
              po_4++;
            }
            if (row?.tip_of_catheter?.value === "1") {
              po_5++;
            }
            if (row?.tip_of_catheter?.value === "2") {
              po_6++;
            }
            if (row?.tip_of_catheter?.value === "3") {
              po_7++;
            }
            if (row?.other_tip_of_catheter_lower) {
              po_8++;
            }
            // กลุ่ม ดี
            if (
              (row?.tip_of_catheter?.value === "-1" ||
                row?.tip_of_catheter?.value === "0" ||
                row?.tip_of_catheter?.value === "1") &&
              row?.fluoroscopy
            ) {
              con_good_flu++;
            }
            if (
              (row?.tip_of_catheter?.value === "-1" ||
                row?.tip_of_catheter?.value === "0" ||
                row?.tip_of_catheter?.value === "1") &&
              row?.iecg
            ) {
              con_good_iecg++;
            }
            if (
              (row?.tip_of_catheter?.value === "-1" ||
                row?.tip_of_catheter?.value === "0" ||
                row?.tip_of_catheter?.value === "1") &&
              row?.landmark
            ) {
              con_good_land++;
            }
            if (
              (row?.tip_of_catheter?.value === "-1" ||
                row?.tip_of_catheter?.value === "0" ||
                row?.tip_of_catheter?.value === "1") &&
              !row?.landmark &&
              !row?.iecg &&
              !row?.fluoroscopy
            ) {
              con_good_land++;
            }

            // กลุ่ม ตื้น
            if (
              (row?.tip_of_catheter?.value === "-2" ||
                row?.tip_of_catheter?.value === "-3") &&
              row?.fluoroscopy
            ) {
              con_short_flu++;
            }
            if (
              (row?.tip_of_catheter?.value === "-2" ||
                row?.tip_of_catheter?.value === "-3") &&
              row?.iecg
            ) {
              con_short_iecg++;
            }
            if (
              (row?.tip_of_catheter?.value === "-2" ||
                row?.tip_of_catheter?.value === "-3") &&
              row?.landmark
            ) {
              con_short_land++;
            }
            if (
              (row?.tip_of_catheter?.value === "-2" ||
                row?.tip_of_catheter?.value === "-3") &&
              !row?.landmark &&
              !row?.iecg &&
              !row?.fluoroscopy
            ) {
              con_short_land++;
            }
            // กลุ่ม ลึก
            if (
              (row?.tip_of_catheter?.value === "2" ||
                row?.tip_of_catheter?.value === "3") &&
              row?.fluoroscopy
            ) {
              con_long_flu++;
            }
            if (
              (row?.tip_of_catheter?.value === "2" ||
                row?.tip_of_catheter?.value === "3") &&
              row?.iecg
            ) {
              con_long_iecg++;
            }
            if (
              (row?.tip_of_catheter?.value === "2" ||
                row?.tip_of_catheter?.value === "3") &&
              row?.landmark
            ) {
              con_long_land++;
            }
            if (
              (row?.tip_of_catheter?.value === "2" ||
                row?.tip_of_catheter?.value === "3") &&
              !row?.landmark &&
              !row?.iecg &&
              !row?.fluoroscopy
            ) {
              con_long_land++;
            }

            // กลุ่ม un
            if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
              con_un_flu++;
            }
            if (row?.other_tip_of_catheter_lower && row?.iecg) {
              con_un_iecg++;
            }
            if (row?.other_tip_of_catheter_lower && row?.landmark) {
              con_un_land++;
            }
            if (
              row?.other_tip_of_catheter_lower &&
              !row?.landmark &&
              !row?.iecg &&
              !row?.fluoroscopy
            ) {
              con_un_land++;
            }
            if (row?.in_ivc) {
              in_ivc++;
            }
            if (row?.not_in_ivc) {
              no_ivc++;
            }
          }
        }
        // if (year === useYear) {
        //   if (type === "NONE") {
        //     if (row?.tip_of_catheter?.value === "-3") {
        //       po_1++;
        //     }
        //     if (row?.tip_of_catheter?.value === "-2") {
        //       po_2++;
        //     }
        //     if (row?.tip_of_catheter?.value === "-1") {
        //       po_3++;
        //     }
        //     if (row?.tip_of_catheter?.value === "0") {
        //       po_4++;
        //     }
        //     if (row?.tip_of_catheter?.value === "1") {
        //       po_5++;
        //     }
        //     if (row?.tip_of_catheter?.value === "2") {
        //       po_6++;
        //     }
        //     if (row?.tip_of_catheter?.value === "3") {
        //       po_7++;
        //     }
        //     if (row?.other_tip_of_catheter_lower) {
        //       po_8++;
        //     }
        //     // กลุ่ม ดี
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_good_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.iecg
        //     ) {
        //       con_good_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.landmark
        //     ) {
        //       con_good_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_good_land++;
        //     }

        //     // กลุ่ม ตื้น
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_short_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.iecg
        //     ) {
        //       con_short_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.landmark
        //     ) {
        //       con_short_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_short_land++;
        //     }
        //     // กลุ่ม ลึก
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_long_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.iecg
        //     ) {
        //       con_long_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.landmark
        //     ) {
        //       con_long_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_long_land++;
        //     }

        //     // กลุ่ม un
        //     if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
        //       con_un_flu++;
        //     }
        //     if (row?.other_tip_of_catheter_lower && row?.iecg) {
        //       con_un_iecg++;
        //     }
        //     if (row?.other_tip_of_catheter_lower && row?.landmark) {
        //       con_un_land++;
        //     }
        //     if (
        //       row?.other_tip_of_catheter_lower &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_un_land++;
        //     }
        //     if (row?.in_ivc) {
        //       in_ivc++;
        //     }
        //     if (row?.not_in_ivc) {
        //       no_ivc++;
        //     }
        //   }

        //   if (
        //     type === "PICC" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     if (row?.tip_of_catheter?.value === "-3") {
        //       po_1++;
        //     }
        //     if (row?.tip_of_catheter?.value === "-2") {
        //       po_2++;
        //     }
        //     if (row?.tip_of_catheter?.value === "-1") {
        //       po_3++;
        //     }
        //     if (row?.tip_of_catheter?.value === "0") {
        //       po_4++;
        //     }
        //     if (row?.tip_of_catheter?.value === "1") {
        //       po_5++;
        //     }
        //     if (row?.tip_of_catheter?.value === "2") {
        //       po_6++;
        //     }
        //     if (row?.tip_of_catheter?.value === "3") {
        //       po_7++;
        //     }
        //     if (row?.other_tip_of_catheter_lower) {
        //       po_8++;
        //     }
        //     // กลุ่ม ดี
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_good_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.iecg
        //     ) {
        //       con_good_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.landmark
        //     ) {
        //       con_good_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_good_land++;
        //     }

        //     // กลุ่ม ตื้น
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_short_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.iecg
        //     ) {
        //       con_short_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.landmark
        //     ) {
        //       con_short_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_short_land++;
        //     }
        //     // กลุ่ม ลึก
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_long_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.iecg
        //     ) {
        //       con_long_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.landmark
        //     ) {
        //       con_long_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_long_land++;
        //     }

        //     // กลุ่ม un
        //     if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
        //       con_un_flu++;
        //     }
        //     if (row?.other_tip_of_catheter_lower && row?.iecg) {
        //       con_un_iecg++;
        //     }
        //     if (row?.other_tip_of_catheter_lower && row?.landmark) {
        //       con_un_land++;
        //     }
        //     if (
        //       row?.other_tip_of_catheter_lower &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_un_land++;
        //     }
        //     if (row?.in_ivc) {
        //       in_ivc++;
        //     }
        //     if (row?.not_in_ivc) {
        //       no_ivc++;
        //     }
        //   }
        //   if (
        //     type === "CENTRAL_LINE" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     if (row?.tip_of_catheter?.value === "-3") {
        //       po_1++;
        //     }
        //     if (row?.tip_of_catheter?.value === "-2") {
        //       po_2++;
        //     }
        //     if (row?.tip_of_catheter?.value === "-1") {
        //       po_3++;
        //     }
        //     if (row?.tip_of_catheter?.value === "0") {
        //       po_4++;
        //     }
        //     if (row?.tip_of_catheter?.value === "1") {
        //       po_5++;
        //     }
        //     if (row?.tip_of_catheter?.value === "2") {
        //       po_6++;
        //     }
        //     if (row?.tip_of_catheter?.value === "3") {
        //       po_7++;
        //     }
        //     if (row?.other_tip_of_catheter_lower) {
        //       po_8++;
        //     }
        //     // กลุ่ม ดี
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_good_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.iecg
        //     ) {
        //       con_good_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.landmark
        //     ) {
        //       con_good_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_good_land++;
        //     }

        //     // กลุ่ม ตื้น
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_short_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.iecg
        //     ) {
        //       con_short_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.landmark
        //     ) {
        //       con_short_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_short_land++;
        //     }
        //     // กลุ่ม ลึก
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_long_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.iecg
        //     ) {
        //       con_long_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.landmark
        //     ) {
        //       con_long_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_long_land++;
        //     }

        //     // กลุ่ม un
        //     if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
        //       con_un_flu++;
        //     }
        //     if (row?.other_tip_of_catheter_lower && row?.iecg) {
        //       con_un_iecg++;
        //     }
        //     if (row?.other_tip_of_catheter_lower && row?.landmark) {
        //       con_un_land++;
        //     }
        //     if (
        //       row?.other_tip_of_catheter_lower &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_un_land++;
        //     }
        //     if (row?.in_ivc) {
        //       in_ivc++;
        //     }
        //     if (row?.not_in_ivc) {
        //       no_ivc++;
        //     }
        //   }
        // }
        // if (year === "2023" && useYear === "2023") {
        //   if (type === "NONE") {
        //     if (row?.tip_of_catheter?.value === "-3") {
        //       po_1++;
        //     }
        //     if (row?.tip_of_catheter?.value === "-2") {
        //       po_2++;
        //     }
        //     if (row?.tip_of_catheter?.value === "-1") {
        //       po_3++;
        //     }
        //     if (row?.tip_of_catheter?.value === "0") {
        //       po_4++;
        //     }
        //     if (row?.tip_of_catheter?.value === "1") {
        //       po_5++;
        //     }
        //     if (row?.tip_of_catheter?.value === "2") {
        //       po_6++;
        //     }
        //     if (row?.tip_of_catheter?.value === "3") {
        //       po_7++;
        //     }
        //     if (row?.other_tip_of_catheter_lower) {
        //       po_8++;
        //     }
        //     // กลุ่ม ดี
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_good_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.iecg
        //     ) {
        //       con_good_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.landmark
        //     ) {
        //       con_good_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_good_land++;
        //     }

        //     // กลุ่ม ตื้น
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_short_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.iecg
        //     ) {
        //       con_short_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.landmark
        //     ) {
        //       con_short_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_short_land++;
        //     }
        //     // กลุ่ม ลึก
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_long_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.iecg
        //     ) {
        //       con_long_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.landmark
        //     ) {
        //       con_long_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_long_land++;
        //     }

        //     // กลุ่ม un
        //     if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
        //       con_un_flu++;
        //     }
        //     if (row?.other_tip_of_catheter_lower && row?.iecg) {
        //       con_un_iecg++;
        //     }
        //     if (row?.other_tip_of_catheter_lower && row?.landmark) {
        //       con_un_land++;
        //     }
        //     if (
        //       row?.other_tip_of_catheter_lower &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_un_land++;
        //     }
        //     if (row?.in_ivc) {
        //       in_ivc++;
        //     }
        //     if (row?.not_in_ivc) {
        //       no_ivc++;
        //     }
        //   }

        //   if (
        //     type === "PICC" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     if (row?.tip_of_catheter?.value === "-3") {
        //       po_1++;
        //     }
        //     if (row?.tip_of_catheter?.value === "-2") {
        //       po_2++;
        //     }
        //     if (row?.tip_of_catheter?.value === "-1") {
        //       po_3++;
        //     }
        //     if (row?.tip_of_catheter?.value === "0") {
        //       po_4++;
        //     }
        //     if (row?.tip_of_catheter?.value === "1") {
        //       po_5++;
        //     }
        //     if (row?.tip_of_catheter?.value === "2") {
        //       po_6++;
        //     }
        //     if (row?.tip_of_catheter?.value === "3") {
        //       po_7++;
        //     }
        //     if (row?.other_tip_of_catheter_lower) {
        //       po_8++;
        //     }
        //     // กลุ่ม ดี
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_good_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.iecg
        //     ) {
        //       con_good_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.landmark
        //     ) {
        //       con_good_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_good_land++;
        //     }

        //     // กลุ่ม ตื้น
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_short_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.iecg
        //     ) {
        //       con_short_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.landmark
        //     ) {
        //       con_short_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_short_land++;
        //     }
        //     // กลุ่ม ลึก
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_long_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.iecg
        //     ) {
        //       con_long_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.landmark
        //     ) {
        //       con_long_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_long_land++;
        //     }

        //     // กลุ่ม un
        //     if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
        //       con_un_flu++;
        //     }
        //     if (row?.other_tip_of_catheter_lower && row?.iecg) {
        //       con_un_iecg++;
        //     }
        //     if (row?.other_tip_of_catheter_lower && row?.landmark) {
        //       con_un_land++;
        //     }
        //     if (
        //       row?.other_tip_of_catheter_lower &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_un_land++;
        //     }
        //     if (row?.in_ivc) {
        //       in_ivc++;
        //     }
        //     if (row?.not_in_ivc) {
        //       no_ivc++;
        //     }
        //   }
        //   if (
        //     type === "CENTRAL_LINE" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     if (row?.tip_of_catheter?.value === "-3") {
        //       po_1++;
        //     }
        //     if (row?.tip_of_catheter?.value === "-2") {
        //       po_2++;
        //     }
        //     if (row?.tip_of_catheter?.value === "-1") {
        //       po_3++;
        //     }
        //     if (row?.tip_of_catheter?.value === "0") {
        //       po_4++;
        //     }
        //     if (row?.tip_of_catheter?.value === "1") {
        //       po_5++;
        //     }
        //     if (row?.tip_of_catheter?.value === "2") {
        //       po_6++;
        //     }
        //     if (row?.tip_of_catheter?.value === "3") {
        //       po_7++;
        //     }
        //     if (row?.other_tip_of_catheter_lower) {
        //       po_8++;
        //     }
        //     // กลุ่ม ดี
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_good_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.iecg
        //     ) {
        //       con_good_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       row?.landmark
        //     ) {
        //       con_good_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-1" ||
        //         row?.tip_of_catheter?.value === "0" ||
        //         row?.tip_of_catheter?.value === "1") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_good_land++;
        //     }

        //     // กลุ่ม ตื้น
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_short_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.iecg
        //     ) {
        //       con_short_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       row?.landmark
        //     ) {
        //       con_short_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "-2" ||
        //         row?.tip_of_catheter?.value === "-3") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_short_land++;
        //     }
        //     // กลุ่ม ลึก
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.fluoroscopy
        //     ) {
        //       con_long_flu++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.iecg
        //     ) {
        //       con_long_iecg++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       row?.landmark
        //     ) {
        //       con_long_land++;
        //     }
        //     if (
        //       (row?.tip_of_catheter?.value === "2" ||
        //         row?.tip_of_catheter?.value === "3") &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_long_land++;
        //     }

        //     // กลุ่ม un
        //     if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
        //       con_un_flu++;
        //     }
        //     if (row?.other_tip_of_catheter_lower && row?.iecg) {
        //       con_un_iecg++;
        //     }
        //     if (row?.other_tip_of_catheter_lower && row?.landmark) {
        //       con_un_land++;
        //     }
        //     if (
        //       row?.other_tip_of_catheter_lower &&
        //       !row?.landmark &&
        //       !row?.iecg &&
        //       !row?.fluoroscopy
        //     ) {
        //       con_un_land++;
        //     }
        //     if (row?.in_ivc) {
        //       in_ivc++;
        //     }
        //     if (row?.not_in_ivc) {
        //       no_ivc++;
        //     }
        //   }
        // }
      }
    }
    setValue({
      po_1: po_1,
      po_2: po_2,
      po_3: po_3,
      po_4: po_4,
      po_5: po_5,
      po_6: po_6,
      po_7: po_7,
      po_8: po_8,
      total_po: po_1 + po_2 + po_3 + po_4 + po_5 + po_6 + po_7 + po_8,

      //
      // con_good_flu: con_good_flu,
      // con_good_iecg: con_good_iecg,
      // con_good_land: con_good_land,
      // total_good: con_good_flu + con_good_iecg + con_good_land,

      // //
      // con_short_flu: con_short_flu,
      // con_short_iecg: con_short_iecg,
      // con_short_land: con_short_land,
      // total_short: con_short_flu + con_short_iecg + con_short_land,

      // //
      // con_long_flu: con_long_flu,
      // con_long_iecg: con_long_iecg,
      // con_long_land: con_long_land,
      // total_long: con_long_flu + con_long_iecg + con_long_land,

      // //
      // con_un_flu: con_un_flu,
      // con_un_iecg: con_un_iecg,
      // con_un_land: con_un_land,
      // total_un: con_un_flu + con_un_iecg + con_un_land,

      //
      in_ivc: in_ivc,
      no_ivc: no_ivc,
    });
  }
};

export default ReportCountConfirmation;
