import React from "react";
import {
  AttendingOptions,
  OptionECG,
  OptionFinalCatheterIn,
  OptionsCatheter,
  RNOptions,
} from "../../contexts/DataOptions";
import CheckBoxMulti from "../field/CheckBoxMulti";
import DatePickerYear from "../field/DatePickerYear";
import Dropdown from "../field/Dropdown";
import TextField from "../field/TextField";
import { ListDoctorAndNurseContext } from "../../contexts/ListDoctorAndNurseContext";
import { filterRole } from "../../components/functions/Functions";
import { useContext } from "react";
import { WarningLabel } from "../../hooks/useClassName";
import { WatDatePicker } from "thaidatepicker-react";
import dayjs from "dayjs";
import CheckBoxSelectOne from "../field/CheckboxSelectOne";

const FormEditFollowUp = ({
  setOpenEdit,
  submitEdit,
  form,
  setField,
  data,
  editLoading,
}) => {
  const { listName } = useContext(ListDoctorAndNurseContext);
  const handleXray = (christDate, buddhistDate) => {
    // console.log(christDate)
    // console.log(buddhistDate);
    setField("x_ray_date")(christDate);
    // setSelectedDate(christDate)
    // setSelectedThaiDate(buddhistDate)
  };
  return (
    <div className="sectionAll relative  w-auto rounded-xl text-textblue bg-white p-10 ">
      <div className="overflow-y-scroll h-80vh">
        <div className="section_2">
          <div className="text-center grid grid-cols-1 ">
            <WarningLabel
              label={
                " วันที่ใส่สายแก้ไขได้จาก วันที่ของ attempt ที่สำเร็จ ในหน้า PROCEDURE NOTE"
              }
            />
            <div className=""> Main diagnosis </div>
            <div className="grid grid-cols-1 gap-3 ">
              <TextField
                name={"diagnosis"}
                value={form?.diagnosis}
                setField={setField}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-3 ">
          <div className="flex justify-start items-center gap-8">
            Catheter
            <div className="flex">
              <Dropdown
                setField={setField}
                name="catheter"
                value={form?.catheter}
                options={OptionsCatheter}
                other_name="other_catheter_name"
                other_value={form?.other_catheter_name}
              />
            </div>
            {form?.catheter?.value === "other_catheter" && (
              <div className="flex justify-start items-center gap-8">
                <CheckBoxSelectOne
                  title={""}
                  setField={setField}
                  value={form?.check_catheter}
                  name="check_catheter"
                  label={["picc", "central"]}
                  className={
                    "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2   md:gap-20  lg:gap-1 xl:gap-1 my-2 mx-10 md:mx-12 lg:mx-10 xl:mx-10"
                  }
                  classNameCustom="text-sm"
                />
              </div>
            )}
          </div>

          <div className=" flex justify-start items-center gap-16 ml-1">
            iECG
            <Dropdown
              className="w-40"
              setField={setField}
              name="ecg"
              value={form?.ecg}
              options={OptionECG}
              other_name="other_ecg_name"
              other_value={form?.other_ecg_name}
            />
          </div>
          {/* <div className="flex justify-start items-center gap-16">
            ใส่วันที่
            <div className=" w-40 ">
              <DatePickerYear
                title="ใส่วันที่"
                setField={setField}
                name="date"
                value={form?.date} 
                year
              />
            </div>
          </div> */}
          <div className=" flex justify-start items-center gap-6 ">
            หลอดเลือด
            <Dropdown
              className="w-40"
              setField={setField}
              name="final_catheter_in"
              value={form?.final_catheter_in}
              options={OptionFinalCatheterIn}
              other_name="other_final_catheter_in"
              other_value={form?.other_final_catheter_in}
            />
          </div>

          <div className="flex justify-start items-center gap-20">
            BW
            <div className=" w-40  ">
              <TextField
                type="number"
                name="weight"
                unit="kg."
                value={form?.weight}
                setField={setField}
              />
            </div>
          </div>

          <div className="flex justify-start items-center gap-20 ml-1">
            HT
            <div className=" w-40">
              <TextField
                // type="number"
                name="height"
                unit="cm."
                value={form?.height}
                setField={setField}
              />
            </div>
          </div>
          <div className="flex justify-start items-center gap-20">
            ลึก
            <div className=" w-40 ">
              <TextField
                // type="number"
                unit="cm."
                name="indwelled"
                value={form?.indwelled}
                setField={setField}
              />
            </div>
          </div>
          {/* <div className="flex justify-start items-center gap-8">
            CXR หลังใส่
            <div className=" w-40 ">
              <DatePickerYear
                setField={setField}
                name="date_cxr"
                value={
                  form?.x_ray_date ? new Date(form?.x_ray_date) : new Date()
                }
                year
              />
            </div>
          </div> */}
          <div className="flex justify-start items-center gap-8">
            <div className=""> CXR หลังใส่</div>
            <div className="text-base w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full  mx-10">
              <WatDatePicker
                value={form?.x_ray_date ? form?.x_ray_date : null}
                onChange={handleXray}
                placeholder={"DD/MM/YYYY"}
                dateFormat={"yyyy-MM-dd"}
                displayFormat={"DD MMMM YYYY"}
                inputStyle={{ color: "black", width: "190px" }}
                clearable={true}
                minDate={"1920-01-01"}
                maxDate={dayjs().add(20, "day")}
                disabled={false}
                readOnly={false}
              />
            </div>
          </div>
          <div className="flex justify-start items-center gap-8">
            CXR tip
            <Dropdown
              title=""
              name="tip_of_catheter"
              value={form?.tip_of_catheter}
              setField={setField}
              options={[
                { label: "-3", value: "-3" },
                { label: "-2", value: "-2" },
                { label: "-1", value: "-1" },
                { label: "0", value: "0" },
                { label: "1", value: "1" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                // { label: "Other", value: "other" },
              ]}
              other_name="other_tip_of_catheter"
              other_value={form?.other_tip_of_catheter}
            />
          </div>
        </div>
        <div className=" grid grid-cols-1 my-4 mx-1">
          <div className=" flex justify-start items-center gap-8 ">
            ตำแหน่ง
            <TextField
              // title="Exit site mark"
              setField={setField}
              name="exit_site_mark"
              value={form?.exit_site_mark}
              unit="cm."
            />
            {/* <Dropdown
              className="w-40 mb-6 ml-3"
              name="tip_of_catheter"
              value={form?.tip_of_catheter}
              setField={setField}
              options={[
                { label: "-3", value: "-3" },
                { label: "-2", value: "-2" },
                { label: "-1", value: "-1" },
                { label: "0", value: "0" },
                { label: "1", value: "1" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
              ]}
              other_name="other_tip_of_catheter"
              other_value={form?.other_tip_of_catheter}
            /> */}
          </div>
          <div>
            สถานที่ใส่
            <CheckBoxMulti
              setField={setField}
              name={["number", "beside", "other_location"]}
              value={[form?.number, form?.beside, form?.other_location]}
              label={["504.5", "Bedside", "Other"]}
              other_name="other_location_name"
              other_value={form?.other_location_name}
            />
          </div>
          {/* <Dropdown
            title="Doctor"
            setField={setField}
            name="doctor"
            value={form?.doctor}
            options={filterRole(listName, "DOCTOR")}
            // options={AttendingOptions}
          /> */}
          <Dropdown
            title="Nurse"
            setField={setField}
            name="nurse"
            value={form?.nurse}
            options={filterRole(listName, "NURSE")}
            // options={RNOptions}
          />

          <TextField
            title="Other"
            name={"other_person"}
            value={form?.other_person}
            setField={setField}
          />
        </div>
        <div className="flex justify-center items-center space-x-2 mt-4 mb-10">
          <button
            disabled={editLoading}
            onClick={() => submitEdit(form)}
            type="button"
            className="w-24 my-2 inline-flex justify-center text-base rounded-md bg-green-400 hover:bg-green-500 xl: text-white px-4 py-1 mx-4 font-medium hover:shadow-lg shadow-md "
          >
            {editLoading ? "Saving..." : "Save"}
          </button>
          <button
            onClick={() => {
              setOpenEdit(false);
            }}
            className="w-24 my-2 inline-flex justify-center rounded-md  bg-red-400 hover:bg-red-500 xl: px-4 py-1 text-white text-base font-medium hover:shadow-lg shadow-md"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormEditFollowUp;
