import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const userReportStore = create(
  persist(
    (set) => ({
      reportLoading: false,
      setReportLoading: (reportLoading) =>
        set({ reportLoading: reportLoading }),
    }),
    {
      name: "userReportStore", // unique name
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default userReportStore;
