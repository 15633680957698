import { label } from "aws-amplify";

export const OptionECG = [
  { label: "Sinus Rhythm", value: "Sinus Rhythm" },
  { label: "A.fib", value: "A.fib" },
  { label: "Paroxysmal a.fib", value: "Paroxysmal a.fib" },
  { label: "Pacemaker", value: "Pacemaker" },
  { label: "Other ", value: "other_ecg" },
];

export const OptionsReasonKeep = [
  {
    label: "เปลี่ยนแผนการรักษา ไม่ต้องการใช้สาย",
    value: "เปลี่ยนแผนการรักษา ไม่ต้องการใช้สาย",
  },
  { label: "ส่งปรึกษาแผนกอื่น", value: "ส่งปรึกษาแผนกอื่น" },
  { label: "เสียชีวิต", value: "เสียชีวิต" },
  { label: "ใส่สายไม่สำเร็จ", value: "ใส่สายไม่สำเร็จ" },
  { label: "ซ้ำ", value: "ซ้ำ" },
  { label: "อื่นๆ", value: "other" },
];

export const OptionWard = [
  { label: "อายุรกรรม ", value: "อายุรกรรม" },
  { label: "ศัลยกรรม ", value: "ศัลยกรรม" },
  { label: "สูตินรีเวช ", value: "สูตินรีเวช" },
  { label: "กุมารเวชศาสตร์ ", value: "กุมารเวชศาสตร์" },
  { label: "หออภิบาล ", value: "หออภิบาล" },
  { label: "ผู้ป่วยนอก ", value: "ผู้ป่วยนอก" },
  { value: "other_location", label: "Other" },
];

export const OptionWardBuilding = [
  { label: "72 ปี", value: "72 ปี" }, //1
  { label: "84 ปี ", value: "84 ปี" }, //2
  { label: "เฉลิมพระเกียรติ ", value: "เฉลิมพระเกียรติ" }, //3
  { label: "พระศรี ", value: "พระศรี" }, //4
  { label: "เด็ก", value: "เด็ก" }, //5
  { label: "นวมินทรบพิตร", value: "นวมินทรบพิตร" }, //6

  {
    label: "ตึกผู้ป่วยนอก", //  7.2
    value: "ตึกผู้ป่วยนอก",
  },
  {
    label: "สยามินทร์", //  7.3
    value: "สยามินทร์",
  },
  {
    label: "ศูนย์โรคหัวใจ", //  7.4
    value: "ศูนย์โรคหัวใจ",
  },
  {
    label: "สลาก", //  7.5
    value: "สลาก",
  },
  {
    label: "อุบัติเหตุ", //  7.6
    value: "อุบัติเหตุ",
  },

  {
    label: "Short stay", //  7.8
    value: "Short stay",
  },
  { label: "ตึกอื่น ๆ", value: "ตึกอื่น ๆ" }, //7
  { label: "Others", value: "other_location" },
];
export const OptionWard72years = [
  // 1.หอ 72 ปี
  {
    label: "72/4 ประสาทศัลย์ชาย", //1.1
    value: "72/4 ประสาทศัลย์ชาย",
    phone: "97103",
  },
  {
    label: "72/4 ประสาทศัลย์หญิง", //1.2
    value: "72/4 ประสาทศัลย์หญิง",
    phone: "97104",
  },
  {
    label: "72/5 ตอ", //1.3
    value: "72/5 ตอ",
    phone: "96861-2",
  },
  {
    label: "72/5 ตต (ICU premium)", //1.4
    value: "72/5 ตต (ICU premium)",
    phone: "96863-4",
  },

  {
    label: "72/7 หญิงใต้", //1.5
    value: "72/7 หญิงใต้",
    phone: "99682-3, 41101",
  },
  {
    label: "72/7 หญิงเหนือ", //1.6
    value: "72/7 หญิงเหนือ",
    phone: "97113-4, 41102",
  },
  {
    label: "72/7 ชายเหนือ", //1.7
    value: "72/7 ชายเหนือ",
    phone: "97115, 99947",
  },
  {
    label: "72/7 ชายใต้", //1.8
    value: "72/7 ชายใต้",
    phone: "97116, 99203",
  },
  {
    label: "72/8 ตอ", //1.9
    value: "72/8 ตอ",
    phone: "97119, 97121",
  },
  {
    label: "72/8 ตต", //1.10
    value: "72/8 ตต",
    phone: "97120",
  },
  {
    label: "72/9 ออกหญิง", //1.11
    value: "72/9 ออกหญิง",
    phone: "98076, 99287",
  },
  {
    label: "72/9 ออกชาย", //1.12
    value: "72/9 ออกชาย",
    phone: "97223, 9919",
  },
  {
    label: "72/9 ตต", //1.13
    value: "72/9 ตต",
    phone: "98270",
  },
  {
    label: "72/10 (Stroke unit)", //1.14
    value: "72/10 (Stroke unit)",
    phone: "98613-4",
  },
];

export const OptionWard84years = [
  //2. หอ 84 ปี
  {
    label: "84/2 (Transplant unit)", //2.1
    value: "84/2 (Transplant unit)",
    phone: "98080, 97455",
  },
  {
    label: "84/3 ตก ใต้", //2.2
    value: "84/3 ตก ใต้",
    phone: "99049, 97267",
  },
  {
    label: "84/3 ตก เหนือ (ตตจ 2)", //2.3
    value: "84/3 ตก เหนือ (ตตจ 2)",
    phone: "97304-5",
  },
  {
    label: "84/4 ออก", //2.4
    value: "84/4 ออก",
    phone: "97236-7",
  },
  {
    label: "84/4 ตก", //2.5
    value: "84/4 ตก",
    phone: "97238-9",
  },
  {
    label: "84/5 ออก", //2.6
    value: "84/5 ออก",
    phone: "98415, 98590",
  },
  {
    label: "84/5 ตก", //2.7
    value: "84/5 ตก",
    phone: "97240-1",
  },
  {
    label: "84/6 ออก", //2.8
    value: "84/6 ออก",
    phone: "97249-50",
  },
  {
    label: "84/6 ตก", //2.9
    value: "84/6 ตก",
    phone: "97251-2",
  },
  {
    label: "84/7 ออก", //2.10
    value: "84/7 ออก",
    phone: "97255-6",
  },
  {
    label: "84/7 ตก", //2.11
    value: "84/7 ตก",
    phone: "97450, 99112",
  },
  {
    label: "84/8 ออก", //2.12
    value: "84/8 ออก",
    phone: "97257, 98551",
  },
  {
    label: "84/8 ตก", //2.13
    value: "84/8 ตก",
    phone: "97258, 98552",
  },
  {
    label: "84/9 ออก", //2.14
    value: "84/9 ออก",
    phone: "97259-60",
  },
  {
    label: "84/9 ตก", //2.15
    value: "84/9 ตก",
    phone: "97261-2",
  },
  {
    label: "84/10 ออก", //2.16
    value: "84/10 ออก",
    phone: "97263-4",
  },
  {
    label: "84/10 ตก", //2.17
    value: "84/10 ตก",
    phone: "97265-6",
  },
];
export const OptionWardChalerm = [
  // 3.หอเฉลิมพระเกียรติ
  {
    label: "ฉก. 1", //3.1
    value: "ฉก. 1",
    phone: "94040-1",
  },
  {
    label: "ฉก. 2 เหนือ", //3.2
    value: "ฉก. 2 เหนือ",
    phone: "94060",
  },
  {
    label: "ฉก. 2 ใต้", //3.3
    value: "ฉก. 2 ใต้",
    phone: "94070",
  },
  {
    label: "ฉก. 3", //3.4
    value: "ฉก. 3",
    phone: "94090-1",
  },
  {
    label: "ฉก. 4", //3.5
    value: "ฉก. 4",
    phone: "94120",
  },
  {
    label: "ฉก.  5 (ENT)", //3.6
    value: "ฉก.  5 (ENT)",
    phone: "94150",
  },
  {
    label: "ฉก. 6 เหนือ (เด็ก) ", //3.7
    value: "ฉก. 6 เหนือ (เด็ก) ",
    phone: "94205",
  },
  {
    label: "ฉก.  6 ใต้ (BM unit) ", //3.8
    value: "ฉก.  6 ใต้ (BM unit) ",
    phone: "94207-8",
  },
  {
    label: "ฉก. 7 เหนือ ", //3.9
    value: "ฉก. 7 เหนือ ",
    phone: "94238-9",
  },
  {
    label: "ฉก. 7 ใต้", //3.10
    value: "ฉก. 7 ใต้",
    phone: "94240-2",
  },
  {
    label: "ฉก. 10 ใต้", //3.11
    value: "ฉก. 10 ใต้",
    phone: "94355-7",
  },
  {
    label: "ฉก. 11", //3.12
    value: "ฉก. 11",
    phone: "94383",
  },
  {
    label: "ฉก.  14 (Premium)", //3.13
    value: "ฉก.  14 (Premium)",
    phone: "41500-1",
  },
  {
    label: "ฉก. 15", //3.14
    value: "ฉก. 15",
    phone: "94531-2",
  },
  {
    label: "ฉก. 16", //3.15
    value: "ฉก. 16",
    phone: "94553-5",
  },
];

export const OptionWardPraSri = [
  //4.หอพระศรี
  {
    label: "พระศรี 10/1", // 4.1
    value: "พระศรี 10/1",
    phone: "94673-4",
  },
  {
    label: "พระศรี 10/2", // 4.2
    value: "พระศรี 10/2",
    phone: "94675-6",
  },
  {
    label: "พระศรี 10/3", // 4.3
    value: "พระศรี 10/3",
    phone: "94662-3",
  },
  {
    label: "พระศรี 11/1", // 4.4
    value: "พระศรี 11/1",
    phone: "94681-2",
  },
  {
    label: "พระศรี 12/1", // 4.5
    value: "พระศรี 12/1",
    phone: "94686-7",
  },
  {
    label: "พระศรี 12/2", // 4.6
    value: "พระศรี 12/2",
    phone: "94688",
  },
  {
    label: "พระศรี 13/1", // 4.7
    value: "พระศรี 13/1",
    phone: "94696-7",
  },
  {
    label: "พระศรี 13/2", // 4.8
    value: "พระศรี 13/2",
    phone: "94698-9",
  },
  {
    label: "พระศรี 14/1", // 4.9
    value: "พระศรี 14/1",
    phone: "94701",
  },
];

export const OptionWardDek = [
  //5.หอเด็ก
  {
    label: "เจ้าฟ้า 3", // 5.1
    value: "เจ้าฟ้า 3",
    phone: "95861-2",
  },
  {
    label: "เจ้าฟ้า 4", // 5.2
    value: "เจ้าฟ้า 4",
    phone: "95846-8",
  },
  {
    label: "เจ้าฟ้า 5", // 5.3
    value: "เจ้าฟ้า 5",
    phone: "95645,95842",
  },
  {
    label: "เจ้าฟ้า 6", // 5.4
    value: "เจ้าฟ้า 6",
    phone: "95844-5",
  },
  {
    label: "อน. 2 (RCU)", // 5.5
    value: "อน. 2 (RCU)",
    phone: "95806-8",
  },
  {
    label: "อน. 3", // 5.6
    value: "อน. 3",
    phone: "95811-2",
  },
  {
    label: "อน. 4", // 5.7
    value: "อน. 4",
    phone: "95816-7",
  },
  {
    label: "อน. 5", // 5.8
    value: "อน. 5",
    phone: "95822-3",
  },
  {
    label: "อน. 6", // 5.9
    value: "อน. 6",
    phone: "95825-6",
  },
  {
    label: "อน. 7", // 5.10
    value: "อน. 7",
    phone: "95827",
  },
  {
    label: "อน. 7 (Ped CCU)", // 5.11
    value: "อน. 7 (Ped CCU)",
    phone: "95828",
  },
  {
    label: "อน. 9", // 5.12
    value: "อน. 9",
    phone: "99809-10",
  },
];

export const OptionWardNavamin = [
  // 6.นวมินทรบพิตร
  {
    label: "ICCU 7 น.", // 6.1
    value: "ICCU 7 น.",
    phone: "97749, 98874, 40277",
  },
  {
    label: "CCU 7 ต.", // 6.2
    value: "CCU 7 ต.",
    phone: "97751, 98875",
  },
  {
    label: "ICU MED2 8 น.", // 6.3
    value: "ICU MED2 8 น.",
    phone: "99317, 99318",
  },
  {
    label: "ICU MED1 8 ต.", // 6.4
    value: "ICU MED1 8 ต.",
    phone: "97764, 97765",
  },
  {
    label: "นวม. 16 ใต้ ", // 6.32
    value: "นวม. 16 ใต้",
    phone: "97302",
  },
  {
    label: "นวม. 17 น. (ผอบ 6)", // 6.5
    value: "นวม. 17 น. (ผอบ 6)",
    phone: "97454, 97458",
  },
  {
    label: "นวม. 17 ต. (จธ 11)", // 6.6
    value: "นวม. 17 ต. (จธ 11)",
    phone: "97337, 97338",
  },
  {
    label: "นวม. 18 น. (จธ 12)", // 6.7
    value: "นวม. 18 น. (จธ 12)",
    phone: "98050, 98051, 40584",
  },
  {
    label: "นวม. 18 ต. (จธ 13)", // 6.8
    value: "นวม. 18 ต. (จธ 13)",
    phone: "99841, 99842, 40580, 40573",
  },
  {
    label: "นวม. 19 น. (อฎ 10 น)", // 6.9
    value: "นวม. 19 น. (อฎ 10 น)",
    phone: "98267, 40622, 98062, 40612",
  },
  {
    label: "นวม. 19 ต. (อฎ 12 ต)", // 6.10
    value: "นวม. 19 ต. (อฎ 12 ต)",
    phone: "98068, 98464, 40608, 40502",
  },
  {
    label: "นวม. 20 น. (อฎ 12 น)", // 6.11
    value: "นวม. 20 น. (อฎ 12 น)",
    phone: "98066, 40649, 98067, 40640",
  },
  {
    label: "นวม. 20 ต. (อฎ 10 ต)", // 6.12
    value: "นวม. 20 ต. (อฎ 10 ต)",
    phone: "98063, 40631, 98268, 40628",
  },
  {
    label: "นวม. 21 น.(อฎ 11 น)", // 6.13
    value: "นวม. 21 น.(อฎ 11 น)",
    phone: "98064, 98265, 40668",
  },
  {
    label: "นวม. 21 ต. (อฎ 9 ต)", // 6.14
    value: "นวม. 21 ต. (อฎ 9 ต)",
    phone: "98060, 98061, 40655",
  },
  {
    label: "นวม. 22 น. (อฎ 9 น)", // 6.15
    value: "นวม. 22 น. (อฎ 9 น)",
    phone: "98426, 98427, 40695",
  },
  {
    label: "นวม. 22 ต. (อฎ 11 ต)", // 6.16
    value: "นวม. 22 ต. (อฎ 11 ต)",
    phone: "98065, 98266, 40684",
  },
  {
    label: "นวม. 23 น. (อฎ 6 น)", // 6.17
    value: "นวม. 23 น. (อฎ 6 น)",
    phone: "97802, 98269",
  },
  {
    label: "นวม. 23 ต. (อฎ 6 ต)", // 6.18
    value: "นวม. 23 ต. (อฎ 6 ต)",
    phone: "97801, 98057",
  },
  {
    label: "นวม. 24 น. (สลาก 5)", // 6.19
    value: "นวม. 24 น. (สลาก 5)",
    phone: "97531, 97532",
  },
  {
    label: "นวม. 24 ต. (ผอบ 5)", // 6.20
    value: "นวม. 24 ต. (ผอบ 5)",
    phone: "40699, 40700",
  },
  {
    label: "ไตสง่า นิลวรางกูล 10 ต. (โทรหลัง 23 น.)", // 6.21
    value: "ไตสง่า นิลวรางกูล 10 ต. (โทรหลัง 23 น.)",
    phone: "99794-5",
  },
  {
    label: "ไตเทียม Acute", // 6.22
    value: "ไตเทียม Acute",
    phone: "97296-7",
  },

  {
    label: "72/5 ตก ICU premium", // 6.23
    value: "72/5 ตก ICU premium",
    phone: "96863",
  },
  {
    label: "72/5 ออก พิเศษ premium", // 6.24
    value: "72/5 ออก พิเศษ premium",
    phone: "96861",
  },
  {
    label: "Stoke unit 72/10 ตก", // 6.25
    value: "Stoke unit 72/10 ตก",
    phone: "98613-4",
  },
  {
    label: "Bone marrow", // 6.26
    value: "Bone marrow",
    phone: "94207-8",
  },
  {
    label: "PD clinic 10 น.", // 6.27
    value: "PD clinic 10 น.",
    phone: "-",
  },
  {
    label: "Portable CXR", // 6.28
    value: "Portable CXR",
    phone: "97091, 97125",
  },
  {
    label: "OPD ชั้น 3 X-ray", // 6.29
    value: "OPD ชั้น 3 X-ray",
    phone: "97362",
  },
  {
    label: "RCU นวมินทรฯ 9", // 6.30
    value: "RCU นวมินทรฯ 9",
    phone: "97752-3",
  },
  {
    label: "ผู้บาดเจ็บกระดูกสันหลัง นว. 12 น.", // 6.31
    value: "ผู้บาดเจ็บกระดูกสันหลัง นว. 12 น.",
    phone: "9902, 9905, 7521-2",
  },
];

export const OptionWardOther = [
  // 7.อื่นๆ
  {
    label: "ประกันสังคม", //  7.1
    value: "ประกันสังคม",
  },
  // {
  //   label: "ตึกผู้ป่วยนอก", //  7.2
  //   value: "ตึกผู้ป่วยนอก",
  // },
  // {
  //   label: "สยามินทร์", //  7.3
  //   value: "สยามินทร์",
  // },
  // {
  //   label: "ศูนย์โรคหัวใจ", //  7.4
  //   value: "ศูนย์โรคหัวใจ",
  // },
  // {
  //   label: "สลาก", //  7.5
  //   value: "สลาก",
  // },
  // {
  //   label: "อุบัติเหตุ", //  7.6
  //   value: "อุบัติเหตุ",
  // },
  {
    label: "PICU", //  7.7
    value: "PICU",
  },

  // {
  //   label: "Short stay", //  7.8
  //   value: "Short stay",
  // },

  { label: "ห้องตรวจอื่นๆ", value: "ห้องตรวจอื่นๆ" },
];

export const OptionWardPrakan = [
  // 7.1 อื่นๆ > ประกันสังคม
  {
    label: "ห้องตรวจ ปกส.", //  7.1.1
    value: "ห้องตรวจ ปกส.",
    phone: "97524, 98301, 98788, 98869",
  },
  {
    label: "ปกส. 3", //  7.1.2
    value: "ปกส. 3",
    phone: "98051, 98361",
  },
  {
    label: "ปกส. 4", //  7.1.3
    value: "ปกส. 4",
    phone: "97269, 98050",
  },
];

export const OptionWardOutPatient = [
  //7.2 อื่นๆ >ตึกผู้ป่วยนอก
  {
    label: "OPD เคมีบำบัด", //  7.2.1
    value: "OPD เคมีบำบัด",
    phone: "97808",
  },
  {
    label: "จธ 12 (ปาวา 3, พิเศษรวม)", //  7.2.2
    value: "จธ 12 (ปาวา 3, พิเศษรวม)",
    phone: "97269, 98050",
  },
  {
    label: "จธ 13 (หรจ)", //  7.2.3
    value: "จธ 13 (หรจ)",
    phone: "99841-2",
  },
  {
    label: "มว. 2 ทีม 1 (ชั้น 2)", //  7.2.4
    value: "มว. 2 ทีม 1 (ชั้น 2)",
    phone: "97488, 99406",
  },
  {
    label: "มว. 2 ทีม 2 (ชั้น 2)", //  7.2.5
    value: "มว. 2 ทีม 2 (ชั้น 2)",
    phone: "97207, 97487",
  },
  {
    label: "มว. 2 ทีม 3 (ชั้น 1)", //  7.2.6
    value: "มว. 2 ทีม 3 (ชั้น 1)",
    phone: "98694, 98737",
  },
  {
    label: "วธ. 3 ทีม 1", //  7.2.7
    value: "วธ. 3 ทีม 1",
    phone: "97489, 98789",
  },
  {
    label: "วธ. 3 ทีม 2", //  7.2.8
    value: "วธ. 3 ทีม 2",
    phone: "97490, 99899",
  },
];

export const OptionWardSayamin = [
  //7.3 อื่นๆ > สยามินทร์
  {
    label: "ICU อินทรสุขศรี", //  7.3.1
    value: "ICU อินทรสุขศรี",
    phone: "97935-6",
  },
  {
    label: "ICU ตตจ. 1", //  7.3.2
    value: "ICU ตตจ. 1",
    phone: "97939-40",
  },
  {
    label: "ICU สยามินทร์", //  7.3.3
    value: "ICU สยามินทร์",
    phone: "97941-2",
  },
  {
    label: "ICU สลาด-สำอางค์", //  7.3.4
    value: "ICU สลาด-สำอาง",
    phone: "97943-4",
  },

  {
    label: "ICU ประสาทศัลย์", //  7.3.5
    value: "ICU ประสาทศัลย์",
    phone: "97937-8",
  },
];
export const OptionWardHeart = [
  //7.4 อื่นๆ > ศูนย์โรคหัวใจ
  {
    label: "ศูนย์ 5 (ICU)", //  7.4.1
    value: "ศูนย์ 5 (ICU)",
    phone: "96050-1, 96053",
  },
  {
    label: "ศูนย์ 8 (step down)", //  7.4.2
    value: "ศูนย์ 8 (step down)",
    phone: "96080-1",
  },
];
export const OptionWardSalak = [
  //7.5 อื่นๆ > สลาก
  {
    label: "สลาก 2 spine unit", //  7.5.1
    value: "สลาก 2 spine unit",
    phone: "97521-2",
  },
  {
    label: "สลาก 5", //  7.5.2
    value: "สลาก 5",
    phone: "97531-2",
  },
];

export const OptionWardAccident = [
  //7.6 อื่นๆ > อุบัติเหตุ
  {
    label: "อุบัติเหตุ 2 พิเศษ", //  7.6.1
    value: "อุบัติเหตุ 2 พิเศษ",
    phone: "98577-8",
  },
  {
    label: "ICU อุบัติเหตุ", //  7.6.2
    value: "ICU อุบัติเหตุ",
    phone: "97721-2",
  },
  {
    label: "อุบัติเหตุ 3 สามัญ", //  7.6.3
    value: "อุบัติเหตุ 3 สามัญ",
    phone: "97723-4",
  },
  {
    label: "อุบัติเหตุ 3 พิเศษ", //  7.6.4
    value: "อุบัติเหตุ 3 พิเศษ",
    phone: "97725-6",
  },
  {
    label: "อุบัติเหตุ 4", //  7.6.5
    value: "อุบัติเหตุ 4",
    phone: "97731-2",
  },
];
export const OptionWardPICU = [
  //7.7 อื่นๆ > PICU
  {
    label: "PICU (ตึก 10 ชั้น 3)", //  7.7.1
    value: "PICU (ตึก 10 ชั้น 3)",
    phone: "95803-5",
  },
];

export const OptionWardShortStay = [
  //7.8 อื่นๆ > ShortStay
  {
    label: "Short stay", //  7.7.1
    value: "Short stay",
    phone: "97337-8",
  },
];

export const OptionLocation = [
  { label: "อายุรกรรม ", value: "อายุรกรรม" },
  { label: "ศัลยกรรม ", value: "ศัลยกรรม" },
  { label: "สูตินรีเวช ", value: "สูตินรีเวช" },
  { label: "กุมารเวชศาสตร์ ", value: "กุมารเวชศาสตร์" },
  { label: "หออภิบาล ", value: "หออภิบาล" },
  { label: "ผู้ป่วยนอก ", value: "ผู้ป่วยนอก" },
  { value: "other_location", label: "Other" },
];

export const OptionsPatientType = [
  {
    label: "อายุรกรรม",
    value: "อายุรกรรม",
  },
  { label: "ศัลยกรรม", value: "ศัลยกรรม" },
  { label: "สูตินรีเวช", value: "สูตินรีเวช" },
  { label: "กุมารเวชศาสตร์", value: "กุมารเวชศาสตร์" },
  { label: "หออภิบาล", value: "หออภิบาล" },
  { label: "OPD700", value: "ผู้ป่วยนอก" },
  { label: "Others", value: "other_patient_type" },
];

export const OptionWardOtherRoom = [
  {
    label: "ผะอบ 5", //  7.1.4
    value: "ผะอบ 5",
    phone: "97393, 97594",
  },
  {
    label: "ผะอบ 6", //  7.1.5
    value: "ผะอบ 6",
    phone: "97454, 97458",
  },
  {
    label: "แยกโรค (ทองคำ เมฆโต)", //  7.1.6
    value: "แยกโรค (ทองคำ เมฆโต)",
    phone: "98962, 98995",
  },
];

export const OptionsCatheter = [
  // catheter01
  { label: "Arrow 5 Fr 2-lumen PICC", value: "Arrow5Fr2lumenPICC" },
  // catheter02
  { label: "Arrow 4 Fr 1-lumen PICC", value: "Arrow4Fr1lumenPICC" },
  // catheter03
  {
    label: "Bard 5 Fr 2-Lumen power PICC",
    value: "Bard5Fr2LumenpowerPICC",
  },
  //catheter04
  { label: "Vygon 3 Fr 1-lumen PICC", value: "Vygon3Fr1lumenPICC" },
  //catheter05
  {
    label: "Arrow 7 Fr 2-lumen CVL, antimicrobials coated",
    value: "Arrow7Fr2lumenCVLantimicrobialscoated",
  },
  //catheter06
  {
    label: "Arrow 7 Fr 3-lumen CVL, antimicrobials coated",
    value: "Arrow7Fr3lumenCVLantimicrobialscoated",
  },
  //catheter07
  { label: "12 Fr DLC", value: "12FrDLC" },
  //
  { label: "Other", value: "other_catheter" },
];

export const OptionFinalCatheterIn = [
  //CatheterIn01
  { label: "Basilic vein", value: "Basilicvein" },
  //CatheterIn02
  { label: "Brachial vein", value: "Brachialvein" },
  //CatheterIn03
  { label: "Cephalic vein", value: "Cephalicvein" },
  //CatheterIn04
  { label: "Internal jugular vein", value: "Internaljugularvein" },
  //CatheterIn05
  { label: "Subclavian vein", value: "Subclavianvein" },
  //CatheterIn06
  { label: "Femoral vein", value: "Femoralvein" },
  //CatheterIn07
  { label: "Distal femoral vein", value: "Distalfemoralvein" },
  //
  { label: "Other", value: "other_final_cather_in" },
];

export const OptionTorso = [
  { label: "Upper Torso", value: "upper" },
  { label: "Lower Torso", value: "lower" },
];

export const AttendingOptions = [
  {
    value: "จิตสุภา นิธิอุทัย", //1 รายชื่อตาม excel หมอ
    label: "จิตสุภา นิธิอุทัย",
  },

  {
    value: "ชยาภา ลัคนาจันทโชติ", //2
    label: "ชยาภา ลัคนาจันทโชติ",
  },
  {
    value: "ฐานิกา เกียรติชัย", //3
    label: "ฐานิกา เกียรติชัย",
  },
  {
    value: "ณัฐชัย เหมทานนท์", //4
    label: "ณัฐชัย เหมทานนท์",
  },

  {
    value: "ธัชวรรณ จิระติวานนท์", //5
    label: "ธัชวรรณ จิระติวานนท์",
  },
  {
    value: "นนทิดา โรจนพิทยากร", //6
    label: "นนทิดา โรจนพิทยากร",
  },
  {
    value: "ประเสริฐ สวัสดิ์วิภาชัย", //7
    label: "ประเสริฐ สวัสดิ์วิภาชัย",
  },

  {
    value: "ปราณี รัชตามุขยนันต์", //8
    label: "ปราณี รัชตามุขยนันต์",
  },
  {
    value: "ภาวิณี ปางทิพย์อำไพ", //9
    label: "ภาวิณี ปางทิพย์อำไพ",
  },
  {
    value: "ปาริฉัตต์ นิลแย้ม", //10
    label: "ปาริฉัตต์ นิลแย้ม",
  },

  {
    value: "ภัทราพรรณ วงศ์ศรีภูมิเทศ", //11
    label: "ภัทราพรรณ วงศ์ศรีภูมิเทศ",
  },
  {
    value: "ภานพ หลิมรัตน์", //12
    label: "ภานพ หลิมรัตน์",
  },
  {
    value: "ภาวิต สมนึก", //13
    label: "ภาวิต สมนึก",
  },

  {
    value: "รัตนาภรณ์ ตันกูล", //14
    label: "รัตนาภรณ์ ตันกูล",
  },
  {
    value: "วรรธนันทน์ มะกรสาร", //15
    label: "วรรธนันทน์ มะกรสาร",
  },
  {
    value: "สมิตา ไพโรจน์ศักดิ์", //16
    label: "สมิตา ไพโรจน์ศักดิ์",
  },
  {
    value: "สวิตา คณาวิฑูรย์", //17
    label: "สวิตา คณาวิฑูรย์",
  },

  {
    value: "สหัสา หมั่นดี", //18
    label: "สหัสา หมั่นดี",
  },
  {
    value: "สิริมา ภูวนกุลชัย", //19
    label: "สิริมา ภูวนกุลชัย",
  },
  {
    value: "อภิชาต ศุภธรรมวิทย์", //20
    label: "อภิชาต ศุภธรรมวิทย์",
  },

  {
    value: "อรรณพ พิริยะแพทย์สม", //21
    label: "อรรณพ พิริยะแพทย์สม",
  },
  {
    value: "อรวรรณ พงศ์รวีวรรณ", //22
    label: "อรวรรณ พงศ์รวีวรรณ",
  },
];

export const RNOptions = [
  {
    value: "คณิตา กสิการ ", //1 รายชื่อตาม excel พยาบาล
    label: "คณิตา กสิการ ",
  },
  {
    value: "จุฑารัตน์ เวชกามา", //2
    label: "จุฑารัตน์ เวชกามา",
  },
  {
    value: "นภัสร์ชนันท์ เหล่าทวีสุข", //3
    label: "นภัสร์ชนันท์ เหล่าทวีสุข",
  },
  {
    value: "นภัสสร ทุยาติโก ", //4
    label: "นภัสสร ทุยาติโก ",
  },
  {
    value: "นิรชา วงศ์ชมภู", //5
    label: "นิรชา วงศ์ชมภู",
  },
  {
    value: "เบญจมาพร บุญเสวก", //6
    label: "เบญจมาพร บุญเสวก",
  },
  {
    value: "เบ็ญจวรรณ์ คงเมือง", //7
    label: "เบ็ญจวรรณ์ คงเมือง",
  },
  {
    value: "พัชรอร แสงจักร์", //8
    label: "พัชรอร แสงจักร์",
  },
  {
    value: "เพ็ญพักตร์ ดีปินตา", //9
    label: "เพ็ญพักตร์ ดีปินตา",
  },
  {
    value: "ภัสสร ธรรมอักษร", //10
    label: "ภัสสร ธรรมอักษร",
  },
  {
    value: "วรรณภา ติวิรัช", //11
    label: "วรรณภา ติวิรัช",
  },
  {
    value: "อารยา คําทา", //12
    label: "อารยา คําทา",
  },
];

export const wo_rtPAx = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
];
export const co_rtPAx = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
];

export const options_role = [
  {
    value: "DOCTOR",
    label: "Doctor",
  },
  {
    value: "NURSE",
    label: "Nurse",
  },
  {
    value: "OTHER",
    label: "Other",
  },
];

export const options_department = [
  {
    value: "super_AN",
    label: "Super AN",
  },
  {
    value: "AN",
    label: "AN",
  },
  {
    value: "special_IR",
    label: "Special IR",
  },
  {
    value: "IR",
    label: "IR",
  },
  {
    value: "speacial_TR",
    label: "Special TR",
  },
  {
    value: "TR",
    label: "TR",
  },
  {
    value: "special_PED",
    label: "Special PED",
  },
  {
    value: "PED",
    label: "PED",
  },
  {
    value: "NURSE",
    label: "Nurse",
  },
  {
    value: "NURSEWARD",
    label: "Nurse Ward",
  },
  {
    value: "HEMATO",
    label: "Hemato",
  },
];

export const options_role_in_dropdown = [
  {
    value: "DOCTOR",
    label: "Doctor",
  },
  {
    value: "NURSE",
    label: "Nurse",
  },
];
export const options_department_in_dropdown = [
  {
    value: "AN",
    label: "AN",
  },
  {
    value: "IR",
    label: "IR",
  },
  {
    value: "TR",
    label: "TR",
  },
  {
    value: "PED",
    label: "PED",
  },
  {
    value: "NURSE",
    label: "Nurse",
  },
  {
    value: "NURSEWARD",
    label: "Nurse Ward",
  },
];

export const optionFullMonth = [
  { value: "01", label: "มกราคม" },
  { value: "02", label: "กุมภาพันธ์" },
  { value: "03", label: "มีนาคม" },
  { value: "04", label: "เมษายน" },
  { value: "05", label: "พฤษภาคม" },
  { value: "06", label: "มิถุนายน" },
  { value: "07", label: "กรกฎาคม" },
  { value: "08", label: "สิงหาคม" },
  { value: "09", label: "กันยายน" },
  { value: "10", label: "ตุลาคม" },
  { value: "11", label: "พฤศจิกายน" },
  { value: "12", label: "ธันวาคม" },
];
export const optionType = [
  { value: "PICC", label: "Picc Line" },
  { value: "CENTRAL_LINE", label: "Central Line" },
];

export const optionsReasons = [
  { value: "No loanger needed", label: "No longer needed" },
  // { value: "Death", label: "Death" },
  { value: "CLABSI", label: "CLABSI" },
  { value: "Mechanical problems", label: "Mechanical problems" },
  { value: "Self dislodged", label: "Dislodged" },
  // { value: "Dislodged by others", label: "Dislodged by others" },
  { value: "Unresolved occlusion", label: "Unresolved occlusion" },
  { value: "Exit site infection", label: "Exit site infection" },
  { value: "Unintended position", label: "Unintended position" },
  { value: "other_reasons_for_discontinuation", label: "Other" },
];
export const optionsDeath = [{ value: "Death", label: "Death" }];
