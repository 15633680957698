import React, { useEffect } from "react";
import api from "../../../api";
import { useSelector } from "react-redux";
import { fi } from "date-fns/locale";

const getUserDepartment = (userDep) => {
  let result = "";
  if (userDep === "super_AN" || userDep === "AN" || userDep === "NURSE") {
    // setUserDept("AN");

    result = "AN";
  } else if (userDep === "special_IR" || userDep === "IR") {
    // setUserDept("IR");
    result = "IR";
  } else if (userDep === "speacial_TR" || userDep === "TR") {
    // setUserDept("TR");
    result = "TR";
  } else if (userDep === "special_PED" || userDep === "PED") {
    // setUserDept("PED");
    result = "PED";
  } else if (userDep === "All") {
    result = "All";
  }
  return result;
};

const LoadProduction = ({
  setLoading,
  setList,
  setListFollows,
  setCount,
  setListAttempt,
  userSend,
  userDept,
  setReportLoading,
}) => {
  const loadData = async (dataCount) => {
    try {
      const { data: dataAttemptCount } = await api.get("/attempts/count", {
        params: {
          user_department: getUserDepartment(userDept),
        },
      });

      const limit = 500;
      const limitFollow = 500;
      const limitAttempt = 500;

      const countCeil = Math.ceil(dataCount.total / limit) - 1;
      const countCeil_follow =
        Math.ceil(dataCount.totalFollows / limitFollow) - 1;
      const countCeil_attempt =
        Math.ceil(dataAttemptCount.count / limitAttempt) - 1;

      let lookUpTask = {};
      let follows = [];
      let attempts = [];

      // setList([]);

      const isAll = getUserDepartment(userDept) === "All";

      for (let index = 0; index <= countCeil_follow; index++) {
        const { data } = await api.get(
          `/follows?skip=${index * limitFollow}&limit=${limitFollow}`,
          {
            params: {
              user_department: isAll ? null : getUserDepartment(userDept),
            },
          }
        );
        follows.push(...data?.follows);
      }

      for (let index = 0; index <= countCeil_attempt; index++) {
        const { data } = await api.get(
          `/attempts?skip=${index * limitAttempt}&limit=${limitAttempt}`,
          {
            params: {
              user_department: isAll ? null : getUserDepartment(userDept),
            },
          }
        );
        attempts.push(...data?.attempts);
      }

      //

      let resultList = [];

      let dataCountState = 0;

      for (let index = 0; index <= countCeil; index++) {
        let newTasks = [];

        const thisUserDepartment = getUserDepartment(userDept);

        const { data } = await api.get(
          `/tasks?skip=${index * limit}&limit=${limit}&report=true`,
          {
            params: {
              user_department: thisUserDepartment,
            },
          }
        );
        let tasks = data?.tasks;

        for (const task of tasks) {
          const foundFollows = follows.filter((follow) => {
            return follow?.task_id === task?._id;
          });
          const foundAttempts = attempts.filter((attempt) => {
            return attempt?.task_id === task?._id;
          });

          const newTask = {
            ...task,
            follows: foundFollows,
            attempts: foundAttempts,
          };

          lookUpTask = {
            ...lookUpTask,
            [newTask?._id]: {
              follows: newTask?.follows?.length,
              attempts: newTask?.attempts?.length,
            },
          };

          newTasks.push(newTask);

          dataCountState += 1;
        }

        resultList.push(...newTasks);
      }
      setCount(dataCountState);
      setList(resultList); // List = Task
      setListFollows(follows);
      setListAttempt(attempts);
    } catch (error) {
      console.log("error", error);
    }
  };

  const LoadDashboard = async () => {
    setReportLoading(true);
    try {
      const { data } = await api.get("/dashboard/status", {
        params: {
          user_department: getUserDepartment(userDept),
        },
      });
      await loadData(data?.data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      setReportLoading(false);
    }
  };

  LoadDashboard();
};

export const ReportLoadData = ({
  setLoading,
  setList,
  setListFollows,
  setCount,
  setListAttempt,
  userSend,
  userDept,
  setReportLoading,
}) => {
  LoadProduction({
    setLoading,
    setList,
    setListFollows,
    setCount,
    setListAttempt,
    userSend,
    userDept,
    setReportLoading,
  });
};
