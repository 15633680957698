import { ageDayCalculator } from "../../../components/functions/Functions";
import FunctionCheckType from "./Report.FunctionCheckType";

const CountTimePaperToCatheter = ({ list, setValue, type, year, month }) => {
  let a = 0;
  let b = 0;
  let c = 0;

  if (list?.length > 0) {
    for (let row of list) {
      const isTime = Number(
        ageDayCalculator(
          new Date(row?.paper_date),
          new Date(row?.final_date_success)
        )
      );

      const finalDate = new Date(row?.final_date_success);
      const paperDate = new Date(row?.paper_date);

      // Extract years and month (adjusting for zero index)
      const finalYear = finalDate.getFullYear().toString();
      const finalMonth = finalDate.getMonth() + 1;

      const paperYear = paperDate.getFullYear().toString();
      const paperMonth = paperDate.getMonth() + 1;

      // Condition for when to process this item
      const shouldProcess =
        (year === "ALL" || year === finalYear || year === paperYear) &&
        (month === "ALL" ||
          month === finalMonth.toString() ||
          month === paperMonth.toString()) &&
        row?.paper_date !== "" &&
        row?.final_date_success !== "";

      if (shouldProcess) {
        const isCorrectType =
          type === "NONE" ||
          (type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.label,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC") ||
          (type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.label,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE");

        if (isCorrectType) {
          if (isTime <= 2) {
            a++;
          } else if (isTime > 2 && isTime <= 5) {
            b++;
          } else if (isTime >= 6) {
            c++;
          }
        }
      }
    }
    setValue({
      A: a,
      B: b,
      C: c,
    });
  }
};

export default CountTimePaperToCatheter;

// import { ageDayCalculator } from "../../../components/functions/Functions";
// import FunctionCheckType from "./Report.FunctionCheckType";

// const CountTimePaperToCatheter = ({ list, setValue, type, year ,month}) => {
//   let a = 0;
//   let b = 0;
//   let c = 0;

//   if (list?.length > 0) {
//     for (let row of list) {
//       const isTime = Number(
//         ageDayCalculator(
//           new Date(row?.paper_date),
//           new Date(row?.final_date_success)
//         )
//       );
//       // console.log("isTime", isTime);

//       if (row?.paper_date !== "" && row?.final_date_success !== "") {
//         const thisYear = row?.final_date_success ? row?.final_date_success : "";
//         const useYear = thisYear.substring(0, 4);

//         if (year === "ALL") {
//           if (type === "NONE") {
//             if (isTime <= 2) {
//               a++;
//             } else if (isTime > 2 && isTime <= 5) {
//               b++;
//             } else if (isTime >= 6) {
//               c++;
//             }
//           } else if (type === "PICC") {
//             if (
//               FunctionCheckType(
//                 row?.catheter?.label,
//                 row?.other_catheter_name,
//                 row?.check_catheter
//               ) === "PICC"
//             ) {
//               if (isTime <= 2) {
//                 a++;
//               } else if (isTime > 2 && isTime <= 5) {
//                 b++;
//               } else if (isTime >= 6) {
//                 c++;
//               }
//             }

//             //  else {
//             // }
//           } else if (type === "CENTRAL_LINE") {
//             if (
//               FunctionCheckType(
//                 row?.catheter?.label,
//                 row?.other_catheter_name,
//                 row?.check_catheter
//               ) === "CENTRAL_LINE"
//             ) {
//               if (isTime <= 2) {
//                 a++;
//               } else if (isTime > 2 && isTime <= 5) {
//                 b++;
//               } else if (isTime >= 6) {
//                 c++;
//               }
//             }
//           }

//           // return;
//           // return "1";
//         }
//         if (year === "2022") {
//           if (type === "NONE") {
//             if (useYear === "2022") {
//               if (isTime <= 2) {
//                 a++;
//               } else if (isTime > 2 && isTime <= 5) {
//                 b++;
//               } else if (isTime >= 6) {
//                 c++;
//               }
//             }
//           } else if (type === "PICC") {
//             if (
//               FunctionCheckType(
//                 row?.catheter?.label,
//                 row?.other_catheter_name,
//                 row?.check_catheter
//               ) === "PICC" &&
//               useYear === "2022"
//             ) {
//               if (isTime <= 2) {
//                 a++;
//               } else if (isTime > 2 && isTime <= 5) {
//                 b++;
//               } else if (isTime >= 6) {
//                 c++;
//               }
//             }
//           } else if (type === "CENTRAL_LINE") {
//             if (
//               FunctionCheckType(
//                 row?.catheter?.label,
//                 row?.other_catheter_name,
//                 row?.check_catheter
//               ) === "CENTRAL_LINE" &&
//               useYear === "2022"
//             ) {
//               if (isTime <= 2) {
//                 a++;
//               } else if (isTime > 2 && isTime <= 5) {
//                 b++;
//               } else if (isTime >= 6) {
//                 c++;
//               }
//             }
//           }
//         }
//         if (year === "2023") {
//           if (type === "NONE") {
//             if (useYear === "2023") {
//               if (isTime <= 2) {
//                 a++;
//               } else if (isTime > 2 && isTime <= 5) {
//                 b++;
//               } else if (isTime >= 6) {
//                 c++;
//               }
//             }
//           } else if (type === "PICC") {
//             if (
//               FunctionCheckType(
//                 row?.catheter?.label,
//                 row?.other_catheter_name,
//                 row?.check_catheter
//               ) === "PICC" &&
//               useYear === "2023"
//             ) {
//               if (isTime <= 2) {
//                 a++;
//               } else if (isTime > 2 && isTime <= 5) {
//                 b++;
//               } else if (isTime >= 6) {
//                 c++;
//               }
//             }
//           } else if (type === "CENTRAL_LINE") {
//             if (
//               FunctionCheckType(
//                 row?.catheter?.label,
//                 row?.other_catheter_name,
//                 row?.check_catheter
//               ) === "CENTRAL_LINE" &&
//               useYear === "2023"
//             ) {
//               if (isTime <= 2) {
//                 a++;
//               } else if (isTime > 2 && isTime <= 5) {
//                 b++;
//               } else if (isTime >= 6) {
//                 c++;
//               }
//             }
//           }
//         }
//       }
//     }
//     setValue({
//       A: a,
//       B: b,
//       C: c,
//     });
//   }
// };
// export default CountTimePaperToCatheter;
