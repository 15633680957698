import { useState, useEffect } from "react";
import HeadInfo from "./HeadInfo";
import { CelesTableView } from "../attribute/CelesTableView";
import Dropdown from "../field/Dropdown";
import TextField from "../field/TextField";
import FormSummary from "../form/FormSummary";
import Summary from "./Summary";
import NavClick from "../attribute/NavClick";
import { useContext } from "react";
import { TaskContext } from "../../contexts/TaskContext";
import { OptionECG, OptionsCatheter } from "../../contexts/DataOptions";
import api from "../../api";
import Loading from "../loading/Loading";
import { useHistory } from "react-router-dom";
import { getStatusByStatus, getStyleByStatus } from "../../hooks/useClassName";
import { ThaiDigiDate, UploadFileAws } from "../functions/Functions";
import { CelesStandardPopUp } from "../../components/popup/CelesStandardPopUp";
import ta from "date-fns/esm/locale/ta/index.js";
import FormEditFollowUp from "../form/FormEditFollowUp";
import swalAlert from "../../utils/swalAlert";
import ToastsifySuccess from "../loading/ToastsifySuccess";
import { toast, ToastContainer } from "react-toastify";
import { FunctionCloneDeep } from "radium-omnibus";
import FormSummaryShort from "../form/FormSummaryShort";
import followUpStore from "../../_store/followUpStore";

const HeadFollow = ({ form, setField }) => {
  const router = useHistory();
  const [data, setData] = useState([]);

  useEffect(() => {
    LoadDataAttemp();
  }, []);

  useEffect(() => {
    if (data?.length === 0) return;
    if (!data?.date) return;
    const getDate = new Date(data?.date);
    setField("date")(getDate);
  }, [data]);

  const LoadDataAttemp = async () => {
    const { data } = await api.get(
      // `/attempts?task_id=${form?._id}&sort=createdAt:-1&limit=1`
      `/attempts?task_id=${form?._id}&status=Successful`
    );
    setData(data?.attempts[0]);
  };

  // console.log("form?._id", form?._id);
  const formatCon = (landmark, iecg, fluoroscopy) => {
    if (landmark) {
      return "Landmark";
    } else if (iecg && fluoroscopy) {
      return "iECG, Fluoroscopy";
    } else if (iecg) {
      return "iECG";
    } else if (fluoroscopy) {
      return "Fluoroscopy";
    } else {
      return "-";
    }
  };

  const infoFollow = [
    {
      name: "Catheter",
      info:
        form?.catheter?.value?.slice(0, 5) === "other"
          ? form?.other_catheter_name
          : form?.catheter?.label
          ? form?.catheter?.label
          : "-",
    },
    {
      name: "วันที่ใส่สาย",
      info: data?.date ? (
        new Date(data?.date).toLocaleDateString("th-TH")
      ) : (
        <>-</>
      ),
    },
    {
      name: "BW",
      info: form?.weight ? form?.weight + " kg." : <> -</>,
    },
    {
      name: "HT",
      info: form?.height ? form?.height + " cm." : <>-</>,
    },
    {
      name: "สถานที่ใส่",
      info: form?.number
        ? "504.5"
        : form?.beside
        ? "Bedside"
        : form?.other_location
        ? form?.other_location_name
        : "-",
    },

    // {
    //   name: "ECG",
    //   info:
    //     form?.ecg?.value?.slice(0, 5) === "other"
    //       ? form?.other_ecg_name
    //       : form?.ecg?.label
    //       ? form?.ecg?.label
    //       : "-",
    // },
    {
      name: "ด้านที่ใส่สาย",
      info: form?.final_lt ? "Lt (ซ้าย)" : form?.final_rt ? "Rt (ขวา)" : "-",
    },
    {
      name: "หลอดเลือด",
      info:
        form?.final_catheter_in?.value?.slice(0, 5) === "other"
          ? form?.other_final_catheter_in
          : form?.final_catheter_in?.label
          ? form?.final_catheter_in?.label
          : "-",
    },
    {
      name: "ลึก",
      info: form?.indwelled ? form?.indwelled + " cm." : "-",
    },
    {
      name: "ตำแหน่ง",
      info: form?.exit_site_mark ? form?.exit_site_mark + " cm." : "-",
    },
    {
      name: "Confirmation",
      info: formatCon(form?.landmark, form?.iecg, form?.fluoroscopy),
    },

    // {
    //   name: "ตำแหน่ง",
    //   info:
    //     form?.tip_of_catheter?.value?.slice(0, 5) === "other"
    //       ? form?.other_tip_of_catheter
    //       : form?.tip_of_catheter?.label,
    // },
    {
      name: "CXR หลังใส่",
      info: form?.x_ray_date
        ? new Date(form?.x_ray_date).toLocaleDateString("th-TH")
        : "-",
    },
    {
      name: "CXR tip",
      info: form?.tip_of_catheter?.value ? form?.tip_of_catheter?.value : "-",
    },
    {
      name: "Doctor",
      info: data?.attending_name?.label ? data?.attending_name?.label : "-",
    },
    // {
    //   name: "Doctor",
    //   info: form?.doctor?.label
    //     ? form?.doctor?.label
    //     : // : form?.nurse?.label
    //       // ? form?.nurse?.label
    //       // form?.other_person,
    //       "-",
    // },
    {
      name: "Nurse",
      info:
        // data?.attending
        //   ? data?.attending_name?.label

        // form?.doctor?.label
        // ? form?.doctor?.label
        // :
        // form?.nurse?.label ? form?.nurse?.label : form?.other_person,
        form?.nurse?.label ? form?.nurse?.label : "-",
    },
    {
      name: "Other",
      info:
        // data?.attending
        //   ? data?.attending_name?.label

        // form?.doctor?.label
        // ? form?.doctor?.label
        // :
        form?.other_person ? form?.other_person : "-",
    },
  ];
  const StyleInfoFollow = ({ name = "", info }) => {
    return (
      <div className=" lg:grid lg:gird-cols-2 ">
        <div
          className={
            " my-0  md:my-0  lg:my-0  xl:my-0   text-xs  md:text-base lg:text-base xl:text-base  font-bold text-textinput md:flex md:gap-5 flex justify-between "
          }
        >
          {name}
          <div className={"font-normal"}> {info}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="grid ">
        {infoFollow?.map((infoFollow, index) => {
          return (
            <div>
              <div key={index}>
                <StyleInfoFollow
                  name={infoFollow.name}
                  info={infoFollow.info}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const InfoLocation = ({ form }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    LoadLocation();
  }, []);

  const LoadLocation = async () => {
    const { data } = await api.get(`/locations?task_id=${form?._id}`);
    setData(data?.locations);
  };

  console.log("infoData", data);

  return (
    <div>
      <div className=" bg-white w-full h-full p-12 ">
        <div className=" md:text-2xl text-xl  text-textblue font-bold ">
          Tracking Location
        </div>
        {data?.map((row, index) => {
          // console.log(data, "data");
          // console.log(row?.current_location, "row");
          return (
            <div key={index}>
              <div className=" divide-y divide-gray-300 p-5 ">
                <div className=" md:flex md:justify-between justify-items-center items-center py-5 ">
                  <div className=" flex font-bold text-bgbluealist md:text-xl  md:justify-center items-center lg:gap-5 md:gap-10 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10"
                      fill="#CCE4FF"
                      viewBox="0 0 24 24"
                      stroke="#007AFF"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    {row?.other_current_location_name
                      ? row?.other_current_location_name
                      : row?.current_location?.label}
                  </div>
                  <div className=" text-textblue md:text-xl font-bold mt-5 lg:mt-0 ">
                    {new Date(row?.createdAt)?.toLocaleString("th-TH")}
                    {/* ?.slice(0, 16)} */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const FollowMe = ({
  index,
  form,
  setField,
  onClickSave,
  reloadFollowUp,
  isNurse,
  summaryType,
  setSummaryType,
  isNurseWard,
}) => {
  const { initialStateFollow, setForm } = useContext(TaskContext);
  const navName = ["FOLLOW UP", "TRACKING LOCATION"];
  const [nav, setNav] = useState(navName[0]);
  const [displayDrillDown, setDisplayDrillDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tables, setTables] = useState([]);
  const [seeDetailId, setSeeDetailId] = useState("");
  const [currentFollow, setCurrentFollow] = useState(null);

  const [seeDetail, setSeeDetail] = useState(false);
  const [dataFollowId, setDataFollowId] = useState("");
  const [updaNoteId, setUpdaNoteId] = useState("");
  const [updateOpen, setUpdateOpen] = useState(false);
  const [textNote, setTextNote] = useState("");
  const [dataFollow, setDataFollow] = useState([]);
  const [tempDataFollow, setTempDataFollow] = useState([]);

  // console.log("dataFollow", dataFollow);

  const [openEdit, setOpenEdit] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  const isLong = summaryType === "long";

  const { followUpTask, clearFollowUpTask } = followUpStore();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (seeDetailId) {
        const thisData = tempDataFollow.find((row) => row._id === seeDetailId);
        const follow_rn = thisData?.follow_rn || null;
        // console.log(`thisData: `, thisData);
        // console.log(`follow_rn: `, follow_rn);
        if (follow_rn) {
          setField("follow_rn")(follow_rn);
        }
      } else {
        setField("follow_rn")(null);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [seeDetailId]);
  // console.log("form", form);
  // console.log(`tables: `, tables);
  useEffect(() => {
    let isMounted = true;

    if (isMounted && followUpTask?.followID && tables?.length > 0) {
      setSummaryType(followUpTask?.summary_type);
      const followUpTaskId = followUpTask?.followID;
      const followUpTaskIndex = tables.findIndex(
        (table) => table.id === followUpTaskId
      );

      setCurrentFollow(followUpTaskIndex);
      setSeeDetailId(String(followUpTask?.followID));

      CheckViewOnlyByFollowUpDate(
        FunctionFormatBeToAd(tables[followUpTaskIndex]?.dateView)
      ) === "ดูอย่างเดียว"
        ? setViewOnly(true)
        : setViewOnly(false);

      clearFollowUpTask();
    }

    return () => {
      isMounted = false;
    };
  }, [followUpTask?.followID, tables?.length]);

  // console.log(form?.location_ward, "form?.location_ward ");
  // console.log("FORM IN FOLLOW ME : ", form);

  useEffect(() => {
    if (updaNoteId.length === 24 && viewOnly === false) {
      setUpdateOpen(true);
    }
  }, [updaNoteId]);

  useEffect(() => {
    if (seeDetailId?.length) {
      setSeeDetail(true);
    } else {
      setSeeDetail(false);
    }
  }, [seeDetailId]);

  useEffect(() => {
    LoadDataFollowUp();
  }, [displayDrillDown, reloadFollowUp]);

  useEffect(() => {
    if (seeDetailId) {
      LoadFollowUp();
    }
  }, [seeDetail, seeDetailId]);

  useEffect(() => {
    if (dataFollow?.node_etc) {
      setTextNote(dataFollow?.node_etc);
    }
  }, []);

  const LoadFollowUp = async () => {
    const { data: followId } = await api.get(`/follows/${seeDetailId}`);
    setDataFollowId(followId?.follow);
    setSummaryType(
      followId?.follow?.summary_type ? followId?.follow?.summary_type : "long"
    );
  };

  const onClickCancle = () => {
    setDisplayDrillDown(false);
    setSummaryType("");

    setForm((prev) => {
      return {
        ...prev,
        ...initialStateFollow,
      };
    });
  };

  const onClickUpdateNote = async () => {
    try {
      await api.put(`/follows/${updaNoteId}`, {
        node_etc: textNote,
      });
      await api.put(`/tasks/${form?._id}`, {
        note_follow: textNote,
      });
      setUpdateOpen(false);
      setUpdaNoteId("");
      setTextNote("");
      LoadDataFollowUp();
    } catch (error) {
      console.error("ERROR TO SUBMIT NOTE : ", error);
    }
  };

  const FunctionFormatBeToAd = (date) => {
    const splitDate = date.split("/");
    const ad = Number(splitDate[2]) - 543;
    return splitDate[1] + "/" + splitDate[0] + "/" + ad;
  };
  const CheckViewOnlyByFollowUpDate = (input) => {
    const thisDay = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
    const thatDay = new Date(new Date(input).setHours(0, 0, 0, 0)).getTime();
    const oneDay = 86400000;
    // const oneHours = 3600000;

    const howManyDays = (thisDay - thatDay) / oneDay;

    if (howManyDays >= 1) {
      return "ดูอย่างเดียว";
    } else if (howManyDays < 1) {
      return "แก้ไขได้";
    } else {
      return "N/A";
    }
  };

  const LoadDataFollowUp = async () => {
    if (form?._id) {
      const { data } = await api.get(`/follows?task_id=${form?._id}`);
      const array_data = await data?.follows?.map((row, index) => {
        // console.log("roww", row);

        return {
          id: row?._id,
          number: index + 1,
          meet: row?.by_phone
            ? "โทรศัพท์"
            : row?.by_actial_visit
            ? "เข้าเยี่ยม"
            : "",
          location: row?.location_ward,
          problem: row?.node_etc,
          date: row?.date_follow ? ThaiDigiDate(row?.date_follow) : <>-</>,
          time: row?.time_follow,
          // note: row?.node_etc,
          // date:, new Date(row?.createdAt)?.toLocaleDateString("th-TH")
          // time: new Date(row?.createdAt)
          //   ?.toLocaleTimeString("th-TH")
          //   ?.slice(0, 5),
          followup: "Detail",
          by:
            `${row?.attending ? row?.attending?.label : ""}` +
            " " +
            `${row?.follow_rn ? row?.follow_rn?.label : ""}`,
          dateView: row?.date_follow
            ? new Date(row?.date_follow).toLocaleDateString("th-TH")
            : "-",
          // view: row?.date_follow
          //   ? CheckViewOnlyByFollowUpDate(
          //       FunctionFormatBeToAd(
          //         new Date(row?.date_follow).toLocaleDateString("th-TH")
          //       )
          //     )
          //   : "ไม่ได้ใส่วันที่",
        };
      });

      setTables(array_data?.length > 0 ? array_data : []);
      setDataFollow(data?.follows?.slice(data?.follows?.length - 1));
      setTempDataFollow(data?.follows);
    }
  };

  var columns = [
    {
      Header: "NO.",
      accessor: "number",
      Cell: ({ row }) => {
        return (
          <div
            onClick={() => {
              setCurrentFollow(row.index + 1);
              setSeeDetailId(row?.original?.id);

              CheckViewOnlyByFollowUpDate(
                FunctionFormatBeToAd(row.original.dateView)
              ) === "ดูอย่างเดียว"
                ? setViewOnly(true)
                : setViewOnly(false);
            }}
            className={"  cursor-pointer p-2 w-auto mx-auto "}
          >
            {row.original.number}
          </div>
        );
      },
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ row }) => {
        return (
          <div
            onClick={() => {
              setCurrentFollow(row.index + 1);
              setSeeDetailId(row?.original?.id);
              CheckViewOnlyByFollowUpDate(
                FunctionFormatBeToAd(row.original.dateView)
              ) === "ดูอย่างเดียว"
                ? setViewOnly(true)
                : setViewOnly(false);
            }}
            className={" cursor-pointer p-2 w-auto mx-auto "}
          >
            {row.original.date}
          </div>
        );
      },
    },
    {
      Header: "Time ",
      accessor: "time",
      Cell: ({ row }) => {
        return (
          <div
            onClick={() => {
              setCurrentFollow(row.index + 1);
              setSeeDetailId(row?.original?.id);
              CheckViewOnlyByFollowUpDate(
                FunctionFormatBeToAd(row.original.dateView)
              ) === "ดูอย่างเดียว"
                ? setViewOnly(true)
                : setViewOnly(false);
            }}
            className={" cursor-pointer p-2 w-auto mx-auto "}
          >
            {row.original.time}
          </div>
        );
      },
    },
    // { Header: "Location", accessor: "location" },

    {
      Header: "วิธี",
      accessor: "meet",
      Cell: ({ row }) => {
        return (
          <div
            onClick={() => {
              setCurrentFollow(row.index + 1);
              setSeeDetailId(row?.original?.id);
              CheckViewOnlyByFollowUpDate(
                FunctionFormatBeToAd(row.original.dateView)
              ) === "ดูอย่างเดียว"
                ? setViewOnly(true)
                : setViewOnly(false);
            }}
            className={" cursor-pointer p-2 w-auto mx-auto "}
          >
            {row.original.meet}
          </div>
        );
      },
    },
    {
      Header: "โดย",
      accessor: "by",
      Cell: ({ row }) => {
        return (
          <div
            onClick={() => {
              setCurrentFollow(row.index + 1);
              setSeeDetailId(row?.original?.id);
              CheckViewOnlyByFollowUpDate(
                FunctionFormatBeToAd(row.original.dateView)
              ) === "ดูอย่างเดียว"
                ? setViewOnly(true)
                : setViewOnly(false);
            }}
            className={" cursor-pointer p-2 w-auto mx-auto "}
          >
            {row.original.by}
          </div>
        );
      },
    },
    {
      Header: "note",
      accessor: "problem",
      Cell: ({ row }) => {
        return (
          <>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setUpdaNoteId(row.original.id);
                setTextNote(row?.original?.problem);
                CheckViewOnlyByFollowUpDate(
                  FunctionFormatBeToAd(row.original.dateView)
                ) === "ดูอย่างเดียว"
                  ? setViewOnly(true)
                  : setViewOnly(false);
              }}
              className={" cursor-pointer w-auto mx-auto "}
            >
              {row.original.problem ? (
                CheckViewOnlyByFollowUpDate(
                  FunctionFormatBeToAd(row.original.dateView)
                ) === "ดูอย่างเดียว" ? (
                  <div className=" "> {row.original.problem} </div>
                ) : (
                  <div className="rounded-md hover:bg-blue-500 hover:text-white p-2  ">
                    {" "}
                    {row.original.problem}{" "}
                  </div>
                )
              ) : CheckViewOnlyByFollowUpDate(
                  FunctionFormatBeToAd(row.original.dateView)
                ) === "ดูอย่างเดียว" ? (
                <div className=""> - </div>
              ) : (
                <div className="text-sm  text-gray-200 hover:text-gray-500">
                  กดเพื่อเพิ่ม
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "followup",
      Cell: ({ row }) => {
        return (
          <div
            onClick={() => {
              setCurrentFollow(row.index + 1);
              setSeeDetailId(row?.original?.id);
              CheckViewOnlyByFollowUpDate(
                FunctionFormatBeToAd(row.original.dateView)
              ) === "ดูอย่างเดียว"
                ? setViewOnly(true)
                : setViewOnly(false);
            }}
            className={" mx-auto w-auto cursor-pointer"}
          >
            {CheckViewOnlyByFollowUpDate(
              FunctionFormatBeToAd(row.original.dateView)
            ) === "ดูอย่างเดียว" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#D3D3D3"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            )}
          </div>
        );
      },
    },
  ];

  const InfoFollowup = () => {
    return (
      <div>
        <div className=" mt-5 overflow-x-scroll w-full">
          <div
          // style={{
          //   width: 800,
          // }}
          >
            <CelesTableView
              textcolor=""
              hoverbg=" hover:bg-tablesection text-textinput"
              bgseccion=" "
              bgcolor="bg-tablehead text-white"
              isLoading={false}
              data={tables}
              py={"2"}
              columns={columns}
              className=""
            />
          </div>
        </div>
      </div>
    );
  };

  const onClickOutDetail = () => {
    setSeeDetailId();
    setCurrentFollow(null);
    setSeeDetail(false);
  };

  const onClickEdit = () => {
    setOpenEdit(true);
  };

  if (loading) return <Loading />;

  if (seeDetail) {
    // console.log(`KKKKKK`);
    // console.log(`dataFollowId: `, dataFollowId);
    return (
      <div>
        {isLong ? (
          <FormSummary
            onClickSaveFollow={() =>
              onClickSave("FOLLOW UP", null, seeDetailId)
            }
            seeDetailId={seeDetailId}
            disabled={viewOnly ? true : false}
            form={FunctionCloneDeep({
              ...dataFollowId,
              ...form,
              // load_date_follow: dataFollow?.date_follow ? false : true,
              // load_time_follow: dataFollow?.time_follow ? false : true,
            })}
            seeDetail={seeDetail}
            onClickOutDetail={onClickOutDetail}
            count={currentFollow}
            viewOnly={viewOnly}
            setNav={setNav}
            nav={nav}
            setSeeDetailId={setSeeDetailId}
            setCurrentFollow={setCurrentFollow}
            currentFollow={currentFollow}
            LoadFollowUp={LoadFollowUp}
            tables={tables}
            setSeeDetail={setSeeDetail}
          />
        ) : (
          <FormSummaryShort
            onClickSaveFollow={() =>
              onClickSave("FOLLOW UP", null, seeDetailId)
            }
            seeDetailId={seeDetailId}
            disabled={viewOnly ? true : false}
            form={FunctionCloneDeep({
              ...dataFollowId,
              ...form,
              // load_date_follow: "aaa",
              // load_time_follow: "bbb",
            })}
            // loadDateFollow
            // loadTimeFollow
            seeDetail={seeDetail}
            onClickOutDetail={onClickOutDetail}
            count={currentFollow}
            viewOnly={viewOnly}
            setNav={setNav}
            nav={nav}
            setSeeDetailId={setSeeDetailId}
            setCurrentFollow={setCurrentFollow}
            currentFollow={currentFollow}
            LoadFollowUp={LoadFollowUp}
            tables={tables}
            setSeeDetail={setSeeDetail}
          />
        )}
      </div>
    );
  }

  if (displayDrillDown)
    return (
      <div>
        <div onClick={() => setDisplayDrillDown(false)}></div>
        {isLong ? (
          <FormSummary
            seeDetailId={seeDetailId}
            onClickCancle={onClickCancle}
            onClickSaveFollow={() => onClickSave("FOLLOW UP", null, null)}
            onClickFinalSave={() => onClickSave("FINAL")}
            form={form}
            count={tables?.length + 1}
            summaryType={summaryType}
            setSummaryType={setSummaryType}
            create={true}
            loadDateFollow
            loadTimeFollow
          />
        ) : (
          <FormSummaryShort
            seeDetailId={seeDetailId}
            onClickCancle={onClickCancle}
            onClickSaveFollow={() => onClickSave("FOLLOW UP", null, null)}
            onClickFinalSave={() => onClickSave("FINAL")}
            form={form}
            count={tables?.length + 1}
            summaryType={summaryType}
            setSummaryType={setSummaryType}
            create={true}
            loadDateFollow
            loadTimeFollow
          />
        )}
      </div>
    );

  const submitEdit = async () => {
    setEditLoading(true);
    try {
      await api.put(`/tasks/${form?._id}`, {
        // age: `${getAge(form?.dob)?.year} ปี ${getAge(form?.dob)?.month} เดือน `,
        // beside: form?.beside,
        // cad: form?.cad,
        catheter: form?.catheter,
        // chemotherapy: form?.chemotherapy,
        // createdAt: form?.createdAt,
        diagnosis: form?.diagnosis,
        // dm: form?.dm,
        // dob: form?.dob,
        doctor: form?.doctor,
        // dyslipidemia: form?.dyslipidemia,
        // ecg: form?.ecg,
        // esrd: form?.esrd,
        // exit_site_mark: form?.exit_site_mark,
        final_catheter_in: form?.final_catheter_in,
        // final_lt: form?.final_lt,
        // final_rt: form?.final_rt,
        // fluoroscopy: form?.fluoroscopy,
        // frequent_blood_sampling: form?.frequent_blood_sampling,
        // fuided_seldinger: form?.fuided_seldinger,
        // full_name: form?.full_name,
        height: form?.height,
        // hn: form?.hn,
        // htn: form?.htn,
        // iecg: form?.iecg,
        indwelled: form?.indwelled,
        // involved: form?.involved,
        // landmark: form?.landmark,
        // mailignancy: form?.mailignancy,
        // number: form?.number,
        nurse: form?.nurse,
        other_catheter_name: form?.other_catheter_name,
        // other_disease: form?.other_disease,
        // other_disease_name: form?.other_disease_name,
        other_ecg_name: form?.other_ecg_name,
        other_final_catheter_in: form?.other_final_catheter_in,
        // other_indication: form?.other_indication,
        // other_indication_name: form?.other_indication_name,
        // other_location: form?.other_location,
        // other_ward_name: form?.other_ward_name,
        // patient_type: form?.patient_type,
        // other_patient_type_name: form?.other_patient_type_name,
        other_location_name: form?.other_location_name,
        other_person: form?.other_person,
        // other_person: form?.other_person,
        // other_tip_of_catheter: form?.other_tip_of_catheter,
        // patient_phone_number: form?.patient_phone_number,
        // picture_of_exit_site: picture_of_exit_siteKey,
        // prolonged_iv_meds: form?.prolonged_iv_meds,
        // rewire: form?.rewire,
        // percutaneous: form?.percutaneous,
        // status: "on_iv_tube",
        tip_of_catheter: form?.tip_of_catheter,
        // tpn: form?.tpn,
        // vasopressor: form?.vasopressor,
        // ward: form?.ward,
        weight: form?.weight,
        x_ray_date: form?.x_ray_date,
        // current_location: form?.ward?.label,
        // in_ivc: form?.in_ivc,
        // not_in_ivc: form?.not_in_ivc,
        // other_tip_of_catheter_lower: form?.other_tip_of_catheter_lower,
        // other_tip_of_catheter_lower_name:
        //   form?.other_tip_of_catheter_lower_name,
        // attempt_final_date: new Date(),
      });
      toast.success("SUCCESS", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("ERROR TO SUBMIT EDIT : ", error);
    } finally {
      setEditLoading(false);
      setOpenEdit(false);
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <CelesStandardPopUp
        panel={
          <FormEditFollowUp
            editLoading={editLoading}
            setOpenEdit={setOpenEdit}
            submitEdit={submitEdit}
            form={form}
            setField={setField}
          />
        }
        open={openEdit}
        setOpen={setOpenEdit}
      />
      <div className=" my-10">
        {form?.status === "completed" && (
          <Summary data={dataFollow} form={form} />
        )}
        {form?.status === "deceased" && (
          <Summary data={dataFollow} form={form} />
        )}
      </div>

      <div className="flex justify-between items-center mt-5">
        <div className="md:mr-0 text-xl  text-bgbluealist font-bold mb-5 mx-10">
          ALiST Follow up note-
        </div>

        <div className="mx-10 md:space-x-3 md:space-y-0 md:flex space-y-3 xs:grid xs:grid-flow-row">
          {form?.status !== "completed" && form?.status !== "deceased" && (
            <button
              onClick={() => {
                setSummaryType("short");
                setDisplayDrillDown(true);
              }}
              className=" bg-bgbluealist2 hover:bg-bgbluealist  text-bgbluealist hover:text-gray-50 font-semibold mx-auto md:px-5 px-2 rounded-md w-36 py-2"
            >
              เพิ่ม F/U
            </button>
          )}

          {form?.status !== "completed" &&
            form?.status !== "deceased" &&
            !isNurseWard && (
              <button
                onClick={() => {
                  setSummaryType("long");
                  setDisplayDrillDown(true);
                }}
                className=" bg-greenstatus hover:bg-textgreen  text-textgreen hover:text-gray-50 font-semibold mx-auto md:px-5 px-2 rounded-md w-40 py-2"
              >
                เพิ่ม F/U details
              </button>
            )}
          <div
            className={
              "flex justify-center items-center mx-auto p-2 rounded-md  " +
              getStyleByStatus(form?.status)
            }
          >
            {getStatusByStatus(form?.status)}
          </div>
        </div>
      </div>
      <div className="md:mt-20  mt-10 md:grid md:grid-cols-2 gap-10 ">
        <div className=" bg-white w-full h-full p-5 mx-auto md:mx-10 lg:mx-10 xl:mx-10 ">
          <HeadInfo form={form} />
        </div>
        <div className=" bg-white  w-11/12 h-full mx-auto md:-mx-2 lg:-mx-2 xl:-mx-2 p-10 relative">
          <div className="  flex justify-between lg:my-5">
            <div className=" text-xs  md:text-lg xl:text-base text-textinput font-bold flex   xl:justify-start xl:space-x-4">
              <div className="gap-4 -mb-4 md:mb-4 lg:-mb-4 xl:-mb-4">
                <div className="-my-1 md:my-0">Main diagnosis </div>
                <div
                  className="  flex text-xs  md:text-base  xl:text-base text-textinput  my-4  md:my-0 lg:my-0 xl:my-0 
                 font-normal break-all"
                >
                  {form?.diagnosis ? form?.diagnosis : "-"}
                </div>
              </div>
            </div>

            <div className=" ">
              {isNurse ? null : (
                <button
                  onClick={onClickEdit}
                  type="button"
                  className=" absolute top-8 right-8 w-24 inline-flex justify-center items-center text-base rounded-md border xl: text-yellow-500 font-medium hover:text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:shadow-outline  "
                >
                  Edit
                </button>
              )}
            </div>
          </div>

          <HeadFollow form={form} setField={setField} />
        </div>
      </div>
      <div className="md:my-20 my-10">
        <NavClick navName={navName} nav={nav} setNav={setNav} />
        {nav === "FOLLOW UP" && <InfoFollowup nav="FOLLOW UP" />}
        {nav === "TRACKING LOCATION" && (
          <InfoLocation form={form} nav="TRACKING LOCATION" />
        )}
      </div>
      <CelesStandardPopUp
        hidden
        panel={
          <div className="relative  w-auto h-auto  rounded-xl  bg-white">
            <div className="m-4 flex flex-col justify-center items-center  space-y-2">
              <TextField
                title={"ปัญหา / เรื่องราว "}
                classNameTitle="md:text-2xl text-xl text-textinput font-semibold my-5 text-center"
                value={textNote}
                setValue={setTextNote}
              />
              <div className="flex justify-center items-center space-x-2">
                <button
                  onClick={() => onClickUpdateNote()}
                  type="button"
                  className="w-24 my-2 inline-flex justify-center text-base rounded-md bg-green-400 hover:bg-green-500 xl: text-white px-4 py-1 mx-4 font-medium hover:shadow-lg shadow-md "
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setUpdateOpen(false);
                    setUpdaNoteId("");
                  }}
                  className="w-24 my-2 inline-flex justify-center rounded-md  bg-red-400 hover:bg-red-500 xl: px-4 py-1 text-white text-base font-medium hover:shadow-lg shadow-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        }
        open={updateOpen}
        setOpen={setUpdateOpen}
      />
    </div>
  );
};

export default FollowMe;
