import { DebounceInput } from "react-debounce-input";

const HomeSearchBar = ({ setInputSearch, inputSearch }) => {
  return (
    <div className=" md:max-w-md lg:max-w-xl w-4/6 ml-auto relative  md:h-10  text-gray-800 md:text-lg text-sm  md:px-0 ">
      <DebounceInput
        placeholder="Search..."
        className="w-44 md:w-full h-full rounded-full focus:outline-none p-2 md:p-3  pl-8 md:pl-16 lg:pl-16 xl:pl-16 bg-gray-200 mx-5 md:mx-0"
        minLength={3}
        debounceTimeout={500}
        value={inputSearch}
        onChange={(e) => setInputSearch(e.target.value)}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 md:h-5 w-3 md:w-5   absolute top-2 md:top-3  left-8 md:left-5   text-gray-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </div>
  );
};

export default HomeSearchBar;
