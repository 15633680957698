const XLSX = require("xlsx");

export const ExcelTotalDay = (data) => {
  const wb = XLSX.utils.book_new();

  const ws_data = [
    ["Duration", "Total", "", "Adults", "", "Children", ""],
    ["", "Number", "%", "Number", "%", "Number", "%"],
  ];
  Object.keys(data).forEach((key) => {
    ws_data.push([
      key, // Duration label, e.g., "1-7"
      data[key].total, // Total number
      data[key].total_percent + "%", // Total percent
      data[key].adults, // Adults number
      data[key].adults_percent + "%", // Adults percent
      data[key].children, // Children number
      data[key].children_percent + "%", // Children percent
    ]);
  });

  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  XLSX.utils.sheet_add_aoa(ws, [["Duration"]], { origin: "A1" });
  ws["!merges"] = ws["!merges"] || [];
  ws["!merges"].push(XLSX.utils.decode_range("A1:A2"));

  // Merge cells for the 'Total', 'Adults', and 'Children' headers
  ws["!merges"].push(XLSX.utils.decode_range("B1:C1")); // Merging 'Total' cells
  ws["!merges"].push(XLSX.utils.decode_range("D1:E1")); // Merging 'Adults' cells
  ws["!merges"].push(XLSX.utils.decode_range("F1:G1")); // Merging 'Children' cells

  XLSX.utils.book_append_sheet(wb, ws, "Report");

  // If in a browser environment
  if (typeof window !== "undefined" && window) {
    // Trigger a file download in the browser
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "report.xlsx";
    document.body.appendChild(anchor); // Append anchor to the body
    anchor.click();
    document.body.removeChild(anchor); // Clean up and remove the anchor
    window.URL.revokeObjectURL(url); // Release the created URL
  } else {
    // Node.js environment
    XLSX.writeFile(wb, "report.xlsx");
  }
};

// // Sample data
// const sampleData = {
//   "1-7": { total: 70, adults: 68, children: 2 },
//   "8-14": { total: 126, adults: 116, children: 10 },
//   // ... more data
// };

// ExcelTotalDay(sampleData);

// import ExcelExport from "export-xlsx";
// import ReactExport from "react-export-excel";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

// export const ExcelTotalDay = async (inputdata, year, setLoading) => {
//   console.log("inputdata", inputdata);
//   // const inputdata = { data: [{ test: 5 }] };
//   const SETTINGS_FOR_EXPORT = {
//     // Table settings
//     fileName: "ALiST_REPORT",
//     workSheets: [
//       {
//         sheetName: "Report",
//         startingRowNumber: 1,
//         gapBetweenTwoTables: 6,

//         tableSettings: {
//           data: {
//             headerGroups: [
//               //กลุ่มที่ 1
//               {
//                 name: `รายงานจำนวนวันที่ผู้ป่วยแต่ละคนใช้สาย ของปี ${year?.label} `,
//                 key: "header_1",
//               },
//               {
//                 name: `รายงานค่าสถิติของจำนวนวันที่ผู้ป่วยแต่ละคนใช้สาย ของปี ${year?.label} `,
//                 key: "header_2",
//               },
//               //
//               {
//                 name: `1-7 วัน`,
//                 key: "level1",
//                 groupKey: "header_1",
//               },
//               {
//                 name: `8-14 วัน`,
//                 key: "level2",
//                 groupKey: "header_1",
//               },
//               {
//                 name: `15-30 วัน`,
//                 key: "level3",
//                 groupKey: "header_1",
//               },
//               {
//                 name: `31-90 วัน`,
//                 key: "level4",
//                 groupKey: "header_1",
//               },
//               {
//                 name: `91-180 วัน`,
//                 key: "level5",
//                 groupKey: "header_1",
//               },
//               {
//                 name: `181-365 วัน`,
//                 key: "level6",
//                 groupKey: "header_1",
//               },
//               {
//                 name: `มากกว่า 365 วัน`,
//                 key: "level7",
//                 groupKey: "header_1",
//               },
//               //
//               {
//                 name: `mean`,
//                 key: "use_mean",
//                 groupKey: "header_2",
//               },
//               {
//                 name: `median`,
//                 key: "use_median",
//                 groupKey: "header_2",
//               },
//               {
//                 name: `SD`,
//                 key: "use_sd",
//                 groupKey: "header_2",
//               },
//               {
//                 name: `min`,
//                 key: "use_min",
//                 groupKey: "header_2",
//               },
//               {
//                 name: `max`,
//                 key: "use_max",
//                 groupKey: "header_2",
//               },
//               {
//                 name: `Percentile 25th`,
//                 key: "use_per25",
//                 groupKey: "header_2",
//               },
//               {
//                 name: `Percentile 75th`,
//                 key: "use_per75",
//                 groupKey: "header_2",
//               },
//               {
//                 name: `n`,
//                 key: "use_number",
//                 groupKey: "header_2",
//               },

//               //
//             ],
//             headerDefinition: [
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "A",
//                 groupKey: "level1",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "A_adult",
//                 groupKey: "level1",
//               },
//               {
//                 name: `รวม`,
//                 key: "A_kid",
//                 groupKey: "level1",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "B",
//                 groupKey: "level2",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "B_adult",
//                 groupKey: "level2",
//               },
//               {
//                 name: `รวม`,
//                 key: "B_kid",
//                 groupKey: "level2",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "C",
//                 groupKey: "level3",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "C_adult",
//                 groupKey: "level3",
//               },
//               {
//                 name: `รวม`,
//                 key: "C_kid",
//                 groupKey: "level3",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "D",
//                 groupKey: "level4",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "D_adult",
//                 groupKey: "level4",
//               },
//               {
//                 name: `รวม`,
//                 key: "D_kid",
//                 groupKey: "level4",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "E",
//                 groupKey: "level5",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "E_adult",
//                 groupKey: "level5",
//               },
//               {
//                 name: `รวม`,
//                 key: "E_kid",
//                 groupKey: "level5",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "F",
//                 groupKey: "level6",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "F_adult",
//                 groupKey: "level6",
//               },
//               {
//                 name: `รวม`,
//                 key: "F_kid",
//                 groupKey: "level6",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "G",
//                 groupKey: "level7",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "G_adult",
//                 groupKey: "level7",
//               },
//               {
//                 name: `รวม`,
//                 key: "G_kid",
//                 groupKey: "level7",
//               },
//               ////////////////////
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "mean_ad",
//                 groupKey: "use_mean",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "mean_kid",
//                 groupKey: "use_mean",
//               },
//               {
//                 name: `รวม`,
//                 key: "mean",
//                 groupKey: "use_mean",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "median_ad",
//                 groupKey: "use_median",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "median_kid",
//                 groupKey: "use_median",
//               },
//               {
//                 name: `รวม`,
//                 key: "median",
//                 groupKey: "use_median",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "sd_ad",
//                 groupKey: "use_sd",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "sd_kid",
//                 groupKey: "use_sd",
//               },
//               {
//                 name: `รวม`,
//                 key: "sd",
//                 groupKey: "use_sd",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "min_ad",
//                 groupKey: "use_min",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "min_kid",
//                 groupKey: "use_min",
//               },
//               {
//                 name: `รวม`,
//                 key: "min",
//                 groupKey: "use_min",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "max_ad",
//                 groupKey: "use_max",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "max_kid",
//                 groupKey: "use_max",
//               },
//               {
//                 name: `รวม`,
//                 key: "max",
//                 groupKey: "use_max",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "per25_ad",
//                 groupKey: "use_per25",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "per25_kid",
//                 groupKey: "use_per25",
//               },
//               {
//                 name: `รวม`,
//                 key: "per25",
//                 groupKey: "use_per25",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "per75_ad",
//                 groupKey: "use_per75",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "per75_kid",
//                 groupKey: "use_per75",
//               },
//               {
//                 name: `รวม`,
//                 key: "per75",
//                 groupKey: "use_per75",
//               },
//               //
//               {
//                 name: `ผู้ใหญ่`,
//                 key: "number_ad",
//                 groupKey: "use_number",
//               },
//               {
//                 name: `เด็ก`,
//                 key: "number_kid",
//                 groupKey: "use_number",
//               },
//               {
//                 name: `รวม`,
//                 key: "number",
//                 groupKey: "use_number",
//               },
//             ],
//           },
//         },
//       },
//     ],
//   };

//   try {
//     setLoading(true);
//     const excelExport = new ExcelExport();
//     await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
//   } catch (error) {
//     console.log("error", error);
//   } finally {
//     setLoading(false);
//   }
// };
