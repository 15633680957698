import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountHeight = ({ list, setValue, type, year, height }) => {
  let lt_deep1 = 0;
  let lt_deep2 = 0;
  let lt_deep3 = 0;
  let lt_deep4 = 0;
  let lt_deep5 = 0;
  let lt_deep6 = 0;
  let lt_deep7 = 0;
  let lt_deep8 = 0;
  let lt_deep9 = 0;
  let lt_deep10 = 0;
  let lt_deep11 = 0;
  let lt_deep12 = 0;
  //
  let rt_deep1 = 0;
  let rt_deep2 = 0;
  let rt_deep3 = 0;
  let rt_deep4 = 0;
  let rt_deep5 = 0;
  let rt_deep6 = 0;
  let rt_deep7 = 0;
  let rt_deep8 = 0;
  let rt_deep9 = 0;
  let rt_deep10 = 0;
  let rt_deep11 = 0;
  let rt_deep12 = 0;

  if (list?.length > 0) {
    for (let row of list) {
      if (row?.final_date_success !== "") {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);

        const thisHeight = String(row?.height)
          .replace(" ", "")
          .replace("~", "")
          .replace("e", "");
        const useHeight = Number(thisHeight);

        const thisIndwelled = String(row?.indwelled)
          .replace(" ", "")
          .replace("~", "")
          .replace("e", "");
        const useIndwelled = Number(thisIndwelled);

        if (year === "ALL") {
          if (type === "NONE") {
            if (height === "level0") {
              if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                lt_deep1++;
              } else if (
                useIndwelled >= 6 &&
                useIndwelled <= 10 &&
                row?.final_lt
              ) {
                lt_deep2++;
              } else if (
                useIndwelled >= 11 &&
                useIndwelled <= 15 &&
                row?.final_lt
              ) {
                lt_deep3++;
              } else if (
                useIndwelled >= 16 &&
                useIndwelled <= 20 &&
                row?.final_lt
              ) {
                lt_deep4++;
              } else if (
                useIndwelled >= 21 &&
                useIndwelled <= 25 &&
                row?.final_lt
              ) {
                lt_deep5++;
              } else if (
                useIndwelled >= 26 &&
                useIndwelled <= 30 &&
                row?.final_lt
              ) {
                lt_deep6++;
              } else if (
                useIndwelled >= 31 &&
                useIndwelled <= 35 &&
                row?.final_lt
              ) {
                lt_deep7++;
              } else if (
                useIndwelled >= 36 &&
                useIndwelled <= 40 &&
                row?.final_lt
              ) {
                lt_deep8++;
              } else if (
                useIndwelled >= 41 &&
                useIndwelled <= 45 &&
                row?.final_lt
              ) {
                lt_deep9++;
              } else if (
                useIndwelled >= 46 &&
                useIndwelled <= 50 &&
                row?.final_lt
              ) {
                lt_deep10++;
              } else if (
                useIndwelled >= 51 &&
                useIndwelled <= 55 &&
                row?.final_lt
              ) {
                lt_deep11++;
              } else if (
                useIndwelled >= 56 &&
                useIndwelled <= 60 &&
                row?.final_lt
              ) {
                lt_deep12++;
              }
              //
              //

              if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                rt_deep1++;
              } else if (
                useIndwelled >= 6 &&
                useIndwelled <= 10 &&
                row?.final_rt
              ) {
                rt_deep2++;
              } else if (
                useIndwelled >= 11 &&
                useIndwelled <= 15 &&
                row?.final_rt
              ) {
                rt_deep3++;
              } else if (
                useIndwelled >= 16 &&
                useIndwelled <= 20 &&
                row?.final_rt
              ) {
                rt_deep4++;
              } else if (
                useIndwelled >= 21 &&
                useIndwelled <= 25 &&
                row?.final_rt
              ) {
                rt_deep5++;
              } else if (
                useIndwelled >= 26 &&
                useIndwelled <= 30 &&
                row?.final_rt
              ) {
                rt_deep6++;
              } else if (
                useIndwelled >= 31 &&
                useIndwelled <= 35 &&
                row?.final_rt
              ) {
                rt_deep7++;
              } else if (
                useIndwelled >= 36 &&
                useIndwelled <= 40 &&
                row?.final_rt
              ) {
                rt_deep8++;
              } else if (
                useIndwelled >= 41 &&
                useIndwelled <= 45 &&
                row?.final_rt
              ) {
                rt_deep9++;
              } else if (
                useIndwelled >= 46 &&
                useIndwelled <= 50 &&
                row?.final_rt
              ) {
                rt_deep10++;
              } else if (
                useIndwelled >= 51 &&
                useIndwelled <= 55 &&
                row?.final_rt
              ) {
                rt_deep11++;
              } else if (
                useIndwelled >= 56 &&
                useIndwelled <= 60 &&
                row?.final_rt
              ) {
                rt_deep12++;
              }
            }
            if (height === "level1") {
              if (useHeight < 100) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level2") {
              if (useHeight >= 100 && useHeight <= 120) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level3") {
              if (useHeight >= 121 && useHeight <= 140) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level4") {
              if (useHeight >= 141 && useHeight <= 160) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level5") {
              if (useHeight >= 161 && useHeight <= 180) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level6") {
              if (useHeight >= 181 && useHeight <= 200) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level7") {
              if (useHeight > 200) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (height === "level0") {
              if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                lt_deep1++;
              } else if (
                useIndwelled >= 6 &&
                useIndwelled <= 10 &&
                row?.final_lt
              ) {
                lt_deep2++;
              } else if (
                useIndwelled >= 11 &&
                useIndwelled <= 15 &&
                row?.final_lt
              ) {
                lt_deep3++;
              } else if (
                useIndwelled >= 16 &&
                useIndwelled <= 20 &&
                row?.final_lt
              ) {
                lt_deep4++;
              } else if (
                useIndwelled >= 21 &&
                useIndwelled <= 25 &&
                row?.final_lt
              ) {
                lt_deep5++;
              } else if (
                useIndwelled >= 26 &&
                useIndwelled <= 30 &&
                row?.final_lt
              ) {
                lt_deep6++;
              } else if (
                useIndwelled >= 31 &&
                useIndwelled <= 35 &&
                row?.final_lt
              ) {
                lt_deep7++;
              } else if (
                useIndwelled >= 36 &&
                useIndwelled <= 40 &&
                row?.final_lt
              ) {
                lt_deep8++;
              } else if (
                useIndwelled >= 41 &&
                useIndwelled <= 45 &&
                row?.final_lt
              ) {
                lt_deep9++;
              } else if (
                useIndwelled >= 46 &&
                useIndwelled <= 50 &&
                row?.final_lt
              ) {
                lt_deep10++;
              } else if (
                useIndwelled >= 51 &&
                useIndwelled <= 55 &&
                row?.final_lt
              ) {
                lt_deep11++;
              } else if (
                useIndwelled >= 56 &&
                useIndwelled <= 60 &&
                row?.final_lt
              ) {
                lt_deep12++;
              }
              //
              //

              if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                rt_deep1++;
              } else if (
                useIndwelled >= 6 &&
                useIndwelled <= 10 &&
                row?.final_rt
              ) {
                rt_deep2++;
              } else if (
                useIndwelled >= 11 &&
                useIndwelled <= 15 &&
                row?.final_rt
              ) {
                rt_deep3++;
              } else if (
                useIndwelled >= 16 &&
                useIndwelled <= 20 &&
                row?.final_rt
              ) {
                rt_deep4++;
              } else if (
                useIndwelled >= 21 &&
                useIndwelled <= 25 &&
                row?.final_rt
              ) {
                rt_deep5++;
              } else if (
                useIndwelled >= 26 &&
                useIndwelled <= 30 &&
                row?.final_rt
              ) {
                rt_deep6++;
              } else if (
                useIndwelled >= 31 &&
                useIndwelled <= 35 &&
                row?.final_rt
              ) {
                rt_deep7++;
              } else if (
                useIndwelled >= 36 &&
                useIndwelled <= 40 &&
                row?.final_rt
              ) {
                rt_deep8++;
              } else if (
                useIndwelled >= 41 &&
                useIndwelled <= 45 &&
                row?.final_rt
              ) {
                rt_deep9++;
              } else if (
                useIndwelled >= 46 &&
                useIndwelled <= 50 &&
                row?.final_rt
              ) {
                rt_deep10++;
              } else if (
                useIndwelled >= 51 &&
                useIndwelled <= 55 &&
                row?.final_rt
              ) {
                rt_deep11++;
              } else if (
                useIndwelled >= 56 &&
                useIndwelled <= 60 &&
                row?.final_rt
              ) {
                rt_deep12++;
              }
            }
            if (height === "level1") {
              if (useHeight < 100) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level2") {
              if (useHeight >= 100 && useHeight <= 120) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level3") {
              if (useHeight >= 121 && useHeight <= 140) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level4") {
              if (useHeight >= 141 && useHeight <= 160) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level5") {
              if (useHeight >= 161 && useHeight <= 180) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level6") {
              if (useHeight >= 181 && useHeight <= 200) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level7") {
              if (useHeight > 200) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            if (height === "level0") {
              if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                lt_deep1++;
              } else if (
                useIndwelled >= 6 &&
                useIndwelled <= 10 &&
                row?.final_lt
              ) {
                lt_deep2++;
              } else if (
                useIndwelled >= 11 &&
                useIndwelled <= 15 &&
                row?.final_lt
              ) {
                lt_deep3++;
              } else if (
                useIndwelled >= 16 &&
                useIndwelled <= 20 &&
                row?.final_lt
              ) {
                lt_deep4++;
              } else if (
                useIndwelled >= 21 &&
                useIndwelled <= 25 &&
                row?.final_lt
              ) {
                lt_deep5++;
              } else if (
                useIndwelled >= 26 &&
                useIndwelled <= 30 &&
                row?.final_lt
              ) {
                lt_deep6++;
              } else if (
                useIndwelled >= 31 &&
                useIndwelled <= 35 &&
                row?.final_lt
              ) {
                lt_deep7++;
              } else if (
                useIndwelled >= 36 &&
                useIndwelled <= 40 &&
                row?.final_lt
              ) {
                lt_deep8++;
              } else if (
                useIndwelled >= 41 &&
                useIndwelled <= 45 &&
                row?.final_lt
              ) {
                lt_deep9++;
              } else if (
                useIndwelled >= 46 &&
                useIndwelled <= 50 &&
                row?.final_lt
              ) {
                lt_deep10++;
              } else if (
                useIndwelled >= 51 &&
                useIndwelled <= 55 &&
                row?.final_lt
              ) {
                lt_deep11++;
              } else if (
                useIndwelled >= 56 &&
                useIndwelled <= 60 &&
                row?.final_lt
              ) {
                lt_deep12++;
              }
              //
              //

              if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                rt_deep1++;
              } else if (
                useIndwelled >= 6 &&
                useIndwelled <= 10 &&
                row?.final_rt
              ) {
                rt_deep2++;
              } else if (
                useIndwelled >= 11 &&
                useIndwelled <= 15 &&
                row?.final_rt
              ) {
                rt_deep3++;
              } else if (
                useIndwelled >= 16 &&
                useIndwelled <= 20 &&
                row?.final_rt
              ) {
                rt_deep4++;
              } else if (
                useIndwelled >= 21 &&
                useIndwelled <= 25 &&
                row?.final_rt
              ) {
                rt_deep5++;
              } else if (
                useIndwelled >= 26 &&
                useIndwelled <= 30 &&
                row?.final_rt
              ) {
                rt_deep6++;
              } else if (
                useIndwelled >= 31 &&
                useIndwelled <= 35 &&
                row?.final_rt
              ) {
                rt_deep7++;
              } else if (
                useIndwelled >= 36 &&
                useIndwelled <= 40 &&
                row?.final_rt
              ) {
                rt_deep8++;
              } else if (
                useIndwelled >= 41 &&
                useIndwelled <= 45 &&
                row?.final_rt
              ) {
                rt_deep9++;
              } else if (
                useIndwelled >= 46 &&
                useIndwelled <= 50 &&
                row?.final_rt
              ) {
                rt_deep10++;
              } else if (
                useIndwelled >= 51 &&
                useIndwelled <= 55 &&
                row?.final_rt
              ) {
                rt_deep11++;
              } else if (
                useIndwelled >= 56 &&
                useIndwelled <= 60 &&
                row?.final_rt
              ) {
                rt_deep12++;
              }
            }

            if (height === "level1") {
              if (useHeight < 100) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level2") {
              if (useHeight >= 100 && useHeight <= 120) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level3") {
              if (useHeight >= 121 && useHeight <= 140) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level4") {
              if (useHeight >= 141 && useHeight <= 160) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level5") {
              if (useHeight >= 161 && useHeight <= 180) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level6") {
              if (useHeight >= 181 && useHeight <= 200) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level7") {
              if (useHeight > 200) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
          }
        }
        if (year === useYear) {
          if (type === "NONE") {
            if (height === "level0") {
              if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                lt_deep1++;
              } else if (
                useIndwelled >= 6 &&
                useIndwelled <= 10 &&
                row?.final_lt
              ) {
                lt_deep2++;
              } else if (
                useIndwelled >= 11 &&
                useIndwelled <= 15 &&
                row?.final_lt
              ) {
                lt_deep3++;
              } else if (
                useIndwelled >= 16 &&
                useIndwelled <= 20 &&
                row?.final_lt
              ) {
                lt_deep4++;
              } else if (
                useIndwelled >= 21 &&
                useIndwelled <= 25 &&
                row?.final_lt
              ) {
                lt_deep5++;
              } else if (
                useIndwelled >= 26 &&
                useIndwelled <= 30 &&
                row?.final_lt
              ) {
                lt_deep6++;
              } else if (
                useIndwelled >= 31 &&
                useIndwelled <= 35 &&
                row?.final_lt
              ) {
                lt_deep7++;
              } else if (
                useIndwelled >= 36 &&
                useIndwelled <= 40 &&
                row?.final_lt
              ) {
                lt_deep8++;
              } else if (
                useIndwelled >= 41 &&
                useIndwelled <= 45 &&
                row?.final_lt
              ) {
                lt_deep9++;
              } else if (
                useIndwelled >= 46 &&
                useIndwelled <= 50 &&
                row?.final_lt
              ) {
                lt_deep10++;
              } else if (
                useIndwelled >= 51 &&
                useIndwelled <= 55 &&
                row?.final_lt
              ) {
                lt_deep11++;
              } else if (
                useIndwelled >= 56 &&
                useIndwelled <= 60 &&
                row?.final_lt
              ) {
                lt_deep12++;
              }
              //
              //

              if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                rt_deep1++;
              } else if (
                useIndwelled >= 6 &&
                useIndwelled <= 10 &&
                row?.final_rt
              ) {
                rt_deep2++;
              } else if (
                useIndwelled >= 11 &&
                useIndwelled <= 15 &&
                row?.final_rt
              ) {
                rt_deep3++;
              } else if (
                useIndwelled >= 16 &&
                useIndwelled <= 20 &&
                row?.final_rt
              ) {
                rt_deep4++;
              } else if (
                useIndwelled >= 21 &&
                useIndwelled <= 25 &&
                row?.final_rt
              ) {
                rt_deep5++;
              } else if (
                useIndwelled >= 26 &&
                useIndwelled <= 30 &&
                row?.final_rt
              ) {
                rt_deep6++;
              } else if (
                useIndwelled >= 31 &&
                useIndwelled <= 35 &&
                row?.final_rt
              ) {
                rt_deep7++;
              } else if (
                useIndwelled >= 36 &&
                useIndwelled <= 40 &&
                row?.final_rt
              ) {
                rt_deep8++;
              } else if (
                useIndwelled >= 41 &&
                useIndwelled <= 45 &&
                row?.final_rt
              ) {
                rt_deep9++;
              } else if (
                useIndwelled >= 46 &&
                useIndwelled <= 50 &&
                row?.final_rt
              ) {
                rt_deep10++;
              } else if (
                useIndwelled >= 51 &&
                useIndwelled <= 55 &&
                row?.final_rt
              ) {
                rt_deep11++;
              } else if (
                useIndwelled >= 56 &&
                useIndwelled <= 60 &&
                row?.final_rt
              ) {
                rt_deep12++;
              }
            }
            if (height === "level1") {
              if (useHeight < 100) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level2") {
              if (useHeight >= 100 && useHeight <= 120) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level3") {
              if (useHeight >= 121 && useHeight <= 140) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level4") {
              if (useHeight >= 141 && useHeight <= 160) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level5") {
              if (useHeight >= 161 && useHeight <= 180) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level6") {
              if (useHeight >= 181 && useHeight <= 200) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level7") {
              if (useHeight > 200) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (height === "level0") {
              if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                lt_deep1++;
              } else if (
                useIndwelled >= 6 &&
                useIndwelled <= 10 &&
                row?.final_lt
              ) {
                lt_deep2++;
              } else if (
                useIndwelled >= 11 &&
                useIndwelled <= 15 &&
                row?.final_lt
              ) {
                lt_deep3++;
              } else if (
                useIndwelled >= 16 &&
                useIndwelled <= 20 &&
                row?.final_lt
              ) {
                lt_deep4++;
              } else if (
                useIndwelled >= 21 &&
                useIndwelled <= 25 &&
                row?.final_lt
              ) {
                lt_deep5++;
              } else if (
                useIndwelled >= 26 &&
                useIndwelled <= 30 &&
                row?.final_lt
              ) {
                lt_deep6++;
              } else if (
                useIndwelled >= 31 &&
                useIndwelled <= 35 &&
                row?.final_lt
              ) {
                lt_deep7++;
              } else if (
                useIndwelled >= 36 &&
                useIndwelled <= 40 &&
                row?.final_lt
              ) {
                lt_deep8++;
              } else if (
                useIndwelled >= 41 &&
                useIndwelled <= 45 &&
                row?.final_lt
              ) {
                lt_deep9++;
              } else if (
                useIndwelled >= 46 &&
                useIndwelled <= 50 &&
                row?.final_lt
              ) {
                lt_deep10++;
              } else if (
                useIndwelled >= 51 &&
                useIndwelled <= 55 &&
                row?.final_lt
              ) {
                lt_deep11++;
              } else if (
                useIndwelled >= 56 &&
                useIndwelled <= 60 &&
                row?.final_lt
              ) {
                lt_deep12++;
              }
              //
              //

              if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                rt_deep1++;
              } else if (
                useIndwelled >= 6 &&
                useIndwelled <= 10 &&
                row?.final_rt
              ) {
                rt_deep2++;
              } else if (
                useIndwelled >= 11 &&
                useIndwelled <= 15 &&
                row?.final_rt
              ) {
                rt_deep3++;
              } else if (
                useIndwelled >= 16 &&
                useIndwelled <= 20 &&
                row?.final_rt
              ) {
                rt_deep4++;
              } else if (
                useIndwelled >= 21 &&
                useIndwelled <= 25 &&
                row?.final_rt
              ) {
                rt_deep5++;
              } else if (
                useIndwelled >= 26 &&
                useIndwelled <= 30 &&
                row?.final_rt
              ) {
                rt_deep6++;
              } else if (
                useIndwelled >= 31 &&
                useIndwelled <= 35 &&
                row?.final_rt
              ) {
                rt_deep7++;
              } else if (
                useIndwelled >= 36 &&
                useIndwelled <= 40 &&
                row?.final_rt
              ) {
                rt_deep8++;
              } else if (
                useIndwelled >= 41 &&
                useIndwelled <= 45 &&
                row?.final_rt
              ) {
                rt_deep9++;
              } else if (
                useIndwelled >= 46 &&
                useIndwelled <= 50 &&
                row?.final_rt
              ) {
                rt_deep10++;
              } else if (
                useIndwelled >= 51 &&
                useIndwelled <= 55 &&
                row?.final_rt
              ) {
                rt_deep11++;
              } else if (
                useIndwelled >= 56 &&
                useIndwelled <= 60 &&
                row?.final_rt
              ) {
                rt_deep12++;
              }
            }
            if (height === "level1") {
              if (useHeight < 100) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level2") {
              if (useHeight >= 100 && useHeight <= 120) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level3") {
              if (useHeight >= 121 && useHeight <= 140) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level4") {
              if (useHeight >= 141 && useHeight <= 160) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level5") {
              if (useHeight >= 161 && useHeight <= 180) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level6") {
              if (useHeight >= 181 && useHeight <= 200) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level7") {
              if (useHeight > 200) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            if (height === "level0") {
              if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                lt_deep1++;
              } else if (
                useIndwelled >= 6 &&
                useIndwelled <= 10 &&
                row?.final_lt
              ) {
                lt_deep2++;
              } else if (
                useIndwelled >= 11 &&
                useIndwelled <= 15 &&
                row?.final_lt
              ) {
                lt_deep3++;
              } else if (
                useIndwelled >= 16 &&
                useIndwelled <= 20 &&
                row?.final_lt
              ) {
                lt_deep4++;
              } else if (
                useIndwelled >= 21 &&
                useIndwelled <= 25 &&
                row?.final_lt
              ) {
                lt_deep5++;
              } else if (
                useIndwelled >= 26 &&
                useIndwelled <= 30 &&
                row?.final_lt
              ) {
                lt_deep6++;
              } else if (
                useIndwelled >= 31 &&
                useIndwelled <= 35 &&
                row?.final_lt
              ) {
                lt_deep7++;
              } else if (
                useIndwelled >= 36 &&
                useIndwelled <= 40 &&
                row?.final_lt
              ) {
                lt_deep8++;
              } else if (
                useIndwelled >= 41 &&
                useIndwelled <= 45 &&
                row?.final_lt
              ) {
                lt_deep9++;
              } else if (
                useIndwelled >= 46 &&
                useIndwelled <= 50 &&
                row?.final_lt
              ) {
                lt_deep10++;
              } else if (
                useIndwelled >= 51 &&
                useIndwelled <= 55 &&
                row?.final_lt
              ) {
                lt_deep11++;
              } else if (
                useIndwelled >= 56 &&
                useIndwelled <= 60 &&
                row?.final_lt
              ) {
                lt_deep12++;
              }
              //
              //

              if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                rt_deep1++;
              } else if (
                useIndwelled >= 6 &&
                useIndwelled <= 10 &&
                row?.final_rt
              ) {
                rt_deep2++;
              } else if (
                useIndwelled >= 11 &&
                useIndwelled <= 15 &&
                row?.final_rt
              ) {
                rt_deep3++;
              } else if (
                useIndwelled >= 16 &&
                useIndwelled <= 20 &&
                row?.final_rt
              ) {
                rt_deep4++;
              } else if (
                useIndwelled >= 21 &&
                useIndwelled <= 25 &&
                row?.final_rt
              ) {
                rt_deep5++;
              } else if (
                useIndwelled >= 26 &&
                useIndwelled <= 30 &&
                row?.final_rt
              ) {
                rt_deep6++;
              } else if (
                useIndwelled >= 31 &&
                useIndwelled <= 35 &&
                row?.final_rt
              ) {
                rt_deep7++;
              } else if (
                useIndwelled >= 36 &&
                useIndwelled <= 40 &&
                row?.final_rt
              ) {
                rt_deep8++;
              } else if (
                useIndwelled >= 41 &&
                useIndwelled <= 45 &&
                row?.final_rt
              ) {
                rt_deep9++;
              } else if (
                useIndwelled >= 46 &&
                useIndwelled <= 50 &&
                row?.final_rt
              ) {
                rt_deep10++;
              } else if (
                useIndwelled >= 51 &&
                useIndwelled <= 55 &&
                row?.final_rt
              ) {
                rt_deep11++;
              } else if (
                useIndwelled >= 56 &&
                useIndwelled <= 60 &&
                row?.final_rt
              ) {
                rt_deep12++;
              }
            }

            if (height === "level1") {
              if (useHeight < 100) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level2") {
              if (useHeight >= 100 && useHeight <= 120) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level3") {
              if (useHeight >= 121 && useHeight <= 140) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level4") {
              if (useHeight >= 141 && useHeight <= 160) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level5") {
              if (useHeight >= 161 && useHeight <= 180) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level6") {
              if (useHeight >= 181 && useHeight <= 200) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
            if (height === "level7") {
              if (useHeight > 200) {
                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
                  lt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_lt
                ) {
                  lt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_lt
                ) {
                  lt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_lt
                ) {
                  lt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_lt
                ) {
                  lt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_lt
                ) {
                  lt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_lt
                ) {
                  lt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_lt
                ) {
                  lt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_lt
                ) {
                  lt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_lt
                ) {
                  lt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_lt
                ) {
                  lt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_lt
                ) {
                  lt_deep12++;
                }
                //
                //

                if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
                  rt_deep1++;
                } else if (
                  useIndwelled >= 6 &&
                  useIndwelled <= 10 &&
                  row?.final_rt
                ) {
                  rt_deep2++;
                } else if (
                  useIndwelled >= 11 &&
                  useIndwelled <= 15 &&
                  row?.final_rt
                ) {
                  rt_deep3++;
                } else if (
                  useIndwelled >= 16 &&
                  useIndwelled <= 20 &&
                  row?.final_rt
                ) {
                  rt_deep4++;
                } else if (
                  useIndwelled >= 21 &&
                  useIndwelled <= 25 &&
                  row?.final_rt
                ) {
                  rt_deep5++;
                } else if (
                  useIndwelled >= 26 &&
                  useIndwelled <= 30 &&
                  row?.final_rt
                ) {
                  rt_deep6++;
                } else if (
                  useIndwelled >= 31 &&
                  useIndwelled <= 35 &&
                  row?.final_rt
                ) {
                  rt_deep7++;
                } else if (
                  useIndwelled >= 36 &&
                  useIndwelled <= 40 &&
                  row?.final_rt
                ) {
                  rt_deep8++;
                } else if (
                  useIndwelled >= 41 &&
                  useIndwelled <= 45 &&
                  row?.final_rt
                ) {
                  rt_deep9++;
                } else if (
                  useIndwelled >= 46 &&
                  useIndwelled <= 50 &&
                  row?.final_rt
                ) {
                  rt_deep10++;
                } else if (
                  useIndwelled >= 51 &&
                  useIndwelled <= 55 &&
                  row?.final_rt
                ) {
                  rt_deep11++;
                } else if (
                  useIndwelled >= 56 &&
                  useIndwelled <= 60 &&
                  row?.final_rt
                ) {
                  rt_deep12++;
                }
              }
            }
          }
        }
        // if (year === "2023" && useYear === "2023") {
        //   if (type === "NONE") {
        //     if (height === "level0") {
        //       if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //         lt_deep1++;
        //       } else if (
        //         useIndwelled >= 6 &&
        //         useIndwelled <= 10 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep2++;
        //       } else if (
        //         useIndwelled >= 11 &&
        //         useIndwelled <= 15 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep3++;
        //       } else if (
        //         useIndwelled >= 16 &&
        //         useIndwelled <= 20 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep4++;
        //       } else if (
        //         useIndwelled >= 21 &&
        //         useIndwelled <= 25 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep5++;
        //       } else if (
        //         useIndwelled >= 26 &&
        //         useIndwelled <= 30 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep6++;
        //       } else if (
        //         useIndwelled >= 31 &&
        //         useIndwelled <= 35 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep7++;
        //       } else if (
        //         useIndwelled >= 36 &&
        //         useIndwelled <= 40 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep8++;
        //       } else if (
        //         useIndwelled >= 41 &&
        //         useIndwelled <= 45 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep9++;
        //       } else if (
        //         useIndwelled >= 46 &&
        //         useIndwelled <= 50 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep10++;
        //       } else if (
        //         useIndwelled >= 51 &&
        //         useIndwelled <= 55 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep11++;
        //       } else if (
        //         useIndwelled >= 56 &&
        //         useIndwelled <= 60 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep12++;
        //       }
        //       //
        //       //

        //       if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //         rt_deep1++;
        //       } else if (
        //         useIndwelled >= 6 &&
        //         useIndwelled <= 10 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep2++;
        //       } else if (
        //         useIndwelled >= 11 &&
        //         useIndwelled <= 15 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep3++;
        //       } else if (
        //         useIndwelled >= 16 &&
        //         useIndwelled <= 20 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep4++;
        //       } else if (
        //         useIndwelled >= 21 &&
        //         useIndwelled <= 25 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep5++;
        //       } else if (
        //         useIndwelled >= 26 &&
        //         useIndwelled <= 30 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep6++;
        //       } else if (
        //         useIndwelled >= 31 &&
        //         useIndwelled <= 35 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep7++;
        //       } else if (
        //         useIndwelled >= 36 &&
        //         useIndwelled <= 40 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep8++;
        //       } else if (
        //         useIndwelled >= 41 &&
        //         useIndwelled <= 45 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep9++;
        //       } else if (
        //         useIndwelled >= 46 &&
        //         useIndwelled <= 50 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep10++;
        //       } else if (
        //         useIndwelled >= 51 &&
        //         useIndwelled <= 55 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep11++;
        //       } else if (
        //         useIndwelled >= 56 &&
        //         useIndwelled <= 60 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep12++;
        //       }
        //     }
        //     if (height === "level1") {
        //       if (useHeight < 100) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level2") {
        //       if (useHeight >= 100 && useHeight <= 120) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level3") {
        //       if (useHeight >= 121 && useHeight <= 140) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level4") {
        //       if (useHeight >= 141 && useHeight <= 160) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level5") {
        //       if (useHeight >= 161 && useHeight <= 180) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level6") {
        //       if (useHeight >= 181 && useHeight <= 200) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level7") {
        //       if (useHeight > 200) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //   }
        //   if (
        //     type === "PICC" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     if (height === "level0") {
        //       if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //         lt_deep1++;
        //       } else if (
        //         useIndwelled >= 6 &&
        //         useIndwelled <= 10 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep2++;
        //       } else if (
        //         useIndwelled >= 11 &&
        //         useIndwelled <= 15 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep3++;
        //       } else if (
        //         useIndwelled >= 16 &&
        //         useIndwelled <= 20 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep4++;
        //       } else if (
        //         useIndwelled >= 21 &&
        //         useIndwelled <= 25 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep5++;
        //       } else if (
        //         useIndwelled >= 26 &&
        //         useIndwelled <= 30 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep6++;
        //       } else if (
        //         useIndwelled >= 31 &&
        //         useIndwelled <= 35 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep7++;
        //       } else if (
        //         useIndwelled >= 36 &&
        //         useIndwelled <= 40 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep8++;
        //       } else if (
        //         useIndwelled >= 41 &&
        //         useIndwelled <= 45 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep9++;
        //       } else if (
        //         useIndwelled >= 46 &&
        //         useIndwelled <= 50 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep10++;
        //       } else if (
        //         useIndwelled >= 51 &&
        //         useIndwelled <= 55 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep11++;
        //       } else if (
        //         useIndwelled >= 56 &&
        //         useIndwelled <= 60 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep12++;
        //       }
        //       //
        //       //

        //       if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //         rt_deep1++;
        //       } else if (
        //         useIndwelled >= 6 &&
        //         useIndwelled <= 10 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep2++;
        //       } else if (
        //         useIndwelled >= 11 &&
        //         useIndwelled <= 15 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep3++;
        //       } else if (
        //         useIndwelled >= 16 &&
        //         useIndwelled <= 20 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep4++;
        //       } else if (
        //         useIndwelled >= 21 &&
        //         useIndwelled <= 25 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep5++;
        //       } else if (
        //         useIndwelled >= 26 &&
        //         useIndwelled <= 30 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep6++;
        //       } else if (
        //         useIndwelled >= 31 &&
        //         useIndwelled <= 35 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep7++;
        //       } else if (
        //         useIndwelled >= 36 &&
        //         useIndwelled <= 40 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep8++;
        //       } else if (
        //         useIndwelled >= 41 &&
        //         useIndwelled <= 45 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep9++;
        //       } else if (
        //         useIndwelled >= 46 &&
        //         useIndwelled <= 50 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep10++;
        //       } else if (
        //         useIndwelled >= 51 &&
        //         useIndwelled <= 55 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep11++;
        //       } else if (
        //         useIndwelled >= 56 &&
        //         useIndwelled <= 60 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep12++;
        //       }
        //     }
        //     if (height === "level1") {
        //       if (useHeight < 100) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level2") {
        //       if (useHeight >= 100 && useHeight <= 120) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level3") {
        //       if (useHeight >= 121 && useHeight <= 140) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level4") {
        //       if (useHeight >= 141 && useHeight <= 160) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level5") {
        //       if (useHeight >= 161 && useHeight <= 180) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level6") {
        //       if (useHeight >= 181 && useHeight <= 200) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level7") {
        //       if (useHeight > 200) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //   }
        //   if (
        //     type === "CENTRAL_LINE" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     if (height === "level0") {
        //       if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //         lt_deep1++;
        //       } else if (
        //         useIndwelled >= 6 &&
        //         useIndwelled <= 10 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep2++;
        //       } else if (
        //         useIndwelled >= 11 &&
        //         useIndwelled <= 15 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep3++;
        //       } else if (
        //         useIndwelled >= 16 &&
        //         useIndwelled <= 20 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep4++;
        //       } else if (
        //         useIndwelled >= 21 &&
        //         useIndwelled <= 25 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep5++;
        //       } else if (
        //         useIndwelled >= 26 &&
        //         useIndwelled <= 30 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep6++;
        //       } else if (
        //         useIndwelled >= 31 &&
        //         useIndwelled <= 35 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep7++;
        //       } else if (
        //         useIndwelled >= 36 &&
        //         useIndwelled <= 40 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep8++;
        //       } else if (
        //         useIndwelled >= 41 &&
        //         useIndwelled <= 45 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep9++;
        //       } else if (
        //         useIndwelled >= 46 &&
        //         useIndwelled <= 50 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep10++;
        //       } else if (
        //         useIndwelled >= 51 &&
        //         useIndwelled <= 55 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep11++;
        //       } else if (
        //         useIndwelled >= 56 &&
        //         useIndwelled <= 60 &&
        //         row?.final_lt
        //       ) {
        //         lt_deep12++;
        //       }
        //       //
        //       //

        //       if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //         rt_deep1++;
        //       } else if (
        //         useIndwelled >= 6 &&
        //         useIndwelled <= 10 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep2++;
        //       } else if (
        //         useIndwelled >= 11 &&
        //         useIndwelled <= 15 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep3++;
        //       } else if (
        //         useIndwelled >= 16 &&
        //         useIndwelled <= 20 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep4++;
        //       } else if (
        //         useIndwelled >= 21 &&
        //         useIndwelled <= 25 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep5++;
        //       } else if (
        //         useIndwelled >= 26 &&
        //         useIndwelled <= 30 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep6++;
        //       } else if (
        //         useIndwelled >= 31 &&
        //         useIndwelled <= 35 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep7++;
        //       } else if (
        //         useIndwelled >= 36 &&
        //         useIndwelled <= 40 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep8++;
        //       } else if (
        //         useIndwelled >= 41 &&
        //         useIndwelled <= 45 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep9++;
        //       } else if (
        //         useIndwelled >= 46 &&
        //         useIndwelled <= 50 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep10++;
        //       } else if (
        //         useIndwelled >= 51 &&
        //         useIndwelled <= 55 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep11++;
        //       } else if (
        //         useIndwelled >= 56 &&
        //         useIndwelled <= 60 &&
        //         row?.final_rt
        //       ) {
        //         rt_deep12++;
        //       }
        //     }

        //     if (height === "level1") {
        //       if (useHeight < 100) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level2") {
        //       if (useHeight >= 100 && useHeight <= 120) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level3") {
        //       if (useHeight >= 121 && useHeight <= 140) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level4") {
        //       if (useHeight >= 141 && useHeight <= 160) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level5") {
        //       if (useHeight >= 161 && useHeight <= 180) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level6") {
        //       if (useHeight >= 181 && useHeight <= 200) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //     if (height === "level7") {
        //       if (useHeight > 200) {
        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_lt) {
        //           lt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_lt
        //         ) {
        //           lt_deep12++;
        //         }
        //         //
        //         //

        //         if (useIndwelled >= 0 && useIndwelled <= 5 && row?.final_rt) {
        //           rt_deep1++;
        //         } else if (
        //           useIndwelled >= 6 &&
        //           useIndwelled <= 10 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep2++;
        //         } else if (
        //           useIndwelled >= 11 &&
        //           useIndwelled <= 15 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep3++;
        //         } else if (
        //           useIndwelled >= 16 &&
        //           useIndwelled <= 20 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep4++;
        //         } else if (
        //           useIndwelled >= 21 &&
        //           useIndwelled <= 25 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep5++;
        //         } else if (
        //           useIndwelled >= 26 &&
        //           useIndwelled <= 30 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep6++;
        //         } else if (
        //           useIndwelled >= 31 &&
        //           useIndwelled <= 35 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep7++;
        //         } else if (
        //           useIndwelled >= 36 &&
        //           useIndwelled <= 40 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep8++;
        //         } else if (
        //           useIndwelled >= 41 &&
        //           useIndwelled <= 45 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep9++;
        //         } else if (
        //           useIndwelled >= 46 &&
        //           useIndwelled <= 50 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep10++;
        //         } else if (
        //           useIndwelled >= 51 &&
        //           useIndwelled <= 55 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep11++;
        //         } else if (
        //           useIndwelled >= 56 &&
        //           useIndwelled <= 60 &&
        //           row?.final_rt
        //         ) {
        //           rt_deep12++;
        //         }
        //       }
        //     }
        //   }
        // }
      }
    }
    setValue({
      lt_deep1: lt_deep1,
      lt_deep2: lt_deep2,
      lt_deep3: lt_deep3,
      lt_deep4: lt_deep4,
      lt_deep5: lt_deep5,
      lt_deep6: lt_deep6,
      lt_deep7: lt_deep7,
      lt_deep8: lt_deep8,
      lt_deep9: lt_deep9,
      lt_deep10: lt_deep10,
      lt_deep11: lt_deep11,
      lt_deep12: lt_deep12,
      //
      rt_deep1: rt_deep1,
      rt_deep2: rt_deep2,
      rt_deep3: rt_deep3,
      rt_deep4: rt_deep4,
      rt_deep5: rt_deep5,
      rt_deep6: rt_deep6,
      rt_deep7: rt_deep7,
      rt_deep8: rt_deep8,
      rt_deep9: rt_deep9,
      rt_deep10: rt_deep10,
      rt_deep11: rt_deep11,
      rt_deep12: rt_deep12,
    });
  }
};

export default ReportCountHeight;
