import { useState, createContext, useCallback } from "react";
import useForm from "../hooks/useForm";

// create context
const TaskContext = createContext({});

const initialAttempts = {
  attending: false,
  attending_name: { label: "", value: "" },
  date: new Date(),
  fellow: false,
  fellow_name: "",
  femoral: false,
  le: false,
  line_finalized: "",
  lt: false,
  neck: false,
  note: "",
  other_position: false,
  other_position_name: "",
  out_of_room: "",
  puncture: "",
  resident: false,
  resident_name: "",
  room_in: "",
  rt: false,
  subcalvian: false,
  ue: false,
  complications_pneumothorax: false,
  complications_hemothorax: false,
  complications_arterial_puncture: false,
  complications_paresthesia: false,
  complications_nerve_injury: false,
  complications_limb_ischemia_related_to_arterial_puncture: false,
  other_complications: false,
  date: null,
  other_complications_name: "",
  other: false,
  other_name: "",
  check_attemp: "",
};

const initialStateFollow = {
  attending: { label: "", value: "" },
  by_actial_visit: false,
  by_phone: false,
  exit_site_note: "",
  exit_site_ok: false,
  line_co_distal: false,
  line_co_observe: false,
  line_co_port: false,
  line_co_proximal: false,
  line_co_purple: false,
  line_co_result_co: false,
  line_co_result_opened: false,
  line_co_result_wo: false,
  line_co_rtpax: false,
  line_co_rtpax_name: { label: "", value: "" },
  line_co_white: false,
  line_disledge_breakage_leak_port: false,
  line_disledge_distal: false,
  line_disledge_indwellend: false,
  line_disledge_indwellend_name: "",
  line_disledge_new_exit_mark_at: false,
  line_disledge_new_exit_mark_at_name: "",
  line_disledge_nil: false,
  line_disledge_proximal: false,
  line_disledge_purple: false,
  line_disledge_since_date: "",
  line_disledge_red: false,
  line_infection_bug_found: false,
  line_infection_bug_found_cs_plus: false,
  line_infection_bug_found_distal: false,
  line_infection_bug_found_organism: "",
  line_infection_bug_found_proximal: false,
  line_infection_bug_found_purple: false,
  line_infection_bug_found_white: false,
  line_infection_nil: false,
  line_ok_all: false,
  line_retained_fb_distal: false,
  line_retained_fb_port: false,
  line_retained_fb_proximal: false,
  line_retained_fb_purple: false,
  line_retained_fb_since_date: "",
  line_retained_fb_red: false,
  line_wo_distal: false,
  line_wo_observe: false,
  line_wo_port: false,
  line_wo_proximal: false,
  line_wo_purple: false,
  line_wo_result_co: false,
  line_wo_result_opened: false,
  line_wo_result_wo: false,
  line_wo_rtpax: false,
  line_wo_rtpax_name: { label: "", value: "" },
  line_wo_white: false,
  other_exit_site: false,
  other_exit_site_name: "",
  other_line_co_port: false,
  other_line_co_port_name: "",
  other_line_disledge: false,
  other_line_disledge_name: "",
  other_line_infection_bug_found: false,
  other_line_infection_bug_found_name: "",
  other_line_retained_fb: false,
  other_line_retained_fb_name: "",
  other_line_wo_port: false,
  other_line_wo_port_name: "",
  picture_exit_site_summary: "",
  follow_rn: { label: "", value: "" },
  line_is_no_loanger: "",
  total_cather_day: "",
  summary_alive: false,
  summary_deceased: false,
  reasons_for_discontinuation: { label: "", value: "" },
  other_reasons_for_discontinuation_name: "",
  more_patient: [
    {
      phone_number: "",
      relation: "",
    },
  ],
};

// context provider
const TaskProvider = ({ children }) => {
  const [editing, setEditing] = useState(false);

  const {
    form,
    setField,
    setForm,
    setFieldMulti,
    setFieldRemoveMulti,
    setFieldAddMulti,
    setFieldUploadMulti,
  } = useForm({});

  const onDropFileSingle = (primaryField, field, index) => (acceptedFiles) => {
    setFieldMulti(field, primaryField, index)(acceptedFiles[0]);
  };

  const removeArray = (primaryField, index) => {
    setFieldRemoveMulti(primaryField, index)();
  };

  const addArray = (primaryField, defaultValue) => {
    setFieldAddMulti(primaryField, defaultValue);
  };

  const value = {
    form,
    setField,
    setForm,
    setFieldMulti,
    setFieldRemoveMulti,
    setFieldAddMulti,
    addArray,
    removeArray,
    onDropFileSingle,
    editing,
    setEditing,
    setFieldUploadMulti,
    initialAttempts,
    initialStateFollow,
  };

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};

export { TaskContext, TaskProvider };
