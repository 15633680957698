import TextField from "./TextField";

const CheckBoxWithTextField = ({
  title,
  name,
  disabled = false,
  value,
  textFieldName,
  setField = false,
  textFieldValue,
  unit,
  className = "flex justify-start items-center  space-x-2 rounded-xl my-4 mx-11",
  type = "text",
  setValue = false,
  setStateObject = false,
  classNamelabel = "md:text-lg text-base text-textblue",
}) => {
  const onChange = (e) => {
    const value = e.target.checked;

    if (setValue && setStateObject) {
      setValue((prev) => {
        let temp = { ...prev };
        temp[name] = value;
        return { ...temp };
      });
    } else if (setField) {
      setField(name)(value);
    } else if (setValue) {
      setValue(value);
    }
  };

  return (
    <div>
      <div className={className}>
        <input
          disabled={disabled}
          type="checkbox"
          name={name}
          checked={value}
          onChange={onChange}
        />
        <div className={classNamelabel}>{title}</div>
      </div>
      {value && (
        <div className={unit ? "flex" : ""}>
          <TextField
            disabled={disabled}
            placeholder={title}
            title=""
            name={textFieldName}
            setField={setField}
            value={textFieldValue}
            type={type}
            setValue={setValue}
            setStateObject={setStateObject}
          />
          {unit && <div className="my-auto ml-4 text-textinput ">{unit} </div>}
        </div>
      )}
    </div>
  );
};
export default CheckBoxWithTextField;
