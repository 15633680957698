import { set } from "lodash";
import followUpStore from "../../_store/followUpStore";
import { useHistory } from "react-router-dom";

const FollowUpTable = ({
  followUpTable,
  setFollowUpTable,
  selectedFollowIds,
  setSelectedFollowIds,
  setWellReceived,
  wellReceived,
  checkAll,
  setCheckAll,
}) => {
  const { followUpTask, setFollowUpTask } = followUpStore();
  const router = useHistory();

  const setFollowTaskAndRouter = ({ taskID, followID, summary_type }) => {
    setFollowUpTask({ followID, summary_type });
    router.push(`/info/${taskID}`);
  };

  // console.log("followUpTable", followUpTable);
  // console.log("selectedFollowIds", selectedFollowIds);
  return (
    <div>
      <div className="text-textred2 text-base font-bold pl-4">
        แจ้งสายผิดปกติ
      </div>
      <div className="bg-tablered text-white h-12 px-1">
        <div className="grid grid-cols-10 h-12  px-1 items-center justify-center">
          <div className="text-white font-bold items-center justify-center text-center">
            NO.
          </div>
          <div className="text-white font-bold  items-center justify-center text-center">
            HN
          </div>
          <div className="text-white font-bold  items-center justify-center text-center">
            Name
          </div>
          <div className="text-white font-bold  items-center justify-center text-center">
            แผนก
          </div>
          <div className="flex text-white font-bold  items-center  space-x-2  col-span-6">
            <div>
              <input
                type="checkbox"
                checked={checkAll}
                onChange={(e) => {
                  setSelectedFollowIds((prev) => {
                    let temp = { ...prev };
                    Object.keys(temp).forEach((key) => {
                      temp[key] = e.target.checked;
                    });
                    return { ...temp };
                  });
                  setCheckAll(true);
                  setWellReceived(true);
                }}
              />
            </div>
            <div>เลือกทั้งหมด</div>
          </div>
        </div>
      </div>
      {followUpTable?.map((item, index) => {
        return (
          <div
            key={index}
            className="grid grid-cols-10 h-12  px-1 items-center justify-center border-b-2 border-gray-100 cursor-pointer hover:bg-gray-50"
            onClick={() => {}}
          >
            <div
              className="text-center"
              onClick={() => {
                setFollowTaskAndRouter({
                  taskID: item?.task_id,
                  followID: item?.id,
                  summary_type: item?.summary_type,
                });
              }}
            >
              {index + 1}
            </div>
            <div
              className="text-center"
              onClick={() => {
                setFollowTaskAndRouter({
                  taskID: item?.task_id,
                  followID: item.id,
                  summary_type: item?.summary_type,
                });
              }}
            >
              {item.HN}
            </div>
            <div
              className="text-center"
              onClick={() => {
                setFollowTaskAndRouter({
                  taskID: item?.task_id,
                  followID: item.id,
                  summary_type: item?.summary_type,
                });
              }}
            >
              {item.name}
            </div>
            <div
              className="text-center"
              onClick={() => {
                setFollowTaskAndRouter({
                  taskID: item?.task_id,
                  followID: item.id,
                  summary_type: item?.summary_type,
                });
              }}
            >
              {item.user_department}
            </div>
            <div className="flex items-center  space-x-2 col-span-6">
              <div>
                <input
                  type="checkbox"
                  checked={selectedFollowIds[item.id]}
                  onChange={(e) => {
                    setSelectedFollowIds((prev) => {
                      let temp = { ...prev };
                      temp[item.id] = e.target.checked;
                      return { ...temp };
                    });
                    setWellReceived(true);
                  }}
                />
              </div>
              <div>รับทราบ</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FollowUpTable;
