import {
  formateDateWithTime,
  ThaiDigiDate,
  timeDifference,
} from "../functions/Functions";

const HeadSummary = ({ data, form }) => {
  console.log("form ", form);
  const zero = data[0];

  // console.log("form?.final_date_success", form?.final_date_success);
  const infoHeadSummary = [
    {
      name: "Line is no longer used on date",
      info: new Date(zero?.line_is_no_loanger).toLocaleDateString("th-TH"),
    },
    {
      name: "Total catheter day",
      info: timeDifference(
        new Date(`${form?.line_is_no_loanger}`),
        new Date(`${form?.final_date_success}`)
      ),
    },
    {
      name: "Status ",
      info: zero?.summary_alive
        ? "Alive"
        : zero?.summary_deceased
        ? "deceased"
        : "Unknown",
    },
    {
      name: "Reasons for discontinuation ",
      info:
        zero?.reasons_for_discontinuation?.value?.slice(0, 5) === "other"
          ? zero?.other_reasons_for_discontinuation_name
          : zero?.reasons_for_discontinuation?.label,
    },
  ];
  const StyleHeadSummary = ({ name = "", info }) => {
    return (
      <div className=" flex justify-between ">
        <div className={" font-bold text-textgrayalist line-clamp-1 "}>
          {name}
        </div>
        <div
          className={" md:text-lg  font-normal text-textgrayalist line-clamp-1"}
        >
          {info}
        </div>
      </div>
    );
  };

  return (
    <div className="p-4  ">
      <div className=" lg:grid lg:gird-cols-2  md:mx-4 lg:mx-10 xl:mx-4">
        {infoHeadSummary?.map((infoHeadSummary, index) => {
          return (
            <div>
              <div key={index}>
                <StyleHeadSummary
                  name={infoHeadSummary.name}
                  info={infoHeadSummary.info}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const Summary = ({ data, form }) => {
  return (
    <div>
      <div className=" bg-bgbluesummary  w-full h-auto border-bgbluealist border-4  justify-center items-center md:p-10 p-5">
        <div className="text-2xl md:text-5xl font-bold text-bgbluealist text-center mb-5  ">
          Summary
        </div>
        <HeadSummary data={data} form={form} />
      </div>
    </div>
  );
};
export default Summary;
