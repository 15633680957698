import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import NavClick from "../../components/attribute/NavClick";
import FollowMe from "../../components/infomation/FollowMe";
import Full from "../../components/infomation/Full";
import Initial from "../../components/infomation/Initial";
import Procedure from "../../components/infomation/Procedure";
import Loading from "../../components/loading/Loading";
import { TaskContext } from "../../contexts/TaskContext";
import api from "../../api";
import {
  ageCalculator,
  UploadFileAws,
} from "../../components/functions/Functions";
import { useSelector } from "react-redux";
import HistoryMain from "./historyMain";
import FunctionCheckType from "../report/functions/Report.FunctionCheckType";
import { toast, ToastContainer } from "react-toastify";

const Info = () => {
  const { form, setField, setForm } = useContext(TaskContext);
  const search = useLocation();
  const router = useHistory();
  const [saved, setSaved] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const userDept = user?.user_department;
  const [userDeptforSubmit, setUserDeptforSubmit] = useState("");
  const [summaryType, setSummaryType] = useState("");

  const currentUserDeptOnForm = form?.user_department;

  // console.log("userInfo", user);

  // console.log("currentUserDeptOnForm", currentUserDeptOnForm);
  // console.log("userDeptforSubmit", userDeptforSubmit);

  useEffect(() => {
    if (userDept === "super_AN" || userDept === "AN" || userDept === "HEMATO") {
      setUserDeptforSubmit("AN");
    } else if (userDept === "special_IR" || userDept === "IR") {
      setUserDeptforSubmit("IR");
    } else if (userDept === "speacial_TR" || userDept === "TR") {
      setUserDeptforSubmit("TR");
    } else if (userDept === "special_PED" || userDept === "PED") {
      setUserDeptforSubmit("PED");
    } else if (userDept === "NURSEWARD") {
      setUserDeptforSubmit(currentUserDeptOnForm);
    }
  }, [userDept, currentUserDeptOnForm]);

  const isNurse = user?.user_department === "NURSE";
  const isNurseWard = user?.user_department === "NURSEWARD";

  useEffect(() => {
    if (form.ward) {
      setForm((prev) => {
        return {
          ...prev,
          current_location: form?.ward?.value,
        };
      });
    }
  }, [form.ward]);

  const checkStatusToCreateNav = (status, userDept) => {
    if (userDept === "HEMATO") {
      return ["INITIAL DATABASE", "FULL DATABASE"];
    }
    // if (userDept === "NURSEWARD") {
    //   return ["FOLLOW UP"];
    // }
    if (status === "deceased")
      return [
        "INITIAL DATABASE",
        "FULL DATABASE",
        "PROCEDURE NOTE",
        "FOLLOW UP",
      ];
    else if (status === "completed")
      return [
        "INITIAL DATABASE",
        "FULL DATABASE",
        "PROCEDURE NOTE",
        "FOLLOW UP",
      ];
    else if (status === "on_iv_tube")
      return [
        "INITIAL DATABASE",
        "FULL DATABASE",
        "PROCEDURE NOTE",
        "FOLLOW UP",
      ];
    else if (status === "waiting_full" || status === "unfollow") {
      return ["INITIAL DATABASE", "FULL DATABASE", "PROCEDURE NOTE"];
    } else if (status === "waiting")
      return ["INITIAL DATABASE", "FULL DATABASE"];
    else {
      return ["INITIAL DATABASE"];
    }
  };

  const NavSetForm = (input) => {
    if (input === "waiting") {
      return "FULL DATABASE";
    } else if (input === "waiting_full" || input === "unfollow") {
      return "PROCEDURE NOTE";
    } else if (input === "on_iv_tube") {
      return "FOLLOW UP";
    } else if (input === "completed") {
      return "FOLLOW UP";
    } else if (input === "deceased") {
      return "FOLLOW UP";
    }
  };

  const navName = [
    "INITIAL DATABASE",
    "FULL DATABASE",
    "PROCEDURE NOTE",
    "FOLLOW UP",
  ];

  const [nav, setNav] = useState(navName[0]);
  const [loading, setLoading] = useState(true);
  const [reloadFollowUp, setReloadFollowUp] = useState(false);
  const [history, setHistory] = useState([]);
  const [undo, setUndo] = useState([]);
  const [redo, setRedo] = useState([]);

  const navigate = useHistory();

  useEffect(() => {
    Loaddata();
  }, []);

  const onBackButtonEvent = (e) => {
    if (form?._id) {
      sessionStorage.setItem("last", form?._id);
    }
    if (undo.length > 0) {
      setNav(undo?.pop());
      setUndo((prev) => {
        let temp = [...prev];
        temp.slice(0, -1);
        return [...temp];
      });
    } else {
      navigate.push("/");
    }
  };

  useEffect(() => {
    if (window !== undefined && window !== null) {
      window?.addEventListener("popstate", onBackButtonEvent);
      return () => {
        window?.removeEventListener("popstate", onBackButtonEvent);
      };
    }
  }, [undo]);

  const Loaddata = async () => {
    setLoading(true);
    try {
      setForm("");
      const id = search?.pathname?.slice(6, 30);
      if (id.length === 24) {
        const { data } = await api.get(`/tasks/${id}`);

        // console.log("data", data);

        const isMorePatient = data.task?.more_patient?.length;

        if (!isMorePatient) {
          data.task["more_patient"] = [
            {
              phone_number: "",
              relation: "",
            },
          ];
        }

        const { data: dataAttempts } = await api.get(
          `/attempts?task_id=${data?.task._id}`
        );

        let filterAttempt = null;

        if (dataAttempts.attempts) {
          filterAttempt = dataAttempts?.attempts.find((item) => {
            return item?.status === "Successful";
          });
        }

        // if (filterAttempt) {
        //   setField("attempts_final_obj")(filterAttempt);
        // }
        // filterAttempt

        if (nav === "FOLLOW UP") {
          setReloadFollowUp(true);
          // console.log("form", form);
          // return setForm({
          //   ...data?.task,
          //   attempts_final_obj: filterAttempt,
          //   ...form,
          // });
        }
        setHistory(data?.task?.history);
        setForm({ ...data?.task, attempts_final_obj: filterAttempt });
        if (userDept === "HEMATO") {
          setNav("FULL DATABASE");
        } else {
          setNav(
            data?.task?.status ? NavSetForm(data?.task?.status) : navName[0]
          );
        }
      }
      return;
    } catch (error) {
      console.error("ERROR_TO_LOAD_DATA : ", error);
    } finally {
      setLoading(false);
    }
  };

  //ปุ่มเซฟ
  const onClickSave = async (page, isNextStep = false, updateId) => {
    // setLoading(true);
    // console.log(" location_ward: form?.ward", form?.ward);

    let checkFinal = false;

    let respon = {};

    try {
      // setSaved(true);

      let newFrom = { ...form };
      // console.log("newFrom>>>>>>", newFrom);
      const key_picture_consultation = await UploadFileAws(
        form?.picture_consultation
      );

      const key_picture_exit_site_key = await UploadFileAws(
        form?.picture_of_exit_site
      );

      const key_picture_exit_site_summary = await UploadFileAws(
        form?.picture_exit_site_summary
      );

      if (form?.dob) {
        newFrom = {
          ...form,
          age: ageCalculator(new Date(form?.dob)),
        };
      }

      if (key_picture_consultation) {
        newFrom = {
          ...form,
          picture_consultation: key_picture_consultation,
        };
      }

      if (key_picture_exit_site_key) {
        newFrom = {
          ...form,
          picture_of_exit_site: key_picture_exit_site_key,
        };
      }

      if (key_picture_exit_site_summary) {
        newFrom = {
          ...form,
          picture_exit_site_summary: key_picture_exit_site_summary,
        };
      }

      if (page === "INITIAL DATABASE") {
        // console.log("IN INITIAL DATABASE", isNextStep);

        if (
          !form?.initital_line ||
          (form?.initital_line === "อื่นๆ" && !form?.initital_line_other)
        ) {
          alert("กรุณาเลือก ชนิดของสายที่ต้องการส่งปรึกษา");
          // toast.error("กรุณาเลือก ชนิดของสายที่ต้องการส่งปรึกษา", {
          //   position: "top-center",
          //   autoClose: 2500,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          // });
          return;
        }

        if (form?.initital_line !== "อื่นๆ" && form?.initital_line_other) {
          setForm({
            ...form,
            initital_line_other: "",
          });
        }

        if (form?._id) {
          // console.log("IN 1 INITIAL DATABASE");
          await api.post(`/historys`, {
            name: user?.fullname,
            action: "แก้ไข INITIAL DATABASE",
            task_id: form?._id,
            user_department: userDeptforSubmit,
          });

          respon = await api.put(`/tasks/${form?._id}`, {
            ...newFrom,
          });
          Loaddata(); //2 update
        } else {
          if (!form?.hn) {
            alert("กรุณากรอก HN");
            return;
          }

          respon = await api.post(`/tasks`, {
            ...newFrom,
            status: "waiting",
            user_department: userDeptforSubmit,
          });
          // console.log("respon", respon);
          await api.post(`/historys`, {
            name: user?.fullname,
            action: "เริ่มบันทึกข้อมูล",
            task_id: respon?.data?.data?._id,
            user_department: userDeptforSubmit,
          });

          const new_id = respon?.data?.data?._id;
          if (new_id) {
            router.push(`/Info/${new_id}`); //1 create
          }
        }
      } else if (page === "FULL DATABASE") {
        // console.log("IN 3 FULL DATABASE", isNextStep);

        if (!isNextStep) {
          respon = await api.put(`/tasks/${form?._id}`, {
            ...newFrom,
            status: "waiting_full",
            user_department: userDeptforSubmit,
          });
          await api.post(`/historys`, {
            name: user?.fullname,
            action: "แก้ไข FULL DATABASE",
            task_id: form?._id,
            user_department: userDeptforSubmit,
          });
          Loaddata();
        } else {
          respon = await api.put(`/tasks/${form?._id}`, {
            ...newFrom,
          });
          await api.post(`/historys`, {
            name: user?.fullname,
            action: "แก้ไข FULL DATABASE",
            task_id: form?._id,
            user_department: userDeptforSubmit,
          });
          Loaddata();
        }
        if (userDept === "HEMATO") {
          router.push("/");
        }
      } else if (page === "PROCEDURE NOTE") {
        const { data: attemptExist } = await api.get(
          "/attempts/" + newFrom._id
        );

        if (
          attemptExist?.attempt?.status !== "Successful" ||
          !attemptExist?.attempt
        ) {
          if (!form?.date) {
            alert("กรุณากรอกวันที่ใส่สาย (Date ใน Attempt)");
            return;
          }

          if (!form?.attending && !form?.attending_name?.value) {
            alert("กรุณาเลือก Attending และเลือกชื่อให้เรียบร้อย");
            return;
          }
        }

        // console.log("attemptExist", attemptExist);

        if (!form?.catheter?.value) {
          alert("กรุณาเลือก Catheter");
          return;
        }

        if (!form?.final_lt && !form?.final_rt) {
          alert("กรุณาเลือกข้างที่ Final catheter in ");
          return;
        }
        if (!form?.final_catheter_in?.value || !form?.final_catheter_in) {
          alert("กรุณาเลือกหลอดเลือดที่ Final catheter in ");
          return;
        }
        if (!form?.iecg && !form?.fluoroscopy && !form?.landmark) {
          alert("กรุณาเลือก Confirmation ");
          return;
        }
        if (!form?.exit_site_mark) {
          alert("กรุณากรอก Exit site mark");
          return;
        }
        if (!form?.indwelled) {
          alert("กรุณากรอก Indwelled");
          return;
        }
        // if (!form?.nurse?.value) {
        //   alert("กรุณาเลือก nurse");
        //   return;
        // }
        if (
          form?.catheter?.value === "other_catheter" &&
          !form?.check_catheter
        ) {
          alert("กรุณาเลือก picc / central ");
          return;
        }
        if (!isNextStep) {
          console.log("newFrom", newFrom);

          // if (newFrom._id) {
          //   delete newFrom._id;
          // }

          const { data: attemptExist } = await api.get(
            "/attempts/" + newFrom._id
          );

          // console.log("attemptExist", attemptExist);

          if (
            attemptExist?.attempt?.status !== "Successful" ||
            !attemptExist?.attempt
          ) {
            await api.post(`/attempts`, {
              ...newFrom,
              task_id: form?._id,
              status: "Successful",
            });
          }

          await api.post(`/historys`, {
            name: user?.fullname,
            action: "แก้ไข PROCEDURE NOTE",
            task_id: form?._id,
            user_department: userDeptforSubmit,
          });

          const final_date_success = form?.date;

          // console.log("IN 5");

          respon = await api.put(`/tasks/${form?._id}`, {
            ...newFrom,
            status:
              FunctionCheckType(
                form?.catheter?.value,
                form?.other_catheter_name,
                form?.check_catheter
              ) === "PICC"
                ? "on_iv_tube"
                : "unfollow",
            final_date_success,
          });

          if (form?.status === "waiting_full") {
            await api.post(`/locations`, {
              current_location: form?.ward,
              task_id: form?._id,
              other_current_location_name: form?.other_ward_name
                ? form?.other_ward_name
                : "",
              user_department: userDeptforSubmit,
            });
            // Loaddata();
          }
          Loaddata();
        } else {
          // console.log("IN 6");

          // const isPICC = CheckPICCLine(form?.catheter?.value);

          // const isCentral = CheckCentraline(form?.catheter?.value);

          // const { data: taskHn } = await api.get("/tasks?hn=" + form?.hn);
          // const exceptSelf = taskHn?.tasks?.filter((i) => {
          //   return i?._id !== form?._id;
          // });

          // const picc_count = exceptSelf?.filter((i) => {
          //   return CheckPICCLine(i?.catheter?.value);
          // });
          // const central_count = exceptSelf?.filter((i) => {
          //   return CheckCentraline(i?.catheter?.value);
          // });

          // let dream_value = {
          //   hn_count: exceptSelf.length + 1,
          //   id_picc: isPICC ? picc_count?.length + 1 : 0,
          //   id_central: isCentral ? central_count + 1 : 0,
          //   id_picc_trigger: picc_count?.length > 1,
          //   id_central_trigger: central_count?.length > 1,
          // };
          // console.log("dream_value", dream_value);

          respon = await api.put(
            `/tasks/${form?._id}`,
            {
              ...newFrom,
              // ...dream_value,
              other_catheter_name:
                form?.catheter?.value != "other_catheter"
                  ? ""
                  : form?.other_catheter_name,
              // ตรงนี้จ้า
              status: form?.status,
              // FunctionCheckType(
              //   form?.catheter?.value,
              //   form?.other_catheter_name,
              //   form?.check_catheter
              // ) === "PICC"
              //   ? "on_iv_tube"
              //   : "unfollow",
            },
            router.push("/")
          );
          await api.post(`/historys`, {
            name: user?.fullname,
            action: "แก้ไข PROCEDURE NOTE",
            task_id: form?._id,
            user_department: userDeptforSubmit,
          });
          if (form?.status === "waiting_full") {
            // console.log("IN 7");
            await api.post(`/locations`, {
              current_location: form?.ward,
              task_id: form?._id,
              other_current_location_name: form?.other_ward_name
                ? form?.other_ward_name
                : "",
              user_department: userDeptforSubmit,
            });
          }
          Loaddata();
        }
      } else if (page === "FOLLOW UP") {
        const { _id, ...followData } = newFrom;
        const note_follow = form?.node_etc ? form?.node_etc : "";

        if (updateId) {
          await api.put(`/follows/${updateId}`, {
            ...followData,
            location_ward: form?.ward?.label,

            // task_id: form?._id,
          });
          await api.post(`/historys`, {
            name: user?.fullname,
            action: "แก้ไข FOLLOW UP",
            task_id: form?._id,
            user_department: userDeptforSubmit,
          });
          await api.put(`/tasks/${form?._id}`, {
            note_follow: note_follow,
          });
        } else {
          await api.post(`/follows`, {
            ...followData,
            location_ward: form?.ward?.label,
            task_id: form?._id,
            user_department: userDeptforSubmit,
            summary_type: summaryType,
            acknowledge: true,
          });

          await api.post(`/historys`, {
            name: user?.fullname,
            action: "แก้ไข FOLLOW UP",
            task_id: form?._id,
            user_department: userDeptforSubmit,
          });
          await api.put(`/tasks/${form?._id}`, {
            note_follow: note_follow,
          });
          if (isNurseWard) {
            const { data } = await api.get(`/tasks/${form?._id}`);
            if (data?.task?.current_location !== user?.current_location.value) {
              await api.put(`/tasks/${form?._id}`, {
                current_location: user?.current_location.value,
                ward: user?.current_location,
                option_ward: user?.building,
              });
              await api.post(`/locations`, {
                current_location: user?.current_location,
                task_id: form?._id,
                other_current_location_name: user?.other_ward_name
                  ? user?.other_ward_name
                  : "",
                user_department: userDeptforSubmit,
              });
            }
          }
        }
        Loaddata();
      } else if (page === "FINAL") {
        // console.log("IN 9 Final");
        const note_follow = form?.node_etc ? form?.node_etc : "";
        if (!form?.summary_alive && !form?.summary_deceased) {
          alert("กรุณาเลือกสถานะผู้ป่วย ( Alive or Deceased ) ");
          checkFinal = true;
          return;
        }

        await api.post(`/follows`, {
          ...newFrom,
          note_follow: note_follow,
          task_id: form?._id,
          location_ward: form?.ward?.label,
          location: form?.beside
            ? "Bedside"
            : form?.number
            ? "504.5"
            : form?.other_location
            ? form?.other_location_name
            : "Unknown",
          summary_type: summaryType,
          user_department: userDeptforSubmit,
        });
        respon = await api.put(`/tasks/${form?._id}`, {
          ...newFrom,
          note_follow: note_follow,
          status: form?.summary_alive
            ? "completed"
            : form?.summary_deceased
            ? "deceased"
            : "error",
        });
        await api.post(`/historys`, {
          name: user?.fullname,
          action: "แก้ไข FOLLOW UP",
          task_id: form?._id,
          user_department: userDeptforSubmit,
        });
      } else {
        // console.log("IN ELSE");
        respon = await api.put(`/tasks/${form?._id}`, {
          ...newFrom,
          status: "cancelled",
        });
        await api.post(`/historys`, {
          name: user?.fullname,
          action: "แก้ไข FOLLOW UP",
          task_id: form?._id,
        });
      }
      Loaddata();
    } catch (error) {
      console.log("ERROR_TO_SAVE_DATA : ", error);
    }
  };

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <div className=" max-w-5xl mx-auto relative ">
      <div className=" max-w-5xl w-full px-4 top-1/2">
        {form?.id_backward && (
          <div
            onClick={() => {
              router.push(`/hopper/${form?.id_backward} `);
              Loaddata();
            }}
            className="cursor-pointer rounded-md border-2    hover:bg-gray-300 hover:text-white   bg-white w-7 md:w-10 h-7 md:h-10 shadow-lg fixed left-2 lg:left-10 xl:left-40 "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-auto h-auto"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </div>
        )}

        {form?.id_forward && (
          <div
            onClick={() => {
              router.push(`/hopper/${form?.id_forward}`);
              Loaddata();
            }}
            className="  hover:bg-gray-300 hover:text-white  cursor-pointer rounded-md border-2 bg-white w-7 md:w-10 h-7 md:h-10 shadow-lg fixed right-2 lg:right-10 xl:right-40"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-auto h-auto"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>
        )}
      </div>
      <div className=" bg-inputbg m-4 lg:m-20 xl:m-28 pb-4">
        <NavClick
          navName={checkStatusToCreateNav(form?.status, userDept)}
          nav={nav}
          setNav={setNav}
          setUndo={setUndo}
          undo={undo}
        />
        {nav === "INITIAL DATABASE" && (
          <Initial
            saved={saved}
            form={form}
            setField={setField}
            nav="INITIAL DATABASE"
            setForm={setForm}
            onClickSave={onClickSave}
            isNurse={isNurse}
          />
        )}
        {nav === "FULL DATABASE" && (
          <Full
            form={form}
            setField={setField}
            nav="FULL DATABASE"
            onClickSave={onClickSave}
            isNurse={isNurse}
          />
        )}

        {nav === "PROCEDURE NOTE" && (
          <Procedure
            form={form}
            setField={setField}
            onClickSave={onClickSave}
            isNurse={isNurse}
          />
        )}
        {nav === "FOLLOW UP" && (
          <FollowMe
            reloadFollowUp={reloadFollowUp}
            form={form}
            setField={setField}
            onClickSave={onClickSave}
            isNurse={isNurse}
            summaryType={summaryType}
            setSummaryType={setSummaryType}
            isNurseWard={isNurseWard}
          />
        )}
      </div>
      <div className=" ">
        <HistoryMain history={history} />
      </div>
    </div>
  );
};
export default Info;
