import ExcelExport from "export-xlsx";

export const ExcelPaperConsultAll = async (
  inputdata,
  year,
  type,
  setLoading
) => {
  const SETTINGS_FOR_EXPORT_PAPER = {
    fileName: "ALiST_REPORT_PAPER",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `สรุปจำนวนใบปรึกษาที่ได้รับทั้งหมดต่อปี แยกตามเดือน  ของปี${year?.label} ตามประเภทสาย ${type?.label}   `,
                key: "header_01",
              },
            ],

            headerDefinition: [
              {
                name: `มกราคม`,
                groupKey: "header_01",
                key: "month_01",
                width: 15,
              },
              {
                name: `กุมภาพันธ์`,
                groupKey: "header_01",
                key: "month_02",
                width: 15,
              },
              {
                name: `มีนาคม`,
                groupKey: "header_01",
                key: "month_03",
                width: 15,
              },
              {
                name: `เมษายน`,
                groupKey: "header_01",
                key: "month_04",
                width: 15,
              },
              {
                name: `พฤษภาคม`,
                groupKey: "header_01",
                key: "month_05",
                width: 15,
              },
              {
                name: `มิถุนายน`,
                groupKey: "header_01",
                key: "month_06",
                width: 15,
              },
              {
                name: `กรกฎาคม`,
                groupKey: "header_01",
                key: "month_07",
                width: 15,
              },
              {
                name: `สิงหาคม`,
                groupKey: "header_01",
                key: "month_08",
                width: 15,
              },
              {
                name: `กันยายน`,
                groupKey: "header_01",
                key: "month_09",
                width: 15,
              },
              {
                name: `ตุลาคม`,
                groupKey: "header_01",
                key: "month_10",
                width: 15,
              },
              {
                name: `พฤศจิกายน`,
                groupKey: "header_01",
                key: "month_11",
                width: 15,
              },
              {
                name: `ธันวาคม`,
                groupKey: "header_01",
                key: "month_12",
                width: 15,
              },
              {
                name: `รวม`,
                groupKey: "header_01",
                key: "sum",
                width: 15,
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT_PAPER, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
