import ExcelExport from "export-xlsx";
const XLSX = require("xlsx");

export const ExcelSideCat = (data, year, type, setLoading) => {
  const wb = XLSX.utils.book_new();

  const ws_data = [
    [
      `นับหลอดเลือดและข้าง (ซ้ายหรือขวา) ของสายน้ำเกลือที่ใส่ ของปี ${year?.label} ตามประเภทสาย ${type?.label}`,
      "Lt",
      "Lt %",
      "Rt",
      "Rt %",
      "Total",
      "Total %",
    ],
  ];
  Object.keys(data).forEach((key) => {
    ws_data.push([
      key, // Duration label, e.g., "1-7"
      data[key].Lt, // Total number
      data[key].Lt_percent, // Total percent
      data[key].Rt, // Adults number
      data[key].Rt_percent, // Adults percent
      data[key].Total, // Children number
      data[key].Total_percent, // Children percent
    ]);
  });

  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  // XLSX.utils.sheet_add_aoa(ws, [["Duration"]], { origin: "A1" });
  // ws["!merges"] = ws["!merges"] || [];
  // ws["!merges"].push(XLSX.utils.decode_range("A1:F1"));

  // Merge cells for the 'Total', 'Adults', and 'Children' headers
  // ws["!merges"].push(XLSX.utils.decode_range("B1:C1"));
  // Merging 'Total' cells
  // ws["!merges"].push(XLSX.utils.decode_range("D1:E1"));
  // Merging 'Adults' cells
  // ws["!merges"].push(XLSX.utils.decode_range("F1:G1"));
  // Merging 'Children' cells

  XLSX.utils.book_append_sheet(wb, ws, "Report");

  // If in a browser environment
  if (typeof window !== "undefined" && window) {
    // Trigger a file download in the browser
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "report.xlsx";
    document.body.appendChild(anchor); // Append anchor to the body
    anchor.click();
    document.body.removeChild(anchor); // Clean up and remove the anchor
    window.URL.revokeObjectURL(url); // Release the created URL
  } else {
    // Node.js environment
    XLSX.writeFile(wb, "report.xlsx");
  }
};
// export const ExcelSideCat = async (inputdata, year, type, setLoading) => {
//   // const inputdata = { data: [{ test: 5 }] };
//   console.log("inputdata", inputdata);
//   const SETTINGS_FOR_EXPORT = {
//     // Table settings
//     fileName: "ALiST_REPORT",
//     workSheets: [
//       {
//         sheetName: "Report",
//         startingRowNumber: 1,
//         gapBetweenTwoTables: 6,

//         tableSettings: {
//           data: {
//             headerGroups: [
//               //กลุ่มที่ 1
//               {
//                 name: `นับหลอดเลือดและข้าง (ซ้ายหรือขวา) ของสายน้ำเกลือที่ใส่ ของปี ${year?.label} ตามประเภทสาย ${type?.label}`,
//                 key: "header_1",
//                 style: { fill: { fgColor: { rgb: "FF0000" } } },
//               },
//               //
//               {
//                 name: `Basilic vein`,
//                 groupKey: "header_1",
//                 key: "cat1",
//               },
//               {
//                 name: `Brachial vein`,
//                 groupKey: "header_1",
//                 key: "cat2",
//               },
//               {
//                 name: `Cephalic vein`,
//                 groupKey: "header_1",
//                 key: "cat3",
//               },
//               {
//                 name: `Internal jugular vein`,
//                 groupKey: "header_1",
//                 key: "cat4",
//               },
//               {
//                 name: `Subclavian vein`,
//                 groupKey: "header_1",
//                 key: "cat5",
//               },
//               {
//                 name: `Femoral vein`,
//                 groupKey: "header_1",
//                 key: "cat6",
//               },
//               {
//                 name: `Distal femoral vein`,
//                 groupKey: "header_1",
//                 key: "cat7",
//               },
//               {
//                 name: `Other`,
//                 groupKey: "header_1",
//                 key: "cat8",
//               },
//               {
//                 name: `รวม`,
//                 groupKey: "header_1",
//                 key: "total",
//               },
//             ],
//             headerDefinition: [
//               //
//               {
//                 name: `Lt(ซ้าย)`,
//                 groupKey: "cat1",
//                 key: "cat1_LT",
//               },
//               {
//                 name: `Lt(ซ้าย) %`,
//                 groupKey: "cat1",
//                 key: "cat1_LTpercent",
//               },
//               {
//                 name: `Rt(ขวา)`,
//                 groupKey: "cat1",
//                 key: "cat1_RT",
//               },
//               {
//                 name: `Rt(ขวา)%`,
//                 groupKey: "cat1",
//                 key: "cat1_RTpercent",
//               },
//               //
//               {
//                 name: `Lt(ซ้าย)`,
//                 groupKey: "cat2",
//                 key: "cat2_LT",
//               },
//               {
//                 name: `Lt(ซ้าย)%`,
//                 groupKey: "cat2",
//                 key: "cat2_LTpercent",
//               },
//               {
//                 name: `Rt(ขวา)`,
//                 groupKey: "cat2",
//                 key: "cat2_RT",
//               },
//               {
//                 name: `Rt(ขวา)%`,
//                 groupKey: "cat2",
//                 key: "cat2_RTpercent",
//               },
//               //
//               {
//                 name: `Lt(ซ้าย)`,
//                 groupKey: "cat3",
//                 key: "cat3_LT",
//               },
//               {
//                 name: `Lt(ซ้าย)%`,
//                 groupKey: "cat3",
//                 key: "cat3_LTpercent",
//               },
//               {
//                 name: `Rt(ขวา)`,
//                 groupKey: "cat3",
//                 key: "cat3_RT",
//               },
//               {
//                 name: `Rt(ขวา)%`,
//                 groupKey: "cat3",
//                 key: "cat3_RTpercent",
//               },
//               //
//               {
//                 name: `Lt(ซ้าย)`,
//                 groupKey: "cat4",
//                 key: "cat4_LT",
//               },
//               {
//                 name: `Lt(ซ้าย)%`,
//                 groupKey: "cat4",
//                 key: "cat4_LTpercent",
//               },
//               {
//                 name: `Rt(ขวา)`,
//                 groupKey: "cat4",
//                 key: "cat4_RT",
//               },
//               {
//                 name: `Rt(ขวา)%`,
//                 groupKey: "cat4",
//                 key: "cat4_RTpercent",
//               },
//               //
//               {
//                 name: `Lt(ซ้าย)`,
//                 groupKey: "cat5",
//                 key: "cat5_LT",
//               },
//               {
//                 name: `Lt(ซ้าย)%`,
//                 groupKey: "cat5",
//                 key: "cat5_LTpercent",
//               },
//               {
//                 name: `Rt(ขวา)`,
//                 groupKey: "cat5",
//                 key: "cat5_RT",
//               },
//               {
//                 name: `Rt(ขวา)%`,
//                 groupKey: "cat5",
//                 key: "cat5_RTpercent",
//               },
//               //
//               {
//                 name: `Lt(ซ้าย)`,
//                 groupKey: "cat6",
//                 key: "cat6_LT",
//               },
//               {
//                 name: `Lt(ซ้าย)%`,
//                 groupKey: "cat6",
//                 key: "cat6_LTpercent",
//               },
//               {
//                 name: `Rt(ขวา)`,
//                 groupKey: "cat6",
//                 key: "cat6_RT",
//               },
//               {
//                 name: `Rt(ขวา)%`,
//                 groupKey: "cat6",
//                 key: "cat6_RTpercent",
//               },
//               //
//               {
//                 name: `Lt(ซ้าย)`,
//                 groupKey: "cat7",
//                 key: "cat7_LT",
//               },
//               {
//                 name: `Lt(ซ้าย)%`,
//                 groupKey: "cat7",
//                 key: "cat7_LTpercent",
//               },
//               {
//                 name: `Rt(ขวา)`,
//                 groupKey: "cat7",
//                 key: "cat7_RT",
//               },
//               {
//                 name: `Rt(ขวา)%`,
//                 groupKey: "cat7",
//                 key: "cat7_RTpercent",
//               },
//               //
//               {
//                 name: `Lt(ซ้าย)`,
//                 groupKey: "cat8",
//                 key: "cat8_LT",
//               },
//               {
//                 name: `Lt(ซ้าย)%`,
//                 groupKey: "cat8",
//                 key: "cat8_LTpercent",
//               },
//               {
//                 name: `Rt(ขวา)`,
//                 groupKey: "cat8",
//                 key: "cat8_RT",
//               },
//               {
//                 name: `Rt(ขวา)%`,
//                 groupKey: "cat8",
//                 key: "cat8_RTpercent",
//               },
//               //
//               {
//                 name: `Lt(ซ้าย)`,
//                 groupKey: "total",
//                 key: "sum_LT",
//               },
//               {
//                 name: `Rt(ขวา)`,
//                 groupKey: "total",
//                 key: "sum_RT",
//               },
//             ],
//           },
//         },
//       },
//     ],
//   };

//   try {
//     setLoading(true);
//     const excelExport = new ExcelExport();
//     await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
//   } catch (error) {
//     console.log("error", error);
//   } finally {
//     setLoading(false);
//   }
// };
