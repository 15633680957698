import React from "react";
import FunctionCheckType from "./Report.FunctionCheckType";
import {
  calculateAverage,
  calculateMedian,
  calculatePercentile25,
  calculatePercentile75,
  calculateStandardDeviation,
} from "../utils/useFunction";
import { ageDayCalculator } from "../../../components/functions/Functions";

const CountTimeAttempt = ({ list, setValue, year, type, month }) => {
  let array_adult = [];
  let array_kid1 = [];
  let array_kid2 = [];
  let thisAllTime = 0;

  let checkTask = {
    array_adult: [],
    array_kid1: [],
    array_kid2: [],
  };

  const TotalTime = (room_in, room_out) => {
    const diff_time =
      room_in &&
      room_out &&
      Math.abs(
        new Date().setHours(room_in?.split(":")[0], room_in?.split(":")[1]) -
          new Date().setHours(room_out?.split(":")[0], room_out?.split(":")[1])
      ) / 60000;
    return typeof diff_time === "undefined" ? 0 : diff_time;
  };

  const formattedMonth = month.length === 1 ? `0${month}` : month;

  if (list?.length > 0) {
    const currentYear = new Date().getFullYear();

    //#filter final_date_success by month

    if (month !== "ALL") {
      list = list.filter((row) => {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);
        const useMonth = thisYear.substring(5, 7);

        return useYear === year && useMonth === formattedMonth;
      });
    }

    for (let row of list) {
      if (
        row?.final_date_success &&
        row?.dob &&
        row?.attempts[0]?.room_in &&
        row?.attempts[row?.attempts.length - 1]?.out_of_room
      ) {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);
        const useMonth = thisYear.substring(5, 7);
        // console.log("thisYear", thisYear);
        // console.log("useYear", useYear);
        // console.log("useMonth", useMonth);
        let attempts = row?.attempts;
        const isAdult = Number(
          ageDayCalculator(
            new Date(row?.dob),
            new Date(row?.final_date_success)
          ) >= 6570
        );

        const isKid1 = Number(
          ageDayCalculator(
            new Date(row?.dob),
            new Date(row?.final_date_success)
          ) <= 3650
        );

        const isKid2 = Number(
          ageDayCalculator(
            new Date(row?.dob),
            new Date(row?.final_date_success)
          ) < 6570
        );

        if (year === "ALL") {
          if (type === "NONE" && isAdult) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_adult.push(thisAllTime);
          }

          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC" &&
            isAdult
          ) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_adult.push(thisAllTime);
          }

          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE" &&
            isAdult
          ) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_adult.push(thisAllTime);
          }
          //
          //
          //
          if (type === "NONE" && isKid1) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_kid1.push(thisAllTime);
          }

          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC" &&
            isKid1
          ) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_kid1.push(thisAllTime);
          }

          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE" &&
            isKid1
          ) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_kid1.push(thisAllTime);
          }

          //
          //
          //
          if (type === "NONE" && isKid2) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_kid2.push(thisAllTime);
          }

          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC" &&
            isKid2
          ) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_kid2.push(thisAllTime);
          }

          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE" &&
            isKid2
          ) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_kid2.push(thisAllTime);
          }
        }

        if (year === useYear) {
          if (type === "NONE" && isAdult) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_adult.push(thisAllTime);
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC" &&
            isAdult
          ) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_adult.push(thisAllTime);
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE" &&
            isAdult
          ) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_adult.push(thisAllTime);
          }
          //
          //
          //
          if (type === "NONE" && isKid1) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_kid1.push(thisAllTime);
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC" &&
            isKid1
          ) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_kid1.push(thisAllTime);
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE" &&
            isKid1
          ) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_kid1.push(thisAllTime);
          }

          //
          //
          //
          if (type === "NONE" && isKid2) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_kid2.push(thisAllTime);
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC" &&
            isKid2
          ) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_kid2.push(thisAllTime);
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE" &&
            isKid2
          ) {
            if (attempts?.length > 0) {
              if (
                attempts[0].room_in &&
                attempts[attempts.length - 1].out_of_room
              ) {
                thisAllTime = TotalTime(
                  attempts[0].room_in,
                  attempts[attempts.length - 1].out_of_room
                );
              }
            }
            array_kid2.push(thisAllTime);
          }
        }
      }
    }
    const useSumAllAdult = array_adult.filter((value) => value !== 0);
    const useSumAllKid1 = array_kid1.filter((value) => value !== 0);
    const useSumAllKid2 = array_kid2.filter((value) => value !== 0);
    const useSumAll = [...useSumAllAdult, ...useSumAllKid1, ...useSumAllKid2];
    const useSumAll2 = [...useSumAllAdult, ...useSumAllKid2];

    setValue({
      ad_mean: calculateAverage(useSumAllAdult),
      ad_median: calculateMedian(useSumAllAdult),
      ad_min: Math.min(...useSumAllAdult),
      ad_max: Math.max(...useSumAllAdult),
      ad_sd: calculateStandardDeviation(useSumAllAdult),
      ad_per25: calculatePercentile25(useSumAllAdult),
      ad_per75: calculatePercentile75(useSumAllAdult),
      ad_number: useSumAllAdult?.length,
      //
      kid1_mean: calculateAverage(useSumAllKid1),
      kid1_median: calculateMedian(useSumAllKid1),
      kid1_min: Math.min(...useSumAllKid1),
      kid1_max: Math.max(...useSumAllKid1),
      kid1_sd: calculateStandardDeviation(useSumAllKid1),
      kid1_per25: calculatePercentile25(useSumAllKid1),
      kid1_per75: calculatePercentile75(useSumAllKid1),
      kid1_number: useSumAllKid1?.length,

      //
      kid2_mean: calculateAverage(useSumAllKid2),
      kid2_median: calculateMedian(useSumAllKid2),
      kid2_min: Math.min(...useSumAllKid2),
      kid2_max: Math.max(...useSumAllKid2),
      kid2_sd: calculateStandardDeviation(useSumAllKid2),
      kid2_per25: calculatePercentile25(useSumAllKid2),
      kid2_per75: calculatePercentile75(useSumAllKid2),
      kid2_number: useSumAllKid2?.length,

      //
      all_mean: calculateAverage(useSumAll),
      all_median: calculateMedian(useSumAll),
      all_min: Math.min(...useSumAll),
      all_max: Math.max(...useSumAll),
      all_sd: calculateStandardDeviation(useSumAll),
      all_per25: calculatePercentile25(useSumAll),
      all_per75: calculatePercentile75(useSumAll),
      all_number: useSumAll2?.length,
      checkTask: checkTask,
    });
  }
};

export default CountTimeAttempt;
