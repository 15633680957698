import FunctionCheckType from "./Report.FunctionCheckType";

const CountLabelCatheterType = ({ list, setValue, year }) => {
  let picc1 = 0;
  let picc2 = 0;
  let picc3 = 0;
  let picc4 = 0;
  let picc5 = 0;
  let central1 = 0;
  let central2 = 0;
  let central3 = 0;
  let central4 = 0;
  let checkPICC = [];
  let checkCentral = [];

  if (list?.length > 0) {
    for (let row of list) {
      if (row?.final_date_success !== "" && row?.catheter) {
        const month = row?.final_date_success ? row?.final_date_success : "";
        const thisMonth = String(month);
        const useMont = thisMonth.substring(5, 7);

        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);
        if (year === "ALL" || year === useYear) {
          if (
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (row?.catheter?.value === "Arrow5Fr2lumenPICC") {
              picc1++;
              // checkPICC.push(row?._id);
            }
            if (row?.catheter?.value === "Arrow4Fr1lumenPICC") {
              picc2++;
              // checkPICC.push(row?._id);
            }
            if (row?.catheter?.value === "Bard5Fr2LumenpowerPICC") {
              picc3++;
              // checkPICC.push(row?._id);
            }
            if (row?.catheter?.value === "Vygon3Fr1lumenPICC") {
              picc4++;
              // checkPICC.push(row?._id);
            }

            if (row?.catheter?.value === "other_catheter") {
              picc5++;
              // checkPICC.push(row?._id);
            }
          } else if (
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            {
              if (
                row?.catheter?.value === "Arrow7Fr2lumenCVLantimicrobialscoated"
              ) {
                central1++;
                // checkCentral.push(row?._id);
              }
              if (
                row?.catheter?.value === "Arrow7Fr3lumenCVLantimicrobialscoated"
              ) {
                central2++;
                // checkCentral.push(row?._id);
              }
              if (row?.catheter?.value === "12FrDLC") {
                central3++;
                // checkCentral.push(row?._id);
              }
              if (row?.catheter?.value === "other_catheter") {
                central4++;
                // checkCentral.push(row?._id);
              }
            }
          }
        }
        // if (year === useYear) {
        //   if (
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     if (row?.catheter?.value === "Arrow5Fr2lumenPICC") {
        //       picc1++;
        //     }
        //     if (row?.catheter?.value === "Arrow4Fr1lumenPICC") {
        //       picc2++;
        //     }
        //     if (row?.catheter?.value === "Bard5Fr2LumenpowerPICC") {
        //       picc3++;
        //     }
        //     if (row?.catheter?.value === "Vygon3Fr1lumenPICC") {
        //       picc4++;
        //     }

        //     if (row?.catheter?.value === "other_catheter") {
        //       picc5++;
        //     }
        //   } else if (
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     {
        //       if (
        //         row?.catheter?.value === "Arrow7Fr2lumenCVLantimicrobialscoated"
        //       ) {
        //         central1++;
        //       }
        //       if (
        //         row?.catheter?.value === "Arrow7Fr3lumenCVLantimicrobialscoated"
        //       ) {
        //         central2++;
        //       }
        //       if (row?.catheter?.value === "12FrDLC") {
        //         central3++;
        //       }
        //       if (row?.catheter?.value === "other_catheter") {
        //         central4++;
        //       }
        //     }
        //   }
        // }
        // if (year === "2023" && useYear === "2023") {
        //   if (
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     if (row?.catheter?.value === "Arrow5Fr2lumenPICC") {
        //       picc1++;
        //     }
        //     if (row?.catheter?.value === "Arrow4Fr1lumenPICC") {
        //       picc2++;
        //     }
        //     if (row?.catheter?.value === "Bard5Fr2LumenpowerPICC") {
        //       picc3++;
        //     }
        //     if (row?.catheter?.value === "Vygon3Fr1lumenPICC") {
        //       picc4++;
        //     }

        //     if (row?.catheter?.value === "other_catheter") {
        //       picc5++;
        //     }
        //   } else if (
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     {
        //       if (
        //         row?.catheter?.value === "Arrow7Fr2lumenCVLantimicrobialscoated"
        //       ) {
        //         central1++;
        //       }
        //       if (
        //         row?.catheter?.value === "Arrow7Fr3lumenCVLantimicrobialscoated"
        //       ) {
        //         central2++;
        //       }
        //       if (row?.catheter?.value === "12FrDLC") {
        //         central3++;
        //       }
        //       if (row?.catheter?.value === "other_catheter") {
        //         central4++;
        //       }
        //     }
        //   }
        // }

        setValue({
          picc1: picc1,
          picc2: picc2,
          picc3: picc3,
          picc4: picc4,
          picc5: picc5,
          central1: central1,
          central2: central2,
          central3: central3,
          central4: central4,
          // checkPICC: checkPICC,
          // checkCentral: checkCentral,
        });
      }
    }
  }
};

export default CountLabelCatheterType;
