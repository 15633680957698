import ExcelExport from "export-xlsx";

export const ExcelRating = async (inputdata, year, setLoading) => {
  // const inputdata = { data: [{ test: 5 }] };
  const SETTINGS_FOR_EXPORT = {
    // Table settings
    fileName: "ALiST_REPORT",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `รายงานความพึงพอใจของญาติ และ บุคลาการดูแลผู้ป่วยแยกตามระดับ" ของปี ${year?.label} `,
                key: "header_1",
              },

              {
                name: `ระดับ 1 พอใจน้อยที่สุด`,
                key: "level1",
                groupKey: "header_1",
              },
              {
                name: `ระดับ 2 พอใจน้อย`,
                key: "level2",
                groupKey: "header_1",
              },
              {
                name: `ระดับ 3 พอใจปานกลาง`,
                key: "level3",
                groupKey: "header_1",
              },
              {
                name: `ระดับ 4 พอใจมาก`,
                key: "level4",
                groupKey: "header_1",
              },
              {
                name: `ระดับ 5 พอใจมากที่สุด`,
                key: "level5",
                groupKey: "header_1",
              },
            ],
            headerDefinition: [
              {
                name: "ญาติ",
                key: "level01_relative",
                groupKey: "level1",
                width: 10,
              },
              {
                name: "บุคลากร",
                key: "level01_patient",
                groupKey: "level1",
                width: 10,
              },
              //
              {
                name: "ญาติ",
                key: "level02_relative",
                groupKey: "level2",
                width: 10,
              },
              {
                name: "บุคลากร",
                key: "level02_patient",
                groupKey: "level2",
                width: 10,
              },
              //
              {
                name: "ญาติ",
                key: "level03_relative",
                groupKey: "level3",
                width: 10,
              },
              {
                name: "บุคลากร",
                key: "level03_patient",
                groupKey: "level3",
                width: 10,
              },
              //
              {
                name: "ญาติ",
                key: "level04_relative",
                groupKey: "level4",
                width: 10,
              },
              {
                name: "บุคลากร",
                key: "level04_patient",
                groupKey: "level4",
                width: 10,
              },
              //
              {
                name: "ญาติ",
                key: "level05_relative",
                groupKey: "level5",
                width: 10,
              },
              {
                name: "บุคลากร",
                key: "level05_patient",
                groupKey: "level5",
                width: 10,
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
