import ExcelExport from "export-xlsx";
import numeral from "numeral";
import React, { useState } from "react";
import api from "../../api";
import {
  timeDifference,
  ageCalculator,
  FormatStatus,
  GenerateOptionsYearForExport,
  ageYearCalculator,
  calculateHours,
  formatdate,
  timeDifferenceFull,
  timeDiffHour,
  timeDiffHourandMinutes,
} from "../../components/functions/Functions";
import DropdownReport from "../report/report.dropdwn";
import Loading from "../../components/loading/Loading";
import {
  formatExcelKeep,
  generateFunctionFromData,
} from "../../utils/generateFunctionFromData";
import { findMaxLength } from "../../utils/findMaxLength";
import { diffTime, formatLimb } from "../../utils/formatFunctionForExport";
import FunctionCheckType from "../report/functions/Report.FunctionCheckType";
import { optionFullMonth, optionType } from "../../contexts/DataOptions";
import { useSelector } from "react-redux";
import { getUserDepartment } from "../../utils/formatUserDepartment";
import userDepStore from "../../_store/userDepStore";
import classNames from "classnames";

const ExportMain = () => {
  const [dataDashboard, setDataDashboard] = useState({});
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  //
  const [startMonthNew, setStartMonthNew] = useState("");
  const [endMonthNew, setEndMonthNew] = useState("");
  const [startYearNew, setStartYearNew] = useState("");
  const [endYearNew, setEndYearNew] = useState("");
  //
  const [loading, setLoading] = useState(false);
  const optionsYear = GenerateOptionsYearForExport();
  const [countTask, setCountTask] = useState(0);
  const [tempTask, setTempTask] = useState(0);
  const [task, setTask] = useState("");
  //
  const [type, setType] = useState({ value: "", label: "" });

  const { user } = useSelector((state) => state.auth);
  const [userDept, setUserDept] = useState(user?.user_department);
  const [isActivated, setIsActivated] = useState(getUserDepartment(userDept));

  // console.log(`isActivated: `, isActivated);
  // console.log("userDept", userDept);

  const isAN = user?.user_department === "super_AN";

  const isNurse =
    user?.user_department === "NURSE" ||
    user?.user_department === "AN" ||
    user?.user_department === "TR" ||
    user?.user_department === "IR" ||
    user?.user_department === "PED";

  const percent =
    tempTask !== 0 && countTask !== 0
      ? Math.round((Number(tempTask) / Number(countTask)) * 100)
      : "";

  const paperDateNpaperTime = (paperDate, paperTime) => {
    // 2023-06-14T00:00:00.000Z
    const useDate = paperDate + "T" + paperTime + ":00.000Z";
    return useDate;
  };
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const LoadDashboard = async (thisUserDept) => {
    const { data } = await api.get("/dashboard/status", {
      params: {
        user_department: thisUserDept,
      },
    });

    setDataDashboard(data?.data);
  };

  React.useEffect(() => {
    if (!isActivated) {
      if (user?.user_department || userDept) {
        const thisUserDept = userDept
          ? getUserDepartment(userDept)
          : getUserDepartment(user?.user_department);
        setIsActivated(thisUserDept);
      }
    }
  }, [user?.user_department, userDept, isActivated]);

  React.useEffect(() => {
    if (userDept || user?.user_department) {
      const thisUserDept = userDept
        ? getUserDepartment(userDept)
        : getUserDepartment(user?.user_department);
      LoadDashboard(thisUserDept);
    }
  }, [userDept, user?.user_department]);

  const onClickExportExcelType = async () => {
    // FULL V.1

    try {
      setLoading(true);
      const limit = 100;
      const countCeil = Math.ceil(dataDashboard.total / limit) - 1;
      let newDatas = [];
      let maxAttempt = 0;
      let countAttempt = [];
      const startDate = `${startYearNew?.value}-${startMonthNew?.value}-01`;
      const endDate = `${endYearNew?.value}-${endMonthNew?.value}-31`;
      const thisUserDept = userDept
        ? getUserDepartment(userDept)
        : getUserDepartment(user?.user_department);
      const { data } = await api.get(
        `/tasks?/countdate?startDate=${startDate}&endDate=${endDate}`,
        {
          params: {
            user_department: thisUserDept,
          },
        }
      );
      setCountTask(data?.countTask);

      for (let index = 0; index <= countCeil; index++) {
        let { data } = await api.get(
          `/tasks?skip=${
            index * limit
          }&limit=${limit}&sort=final_date_success:1`,
          {
            params: {
              user_department: thisUserDept,
            },
          }
        );

        const startDate = `${startYearNew?.value}-${startMonthNew?.value}-01 `;
        const endDate = `${endYearNew?.value}-${endMonthNew?.value}-31 `;

        const thisData = data?.tasks?.filter((row) => {
          const dateValue = row?.final_date_success;

          return dateValue >= startDate && dateValue <= endDate;
        });
        // หาความยาว

        if (thisData?.length > 0) {
          const tasks = thisData;

          for (let task of tasks) {
            if (
              // CheckPICCLine(task?.catheter?.label, task?.other_catheter_name)
              FunctionCheckType(
                task?.catheter?.value,
                task?.other_catheter_name,
                task?.check_catheter
              ) === type?.value
            ) {
              let tempCountTask = 0;

              if (task?.attempts) {
                countAttempt.push(task?.attempts?.length);
              }

              task["location_porcidure"] = task?.other_location
                ? task?.other_location_name
                : task?.number
                ? "504.5"
                : task?.beside
                ? "Bedside"
                : "";
              task["insertion_technique"] = task?.fuided_seldinger
                ? "U/S guided, Seldinger"
                : task?.rewire
                ? "rewire"
                : task?.percutaneous
                ? "percutaneous"
                : task?.other_technique
                ? "other_technique_name"
                : "";
              task["confirmation"] =
                task?.iecg && task?.fluoroscopy
                  ? "iECG , Fluoroscopy "
                  : task?.iecg
                  ? "iECG"
                  : task?.fluoroscopy
                  ? "Fluoroscopy"
                  : task?.landmark
                  ? "Landmark"
                  : "";
              task["patient_type"] = task?.other_patient_type_name
                ? task?.other_patient_type_name
                : task?.patient_type?.label
                ? task?.patient_type?.label
                : "";

              task["catheter"] = task?.other_catheter_name
                ? task?.other_catheter_name
                : task?.catheter?.label
                ? task?.catheter?.label
                : "";
              task["tip_of_catheter"] = task?.tip_of_catheter
                ? task?.tip_of_catheter?.label
                : "";
              task["tip_of_catheter_lower"] =
                task?.in_ivc && task?.not_in_ivc
                  ? ""
                  : task?.not_in_ivc
                  ? "Not in IVC"
                  : task?.in_ivc
                  ? "In IVC"
                  : "";
              task["unintended_position"] = task?.other_tip_of_catheter_lower
                ? task?.other_tip_of_catheter_lower_name
                : "";

              task["age"] = task?.dob
                ? ageYearCalculator(
                    task?.dob,
                    new Date(task?.final_date_success)
                  )
                : "";
              task["age_old"] = task?.dob
                ? ageCalculator(task?.dob, new Date(task?.final_date_success))
                : "";

              task["ward1"] = task?.option_ward?.label
                ? task?.option_ward?.label
                : "";
              task["ward2"] = task?.ward?.label
                ? task?.ward?.label
                : task?.other_ward_name
                ? task?.other_ward_name
                : "";

              const paper_date_cal =
                task?.paper_date && task?.paper_time
                  ? paperDateNpaperTime(task?.paper_date, task?.paper_time)
                  : "-";
              const attempt_date_cal =
                task?.attemptsLast?.date && task?.attemptsLast?.out_of_room
                  ? paperDateNpaperTime(
                      task?.attemptsLast?.date,
                      task?.attemptsLast?.out_of_room
                    )
                  : "-";
              task["test_1"] = paper_date_cal;
              task["test_2"] = attempt_date_cal;

              task["cal_total_wait"] =
                paper_date_cal != "-" && attempt_date_cal !== "-"
                  ? timeDifferenceFull(
                      new Date(attempt_date_cal),
                      new Date(paper_date_cal)
                    )
                  : "";

              task["cal_total_wait_hour_min"] =
                paper_date_cal != "-" && attempt_date_cal !== "-"
                  ? timeDiffHourandMinutes(paper_date_cal, attempt_date_cal)
                  : "-";
              task["cal_total_wait_hour"] =
                paper_date_cal != "-" && attempt_date_cal !== "-"
                  ? timeDiffHour(paper_date_cal, attempt_date_cal)
                  : "-";

              //

              const formatFirstWard = () => {
                if (task?.firstLo) {
                  if (
                    task?.firstLo?.other_current_location_name !== "" &&
                    !task?.firstLo?.current_location?.label
                  ) {
                    return task?.firstLo?.other_current_location_name;
                  }
                  if (
                    task?.firstLo?.other_current_location_name === "" &&
                    task?.firstLo?.current_location?.label !== ""
                  ) {
                    return task?.firstLo?.current_location?.label;
                  }
                } else {
                  return "";
                }
              };

              task["first_ward"] = formatFirstWard();

              task["id"] = task?._id;
              task["hn_picc"] = task?.attempts?.length;

              task["side"] = task?.final_lt ? "Lt" : task?.final_rt ? "Rt" : "";
              task["catheter_in"] = task?.other_final_catheter_in
                ? task?.other_final_catheter_in
                : task?.final_catheter_in?.label
                ? task?.final_catheter_in?.label
                : "";
              task["use_indwelled"] = task?.indwelled ? task?.indwelled : "";

              task["format_status"] = FormatStatus(task?.status);
              // task["use_doctor"] = task?.doctor?.label
              //   ? task?.doctor?.label
              //   : "-";
              task["use_nurse"] = task?.nurse?.label ? task?.nurse?.label : "";
              task["use_final_date"] = task?.final_date_success
                ? new Date(task?.final_date_success).toLocaleDateString("th-TH")
                : "";

              task["use_done"] = task?.line_is_no_loanger
                ? new Date(task?.line_is_no_loanger).toLocaleDateString("th-TH")
                : "";

              // add  full v.1 //

              task["DOB"] = task?.dob
                ? new Date(task?.dob).toLocaleDateString("th-TH")
                : "";

              task["paper_date"] = task?.paper_date
                ? new Date(task?.paper_date).toLocaleDateString("th-TH")
                : "";

              task["paper_time"] = task?.paper_time ? task?.paper_time : "";

              task["promise_date"] = task?.promise
                ? new Date(task?.promise).toLocaleDateString("th-TH")
                : "";

              task["post_cxr"] = task?.doctor?.label ? task?.doctor?.label : "";

              task["total_time"] =
                task?.paper_date && task?.final_date_success
                  ? calculateHours(
                      formatdate(task?.paper_date),
                      task?.final_date_success
                    )
                  : "-";

              // โรคร่วม

              task["DM"] = task?.dm ? "1" : "";
              task["HTN"] = task?.htn ? "1" : "";
              task["Dyslipidemia"] = task?.dyslipidemia ? "1" : "";
              task["ESRD"] = task?.esrd ? "1" : "";
              task["Malignancy"] = task?.mailignancy ? "1" : "";
              task["other_disease"] = task?.other_disease ? "1" : "";
              task["other_disease_name"] =
                task?.other_disease && task?.other_disease
                  ? task?.other_disease_name
                  : "";

              task["TPN"] = task?.tpn ? "1" : "";
              task["Prolonged IV meds"] = task?.prolonged_iv_meds ? "1" : "";
              task["Chemotherapy"] = task?.chemotherapy ? "1" : "";
              task["Vasopressor"] = task?.vasopressor ? "1" : "";
              task["เจาะเลือดบ่อย"] = task?.frequent_blood_sampling ? "1" : "";
              task["Stem cell/ Plasma Exc."] =
                task?.stem_cell_collection_plasma_exchange ? "1" : "";
              task["Stem cell Tx"] = task?.stem_cell_transplantation ? "1" : "";
              task["other_indication"] = task?.other_indication ? "1" : "";
              task["other_indication_name"] = task?.other_indication_name
                ? task?.other_indication_name
                : "";
              task["ECG"] = task?.ecg ? task?.ecg?.label : "";
              task["ECG_NAME"] = task?.other_ecg_name
                ? task?.other_ecg_name
                : "";
              task["exit_site_mark"] = task?.exit_site_mark;

              task["x_ray_date"] = task?.x_ray_date
                ? new Date(task?.x_ray_date).toLocaleDateString("th-TH")
                : "";
              task["upper_lower_torso"] = task?.check_torso?.label;
              task["related_produre"] = task?.other_person;

              // ข้อมูลต้อง map ของ เบอร์ เจ้าปัญหา
              const phone = task?.more_patient?.map((row) => {
                if (row?.phone_number !== "") {
                  const usePhone = row?.phone_number;
                  return usePhone;
                } else {
                  return "";
                }
              });

              const formatPhone = (phone) => {
                if (phone) {
                  const myString = phone.join(",");
                  const useMyString = myString.replace(",", "");

                  return useMyString;
                } else {
                  return "";
                }
              };
              const relation = task?.more_patient?.map((row) => {
                if (row?.relation !== "") {
                  const useRelation = row?.relation;
                  return useRelation;
                } else {
                  return "";
                }
              });

              const formatRelation = (relation) => {
                if (relation) {
                  const myString = relation.join(",");
                  const useMyString = myString.replace(",", "");

                  return useMyString;
                } else {
                  return "";
                }
              };

              task["phone_more"] = task?.patient_phone_number
                ? task?.patient_phone_number
                : formatPhone(phone);
              task["ralation_more"] = task?.involved
                ? task?.involved
                : formatRelation(relation);

              //ข้อมูลที่ต้องวนลูป attempt

              let {
                data: { attempts: thisAttempts },
              } = await api.get("/attempts?task_id=" + task?._id);

              task["count_of_attempts"] = thisAttempts?.length || 0;
              //////////////////////
              let thisTimeImRoom = [];
              thisAttempts?.forEach((row) => {
                if (row?.room_in && row?.out_of_room) {
                  const total_time = diffTime(row?.room_in, row?.out_of_room);
                  thisTimeImRoom.push(total_time);
                }
              });

              const totalTimeRoom = thisTimeImRoom?.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              );
              task["total_time_room"] = Math.round(totalTimeRoom);
              /////////////////////

              let thisAllTime = 0;

              if (thisAttempts.length > 0) {
                if (
                  thisAttempts[0].room_in &&
                  thisAttempts[thisAttempts.length - 1].out_of_room
                ) {
                  thisAllTime = diffTime(
                    thisAttempts[0].room_in,
                    thisAttempts[thisAttempts.length - 1].out_of_room
                  );
                }
              }

              task["total_all_time"] = Math.round(thisAllTime);

              let {
                data: { attempts: useAttempts },
              } = await api.get(
                "/attempts?status=Successful&task_id=" + task?._id
              );
              //
              let thisDoctor = [];
              useAttempts?.forEach((a, i) => {
                if (a["attending_name"]) {
                  thisDoctor.push({
                    name: a?.attending_name?.label,
                  });
                }
              });

              let keepthisDoctornStr = "";
              thisDoctor.forEach((row) => {
                keepthisDoctornStr += row?.name;
              });
              task["use_doctor"] = keepthisDoctornStr;
              //

              let thisTime = [];
              thisAttempts?.forEach((a, i) => {
                if (a["room_in"] && a["out_of_room"]) {
                  const diff_time =
                    a["room_in"] &&
                    a["out_of_room"] &&
                    Math.abs(
                      new Date().setHours(
                        a["room_in"]?.split(":")[0],
                        a["room_in"]?.split(":")[1]
                      ) -
                        new Date().setHours(
                          a["out_of_room"]?.split(":")[0],
                          a["out_of_room"]?.split(":")[1]
                        )
                    ) / 60000;
                  const result =
                    numeral(diff_time).format("0") > 0
                      ? numeral(diff_time).format("0")
                      : 0;
                  thisTime.push({
                    name: result,
                    round: i + 1,
                  });
                }
                let keepthisTimeStr = "";
                thisTime.forEach((row) => {
                  keepthisTimeStr +=
                    row?.round + ")" + "" + row?.name + "mins" + "|";
                });
                task["time"] = keepthisTimeStr;
              });
              //
              let thisComplication = [];
              thisAttempts?.forEach((a, i) => {
                if (a["complications_pneumothorax"]) {
                  thisComplication.push({
                    name: "Pneumothorax",
                    round: i + 1,
                  });
                }
                if (a["complications_hemothorax"]) {
                  thisComplication.push({
                    name: "Hemothorax",
                    round: i + 1,
                  });
                }
                if (a["complications_arterial_puncture"]) {
                  thisComplication.push({
                    name: "Arterial puncture",
                    round: i + 1,
                  });
                }
                if (a["complications_paresthesia"]) {
                  thisComplication.push({
                    name: "Paresthesia",
                    round: i + 1,
                  });
                }
                if (a["complications_nerve_injury"]) {
                  thisComplication.push({
                    name: "Nerve injury",
                    round: i + 1,
                  });
                }
                if (
                  a["complications_limb_ischemia_related_to_arterial_puncture"]
                ) {
                  thisComplication.push({
                    name: "Limb ischemia related to arterial puncture",
                    round: i + 1,
                  });
                }
                if (a["other_complications"]) {
                  thisComplication.push({
                    name: a["other_complications_name"],
                    round: i + 1,
                  });
                }
              });
              //
              let keepthisComplicationStr = "";
              thisComplication.forEach((row) => {
                keepthisComplicationStr +=
                  row?.round + "." + "" + row?.name + "|";
              });
              task["complications"] = keepthisComplicationStr;
              //

              // ข้อมูลที่ต้อง วนลูป follow up
              let {
                data: { follows: thisFollows },
              } = await api.get("/follows?task_id=" + task?._id);
              //

              let thisBy = [];
              thisFollows?.forEach((a, i) => {
                if (a["by_phone"]) {
                  thisBy.push({
                    name: "phone",
                    round: i + 1,
                  });
                }
                if (a["by_actial_visit"]) {
                  thisBy.push({
                    name: "Actual visit",
                    round: i + 1,
                  });
                }
              });
              let keepthisBynStr = "";
              thisBy.forEach((row) => {
                keepthisBynStr += row?.round + "." + "" + row?.name + "|";
              });
              task["by"] = keepthisBynStr;
              //
              let thisLinePatency = [];
              thisFollows?.forEach((a, i) => {
                if (a["line_ok_all"]) {
                  thisLinePatency.push({
                    name: "OK ALL",
                    round: i + 1,
                  });
                }
                if (a["line_wo_port"]) {
                  thisLinePatency.push({
                    name: "WO Port",
                    round: i + 1,
                  });
                }
                if (a["line_wo_distal"]) {
                  thisLinePatency.push({
                    name: "Distal",
                    round: i + 1,
                  });
                }
                if (a["line_wo_proximal"]) {
                  thisLinePatency.push({
                    name: "Proximal",
                    round: i + 1,
                  });
                }
                if (a["line_wo_purple"]) {
                  thisLinePatency.push({
                    name: "Purple",
                    round: i + 1,
                  });
                }
                if (a["line_wo_white"]) {
                  thisLinePatency.push({
                    name: "White",
                    round: i + 1,
                  });
                }
                if (a["other_line_wo_port"]) {
                  thisLinePatency.push({
                    name: a["other_line_wo_port_name"],
                    round: i + 1,
                  });
                }
                if (a["line_wo_observe"]) {
                  thisLinePatency.push({
                    name: "Observe",
                    round: i + 1,
                  });
                }
                if (a["line_wo_rtpax"]) {
                  thisLinePatency.push({
                    name: "rtPA x",
                    round: i + 1,
                  });
                }
                if (a["line_wo_result_opened"]) {
                  thisLinePatency.push({
                    name: "Opened",
                    round: i + 1,
                  });
                }
                if (a["line_wo_result_wo"]) {
                  thisLinePatency.push({
                    name: "WO",
                    round: i + 1,
                  });
                }
                if (a["line_wo_result_co"]) {
                  thisLinePatency.push({
                    name: "CO",
                    round: i + 1,
                  });
                }
                //CO
                if (a["line_co_port"]) {
                  thisLinePatency.push({
                    name: "CO Port",
                    round: i + 1,
                  });
                }
                if (a["line_co_distal"]) {
                  thisLinePatency.push({
                    name: "Distal",
                    round: i + 1,
                  });
                }
                if (a["line_co_proximal"]) {
                  thisLinePatency.push({
                    name: "Proximal",
                    round: i + 1,
                  });
                }
                if (a["line_co_purple"]) {
                  thisLinePatency.push({
                    name: "Purple",
                    round: i + 1,
                  });
                }
                if (a["line_co_white"]) {
                  thisLinePatency.push({
                    name: "White",
                    round: i + 1,
                  });
                }
                if (a["other_line_co_port"]) {
                  thisLinePatency.push({
                    name: a["other_line_wo_port_name"],
                    round: i + 1,
                  });
                }
                if (a["line_co_observe"]) {
                  thisLinePatency.push({
                    name: "Observe",
                    round: i + 1,
                  });
                }
                if (a["line_co_rtpax"]) {
                  thisLinePatency.push({
                    name: "rtPA x",
                    round: i + 1,
                  });
                }
                if (a["line_co_result_opened"]) {
                  thisLinePatency.push({
                    name: "Opened",
                    round: i + 1,
                  });
                }
                if (a["line_co_result_wo"]) {
                  thisLinePatency.push({
                    name: "WO",
                    round: i + 1,
                  });
                }
                if (a["line_co_result_co"]) {
                  thisLinePatency.push({
                    name: "CO",
                    round: i + 1,
                  });
                }
              });
              let keepLinePatStr = "";
              thisLinePatency.forEach((row, index) => {
                keepLinePatStr += row?.round + "." + "" + row?.name + "|";
              });
              task["line_patency"] = keepLinePatStr;
              //
              let thisLineIn = [];
              thisFollows?.forEach((a, i) => {
                if (a["line_infection_nil"]) {
                  thisLineIn.push({
                    name: "Nil",
                    round: i + 1,
                  });
                }
                if (a["line_infection_bug_found"]) {
                  thisLineIn.push({
                    name: "Bug Found",
                    round: i + 1,
                  });
                }
                if (a["line_infection_bug_found_cs_plus"]) {
                  thisLineIn.push({
                    name: "C/S {+} ve",
                    round: i + 1,
                  });
                }
                if (a["line_infection_bug_found_distal"]) {
                  thisLineIn.push({
                    name: "Distal",
                    round: i + 1,
                  });
                }
                if (a["line_infection_bug_found_proximal"]) {
                  thisLineIn.push({
                    name: "Proximal",
                    round: i + 1,
                  });
                }
                if (a["line_infection_bug_found_purple"]) {
                  thisLineIn.push({
                    name: "Purple",
                    round: i + 1,
                  });
                }
                if (a["line_infection_bug_found_white"]) {
                  thisLineIn.push({
                    name: "White",
                    round: i + 1,
                  });
                }
                if (a["other_line_infection_bug_found"]) {
                  thisLineIn.push({
                    name: a["other_line_infection_bug_found_name"],
                    round: i + 1,
                  });
                }
                if (a["line_infection_bug_found_organism"]) {
                  thisLineIn.push({
                    name: a["line_infection_bug_found_organism"],
                    round: i + 1,
                  });
                }
              });
              let keepthisLineInStr = "";
              thisLineIn.forEach((row) => {
                keepthisLineInStr += row?.round + "." + "" + row?.name + "|";
              });
              task["line_infection"] = keepthisLineInStr;
              //
              let thisLineDis = [];
              thisFollows?.forEach((a, i) => {
                if (a["line_disledge_nil"]) {
                  thisLineDis.push({
                    name: "Nil",
                    round: i + 1,
                  });
                }
                if (a["line_disledge_new_exit_mark_at"]) {
                  thisLineDis.push({
                    name: a["line_disledge_new_exit_mark_at_name"],
                    round: i + 1,
                  });
                }
                if (a["line_disledge_indwellend_name"]) {
                  thisLineDis.push({
                    name: new Date(a["line_disledge_indwellend_name"]),
                    round: i + 1,
                  });
                }
                if (a["complete_dislodge"]) {
                  thisLineDis.push({
                    name: "Completely dislodged",
                    round: i + 1,
                  });
                }
              });
              let keepthisLinDisnStr = "";
              thisLineDis.forEach((row) => {
                keepthisLinDisnStr += row?.round + "." + "" + row?.name + "|";
              });
              task["line_displacement"] = keepthisLinDisnStr;
              //
              let thisCatRe = [];
              thisFollows?.forEach((a, i) => {
                if (a["catheter_related_limb_edema_no"]) {
                  thisCatRe.push({
                    name: "no",
                    round: i + 1,
                  });
                }
                if (a["catheter_related_limb_edema_yes"]) {
                  thisCatRe.push({
                    name: "yes",
                    round: i + 1,
                  });
                }
                if (a["catheter_related_limb_edema_date"]) {
                  thisCatRe.push({
                    name: new Date(
                      a["catheter_related_limb_edema_date"]
                    ).toLocaleDateString("th-TH"),
                    round: i + 1,
                  });
                }
                if (a["remark"]) {
                  thisCatRe.push({
                    name: a["remark"],
                    round: i + 1,
                  });
                }
              });
              let keepthisCatReStr = "";
              thisCatRe.forEach((row) => {
                keepthisCatReStr += row?.round + "." + "" + row?.name + "|";
              });
              task["catheter_related"] = keepthisCatReStr;
              //
              let thisDVT = [];
              thisFollows?.forEach((a, i) => {
                if (a["presence_of_dvt_no"]) {
                  thisDVT.push({
                    name: "no",
                    round: i + 1,
                  });
                }
                if (a["presence_of_dvt_yes"]) {
                  thisDVT.push({
                    name: "yes",
                    round: i + 1,
                  });
                }
                if (a["presence_of_dvt_na"]) {
                  thisDVT.push({
                    name: "NA",
                    round: i + 1,
                  });
                }
              });
              let keepthisDVTStr = "";
              thisDVT.forEach((row) => {
                keepthisDVTStr += row?.round + "." + "" + row?.name + "|";
              });
              task["DVT"] = keepthisDVTStr;
              //
              let thisManagement = [];
              thisFollows?.forEach((a, i) => {
                if (a["management_observation"]) {
                  thisManagement.push({
                    name: "Observation",
                    round: i + 1,
                  });
                }
                if (a["management_anticoagulation"]) {
                  thisManagement.push({
                    name: a["management_anticoagulation_name"],
                    round: i + 1,
                  });
                }
                if (a["management_catheter_removal"]) {
                  thisManagement.push({
                    name: "Catheter removal",
                    round: i + 1,
                  });
                }
              });
              let keepthisManaStr = "";
              thisManagement.forEach((row) => {
                keepthisManaStr += row?.round + "." + "" + row?.name + "|";
              });
              task["management"] = keepthisManaStr;
              //
              let thisOther = [];
              thisFollows?.forEach((a, i) => {
                if (a["Others"]) {
                  thisOther.push({
                    name: "Others",
                    round: i + 1,
                  });
                }
                if (a["line_disledge_breakage_leak_port"]) {
                  thisOther.push({
                    name: "Breakage/leak port",
                    round: i + 1,
                  });
                }
                if (a["line_disledge_distal"]) {
                  thisOther.push({
                    name: "Distal",
                    round: i + 1,
                  });
                }
                if (a["line_disledge_proximal"]) {
                  thisOther.push({
                    name: "Proximal",
                    round: i + 1,
                  });
                }
                if (a["line_disledge_purple"]) {
                  thisOther.push({
                    name: "Purple",
                    round: i + 1,
                  });
                }
                if (a["line_disledge_red"]) {
                  thisOther.push({
                    name: "Red",
                    round: i + 1,
                  });
                }
                if (a["other_line_disledge"]) {
                  thisOther.push({
                    name: a["other_line_disledge_name"],
                    round: i + 1,
                  });
                }
                if (a["line_retained_fb_port"]) {
                  thisOther.push({
                    name: "Retained FB Port",
                    round: i + 1,
                  });
                }
                if (a["line_retained_fb_distal"]) {
                  thisOther.push({
                    name: "Distal",
                    round: i + 1,
                  });
                }
                if (a["line_retained_fb_proximal"]) {
                  thisOther.push({
                    name: "Proximal",
                    round: i + 1,
                  });
                }
                if (a["line_retained_fb_purple"]) {
                  thisOther.push({
                    name: "Purple",
                    round: i + 1,
                  });
                }
                if (a["line_retained_fb_red"]) {
                  thisOther.push({
                    name: "Red",
                    round: i + 1,
                  });
                }
                if (a["other_line_retained_fb"]) {
                  thisOther.push({
                    name: a["other_line_retained_fb_name"],
                    round: i + 1,
                  });
                }
              });
              let keepthisOtherStr = "";
              thisOther.forEach((row) => {
                keepthisOtherStr += row?.round + "." + "" + row?.name + "|";
              });
              task["other"] = keepthisOtherStr;
              //

              let thisInfection = [];
              thisFollows?.forEach((a, i) => {
                if (a["exit_site_infection"]) {
                  thisInfection.push({
                    name: a["exit_site_infection_name"],
                    round: i + 1,
                  });
                }
              });
              let keepthisInfecStr = "";
              thisInfection.forEach((row) => {
                keepthisInfecStr += row?.round + "." + "" + row?.name + "|";
              });
              task["exit_site_infection"] = keepthisInfecStr;
              //
              let total_cather_day = 0;
              const findFollowTotal = thisFollows?.find((row) => {
                if (row?.line_is_no_loanger) {
                  return row;
                }
              });
              if (findFollowTotal) {
                total_cather_day = timeDifference(
                  new Date(`${findFollowTotal?.line_is_no_loanger}`),
                  new Date(`${task?.final_date_success}`)
                );
              }
              task["totalDay"] = total_cather_day;
              task["relative_satisfaction"] =
                findFollowTotal?.relative_satisfaction
                  ? findFollowTotal?.relative_satisfaction
                  : "-";
              task["outcome"] = findFollowTotal?.summary_alive
                ? "A"
                : findFollowTotal?.summary_deceased
                ? "D"
                : "-";
              task["reason"] =
                findFollowTotal?.other_reasons_for_discontinuation_name
                  ? findFollowTotal?.other_reasons_for_discontinuation_name
                  : findFollowTotal?.reasons_for_discontinuation?.label
                  ? findFollowTotal?.reasons_for_discontinuation?.label
                  : "";
              task["satisfaction"] =
                findFollowTotal?.patient_care_personnel_satisfaction
                  ? findFollowTotal?.patient_care_personnel_satisfaction
                  : "-";
              task["count_of_follow"] = thisFollows?.length || 0;

              task["status"] =
                task?.status === "keep_for_report"
                  ? "ไม่ดำเนินการต่อ"
                  : task?.status;
              task["time_catheter"] =
                task?.paper_date && task?.final_date_success
                  ? timeDifference(
                      new Date(`${task?.final_date_success}`),
                      new Date(`${task?.paper_date}`)
                    )
                  : "-";

              newDatas.push(task);

              setTempTask((prev) => prev + 1);
              task["id_picc_new"] = newDatas?.length;
              // ล่าง big
            }
          }
        }

        // }
      }

      maxAttempt = findMaxLength(countAttempt);

      let injectAttempts = newDatas.map((data) => {
        let sameObj = { ...data };
        let i = 0;
        for (const attempt of data?.attempts) {
          sameObj = {
            ...sameObj,
            [`attempt_name_${i + 1}`]: attempt?.attending_name?.label,
            [`use_fellow_${i + 1}`]: attempt?.fellow_name,
            [`use_resident_${i + 1}`]: attempt?.resident_name,
            [`use_operater_${i + 1}`]: attempt?.other_name,
            [`use_side_${i + 1}`]: attempt?.rt ? "Rt" : attempt?.lt ? "Lt" : "",
            [`use_limb_${i + 1}`]: formatLimb(
              attempt?.ue,
              attempt?.le,
              attempt?.neck,
              attempt?.subcalvian,
              attempt?.femoral,
              attempt?.other_position_name
            ),
            [`use_pneumothorax_${i + 1}`]: attempt?.complications_pneumothorax
              ? "1"
              : "",
            [`use_hemothorax_${i + 1}`]: attempt?.complications_hemothorax
              ? "1"
              : "",
            [`use_arterial_${i + 1}`]: attempt?.complications_arterial_puncture
              ? "1"
              : "",
            [`use_paresthesia_${i + 1}`]: attempt?.complications_paresthesia
              ? "1"
              : "",
            [`use_nerve_${i + 1}`]: attempt?.complications_nerve_injury
              ? "1"
              : "",
            [`use_limbischemia_${i + 1}`]:
              attempt?.complications_limb_ischemia_related_to_arterial_puncture
                ? "1"
                : "",
            [`use_other_com_${i + 1}`]: attempt?.other_complications ? "1" : "",
            [`use_other_com_detail_${i + 1}`]: attempt?.other_complications_name
              ? attempt?.other_complications_name
              : "",
            [`use_date_${i + 1}`]: attempt?.date
              ? new Date(attempt?.date).toLocaleDateString("th-TH")
              : "",
            [`attempt_start_${i + 1}`]: attempt?.room_in
              ? attempt?.room_in
              : "",
            [`attempt_end_${i + 1}`]: attempt?.out_of_room
              ? attempt?.out_of_room
              : "",
            [`start_to_end_${i + 1}`]:
              attempt?.room_in && attempt?.out_of_room
                ? diffTime(attempt?.room_in, attempt?.out_of_room)
                : "",
            [`puncture_${i + 1}`]: attempt?.puncture ? attempt?.puncture : "",
            [`successful_${i + 1}`]:
              attempt?.status === "Successful" ? "Y" : "N",
            [`line_finalized_${i + 1}`]: attempt?.line_finalized
              ? attempt?.line_finalized
              : "",
            [`punct_to_final_${i + 1}`]:
              attempt?.puncture && attempt?.line_finalized
                ? diffTime(attempt?.puncture, attempt?.line_finalized)
                : "",
            [`procedure_note_${i + 1}`]: attempt?.note ? attempt?.note : "",
          };
          i++;
        }
        return {
          ...sameObj,
        };
      });

      console.log("injectAttempts", injectAttempts);

      const findDuplicated = injectAttempts?.filter((item, index) => {
        return (
          injectAttempts?.findIndex((i) => {
            return i?._id === item?._id;
          }) !== index
        );
      });

      console.log("findDuplicated", findDuplicated);

      //remove duplicated _id
      const FilteredInjectAttempts = injectAttempts.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t._id === thing._id)
      );

      console.log("FilteredInjectAttempts", FilteredInjectAttempts);

      const formatData2 = [{ data: FilteredInjectAttempts }];
      const excelExport = new ExcelExport();
      const settings = generateFunctionFromData(
        maxAttempt,
        startMonthNew,
        startYearNew,
        endMonthNew,
        endYearNew,
        type
      );

      await excelExport.downloadExcel(settings, formatData2);

      // const findDuplicated = formatData2?.tasks?.filter((item, index) => {
      //   return (
      //     formatData2?.tasks?.findIndex((i) => {
      //       return i?._id === item?._id;
      //     }) !== index
      //   );
      // });

      // console.log("findDuplicated", findDuplicated);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const onClickExportExcelKeep = async () => {
    try {
      setLoading(true);
      const limit = 100;
      const countCeil = Math.ceil(dataDashboard.total / limit) - 1;
      let newDatas = [];

      const startDate = `${startYear?.value}-${startMonth?.value}-01`;
      const endDate = `${endYear?.value}-${endMonth?.value}-31`;
      const thisUserDept = userDept
        ? getUserDepartment(userDept)
        : getUserDepartment(user?.user_department);
      const { data } = await api.get(
        `/tasks/countdate?startDate=${startDate}&endDate=${endDate}&sort=paper_date:1`,
        {
          params: {
            user_department: thisUserDept,
          },
        }
      );
      setCountTask(data?.countTask);

      for (let index = 0; index <= countCeil; index++) {
        let { data } = await api.get(
          `/tasks?skip=${index * limit}&limit=${limit}`,
          {
            params: {
              user_department: thisUserDept,
            },
          }
        );

        const startDate = `${startYear?.value}-${startMonth?.value}-01 `;
        const endDate = `${endYear?.value}-${endMonth?.value}-31 `;

        const thisData = data?.tasks?.filter((row) => {
          const dateValue = formatDate(row?.createdAt);

          return dateValue >= startDate && dateValue <= endDate;
        });
        // หาความยาว

        if (thisData?.length > 0) {
          const tasks = thisData;

          for (let task of tasks) {
            if (task?.status === "keep_for_report") {
              task["id"] = task?._id;
              task["name"] = task?.full_name ?? "-";
              task["last_name"] = task?.last_name ?? "-";
              task["status"] = task?.status ? "ไม่ดำเนินการต่อ" : "-";
              task["patient_type"] = task?.other_patient_type_name
                ? task?.other_patient_type_name
                : task?.patient_type?.label
                ? task?.patient_type?.label
                : "";

              task["ward1"] = task?.option_ward?.label
                ? task?.option_ward?.label
                : "";
              task["ward2"] = task?.ward?.label
                ? task?.ward?.label
                : task?.other_ward_name
                ? task?.other_ward_name
                : "";
              task["paper_date"] = task?.paper_date
                ? new Date(task?.paper_date).toLocaleDateString("th-TH")
                : "";
              task["paper_time"] = task?.paper_time ? task?.paper_time : "";

              task["promise_date"] = task?.promise
                ? new Date(task?.promise).toLocaleDateString("th-TH")
                : "";
              task["reasons"] = task?.reason_othrtkeep
                ? task?.reason_othrtkeep
                  ? task?.reason_othrtkeep
                  : task?.reason_keep?.label
                : "- ";
              task["other_des"] = task?.other_description ?? "-";
              task["cancel_date"] = task?.cancel_date
                ? new Date(task?.cancel_date).toLocaleDateString("th-TH")
                : new Date(task?.updatedAt).toLocaleDateString("th-TH");

              newDatas.push(task);

              setTempTask((prev) => prev + 1);
              task["number"] = newDatas?.length;
            }
          }
        }

        // }
      }

      const formatData = [{ data: newDatas }];
      const excelExport = new ExcelExport();
      const settings = formatExcelKeep(
        startMonth,
        startYear,
        endMonth,
        endYear
      );

      await excelExport.downloadExcel(settings, formatData);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  if (loading) return <Loading count={percent} />;

  return (
    <div className="grid col-span-1 justify-center ">
      {isAN ? (
        <div className=" flex gap-3 justify-center items-center mb-10 mt-20">
          <button
            className={classNames(
              "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
              isActivated === "All"
                ? "bg-blue-500 text-gray-900"
                : "bg-blue-200"
            )}
            onClick={() => {
              setUserDept("All");
              setIsActivated("All");
            }}
          >
            All
          </button>
          <button
            className={classNames(
              "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
              isActivated === "AN" ? "bg-blue-500 text-gray-900" : "bg-blue-200"
            )}
            onClick={() => {
              setUserDept("AN");
              setIsActivated("AN");
            }}
          >
            AN
          </button>
          <button
            className={classNames(
              "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
              isActivated === "IR" ? "bg-blue-500 text-gray-900" : "bg-blue-200"
            )}
            onClick={() => {
              setUserDept("IR");
              setIsActivated("IR");
            }}
          >
            IR
          </button>
          <button
            className={classNames(
              "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
              isActivated === "TR" ? "bg-blue-500 text-gray-900" : "bg-blue-200"
            )}
            onClick={() => {
              setUserDept("TR");
              setIsActivated("TR");
            }}
          >
            TR
          </button>
          <button
            className={classNames(
              "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
              isActivated === "PED"
                ? "bg-blue-500 text-gray-900"
                : "bg-blue-200"
            )}
            onClick={() => {
              setUserDept("PED");
              setIsActivated("PED");
            }}
          >
            PED
          </button>
        </div>
      ) : null}
      {isNurse ? (
        `คุณไม่สามารถเข้าถึงหน้านี้ได้`
      ) : (
        <div className="flex justify-center gap-4">
          <div className="">
            <div className="border-2 p-4 rounded-xl my-40 ">
              <div className="text-center font-bold text-sm ">
                ข้อมูลกลุ่ม PICC line, Central line (full v.1)
              </div>
              <div className="mt-5">
                <DropdownReport
                  title={"ระบุประเภทสาย"}
                  className="w-85"
                  placeholder={"ระบุ"}
                  options={optionType}
                  value={type}
                  setValue={setType}
                />
              </div>
              <div className="text-left text-sm p-2"> จาก </div>
              <div className="flex">
                <div className=" p-1 gap-4">
                  {" "}
                  <DropdownReport
                    title={""}
                    className="w-40"
                    placeholder={"ระบุเดือน"}
                    options={optionFullMonth}
                    value={startMonthNew}
                    setValue={setStartMonthNew}
                  />
                </div>
                <div className=" p-1 gap-4">
                  <DropdownReport
                    title={""}
                    className="w-40"
                    placeholder={"ระบุปี"}
                    options={optionsYear}
                    value={startYearNew}
                    setValue={setStartYearNew}
                  />
                </div>
              </div>
              <div className="text-left text-sm p-2"> ถึง </div>
              <div className="flex">
                <div className=" p-1 gap-4">
                  <DropdownReport
                    title={""}
                    className="w-40"
                    placeholder={"ระบุเดือน"}
                    options={optionFullMonth}
                    value={endMonthNew}
                    setValue={setEndMonthNew}
                  />
                </div>
                <div className=" p-1 gap-4">
                  <DropdownReport
                    title={""}
                    className="w-40"
                    placeholder={"ระบุปี"}
                    options={optionsYear}
                    value={endYearNew}
                    setValue={setEndYearNew}
                  />
                </div>
              </div>
              {startMonthNew &&
                startYearNew &&
                endMonthNew &&
                endYearNew &&
                type.value !== "" && (
                  <div className="flex justify-center p-2">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2  px-4 rounded"
                      onClick={onClickExportExcelType}
                    >
                      EXPORT
                      <div></div>
                    </button>
                  </div>
                )}
            </div>
          </div>
          <div className="">
            <div className="border-2 p-4 rounded-xl my-40 ">
              <div className="text-center font-bold text-sm ">
                ข้อมูลกลุ่มไม่ดำเนินการต่อ
              </div>
              <div className="text-left text-sm p-2"> จาก </div>
              <div className="flex">
                <div className=" p-1 gap-4">
                  {" "}
                  <DropdownReport
                    title={""}
                    className="w-40"
                    placeholder={"ระบุเดือน"}
                    options={optionFullMonth}
                    value={startMonth}
                    setValue={setStartMonth}
                  />
                </div>
                <div className=" p-1 gap-4">
                  <DropdownReport
                    title={""}
                    className="w-40"
                    placeholder={"ระบุปี"}
                    options={optionsYear}
                    value={startYear}
                    setValue={setStartYear}
                  />
                </div>
              </div>
              <div className="text-left text-sm p-2"> ถึง </div>
              <div className="flex">
                <div className=" p-1 gap-4">
                  <DropdownReport
                    title={""}
                    className="w-40"
                    placeholder={"ระบุเดือน"}
                    options={optionFullMonth}
                    value={endMonth}
                    setValue={setEndMonth}
                  />
                </div>
                <div className=" p-1 gap-4">
                  <DropdownReport
                    title={""}
                    className="w-40"
                    placeholder={"ระบุปี"}
                    options={optionsYear}
                    value={endYear}
                    setValue={setEndYear}
                  />
                </div>
              </div>
              {startMonth && startYear && endMonth && endYear && (
                <div className="flex justify-center p-2">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2  px-4 rounded"
                    onClick={onClickExportExcelKeep}
                  >
                    EXPORT
                    <div></div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExportMain;
