import { ZAxis } from "recharts";

export const getUserDepartment = (userDep) => {
  let result = "";
  if (
    userDep === "super_AN" ||
    userDep === "AN" ||
    userDep === "NURSE" ||
    userDep === "HEMATO"
  ) {
    // setUserDept("AN");

    result = "AN";
  } else if (userDep === "special_IR" || userDep === "IR") {
    // setUserDept("IR");
    result = "IR";
  } else if (userDep === "speacial_TR" || userDep === "TR") {
    // setUserDept("TR");
    result = "TR";
  } else if (userDep === "special_PED" || userDep === "PED") {
    // setUserDept("PED");
    result = "PED";
  } else if (userDep === "All" || userDep === "NURSEWARD") {
    // setUserDept("All");
    result = "All";
  }

  return result;
};
export const getUserDepartment2 = (userDep) => {
  let result = "";
  if (userDep === "super_AN" || userDep === "AN" || userDep === "HEMATO") {
    // setUserDept("AN");

    result = "AN";
  } else if (userDep === "special_IR" || userDep === "IR") {
    // setUserDept("IR");
    result = "IR";
  } else if (userDep === "speacial_TR" || userDep === "TR") {
    // setUserDept("TR");
    result = "TR";
  } else if (userDep === "special_PED" || userDep === "PED") {
    // setUserDept("PED");
    result = "PED";
  } else if (
    userDep === "All" ||
    userDep === "NURSE" ||
    userDep === "NURSEWARD"
  ) {
    // setUserDept("All");
    result = "All";
  }

  return result;
};
