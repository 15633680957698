import React from "react";

const HistoryMain = ({ history = [] }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div
        style={
          open
            ? {
                width: "300px",
                height: "600px",
                //   opacity: open ? 1 : 0,
              }
            : { width: "90px", height: "50px"   }
        }
        className={`${
          open
            ? " rounded-md bg-white text-textblue border border-tablehead overflow-x-scroll"
            : " rounded-full bg-bluestatus text-textblue "
        } transition-all duration-500 fixed right-5 bottom-5 items-center  p-4 h-15 w-15 cursor-pointer m-auto text-center`}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {open ? (

          <div>
            <div className="font-semibold"> รายการผู้บันทึกข้อมูล</div>
            {history?.map((row, index) => {
              return (
                <div className=" text-left text-sm border border-textblue p-2  py- my-4" key={index}>
                    
                    <div>{index+1}.</div>
                  <div>name : {row?.name}</div>
                  <div>action : {row?.action}</div>
                  <div>
                    date :{" "}
                    {new Date(row?.createdAt).toLocaleDateString("th-TH")}   {new Date(row?.createdAt).toLocaleTimeString("th-TH")} 
                  </div>
                  
                </div>
              );
            })}
          </div>
        ) : (
          <div className=" text-sm font-semibold" >Recorder</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default HistoryMain;
