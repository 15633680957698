const InputTime = ({
  name,
  setField = false,
  title,
  value,
  disabled = false,
  classNameTitle,
  setValue = false,
  setStateObject = false,
}) => {
  const onChange = (e) => {
    const thisValue = e.target.value;
    if (setValue && !setStateObject) {
      setValue(thisValue);
    } else if (setValue && setStateObject) {
      setValue((prev) => {
        let temp = { ...prev };
        temp[name] = thisValue;
        return { ...temp };
      });
    } else if (setField) {
      setField(name)(thisValue);
    } else {
      return;
    }
  };
  return (
    <div>
      <div className={classNameTitle ? classNameTitle : "my-2"}>{title}</div>
      <input
        type="time"
        name={name}
        onChange={onChange}
        value={value}
        className="border border-gray-300 p-2 rounded-md mx-10"
        disabled={disabled}
      />
    </div>
  );
};
export default InputTime;
