import React, { PureComponent, useState, useEffect } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Cell,
  LabelList,
} from "recharts";
import Loading from "../../components/loading/Loading";
import DropdownReport from "./report.dropdwn";

import {
  GenerateOptionsYear,
  displayValue,
} from "../../components/functions/Functions";
import { ExcelGender, ExportGender } from "./functions/Report.ExportGender";
import FunctionCheckType from "./functions/Report.FunctionCheckType";
import ReportCountGender from "./functions/Report.CountGender";
import ReportCountIndications from "./functions/Report.CountIndications";
import ReportCountConsultPapers from "./functions/Report.CountConsultPapers";
import CountCatheter from "./functions/Report.CountCatheter";
import { Doughnut, Pie } from "react-chartjs-2";
import CountTimePaperToCatheter from "./functions/Report.CountTimePaperToCat";
import CountWeight from "./functions/Report.CountWeight";
import CountLabelCatheter from "./functions/Report.CountLabelCatheter";
import ReportCountPatency from "./functions/Report.CountPatency";
import api from "../../api";
import ReportCountVisit from "./functions/Report.CountVisit";
import ReportCountFirstAttempt from "./functions/Report.CountFirstAttempt";
import ReportCountAttemptByDoc from "./functions/Report.CountAttemptByDoc";
import ReportCountAvgTimeAt from "./functions/Report.CountAvgTime";
import { ButtonExcel } from "../../utils/buttonLoadExcel";
import { ExcelPaperConsult } from "./functions/Report.ExportPaper";
import {
  formatConfrimation,
  formatDataAttemptByDoc,
  formatDataAvgAttempt,
  formatDataCatPICCCEN,
  formatDataCatheter,
  formatDataGender,
  formatDataHeight,
  formatDataIndications,
  formatDataLabelCatheterType,
  formatDataPaper,
  formatDataPaperAll,
  formatDataPatient,
  formatDataSideCat,
  formatDataWeight,
  formatFollowVisit,
  formatInfectionAt,
  formatLinePatency,
  formatRating,
  formatReason,
  formatReasonByStatus,
  formatSucAttempt,
  formatTimeAttmept,
  formatTimeWaitCat,
  formatTotalDay,
} from "./utils/Report.Format";
import { ExcelCatheter } from "./functions/Report.ExportCatheter";
import { ExcelWaitCat } from "./functions/Report.ExportWaitCat";
import ReportCountRating from "./functions/Report.CountRating";
import ReportCountInfectionAttempt from "./functions/Report.CountInfectionAttempt";
import ReportCountConsultFirst from "./functions/Report.CountConsult";
import ReportCountPatientType from "./functions/Report.CountPatientType";
import { ExcelPaperConsultAll } from "./functions/Report.ExportPaperAll";
import ReportCountReason from "./functions/Report.CountReason";
import CountLabelCatheterType from "./functions/Report.CountLabelCatheterType";
import CountTotalDay from "./functions/Report.CountTotalDay";
import ReportCountReasonByStatatus from "./functions/Report.CoutReaSonByStatus";
import { calculateAverage } from "./utils/useFunction";
import ReportCountConfirmation from "./functions/Report.CountConfirmation";
import ReportCountSideCat from "./functions/Report.CountSideCat";
import ReportCountHeight from "./functions/Report.CountHeight";
import CountTimeAttempt from "./functions/Report.CountTimeAttempt";
import CountSucAttempt from "./functions/Report.CountSucAttempt";
import { ExcelWeight } from "./functions/Report.ExportWeight";
import { ExceltPatient } from "./functions/Report.ExportPaiten";
import { ExceltCatPICCCEN } from "./functions/Report.ExportCatPICCCEN";
import { ExceltCatLabelName } from "./functions/Report.ExportCatheterType";
import { ExceltIndications } from "./functions/Report.ExportIndications";
import { ExcelHeight } from "./functions/Report.ExportHeight";
import { ExcelAttemptByDoc } from "./functions/Report.ExportAttemptByDoc";
import { ExcelFirstAttempt } from "./functions/Report.ExportAttemptFirst";
import { ExcelAvgAttempt } from "./functions/Report.ExportAvgAttempt";
import { ExcelSideCat } from "./functions/Report.ExportSideCat";
import { ExcelConfrimation } from "./functions/Report.ExportConfirmation";
import { ExcelInfectionAt } from "./functions/Report.ExportInfectionAt";
import { ExcelTotalDay } from "./functions/Report.ExportTotalDay";
import { ExcelReasonByStatus } from "./functions/Report.ExportReasonStatus";
import { ExcelReason } from "./functions/Report.ExportReson";
import { ExcelFollowVisit } from "./functions/Report.ExportCountVisit";
import { ExcelRating } from "./functions/Report.ExportRating";
import { ExcelTimeAttempt } from "./functions/Report.ExportTimeAttempt";
import { ExcelSucAttempt } from "./functions/Report.ExportSucAttempt";
import { ExceltLinePatency } from "./functions/Report.ExportLinePatency";
import {
  BARCHART,
  FLEXDROPDOWN,
  LEFTTABLE,
  LEFTTHTABLE,
  RIGHTTABLE,
  SCREENIPAD,
  STYLEBODY,
  STYLEHEAD,
  TABLE,
  TABLESTYLE,
  THTABLE,
  TRTABLE,
  TXCENTER,
  TXLEFT,
} from "../../utils/useStyle";
import numeral from "numeral";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { ReportLoadData } from "./functions/Report.LoadData";
import CountCatheterAll from "./functions/Report.CountCatheterAll";
import { is } from "date-fns/locale";
import ReportCountTorso from "./functions/Report.CountTorso";
import CountDepartment from "./functions/Report.CountDepartment";
import { ReportLoadData2 } from "./functions/Report.LoadData2";
import userReportStore from "../../_store/userReportStore";
import { ExportCountDepartment } from "./functions/Report.ExportCountDepartment";
import { ExportCatheterLine } from "./functions/Report.CountCatheterLine";
import { chartColors } from "../../utils/colors";
import ReportCountInfectionAttempt2 from "./functions/ReportCountInfectionAttempt2";
import ReportCountAttemptByDoc2 from "./functions/Report.CountAttemptByDoc2";

// import userReportStore from "../../_store/userReportStore";
const optionsYear = GenerateOptionsYear();
const optionsMonth = [
  { value: "ALL", label: "ทุกเดือน" },
  { value: "1", label: "มกราคม" },
  { value: "2", label: "กุมภาพันธ์" },
  { value: "3", label: "มีนาคม" },
  { value: "4", label: "เมษายน" },
  { value: "5", label: "พฤษภาคม" },
  { value: "6", label: "มิถุนายน" },
  { value: "7", label: "กรกฎาคม" },
  { value: "8", label: "สิงหาคม" },
  { value: "9", label: "กันยายน" },
  { value: "10", label: "ตุลาคม" },
  { value: "11", label: "พฤศจิกายน" },
  { value: "12", label: "ธันวาคม" },
];
const optionsTpye = [
  { value: "NONE", label: "-" },
  { value: "PICC", label: "PICC Line" },
  { value: "CENTRAL_LINE", label: "Central Line" },
];

const getUserDepartment = (userDep) => {
  let result = "";
  if (userDep === "super_AN" || userDep === "AN" || userDep === "NURSE") {
    // setUserDept("AN");

    result = "AN";
  } else if (userDep === "special_IR" || userDep === "IR") {
    // setUserDept("IR");
    result = "IR";
  } else if (userDep === "speacial_TR" || userDep === "TR") {
    // setUserDept("TR");
    result = "TR";
  } else if (userDep === "special_PED" || userDep === "PED") {
    // setUserDept("PED");
    result = "PED";
  }
  return result;
};

const DropdownOne = ({ year, setYear, type, setType, month, setMonth }) => {
  return (
    <div class="flex space-x-2">
      {/* <DropdownReport
        title={"เดือน"}
        options={optionsMonth}
        value={month}
        setValue={setMonth}
      /> */}
      <DropdownReport
        title={"ปี"}
        options={optionsYear}
        value={year}
        setValue={setYear}
      />
      <DropdownReport
        title={"ประเภทสาย"}
        options={optionsTpye}
        value={type}
        setValue={setType}
      />
    </div>
  );
};

const DropdownOneNoType = ({
  year,
  setYear,
  type,
  setType,
  month,
  setMonth,
}) => {
  return (
    <div class="flex space-x-2">
      {/* <DropdownReport
        title={"เดือน"}
        options={optionsMonth}
        value={month}
        setValue={setMonth}
      /> */}
      <DropdownReport
        title={"ปี"}
        options={optionsYear}
        value={year}
        setValue={setYear}
      />
      {/* <DropdownReport
        title={"ประเภทสาย"}
        options={optionsTpye}
        value={type}
        setValue={setType}
      /> */}
    </div>
  );
};

const DropdownTwo = ({ year, setYear, type, setType, month, setMonth }) => {
  const isMonthDisabled = year.value === "ALL";
  return (
    <div class="flex space-x-2">
      <DropdownReport
        title={"ปี"}
        options={optionsYear}
        value={year}
        setValue={setYear}
        sideFunction={(val) => {
          if (val?.value === "ALL") {
            setMonth({
              label: "ทุกเดือน",
              value: "ALL",
            });
          }
          // console.log(`KKK`);
          // console.log(`val: `, val);
        }}
      />
      <DropdownReport
        title={"เดือน"}
        options={optionsMonth}
        value={month}
        setValue={setMonth}
        disabled={isMonthDisabled}
      />
      <DropdownReport
        title={"ประเภทสาย"}
        options={optionsTpye}
        value={type}
        setValue={setType}
      />
    </div>
  );
};

const DropdownRange = ({
  startYear,
  setStartYear,
  endYear,
  setEndYear,
  startMonth,
  setStartMonth,
  endMonth,
  setEndMonth,
}) => {
  // Disable the month dropdowns if "ALL" is selected for year
  const isStartMonthDisabled = startYear.value === "ALL";
  const isEndMonthDisabled = endYear.value === "ALL";

  // Validation (optional): Ensure start is not after end

  return (
    <div class="flex flex-wrap justify-end space-x-2">
      <div class="flex space-x-3">
        <DropdownReport
          title={"ปีที่เริ่มต้น"}
          options={optionsYear}
          value={startYear}
          setValue={setStartYear}
          classNameTitle={` text-gray-500  `}
          sideFunction={(val) => {
            if (val?.value === "ALL") {
              setStartMonth({
                label: "ทุกเดือน",
                value: "ALL",
              });
            }
          }}
        />
        <DropdownReport
          title={"เดือนที่เริ่มต้น"}
          options={optionsMonth}
          value={startMonth}
          setValue={setStartMonth}
          disabled={isStartMonthDisabled}
          classNameTitle={` text-gray-500  `}
        />
      </div>

      <div class="flex space-x-3">
        <DropdownReport
          title={"ปีที่สิ้นสุด"}
          options={optionsYear}
          value={endYear}
          setValue={setEndYear}
          classNameTitle={` text-gray-500  `}
          sideFunction={(val) => {
            if (val?.value === "ALL") {
              setEndMonth({
                label: "ทุกเดือน",
                value: "ALL",
              });
            }
          }}
        />
        <DropdownReport
          title={"เดือนที่สิ้นสุด"}
          options={optionsMonth}
          value={endMonth}
          setValue={setEndMonth}
          disabled={isEndMonthDisabled}
          classNameTitle={` text-gray-500  `}
        />
      </div>
    </div>
  );
};

const DropdownMonthYear = ({
  year,
  setYear,
  type,
  setType,
  month,
  setMonth,
}) => {
  const isMonthDisabled = year.value === "ALL";
  return (
    <div class="flex space-x-2">
      <DropdownReport
        title={"ปี"}
        options={optionsYear}
        value={year}
        setValue={setYear}
        sideFunction={(val) => {
          if (val?.value === "ALL") {
            setMonth({
              label: "ทุกเดือน",
              value: "ALL",
            });
          }
        }}
      />
      <DropdownReport
        title={"เดือน"}
        options={optionsMonth}
        value={month}
        setValue={setMonth}
        disabled={isMonthDisabled}
      />
      {/* <DropdownReport
        title={"ประเภทสาย"}
        options={optionsTpye}
        value={type}
        setValue={setType}
      /> */}
    </div>
  );
};

const DropdownFollows = ({ year, setYear }) => {
  return (
    <div>
      <DropdownReport
        title={"ปี"}
        options={optionsYear}
        value={year}
        setValue={setYear}
      />
    </div>
  );
};

// tab01 ขั้นตอนการรับปรึกษา
const ReportCountConsultPaper = ({ list }) => {
  const [loading, setLoading] = React.useState("");
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  const [data, setData] = React.useState({
    jan: {
      adult: 0,
      kid: 0,
    },
    feb: {
      adult: 0,
      kid: 0,
    },
    mar: {
      adult: 0,
      kid: 0,
    },
    apr: {
      adult: 0,
      kid: 0,
    },
    may: {
      adult: 0,
      kid: 0,
    },
    jun: {
      adult: 0,
      kid: 0,
    },
    jul: {
      adult: 0,
      kid: 0,
    },
    aug: {
      adult: 0,
      kid: 0,
    },
    sep: {
      adult: 0,
      kid: 0,
    },
    oct: {
      adult: 0,
      kid: 0,
    },
    nov: {
      adult: 0,
      kid: 0,
    },
    dec: {
      adult: 0,
      kid: 0,
    },
  });

  // const { reportLoading, setReportLoading } = userReportStore();

  React.useEffect(() => {
    // ReportCountGender(String(year.value), String(type.value) ,);
    ReportCountConsultPapers({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
      // setReportLoading,
    });
  }, [year, type]);

  const listTable = [
    {
      name: "ม.ค.",
      child: data?.jan?.kid,
      adult: data?.jan?.adult,
    },
    {
      name: "ก.พ.",
      child: data?.feb?.kid,
      adult: data?.feb?.adult,
    },
    {
      name: "มี.ค.",
      child: data?.mar?.kid,
      adult: data?.mar?.adult,
    },
    {
      name: "เม.ย.",
      child: data?.apr?.kid,
      adult: data?.apr?.adult,
    },
    {
      name: "พ.ค.",
      child: data?.may?.kid,
      adult: data?.may?.adult,
    },
    {
      name: "มิ.ย.",
      child: data?.jun?.kid,
      adult: data?.jun?.adult,
    },
    {
      name: "ก.ค.",
      child: data?.jul?.kid,
      adult: data?.jul?.adult,
    },
    {
      name: "ส.ค.",
      child: data?.aug?.kid,
      adult: data?.aug?.adult,
    },
    {
      name: "ก.ย.",
      child: data?.sep?.kid,
      adult: data?.sep?.adult,
    },
    {
      name: "ต.ค.",
      child: data?.oct?.kid,
      adult: data?.oct?.adult,
    },
    {
      name: "พ.ย.",
      child: data?.nov?.kid,
      adult: data?.nov?.adult,
    },
    {
      name: "ธ.ค.",
      child: data?.dec?.kid,
      adult: data?.dec?.adult,
    },
  ];

  console.log("Year", year.value);

  return (
    <>
      <div className={``}>
        <div className="my-20">
          <>
            <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>
                จำนวนใบปรึกษาที่ได้รับทั้งหมดต่อปี
                <div className={STYLEBODY}>
                  แยกตามเดือน แยกเป็น เด็ก (ต่ำกว่า 18ปี) ผู้ใหญ่ (18 ปีขึ้นไป)
                  ของปี "{year.label}" ประเภทสาย "{type.label}"
                </div>
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownOne
                  year={year}
                  setYear={setYear}
                  type={type}
                  setType={setType}
                />
                <ButtonExcel
                  text={"DOWNLOAD EXCEL"}
                  onClick={() => {
                    ExcelPaperConsult(
                      formatDataPaper(data),
                      year,
                      type,
                      setLoading
                    );
                  }}
                  loading={loading}
                />
              </div>
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listTable}
                margin={{
                  top: 20,

                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  label={{ fill: "#111827" }}
                  dataKey="child"
                  fill="#fda4af"
                />
                <Bar
                  label={{ fill: "#111827" }}
                  dataKey="adult"
                  fill="#fecdd3"
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const ReportCountConsult = ({ list }) => {
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });
  const [loading, setLoading] = React.useState("");

  const [data, setData] = React.useState({
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
  });

  React.useEffect(() => {
    ReportCountConsultFirst({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
    });
  }, [year, type]);

  const listTable = [
    {
      name: "ม.ค.",
      count: data?.jan,
    },
    {
      name: "ก.พ.",
      count: data?.feb,
    },
    {
      name: "มี.ค.",
      count: data?.mar,
    },
    {
      name: "เม.ย.",
      count: data?.apr,
    },
    {
      name: "พ.ค.",
      count: data?.may,
    },
    {
      name: "มิ.ย.",
      count: data?.jun,
    },
    {
      name: "ก.ค.",
      count: data?.jul,
    },
    {
      name: "ส.ค.",
      count: data?.aug,
    },
    {
      name: "ก.ย.",
      count: data?.sep,
    },
    {
      name: "ต.ค.",
      count: data?.oct,
    },
    {
      name: "พ.ย.",
      count: data?.nov,
    },
    {
      name: "ธ.ค.",
      count: data?.dec,
    },
  ];

  return (
    <>
      <div className="">
        <div className="my-10">
          <>
            <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>
                จำนวนใบปรึกษาที่ได้รับทั้งหมดต่อปี
                <div className={STYLEBODY}>
                  แยกตามเดือน ของปี {year.label}" ประเภทสาย "{type.label}"
                </div>
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownOne
                  year={year}
                  setYear={setYear}
                  type={type}
                  setType={setType}
                />
                <ButtonExcel
                  onClick={() => {
                    ExcelPaperConsultAll(
                      formatDataPaperAll(data),
                      year,
                      type,
                      setLoading
                    );
                  }}
                  loading={loading}
                />
              </div>
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listTable}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}
                <Bar
                  dataKey="count"
                  fill="#d8b4fe"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

// tab02  Demographic ของผู้เข้ารับบริการ
const ReportWeight = ({ list }) => {
  // console.log("listWeight", list);
  // const [genderMen, setGenderMen] = React.useState("");
  // const [genderWomen, setGenderWomen] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  const [data, setData] = React.useState({
    min: 0,
    max: 0,
    median: 0,
    mode: [], // ค่า mode สามารถมีได้หลายค่า
    average: 0,
    sumAll: 0,
    count: 0,
    sd: 0,
  });

  React.useEffect(() => {
    CountWeight({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
    });
  }, [year, type]);

  // console.log("น้ำหนักตัวผู้ป่วย", data);

  return (
    <>
      <div>
        <div className="my-10">
          <>
            <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>น้ำหนักตัวผู้ป่วย</div>
              <div className={FLEXDROPDOWN}>
                <DropdownOne
                  year={year}
                  setYear={setYear}
                  type={type}
                  setType={setType}
                />
                <ButtonExcel
                  onClick={() => {
                    ExcelWeight(formatDataWeight(data), year, type, setLoading);
                  }}
                  loading={loading}
                />
              </div>
            </div>

            <table className={TABLE}>
              <thead>
                <tr>
                  <th className={LEFTTABLE}>รายการ</th>
                  <th className={RIGHTTABLE}>ค่าสถิติ</th>
                </tr>
              </thead>
              <tbody>
                <tr className={TRTABLE}>
                  <td className={TXCENTER}> Mean </td>
                  <td className={TXCENTER}> {displayValue(data?.average)}</td>
                </tr>
                <tr className={TRTABLE}>
                  <td className={TXCENTER}>Median </td>
                  <td className={TXCENTER}> {displayValue(data?.median)}</td>
                </tr>
                <tr className={TRTABLE}>
                  <td className={TXCENTER}>Min </td>
                  <td className={TXCENTER}> {displayValue(data?.min)}</td>
                </tr>
                <tr className={TRTABLE}>
                  <td className={TXCENTER}>Max </td>
                  <td className={TXCENTER}>{displayValue(data?.max)}</td>
                </tr>
                <tr className={TRTABLE}>
                  <td className={TXCENTER}> SD </td>
                  <td className={TXCENTER}>{displayValue(data?.sd)}</td>
                </tr>
                <tr className={TRTABLE}>
                  <td className={TXCENTER}> n </td>
                  <td className={TXCENTER}>{displayValue(data?.count)}</td>
                </tr>
              </tbody>
            </table>
          </>
        </div>
      </div>
    </>
  );
};

const ReportGender = ({ list }) => {
  const [loading, setLoading] = React.useState("");
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  const [data, setData] = React.useState({
    men: 0,
    women: 0,
    checkTask: [],
  });

  const total = data.men + data.women;
  const menPercentage = ((data.men / (data.men + data.women)) * 100).toFixed(1);
  const womenPercentage = (
    (data.women / (data.men + data.women)) *
    100
  ).toFixed(1);

  React.useEffect(() => {
    ReportCountGender({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
    });
  }, [year, type]);

  const listGender = [
    {
      name: `ชาย (${menPercentage}%)`,

      count: data?.men,
    },
    {
      name: `หญิง (${womenPercentage}%)`,
      count: data?.women,
    },
  ];

  // console.log("list", list);
  // console.log("dataGender", data);
  return (
    <>
      <div>
        <div className="my-20">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div className={STYLEHEAD}>
                จำนวนผู้ป่วยเพศชายและเพศหญิง
                <div className={STYLEBODY}>
                  แยกตามปี "{year.label} ตามประเภทสาย "{type.label}"
                </div>
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownOne
                  year={year}
                  setYear={setYear}
                  type={type}
                  setType={setType}
                />
                <ButtonExcel
                  onClick={() => {
                    ExcelGender(formatDataGender(data), year, type, setLoading);
                  }}
                  loading={loading}
                />
              </div>
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listGender}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}

                <Bar
                  dataKey="count"
                  fill="#99CC00"
                  // minPointSize={5}
                  label={true}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const ReportPatientType = ({ list }) => {
  const [loading, setLoading] = React.useState("");
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  const [data, setData] = React.useState({
    type1: 0,
    type2: 0,
    type3: 0,
    type4: 0,
    type5: 0,
    type6: 0,
    type7: 0,
  });

  React.useEffect(() => {
    ReportCountPatientType({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
    });
  }, [year, type]);

  const total =
    data.type1 +
    data.type2 +
    data.type3 +
    data.type4 +
    data.type5 +
    data.type6 +
    data.type7;
  const type1Percentage = ((data.type1 / total) * 100).toFixed(1);
  const type2Percentage = ((data.type2 / total) * 100).toFixed(1);
  const type3Percentage = ((data.type3 / total) * 100).toFixed(1);
  const type4Percentage = ((data.type4 / total) * 100).toFixed(1);
  const type5Percentage = ((data.type5 / total) * 100).toFixed(1);
  const type6Percentage = ((data.type6 / total) * 100).toFixed(1);
  const type7Percentage = ((data.type7 / total) * 100).toFixed(1);

  const listType = [
    {
      name: `อายุรกรรม `,

      count: data?.type1,
    },
    {
      name: `ศัลยกรรม `,

      count: data?.type2,
    },
    {
      name: `สูตินรีเวช `,

      count: data?.type3,
    },
    {
      name: `กุมารเวชศาสตร์ `,

      count: data?.type4,
    },
    {
      name: `หออภิบาล `,

      count: data?.type5,
    },
    {
      name: `ผู้ป่วยนอก `,

      count: data?.type6,
    },
    {
      name: `อื่นๆ`,

      count: data?.type7,
    },
  ];

  // console.log("type1Percentage", type1Percentage);
  // console.log("dataGender", data);
  return (
    <>
      <div className={``}>
        <div className="my-20">
          <>
            <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>
                จำนวนผู้ป่วยตามประเภทผู้ป่วย
                <div className={STYLEBODY}>
                  แยกตามปี "{year.label} ตามประเภทสาย "{type.label}"
                </div>
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownOne
                  year={year}
                  setYear={setYear}
                  type={type}
                  setType={setType}
                />
                <ButtonExcel
                  onClick={() => {
                    ExceltPatient(
                      formatDataPatient(data),
                      year,
                      type,
                      setLoading
                    );
                  }}
                  loading={loading}
                />
              </div>
            </div>

            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listType}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                {/* <XAxis dataKey="name" /> */}
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}

                <Bar
                  dataKey="count"
                  fill="#fdba74"
                  // minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
            <div className="flex ml-16 ">
              <div className="mr-7">
                อายุรกรรม <br /> {type1Percentage}%
              </div>
              <div className="mr-8">
                ศัลยกรรม <br /> {type2Percentage}%
              </div>
              <div className="mr-6">
                สูตินรีเวช <br /> {type3Percentage}%{" "}
              </div>
              <div className="mr-7 text-center">
                กุมาร <br /> {type4Percentage}%<div>เวชศาสตร์</div>
              </div>
              <div className="mr-6">
                หออภิบาล <br /> {type5Percentage}%{" "}
              </div>
              <div className="mr-10">
                ผู้ป่วยนอก <br /> {type6Percentage}%{" "}
              </div>
              <div className="mr-6">
                อื่นๆ <br /> {type7Percentage}%{" "}
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const ReportAllCountCatheter = ({ list }) => {
  // console.log("list", list);
  const [loading, setLoading] = React.useState("");
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  const [data, setData] = React.useState({
    picc: {
      adult: 0,
      kid: 0,
    },
    central: {
      adult: 0,
      kid: 0,
    },
    missingDataDOB: [],
    piccMissingDataDOB: 0,
    centralMissingDataDOB: 0,
    missingDataDOBtask: [],
    filteredRows: [],
  });

  React.useEffect(() => {
    // ReportCountGender(String(year.value), String(type.value) ,);
    CountCatheterAll({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
    });
  }, [year, type]);

  // console.log("AllCountCatheter", data?.filteredRows);

  // const filtered_id = data?.map((item) => item?._id);

  // console.log("filtered_id", filtered_id);

  // const filtered_id = data?.map((item) => item?._id);

  // console.log("filtered_id", filtered_id);

  const piccAdult = data?.picc?.adult;
  const piccKid = data?.picc?.kid;

  const centralAdult = data?.central?.adult;
  const centralKid = data?.central?.kid;

  const piccMissing = data?.piccMissingDataDOB;
  const centralMissing = data?.centralMissingDataDOB;
  const totalMissing = piccMissing + centralMissing;

  const totalAdult = piccAdult + centralAdult;
  const totalKid = piccKid + centralKid;
  const totalPicc = piccAdult + piccKid + piccMissing;
  const totalCentral = centralAdult + centralKid + centralMissing;
  const total = totalAdult + totalKid + totalMissing;

  const submitExcelData = {
    Adult: {
      picc: piccAdult,
      central: centralAdult,
      total: totalAdult,
    },
    Child: {
      picc: piccKid,
      central: centralKid,
      total: totalKid,
    },
    Missing: {
      picc: piccMissing,
      central: centralMissing,
      total: totalMissing,
    },
    Total: {
      picc: totalPicc,
      central: totalCentral,
      total: total,
    },
  };

  return (
    <>
      <div className={``}>
        <div className="my-20">
          <>
            <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>
                จำนวนผู้ป่วยที่ได้รับการใส่สายทั้งหมดต่อปี
                <div className={STYLEBODY}>
                  แยกเป็นผู้ป่วยเด็ก/ผู้ใหญ่ แยกตามเดือน ของปี "{year.label}"
                  ประเภทสาย "{type.label}"
                </div>
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownOneNoType
                  year={year}
                  setYear={setYear}
                  // type={type}
                  // setType={setType}
                />
                <ButtonExcel
                  onClick={() => {
                    ExportCatheterLine(submitExcelData, year);
                  }}
                  loading={loading}
                />
              </div>
            </div>

            <div class="relative overflow-x-auto mt-3">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      ปี {year.label}
                    </th>

                    <th scope="col" class="px-6 py-3">
                      PICC Line
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Central Line
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Adult
                    </th>
                    <td class="px-6 py-4">{piccAdult}</td>
                    <td class="px-6 py-4">{centralAdult}</td>
                    <td class="px-6 py-4">{totalAdult}</td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Child
                    </th>
                    <td class="px-6 py-4">{piccKid}</td>
                    <td class="px-6 py-4">{centralKid}</td>
                    <td class="px-6 py-4">{totalKid}</td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Missing
                    </th>
                    <td class="px-6 py-4">{piccMissing}</td>
                    <td class="px-6 py-4">{centralMissing}</td>
                    <td class="px-6 py-4">{totalMissing}</td>
                  </tr>
                  <tr class="bg-white dark:bg-gray-800">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Total
                    </th>
                    <td class="px-6 py-4">{totalPicc}</td>
                    <td class="px-6 py-4">{totalCentral}</td>
                    <td class="px-6 py-4">{total}</td>
                  </tr>
                </tbody>
              </table>
              <div>
                <i>
                  {" "}
                  *หมายเหตุ Missing หมายถึง
                  ผู้ป่วยที่ได้รับการใส่สายสำเร็จแต่ไม่ได้กรอกข้อมูลวันเกิด
                </i>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const ReportCountCatheter = ({ list }) => {
  const [loading, setLoading] = React.useState("");
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  const [data, setData] = React.useState({
    jan: {
      adult: 0,
      kid: 0,
    },
    feb: {
      adult: 0,
      kid: 0,
    },
    mar: {
      adult: 0,
      kid: 0,
    },
    apr: {
      adult: 0,
      kid: 0,
    },
    may: {
      adult: 0,
      kid: 0,
    },
    jun: {
      adult: 0,
      kid: 0,
    },
    jul: {
      adult: 0,
      kid: 0,
    },
    aug: {
      adult: 0,
      kid: 0,
    },
    sep: {
      adult: 0,
      kid: 0,
    },
    oct: {
      adult: 0,
      kid: 0,
    },
    nov: {
      adult: 0,
      kid: 0,
    },
    dec: {
      adult: 0,
      kid: 0,
    },
    checkTask: [],
  });

  React.useEffect(() => {
    // ReportCountGender(String(year.value), String(type.value) ,);
    CountCatheter({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
    });
  }, [year, type]);

  const listTable = [
    {
      name: "ม.ค.",
      child: data?.jan?.kid,
      adult: data?.jan?.adult,
    },
    {
      name: "ก.พ.",
      child: data?.feb?.kid,
      adult: data?.feb?.adult,
    },
    {
      name: "มี.ค.",
      child: data?.mar?.kid,
      adult: data?.mar?.adult,
    },
    {
      name: "เม.ย.",
      child: data?.apr?.kid,
      adult: data?.apr?.adult,
    },
    {
      name: "พ.ค.",
      child: data?.may?.kid,
      adult: data?.may?.adult,
    },
    {
      name: "มิ.ย.",
      child: data?.jun?.kid,
      adult: data?.jun?.adult,
    },
    {
      name: "ก.ค.",
      child: data?.jul?.kid,
      adult: data?.jul?.adult,
    },
    {
      name: "ส.ค.",
      child: data?.aug?.kid,
      adult: data?.aug?.adult,
    },
    {
      name: "ก.ย.",
      child: data?.sep?.kid,
      adult: data?.sep?.adult,
    },
    {
      name: "ต.ค.",
      child: data?.oct?.kid,
      adult: data?.oct?.adult,
    },
    {
      name: "พ.ย.",
      child: data?.nov?.kid,
      adult: data?.nov?.adult,
    },
    {
      name: "ธ.ค.",
      child: data?.dec?.kid,
      adult: data?.dec?.adult,
    },
  ];

  // console.log("CountCatheter", data);
  return (
    <>
      <div className={``}>
        <div className="my-20">
          <>
            <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>
                จำนวนผู้ป่วยที่ได้รับการใส่สายทั้งหมดต่อปี
                <div className={STYLEBODY}>
                  แยกเป็นผู้ป่วยเด็ก/ผู้ใหญ่ แยกตามเดือน ของปี "{year.label}"
                  ประเภทสาย "{type.label}"
                </div>
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownOne
                  year={year}
                  setYear={setYear}
                  type={type}
                  setType={setType}
                />
                <ButtonExcel
                  onClick={() => {
                    ExcelCatheter(
                      formatDataCatheter(data),
                      year,
                      type,
                      setLoading
                    );
                  }}
                  loading={loading}
                />
              </div>
            </div>

            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listTable}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  label={{ fill: "#111827" }}
                  dataKey="child"
                  fill="#93c5fd"
                />
                <Bar
                  label={{ fill: "#111827" }}
                  dataKey="adult"
                  fill="#bae6fd"
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const ReportCountLabelCatheter = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  const [data, setData] = React.useState({
    jan: {
      picc: 0,
      central: 0,
    },
    feb: {
      picc: 0,
      central: 0,
    },
    mar: {
      picc: 0,
      central: 0,
    },
    apr: {
      picc: 0,
      central: 0,
    },
    may: {
      picc: 0,
      central: 0,
    },
    jun: {
      picc: 0,
      central: 0,
    },
    jul: {
      picc: 0,
      central: 0,
    },
    aug: {
      picc: 0,
      central: 0,
    },
    sep: {
      picc: 0,
      central: 0,
    },
    oct: {
      picc: 0,
      central: 0,
    },
    nov: {
      picc: 0,
      central: 0,
    },
    dec: {
      picc: 0,
      central: 0,
    },
  });

  React.useEffect(() => {
    CountLabelCatheter({
      list,
      setValue: setData,
      year: String(year?.value),
    });
  }, [year, type]);

  const listTable = [
    {
      name: "ม.ค.",
      PICC: data?.jan?.picc,
      CENTRAL: data?.jan?.central,
    },
    {
      name: "ก.พ.",
      PICC: data?.feb?.picc,
      CENTRAL: data?.feb?.central,
    },
    {
      name: "มี.ค.",
      PICC: data?.mar?.picc,
      CENTRAL: data?.mar?.central,
    },
    {
      name: "เม.ย.",
      PICC: data?.apr?.picc,
      CENTRAL: data?.apr?.central,
    },
    {
      name: "พ.ค.",
      PICC: data?.may?.picc,
      CENTRAL: data?.may?.central,
    },
    {
      name: "มิ.ย.",
      PICC: data?.jun?.picc,
      CENTRAL: data?.jun?.central,
    },
    {
      name: "ก.ค.",
      PICC: data?.jul?.picc,
      CENTRAL: data?.jul?.central,
    },
    {
      name: "ส.ค.",
      PICC: data?.aug?.picc,
      CENTRAL: data?.aug?.central,
    },
    {
      name: "ก.ย.",
      PICC: data?.sep?.picc,
      CENTRAL: data?.sep?.central,
    },
    {
      name: "ต.ค.",
      PICC: data?.oct?.picc,
      CENTRAL: data?.oct?.central,
    },
    {
      name: "พ.ย.",
      PICC: data?.nov?.picc,
      CENTRAL: data?.nov?.central,
    },
    {
      name: "ธ.ค.",
      PICC: data?.dec?.picc,
      CENTRAL: data?.dec?.central,
    },
  ];

  // console.log("ReportCountLabelCatheter", data);

  return (
    <div>
      _____________________________________________________________________________________
      <div className={``}>
        <div className="my-20">
          <div className={SCREENIPAD}>
            <> </>
            <div className={STYLEHEAD}>
              นับชนิดของสายน้ำเกลือที่ใส่แต่ละปี แยกตามเดือน
              <div className={STYLEBODY}>ของปี " {year?.label}"</div>
            </div>
            <div className={FLEXDROPDOWN}>
              <DropdownFollows year={year} setYear={setYear} />
              <ButtonExcel
                onClick={() => {
                  ExceltCatPICCCEN(
                    formatDataCatPICCCEN(data),
                    year,
                    setLoading
                  );
                }}
                loading={loading}
              />
            </div>
          </div>
          <div className={BARCHART}>
            <BarChart
              width={700}
              height={300}
              data={listTable}
              margin={{
                top: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar label={{ fill: "#111827" }} dataKey="PICC" fill="#86efac" />
              <Bar
                label={{ fill: "#111827" }}
                dataKey="CENTRAL"
                fill="#bbf7d0"
              />
            </BarChart>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReportCountAllDepartment = ({ list, userDept }) => {
  // console.log("userDept", userDept);
  const [loading, setLoading] = React.useState("");

  const [startYear, setStartYear] = React.useState({
    label: "ทุกปี",
    value: "ALL", // default start year
  });
  const [endYear, setEndYear] = React.useState({
    label: "ทุกปี",
    value: "ALL", // default end year
  });
  const [startMonth, setStartMonth] = React.useState({
    label: "ทุกเดือน",
    value: "ALL",
  });
  const [endMonth, setEndMonth] = React.useState({
    label: "ทุกเดือน",
    value: "ALL",
  });

  const [data, setData] = React.useState({
    an: {
      adult: 0,
      kid: 0,
      total: 0,
    },
    ir: {
      adult: 0,
      kid: 0,
      total: 0,
    },
    tr: {
      adult: 0,
      kid: 0,
      total: 0,
    },
    ped: {
      adult: 0,
      kid: 0,
      total: 0,
    },
    total: {
      adult: 0,
      kid: 0,
      total: 0,
      an: 0,
      ir: 0,
      tr: 0,
      ped: 0,
    },
  });

  React.useEffect(() => {
    // ReportCountGender(String(year.value), String(type.value) ,);
    CountDepartment({
      list,
      setValue: setData,
      startYear: String(startYear?.value),
      endYear: String(endYear?.value),
      startMonth: String(startMonth?.value),
      endMonth: String(endMonth?.value),
    });
  }, [
    list,
    startYear?.value,
    endYear?.value,
    startMonth?.value,
    endMonth?.value,
  ]);

  const submitExcelData = {
    Adult: {
      an: data?.an?.adult,
      ir: data?.ir?.adult,
      tr: data?.tr?.adult,
      ped: data?.ped?.adult,
      total: data?.total?.adult,
    },
    Children: {
      an: data?.an?.kid,
      ir: data?.ir?.kid,
      tr: data?.tr?.kid,
      ped: data?.ped?.kid,
      total: data?.total?.kid,
    },
    Total: {
      an: data?.total?.an,
      ir: data?.total?.ir,
      tr: data?.total?.tr,
      ped: data?.total?.ped,
      total: data?.total?.total,
    },
  };

  const isAll = userDept === "All";

  // console.log("isAll", isAll);

  // console.log("CountDepartment", data);
  return (
    <>
      {isAll ? (
        <div className={``}>
          <div className="my-20">
            <>
              <div className="flex flex-col gap-4">
                <div className="text-black text-lg font-medium">
                  จำนวน PICC Line ที่ใส่ในแต่ละหน่วย <br />
                  <div className={STYLEBODY}>
                    รายงานตั้งแต่ ปี "{startYear.label}" เดือน "
                    {startMonth.label}" ถึง ปี "{endYear.label}" เดือน "
                    {endMonth.label}" แยกเด็กและผู้ใหญ่
                  </div>
                </div>
                <div className=" flex items-end gap-2 my-3 ">
                  <DropdownRange
                    startYear={startYear}
                    setStartYear={setStartYear}
                    endYear={endYear}
                    setEndYear={setEndYear}
                    startMonth={startMonth}
                    setStartMonth={setStartMonth}
                    endMonth={endMonth}
                    setEndMonth={setEndMonth}
                  />
                  <ButtonExcel
                    onClick={() => {
                      ExportCountDepartment(
                        submitExcelData
                        // startYear,
                        // endYear,
                        // startMonth,
                        // endMonth,
                        // setLoading
                      );
                    }}
                    loading={loading}
                  />
                </div>
              </div>

              <div class="relative overflow-x-auto mt-3">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        {/* ระยะเวลา ตั้งแต่เดือน "{startMonth.label}"ปี "
                      {startYear.label}" ถึงเดือน "{endMonth.label}" ปี "
                      {endYear.label} */}
                      </th>

                      {isAll ? (
                        <th
                          scope="col"
                          class={`px-6 py-3 ${isAll ? null : "text-center"}`}
                        >
                          Anesthesia
                        </th>
                      ) : null}
                      {isAll ? (
                        <th scope="col" class="px-6 py-3">
                          Interventional Radiology
                        </th>
                      ) : null}
                      {isAll ? (
                        <th scope="col" class="px-6 py-3">
                          Trauma
                        </th>
                      ) : null}
                      {isAll ? (
                        <th scope="col" class="px-6 py-3">
                          Pediatrics
                        </th>
                      ) : null}
                      {isAll ? (
                        <th scope="col" class="px-6 py-3">
                          Total
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 ">
                      {isAll ? (
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          Adult
                        </th>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">{data?.an?.adult}</td>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">{data?.ir?.adult}</td>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">{data?.tr?.adult}</td>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">
                          {data?.ped?.adult}
                        </td>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">
                          {data?.total?.adult}
                        </td>
                      ) : null}
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      {isAll ? (
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          Child
                        </th>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">{data?.an?.kid}</td>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">{data?.ir?.kid}</td>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">{data?.tr?.kid}</td>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">{data?.ped?.kid}</td>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">
                          {data?.total?.kid}
                        </td>
                      ) : null}
                    </tr>

                    <tr class="bg-white dark:bg-gray-800">
                      {isAll ? (
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          Total
                        </th>
                      ) : null}

                      {isAll ? (
                        <td class="px-6 py-4 text-center">{data?.total?.an}</td>
                      ) : null}

                      {isAll ? (
                        <td class="px-6 py-4 text-center">{data?.total?.ir}</td>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">{data?.total?.tr}</td>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">
                          {data?.total?.ped}
                        </td>
                      ) : null}
                      {isAll ? (
                        <td class="px-6 py-4 text-center">
                          {data?.total?.total}
                        </td>
                      ) : null}
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          </div>
        </div>
      ) : null}
    </>
  );
};

const ReportCountLabelCatheterType = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [data, setData] = React.useState({
    picc1: 0,
    picc2: 0,
    picc3: 0,
    picc4: 0,
    picc5: 0,
    central1: 0,
    central2: 0,
    central3: 0,
    central4: 0,
    // checkPICC: [],
    checkCentral: [],
  });
  const listPicc = [
    {
      name: "Arrow 5 Fr 2-lumen PICC",
      count: data?.picc1,
    },
    {
      name: "Arrow 4 Fr 1-lumen PICC",
      count: data?.picc2,
    },
    {
      name: "Bard 5 Fr 2-Lumen power PICC",
      count: data?.picc3,
    },
    {
      name: "Vygon 3 Fr 1-lumen PICC",
      count: data?.picc4,
    },
    {
      name: "Other",
      count: data?.picc5,
    },
  ];
  const listCentral = [
    {
      name: "Arrow 7 Fr 2-lumen CVL, antimicrobials coated",
      count: data?.central1,
    },
    {
      name: "Arrow 7 Fr 3-lumen CVL, antimicrobials coated",
      count: data?.central2,
    },
    {
      name: "12 Fr DLC",
      count: data?.central3,
    },
    {
      name: "Other",
      count: data?.central4,
    },
  ];

  React.useEffect(() => {
    CountLabelCatheterType({
      list,
      setValue: setData,
      year: String(year?.value),
    });
  }, [year]);

  // console.log("ReportCountLabelCatheterType", data);

  return (
    <div>
      <div className="flex">
        <div className="my-20">
          <div className={SCREENIPAD}>
            <div className={STYLEHEAD}>
              นับชนิดของสายน้ำเกลือแยกตามชื่อสายที่ใส่ (PICC line)
              <div className={STYLEBODY}>ของปี " {year?.label}"</div>
            </div>
            <div className={FLEXDROPDOWN}>
              <DropdownFollows year={year} setYear={setYear} />
              <ButtonExcel
                text={"DOWNLOAD EXCEL"}
                onClick={() => {
                  ExceltCatLabelName(
                    formatDataLabelCatheterType(data),
                    year,
                    setLoading
                  );
                }}
                loading={loading}
              />
            </div>
          </div>
          <div className={BARCHART}>
            <BarChart
              width={700}
              height={300}
              data={listPicc}
              margin={{
                top: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              {/* <XAxis dataKey="name" /> */}
              <YAxis />
              <Tooltip />

              <Bar
                dataKey="count"
                fill="#a5f3fc"
                minPointSize={5}
                label={{ fill: "#111827" }}
              />
            </BarChart>
          </div>
          <div className="flex ml-16 ">
            <div className="mr-4 text-center">
              Arrow 5<div>Fr 2-lumen PICC</div>
            </div>
            <div className="mr-7 text-center">
              Arrow 4<div>Fr 1-lumen PICC</div>
            </div>
            <div className="mr-7 text-center">
              Bard 5<div>Fr 2-Lumen</div>
              <div>power PICC</div>
            </div>
            <div className="mr-12 text-center">
              Vygon 3<div>Fr 1-lumen PICC</div>
            </div>
            <div className="mr-6">Other</div>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="my-20">
          <div className={SCREENIPAD}>
            <div>
              <div className={STYLEHEAD}>
                นับชนิดของสายน้ำเกลือแยกตามชื่อสายที่ใส่ (Central Line)
                <div className={STYLEBODY}>ของปี " {year?.label}"</div>
              </div>
            </div>
          </div>
          <div className={BARCHART}>
            <BarChart
              width={700}
              height={300}
              data={listCentral}
              margin={{
                top: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              {/* <XAxis dataKey="name" /> */}
              <YAxis />
              <Tooltip />

              <Bar
                dataKey="count"
                fill="#38bdf8"
                minPointSize={5}
                label={{ fill: "#111827" }}
              />
            </BarChart>
          </div>
          <div className="flex ml-20 ">
            <div className="mr-14 text-center">
              Arrow 7<div>Fr 2-lumen CVL,</div>
              <div>antimicrobials</div>
              <div>coated</div>
            </div>
            <div className="mr-14 text-center">
              Arrow 7<div>Fr 3-lumen CVL,</div>
              <div>antimicrobials</div>
              <div>coated</div>
            </div>
            <div className="mr-28 text-center">12 Fr DLC</div>
            <div className="">Other</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReportIndications = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  const [data, setData] = React.useState({
    tpn: 0,
    prolonged_iv_meds: 0,
    chemotherapy: 0,
    vasopressor: 0,
    frequent_blood_sampling: 0,
    stem_cell_collection_plasma_exchange: 0,
    stem_cell_transplantation: 0,
    other_indication: 0,
  });

  React.useEffect(() => {
    ReportCountIndications({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
    });
  }, [year, type]);

  const listTable = [
    { name: "TPN", count: data?.tpn },
    { name: "Prolonged IV meds", count: data?.prolonged_iv_meds },
    { name: "Chemotherapy", count: data?.chemotherapy },
    { name: "Vasopressor", count: data?.vasopressor },
    { name: "เจาะเลือดบ่อย", count: data?.frequent_blood_sampling },
    {
      name: "Stem cell/ Plasma Exc.",
      count: data?.stem_cell_collection_plasma_exchange,
    },
    { name: "Stem cell Tx", count: data?.stem_cell_transplantation },
    { name: "Other", count: data?.other_indication },
  ];

  const total =
    data?.tpn +
    data?.prolonged_iv_meds +
    data?.chemotherapy +
    data?.vasopressor +
    data?.frequent_blood_sampling +
    data?.stem_cell_collection_plasma_exchange +
    data?.stem_cell_transplantation +
    data?.other_indication;

  const tpnPercentage = ((data?.tpn / total) * 100).toFixed(1) + "%";
  const prolonged_iv_medsPercentage =
    ((data?.prolonged_iv_meds / total) * 100).toFixed(1) + "%";
  const chemotherapyPercentage =
    ((data?.chemotherapy / total) * 100).toFixed(1) + "%";
  const vasopressorPercentage =
    ((data?.vasopressor / total) * 100).toFixed(1) + "%";
  const frequent_blood_samplingPercentage =
    ((data?.frequent_blood_sampling / total) * 100).toFixed(1) + "%";
  const stem_cell_collection_plasma_exchangePercentage =
    ((data?.stem_cell_collection_plasma_exchange / total) * 100).toFixed(1) +
    "%";
  const stem_cell_transplantationPercentage =
    ((data?.stem_cell_transplantation / total) * 100).toFixed(1) + "%";
  const other_indicationPercentage =
    ((data?.other_indication / total) * 100).toFixed(1) + "%";

  return (
    <div>
      _____________________________________________________________________________________
      <div className={``}>
        <div className="my-20">
          <>
            <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>
                รายงานข้อบ่งชี้การใส่สาย
                <div className={STYLEBODY}>
                  ของปี "{year.label}" ประเภทสาย "{type.label}"
                </div>
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownOne
                  year={year}
                  setYear={setYear}
                  type={type}
                  setType={setType}
                />
                <ButtonExcel
                  onClick={() => {
                    ExceltIndications(
                      formatDataIndications(data),
                      year,
                      type,
                      setLoading
                    );
                  }}
                  loading={loading}
                />
              </div>
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listTable}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                {/* <XAxis dataKey="name" /> */}
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}
                <Bar label={true} dataKey="count" fill="#FFCC66" />
              </BarChart>
            </div>
            <div className="grid grid-cols-8 mx-16 my-6 text-sm">
              <div className=" -rotate-45 transform-gpu text-center">
                TPN <br /> {tpnPercentage}
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Prolonged IV meds <br /> {prolonged_iv_medsPercentage}
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Chemotherapy <br /> {chemotherapyPercentage}
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Vasopressor <br /> {vasopressorPercentage}
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                เจาะเลือดบ่อย <br /> {frequent_blood_samplingPercentage}
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Stem cell/ Plasma Exc. <br />{" "}
                {stem_cell_collection_plasma_exchangePercentage}
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Stem cell Tx <br /> {stem_cell_transplantationPercentage}
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Other <br /> {other_indicationPercentage}{" "}
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

const ReportTimePaperToCatheter = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [month, setMonth] = React.useState({
    label: "ทุกเดือน",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  const [data, setData] = React.useState({
    A: 0,
    B: 0,
    C: 0,
    D: 0,
  });

  React.useEffect(() => {
    CountTimePaperToCatheter({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
      month: String(month?.value),
    });
  }, [year, type, month]);

  // console.log("data", data);
  // console.log("year", year);
  // console.log("month", month);
  const total = data?.A + data?.B + data?.C;

  const data1 = {
    labels: ["น้อยกว่า 2 วัน", "3-5 วัน", "6 วันขึ้นไป"],
    datasets: [
      {
        label: "# of Votes",
        data: [data?.A, data?.B, data?.C],
        backgroundColor: [
          "rgb(85,107,47)",
          "rgb(107,142,35)",
          "rgb(128,128,0)",
        ],

        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };
  const options = {
    title: {
      // display: true,
      // text: "ChartName",
      fontSize: 20,
    },
    plugins: {
      legend: {
        position: "right",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataIndex = context.dataIndex;
            const datasets = context.dataset;
            const valueSet = datasets.data[dataIndex];
            return `${valueSet} (${Number((valueSet / total) * 100).toFixed(
              1
            )})%`;
          },
        },
      },
    },
  };

  const listTable = [
    { name: "น้อยกว่า 2 วัน", count: data?.A },
    { name: "3-5 วัน", count: data?.B },
    { name: "6 วันขึ้นไป", count: data?.C },
  ];

  const renderCustomLabel = (props) => {
    const { x, y, width, height, value } = props;
    const percentage = ((value / total) * 100).toFixed(1) + "%";
    const insideBarPosition = y + height / 2;

    return (
      <g>
        <text
          x={x + width / 2}
          y={insideBarPosition}
          fill="#111827"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {`${value} (${percentage})`}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value} `}</p>
          {/* <p> percentage :  {percentage (payload)}</p> */}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className={``}>
        <div className="my-20">
          <>
            <div className="flex flex-col gap-4">
              <div className="text-black text-lg font-medium">
                ระยะเวลาตั้งแต่ที่ได้รับใบปรึกษา
                จนกระทั่งผู้ป่วยได้รับการใส่สายน้ำเกลือ
                <div className={STYLEBODY}>
                  ของปี "{year.label}" ตามประเภทสาย "{type.label}"
                </div>
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownTwo
                  year={year}
                  setYear={setYear}
                  type={type}
                  setType={setType}
                  month={month}
                  setMonth={setMonth}
                />

                <ButtonExcel
                  text={"DOWNLOAD EXCEL"}
                  onClick={() => {
                    ExcelWaitCat(
                      formatTimeWaitCat(data),
                      year,
                      type,
                      setLoading,
                      month
                    );
                  }}
                  loading={loading}
                />
              </div>
            </div>
            {/* <div className=" max-w-md lg:max-w-lg mx-auto w-full p-4  md:p-1 lg:p-1 xl:p-0">
              <Doughnut data={data1} options={options} />
            </div> */}
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listTable}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={CustomTooltip} />
                {/* <Legend /> */}

                <Bar
                  dataKey="count"
                  fill="#99CC00"
                  // minPointSize={5}
                  label={renderCustomLabel}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const ReportHeight = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });
  const [height, setHight] = React.useState({
    label: "-",
    value: "level0",
  });

  const [data, setData] = React.useState({
    lt_deep1: 0,
    lt_deep2: 0,
    lt_deep3: 0,
    lt_deep4: 0,
    lt_deep5: 0,
    lt_deep6: 0,
    lt_deep7: 0,
    lt_deep8: 0,
    lt_deep9: 0,
    lt_deep10: 0,
    lt_deep11: 0,
    lt_deep12: 0,
    //
    rt_deep1: 0,
    rt_deep2: 0,
    rt_deep3: 0,
    rt_deep4: 0,
    rt_deep5: 0,
    rt_deep6: 0,
    rt_deep7: 0,
    rt_deep8: 0,
    rt_deep9: 0,
    rt_deep10: 0,
    rt_deep11: 0,
    rt_deep12: 0,
  });

  React.useEffect(() => {
    ReportCountHeight({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
      height: String(height?.value),
    });
  }, [year, type, height]);
  const optionHight = [
    { label: "-", value: "level0" },
    { label: "น้อยกว่า 100", value: "level1" },
    { label: "100 - 120", value: "level2" },
    { label: "121 - 140", value: "level3" },
    { label: "141 - 160", value: "level4" },
    { label: "161 - 180", value: "level5" },
    { label: "181 - 200", value: "level6" },
    { label: "มากกว่า 200", value: "level7" },
  ];

  const listTable = [
    { name: "0-5 ", left: data?.lt_deep1, right: data?.rt_deep1 },
    { name: "6-10", left: data?.lt_deep2, right: data?.rt_deep2 },
    { name: "11-15", left: data?.lt_deep3, right: data?.rt_deep3 },
    { name: "16-20", left: data?.lt_deep4, right: data?.rt_deep4 },
    { name: "21-25", left: data?.lt_deep5, right: data?.rt_deep5 },
    { name: "26-30", left: data?.lt_deep6, right: data?.rt_deep6 },
    { name: "31-35", left: data?.lt_deep7, right: data?.rt_deep7 },
    { name: "36-40", left: data?.lt_deep8, right: data?.rt_deep8 },
    { name: "41-45", left: data?.lt_deep9, right: data?.rt_deep9 },
    { name: "46-50", left: data?.lt_deep10, right: data?.rt_deep10 },
    { name: "51-55", left: data?.lt_deep11, right: data?.rt_deep11 },
    { name: "56-60", left: data?.lt_deep12, right: data?.rt_deep12 },
  ];

  return (
    <div>
      _____________________________________________________________________________________
      <div className={``}>
        <div className="my-20">
          <>
            <div className="flex flex-col gap-4">
              <div className="text-black text-lg font-medium">
                รายงานความสูง ความลึก(ซ.ม.) และข้างที่ใส่สาย (ซ้าย,ขวา)
                <div className={STYLEBODY}>
                  เลือกตามความสูง
                  {height.label} ซ.ม. ของปี "{year.label}" ประเภทสาย "
                  {type.label}"
                </div>
              </div>
              <div className="flex justify-center items-end gap-2 my-10">
                <DropdownOne
                  year={year}
                  setYear={setYear}
                  type={type}
                  setType={setType}
                />
                <DropdownReport
                  title={"ส่วนสูง (ซ.ม.)"}
                  className="w-40"
                  options={optionHight}
                  value={height}
                  setValue={setHight}
                />
                <ButtonExcel
                  onClick={() => {
                    ExcelHeight(
                      formatDataHeight(data),
                      year,
                      type,
                      setLoading,
                      height
                    );
                  }}
                  loading={loading}
                />
              </div>
            </div>

            <div className={BARCHART}>
              <BarChart
                width={750}
                height={300}
                data={listTable}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                {/* <XAxis dataKey="name" />  */}
                <YAxis />

                <Tooltip />
                {/* <Legend /> */}

                <Bar
                  label={{ fill: "#111827" }}
                  dataKey="left"
                  fill="#fecdd3"
                />
                <Bar
                  label={{ fill: "#111827" }}
                  dataKey="right"
                  fill="#f9a8d4"
                />
              </BarChart>
            </div>
            <div className="grid grid-cols-12  ml-12 mr-4 my-4 text-sm">
              <div className=" -rotate-45 transform-gpu text-center">0-5</div>
              <div className=" -rotate-45 transform-gpu text-center">6-10</div>
              <div className=" -rotate-45 transform-gpu text-center">11-15</div>
              <div className=" -rotate-45 transform-gpu text-center">16-20</div>
              <div className=" -rotate-45 transform-gpu text-center">21-25</div>
              <div className=" -rotate-45 transform-gpu text-center">26-30</div>
              <div className=" -rotate-45 transform-gpu text-center">31-35</div>
              <div className=" -rotate-45 transform-gpu text-center">36-40</div>
              <div className=" -rotate-45 transform-gpu text-center">41-45</div>
              <div className=" -rotate-45 transform-gpu text-center">46-50</div>
              <div className=" -rotate-45 transform-gpu text-center">51-55</div>
              <div className=" -rotate-45 transform-gpu text-center">56-60</div>
            </div>
            <div className="flex justify-center">
              <div className="flex items-center">
                <div className="box-content h-3 w-4 bg-pinky"></div>
                <div className="text-pinky mx-2">left</div>
              </div>
              <div className="flex items-center">
                <div className="box-content h-3 w-4 bg-pinkrose"></div>
                <div className="text-pinkrose mx-2">right</div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

// // tab03 กระบวนการใส่สาย
const ReportTimeAttempt = ({ list }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    ad_mean: 0,
    ad_median: 0,
    ad_min: 0,
    ad_max: 0,
    ad_sd: 0,
    ad_per25: 0,
    ad_per75: 0,
    ad_number: 0,
    //
    kid1_mean: 0,
    kid1_median: 0,
    kid1_min: 0,
    kid1_max: 0,
    kid1_sd: 0,
    kid1_per25: 0,
    kid1_per75: 0,
    kid1_number: 0,
    //
    kid2_mean: 0,
    kid2_median: 0,
    kid2_min: 0,
    kid2_max: 0,
    kid2_sd: 0,
    kid2_per25: 0,
    kid2_per75: 0,
    kid2_number: 0,
    //
    all_mean: 0,
    all_median: 0,
    all_min: 0,
    all_max: 0,
    all_sd: 0,
    all_per25: 0,
    all_per75: 0,
    all_number: 0,
    checkTask: [],
  });

  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [month, setMonth] = React.useState({
    label: "ทุกเดือน",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });
  React.useEffect(() => {
    CountTimeAttempt({
      list,
      year: String(year?.value),
      month: String(month?.value),
      type: String(type?.value),
      data: data,
      setValue: setData,
    });
  }, [month, year, type]);

  const tabel = [
    {
      name: "Mean",
      adult: data?.ad_mean,
      kid1: data?.kid1_mean,
      kid2: data?.kid2_mean,
      all: data?.all_mean,
    },
    {
      name: "Median",
      adult: data?.ad_median,
      kid1: data?.kid1_median,
      kid2: data?.kid2_median,
      all: data?.all_median,
    },
    {
      name: "Min",
      adult: data?.ad_min,
      kid1: data?.kid1_min,
      kid2: data?.kid2_min,
      all: data?.all_min,
    },
    {
      name: "Max",
      adult: data?.ad_max,
      kid1: data?.kid1_max,
      kid2: data?.kid2_max,
      all: data?.all_max,
    },
    {
      name: "SD",
      adult: data?.ad_sd,
      kid1: data?.kid1_sd,
      kid2: data?.kid2_sd,
      all: data?.all_sd,
    },
    {
      name: "Percentile 25th",
      adult: data?.ad_per25,
      kid1: data?.kid1_per25,
      kid2: data?.kid2_per25,
      all: data?.all_per25,
    },
    {
      name: "Percentile 75th",
      adult: data?.ad_per75,
      kid1: data?.kid1_per75,
      kid2: data?.kid2_per75,
      all: data?.all_per75,
    },
    {
      name: "n",
      adult: data?.ad_number,
      kid1: data?.kid1_number,
      kid2: data?.kid2_number,
      all: data?.all_number,
    },
  ];

  const onClickExportPerformingToPDF = async () => {
    try {
      const doc = new jsPDF("p", "px", "a4");

      const docElement = document.getElementById(
        "รายงานระยะเวลาในการทำหัตถการ"
      );
      const canvas = await html2canvas(docElement, { scale: 3 });
      const image = canvas.toDataURL("image/png");

      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio - 50;
      const canvasHeight = canvas.height * ratio - 50;

      // Define your padding values here
      // const paddingX = 20; // Horizontal padding
      const paddingY = 0; // Vertical padding

      const marginX = (pageWidth - canvasWidth) / 2;
      // const marginY = (pageHeight - canvasHeight) / 2 + paddingY;
      const marginY = 20;
      doc.addImage(image, "PNG", marginX, marginY, canvasWidth, canvasHeight);
      await doc.save(`รายงานระยะเวลาในการทำหัตถการ.pdf`);
    } catch (error) {
      console.log("error", error);
    }
  };

  // console.log("Hand List", list);
  // console.log("month", month);

  // console.log("ReportTimeAttempt", data);

  return (
    <div className={``}>
      <div className="my-10">
        <div className="flex flex-col gap-4">
          <div className="text-black text-lg font-medium">
            รายงานระยะเวลาในการทำหัตถการ
            <div className={STYLEBODY}>
              ของปี {year?.label}
              แยกตามประเภทของสาย {type?.label}
            </div>
          </div>
          <div className=" flex items-end gap-2 my-3 ">
            <DropdownTwo
              year={year}
              setYear={setYear}
              type={type}
              setType={setType}
              month={month}
              setMonth={setMonth}
            />

            <ButtonExcel
              onClick={() => {
                onClickExportPerformingToPDF();
                // ExcelTimeAttempt(
                //   formatTimeAttmept(data),
                //   year,
                //   type,
                //   setLoading
                // );
              }}
              loading={loading}
            />
          </div>
        </div>
        <table id="รายงานระยะเวลาในการทำหัตถการ" className={`${TABLE}`}>
          <thead>
            <tr>
              <th className={LEFTTABLE}>Procedure Time(min)</th>
              <th className={THTABLE}> Children (&lt;10 y) </th>
              <th className={THTABLE}> Children (&lt;18 y) </th>
              <th className={THTABLE}> Adults </th>
              <th className={RIGHTTABLE}> Total </th>
            </tr>
          </thead>
          <tbody>
            {tabel?.map((item, index) => (
              <tr key={index} className={TRTABLE}>
                <td className={TXLEFT}> {item?.name}</td>
                <td className={TXCENTER}>{displayValue(item?.kid1)}</td>
                <td className={TXCENTER}>{displayValue(item?.kid2)}</td>
                <td className={TXCENTER}>{displayValue(item?.adult)}</td>
                <td className={TXCENTER}>{displayValue(item?.all)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        *หมายเหตุ n คือจำนวนผู้ป่วยที่ได้รับการใส่สายที่ได้รับการกรอกข้อมูล
        วันเกิด
        ,วันที่ใส่สายสำเร็จ,ข้อมูลเวลาในการเริ่มใส่สายและเวลาในการสำเร็จการใส่สาย
      </div>
    </div>
  );
};

const ReportCountSucAttempt = ({ list }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    ad_01: 0,
    ad_02: 0,
    ad_03: 0,
    ad_04: 0,

    //
    kid_01: 0,
    kid_02: 0,
    kid_03: 0,
    kid_04: 0,
  });
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  React.useEffect(() => {
    CountSucAttempt({
      list,
      year: String(year?.value),
      type: String(type?.value),
      data: data,
      setValue: setData,
    });
  }, [year, type]);

  const tabel = [
    { name: "1 attempt", adult: data?.ad_01, kid: data?.kid_01 },
    { name: "2 attempts", adult: data?.ad_02, kid: data?.kid_02 },
    { name: "3 attempts", adult: data?.ad_03, kid: data?.kid_03 },
    { name: "4 attempts", adult: data?.ad_04, kid: data?.kid_04 },
  ];

  return (
    <div className="my-20">
      <div className={SCREENIPAD}>
        <div className={STYLEHEAD}>
          รายงานจำนวนครั้งในการทำหัตถการ
          <div className={STYLEBODY}>
            ของปี {year?.label} แยกตามประเภทของสาย {type?.label}
          </div>
        </div>
        <div className={FLEXDROPDOWN}>
          <DropdownOne
            year={year}
            setYear={setYear}
            type={type}
            setType={setType}
          />
          <ButtonExcel
            text={"DOWNLOAD EXCEL"}
            onClick={() => {
              ExcelSucAttempt(formatSucAttempt(data), year, type, setLoading);
            }}
            loading={loading}
          />
        </div>
      </div>

      <table className={TABLE}>
        <thead>
          <tr>
            <th className={LEFTTABLE}>Procedural Attempts</th>
            <th className={THTABLE}> Children </th>
            <th className={RIGHTTABLE}> Adults </th>
          </tr>
        </thead>
        <tbody>
          {tabel?.map((item, index) => (
            <tr key={index} className={TRTABLE}>
              <td className={TXLEFT}> {item?.name}</td>
              <td className={TXCENTER}>{item?.kid}</td>
              <td className={TXCENTER}>{item?.adult}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ReportAttemptByDoctor = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  // console.log("List", list);

  const [data, setData] = React.useState([]);

  // console.log("data", data);
  // console.log("tyof", typeof data);

  React.useEffect(() => {
    ReportCountAttemptByDoc({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
    });
  }, [year, type]);

  const submitExcelData = data?.map((item) => {
    return {
      doctor: item?.doctor,
      countAttemps: item?.countSuccess + item?.countUnsuccess,
      countSuccess: item?.countSuccess,
      countSuccess_percent: (
        (item?.countSuccess * 100) /
        (item?.countSuccess + item?.countUnsuccess)
      ).toFixed(1),
      countUnsuccess: item?.countUnsuccess,
      countUnsuccess_percent: (
        (item?.countUnsuccess * 100) /
        (item?.countSuccess + item?.countUnsuccess)
      ).toFixed(1),
    };
  });

  return (
    <div className="my-20">
      <div className={SCREENIPAD}>
        <div className={STYLEHEAD}>
          attempt ทั้งหมด แยกตามแพทย์ผู้ใส่
          <div className={STYLEBODY}>
            ของปี {year?.label} ตามประเภทสาย {type?.label}
          </div>
        </div>
        <div className={FLEXDROPDOWN}>
          <DropdownOne
            year={year}
            setYear={setYear}
            type={type}
            setType={setType}
          />
          <ButtonExcel
            text={"DOWNLOAD EXCEL"}
            onClick={() => {
              ExcelAttemptByDoc(
                submitExcelData,
                year,
                type
                // formatDataAttemptByDoc(data),
                // year,
                // type,
                // setLoading
              );
            }}
            loading={loading}
          />
        </div>
      </div>

      <table className={TABLE}>
        <thead>
          <tr>
            <th className={LEFTTABLE}>ลำดับที่</th>
            <th className={THTABLE}> ชื่อ </th>
            <th className={THTABLE}> attempt ทั้งหมด</th>
            <th className={THTABLE}> attempt ที่สำเร็จ</th>
            <th className={THTABLE}> % ที่สำเร็จ</th>
            <th className={THTABLE}> attempt ที่ Fail </th>
            <th className={RIGHTTABLE}> % ที่ Fail </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index} className={TRTABLE}>
              <th className={TXCENTER}> {index + 1}</th>
              <td className={TXCENTER}> {item?.doctor}</td>
              <td className={TXCENTER}>
                {" "}
                {item?.countSuccess + item?.countUnsuccess}
              </td>
              <td className={TXCENTER}> {item?.countSuccess}</td>
              <td className={TXCENTER}>
                {" "}
                {(
                  (item?.countSuccess * 100) /
                  (item?.countSuccess + item?.countUnsuccess)
                ).toFixed(1) + "%"}
              </td>
              <td className={TXCENTER}> {item?.countUnsuccess}</td>
              <td className={TXCENTER}>
                {" "}
                {(
                  (item?.countUnsuccess * 100) /
                  (item?.countSuccess + item?.countUnsuccess)
                ).toFixed(1) + "%"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ReportFirstAttempt = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    ReportCountFirstAttempt({
      list,
      year: String(year?.value),
      type: String(type?.value),
      setValue: setData,
    });
  }, [year, type]);

  const submitExcelData = data?.map((item) => {
    return {
      doctor: item?.doctor,
      countSuccess: item?.countSuccess,
      countUnsuccess: item?.countUnsuccess,
      countAll: item?.countSuccess + item?.countUnsuccess,
      percentSuccess:
        (
          (item?.countSuccess * 100) /
          (item?.countSuccess + item?.countUnsuccess)
        ).toFixed(1) + "%",
      percentUnsuccess:
        (
          (item?.countUnsuccess * 100) /
          (item?.countSuccess + item?.countUnsuccess)
        ).toFixed(1) + "%",
    };
  });

  return (
    <div className="my-20">
      <div className={SCREENIPAD}>
        <div className={STYLEHEAD}>
          {" "}
          เก็บเฉพาะ 1 st attempt
          <div className={STYLEBODY}>
            ของปี {year?.label} ตามประเภทสาย {type?.label}{" "}
          </div>
        </div>
        <div className={FLEXDROPDOWN}>
          <DropdownOne
            year={year}
            setYear={setYear}
            type={type}
            setType={setType}
          />
          <ButtonExcel
            onClick={() => {
              ExcelFirstAttempt(submitExcelData, year, type, setLoading);
            }}
            loading={loading}
          />
        </div>
      </div>

      <table className={TABLE}>
        <thead>
          <tr>
            <th className={LEFTTABLE}>ลำดับที่</th>
            <th className={THTABLE}> ชื่อ </th>
            <th className={THTABLE}> 1 st attempt ทั้งหมด</th>
            <th className={THTABLE}> 1 st attempt ที่สำเร็จ</th>
            <th className={THTABLE}>% ที่สำเร็จ</th>
            <th className={THTABLE}> 1 st attempt ที่ Fail </th>
            <th className={RIGHTTABLE}>% ที่ Fail </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index} className={TRTABLE}>
              <th className={TXCENTER}> {index + 1}</th>
              <td className={TXCENTER}> {item?.doctor}</td>
              <td className={TXCENTER}>
                {" "}
                {item?.countSuccess + item?.countUnsuccess}
              </td>
              <td className={TXCENTER}> {item?.countSuccess}</td>
              <td className={TXCENTER}>
                {" "}
                {(
                  (item?.countSuccess * 100) /
                  (item?.countSuccess + item?.countUnsuccess)
                ).toFixed(1) + "%"}
              </td>
              <td className={TXCENTER}> {item?.countUnsuccess}</td>
              <td className={TXCENTER}>
                {" "}
                {(
                  (item?.countUnsuccess * 100) /
                  (item?.countSuccess + item?.countUnsuccess)
                ).toFixed(1) + "%"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ReportAverageTime = ({ list }) => {
  const [statData, setStatData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    ReportCountAvgTimeAt({
      list,
      year: String(year?.value),
      type: String(type?.value),
      data: data,
      setValue: setData,
      statData: statData,
      setStatData: setStatData,
    });
  }, [year, type]);

  const calculateAverageForDoctors = (data) => {
    return data?.map((doctor) => {
      // Calculate the average checkTime for each doctor using the calculateAverage function
      const averageCheckTime = calculateAverage(
        doctor?.checkTime?.map((time) => parseFloat(time))
      ); // Ensure time values are numbers
      return {
        ...doctor, // Spread the original doctor object to retain other properties
        averageCheckTime, // Add the calculated average checkTime
      };
    });
  };

  const updatedDataWithAverage = calculateAverageForDoctors(data);

  const averageCheckTimeArray = updatedDataWithAverage?.map(
    (doctor) => doctor.averageCheckTime
  );
  const useSumAll = averageCheckTimeArray.filter((value) => value !== 0);
  const submitExcelData = updatedDataWithAverage?.map((item) => {
    return {
      doctor: item?.doctor,
      averageCheckTime: item?.averageCheckTime,
      // maxAverageCheckTime: Math.max(...useSumAll),
      // minAverageCheckTime: Math.min(...useSumAll),
      // averageOfAll: calculateAverage(useSumAll),
    };
  });

  const maxAverageCheckTime = Math.max(...useSumAll);
  const minAverageCheckTime = Math.min(...useSumAll);
  const averageOfAll = calculateAverage(useSumAll);

  return (
    <div className="my-20">
      <div className={SCREENIPAD}>
        <div className={STYLEHEAD}>
          เวลาเฉลี่ยของทุก attempt แยกตามแพทย์ผู้ใส่
          <div className={STYLEBODY}>
            ของปี{year?.label}ตามประเภทสาย {type?.label}
          </div>
        </div>
        <div className={FLEXDROPDOWN}>
          <DropdownOne
            year={year}
            setYear={setYear}
            type={type}
            setType={setType}
          />
          <ButtonExcel
            onClick={() => {
              ExcelAvgAttempt(submitExcelData, year, type, setLoading);
            }}
            loading={loading}
          />
        </div>
      </div>

      <div className="flex mt-2">
        <div className="rounded-xl border border-b p-2 m-2 ml-0">
          ค่าเฉลี่ย = {averageOfAll}
        </div>
        <div className="rounded-xl border border-b p-2 m-2">
          {" "}
          min = {minAverageCheckTime}
        </div>
        <div className="rounded-xl border border-b p-2 m-2">
          {" "}
          max = {maxAverageCheckTime}
        </div>
      </div>

      <table className={TABLE}>
        <tr>
          <th className={LEFTTABLE}>ลำดับที่</th>
          <th className={THTABLE}> ชื่อ </th>
          <th className={RIGHTTABLE}> ค่าเฉลี่ยของทุก attempt (นาที) </th>
        </tr>
        <tbody>
          {updatedDataWithAverage?.map((item, index) => (
            <tr key={index} className={TRTABLE}>
              <th className={TXCENTER}> {index + 1}</th>
              <td className={TXCENTER}> {item?.doctor}</td>
              <td className={TXCENTER}>{item?.averageCheckTime}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ReportSideCat = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [month, setMonth] = React.useState({
    label: "ทุกเดือน",
    value: "ALL",
  });
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const count = payload?.[0]?.value || 0;

      const percentage = ((count * 100) / data.sum_LT).toFixed(1);

      const count2 = payload?.[1]?.value || 0;
      const percentage2 = ((count2 * 100) / data.sum_RT).toFixed(1);

      return (
        <div className="bg-gray-50 text-gray-700 text-sm px-2 py-1 rounded">
          <p className="label">{`Left : ${count} (${percentage}%)`}</p>
          <p className="label">{`Right : ${count2} (${percentage2}%)`}</p>
        </div>
      );
    }

    return null;
  };

  const [data, setData] = React.useState({
    cat1_LT: 0,
    cat2_LT: 0,
    cat3_LT: 0,
    cat4_LT: 0,
    cat5_LT: 0,
    cat6_LT: 0,
    cat7_LT: 0,
    cat8_LT: 0,

    cat1_RT: 0,
    cat2_RT: 0,
    cat3_RT: 0,
    cat4_RT: 0,
    cat5_RT: 0,
    cat6_RT: 0,
    cat7_RT: 0,
    cat8_RT: 0,
    sum_LT: 0,
    sum_RT: 0,
    sum_LT1RT1: 0,
    sum_LT2RT2: 0,
    sum_LT3RT3: 0,
    sum_LT4RT4: 0,
    sum_LT5RT5: 0,
    sum_LT6RT6: 0,
    sum_LT7RT7: 0,
    sum_LT8RT8: 0,
    sum_All: 0,
    piccCheck: [],
    centralChecl: [],
    missingAll: 0,
    piccMissing: 0,
    centralMissing: 0,
  });

  React.useEffect(() => {
    ReportCountSideCat({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
      month: String(month?.value),
    });
  }, [year, type, month]);

  const listTable = [
    { name: "Basilic vein", left: data?.cat1_LT, right: data?.cat1_RT },
    { name: "Brachial vein", left: data?.cat2_LT, right: data?.cat2_RT },

    { name: "Cephalic vein", left: data?.cat3_LT, right: data?.cat3_RT },
    {
      name: "Internal jugular vein",
      left: data?.cat4_LT,
      right: data?.cat4_RT,
    },
    { name: "Subclavian vein", left: data?.cat5_LT, right: data?.cat5_RT },
    { name: "Femoral vein", left: data?.cat6_LT, right: data?.cat6_RT },
    { name: "Distal femoral vein", left: data?.cat7_LT, right: data?.cat7_RT },
    { name: "Other", left: data?.cat8_LT, right: data?.cat8_RT },
  ];

  // const renderCustomLabel = (props) => {
  //   const { x, y, width, height, value } = props;
  //   const percentage = ((value / data?.sum_LT) * 100).toFixed(1) + "%";
  //   const insideBarPosition = y + height / 2;

  //   return (
  //     <g>
  //       <text
  //         x={x + width / 2}
  //         y={y}
  //         fill="#111827"
  //         textAnchor="middle"
  //         dominantBaseline="middle"
  //       >
  //         {/* {`${value}
  //         (${percentage})
  //         `} */}
  //         {value}
  //       </text>
  //       <text
  //         x={x + width / 2}
  //         y={insideBarPosition + 15}
  //         fill="#111827"
  //         textAnchor="middle"
  //         dominantBaseline="middle"
  //       >
  //         {/* {`${value}  */}
  //         {percentage}
  //       </text>
  //     </g>
  //   );
  // };

  const submitExcelData = {
    "Basilic vein": {
      Lt: data?.cat1_LT,
      Lt_percent: ((data?.cat1_LT * 100) / data?.sum_LT).toFixed(1) + "%",
      Rt: data?.cat1_RT,
      Rt_percent: ((data?.cat1_RT * 100) / data?.sum_RT).toFixed(1) + "%",
      Total: data?.cat1_LT + data?.cat1_RT,
      Total_percent:
        ((data?.sum_LT1RT1 * 100) / data?.sum_All).toFixed(1) + "%",
    },
    "Brachial vein": {
      Lt: data?.cat2_LT,
      Lt_percent: ((data?.cat2_LT * 100) / data?.sum_LT).toFixed(1) + "%",
      Rt: data?.cat2_RT,
      Rt_percent: ((data?.cat2_RT * 100) / data?.sum_RT).toFixed(1) + "%",
      Total: data?.cat2_LT + data?.cat2_RT,
      Total_percent:
        ((data?.sum_LT2RT2 * 100) / data?.sum_All).toFixed(1) + "%",
    },
    "Cephalic vein": {
      Lt: data?.cat3_LT,
      Lt_percent: ((data?.cat3_LT * 100) / data?.sum_LT).toFixed(1) + "%",
      Rt: data?.cat3_RT,
      Rt_percent: ((data?.cat3_RT * 100) / data?.sum_RT).toFixed(1) + "%",
      Total: data?.cat3_LT + data?.cat3_RT,
      Total_percent:
        ((data?.sum_LT3RT3 * 100) / data?.sum_All).toFixed(1) + "%",
    },
    "Internal jugular vein": {
      Lt: data?.cat4_LT,
      Lt_percent: ((data?.cat4_LT * 100) / data?.sum_LT).toFixed(1) + "%",
      Rt: data?.cat4_RT,
      Rt_percent: ((data?.cat4_RT * 100) / data?.sum_RT).toFixed(1) + "%",
      Total: data?.cat4_LT + data?.cat4_RT,
      Total_percent:
        ((data?.sum_LT4RT4 * 100) / data?.sum_All).toFixed(1) + "%",
    },
    "Subclavian vein": {
      Lt: data?.cat5_LT,
      Lt_percent: ((data?.cat5_LT * 100) / data?.sum_LT).toFixed(1) + "%",
      Rt: data?.cat5_RT,
      Rt_percent: ((data?.cat5_RT * 100) / data?.sum_RT).toFixed(1) + "%",
      Total: data?.cat5_LT + data?.cat5_RT,
      Total_percent:
        ((data?.sum_LT5RT5 * 100) / data?.sum_All).toFixed(1) + "%",
    },
    "Femoral vein": {
      Lt: data?.cat6_LT,
      Lt_percent: ((data?.cat6_LT * 100) / data?.sum_LT).toFixed(1) + "%",
      Rt: data?.cat6_RT,
      Rt_percent: ((data?.cat6_RT * 100) / data?.sum_RT).toFixed(1) + "%",
      Total: data?.cat6_LT + data?.cat6_RT,
      Total_percent:
        ((data?.sum_LT6RT6 * 100) / data?.sum_All).toFixed(1) + "%",
    },
    "Distal femoral vein": {
      Lt: data?.cat7_LT,
      Lt_percent: ((data?.cat7_LT * 100) / data?.sum_LT).toFixed(1) + "%",
      Rt: data?.cat7_RT,
      Rt_percent: ((data?.cat7_RT * 100) / data?.sum_RT).toFixed(1) + "%",
      Total: data?.cat7_LT + data?.cat7_RT,
      Total_percent:
        ((data?.sum_LT7RT7 * 100) / data?.sum_All).toFixed(1) + "%",
    },
    Other: {
      Lt: data?.cat8_LT,
      Lt_percent: ((data?.cat8_LT * 100) / data?.sum_LT).toFixed(1) + "%",
      Rt: data?.cat8_RT,
      Rt_percent: ((data?.cat8_RT * 100) / data?.sum_RT).toFixed(1) + "%",
      Total: data?.cat8_LT + data?.cat8_RT,
      Total_percent:
        ((data?.sum_LT8RT8 * 100) / data?.sum_All).toFixed(1) + "%",
    },
    Total: {
      Lt: data?.sum_LT,
      Lt_percent: "100%",
      Rt: data?.sum_RT,
      Rt_percent: "100%",
      Total: data?.sum_LT + data?.sum_RT,
      Total_percent: "100%",
    },
  };

  return (
    <div className="my-20">
      <>
        <div className="flex flex-col gap-4">
          <div className="text-black text-lg font-medium">
            นับหลอดเลือดและข้าง (ซ้ายหรือขวา) ของสายน้ำเกลือที่ใส่
            <div className={STYLEBODY}>
              ของปี "{year.label}" ประเภทสาย "{type.label}"
            </div>
          </div>
          <div className={FLEXDROPDOWN}>
            <DropdownTwo
              year={year}
              setYear={setYear}
              type={type}
              setType={setType}
              month={month}
              setMonth={setMonth}
            />
            <ButtonExcel
              onClick={() => {
                // ExcelSideCat(formatDataSideCat(data), year, type, setLoading);
                ExcelSideCat(submitExcelData, year, type, setLoading);
              }}
              loading={loading}
            />
          </div>
        </div>

        <div className={BARCHART}>
          <BarChart
            width={750}
            height={300}
            data={listTable}
            margin={{
              top: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            {/* <XAxis dataKey="name" /> */}
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            {/* <Legend /> */}
            <Bar label={{ fill: "#111827" }} dataKey="left" fill="#fda4af" />
            <Bar label={{ fill: "#111827" }} dataKey="right" fill="#fecdd3" />
          </BarChart>
        </div>
        <div className="grid grid-cols-8 ml-16 mr-18 my-6 text-sm">
          <div className=" -rotate-45 transform-gpu text-center">
            Basilic vein
          </div>
          <div className=" -rotate-45 transform-gpu text-center">
            Brachial vein
          </div>
          <div className=" -rotate-45 transform-gpu text-center">
            Cephalic vein
          </div>
          <div className=" -rotate-45 transform-gpu text-center">
            Internal jugular vein
          </div>
          <div className=" -rotate-45 transform-gpu text-center">
            Subclavian vein
          </div>
          <div className=" -rotate-45 transform-gpu text-center">
            Femoral vein
          </div>
          <div className=" -rotate-45 transform-gpu text-center">
            Distal femoral vein
          </div>
          <div className=" -rotate-45 transform-gpu text-center">Other</div>
        </div>
        <div className="flex justify-center">
          <div className="flex items-center">
            <div className="box-content h-3 w-4 bg-pinkfresh"></div>
            <div className="text-pinkfresh mx-2">left</div>
          </div>
          <div className="flex items-center">
            <div className="box-content h-3 w-4 bg-pinkbold"></div>
            <div className="text-pinkbold mx-2">right</div>
          </div>
        </div>
        <div>
          Missing Data : {data?.missingAll}{" "}
          (มีการใส่สายสำเร็จแต่ไม่ได้ระบุหลอดเลือดที่ใส่สาย)
        </div>
      </>
    </div>
  );
};

const ReportConfirmation = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [month, setMonth] = React.useState({
    label: "ทุกเดือน",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  const [data, setData] = React.useState({
    po_1: 0,
    po_2: 0,
    po_3: 0,
    po_4: 0,
    po_5: 0,
    po_6: 0,
    po_7: 0,
    po_8: 0,
    percent_po1: 0,
    percent_po2: 0,
    percent_po3: 0,
    percent_po4: 0,
    percent_po5: 0,
    percent_po6: 0,
    percent_po7: 0,
    percent_po8: 0,
    total_po: 0,
    //
    // con_good_flu: 0,
    // con_good_iecg: 0,
    // con_good_land: 0,
    // percent_good_flu: 0,
    // percent_good_iecg: 0,
    // percent_good_land: 0,
    // total_good: 0,
    // //
    // con_short_flu: 0,
    // con_short_iecg: 0,
    // con_short_land: 0,
    // percent_short_flu: 0,
    // percent_short_iecg: 0,
    // percent_short_land: 0,
    // total_short: 0,

    // //
    // con_long_flu: 0,
    // con_long_iecg: 0,
    // con_long_land: 0,
    // percent_long_flu: 0,
    // percent_long_iecg: 0,
    // percent_long_land: 0,
    // total_long: 0,
    // //
    // con_un_flu: 0,
    // con_un_iecg: 0,
    // con_un_land: 0,
    // percent_un_flu: 0,
    // percent_un_iecg: 0,
    // percent_un_land: 0,
    // total_un: 0,
    //
    in_ivc: 0,
    no_ivc: 0,

    // lower_unflu: 0,
    // lower_uniecg: 0,
    // lower_unland: 0,
    // total_lower_un: 0,
  });

  const [data2, setData2] = React.useState({
    //
    con_good_flu: 0,
    con_good_iecg: 0,
    con_good_land: 0,
    percent_good_flu: 0,
    percent_good_iecg: 0,
    percent_good_land: 0,
    total_good: 0,
    //
    con_short_flu: 0,
    con_short_iecg: 0,
    con_short_land: 0,
    percent_short_flu: 0,
    percent_short_iecg: 0,
    percent_short_land: 0,
    total_short: 0,

    //
    con_long_flu: 0,
    con_long_iecg: 0,
    con_long_land: 0,
    percent_long_flu: 0,
    percent_long_iecg: 0,
    percent_long_land: 0,
    total_long: 0,
    //
    con_un_flu: 0,
    con_un_iecg: 0,
    con_un_land: 0,
    con_un_miss: 0,
    percent_un_flu: 0,
    percent_un_iecg: 0,
    percent_un_land: 0,
    percent_un_miss: 0,
    total_un: 0,
    //
    miss_upper: 0,
    miss_lower: 0,
    //
    in_ivc_iecg: 0,
    no_ivc_iecg: 0,

    in_ivc_flu: 0,
    no_ivc_flu: 0,

    in_ivc_land: 0,
    no_ivc_land: 0,

    in_ivc_miss: 0,
    no_ivc_miss: 0,

    lower_unflu: 0,
    lower_uniecg: 0,
    lower_unland: 0,
    lower_unmiss: 0,

    total_lower_un: 0,
  });

  React.useEffect(() => {
    ReportCountConfirmation({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
      month: String(month?.value),
    });
  }, [year, type, month]);

  React.useEffect(() => {
    ReportCountTorso({
      list,
      setValue: setData2,
      year: String(year?.value),
      type: String(type?.value),
      month: String(month?.value),
    });
  }, [year, type, month]);

  const listTable1 = [
    { name: "-3", count: data?.po_1 },
    { name: "-2", count: data?.po_2 },
    { name: "-1", count: data?.po_3 },
    { name: "0", count: data?.po_4 },
    { name: "1", count: data?.po_5 },
    {
      name: "2",
      count: data?.po_6,
    },
    { name: "3", count: data?.po_7 },
    { name: "In IVC", count: data?.in_ivc },
    { name: "Not in IVC", count: data?.no_ivc },
    { name: "Unintended Position", count: data?.po_8 },
  ];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const count = payload[0].value;
      const total = listTable1.reduce((a, b) => a + b.count, 0);
      const percentage = ((count / total) * 100).toFixed(1);
      return (
        <div className="bg-gray-50 text-gray-700 text-sm px-2 py-1 rounded">
          <p>{`${count} (${percentage}%)`}</p>
        </div>
      );
    }

    return null;
  };

  const listTable2 = [
    { name: "Fluoroscopy", count: data?.con_good_flu },
    { name: "iECG", count: data?.con_good_iecg },
    { name: "Landmark", count: data?.con_good_land },
  ];
  const listTable3 = [
    { name: "Fluoroscopy", count: data?.con_short_flu },
    { name: "iECG", count: data?.con_short_iecg },
    { name: "Landmark", count: data?.con_short_land },
  ];
  const listTable4 = [
    { name: "Fluoroscopy", count: data?.con_long_flu },
    { name: "iECG", count: data?.con_long_iecg },
    { name: "Landmark", count: data?.con_long_land },
  ];
  const listTable5 = [
    { name: "Fluoroscopy", count: data?.con_un_flu },
    { name: "iECG", count: data?.con_un_iecg },
    { name: "Landmark", count: data?.con_un_land },
  ];
  const listTable6 = [
    { name: "In IVC", count: data?.in_ivc },
    { name: "Non in IVC", count: data?.no_ivc },
  ];

  let totalIECG = 0;
  let totalLandmark = 0;
  let totalFluoroscopy = 0;
  let totalMissingData = 0;
  let totalLowerIECG = 0;
  let totalLowerLandmark = 0;
  let totalLowerFluroscopy = 0;

  if (data2) {
    totalIECG =
      (data2?.con_good_iecg || 0) +
      (data2?.con_short_iecg || 0) +
      (data2?.con_long_iecg || 0) +
      (data2?.con_un_iecg || 0);
    totalLandmark =
      (data2?.con_good_land || 0) +
      (data2?.con_short_land || 0) +
      (data2?.con_long_land || 0) +
      (data2?.con_un_land || 0);
    totalFluoroscopy =
      (data2?.con_good_flu || 0) +
      (data2?.con_short_flu || 0) +
      (data2?.con_long_flu || 0) +
      (data2?.con_un_flu || 0);
    totalMissingData = data2.con_un_miss || 0;
    totalLowerIECG =
      data2?.lower_uniecg + data2?.in_ivc_iecg + data2?.no_ivc_iecg;
    totalLowerLandmark =
      data2?.lower_unland + data2?.in_ivc_land + data2?.no_ivc_land;
    totalLowerFluroscopy =
      data2?.lower_unflu + data2?.in_ivc_flu + data2?.no_ivc_flu;
  }

  const IECGpercentGood =
    totalIECG === 0 ? 0 : ((data2?.con_good_iecg * 100) / totalIECG).toFixed(1);
  const IECGpercentShort =
    totalIECG === 0
      ? 0
      : ((data2?.con_short_iecg * 100) / totalIECG).toFixed(1);
  const IECGpercentLong =
    totalIECG === 0 ? 0 : ((data2?.con_long_iecg * 100) / totalIECG).toFixed(1);
  const IECGpercentUn =
    totalIECG === 0 ? 0 : ((data2?.con_un_iecg * 100) / totalIECG).toFixed(1);

  const LandmarkpercentGood =
    totalLandmark === 0
      ? 0
      : ((data2?.con_good_land * 100) / totalLandmark).toFixed(1);
  const LandmarkpercentShort =
    totalLandmark === 0
      ? 0
      : ((data2?.con_short_land * 100) / totalLandmark).toFixed(1);
  const LandmarkpercentLong =
    totalLandmark === 0
      ? 0
      : ((data2?.con_long_land * 100) / totalLandmark).toFixed(1);
  const LandmarkpercentUn =
    totalLandmark === 0
      ? 0
      : ((data2?.con_un_land * 100) / totalLandmark).toFixed(1);

  const FluoroscopypercentGood =
    totalFluoroscopy === 0
      ? 0
      : ((data2?.con_good_flu * 100) / totalFluoroscopy).toFixed(1);
  const FluoroscopypercentShort =
    totalFluoroscopy === 0
      ? 0
      : ((data2?.con_short_flu * 100) / totalFluoroscopy).toFixed(1);
  const FluoroscopypercentLong =
    totalFluoroscopy === 0
      ? 0
      : ((data2?.con_long_flu * 100) / totalFluoroscopy).toFixed(1);
  const FluoroscopypercentUn =
    totalFluoroscopy === 0
      ? 0
      : ((data2?.con_un_flu * 100) / totalFluoroscopy).toFixed(1);

  const LowerPercentInIvcIECG =
    totalLowerIECG === 0
      ? 0
      : ((data2?.in_ivc_iecg * 100) / totalLowerIECG).toFixed(1);

  const LowerPercentNoIvcIECG =
    totalLowerIECG === 0
      ? 0
      : ((data2?.no_ivc_iecg * 100) / totalLowerIECG).toFixed(1);

  const LowerPercentUnIvcIECG =
    totalLowerIECG === 0
      ? 0
      : ((data2?.lower_uniecg * 100) / totalLowerIECG).toFixed(1);

  const LowerPercentInIvcLandmark =
    totalLowerLandmark === 0
      ? 0
      : ((data2?.in_ivc_land * 100) / totalLowerLandmark).toFixed(1);

  const LowerPercentNoIvcLandmark =
    totalLowerLandmark === 0
      ? 0
      : ((data2?.no_ivc_land * 100) / totalLowerLandmark).toFixed(1);
  const LowerPercentUnIvcLandmark =
    totalLowerLandmark === 0
      ? 0
      : ((data2?.lower_unland * 100) / totalLowerLandmark).toFixed(1);

  const LowerPercentInIvcFluroscopy =
    totalLowerFluroscopy === 0
      ? 0
      : ((data2?.in_ivc_flu * 100) / totalLowerFluroscopy).toFixed(1);
  const LowerPercentNoIvcFluroscopy =
    totalLowerFluroscopy === 0
      ? 0
      : ((data2?.no_ivc_flu * 100) / totalLowerFluroscopy).toFixed(1);
  const LowerPercentUnIvcFluroscopy =
    totalLowerFluroscopy === 0
      ? 0
      : ((data2?.lower_unflu * 100) / totalLowerFluroscopy).toFixed(1);

  const mockData = [
    {
      //IECG
      col1IECG: "iECG",
      col2IECG: `${totalIECG}  `,
      col3IECG: `${data2?.con_good_iecg} (${IECGpercentGood}%)`,
      col4IECG: `${data2?.con_short_iecg} (${IECGpercentShort}%)`,
      col5IECG: `${data2?.con_long_iecg} (${IECGpercentLong}%) `,
      col6IECG: `${data2?.con_un_iecg} (${IECGpercentUn}%) `,
      //Landmark
      col1Landmark: "Landmark",
      col2Landmark: `${totalLandmark}  `,
      col3Landmark: `${data2?.con_good_land} (${LandmarkpercentGood}%)`,
      col4Landmark: `${data2?.con_short_land} (${LandmarkpercentShort}%)`,
      col5Landmark: `${data2?.con_long_land} (${LandmarkpercentLong}%) `,
      col6Landmark: `${data2?.con_un_land} (${LandmarkpercentUn}%) `,
      //Fluoroscopy
      col1Fluoroscopy: "Fluoroscopy",
      col2Fluoroscopy: `${totalFluoroscopy}  `,
      col3Fluoroscopy: `${data2?.con_good_flu} (${FluoroscopypercentGood}%)`,
      col4Fluoroscopy: `${data2?.con_short_flu} (${FluoroscopypercentShort}%)`,
      col5Fluoroscopy: `${data2?.con_long_flu} (${FluoroscopypercentLong}%) `,
      col6Fluoroscopy: `${data2?.con_un_flu} (${FluoroscopypercentUn}%) `,
      //Missing Data
      // col1MissingData: "Missing Data",
      // col2MissingData: `${totalMissingData}  `,
      //Missing Upper
      col1MissingUpper: "Missing Upper",
      col2MissingUpper: `${data2?.miss_upper}  `,
    },
  ];
  const mockData2 = [
    {
      //IECG
      col1IECG: "iECG",
      col2IECG: `${totalLowerIECG}`,
      col3IECG: `${data2?.in_ivc_iecg} (${LowerPercentInIvcIECG}%)`,
      col4IECG: `${data2?.no_ivc_iecg} (${LowerPercentNoIvcIECG}%)`,
      col5IECG: `${data2?.lower_uniecg} (${LowerPercentUnIvcIECG}%) `,
      //Landmark
      col1Landmark: "Landmark",
      col2Landmark: `${totalLowerLandmark}`,
      col3Landmark: `${data2?.in_ivc_land} (${LowerPercentInIvcLandmark}%) `,
      col4Landmark: `${data2?.no_ivc_land} (${LowerPercentNoIvcLandmark}%) `,
      col5Landmark: `${data2?.lower_unland} (${LowerPercentUnIvcLandmark}%) `,
      //Fluoroscopy
      col1Fluoroscopy: "Fluoroscopy",
      col2Fluoroscopy: `${totalLowerFluroscopy}`,
      col3Fluoroscopy: `${data2?.in_ivc_flu} (${LowerPercentInIvcFluroscopy}%) `,
      col4Fluoroscopy: `${data2?.no_ivc_flu} (${LowerPercentNoIvcFluroscopy}%) `,
      col5Fluoroscopy: `${data2?.lower_unflu} (${LowerPercentUnIvcFluroscopy}%) `,
      //Missing Data
      // col1MissingData: "Missing Data",
      // col2MissingData: `${totalMissingData}  `,
      // MissingLower
      col1MissingLower: "Missing Lower",
      col2MissingLower: `${data2?.miss_lower}  `,
    },
  ];
  // 1

  // console.log("ReportCountConfirmation", data);
  // console.log("ReportCountConfirmation-2", data2);

  const renderCustomLabel = (props) => {
    const { x, y, width, height, value } = props;
    const percentage = ((value / data?.total_po) * 100).toFixed(1) + "%";
    const insideBarPosition = y + height / 2;

    return (
      <g>
        <text
          x={x + width / 2}
          y={insideBarPosition}
          fill="#111827"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {`${value} (${percentage})`}
        </text>
      </g>
    );
  };

  return (
    <div className="">
      <div className={``}>
        <div className="my-10">
          <div>
            <div className="flex flex-col gap-4">
              <div className="text-black text-lg font-medium">
                รายงานตำแหน่งของสายหลังการถ่าย X-ray ปอด
                <div className={STYLEBODY}>
                  ของปี "{year.label}" ประเภทสาย "{type.label}"
                </div>
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownTwo
                  year={year}
                  setYear={setYear}
                  type={type}
                  setType={setType}
                  month={month}
                  setMonth={setMonth}
                />
                <ButtonExcel
                  onClick={() => {
                    ExcelConfrimation(
                      formatConfrimation(data),
                      year,
                      type,
                      setLoading
                    );
                  }}
                  loading={loading}
                />
              </div>
            </div>
            <div className={BARCHART}>
              <BarChart
                width={750}
                height={300}
                data={listTable1}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                {/* <XAxis dataKey="name" /> */}
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                {/* <Legend /> */}
                <Bar label={renderCustomLabel} dataKey="count" fill="#fde047" />
              </BarChart>
            </div>
            <div className="grid grid-cols-10 ml-16 mr-4 my-4 text-sm">
              <div className="text-center">-3</div>
              <div className="text-center">-2</div>
              <div className="text-center">-1</div>
              <div className="text-center">0</div>
              <div className="text-center">1</div>
              <div className="text-center">2</div>
              <div className="text-center">3</div>
              <div className="text-center">In IVC</div>
              <div className="text-center">Not in IVC</div>
              <div className="text-center">Unintended Position</div>
            </div>
            <div className="flex justify-center">
              <div className="flex items-center">
                <div className="box-content h-3 w-4 bg-yellowgraph"></div>
                <div className="text-yellowgraph mx-2">count</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 2  */}
      <div className="mx-10 font-bold text-lg">
        Tip of catheter (Upper torso catheter){" "}
      </div>
      _____________________________________________________________________________________
      <div className={``}>
        <div className="my-2">
          <>
            <div className={SCREENIPAD}>
              <div>
                <div className="grid grid-cols-6 gap-3 border rounded p-2 bg-gray-100">
                  <div class="text-center">Confirmation technique</div>
                  <div class="text-center">Total (N)</div>
                  <div class="text-center">Proper position N (%)</div>
                  <div class="text-center">Too short N (%)</div>
                  <div class="text-center">Too deep N (%)</div>
                  <div class="text-center">Unintended position</div>
                </div>
                {mockData.map((item, index) => (
                  <div>
                    <div
                      className="grid grid-cols-6 gap-3 px-2 py-1"
                      key={index}
                    >
                      <div class="text-center">{item.col1IECG}</div>
                      <div class="text-center">{item.col2IECG}</div>
                      <div class="text-center">{item.col3IECG}</div>
                      <div class="text-center">{item.col4IECG}</div>
                      <div class="text-center">{item.col5IECG}</div>
                      <div class="text-center">{item.col6IECG}</div>
                    </div>
                    <div
                      className="grid grid-cols-6 gap-3 px-2 py-1"
                      key={index}
                    >
                      <div class="text-center">{item.col1Landmark}</div>
                      <div class="text-center">{item.col2Landmark}</div>
                      <div class="text-center">{item.col3Landmark}</div>
                      <div class="text-center">{item.col4Landmark}</div>
                      <div class="text-center">{item.col5Landmark}</div>
                      <div class="text-center">{item.col6Landmark}</div>
                    </div>
                    <div
                      className="grid grid-cols-6 gap-3 px-2 py-1"
                      key={index}
                    >
                      <div class="text-center">{item.col1Fluoroscopy}</div>
                      <div class="text-center">{item.col2Fluoroscopy}</div>
                      <div class="text-center">{item.col3Fluoroscopy}</div>
                      <div class="text-center">{item.col4Fluoroscopy}</div>
                      <div class="text-center">{item.col5Fluoroscopy}</div>
                      <div class="text-center">{item.col6Fluoroscopy}</div>
                    </div>
                    <div
                      className="grid grid-cols-6 gap-3 px-2 py-1"
                      key={index}
                    >
                      <div class="text-center">{item.col1MissingData}</div>
                      <div class="text-center">{item.col2MissingData}</div>
                    </div>
                    <div
                      className="grid grid-cols-6 gap-3 px-2 py-1"
                      key={index}
                    >
                      <div class="text-center">{item?.col1MissingUpper}</div>
                      <div class="text-center">{item?.col2MissingUpper}</div>
                    </div>
                  </div>
                ))}
                *<i> หมายเหตุ </i> Proper position (+2,+1,0,-1,-2) ,Too short
                (-3) , Too Deep (+3)
                {/* <div className={STYLEHEAD}>
                  รายงานตำแหน่งของสายหลังการถ่าย X-ray ปอด กลุ่มดี (-1 , 0 , +1)
                  โดยสัมพันธ์กับ การใช้ Confirmation
                  <div className={STYLEBODY}>
                    ของปี "{year.label}" ประเภทสาย "{type.label}"
                  </div> */}
                {/* </div> */}
              </div>
            </div>
            {/* <div className={BARCHART}>  */}
            {/* <BarChart
                width={700}
                height={300}
                data={listTable2}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              > */}
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            {/* <XAxis dataKey="name" /> */}
            {/* <YAxis /> */}
            {/* <Tooltip /> */}
            {/* <Legend /> */}
            {/* <Bar label={true} dataKey="count" fill="#CC9933" />  */}
            {/* </BarChart>   */}
            {/* </div>  */}
            {/* <div className="flex justify-center">
              <div className="flex items-center">
                <div className="box-content h-3 w-4 bg-yellowgraph"></div>
                <div className="text-yellowgraph mx-2">count</div>
              </div>
            </div> */}
          </>
        </div>
      </div>
      {/* 3 */}
      <div className={``}>
        {/* <div className="my-10"> */}
        <>
          <div className={SCREENIPAD}>
            {/* <div className={STYLEHEAD}>
                รายงานตำแหน่งของสายหลังการถ่าย X-ray ปอด กลุ่มตื้น (-2 ,-3 )
                โดยสัมพันธ์กับ การใช้ Confirmation
                <div className={STYLEBODY}>
                  ของปี "{year.label}" ประเภทสาย "{type.label}"
                </div>
              </div> */}
          </div>
          {/* <div className={BARCHART}>  */}
          {/* <BarChart
                width={700}
                height={300}
                data={listTable3}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              > */}
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {/* <XAxis dataKey="name" /> */}
          {/* <YAxis /> */}
          {/* <Tooltip /> */}
          {/* <Legend /> */}
          {/* <Bar label={true} dataKey="count" fill="#CC9933" /> */}
          {/* </BarChart> */}
          {/* </div>  */}
          {/* <div className="flex justify-center">
              <div className="flex items-center"> */}
          {/* <div className="box-content h-3 w-4 bg-yellowgraph"></div> */}
          {/* <div className="text-yellowgraph mx-2">count</div> */}
          {/* </div>
            </div> */}
        </>
        {/* </div> */}
      </div>
      {/* 4 */}
      <div className={``}>
        {/* <div className="my-10"> */}
        <>
          {/* <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>
                รายงานตำแหน่งของสายหลังการถ่าย X-ray ปอด กลุ่มลึก (+2 ,+3 )
                โดยสัมพันธ์กับ การใช้ Confirmation
                <div className={STYLEBODY}>
                  ของปี "{year.label}" ประเภทสาย "{type.label}"
                </div>
              </div>
            </div> */}
          {/* <div className={TABLE}> */}
          {/* <BarChart
                width={700}
                height={300}
                data={listTable4}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              > */}
          {/* <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip /> */}
          {/* <Legend /> */}
          {/* <Bar label={true} dataKey="count" fill="#CC9933" /> */}
          {/* </BarChart> */}
          {/* </div> */}
          {/* <div className="flex justify-center"> */}
          {/* <div className="flex items-center"> */}
          {/* <div className="box-content h-3 w-4 bg-yellowgraph"></div> */}
          {/* <div className="text-yellowgraph mx-2">count</div> */}
          {/* </div>
            </div> */}
        </>
        {/* </div> */}
      </div>
      {/* 5 */}
      <div className={``}>
        {/* <div className="my-10"> */}
        <>
          {/* <div className={SCREENIPAD}>
              <div>
                <div className={STYLEHEAD}>
                  รายงานตำแหน่งของสายหลังการถ่าย X-ray ปอด กลุ่ม Unintended
                  Position โดยสัมพันธ์กับ<div>การใช้ Confirmation </div>
                  <div className={STYLEBODY}>
                    ของปี " {year.label}" ประเภทสาย "{type.label}"
                  </div>
                </div>
              </div>
            </div> */}
          {/* <div className={BARCHART}> */}
          {/* <BarChart
                width={700}
                height={300}
                data={listTable5}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              > */}
          {/* <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip /> */}
          {/* <Legend /> */}
          {/* <Bar label={true} dataKey="count" fill="#CC9933" /> */}
          {/* </BarChart> */}
          {/* </div> */}
          {/* <div className="flex justify-center">
              <div className="flex items-center"> */}
          {/* <div className="box-content h-3 w-4 bg-yellowgraph"></div> */}
          {/* <div className="text-yellowgraph mx-2">count</div> */}
          {/* </div>
            </div> */}
        </>
        {/* </div> */}
      </div>
      {/* 6 */}
      <div className="mx-10 font-bold text-lg">
        Tip of catheter (Lower torso catheter){" "}
      </div>
      _____________________________________________________________________________________
      <div className={``}>
        {/* <div className="my-2"> */}
        <>
          <div className={SCREENIPAD}>
            <div>
              <div className="grid grid-cols-5 gap-3 border rounded p-2 bg-gray-100">
                <div className="text-center">Confirmation technique</div>
                <div className="text-center">Total (N)</div>
                <div className="text-center">
                  In IVC <br />
                  N(%)
                </div>
                <div className="text-center">
                  Not in IVC <br /> N(%)
                </div>
                <div className="text-center">Unintended position</div>
              </div>
              {mockData2.map((item, index) => (
                <div>
                  <div className="grid grid-cols-5 gap-3 px-2 py-1" key={index}>
                    <div className="text-center">{item.col1IECG}</div>
                    <div className="text-center">{item.col2IECG}</div>
                    <div className="text-center">{item.col3IECG}</div>
                    <div className="text-center">{item.col4IECG}</div>
                    <div className="text-center">{item.col5IECG}</div>
                  </div>
                  <div className="grid grid-cols-5 gap-3 px-2 py-1" key={index}>
                    <div className="text-center">{item.col1Landmark}</div>
                    <div className="text-center">{item.col2Landmark}</div>
                    <div className="text-center">{item.col3Landmark}</div>
                    <div className="text-center">{item.col4Landmark}</div>
                    <div className="text-center">{item.col5Landmark}</div>
                  </div>
                  <div className="grid grid-cols-5 gap-3 px-2 py-1" key={index}>
                    <div className="text-center">{item.col1Fluoroscopy}</div>
                    <div className="text-center">{item.col2Fluoroscopy}</div>
                    <div className="text-center">{item.col3Fluoroscopy}</div>
                    <div className="text-center">{item.col4Fluoroscopy}</div>
                    <div className="text-center">{item.col5Fluoroscopy}</div>
                  </div>
                  <div className="grid grid-cols-5 gap-3 px-2 py-1" key={index}>
                    <div className="text-center">{item.col1MissingData}</div>
                    <div className="text-center">{item.col2MissingData}</div>
                    <div className="text-center">{item.col3MissingData}</div>
                    <div className="text-center">{item.col4MissingData}</div>
                    <div className="text-center">{item.col5MissingData}</div>
                  </div>
                  <div className="grid grid-cols-5 gap-3 px-2 py-1" key={index}>
                    <div className="text-center">{item?.col1MissingLower}</div>
                    <div className="text-center">{item?.col2MissingLower}</div>
                  </div>
                  Missing Data : {totalMissingData}{" "}
                  (จำนวนผู้ป่วยที่ได้รับการใส่สายแต่ไม่ได้รับการระบุว่าเป็น
                  Upper Torso หรือ Lower Torso)
                </div>
              ))}
              {/* <div className={STYLEHEAD}>
                  รายงาน Tip of catheter (Lower torso catheter) แบ่งตาม In IVC /
                  Non in IVC
                  <div className={STYLEBODY}>
                    ของปี "{year.label}" ประเภทสาย "{type.label}"
                  </div>
                </div> */}
            </div>
          </div>
          {/* <div className={BARCHART}> */}
          {/* <BarChart
                width={700}
                height={300}
                data={listTable6}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              > */}
          {/* <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip /> */}
          {/* <Legend /> */}
          {/* <Bar label={true} dataKey="count" fill="#CC9933" /> */}
          {/* </BarChart> */}
          {/* </div> */}
          {/* <div className="flex justify-center"> */}
          {/* <div className="flex items-center"> */}
          {/* <div className="box-content h-3 w-4 bg-yellowgraph"></div> */}
          {/* <div className="text-yellowgraph mx-2">count</div> */}
          {/* </div> */}
          {/* </div> */}
        </>
        {/* </div> */}
      </div>
    </div>
  );
};

const ReportAttemptInfection = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [month, setMonth] = React.useState({
    label: "ทุกเดือน",
    value: "ALL",
  });
  const [type, setType] = React.useState({
    label: "-",
    value: "NONE",
  });

  const [data, setData] = React.useState({
    complications_pneumothorax: 0,
    complications_hemothorax: 0,
    complications_arterial_puncture: 0,
    complications_paresthesia: 0,
    complications_nerve_injury: 0,
    complications_limb_ischemia_related_to_arterial_puncture: 0,
    complications_wire: 0,
    other_complications: 0,
    total: 0,
  });

  // console.log("ภาวะแทรกซ้อน", data);

  React.useEffect(() => {
    ReportCountInfectionAttempt({
      list,
      setValue: setData,
      year: String(year?.value),
      type: String(type?.value),
      month: String(month?.value),
    });
  }, [year, type, month]);

  const listTable = [
    { name: "Pneumothorax", count: data?.complications_pneumothorax },
    { name: "Hemothorax", count: data?.complications_hemothorax },
    { name: "Arterial puncture", count: data?.complications_arterial_puncture },
    { name: "Paresthesia", count: data?.complications_paresthesia },
    { name: "Nerve injury", count: data?.complications_nerve_injury },
    // {
    //   name: "Limb ischemia",
    //   count: data?.complications_limb_ischemia_related_to_arterial_puncture,
    // },
    { name: "Wire complication", count: data?.complications_wire },
    { name: "Other", count: data?.other_complications },
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    // console.log("payload", payload);
    if (active && payload && payload.length) {
      const count = payload?.[0]?.value || 0;
      const percentage = ((count * 100) / data.total).toFixed(1);

      return (
        <div className="bg-gray-50 text-gray-700 text-sm px-2 py-1 rounded">
          <p className="label">{`count : ${count} (${percentage}%)`}</p>
        </div>
      );
    }

    return null;
  };

  const renderCustomLabel = (props) => {
    const { x, y, width, height, value } = props;
    const percentage = ((value / data?.total) * 100).toFixed(1) + "%";
    const insideBarPosition = y + height / 2;

    return (
      <g>
        <text
          x={x + width / 2}
          y={insideBarPosition}
          fill="#111827"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {`${value} (${percentage})`}
        </text>
      </g>
    );
  };

  return (
    <div>
      {/* _____________________________________________________________________________________ */}
      <div className={``}>
        <div className="my-10">
          <>
            <div className="flex flex-col gap-4">
              <div className="text-black text-lg font-medium">
                การเกิดภาวะแทรกซ้อนระหว่างทำหัตถการ
                <div className={STYLEBODY}>
                  แยก pneumothorax, hemothorax, limb ischemia related to
                  arterial puncture, nerve injury ของปี "{year.label}" ประเภทสาย
                  "{type.label}"
                </div>
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownTwo
                  year={year}
                  setYear={setYear}
                  type={type}
                  setType={setType}
                  month={month}
                  setMonth={setMonth}
                />
                <ButtonExcel
                  onClick={() => {
                    ExcelInfectionAt(
                      formatInfectionAt(data),
                      year,
                      type,
                      setLoading
                    );
                  }}
                  loading={loading}
                />
              </div>
            </div>
            <div>จำนวนสายทั้งหมด {data?.total}</div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listTable}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                {/* <XAxis dataKey="name" /> */}
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                {/* <Legend /> */}
                <Bar label={renderCustomLabel} dataKey="count" fill="#CC9933" />
              </BarChart>
            </div>
            <div className="grid grid-cols-7 mx-16 my-8 text-sm">
              <div className=" -rotate-45 transform-gpu text-center">
                Pneumothorax
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Hemothorax
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Arterial puncture
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Paresthesia
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Nerve injury
              </div>
              {/* <div className=" -rotate-45 transform-gpu text-center">
                Limb ischemia
              </div> */}
              <div className=" -rotate-45 transform-gpu text-center">
                Wire complication
              </div>
              <div className=" -rotate-45 transform-gpu text-center">Other</div>
            </div>
            <div className="flex justify-center">
              <div className="flex items-center">
                <div className="box-content h-3 w-4 bg-yellowgraph"></div>
                <div className="text-yellowgraph mx-2">count</div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

// tab04 การติดตามผู้ป่วย
const ReportTimeTotalDay = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const count = payload?.[0]?.value || 0;
      const percentage = ((count * 100) / data.number).toFixed(1);
      const count2 = payload?.[1]?.value || 0;
      const percentage2 = ((count2 * 100) / data.number).toFixed(1);
      const count3 = payload?.[2]?.value || 0;
      const percentage3 = ((count3 * 100) / data.number).toFixed(1);
      return (
        <div className="bg-gray-50 text-gray-700 text-sm px-2 py-1 rounded">
          <p className="label">{`All : ${count} (${percentage}%)`}</p>
          <p className="label">{`Adult : ${count2} (${percentage2}%)`}</p>
          <p className="label">{`Child : ${count3} (${percentage3}%)`}</p>
        </div>
      );
    }

    return null;
  };

  const [data, setData] = React.useState({
    A: 0, //1
    B: 0, //2
    C: 0, //3
    D: 0, //4
    E: 0, //5
    F: 0, //6
    G: 0, //7

    //
    A_adult: 0,
    B_adult: 0,
    C_adult: 0,
    D_adult: 0,
    E_adult: 0,
    F_adult: 0,
    G_adult: 0,

    //
    A_kid: 0,
    B_kid: 0,
    C_kid: 0,
    D_kid: 0,
    E_kid: 0,
    F_kid: 0,
    G_kid: 0,

    //

    mean: 0,
    median: 0,
    sd: 0,
    min: 0,
    max: 0,
    per25: 0,
    per75: 0,
    number: 0,
    //
    mean_ad: 0,
    median_ad: 0,
    sd_ad: 0,
    min_ad: 0,
    max_ad: 0,
    per25_ad: 0,
    per75_ad: 0,
    number_ad: 0,
    //
    mean_kid: 0,
    median_kid: 0,
    sd_kid: 0,
    min_kid: 0,
    max_kid: 0,
    per25_kid: 0,
    per75_kid: 0,
    number_kid: 0,
  });
  const listTable = [
    {
      name: "1-7 วัน",
      all: data?.A,
      adult: data?.A_adult,
      kid: data?.A_kid,
    },
    { name: "8-14 วัน", all: data?.B, adult: data?.B_adult, kid: data?.B_kid },
    { name: "15-30 วัน", all: data?.C, adult: data?.C_adult, kid: data?.C_kid },
    { name: "31-90 วัน", all: data?.D, adult: data?.D_adult, kid: data?.D_kid },
    {
      name: "91-180 วัน",
      all: data?.E,
      adult: data?.E_adult,
      kid: data?.E_kid,
    },
    {
      name: "181-365 วัน",
      all: data?.F,
      adult: data?.F_adult,
      kid: data?.F_kid,
    },
    {
      name: "มากกว่า 365 วัน",
      all: data?.G,
      adult: data?.G_adult,
      kid: data?.G_kid,
    },
  ];

  React.useEffect(() => {
    CountTotalDay({
      list,
      setValue: setData,
      year: String(year?.value),
    });
  }, [year]);

  const table = [
    {
      name: "mean",
      data: data?.mean,
      adult: data?.mean_ad,
      kid: data?.mean_kid,
    },
    {
      name: "median",
      data: data?.median,
      adult: data?.median_ad,
      kid: data?.median_kid,
    },
    { name: "SD", data: data?.sd, adult: data?.sd_ad, kid: data?.sd_kid },
    { name: "min", data: data?.min, adult: data?.min_ad, kid: data?.min_kid },
    { name: "max", data: data?.max, adult: data?.max_ad, kid: data?.max_kid },
    {
      name: "Percentile 25th",
      data: data?.per25,
      adult: data?.per25_ad,
      kid: data?.per25_kid,
    },
    {
      name: "Percentile 75th",
      data: data?.per75,
      adult: data?.per75_ad,
      kid: data?.per75_kid,
    },
    {
      name: "n",
      data: data?.number,
      adult: data?.number_ad,
      kid: data?.number_kid,
    },
  ];
  const excelData = formatTotalDay(data).data[0];

  // console.log("excelData", excelData);

  const totalAll =
    excelData?.A +
    excelData?.B +
    excelData?.C +
    excelData?.D +
    excelData?.E +
    excelData?.F +
    excelData?.G;

  const totalAdult =
    excelData?.A_adult +
    excelData?.B_adult +
    excelData?.C_adult +
    excelData?.D_adult +
    excelData?.E_adult +
    excelData?.F_adult +
    excelData?.G_adult;

  const totalKid =
    excelData?.A_kid +
    excelData?.B_kid +
    excelData?.C_kid +
    excelData?.D_kid +
    excelData?.E_kid +
    excelData?.F_kid +
    excelData?.G_kid;

  const submitExcelData = {
    "1-7": {
      total: excelData?.A,
      total_percent: ((excelData?.A * 100) / totalAll).toFixed(1),
      adults: excelData?.A_adult,
      adults_percent: ((excelData?.A_adult * 100) / totalAdult).toFixed(1),
      children: excelData?.A_kid,
      children_percent: ((excelData?.A_kid * 100) / totalKid).toFixed(1),
    },
    "8-14": {
      total: excelData?.B,
      total_percent: ((excelData?.B * 100) / totalAll).toFixed(1),
      adults: excelData?.B_adult,
      adults_percent: ((excelData?.B_adult * 100) / totalAdult).toFixed(1),
      children: excelData?.B_kid,
      children_percent: ((excelData?.B_kid * 100) / totalKid).toFixed(1),
    },
    "15-30": {
      total: excelData?.C,
      total_percent: ((excelData?.C * 100) / totalAll).toFixed(1),
      adults: excelData?.C_adult,
      adults_percent: ((excelData?.C_adult * 100) / totalAdult).toFixed(1),
      children: excelData?.C_kid,
      children_percent: ((excelData?.C_kid * 100) / totalKid).toFixed(1),
    },
    "31-90": {
      total: excelData?.D,
      total_percent: ((excelData?.D * 100) / totalAll).toFixed(1),
      adults: excelData?.D_adult,
      adults_percent: ((excelData?.D_adult * 100) / totalAdult).toFixed(1),
      children: excelData?.D_kid,
      children_percent: ((excelData?.D_kid * 100) / totalKid).toFixed(1),
    },
    "91-180": {
      total: excelData?.E,
      total_percent: ((excelData?.E * 100) / totalAll).toFixed(1),
      adults: excelData?.E_adult,
      adults_percent: ((excelData?.E_adult * 100) / totalAdult).toFixed(1),
      children: excelData?.E_kid,
      children_percent: ((excelData?.E_kid * 100) / totalKid).toFixed(1),
    },
    "181-365": {
      total: excelData?.F,
      total_percent: ((excelData?.F * 100) / totalAll).toFixed(1),
      adults: excelData?.F_adult,
      adults_percent: ((excelData?.F_adult * 100) / totalAdult).toFixed(1),
      children: excelData?.F_kid,
      children_percent: ((excelData?.F_kid * 100) / totalKid).toFixed(1),
    },
    "365+": {
      total: excelData?.G,
      total_percent: ((excelData?.G * 100) / totalAll).toFixed(1),
      adults: excelData?.G_adult,
      adults_percent: ((excelData?.G_adult * 100) / totalAdult).toFixed(1),
      children: excelData?.G_kid,
      children_percent: ((excelData?.G_kid * 100) / totalKid).toFixed(1),
    },
    Total: {
      total: totalAll,
      total_percent: 100,
      adults: totalAdult,
      adults_percent: 100,
      children: totalKid,
      children_percent: 100,
    },
  };

  return (
    <>
      <div className={``}>
        <div className="my-10">
          <div className={SCREENIPAD}>
            <div className={STYLEHEAD}>
              รายงานจำนวนวันที่ผู้ป่วยแต่ละคนใช้สาย
              <div className={STYLEBODY}>ของปี "{year.label}"</div>
            </div>
            <div className={FLEXDROPDOWN}>
              <DropdownFollows year={year} setYear={setYear} />
              <ButtonExcel
                text={"DOWNLOAD EXCEL"}
                onClick={() => {
                  // ExcelTotalDay(formatTotalDay(data), year, setLoading);
                  ExcelTotalDay(submitExcelData);
                }}
                loading={loading}
              />
            </div>
          </div>
          <div className={BARCHART}>
            {/* <Doughnut data={data1} options={options} /> */}
            <BarChart
              width={700}
              height={300}
              data={listTable}
              margin={{
                top: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              {/* <XAxis dataKey="name" /> */}
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              {/* <Legend /> */}
              <Bar
                label={{ fill: "#111827" }}
                dataKey="all"
                fill={chartColors.chartblue_1}
              />
              <Bar
                label={{ fill: "#111827" }}
                dataKey="adult"
                fill={chartColors.chartblue_2}
              />
              <Bar
                label={{ fill: "#111827" }}
                dataKey="kid"
                fill={chartColors.chartblue_3}
              />
            </BarChart>
          </div>
          <div className="grid grid-cols-7 mx-16 my-6 text-sm">
            <div className=" -rotate-45 transform-gpu text-center">1-7 day</div>
            <div className=" -rotate-45 transform-gpu text-center">
              8-14 day
            </div>
            <div className=" -rotate-45 transform-gpu text-center">
              15-30 day
            </div>
            <div className=" -rotate-45 transform-gpu text-center">
              31-90 day
            </div>
            <div className=" -rotate-45 transform-gpu text-center">
              91-180 day
            </div>
            <div className=" -rotate-45 transform-gpu text-center">
              181-365 day{" "}
            </div>
            <div className=" -rotate-45 transform-gpu text-center">
              {"> 365 day"}
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex items-center">
              <div className="box-content h-3 w-4 bg-chartblue_1"></div>
              <div className="text-chartblue_1 mx-2">Total</div>
            </div>
            <div className="flex items-center">
              <div className="box-content h-3 w-4 bg-chartblue_2"></div>
              <div className="text-chartblue_2 mx-2">Adult</div>
            </div>
            <div className="flex items-center">
              <div className="box-content h-3 w-4 bg-chartblue_3"></div>
              <div className="text-chartblue_3 mx-2">Children</div>
            </div>
          </div>
        </div>
      </div>

      <div className=" mx-10">
        <table className={TABLE}>
          <thead>
            <tr>
              <th className={LEFTTABLE}>รายการ</th>
              <th className={THTABLE}>ทั้งหมด</th>
              <th className={THTABLE}>ผู้ใหญ่</th>
              <th className={RIGHTTABLE}>เด็ก</th>
            </tr>
          </thead>
          <tbody>
            {table?.map((item, index) => (
              <tr key={index} className={TRTABLE}>
                <td className={TXLEFT}> {item?.name}</td>
                <td className={TXCENTER}>{displayValue(item?.data)}</td>
                <td className={TXCENTER}>{displayValue(item?.adult)}</td>
                <td className={TXCENTER}>{displayValue(item?.kid)} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const ReportReasonBystatus = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });

  const [data, setData] = React.useState({
    alive: 0,
    dead: 0,
    total: 0,
    alivePercent: 0,
    deadPercent: 0,
    checkStatusTask: [],
  });

  React.useEffect(() => {
    ReportCountReasonByStatatus({
      list,
      setValue: setData,
      year: String(year?.value),
      // type: String(type?.value),
    });
  }, [year]);

  const listTable = [
    { name: "Alive", count: data?.alive },
    { name: "Deceased", count: data?.dead },
  ];
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const count = payload?.[0]?.value || 0;

      const percentage = ((count * 100) / data.total).toFixed(1);

      return (
        <div className="bg-gray-50 text-gray-700 text-sm px-2 py-1 rounded">
          <p className="label">{`Left : ${count} (${percentage}%)`}</p>
        </div>
      );
    }

    return null;
  };

  const renderCustomLabel = (props) => {
    const { x, y, width, height, value } = props;
    const percentage = ((value / data?.total) * 100).toFixed(1) + "%";
    const insideBarPosition = y + height / 2;

    return (
      <g>
        <text
          x={x + width / 2}
          y={insideBarPosition}
          fill="#111827"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {`${value} (${percentage})`}
        </text>
      </g>
    );
  };

  console.log("ReportReason", data);
  return (
    <div className="">
      <div className={``}>
        <div className="my-20">
          <>
            <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>
                จำนวนสถานะผู้ป่วยที่ถอดสายแล้ว
                <div className={STYLEBODY}>ของปี "{year.label}"</div>
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownFollows year={year} setYear={setYear} />
                <ButtonExcel
                  text={"DOWNLOAD EXCEL"}
                  onClick={() => {
                    ExcelReasonByStatus(
                      formatReasonByStatus(data),
                      year,
                      setLoading
                    );
                  }}
                  loading={loading}
                />
              </div>
            </div>
            <div className={TABLE}>
              <BarChart
                width={750}
                height={300}
                data={listTable}
                margin={{
                  top: 20,
                  bottom: 2,
                }}
              >
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                {/* <Legend /> */}
                <Bar label={renderCustomLabel} dataKey="count" fill="#99CC99" />
              </BarChart>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

const ReportReason = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [month, setMonth] = React.useState({
    label: "ทุกเดือน",
    value: "ALL",
  });
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });

  const [data, setData] = React.useState({
    No_loanger_needed: 0,
    Death: 0,
    CLABSI: 0,
    Mechanical_problems: 0,
    Self_dislodged: 0,
    Dislodged_by_others: 0,
    Unresolved_occlusion: 0,
    Exit_site_infection: 0,
    other_reasons_for_discontinuation: 0,
    total: 0,
  });

  React.useEffect(() => {
    const total = ReportCountInfectionAttempt2({
      list,
      // setValue: setData,
      year: String(year?.value),
      type: "PICC",
      month: String(month?.value),
    });

    ReportCountReason({
      list,
      setValue: setData,
      year: String(year?.value),
      month: String(month?.value),
      // type: String(type?.value),
      total: total,
    });
  }, [year, month]);

  const listTable = [
    { name: "No longer needed", count: data?.No_loanger_needed },
    { name: "Death", count: data?.Death },
    { name: "CLABSI", count: data?.CLABSI },
    { name: "Mechanical problems", count: data?.Mechanical_problems },
    { name: "Self dislodged", count: data?.Self_dislodged },
    // { name: "Dislodged by others", count: data?.Dislodged_by_others },
    {
      name: "Unresolved occlusion",
      count: data?.Unresolved_occlusion,
    },
    { name: "Exit site infection", count: data?.Exit_site_infection },

    { name: "Other", count: data?.other_reasons_for_discontinuation },
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-gray-50 text-gray-700 text-sm px-2 py-1 rounded">
          {payload?.map((item, index) => {
            const count = item.value || 0;
            const percentage = ((count * 100) / data?.total).toFixed(1);
            return (
              <p key={index} className="label">
                {`count : ${count} (${percentage}%)`}
              </p>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const renderCustomLabel = (props) => {
    const { x, y, width, height, value } = props;
    const percentage = ((value / data?.total) * 100).toFixed(1) + "%";
    const insideBarPosition = y + height / 2;

    return (
      <g>
        <text
          x={x + width / 2}
          y={insideBarPosition}
          fill="#111827"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {`${value} (${percentage})`}
        </text>
      </g>
    );
  };

  return (
    <div className="">
      <div className={``}>
        <div className="my-10">
          <>
            <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>
                รายงานเหตุผลที่เลิกใช้สาย "{year.label}"
              </div>

              <div className={FLEXDROPDOWN}>
                <DropdownMonthYear
                  year={year}
                  setYear={setYear}
                  month={month}
                  setMonth={setMonth}
                />
                <ButtonExcel
                  onClick={() => {
                    ExcelReason(formatReason(data), year, setLoading);
                  }}
                  loading={loading}
                />
              </div>
            </div>
            <div>จำนวนสายทั้งหมด {data?.total}</div>
            <div className={BARCHART}>
              <BarChart
                width={750}
                height={300}
                data={listTable}
                margin={{
                  top: 20,
                  bottom: 2,
                }}
              >
                <CartesianGrid strokeDasharray="2 2" />
                {/* <XAxis dataKey="name" /> */}
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Bar label={renderCustomLabel} dataKey="count" fill="#FFCC66" />
              </BarChart>
            </div>
            <div className="grid grid-cols-8  ml-14 my-6 text-xs">
              <div className=" -rotate-45 transform-gpu text-center">
                No longer needed
              </div>
              <div className=" -rotate-45 transform-gpu text-center">Death</div>
              <div className=" -rotate-45 transform-gpu text-center">
                CLABSI
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Mechanical problems
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Dislodged
              </div>
              {/* <div className=" -rotate-45 transform-gpu text-center">
                Dislodged by others
              </div> */}
              <div className=" -rotate-45 transform-gpu text-center">
                Unresolved occlusion
              </div>
              <div className=" -rotate-45 transform-gpu text-center">
                Exit site infection
              </div>
              <div className=" -rotate-45 transform-gpu text-center">Other</div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

const ReportFollowVisit = ({ list, taskList }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [info, setInfo] = React.useState({
    level01: 0,
    level02: 0,
    level03: 0,
    level04: 0,
    all_level: 0,

    jan: {
      visit: 0,
      phone: 0,
    },
    feb: {
      visit: 0,
      phone: 0,
    },
    mar: {
      visit: 0,
      phone: 0,
    },
    apr: {
      visit: 0,
      phone: 0,
    },
    may: {
      visit: 0,
      phone: 0,
    },
    jun: {
      visit: 0,
      phone: 0,
    },
    jul: {
      visit: 0,
      phone: 0,
    },
    aug: {
      visit: 0,
      phone: 0,
    },
    sep: {
      visit: 0,
      phone: 0,
    },
    oct: {
      visit: 0,
      phone: 0,
    },
    nov: {
      visit: 0,
      phone: 0,
    },
    dec: {
      visit: 0,
      phone: 0,
    },
    //
  });

  const listTableVisit = [
    {
      name: "ม.ค.",
      visit: info?.jan?.visit,
      phone: info?.jan?.phone,
    },
    {
      name: "ก.พ.",
      visit: info?.feb?.visit,
      phone: info?.feb?.phone,
    },
    {
      name: "มี.ค.",
      visit: info?.mar?.visit,
      phone: info?.mar?.phone,
    },
    {
      name: "เม.ย.",
      visit: info?.apr?.visit,
      phone: info?.apr?.phone,
    },
    {
      name: "พ.ค.",
      visit: info?.may?.visit,
      phone: info?.may?.phone,
    },
    {
      name: "มิ.ย.",
      visit: info?.jun?.visit,
      phone: info?.jun?.phone,
    },
    {
      name: "ก.ค.",
      visit: info?.jul?.visit,
      phone: info?.jul?.phone,
    },
    {
      name: "ส.ค.",
      visit: info?.aug?.visit,
      phone: info?.aug?.phone,
    },
    {
      name: "ก.ย.",
      visit: info?.sep?.visit,
      phone: info?.sep?.phone,
    },
    {
      name: "ต.ค.",
      visit: info?.oct?.visit,
      phone: info?.oct?.phone,
    },
    {
      name: "พ.ย.",
      visit: info?.nov?.visit,
      phone: info?.nov?.phone,
    },
    {
      name: "ธ.ค.",
      visit: info?.dec?.visit,
      phone: info?.dec?.phone,
    },
  ];

  const listVistLimitday = [
    { name: "0-7 วัน", count: info?.level01 },
    { name: "8-14 วัน", count: info?.level02 },

    { name: "15-21 วัน", count: info?.level03 },

    { name: ">21 วัน", count: info?.level04 },
  ];

  React.useEffect(() => {
    ReportCountVisit({
      list,
      taskList,
      setValue: setInfo,
      year: String(year?.value),
    });
  }, [year]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const count = payload?.[0]?.value || 0;
      const percentage = ((count * 100) / info?.all_level).toFixed(1);

      return (
        <div className="bg-gray-50 text-gray-700 text-sm px-2 py-1 rounded">
          <p className="label">{` ${count} (${percentage}%)`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {/* 1 */}
      <div className="mt-20 mx-10 font-bold text-lg">
        รายงานจำนวนครั้งที่ผู้ป่วยได้รับการเยี่ยม
      </div>
      _____________________________________________________________________________________
      <div className="my-2">
        <>
          <div className={SCREENIPAD}>
            <div className={STYLEHEAD}>
              รายงานจำนวนครั้งที่ผู้ป่วยได้รับการเยี่ยม
              <div className={STYLEBODY}>
                แยกเป็นการโทร ฯ ติดตามหรือการเยี่ยมจริง "{year.label}"
              </div>
            </div>
            <div className={FLEXDROPDOWN}>
              <DropdownFollows year={year} setYear={setYear} />
              <ButtonExcel
                onClick={() => {
                  ExcelFollowVisit(formatFollowVisit(info), year, setLoading);
                }}
                loading={loading}
              />
            </div>
          </div>
          <div className={TABLE}>
            <BarChart
              width={700}
              height={300}
              data={listTableVisit}
              margin={{
                top: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />

              <Bar label={{ fill: "#111827" }} dataKey="visit" fill="#336600" />
              <Bar label={{ fill: "#111827" }} dataKey="phone" fill="#33CC33" />
            </BarChart>
          </div>
        </>
      </div>
      {/* 2 */}
      <div className="my-10">
        <div className={``}>
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div>
                <div className={STYLEHEAD}>
                  รายงานระยะห่างของการเยี่ยมแต่ละครั้ง
                  <div className={STYLEBODY}>
                    กรณีอายุสายน้อยกว่า 3 เดือน "{year.label}"
                  </div>
                </div>
              </div>

              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={TABLE}>
              <BarChart
                width={700}
                height={300}
                data={listVistLimitday}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />

                <Bar
                  dataKey="count"
                  fill="#339966"
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const ReportFollowLinePatency = ({ list, taskList }) => {
  // console.log("list", list);
  // console.log("taskList", taskList);
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });

  const [data, setData] = React.useState({
    okall: 0,
    wo: 0,
    co: 0,
    //
    count_co: 0,
    count_wo: 0,
    total_woco: 0,

    //

    wo_observe: 0,
    wo_rtpa: 0,
    //
    wo_result_open: 0,
    wo_result_wo: 0,
    wo_result_co: 0,
    //

    //

    co_observe: 0,
    co_rtpa: 0,
    //
    co_result_open: 0,
    co_result_wo: 0,
    co_result_co: 0,
    // หมวด สายติดเชื้อ

    infection_nil: 0,
    infection_bug_found: 0,
    // หมวด แขนขาบวม
    limb_edema_no: 0,
    limb_edema_yes: 0,
    //
    presence_of_dvt_no: 0,
    presence_of_dvt_yes: 0,
    presence_of_dvt_na: 0,
    //
    manag_observation: 0,
    manag_anticoagulation: 0,
    manag_catheter_removal: 0,
    // exit
    exit_site_ok: 0,
    other_exit_site: 0,
    exit_site_infection: 0,
    // line dis
    line_disledge_nil: 0,
    line_disledge_new_exit_mark_at: 0,
    complete_dislodge: 0,

    // สายรั่ว
    line_disledge_breakage_leak_port: 0,
    line_disledge_breakage_leak_port_no: 0,
    line_limb_ischemia_related_to_arterial_puncture: 0,
    line_nerve_injury_weakness_or_paresthesia: 0,

    //
    Arrow5Fr2lumenPICC: 0,
    Arrow4Fr1lumenPICC: 0,
    Bard5Fr2LumenpowerPICC: 0,
    Vygon3Fr1lumenPICC: 0,
    other_final_catheter_in: 0,
  });

  React.useEffect(() => {
    ReportCountPatency({
      list,
      setValue: setData,
      year: String(year?.value),
      taskList,
    });
  }, [year]);

  const listPatency = [
    {
      name: "patency",
      count: data?.okall,
    },
    {
      name: "withdrawal occlusion",
      count: data?.wo,
    },
    {
      name: "complete occlusion",
      count: data?.co,
    },
  ];

  const listCountPatency = [
    {
      name: "co",
      count: data?.count_co,
    },
    {
      name: "wo",
      count: data?.count_wo,
    },
  ];
  const listPatencyWO = [
    {
      name: "observe",
      count: data?.wo_observe,
    },
    {
      name: "rtPA x",
      count: data?.wo_rtpa,
    },
  ];
  const listPatencyResultWO = [
    {
      name: "open",
      count: data?.wo_result_open,
    },
    {
      name: "withdrawal occlusion",
      count: data?.wo_result_wo,
    },
    {
      name: "complete occlusion",
      count: data?.wo_result_co,
    },
  ];
  const listPatencyWoRtp = [
    {
      name: "WO + ได้รับ rtPA x",
      count: data?.wo_rtpa,
    },
  ];

  //
  const listPatencyCO = [
    {
      name: "observe",
      count: data?.co_observe,
    },
    {
      name: "rtPA x",
      count: data?.co_rtpa,
    },
  ];
  const listPatencyResultCO = [
    {
      name: "open",
      count: data?.co_result_open,
    },
    {
      name: "withdrawal occlusion",
      count: data?.co_result_wo,
    },
    {
      name: "complete occlusion",
      count: data?.co_result_co,
    },
  ];
  const listPatencyCoRtp = [
    {
      name: "CO + ได้รับ rtPA x",
      count: data?.co_rtpa,
    },
  ];
  // หมวด สายติดเชื้อ
  const listLineInfection = [
    {
      name: "None",
      count: data?.infection_nil,
    },
    {
      name: "Infection",
      count: data?.infection_bug_found,
    },
  ];
  // หมวด แขนขาบวม
  const listLineEdema = [
    {
      name: "None",
      count: data?.limb_edema_no,
    },
    {
      name: "limb edema",
      count: data?.limb_edema_yes,
    },
  ];
  const listLineEdemaYes = [
    {
      name: "No",
      count: data?.presence_of_dvt_no,
    },
    {
      name: "Yes",
      count: data?.presence_of_dvt_yes,
    },
    {
      name: "N/A",
      count: data?.presence_of_dvt_na,
    },
  ];
  const listLineEdemaManagement = [
    {
      name: "Observation",
      count: data?.manag_observation,
    },
    {
      name: "Anticoagulation",
      count: data?.manag_anticoagulation,
    },
    {
      name: "Catheter removal",
      count: data?.manag_catheter_removal,
    },
  ];
  // exit mark
  const listLineExit = [
    {
      name: "None",
      count: data?.exit_site_ok,
    },
    {
      name: "Other",
      count: data?.other_exit_site,
    },
    {
      name: "Exit site infection",
      count: data?.exit_site_infection,
    },
  ];
  // line dis
  const listLineDis = [
    {
      name: "None",
      count: data?.line_disledge_nil,
    },
    {
      name: "Displace",
      count: data?.line_disledge_new_exit_mark_at,
    },
    {
      name: "Dislodge",
      count: data?.complete_dislodge,
    },
  ];
  //  สายรั่ว breakage
  const listLineBreakage = [
    {
      name: "None",
      count: data?.line_disledge_breakage_leak_port_no,
    },
    {
      name: "Breakage/leak port",
      count: data?.line_disledge_breakage_leak_port,
    },
  ];
  const listOfSickness = [
    {
      name: "Limb ischemia",
      count: data?.line_limb_ischemia_related_to_arterial_puncture,
    },
    {
      name: "Nerve injury weakness",
      count: data?.line_nerve_injury_weakness_or_paresthesia,
    },
  ];
  //
  const listLineBreakByCat = [
    {
      name: "Arrow 5 Fr 2-lumen PICC",
      count: data?.Arrow5Fr2lumenPICC,
    },
    {
      name: "Arrow 4 Fr 1-lumen PICC",
      count: data?.Arrow4Fr1lumenPICC,
    },
    {
      name: "Bard 5 Fr 2-Lumen power PICC",
      count: data?.Bard5Fr2LumenpowerPICC,
    },
    {
      name: "Vygon 3 Fr 1-lumen PICC",
      count: data?.Vygon3Fr1lumenPICC,
    },
    {
      name: "Other",
      count: data?.other_final_catheter_in,
    },
  ];

  const CustomXAxisLabel = ({ x, y, payload }) => {
    const label = payload.value.replace(" ", "\n"); // Add newline character
    return (
      <text x={x} y={y} dy={16} textAnchor="middle">
        {label}
      </text>
    );
  };

  return (
    <div>
      {/* 1 */}
      <div className={``}>
        <div className="my-10">
          <>
            <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>
                รายงานจำนวนครั้งที่สายตัน "{year.label}"
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownFollows year={year} setYear={setYear} />
                <ButtonExcel
                  onClick={() => {
                    ExceltLinePatency(
                      formatLinePatency(data),
                      year,
                      setLoading
                    );
                  }}
                  loading={loading}
                />
              </div>
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listPatency}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#03a9f4"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 1.5 */}
      <div className={``}>
        <div className="my-10">
          <>
            <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>
                รายงานจำนวนสายที่ตัน "{year.label}"
              </div>
              {/* <div className={FLEXDROPDOWN}>
                <DropdownFollows year={year} setYear={setYear} />
                <ButtonExcel
                  onClick={() => {
                    ExceltLinePatency(
                      formatLinePatency(data),
                      year,
                      setLoading
                    );
                  }}
                  loading={loading}
                />
              </div> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listCountPatency}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#03a9f4"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 2 */}
      <div className={``}>
        <div className="my-10">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div>
                <div className={STYLEHEAD}>
                  การรักษาเมื่อสายตันแบบ WO "{year.label}"
                </div>
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listPatencyWO}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#4dd0e1"
                  minPointSize={5}
                  label={true}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 3 */}
      <div className={``}>
        <div className="my-20">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div>
                <div className={STYLEHEAD}>
                  ผลการรักษาเมื่อสายตันแบบ WO "{year.label}"
                </div>
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listPatencyResultWO}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#4dd0e1"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 4 */}
      <div className={``}>
        <div className="my-20">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div className={STYLEHEAD}>
                ผลการรักษาสายตันแบบ WO + ได้รับ rtPA x "{year.label}"
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listPatencyWoRtp}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#4dd0e1"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 5 */}
      <div className={``}>
        <div className="my-20">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div className={STYLEHEAD}>
                การรักษาเมื่อสายตันแบบ CO "{year.label}"
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listPatencyCO}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#42bd41"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 6 */}
      <div className={``}>
        <div className="my-20">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div className={STYLEHEAD}>
                ผลการรักษาเมื่อสายตันแบบ CO "{year.label}"
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listPatencyResultCO}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#42bd41"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 7 */}
      <div className={``}>
        <div className="my-20">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div className={STYLEHEAD}>
                ผลการรักษาสายตันแบบ CO + ได้รับ rtPA x "{year.label}"
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listPatencyCoRtp}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#42bd41"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 8 */}
      <div className="mx-10 font-bold text-xl">Line Infection</div>
      ________________________________________________________________________________________
      <div className={``}>
        <div className="my-2">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div>
                <div className={STYLEHEAD}>
                  รายงานจำนวนครั้งที่เกิดภาวะสายติดเชื้อ "{year.label}"
                </div>
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listLineInfection}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#FFA500"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 9 */}
      <div className="mt-20 mx-10 font-bold text-xl">
        ภาวะแทรกซ้อนจากการใส่สาย
      </div>
      ________________________________________________________________________________________
      <div className={``}>
        <div className="my-2">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div className={STYLEHEAD}>
                ภาวะแทรกซ้อนจากการใส่สาย "{year.label}"
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={`${BARCHART} relative`}>
              <BarChart
                width={700}
                height={300}
                data={listOfSickness}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#CD9B9B"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      <div className="mt-20 mx-10 font-bold text-xl">สายรั่ว</div>
      ________________________________________________________________________________________
      <div className={``}>
        <div className="my-2">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div className={STYLEHEAD}>
                รายงานจำนวนครั้งที่สายรั่ว สายแตก "{year.label}"
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={`${BARCHART} relative`}>
              <BarChart
                width={700}
                height={300}
                data={listLineBreakage}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  // wordSpacing={10}
                  // writingMode={"vertical-lr"}
                  // dx={15}
                  // dy={20}
                  // minTickGap={100}
                  // axisLine={true}
                  // tick={<CustomXAxisLabel />}
                  // interval={0}
                  // height={100}
                />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#CD9B9B"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 10 */}
      <div className={``}>
        <div className="my-10">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div className={STYLEHEAD}>
                รายงาน การเกิดภาวะสายรั่ว สายแตก สัมพันธ์กับชนิดของสาย "
                {year.label}"
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listLineBreakByCat}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                {/* <XAxis dataKey="name" /> */}
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#FFCCCC"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
            <div className="flex ml-16 text-base">
              <div className="mr-7 text-center">
                Arrow 5<div>Fr 2-lumen PICC</div>
              </div>
              <div className="mr-7 text-center">
                Arrow 4<div>Fr 1-lumen PICC</div>
              </div>
              <div className="mr-7 text-center">
                Bard 5<div>Fr 2-lumen</div>
                <div>power PICC</div>
              </div>
              <div className="mr-8 text-center">
                Vygon 3<div>Fr 1-lumen PICC</div>
              </div>
              <div className="mr-6">Other</div>
            </div>
          </>
        </div>
      </div>
      {/* 11 */}
      <div className="mt-20 mx-10 font-bold text-xl">แขนขาบวม</div>
      ________________________________________________________________________________________
      <div className={``}>
        <div className="my-2">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div>
                <div className={STYLEHEAD}>
                  รายงานการเกิดแขนขาบวม (Catheter-related limb edema) "
                  {year.label}"
                </div>
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listLineEdema}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#f5d0fe"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 12 */}
      <div className={``}>
        <div className="my-2">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div>
                <div className={STYLEHEAD}>
                  กรณีแขนขาบวม มี Presence of DVT หรือไม่ "{year.label}"
                </div>
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listLineEdemaYes}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#f0abfc"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 13 */}
      <div className={``}>
        <div className="my-10">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div>
                <div className={STYLEHEAD}>
                  การรักษาแขนขาบวม ( Management ) ของปี "{year.label}"
                </div>
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listLineEdemaManagement}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#fcd34d"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 14 */}
      <div className="mt-20 mx-10 font-bold text-xl">
        ภาวะ Exit site infection
      </div>
      ________________________________________________________________________________________
      <div className={``}>
        <div className="my-2">
          <>
            <div className={SCREENIPAD}>
              <div>
                <div className={STYLEHEAD}>
                  รายงานอุบัติการณ์การเกิดภาวะ exit site infection ของปี "
                  {year.label}"
                </div>
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listLineExit}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#a5b4fc"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
      {/* 15 */}
      <div className="mt-20 mx-10 font-bold text-xl">
        Line displacement / dislodged
      </div>
      ________________________________________________________________________________________
      <div className={``}>
        <div className="my-2">
          <>
            <div className={SCREENIPAD}>
              <> </>
              <div>
                <div className={STYLEHEAD}>
                  รายงาน Line displacement / dislodged ของปี "{year.label}"
                </div>
              </div>
              {/* <DropdownFollows year={year} setYear={setYear} /> */}
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listLineDis}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="count"
                  fill="#fda4af"
                  minPointSize={5}
                  label={{ fill: "#111827" }}
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

const ReportRating = ({ list }) => {
  const [loading, setLoading] = React.useState(false);
  const [year, setYear] = React.useState({
    label: "ทุกปี",
    value: "ALL",
  });
  const [data, setData] = React.useState({
    level01: {
      relative: 0,
      patient: 0,
    },
    level02: {
      relative: 0,
      patient: 0,
    },
    level03: {
      relative: 0,
      patient: 0,
    },
    level04: {
      relative: 0,
      patient: 0,
    },
    level05: {
      relative: 0,
      patient: 0,
    },
  });

  const listTableRating = [
    {
      name: "ระดับ 1",
      relative: data?.level01?.relative,
      healthcare_provider: data?.level01?.patient,
    },
    {
      name: "ระดับ 2",
      relative: data?.level02?.relative,
      healthcare_provider: data?.level02?.patient,
    },
    {
      name: "ระดับ 3",
      relative: data?.level03?.relative,
      healthcare_provider: data?.level03?.patient,
    },
    {
      name: "ระดับ 4",
      relative: data?.level04?.relative,
      healthcare_provider: data?.level04?.patient,
    },
    {
      name: "ระดับ 5",
      relative: data?.level05?.relative,
      healthcare_provider: data?.level05?.patient,
    },
  ];

  React.useEffect(() => {
    ReportCountRating({
      list,
      setValue: setData,
      year: String(year?.value),
    });
  }, [year]);

  return (
    <>
      {" "}
      <div className="mb-20">
        <div className="mt-20 mx-10 font-bold text-xl p-2">
          {" "}
          รายงานความพึงพอใจ{" "}
        </div>
        __________________________________________________________________________________
        <div className={``}>
          <>
            <div className={SCREENIPAD}>
              <div className={STYLEHEAD}>
                รายงานความพึงพอใจของญาติ และบุคลาการดูแลผู้ป่วย
                <div className={STYLEBODY}>
                  แยกตามระดับ (ระดับ 1 พอใจน้อยที่สุด , ระดับ 2 พอใจน้อย , ระดับ
                  3 พอใจปานกลาง ,
                  <div>
                    ระดับ 4 พอใจมาก , ระดับ 5 พอใจมากที่สุด ) ของปี "
                    {year.label}"
                  </div>
                </div>
              </div>
              <div className={FLEXDROPDOWN}>
                <DropdownFollows year={year} setYear={setYear} />
                <ButtonExcel
                  onClick={() => {
                    ExcelRating(formatRating(data), year, setLoading);
                  }}
                  loading={loading}
                />
              </div>
            </div>
            <div className={BARCHART}>
              <BarChart
                width={700}
                height={300}
                data={listTableRating}
                margin={{
                  top: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  label={{ fill: "#111827" }}
                  dataKey="relative"
                  fill="#d9f99d"
                />
                <Bar
                  label={{ fill: "#111827" }}
                  dataKey="healthcare_provider"
                  fill="#33CC33"
                />
              </BarChart>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

// main
const ReportMain = () => {
  const { reportLoading, setReportLoading } = userReportStore();

  const [list, setList] = useState([]);
  const [list2, setList2] = useState([]);
  const [listFollows, setListFollows] = useState([]);
  const [listAttempt, setListAttempt] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("");
  const [dataDashboard, setDataDashboard] = useState({});
  const [count, setCount] = useState(0);

  const { user } = useSelector((state) => state.auth);
  const [userDept, setUserDept] = useState(
    getUserDepartment(user?.user_department)
  );
  const [userSend, setUserSend] = useState(user?.user_department);
  const [isActivated, setIsActivated] = useState(null);

  // console.log("user?.user_department", user?.user_department);
  // console.log("userDept", userDept);
  // console.log("isActivated", isActivated);
  const isAN = user?.user_department === "super_AN";
  const isNurse =
    user?.user_department === "NURSE" ||
    user?.user_department === "AN" ||
    user?.user_department === "TR" ||
    user?.user_department === "IR" ||
    user?.user_department === "PED";

  const Style_tab =
    "cursor-pointer  w-fit px-4 py-2 text-center text-blue-400 rounded-full hover:bg-white text-blue-400 duration-200 h-auto text-sm";
  const Style_tab_active =
    "cursor-pointer  w-fit px-4 py-2 text-center text-blue-400 rounded-full bg-white text-blue-400 duration-200 h-auto text-sm";

  useEffect(() => {
    if (!isActivated) {
      if (user?.user_department || userDept) {
        const thisUserDept = userDept
          ? userDept
          : getUserDepartment(user?.user_department);
        setIsActivated(thisUserDept);
      }
    }
  }, [user?.user_department, userDept, isActivated]);

  useEffect(() => {
    if (user?.user_department) {
      const checkUser = getUserDepartment(user.user_department);
      setUserSend(checkUser);
      LoadDashboard(checkUser);
    }
  }, [user?.user_department]);

  const LoadDashboard = async (userDeptKey) => {
    const { data } = await api.get("/dashboard/status", {
      params: {
        user_department: userDeptKey,
      },
    });
    setDataDashboard(data?.data?.total);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && user?.user_department) {
      const thisUserDept = userDept
        ? userDept
        : getUserDepartment(user?.user_department);

      ReportLoadData({
        setList,
        setLoading,
        setListFollows,
        setCount,
        setListAttempt,
        userSend,
        userDept: thisUserDept,
        setReportLoading,
      });

      ReportLoadData2({
        setList2,
        setLoading,
        setListFollows,
        setCount,
        setListAttempt,
        userSend,
        userDept: thisUserDept,
      });
    }

    return () => {
      isMounted = false;
    };
  }, [user?.user_department, userDept]);

  const onChangeUserDept = (key) => {
    // setReportLoading(true);
    setUserDept(key);
    setIsActivated(key);
  };

  const percent =
    dataDashboard !== 0 && count !== 0
      ? Math.round((Number(count) / Number(dataDashboard)) * 100)
      : "";

  // return <div>Main Report</div>;

  if (loading) {
    return <Loading count={percent} />;
  } else {
    return (
      <>
        {isAN ? (
          <div className=" flex gap-3 justify-center items-center mb-10 mt-20">
            <button
              className={classNames(
                "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
                isActivated === "All"
                  ? "bg-blue-500 text-gray-900"
                  : "bg-blue-200"
              )}
              onClick={() => onChangeUserDept("All")}
            >
              All
            </button>
            <button
              className={classNames(
                "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
                isActivated === "AN"
                  ? "bg-blue-500 text-gray-900"
                  : "bg-blue-200"
              )}
              // onClick={() => {
              //   setUserDept("AN");
              //   setIsActivated("AN");
              // }}
              onClick={() => onChangeUserDept("AN")}
            >
              AN
            </button>
            <button
              className={classNames(
                "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
                isActivated === "IR"
                  ? "bg-blue-500 text-gray-900"
                  : "bg-blue-200"
              )}
              // onClick={() => {
              //   setUserDept("IR");
              //   setIsActivated("IR");
              // }}
              onClick={() => onChangeUserDept("IR")}
            >
              IR
            </button>
            <button
              className={classNames(
                "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
                isActivated === "TR"
                  ? "bg-blue-500 text-gray-900"
                  : "bg-blue-200"
              )}
              // onClick={() => {
              //   setUserDept("TR");
              //   setIsActivated("TR");
              // }}
              onClick={() => onChangeUserDept("TR")}
            >
              TR
            </button>
            <button
              className={classNames(
                "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
                isActivated === "PED"
                  ? "bg-blue-500 text-gray-900"
                  : "bg-blue-200"
              )}
              // onClick={() => {
              //   setUserDept("PED");
              //   setIsActivated("PED");
              // }}
              onClick={() => onChangeUserDept("PED")}
            >
              PED
            </button>
          </div>
        ) : null}

        {isNurse ? (
          `คุณไม่สามารถเข้าถึงหน้านี้ได้`
        ) : (
          <div className="flex gap-2 justify-center mt-10  mx-auto items-center bg-bgblue100 p-2 max-w-max rounded-full">
            <div
              className={`${tab === "Tab01" ? Style_tab_active : Style_tab}`}
              onClick={() => {
                setTab("Tab01");
              }}
            >
              ขั้นตอนการรับปรึกษา
            </div>
            <div
              className={`${tab === "Tab02" ? Style_tab_active : Style_tab}`}
              onClick={() => {
                setTab("Tab02");
              }}
            >
              Demographic ของผู้เข้ารับบริการ
            </div>
            <div
              className={`${tab === "Tab03" ? Style_tab_active : Style_tab}`}
              onClick={() => {
                setTab("Tab03");
              }}
            >
              กระบวนการใส่สาย
            </div>
            <div
              className={`${tab === "Tab04" ? Style_tab_active : Style_tab}`}
              onClick={() => {
                setTab("Tab04");
              }}
            >
              การติดตามผู้ป่วย
            </div>
          </div>
        )}
        {tab === "Tab01" && ( // ขั้นตอนการรับใบปรึกษา
          <div className="max-w-3xl mx-auto">
            {/* <Loading /> */}
            {reportLoading ? (
              <Loading />
            ) : (
              <>
                <ReportCountConsult list={list} />
                <ReportCountConsultPaper list={list} />
              </>
            )}
          </div>
        )}
        {tab === "Tab02" && ( // Demographic ของผู้เข้ารับบริการ
          <div className=" max-w-3xl mx-auto">
            {reportLoading ? (
              <Loading />
            ) : (
              <>
                <ReportWeight list={list} />
                <ReportGender list={list} />
                <ReportPatientType list={list} />
                <ReportAllCountCatheter list={list} />
                <ReportCountCatheter list={list} />
                <ReportCountLabelCatheter list={list} />
                {isAN ? (
                  <ReportCountAllDepartment list={list2} userDept={userDept} />
                ) : null}
                <ReportCountLabelCatheterType list={list} />
                <ReportIndications list={list} />
                <ReportTimePaperToCatheter list={list} />
                <ReportHeight list={list} />
              </>
            )}
          </div>
        )}

        {tab === "Tab03" && ( // กระบวนการใส่สาย
          <div className=" max-w-3xl mx-auto">
            {reportLoading ? (
              <Loading />
            ) : (
              <>
                <ReportTimeAttempt list={list} />
                <ReportCountSucAttempt list={list} />
                <ReportAttemptByDoctor list={list} />
                <ReportFirstAttempt list={list} />
                <ReportAverageTime list={list} />
                <ReportSideCat list={list} />
                <ReportConfirmation list={list} />
                <ReportAttemptInfection list={list} />
              </>
            )}
          </div>
        )}

        {tab === "Tab04" && ( // การติดตามผู้ป่วย
          <div className=" max-w-3xl mx-auto">
            {reportLoading ? (
              <Loading />
            ) : (
              <>
                <ReportTimeTotalDay list={list} />
                <ReportReasonBystatus list={list} />
                <ReportReason list={list} />
                <ReportFollowVisit list={listFollows} taskList={list} />
                <ReportFollowLinePatency list={listFollows} taskList={list} />
                <ReportRating list={listFollows} />
              </>
            )}
          </div>
        )}
      </>
    );
  }
};

export default ReportMain;
