export const STYLEHEAD = "text-black text-lg font-medium "
export const STYLEBODY = "text-gray-500 text-sm font-regular"
export const SCREENIPAD = "md:flex gap-4 justify-between"
export const FLEXDROPDOWN = "flex items-end gap-2"
export const BARCHART ="mx-auto w-full mt-4"

export const TABLE ="w-full mt-5"
export const THTABLE ="bg-bgblue100 p-2 text-sm"
export const TRTABLE ="border-b hover:bg-chartbg duration-200"
export const LEFTTABLE = "border-separate rounded-tl-lg p-4 bg-bgblue100 text-sm"
export const RIGHTTABLE = "border-separate rounded-tr-xl p-4 bg-bgblue100 text-sm"
export const TXLEFT ="text-left p-4 text-sm"
export const TXCENTER ="text-center p-4 text-sm"

// export const TABLE ="w-full mt-5"
// export const THTABLE ="bg-bgblue100 p-4"
// export const TRTABLE ="border-b hover:bg-chartbg duration-200"
// export const LEFTTABLE = "border-separate rounded-tl-lg bg-bgblue100 p-4"
// export const RIGHTTABLE = "border-separate rounded-tr-xl bg-bgblue100 p-4"
// export const TXLEFT ="text-left p-4"