const FunctionCheckType = (catheter, other_catheter_name, check = "") => {
  if (catheter && catheter === "other_catheter") {
    const checkOtherPICC = String(other_catheter_name)
      .toUpperCase()
      .search("PICC");
    const checkOtherDLC = String(other_catheter_name)
      .toUpperCase()
      .search("DLC");
    const checkOtherCVL = String(other_catheter_name)
      .toUpperCase()
      .search("CVL");

    if (check && check === "picc") {
      return "PICC";
    } else if (check && check === "central") {
      return "CENTRAL_LINE";
    } else if (checkOtherPICC !== -1) {
      return "PICC";
    } else if (checkOtherDLC !== -1) {
      return "CENTRAL_LINE";
    } else if (checkOtherCVL !== -1) {
      return "CENTRAL_LINE";
    }
  } else if (catheter && catheter !== "other_catheter") {
    const checkPICC = String(catheter).toUpperCase().search("PICC");

    if (checkPICC !== -1) {
      return "PICC";
    } else if (checkPICC === -1) {
      return "CENTRAL_LINE";
    }
  }

  // อันเก่า

  // if (catheter && catheter !== "other_catheter") {
  //   const checkPICC = String(catheter).toUpperCase().search("PICC");

  //   if (checkPICC !== -1) {
  //     return "PICC";
  //   } else if (checkPICC === -1) {
  //     return "CENTRAL_LINE";
  //   }
  // } else if (catheter && catheter === "other_catheter") {
  //   const checkOtherPICC = String(other_catheter_name)
  //     .toUpperCase()
  //     .search("PICC");
  //   const checkOtherDLC = String(other_catheter_name)
  //     .toUpperCase()
  //     .search("DLC");
  //   const checkOtherCVL = String(other_catheter_name)
  //     .toUpperCase()
  //     .search("CVL");

  //   if (checkOtherPICC !== -1) {
  //     return "PICC";
  //   } else if (checkOtherDLC !== -1) {
  //     return "CENTRAL_LINE";
  //   } else if (checkOtherCVL !== -1) {
  //     return "CENTRAL_LINE";
  //   }
  // }
};

export default FunctionCheckType;
