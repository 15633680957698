import ExcelExport from "export-xlsx";
const XLSX = require("xlsx");

export const ExportCountDepartment = (submitExcelData) => {
  const wb = XLSX.utils.book_new();
  const ws_data = [
    ["จำนวน PICC Line ที่ใส่ในแต่ละหน่วย"],
    [
      "",
      "ANESTHESIA",
      "INTERVENTIONAL RADIOLOGY",
      "TRAUMA",
      "PEDIATRICS",
      "TOTAL",
    ],
  ];
  Object.keys(submitExcelData).forEach((key) => {
    ws_data.push([
      key,
      submitExcelData[key].an,
      submitExcelData[key].ir,
      submitExcelData[key].tr,
      submitExcelData[key].ped,
      submitExcelData[key].total,
    ]);
  });

  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  XLSX.writeFile(wb, "sheetjs.xlsx");
};

// export const ExportCountDepartment = async (
//   inputdata,
//   startYear,
//   endYear,
//   startMonth,
//   endMonth,
//   setLoading
// ) => {
//   // const inputdata = { data: [{ test: 5 }] };
//   const SETTINGS_FOR_EXPORT = {
//     fileName: "ALiST_REPORT_PAPER",
//     workSheets: [
//       {
//         sheetName: "Report",
//         startingRowNumber: 1,
//         gapBetweenTwoTables: 6,

//         tableSettings: {
//           data: {
//             headerGroups: [
//               //กลุ่มที่ 1
//               {
//                 name: `จำนวน PICC Line ที่ใส่ในแต่ละหน่วยตั้งแต่ปี${startYear?.label} เดือน${startMonth?.label} ถึง  ปี${endYear?.label} เดือน${endMonth?.label} `,
//                 key: "header_01",
//               },
//               {
//                 name: `ANESTHESIA`,
//                 groupKey: "header_01",
//                 key: "an",
//               },
//               {
//                 name: `INTERVENTIONAL RADIOLOGY`,
//                 groupKey: "header_01",
//                 key: "ir",
//               },
//               {
//                 name: `TRAUMA`,
//                 groupKey: "header_01",
//                 key: "tr",
//               },
//               {
//                 name: `PEDIATRICS`,
//                 groupKey: "header_01",
//                 key: "ped",
//               },
//               {
//                 name: `Total`,
//                 groupKey: "header_01",
//                 key: "total",
//               },
//             ],

//             headerDefinition: [
//               {
//                 name: "Adult",
//                 key: "an_adult",
//                 groupKey: "an",
//                 width: 7,
//               },
//               {
//                 name: "Child",
//                 key: "an_kid",
//                 groupKey: "an",
//                 width: 7,
//               },
//               {
//                 name: "Total",
//                 key: "an_total",
//                 groupKey: "an",
//                 width: 7,
//               },
//             ],
//           },
//         },
//       },
//     ],
//   };
//   try {
//     setLoading(true);
//     const excelExport = new ExcelExport();
//     await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
//   } catch (error) {
//     console.log("error", error);
//   } finally {
//     setLoading(false);
//   }
// };
