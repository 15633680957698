import { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../attribute/Button";
import FormInfo from "../form/FormInfo";
import api from "../../api";
import Loading from "../loading/Loading";
import { ageCalculator, getAge } from "../functions/Functions";

const Full = ({ nav, setField, form, id, onClickSave, isNurse }) => {
  const router = useHistory();
  const [loading, setLoading] = useState(false);

  // const onClickSubmit = async () => {
  //   setLoading(true);
  //   try {
  //     await api.put(`/tasks/${form?._id}`, {
  //       age: form?.dob ? ageCalculator(new Date(form?.dob)) : 0,
  //       cad: form?.cad,
  //       chemotherapy: form?.chemotherapy,
  //       diagnosis: form?.diagnosis,
  //       dm: form?.dm,
  //       dob: form?.dob,
  //       dyslipidemia: form?.dyslipidemia,
  //       ecg: form?.ecg,
  //       esrd: form?.esrd,
  //       frequent_blood_sampling: form?.frequent_blood_sampling,
  //       full_name: form?.full_name,
  //       last_name: form?.last_name,
  //       height: form?.height,
  //       hn: form?.hn,
  //       htn: form?.htn,
  //       involved: form?.involved,
  //       mailignancy: form?.mailignancy,
  //       other_disease: form?.other_disease,
  //       other_disease_name: form?.other_disease_name,
  //       other_ecg_name: form?.other_ecg_name,
  //       other_indication: form?.other_indication,
  //       other_indication_name: form?.other_indication_name,
  //       patient_phone_number: form?.patient_phone_number,
  //       patient_type: form?.patient_type,
  //       other_patient_type_name: form?.other_patient_type_name,
  //       prolonged_iv_meds: form?.prolonged_iv_meds,
  //       status: "waiting_full",
  //       tpn: form?.tpn,
  //       vasopressor: form?.vasopressor,
  //       ward: form?.ward,
  //       other_ward_name: form?.other_ward_name,
  //       weight: form?.weight,
  //       more_patient: form?.more_patient,
  //       phone_number: form?.phone_number,
  //     });
  //   } catch (error) {
  //     console.error("ERROR TO SUBMIT INITAL : ", error);
  //   } finally {
  //     setLoading(false);
  //     router.push("/");
  //   }
  // };

  // const onClickCancel = async () => {
  //   setLoading(true);
  //   try {
  //     await api.put(`/tasks/${form?._id}`, {
  //       age: form?.dob ? ageCalculator(new Date(form?.dob)) : 0,
  //       cad: form?.cad,
  //       chemotherapy: form?.chemotherapy,
  //       diagnosis: form?.diagnosis,
  //       dm: form?.dm,
  //       dob: form?.dob,
  //       dyslipidemia: form?.dyslipidemia,
  //       ecg: form?.ecg,
  //       esrd: form?.esrd,
  //       frequent_blood_sampling: form?.frequent_blood_sampling,
  //       full_name: form?.full_name,
  //       last_name: form?.last_name,
  //       height: form?.height,
  //       hn: form?.hn,
  //       htn: form?.htn,
  //       involved: form?.involved,
  //       mailignancy: form?.mailignancy,
  //       other_disease: form?.other_disease,
  //       other_disease_name: form?.other_disease_name,
  //       other_ecg_name: form?.other_ecg_name,
  //       other_indication: form?.other_indication,
  //       other_indication_name: form?.other_indication_name,
  //       patient_phone_number: form?.patient_phone_number,
  //       patient_type: form?.patient_type,
  //       other_patient_type_name: form?.other_patient_type_name,
  //       prolonged_iv_meds: form?.prolonged_iv_meds,
  //       status: "cancelled",
  //       tpn: form?.tpn,
  //       vasopressor: form?.vasopressor,
  //       ward: form?.ward,
  //       weight: form?.weight,
  //       other_ward_name: form?.other_ward_name,
  //       more_patient: form?.more_patient,
  //       phone_number: form?.phone_number,
  //     });
  //   } catch (error) {
  //     console.error("ERROR TO SUBMIT CANCEL FULL : ", error);
  //   } finally {
  //     setLoading(false);
  //     router.push("/");
  //   }
  // };
  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  return (
    <div>
      <FormInfo nav={nav} setField={setField} form={form} />
      {isNurse ? null : (
        <Button
          // disabled={form?.status !== "waiting"}
          onClickSave={() =>
            // onClickSave("FULL DATABASE", form?.status !== "waiting")
            onClickSave("FULL DATABASE", form?.status !== "waiting")
          }
          // onClickCancel={() => onClickSave("CANCEL")}
          onClickCancel={() => router.push("/")}
        />
      )}
    </div>
  );
};
export default Full;
