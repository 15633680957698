import { ageCalculator, ThaiDate } from "../functions/Functions";
//im

const HeadProcedure = ({ form }) => {
  // console.log(form, "form");
  const FormatDOB = (date) => {
    if (date) {
      return ThaiDate(new Date(date));
    } else {
      return "N/A";
    }
  };

  const formatWard = () => {};
  const infoProcedure = [
    {
      name: "HN",
      info: form?.hn,
      textcolor: "text-bgblue",
    },
    {
      name: "ชื่อ-นามสกุล",
      info: form?.full_name + " " + form?.last_name,
      textcolor: "text-bgblue",
    },
    {
      name: "เพศ",
      info: form?.gender ? form?.gender : "-",
      textcolor: "text-bgblue",
    },

    {
      name: "DOB",
      info: FormatDOB(form?.dob),
      // info: form?.dob ? ThaiDate(new Date(form?.dob)) : "N/A",
      textcolor: " text-bgblue",
    },
    {
      name: "อายุ ",
      // info: form?.dob ? ageCalculator(new Date(form?.dob)) : 0,
      info: form?.dob
        ? ageCalculator(
            new Date(form?.dob),
            form._id ? new Date(form.createdAt) : new Date()
          )
        : form?.age,
      textcolor: " text-bgblue",
    },
    {
      name: "หอผู้ป่วย",
      info: form?.ward?.value
        ? form?.ward?.value
        : form?.other_ward_name
        ? form?.other_ward_name
        : "-",
      textcolor: " text-bgblue",
    },
    {
      name: "เบอร์โทร",
      info: form?.ward?.phone
        ? form?.ward?.phone
        : form?.phone_number
        ? form?.phone_number
        : "-",
      textcolor: " text-bgblue",
    },
  ];

  const StyleInfoProcedure = ({ name = "", info, textcolor = "" }) => {
    return (
      <div className="px-1 ">
        <div className=" grid grid-cols-3">
          <div
            className={
              " md:text-sm text-base font-normal text-textinput col-span-1  "
            }
          >
            {name}
          </div>
          <div className="col-span-2">
            <div className={" md:text-l text-base font-bold " + [textcolor]}>
              {info ? info : "-"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className=" grid xl:grid-cols-1 xl:gap-5 px-10 ">
        {infoProcedure?.map((infoProcedure, index) => {
          return (
            <div>
              <div key={index}>
                <StyleInfoProcedure
                  name={infoProcedure.name}
                  info={infoProcedure.info}
                  textcolor={infoProcedure.textcolor}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const HeadInfo = ({ form }) => {
  return (
    <div className=" ">
      <HeadProcedure form={form} />
    </div>
  );
};

export default HeadInfo;
