import _ from "lodash";
import { CheckPICCLine } from "../../../components/functions/Functions";

const ReportCountPatency = ({ year, setValue, list, taskList }) => {
  let okall = 0;
  let wo = 0;
  let co = 0;
  //

  //
  let wo_observe = 0;
  let wo_rtpa = 0;
  //
  let wo_result_open = 0;
  let wo_result_wo = 0;
  let wo_result_co = 0;

  //
  let co_observe = 0;
  let co_rtpa = 0;
  //
  let co_result_open = 0;
  let co_result_wo = 0;
  let co_result_co = 0;
  //
  let infection_nil = 0;
  let infection_bug_found = 0;
  //
  let limb_edema_no = 0;
  let limb_edema_yes = 0;
  //
  let presence_of_dvt_no = 0;
  let presence_of_dvt_yes = 0;
  let presence_of_dvt_na = 0;
  //
  let manag_observation = 0;
  let manag_anticoagulation = 0;
  let manag_catheter_removal = 0;
  //
  let exit_site_ok = 0;
  let other_exit_site = 0;
  let exit_site_infection = 0;
  //
  let line_disledge_nil = 0;
  let line_disledge_new_exit_mark_at = 0;
  let complete_dislodge = 0;
  //
  let line_disledge_breakage_leak_port_no = 0;
  let line_disledge_breakage_leak_port = 0;
  let line_limb_ischemia_related_to_arterial_puncture = 0;
  let line_nerve_injury_weakness_or_paresthesia = 0;
  //

  let Arrow5Fr2lumenPICC = 0;
  let Arrow4Fr1lumenPICC = 0;
  let Bard5Fr2LumenpowerPICC = 0;
  let Vygon3Fr1lumenPICC = 0;
  let other_final_catheter_in = 0;

  // const groupByPort = _.groupBy(
  //   list,
  //   (o) => o?.line_wo_port + "-" + o?.line_co_port
  // );

  // const countCO1 = groupByPort?.["true-true"]?.length;
  // const countCO2 = groupByPort?.["false-true"]?.length;
  // const countCO = countCO1 + countCO2;
  // const countWO = groupByPort?.["true-false"]?.length;
  // const totalCOWO = countCO1 + countCO2 + countWO;

  const groupByPort = (year, list) => {
    // Filter the list based on the year
    const filteredList = list.filter((o) => {
      const thisYear = o?.createdAt.substring(0, 4);
      return year === "ALL" || year === thisYear;
    });

    // Group by line_wo_port and line_co_port
    return _.groupBy(
      filteredList,
      (o) => o?.line_wo_port + "-" + o?.line_co_port
    );
  };

  const performGrouping = (year, list) => {
    const grouped = groupByPort(year, list);

    const countCO1 = grouped?.["true-true"]?.length;
    const countCO2 = grouped?.["false-true"]?.length;
    const countCO = countCO1 + countCO2;
    const countWO = grouped?.["true-false"]?.length;
    const totalCOWO = countCO1 + countCO2 + countWO;

    return { countCO, countWO, totalCOWO };
  };

  // Usage
  const { countCO, countWO, totalCOWO } = performGrouping(year, list);

  if (list?.length > 0) {
    for (let row of list) {
      const thisYear = row?.createdAt;
      const useYear = thisYear.substring(0, 4);
      if (year === "ALL") {
        // 3.1 สายตัน

        if (row?.line_ok_all) {
          okall++;
        }
        if (row?.line_wo_port) {
          wo++;
        }
        if (row?.line_co_port) {
          co++;
        }
        // 3.2.1
        if (
          (row?.line_wo_port && row?.line_wo_observe) ||
          (row?.line_wo_port &&
            row?.line_wo_observe === false &&
            row?.line_wo_rtpax_name === false)
        ) {
          wo_observe++;
        }
        if (row?.line_wo_port && row?.line_wo_rtpax_name) {
          wo_rtpa++;
        }
        // 3.2.2

        if (row?.line_wo_port && row?.line_wo_result_opened) {
          wo_result_open++;
        }

        if (row?.line_wo_port && row?.line_wo_result_wo) {
          wo_result_wo++;
        }
        if (row?.line_wo_port && row?.line_wo_result_co) {
          wo_result_co++;
        }

        //3.3.1
        if (
          (row?.line_co_port && row?.line_co_observe) ||
          (row?.line_co_port &&
            row?.line_co_observe === false &&
            row?.line_co_rtpax_name === false)
        ) {
          co_observe++;
        }
        if (row?.line_co_port && row?.line_co_rtpax_name) {
          co_rtpa++;
        }
        //  3.3.2

        if (row?.line_co_port && row?.line_co_result_opened) {
          co_result_open++;
        }

        if (row?.line_co_port && row?.line_co_result_co) {
          co_result_wo++;
        }
        if (row?.line_co_port && row?.line_co_result_co) {
          co_result_co++;
        }
        // Line infection สายติดเชื้อ

        if (row?.line_infection_nil) {
          infection_nil++;
        }
        if (row?.line_infection_bug_found) {
          infection_bug_found++;
        }
        // แขนขาบวม
        if (row?.catheter_related_limb_edema_no) {
          limb_edema_no++;
        }
        if (row?.catheter_related_limb_edema_yes) {
          limb_edema_yes++;
        }
        // เกิดแขนขาบวม
        if (row?.presence_of_dvt_no) {
          presence_of_dvt_no++;
        }
        if (row?.presence_of_dvt_yes) {
          presence_of_dvt_yes++;
        }
        if (row?.presence_of_dvt_na) {
          presence_of_dvt_na++;
        }
        // รักษาแขนขาบวม
        if (row?.management_observation) {
          manag_observation++;
        }
        if (row?.management_anticoagulation) {
          manag_anticoagulation++;
        }
        if (row?.management_catheter_removal) {
          manag_catheter_removal++;
        }
        // exit
        if (row?.exit_site_ok) {
          exit_site_ok++;
        }
        if (row?.other_exit_site) {
          other_exit_site++;
        }
        if (row?.exit_site_infection) {
          exit_site_infection++;
        }
        // line dis
        if (row?.line_disledge_nil) {
          line_disledge_nil++;
        }
        if (row?.line_disledge_new_exit_mark_at) {
          line_disledge_new_exit_mark_at++;
        }
        if (row?.complete_dislodge) {
          complete_dislodge++;
        }
        // สายรั่ว
        if (row?.line_disledge_breakage_leak_port) {
          line_disledge_breakage_leak_port++;
        }
        if (!row?.line_disledge_breakage_leak_port) {
          line_disledge_breakage_leak_port_no++;
        }
        if (row?.line_limb_ischemia_related_to_arterial_puncture) {
          line_limb_ischemia_related_to_arterial_puncture++;
        }
        if (row?.line_nerve_injury_weakness_or_paresthesia) {
          line_nerve_injury_weakness_or_paresthesia++;
        }
      }

      if (year === useYear) {
        // 3.1 สายตัน
        if (row?.line_ok_all) {
          okall++;
        }
        if (row?.line_wo_port) {
          wo++;
        }
        if (row?.line_co_port) {
          co++;
        }
        // 3.2.1
        if (
          (row?.line_wo_port && row?.line_wo_observe) ||
          (row?.line_wo_port &&
            row?.line_wo_observe === false &&
            row?.line_wo_rtpax_name === false)
        ) {
          wo_observe++;
        }
        if (row?.line_wo_port && row?.line_wo_rtpax_name) {
          wo_rtpa++;
        }
        // 3.2.2

        if (row?.line_wo_port && row?.line_wo_result_opened) {
          wo_result_open++;
        }

        if (row?.line_wo_port && row?.line_wo_result_wo) {
          wo_result_wo++;
        }
        if (row?.line_wo_port && row?.line_wo_result_co) {
          wo_result_co++;
        }

        //3.3.1
        if (
          (row?.line_co_port && row?.line_co_observe) ||
          (row?.line_co_port &&
            row?.line_co_observe === false &&
            row?.line_co_rtpax_name === false)
        ) {
          co_observe++;
        }
        if (row?.line_co_port && row?.line_co_rtpax_name) {
          co_rtpa++;
        }
        //  3.3.2

        if (row?.line_co_port && row?.line_co_result_opened) {
          co_result_open++;
        }

        if (row?.line_co_port && row?.line_co_result_co) {
          co_result_wo++;
        }
        if (row?.line_co_port && row?.line_co_result_co) {
          co_result_co++;
        }
        // Line infection สายติดเชื้อ

        if (row?.line_infection_nil) {
          infection_nil++;
        }
        if (row?.line_infection_bug_found) {
          infection_bug_found++;
        }
        // แขนขาบวม
        if (row?.catheter_related_limb_edema_no) {
          limb_edema_no++;
        }
        if (row?.catheter_related_limb_edema_yes) {
          limb_edema_yes++;
        }
        // เกิดแขนขาบวม
        if (row?.presence_of_dvt_no) {
          presence_of_dvt_no++;
        }
        if (row?.presence_of_dvt_yes) {
          presence_of_dvt_yes++;
        }
        if (row?.presence_of_dvt_na) {
          presence_of_dvt_na++;
        }
        // รักษา แขนขาบวม
        if (row?.management_observation) {
          manag_observation++;
        }
        if (row?.management_anticoagulation) {
          manag_anticoagulation++;
        }
        if (row?.management_catheter_removal) {
          manag_catheter_removal++;
        }
        // exit
        if (row?.exit_site_ok) {
          exit_site_ok++;
        }
        if (row?.other_exit_site) {
          other_exit_site++;
        }
        if (row?.exit_site_infection) {
          exit_site_infection++;
        }
        // line dis
        if (row?.line_disledge_nil) {
          line_disledge_nil++;
        }
        if (row?.line_disledge_new_exit_mark_at) {
          line_disledge_new_exit_mark_at++;
        }
        if (row?.complete_dislodge) {
          complete_dislodge++;
        }

        // สายรั่ว
        if (row?.line_disledge_breakage_leak_port) {
          line_disledge_breakage_leak_port++;
        }
        if (!row?.line_disledge_breakage_leak_port) {
          line_disledge_breakage_leak_port_no++;
        }
      }
      // if (year === "2023" && useYear === "2023") {
      //   // 3.1 สายตัน
      //   if (row?.line_ok_all) {
      //     okall++;
      //   }
      //   if (row?.line_wo_port) {
      //     wo++;
      //   }
      //   if (row?.line_co_port) {
      //     co++;
      //   }
      //   // 3.2.1
      //   if (
      //     (row?.line_wo_port && row?.line_wo_observe) ||
      //     (row?.line_wo_port &&
      //       row?.line_wo_observe === false &&
      //       row?.line_wo_rtpax_name === false)
      //   ) {
      //     wo_observe++;
      //   }
      //   if (row?.line_wo_port && row?.line_wo_rtpax_name) {
      //     wo_rtpa++;
      //   }
      //   // 3.2.2

      //   if (row?.line_wo_port && row?.line_wo_result_opened) {
      //     wo_result_open++;
      //   }

      //   if (row?.line_wo_port && row?.line_wo_result_wo) {
      //     wo_result_wo++;
      //   }
      //   if (row?.line_wo_port && row?.line_wo_result_co) {
      //     wo_result_co++;
      //   }

      //   //3.3.1
      //   if (
      //     (row?.line_co_port && row?.line_co_observe) ||
      //     (row?.line_co_port &&
      //       row?.line_co_observe === false &&
      //       row?.line_co_rtpax_name === false)
      //   ) {
      //     co_observe++;
      //   }
      //   if (row?.line_co_port && row?.line_co_rtpax_name) {
      //     co_rtpa++;
      //   }
      //   //  3.3.2

      //   if (row?.line_co_port && row?.line_co_result_opened) {
      //     co_result_open++;
      //   }

      //   if (row?.line_co_port && row?.line_co_result_co) {
      //     co_result_wo++;
      //   }
      //   if (row?.line_co_port && row?.line_co_result_co) {
      //     co_result_co++;
      //   }
      //   // Line infection สายติดเชื้อ

      //   if (row?.line_infection_nil) {
      //     infection_nil++;
      //   }
      //   if (row?.line_infection_bug_found) {
      //     infection_bug_found++;
      //   }
      //   // แขนขาบวม
      //   if (row?.catheter_related_limb_edema_no) {
      //     limb_edema_no++;
      //   }
      //   if (row?.catheter_related_limb_edema_yes) {
      //     limb_edema_yes++;
      //   }
      //   // เกิดแขนขาบวม
      //   if (row?.presence_of_dvt_no) {
      //     presence_of_dvt_no++;
      //   }
      //   if (row?.presence_of_dvt_yes) {
      //     presence_of_dvt_yes++;
      //   }
      //   if (row?.presence_of_dvt_na) {
      //     presence_of_dvt_na++;
      //   }
      //   // รักษา แขนขาบวม
      //   if (row?.management_observation) {
      //     manag_observation++;
      //   }
      //   if (row?.management_anticoagulation) {
      //     manag_anticoagulation++;
      //   }
      //   if (row?.management_catheter_removal) {
      //     manag_catheter_removal++;
      //   }
      //   // exit
      //   if (row?.exit_site_ok) {
      //     exit_site_ok++;
      //   }
      //   if (row?.other_exit_site) {
      //     other_exit_site++;
      //   }
      //   if (row?.exit_site_infection) {
      //     exit_site_infection++;
      //   }
      //   // line dis
      //   if (row?.line_disledge_nil) {
      //     line_disledge_nil++;
      //   }
      //   if (row?.line_disledge_new_exit_mark_at) {
      //     line_disledge_new_exit_mark_at++;
      //   }
      //   if (row?.complete_dislodge) {
      //     complete_dislodge++;
      //   }
      //   // สายรั่ว
      //   if (row?.line_disledge_breakage_leak_port) {
      //     line_disledge_breakage_leak_port++;
      //   }
      //   if (!row?.line_disledge_breakage_leak_port) {
      //     line_disledge_breakage_leak_port_no++;
      //   }
      // }
    }

    for (const taskItem of taskList) {
      const follows = taskItem?.follows;

      for (const follow of follows) {
        const thisYear = taskItem?.createdAt;
        const useYear = thisYear.substring(0, 4);

        if (year === "ALL") {
          if (
            taskItem?.catheter?.value === "Arrow5Fr2lumenPICC" &&
            follow?.line_disledge_breakage_leak_port
          ) {
            Arrow5Fr2lumenPICC++;
          }
          if (
            taskItem?.catheter?.value === "Arrow4Fr1lumenPICC" &&
            follow?.line_disledge_breakage_leak_port
          ) {
            Arrow4Fr1lumenPICC++;
          }
          if (
            taskItem?.catheter?.value === "Bard5Fr2LumenpowerPICC" &&
            follow?.line_disledge_breakage_leak_port
          ) {
            Bard5Fr2LumenpowerPICC++;
          }
          if (
            taskItem?.catheter?.value === "Vygon3Fr1lumenPICC " &&
            follow?.line_disledge_breakage_leak_port
          ) {
            Vygon3Fr1lumenPICC++;
          }
          if (
            taskItem?.catheter?.value === "other_catheter " &&
            CheckPICCLine(
              taskItem?.other_final_catheter_in,
              taskItem?.other_final_catheter_in
            ) &&
            follow?.line_disledge_breakage_leak_port
          ) {
            other_final_catheter_in++;
          }
        }
        if (year === useYear) {
          if (
            taskItem?.catheter?.value === "Arrow5Fr2lumenPICC" &&
            follow?.line_disledge_breakage_leak_port
          ) {
            Arrow5Fr2lumenPICC++;
          }
          if (
            taskItem?.catheter?.value === "Arrow4Fr1lumenPICC" &&
            follow?.line_disledge_breakage_leak_port
          ) {
            Arrow4Fr1lumenPICC++;
          }
          if (
            taskItem?.catheter?.value === "Bard5Fr2LumenpowerPICC" &&
            follow?.line_disledge_breakage_leak_port
          ) {
            Bard5Fr2LumenpowerPICC++;
          }
          if (
            taskItem?.catheter?.value === "Vygon3Fr1lumenPICC " &&
            follow?.line_disledge_breakage_leak_port
          ) {
            Vygon3Fr1lumenPICC++;
          }
          if (
            taskItem?.catheter?.value === "other_catheter " &&
            CheckPICCLine(
              taskItem?.other_final_catheter_in,
              taskItem?.other_final_catheter_in
            ) &&
            follow?.line_disledge_breakage_leak_port
          ) {
            other_final_catheter_in++;
          }
        }
        // if (year === "2023" && useYear === "2023") {
        //   if (
        //     taskItem?.catheter?.value === "Arrow5Fr2lumenPICC" &&
        //     follow?.line_disledge_breakage_leak_port
        //   ) {
        //     Arrow5Fr2lumenPICC++;
        //   }
        //   if (
        //     taskItem?.catheter?.value === "Arrow4Fr1lumenPICC" &&
        //     follow?.line_disledge_breakage_leak_port
        //   ) {
        //     Arrow4Fr1lumenPICC++;
        //   }
        //   if (
        //     taskItem?.catheter?.value === "Bard5Fr2LumenpowerPICC" &&
        //     follow?.line_disledge_breakage_leak_port
        //   ) {
        //     Bard5Fr2LumenpowerPICC++;
        //   }
        //   if (
        //     taskItem?.catheter?.value === "Vygon3Fr1lumenPICC " &&
        //     follow?.line_disledge_breakage_leak_port
        //   ) {
        //     Vygon3Fr1lumenPICC++;
        //   }
        //   if (
        //     taskItem?.catheter?.value === "other_catheter " &&
        //     CheckPICCLine(
        //       taskItem?.other_final_catheter_in,
        //       taskItem?.other_final_catheter_in
        //     ) &&
        //     follow?.line_disledge_breakage_leak_port
        //   ) {
        //     other_final_catheter_in++;
        //   }
        // }
      }
    }

    setValue({
      okall: okall,
      wo: wo,
      co: co,
      //
      count_co: countCO,
      count_wo: countWO,
      total_woco: totalCOWO,
      //
      wo_observe: wo_observe,
      wo_rtpa: wo_rtpa,
      //
      wo_result_open: wo_result_open,
      wo_result_wo: wo_result_wo,
      wo_result_co: wo_result_co,
      //
      co_observe: co_observe,
      co_rtpa: co_rtpa,
      //
      co_result_open: co_result_open,
      co_result_wo: co_result_wo,
      co_result_co: co_result_co,
      infection_nil: infection_nil,
      infection_bug_found: infection_bug_found,
      //
      limb_edema_no: limb_edema_no,
      limb_edema_yes: limb_edema_yes,
      //
      presence_of_dvt_no: presence_of_dvt_no,
      presence_of_dvt_yes: presence_of_dvt_yes,
      presence_of_dvt_na: presence_of_dvt_na,
      //
      manag_observation: manag_observation,
      manag_anticoagulation: manag_anticoagulation,
      manag_catheter_removal: manag_catheter_removal,
      //
      exit_site_ok: exit_site_ok,
      other_exit_site: other_exit_site,
      exit_site_infection: exit_site_infection,
      //
      line_disledge_nil: line_disledge_nil,
      line_disledge_new_exit_mark_at: line_disledge_new_exit_mark_at,
      complete_dislodge: complete_dislodge,
      // สายรั่ว
      line_disledge_breakage_leak_port: line_disledge_breakage_leak_port,
      line_disledge_breakage_leak_port_no: line_disledge_breakage_leak_port_no,
      line_limb_ischemia_related_to_arterial_puncture:
        line_limb_ischemia_related_to_arterial_puncture,
      line_nerve_injury_weakness_or_paresthesia:
        line_nerve_injury_weakness_or_paresthesia,
      //
      Arrow5Fr2lumenPICC: Arrow5Fr2lumenPICC,
      Arrow4Fr1lumenPICC: Arrow4Fr1lumenPICC,
      Bard5Fr2LumenpowerPICC: Bard5Fr2LumenpowerPICC,
      Vygon3Fr1lumenPICC: Vygon3Fr1lumenPICC,
      other_final_catheter_in: other_final_catheter_in,
    });
  }
};

export default ReportCountPatency;
