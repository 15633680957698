import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountIndications = ({ list, setValue, type, year }) => {
  let tpn = 0; // 01
  let prolonged_iv_meds = 0; // 02
  let chemotherapy = 0; // 03
  let vasopressor = 0; // 04
  let frequent_blood_sampling = 0; // 05
  let stem_cell_collection_plasma_exchange = 0; // 06
  let stem_cell_transplantation = 0; // 07
  let other_indication = 0;

  if (list?.length > 0) {
    for (let row of list) {
      if (row?.final_date_success != "") {
        const month = row?.final_date_success ? row?.final_date_success : "";
        // const thisMonth = String(month);
        // const useMont = thisMonth.substring(5, 7);

        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);

        if (year === "ALL") {
          if (type === "NONE") {
            if (row?.tpn) {
              tpn++;
            }
            if (row?.prolonged_iv_meds) {
              prolonged_iv_meds++;
            }
            if (row?.chemotherapy) {
              chemotherapy++;
            }
            if (row?.vasopressor) {
              vasopressor++;
            }
            if (row?.frequent_blood_sampling) {
              frequent_blood_sampling++;
            }
            if (row?.stem_cell_collection_plasma_exchange) {
              stem_cell_collection_plasma_exchange++;
            }
            if (row?.stem_cell_transplantation) {
              stem_cell_transplantation++;
            }
            if (row?.other_indication) {
              other_indication++;
            }
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (row?.tpn) {
              tpn++;
            }
            if (row?.prolonged_iv_meds) {
              prolonged_iv_meds++;
            }
            if (row?.chemotherapy) {
              chemotherapy++;
            }
            if (row?.vasopressor) {
              vasopressor++;
            }
            if (row?.frequent_blood_sampling) {
              frequent_blood_sampling++;
            }
            if (row?.stem_cell_collection_plasma_exchange) {
              stem_cell_collection_plasma_exchange++;
            }
            if (row?.stem_cell_transplantation) {
              stem_cell_transplantation++;
            }
            if (row?.other_indication) {
              other_indication++;
            }
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            if (row?.tpn) {
              tpn++;
            }
            if (row?.prolonged_iv_meds) {
              prolonged_iv_meds++;
            }
            if (row?.chemotherapy) {
              chemotherapy++;
            }
            if (row?.vasopressor) {
              vasopressor++;
            }
            if (row?.frequent_blood_sampling) {
              frequent_blood_sampling++;
            }
            if (row?.stem_cell_collection_plasma_exchange) {
              stem_cell_collection_plasma_exchange++;
            }
            if (row?.stem_cell_transplantation) {
              stem_cell_transplantation++;
            }
            if (row?.other_indication) {
              other_indication++;
            }
          }
        }
        if (year === useYear) {
          if (type === "NONE") {
            if (row?.tpn) {
              tpn++;
            }
            if (row?.prolonged_iv_meds) {
              prolonged_iv_meds++;
            }
            if (row?.chemotherapy) {
              chemotherapy++;
            }
            if (row?.vasopressor) {
              vasopressor++;
            }
            if (row?.frequent_blood_sampling) {
              frequent_blood_sampling++;
            }
            if (row?.stem_cell_collection_plasma_exchange) {
              stem_cell_collection_plasma_exchange++;
            }
            if (row?.stem_cell_transplantation) {
              stem_cell_transplantation++;
            }
            if (row?.other_indication) {
              other_indication++;
            }
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (row?.tpn) {
              tpn++;
            }
            if (row?.prolonged_iv_meds) {
              prolonged_iv_meds++;
            }
            if (row?.chemotherapy) {
              chemotherapy++;
            }
            if (row?.vasopressor) {
              vasopressor++;
            }
            if (row?.frequent_blood_sampling) {
              frequent_blood_sampling++;
            }
            if (row?.stem_cell_collection_plasma_exchange) {
              stem_cell_collection_plasma_exchange++;
            }
            if (row?.stem_cell_transplantation) {
              stem_cell_transplantation++;
            }
            if (row?.other_indication) {
              other_indication++;
            }
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            if (row?.tpn) {
              tpn++;
            }
            if (row?.prolonged_iv_meds) {
              prolonged_iv_meds++;
            }
            if (row?.chemotherapy) {
              chemotherapy++;
            }
            if (row?.vasopressor) {
              vasopressor++;
            }
            if (row?.frequent_blood_sampling) {
              frequent_blood_sampling++;
            }
            if (row?.stem_cell_collection_plasma_exchange) {
              stem_cell_collection_plasma_exchange++;
            }
            if (row?.stem_cell_transplantation) {
              stem_cell_transplantation++;
            }
            if (row?.other_indication) {
              other_indication++;
            }
          }
        }
        // if (year === "2023" && useYear === "2023") {
        //   if (type === "NONE") {
        //     if (row?.tpn) {
        //       tpn++;
        //     }
        //     if (row?.prolonged_iv_meds) {
        //       prolonged_iv_meds++;
        //     }
        //     if (row?.chemotherapy) {
        //       chemotherapy++;
        //     }
        //     if (row?.vasopressor) {
        //       vasopressor++;
        //     }
        //     if (row?.frequent_blood_sampling) {
        //       frequent_blood_sampling++;
        //     }
        //     if (row?.stem_cell_collection_plasma_exchange) {
        //       stem_cell_collection_plasma_exchange++;
        //     }
        //     if (row?.stem_cell_transplantation) {
        //       stem_cell_transplantation++;
        //     }
        //     if (row?.other_indication) {
        //       other_indication++;
        //     }
        //   }
        //   if (
        //     type === "PICC" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     if (row?.tpn) {
        //       tpn++;
        //     }
        //     if (row?.prolonged_iv_meds) {
        //       prolonged_iv_meds++;
        //     }
        //     if (row?.chemotherapy) {
        //       chemotherapy++;
        //     }
        //     if (row?.vasopressor) {
        //       vasopressor++;
        //     }
        //     if (row?.frequent_blood_sampling) {
        //       frequent_blood_sampling++;
        //     }
        //     if (row?.stem_cell_collection_plasma_exchange) {
        //       stem_cell_collection_plasma_exchange++;
        //     }
        //     if (row?.stem_cell_transplantation) {
        //       stem_cell_transplantation++;
        //     }
        //     if (row?.other_indication) {
        //       other_indication++;
        //     }
        //   }
        //   if (
        //     type === "CENTRAL_LINE" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     if (row?.tpn) {
        //       tpn++;
        //     }
        //     if (row?.prolonged_iv_meds) {
        //       prolonged_iv_meds++;
        //     }
        //     if (row?.chemotherapy) {
        //       chemotherapy++;
        //     }
        //     if (row?.vasopressor) {
        //       vasopressor++;
        //     }
        //     if (row?.frequent_blood_sampling) {
        //       frequent_blood_sampling++;
        //     }
        //     if (row?.stem_cell_collection_plasma_exchange) {
        //       stem_cell_collection_plasma_exchange++;
        //     }
        //     if (row?.stem_cell_transplantation) {
        //       stem_cell_transplantation++;
        //     }
        //     if (row?.other_indication) {
        //       other_indication++;
        //     }
        //   }
        // }
      }
    }
    setValue({
      tpn: tpn, // 01
      prolonged_iv_meds: prolonged_iv_meds, // 02
      chemotherapy: chemotherapy, // 03
      vasopressor: vasopressor, // 04
      frequent_blood_sampling: frequent_blood_sampling, // 05
      stem_cell_collection_plasma_exchange:
        stem_cell_collection_plasma_exchange, // 06
      stem_cell_transplantation: stem_cell_transplantation, // 07
      other_indication: other_indication, //08
    });
  }
};

export default ReportCountIndications;
