import ExcelExport from "export-xlsx";

export const ExceltCatPICCCEN = async (inputdata, year, setLoading) => {
  // const inputdata = { data: [{ test: 5 }] };
  const SETTINGS_FOR_EXPORT = {
    fileName: "ALiST_REPORT_PAPER",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `นับชนิดของสายน้ำเกลือที่ใส่แต่ละปี แยกตามเดือนของปี${year?.label}    `,
                key: "header_01",
              },
              {
                name: `มกราคม`,
                groupKey: "header_01",
                key: "month_01",
              },
              {
                name: `กุมภาพันธ์`,
                groupKey: "header_01",
                key: "month_02",
              },
              {
                name: `มีนาคม`,
                groupKey: "header_01",
                key: "month_03",
              },
              {
                name: `เมษายน`,
                groupKey: "header_01",
                key: "month_04",
              },
              {
                name: `พฤษภาคม`,
                groupKey: "header_01",
                key: "month_05",
              },
              {
                name: `มิถุนายน`,
                groupKey: "header_01",
                key: "month_06",
              },
              {
                name: `กรกฎาคม`,
                groupKey: "header_01",
                key: "month_07",
              },
              {
                name: `สิงหาคม`,
                groupKey: "header_01",
                key: "month_08",
              },
              {
                name: `กันยายน`,
                groupKey: "header_01",
                key: "month_09",
              },
              {
                name: `ตุลาคม`,
                groupKey: "header_01",
                key: "month_10",
              },
              {
                name: `พฤศจิกายน`,
                groupKey: "header_01",
                key: "month_11",
              },
              {
                name: `ธันวาคม`,
                groupKey: "header_01",
                key: "month_12",
              },
              {
                name: `รวม`,
                groupKey: "header_01",
                key: "total",
              },
            ],

            headerDefinition: [
              {
                name: "PICC line",
                key: "jan_picc",
                groupKey: "month_01",
                width: 7,
              },
              {
                name: "Central Line",
                key: "jan_central",
                groupKey: "month_01",
                width: 7,
              },
              {
                name: "PICC line",
                key: "feb_picc",
                groupKey: "month_02",
                width: 7,
              },
              {
                name: "Central Line",
                key: "feb_central",
                groupKey: "month_02",
                width: 7,
              },
              {
                name: "PICC line",
                key: "mar_picc",
                groupKey: "month_03",
                width: 7,
              },
              {
                name: "Central Line",
                key: "mar_central",
                groupKey: "month_03",
                width: 7,
              },
              {
                name: "PICC line",
                key: "apr_picc",
                groupKey: "month_04",
                width: 7,
              },
              {
                name: "Central Line",
                key: "apr_central",
                groupKey: "month_04",
                width: 7,
              },
              {
                name: "PICC line",
                key: "may_picc",
                groupKey: "month_05",
                width: 7,
              },
              {
                name: "Central Line",
                key: "may_central",
                groupKey: "month_05",
                width: 7,
              },
              {
                name: "PICC line",
                key: "jun_picc",
                groupKey: "month_06",
                width: 7,
              },
              {
                name: "Central Line",
                key: "jun_central",
                groupKey: "month_06",
                width: 7,
              },
              {
                name: "PICC line",
                key: "jul_picc",
                groupKey: "month_07",
                width: 7,
              },
              {
                name: "Central Line",
                key: "jul_central",
                groupKey: "month_07",
                width: 7,
              },
              {
                name: "PICC line",
                key: "aug_picc",
                groupKey: "month_08",
                width: 7,
              },
              {
                name: "Central Line",
                key: "aug_central",
                groupKey: "month_08",
                width: 7,
              },
              {
                name: "PICC line",
                key: "sep_picc",
                groupKey: "month_09",
                width: 7,
              },
              {
                name: "Central Line",
                key: "sep_central",
                groupKey: "month_09",
                width: 7,
              },
              {
                name: "PICC line",
                key: "oct_picc",
                groupKey: "month_10",
                width: 7,
              },
              {
                name: "Central Line",
                key: "oct_central",
                groupKey: "month_10",
                width: 7,
              },
              {
                name: "PICC line",
                key: "nov_picc",
                groupKey: "month_11",
                width: 7,
              },
              {
                name: "Central Line",
                key: "nov_central",
                groupKey: "month_11",
                width: 7,
              },
              {
                name: "PICC line",
                key: "dec_picc",
                groupKey: "month_12",
                width: 7,
              },
              {
                name: "Central Line",
                key: "dec_central",
                groupKey: "month_12",
                width: 7,
              },
              {
                name: "PICC line",
                key: "sum_picc",
                groupKey: "total",
                width: 7,
              },
              {
                name: "Central Line",
                key: "sum_central",
                groupKey: "total",
                width: 7,
              },
            ],
          },
        },
      },
    ],
  };
  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
