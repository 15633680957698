import { Storage } from "aws-amplify";
import { format } from "date-fns";
import React from "react";
import Resizer from "react-image-file-resizer";

export const toReactSelect = (data, keyLabel, keyValue) =>
  data.map((item) => ({
    value: item[keyValue ?? "_id"],
    label: item[keyLabel],
  }));

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1080,
      1920,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const filterRole = (listName, role) => {
  const filterListName = listName?.filter((item) => {
    return item?.role?.value === role;
  });

  const sortListName = filterListName.sort((a, b) => {
    return a.value > b.value ? 1 : -1;
  });

  return sortListName;
};

export const AddUrlToFile = (file) => {
  if (!file) return;
  if (file) {
    const src = URL.createObjectURL(file);
    return src;
  }
};

export const ConvertHoursToMins = (timeInHour) => {
  if (!timeInHour) return;
  var timeParts = timeInHour?.split(":");
  return Number(timeParts[0]) * 60 + Number(timeParts[1]);
};
export const ThaiDate = (date) => {
  const d = new Date(date);

  const month = new Array();

  month[0] = "มกราคม";
  month[1] = "กุมภาพันธ์";
  month[2] = "มีนาคม";
  month[3] = "เมษายน";
  month[4] = "พฤษภาคม";
  month[5] = "มิถุนายน";
  month[6] = "กรกฎาคม";
  month[7] = "สิงหาคม";
  month[8] = "กันยายน";
  month[9] = "ตุลาคม";
  month[10] = "พฤศจิกายน";
  month[11] = "ธันวาคม";

  const dd = d.getDate();
  const mm = month[d.getMonth()];
  const yyyy = d.getFullYear() + 543;

  return `${dd} ${mm} ${yyyy}`;
};

export const ThaiDigiDate = (date) => {
  const d = new Date(date);

  const month = new Array();

  month[0] = "01";
  month[1] = "02";
  month[2] = "03";
  month[3] = "04";
  month[4] = "05";
  month[5] = "06";
  month[6] = "07";
  month[7] = "08";
  month[8] = "09";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";

  const dd = d.getDate();
  const mm = month[d.getMonth()];
  const yyyy = d.getFullYear() + 543;
  const yy = String(yyyy).substring(2, 4);

  return `${dd}/${mm}/${yy}`;
};

export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  const age = today.getFullYear() - birthDate.getFullYear();
  var m = Math.abs(Number(today.getMonth() - birthDate.getMonth()));
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return { year: age, month: m };
};

export function ageCalculator(input, thatdate = null) {
  //collect input from HTML form and convert into date format
  var dob = new Date(input);

  //check user provide input or not
  if (!input) return 0;
  //execute if the user entered a date
  else {
    //extract the year, month, and date from user date input
    const dobYear = dob?.getYear();
    const dobMonth = dob?.getMonth();
    const dobDate = dob?.getDate();

    //get the current date from the system
    const now = thatdate;
    //extract the year, month, and date from current date
    let currentYear = now?.getYear();
    let currentMonth = now?.getMonth();
    let currentDate = now?.getDate();

    //declare a let iable to collect the age in year, month, and days
    let age = {};
    let ageString = "";

    // //get years
    let yearAge = currentYear - dobYear;

    let monthAge = "";
    let dateAge = "";

    //get months
    if (currentMonth >= dobMonth) {
      //get months when current month is greater
      monthAge = currentMonth - dobMonth;
    } else {
      yearAge--;
      monthAge = 12 + currentMonth - dobMonth;
    }

    // get days
    if (currentDate >= dobDate)
      //get days when the current date is greater
      dateAge = currentDate - dobDate;
    else {
      monthAge--;
      dateAge = 31 + currentDate - dobDate;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
    //group the age in a single variable
    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge,
    };

    if (age.years > 0 && age.months > 0 && age.days > 0)
      ageString =
        age.years + " ปี " + age.months + " เดือน " + age.days + " วัน ";
    else if (age.years == 0 && age.months == 0 && age.days > 0)
      ageString = age.days + " วัน ";
    //when current month and date is same as birth date and month
    else if (age.years > 0 && age.months == 0 && age.days == 0)
      ageString = age.years + " ปี";
    else if (age.years > 0 && age.months > 0 && age.days == 0)
      ageString = age.years + " ปี " + age.months + " เดือน ";
    else if (age.years == 0 && age.months > 0 && age.days > 0)
      ageString = age.months + " เดือน " + age.days + " วัน ";
    else if (age.years > 0 && age.months == 0 && age.days > 0)
      ageString = age.years + " ปี " + age.days + " วัน ";
    else if (age.years == 0 && age.months > 0 && age.days == 0)
      ageString = age.months + " เดือน ";
    //when current date is same as dob(date of birth)
    else ageString = "0";

    // display the calculated age
    return ageString;

    // if (age.years > 0 && age.months > 0 && age.days > 0)
    //   ageString =
    //     age.years + " years " + age.months + " monts " + age.days + " days ";
    // else if (age.years == 0 && age.months == 0 && age.days > 0)
    //   ageString = "Only!" + age.days + " days old!";
    // //when current month and date is same as birth date and month
    // else if (age.years > 0 && age.months == 0 && age.days == 0)
    //   ageString = age.years + " years old. Happy Birthday!!";
    // else if (age.years > 0 && age.months > 0 && age.days == 0)
    //   ageString = age.years + " years and " + age.months + " months old.";
    // else if (age.years == 0 && age.months > 0 && age.days > 0)
    //   ageString = age.months + " months and " + age.days + " days old.";
    // else if (age.years > 0 && age.months == 0 && age.days > 0)
    //   ageString = age.years + " years, and" + age.days + " days old.";
    // else if (age.years == 0 && age.months > 0 && age.days == 0)
    //   ageString = age.months + " months old.";
    // //when current date is same as dob(date of birth)
    // else ageString = "Welcome to Earth! <br> It's first day on Earth!";

    // // display the calculated age
    // return ageString;
  }
}

export function ageDayCalculator(input, thatdate = null) {
  //collect input from HTML form and convert into date format
  var dob = new Date(input);

  //check user provide input or not
  if (!input) return 0;
  //execute if the user entered a date
  else {
    //extract the year, month, and date from user date input
    const dobYear = dob.getYear();
    const dobMonth = dob.getMonth();
    const dobDate = dob.getDate();

    //get the current date from the system
    const now = thatdate;
    //extract the year, month, and date from current date
    let currentYear = now?.getYear();
    let currentMonth = now?.getMonth();
    let currentDate = now?.getDate();

    //declare a let iable to collect the age in year, month, and days
    let age = {};
    let ageString = "";

    // //get years
    let yearAge = currentYear - dobYear;

    let monthAge = "";
    let dateAge = "";

    //get months
    if (currentMonth >= dobMonth) {
      //get months when current month is greater
      monthAge = currentMonth - dobMonth;
    } else {
      yearAge--;
      monthAge = 12 + currentMonth - dobMonth;
    }

    // get days
    if (currentDate >= dobDate)
      //get days when the current date is greater
      dateAge = currentDate - dobDate;
    else {
      monthAge--;
      dateAge = 31 + currentDate - dobDate;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
    //group the age in a single variable
    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge,
    };

    if (age.years > 0 && age.months > 0 && age.days > 0)
      ageString =
        Number(age.years) * 365 + Number(age.months * 30) + Number(age.days);
    else if (age.years == 0 && age.months == 0 && age.days > 0)
      ageString = Number(age.days);
    //when current month and date is same as birth date and month
    else if (age.years > 0 && age.months == 0 && age.days == 0)
      ageString = Number(age.years) * 365;
    else if (age.years > 0 && age.months > 0 && age.days == 0)
      ageString = Number(age.years) * 365 + Number(age.months) * 30;
    else if (age.years == 0 && age.months > 0 && age.days > 0)
      ageString = Number(age.months) * 30 + Number(age.days);
    else if (age.years > 0 && age.months == 0 && age.days > 0)
      ageString = Number(age.years) * 365 + Number(age.days);
    else if (age.years == 0 && age.months > 0 && age.days == 0)
      ageString = Number(age.months) * 30;
    //when current date is same as dob(date of birth)
    else ageString = "0";

    // display the calculated age
    return ageString;

    // if (age.years > 0 && age.months > 0 && age.days > 0)
    //   ageString =
    //     age.years + " years " + age.months + " monts " + age.days + " days ";
    // else if (age.years == 0 && age.months == 0 && age.days > 0)
    //   ageString = "Only!" + age.days + " days old!";
    // //when current month and date is same as birth date and month
    // else if (age.years > 0 && age.months == 0 && age.days == 0)
    //   ageString = age.years + " years old. Happy Birthday!!";
    // else if (age.years > 0 && age.months > 0 && age.days == 0)
    //   ageString = age.years + " years and " + age.months + " months old.";
    // else if (age.years == 0 && age.months > 0 && age.days > 0)
    //   ageString = age.months + " months and " + age.days + " days old.";
    // else if (age.years > 0 && age.months == 0 && age.days > 0)
    //   ageString = age.years + " years, and" + age.days + " days old.";
    // else if (age.years == 0 && age.months > 0 && age.days == 0)
    //   ageString = age.months + " months old.";
    // //when current date is same as dob(date of birth)
    // else ageString = "Welcome to Earth! <br> It's first day on Earth!";

    // // display the calculated age
    // return ageString;
  }
}
export function ageYearCalculator(input, thatdate = null) {
  //collect input from HTML form and convert into date format
  var dob = new Date(input);

  //check user provide input or not
  if (!input) return 0;
  //execute if the user entered a date
  else {
    //extract the year, month, and date from user date input
    const dobYear = dob.getYear();
    const dobMonth = dob.getMonth();
    const dobDate = dob.getDate();

    //get the current date from the system
    const now = thatdate;
    //extract the year, month, and date from current date
    let currentYear = now?.getYear();
    let currentMonth = now?.getMonth();
    let currentDate = now?.getDate();

    //declare a let iable to collect the age in year, month, and days
    let age = {};
    let ageString = "";

    // //get years
    let yearAge = currentYear - dobYear;

    let monthAge = "";
    let dateAge = "";

    //get months
    if (currentMonth >= dobMonth) {
      //get months when current month is greater
      monthAge = currentMonth - dobMonth;
    } else {
      yearAge--;
      monthAge = 12 + currentMonth - dobMonth;
    }

    // get days
    if (currentDate >= dobDate)
      //get days when the current date is greater
      dateAge = currentDate - dobDate;
    else {
      monthAge--;
      dateAge = 31 + currentDate - dobDate;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
    //group the age in a single variable
    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge,
    };

    if (age.years > 0 && age.months > 0 && age.days > 0) ageString = age.years;
    else if (age.years == 0 && age.months == 0 && age.days > 0) ageString = 0;
    //when current month and date is same as birth date and month
    else if (age.years > 0 && age.months == 0 && age.days == 0)
      ageString = age.years;
    else if (age.years > 0 && age.months > 0 && age.days == 0)
      ageString = age.years;
    else if (age.years == 0 && age.months > 0 && age.days > 0) ageString = 0;
    else if (age.years > 0 && age.months == 0 && age.days > 0)
      ageString = age.years;
    else if (age.years == 0 && age.months > 0 && age.days == 0) ageString = 0;
    else ageString = 0;

    return ageString;
  }
}

export const formatdate = (dateString) => {
  const parts = dateString.split("/"); // Split the date string into day, month, and year components
  const day = parts[0];
  const month = parts[1];
  const year = parts[2];
  const useYear = Number(year) - 543;

  const formattedDate = `${useYear}-${month.padStart(2, "0")}-${day.padStart(
    2,
    "0"
  )}`;

  return formattedDate;
};

export function calculateHours(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const timeDiff = Math.abs(end - start);
  const hours = Math.floor(timeDiff / (1000 * 60 * 60)); // milliseconds to hours
  return hours;
}

export const UploadFileAws = async (file) => {
  const old_image = String(Number(file?.slice(0, 13)))?.length === 13;
  if (old_image) {
    return;
  } else if (file) {
    const keyUpload = `${new Date().getTime()}_${file?.name}`;
    await Storage.put(keyUpload, file);
    return keyUpload;
  } else {
    return;
  }
};

export const getPathUrl = (input) => {
  // const url = "https://d38r8805x4obq7.cloudfront.net/public/";
  const url = "https://d2efimkeo98b2f.cloudfront.net/public/";
  return url + input;
};

//คำนวณเป็น วินาที ชั่วโมง วัน ตาม excel
export const timeDifference = (current, previous) => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay;
  var msPerYear = msPerDay;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours";
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + " ";
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + " ";
  } else {
    return Math.round(elapsed / msPerYear) + " ";
  }
};
//คำนวณเป็น วินาที ชั่วโมง วัน ตาม excel ใช้กับกราฟ

export const timeDifferenceTotal = (current, previous) => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay;
  var msPerYear = msPerDay;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return 0;
  } else if (elapsed < msPerHour) {
    return 0;
  } else if (elapsed < msPerDay) {
    return 0;
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay);
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth);
  } else {
    return Math.round(elapsed / msPerYear);
  }
};
export const timeDifferenceFull = (current, previous) => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours";
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + " days";
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + " months";
  } else {
    return Math.round(elapsed / msPerYear) + " year";
  }
};

export const timeDiffHourandMinutes = (before, after) => {
  // 2023-01-04T15:33:00.000Z	 to 2023-01-06T10:20:00.000Z
  const timestamp1 = new Date(before).getTime();
  const timestamp2 = new Date(after).getTime();

  const millisecondsDiff = Math.abs(timestamp2 - timestamp1);
  const hours = Math.floor(millisecondsDiff / (1000 * 60 * 60));
  const minutes = Math.floor((millisecondsDiff / (1000 * 60)) % 60);
  return `${hours}  ชั่วโมง  ${minutes}  นาที`;
};

export const timeDiffHour = (before, after) => {
  // 2023-01-04T15:33:00.000Z	 to 2023-01-06T10:20:00.000Z
  const timestamp1 = new Date(before).getTime();
  const timestamp2 = new Date(after).getTime();

  const millisecondsDiff = Math.abs(timestamp2 - timestamp1);
  const hours = Math.floor(millisecondsDiff / (1000 * 60 * 60));

  return `${hours}`;
};
// คำนวณเป็น วินาที นาที ชั่วโมง วัน เดือน ปี
// export const timeDifference2 = (current, previous) => {
//   var msPerMinute = 60 * 1000;
//   var msPerHour = msPerMinute * 60;
//   var msPerDay = msPerHour * 24;
//   var msPerMonth = msPerDay * 30;
//   var msPerYear = msPerDay * 365;

//   var elapsed = current - previous;

//   if (elapsed < msPerMinute) {
//     return Math.round(elapsed / 1000) + " seconds";
//   } else if (elapsed < msPerHour) {
//     return Math.round(elapsed / msPerMinute) + " minutes";
//   } else if (elapsed < msPerDay) {
//     return Math.round(elapsed / msPerHour) + " hours";
//   } else if (elapsed <msPerMonth) {
//     return Math.round(elapsed / msPerDay) + " days";
//   }

//   else if (elapsed < msPerYear) {
//     return Math.round(elapsed / msPerMonth) + " months";
//   } else {
//     return Math.round(elapsed / msPerYear) + " years";
//   }
// };

export const formateDateWithTimeHourMintues = (dateString, nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  if (dateString === "9999-12-31T00:00:00") return "";

  return format(new Date(dateString), "dd/MM/yyyy HH:mm");
};

export const formateDateWithTime = (dateString, nullValue = "") => {
  if (dateString === "" || dateString === null || !dateString) return nullValue;
  if (dateString === "9999-12-31T00:00:00") return "";

  return format(new Date(dateString), "dd/MM/yyyy");
};

export const useDebounceEffect = (effect, deps, delay) => {
  const callback = React.useCallback(effect, deps);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [callback, delay]);
};

export const checkDisable = (status) => {
  if (status === "deceased" || status === "completed") {
    return true;
  } else {
    return false;
  }
};

export const GenerateOptionsYear = (start = 2022) => {
  const currentYear = new Date().getFullYear();
  const options = [{ value: "ALL", label: "ทุกปี" }];

  for (let year = start; year <= currentYear; year++) {
    options.push({ value: year.toString(), label: (year + 543).toString() });
  }

  return options;
};

export const GenerateOptionsYearForExport = (start = 2022) => {
  const currentYear = new Date().getFullYear();
  const options = [];

  for (let year = start; year <= currentYear; year++) {
    options.push({ value: year.toString(), label: (year + 543).toString() });
  }

  return options;
};

export const FormatStatus = (status) => {
  if (status === "waiting" || status === "waiting_full") {
    return "รอใส่สาย";
  } else if (status === "on_iv_tube") {
    return "ใส่สายอยู่";
  } else if (status === "completed") {
    return "ถอดสายแล้ว";
  } else if (status === "deceased") {
    return "เสียชีวิตหรือขาดการติดต่อ";
  } else if (status === "unfollow") {
    return "ไม่ติดตาม";
  } else if (status === "keep_for_report") {
    return "ไม่ดำเนินการต่อ";
  } else {
    return "-";
  }
};

export const CheckPICCLine = (normal, other) => {
  const checkPICC = String(normal).toUpperCase().search("PICC");

  const checkOtherPICC = String(other).toUpperCase().search("PICC");

  if (checkPICC !== -1 || checkOtherPICC !== -1) {
    return true;
  } else {
    return false;
  }
};

export const CheckMonts = (final_date) => {
  const month = final_date ? final_date : "";
  const thisMonth = String(month);
  const useMont = thisMonth.substring(5, 7);

  return useMont;
};
export const displayValue = (value) => {
  if (
    value === null ||
    value === -Infinity ||
    value === Infinity ||
    isNaN(value)
  ) {
    return "-";
  }
  return value;
};
