import ExcelExport from "export-xlsx";

export const ExcelReason = async (inputdata, year, setLoading) => {
  // console.log("inputdata", inputdata);
  // const inputdata = { data: [{ test: 5 }] };
  const SETTINGS_FOR_EXPORT = {
    // Table settings
    fileName: "ALiST_REPORT",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `รายงานเหตุผลที่เลิกใช้สาย ของปี ${year?.label} `,
                key: "header_1",
              },
            ],
            headerDefinition: [
              {
                name: `No longer needed`,
                groupKey: "header_1",
                key: "No_loanger_needed",
              },
              {
                name: `No longer needed`,
                groupKey: "header_1",
                key: "loanger_percent",
              },
              {
                name: `Death`,
                groupKey: "header_1",
                key: "Death",
              },
              {
                name: `Death`,
                groupKey: "header_1",
                key: "death_percent",
              },
              {
                name: `CLABSI`,
                groupKey: "header_1",
                key: "CLABSI",
              },
              {
                name: `CLABSI`,
                groupKey: "header_1",
                key: "CLABSI_percent",
              },
              {
                name: `Mechanical problems`,
                groupKey: "header_1",
                key: "Mechanical_problems",
              },
              {
                name: `Mechanical problems`,
                groupKey: "header_1",
                key: "Mechanical_problems_percent",
              },
              {
                name: `Dislodged`,
                groupKey: "header_1",
                key: "Self_dislodged",
              },
              {
                name: `Dislodged`,
                groupKey: "header_1",
                key: "Self_dislodged_percent",
              },
              // {
              //   name: `Dislodged by others`,
              //   groupKey: "header_1",
              //   key: "Dislodged_by_others",
              // },
              {
                name: `Unresolved occlusion`,
                groupKey: "header_1",
                key: "Unresolved_occlusion",
              },
              {
                name: `Unresolved occlusion`,
                groupKey: "header_1",
                key: "Unresolved_occlusion_percent",
              },
              {
                name: `Exit site infection`,
                groupKey: "header_1",
                key: "Exit_site_infection",
              },
              {
                name: `Exit site infection`,
                groupKey: "header_1",
                key: "Exit_site_infection_percent",
              },

              {
                name: `Unintended position`,
                groupKey: "header_1",
                key: "Unintended_position",
              },
              {
                name: `Unintended position`,
                groupKey: "header_1",
                key: "Unintended_position_percent",
              },
              // Unintended_position

              {
                name: `Other`,
                groupKey: "header_1",
                key: "other_reasons_for_discontinuation",
              },

              {
                name: `Other`,
                groupKey: "header_1",
                key: "other_reasons_for_discontinuation_percent",
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
