import ExcelExport from "export-xlsx";

export const ExceltIndications = async (inputdata, year, type, setLoading) => {
  // const inputdata = { data: [{ test: 5 }] };
  const SETTINGS_FOR_EXPORT = {
    // Table settings
    fileName: "ALiST_REPORT",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: ` รายงานข้อบ่งชี้การใส่สายแต่ละข้อของปี ${year?.label} ตามประเภทสาย ${type?.label} `,
                key: "header_01",

                style: { fill: { fgColor: { rgb: "FF0000" } } },
              },
            ],
            headerDefinition: [
              {
                name: "TPN",
                key: "type1",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Prolonged IV meds",
                key: "type2",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Chemotherapy",
                key: "type3",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Vasopressor",
                key: "type4",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "เจาะเลือดบ่อย",
                key: "type5",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Stem cell/ Plasma Exc.",
                key: "type6",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Stem cell Tx",
                key: "type7",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "Other",
                key: "type8",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "รวม",
                key: "sum",
                groupKey: "header_01",
                width: 10,
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
