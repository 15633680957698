import {
  calculateAverage,
  calculateMedian,
  calculateMode,
  calculateStandardDeviation,
  calculateSum,
} from "../utils/useFunction";
import FunctionCheckType from "./Report.FunctionCheckType";

const CountWeight = ({ list, setValue, type, year }) => {
  let sumAll = [];

  if (list?.length > 0) {
    for (let row of list) {
      if (
        row?.weight !== "" &&
        row?.weight !== undefined &&
        row?.weight !== "N/A" &&
        row?.weight !== "ไม่เคยชั่ง" &&
        row?.final_date_success !== ""
      ) {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);

        if (year === "ALL") {
          if (type === "NONE") {
            const thisWeight = String(row?.weight)
              .replace(" ", "")
              .replace("e", "")
              .replace("~", "");
            const useWeight = Number(thisWeight);
            sumAll.push(Number(useWeight));
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            const thisWeight = String(row?.weight)
              .replace(" ", "")
              .replace("e", "")
              .replace("~", "");
            const useWeight = Number(thisWeight);
            sumAll.push(Number(useWeight));
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            const thisWeight = String(row?.weight)
              .replace(" ", "")
              .replace("e", "")
              .replace("~", "");
            const useWeight = Number(thisWeight);
            sumAll.push(Number(useWeight));
          }
        }
        if (year === useYear) {
          if (type === "NONE") {
            const thisWeight = String(row?.weight)
              .replace(" ", "")
              .replace("e", "")
              .replace("~", "");
            const useWeight = Number(thisWeight);
            sumAll.push(Number(useWeight));
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            const thisWeight = String(row?.weight)
              .replace(" ", "")
              .replace("e", "")
              .replace("~", "");
            const useWeight = Number(thisWeight);
            sumAll.push(Number(useWeight));
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            const thisWeight = String(row?.weight)
              .replace(" ", "")
              .replace("e", "")
              .replace("~", "");
            const useWeight = Number(thisWeight);
            sumAll.push(Number(useWeight));
          }
        }
        // if (year === "2023" && useYear === "2023") {
        //   if (type === "NONE") {
        //     const thisWeight = String(row?.weight)
        //       .replace(" ", "")
        //       .replace("e", "")
        //       .replace("~", "");
        //     const useWeight = Number(thisWeight);
        //     sumAll.push(Number(useWeight));
        //   }
        //   if (
        //     type === "PICC" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     const thisWeight = String(row?.weight)
        //       .replace(" ", "")
        //       .replace("e", "")
        //       .replace("~", "");
        //     const useWeight = Number(thisWeight);
        //     sumAll.push(Number(useWeight));
        //   }
        //   if (
        //     type === "CENTRAL_LINE" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     const thisWeight = String(row?.weight)
        //       .replace(" ", "")
        //       .replace("e", "")
        //       .replace("~", "");
        //     const useWeight = Number(thisWeight);
        //     sumAll.push(Number(useWeight));
        //   }
        // }
      }

      const useSumAll = sumAll.filter((value) => value !== 0);
      setValue({
        min: Math.min(...useSumAll),
        max: Math.max(...useSumAll),
        median: calculateMedian(sumAll),
        mode: calculateMode(sumAll),
        sumAll: calculateSum(sumAll),
        average: calculateAverage(sumAll),
        count: sumAll?.length,
        sd: calculateStandardDeviation(sumAll),
      });
    }
  }
};
export default CountWeight;
