import ExcelExport from "export-xlsx";

export const ExceltLinePatency = async (inputdata, year, setLoading) => {
  // const inputdata = { data: [{ test: 5 }] };
  const SETTINGS_FOR_EXPORT = {
    // Table settings
    fileName: "ALiST_REPORT",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              {
                name: `รายงานจำนวนครั้งที่สายตัน ของปี ${year?.label} `,
                key: "header_01",
              },
              {
                name: `รายงานจำนวนสายที่ตัน ของปี ${year?.label} `,
                key: "header_16",
              },

              {
                name: `การรักษาเมื่อสายตันแบบ WO ของปี ${year?.label} `,
                key: "header_02",
              },

              {
                name: `ผลการรักษาเมื่อสายตันแบบ WO ของปี ${year?.label} `,
                key: "header_03",
              },

              {
                name: `ผลการรักษาสายตันแบบ WO + ได้รับ rtPA x ของปี ${year?.label} `,
                key: "header_04",
              },

              {
                name: `การรักษาเมื่อสายตันแบบ CO ของปี ${year?.label} `,
                key: "header_05",
              },

              {
                name: `ผลการรักษาเมื่อสายตันแบบ CO ของปี ${year?.label} `,
                key: "header_06",
              },

              {
                name: `ผลการรักษาสายตันแบบ CO + ได้รับ rtPA x ของปี ${year?.label} `,
                key: "header_07",
              },

              {
                name: `รายงานจำนวนครั้งที่เกิดภาวะสายติดเชื้อ ของปี ${year?.label} `,
                key: "header_08",
              },

              {
                name: `รายงานจำนวนครั้งที่สายรั่ว สายแตก ของปี ${year?.label} `,
                key: "header_09",
              },

              {
                name: `รายงาน การเกิดภาวะสายรั่ว สายแตก สัมพันธ์กับชนิดของสาย ของปี ${year?.label} `,
                key: "header_10",
              },
              {
                name: `รายงานการเกิดแขนขาบวม ของปี ${year?.label} `,
                key: "header_11",
              },
              {
                name: `กรณีแขนขาบวม มี Presence of DVT หรือไม่ ของปี ${year?.label} `,
                key: "header_12",
              },
              {
                name: `การรักษาแขนขาบวม ( Management ) ของปี ${year?.label} `,
                key: "header_13",
              },
              {
                name: `รายงานอุบัติการณ์การเกิดภาวะ exit site infection ของปี ${year?.label} `,
                key: "header_14",
              },
              {
                name: `รายงาน Line displacement / dislodged ของปี ${year?.label} `,
                key: "header_15",
              },
            ],
            headerDefinition: [
              //  header_01
              {
                name: "patency",
                key: "okall",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "withdrawal occlusion",
                key: "wo",
                groupKey: "header_01",
                width: 10,
              },
              {
                name: "complete occlusion",
                key: "co",
                groupKey: "header_01",
                width: 10,
              },
              //  header_16
              {
                name: "withdrawal occlusion cause",
                key: "count_wo",
                groupKey: "header_16",
                width: 10,
              },
              {
                name: "complete occlusion cause",
                key: "count_co",
                groupKey: "header_16",
                width: 10,
              },
              {
                name: "total",
                key: "total_woco",
                groupKey: "header_16",
                width: 10,
              },
              // header_02
              {
                name: "observe",
                key: "wo_observe",
                groupKey: "header_02",
                width: 10,
              },
              {
                name: "rtPA x",
                key: "wo_rtpa_1",
                groupKey: "header_02",
                width: 10,
              },
              // header_03
              {
                name: "open",
                key: "wo_result_open",
                groupKey: "header_03",
                width: 10,
              },
              {
                name: "withdrawal occlusion",
                key: "wo_result_wo",
                groupKey: "header_03",
                width: 10,
              },
              {
                name: "complete occlusion",
                key: "wo_result_co",
                groupKey: "header_03",
                width: 10,
              },
              // header_04
              {
                name: "WO + ได้รับ rtPA x",
                key: "wo_rtpa",
                groupKey: "header_04",
                width: 10,
              },
              // header_05
              {
                name: "observe",
                key: "co_observe",
                groupKey: "header_05",
                width: 10,
              },
              {
                name: "rtPA x",
                key: "co_rtpa_1",
                groupKey: "header_05",
                width: 10,
              },
              // header_06
              {
                name: "open",
                key: "co_result_open",
                groupKey: "header_06",
                width: 10,
              },
              {
                name: "withdrawal occlusion",
                key: "co_result_wo",
                groupKey: "header_06",
                width: 10,
              },
              {
                name: "complete occlusion",
                key: "co_result_co",
                groupKey: "header_06",
                width: 10,
              },
              // header_07
              {
                name: "CO + ได้รับ rtPA x",
                key: "co_rtpa",
                groupKey: "header_07",
                width: 10,
              },
              // header_08
              {
                name: "None",
                key: "infection_nil",
                groupKey: "header_08",
                width: 10,
              },
              {
                name: "Infection",
                key: "infection_bug_found",
                groupKey: "header_08",
                width: 10,
              },
              //  header_09
              {
                name: "None",
                key: "line_disledge_breakage_leak_port_no",
                groupKey: "header_09",
                width: 10,
              },
              {
                name: "Breakage/leak port",
                key: "line_disledge_breakage_leak_port",
                groupKey: "header_09",
                width: 10,
              },
              //  header_10
              {
                name: "Arrow 5 Fr 2-lumen PICC",
                key: "Arrow5Fr2lumenPICC",
                groupKey: "header_10",
                width: 10,
              },
              {
                name: "Arrow 4 Fr 1-lumen PICC",
                key: "Arrow4Fr1lumenPICC",
                groupKey: "header_10",
                width: 10,
              },
              {
                name: "Bard 5 Fr 2-Lumen power PICC",
                key: "Bard5Fr2LumenpowerPICC",
                groupKey: "header_10",
                width: 10,
              },
              {
                name: "Vygon 3 Fr 1-lumen PICC",
                key: "Vygon3Fr1lumenPICC",
                groupKey: "header_10",
                width: 10,
              },
              {
                name: "Other",
                key: "other_final_catheter_in",
                groupKey: "header_10",
                width: 10,
              },
              // header_11
              {
                name: "None",
                key: "limb_edema_no",
                groupKey: "header_11",
                width: 10,
              },
              {
                name: "limb edema",
                key: "limb_edema_yes",
                groupKey: "header_11",
                width: 10,
              },
              // header_12

              {
                name: "No",
                key: "presence_of_dvt_no",
                groupKey: "header_12",
                width: 10,
              },
              {
                name: "Yes",
                key: "presence_of_dvt_yes",
                groupKey: "header_12",
                width: 10,
              },
              {
                name: "N/A",
                key: "presence_of_dvt_na",
                groupKey: "header_12",
                width: 10,
              },
              //  header_13

              {
                name: "Observation",
                key: "manag_observation",
                groupKey: "header_13",
                width: 10,
              },
              {
                name: "Anticoagulation",
                key: "manag_anticoagulation",
                groupKey: "header_13",
                width: 10,
              },
              {
                name: "Catheter removal",
                key: "manag_catheter_removal",
                groupKey: "header_13",
                width: 10,
              },
              // header_14
              {
                name: "None",
                key: "exit_site_ok",
                groupKey: "header_14",
                width: 10,
              },
              {
                name: "Other",
                key: "other_exit_site",
                groupKey: "header_14",
                width: 10,
              },
              {
                name: "Exit site infection",
                key: "exit_site_infection",
                groupKey: "header_14",
                width: 10,
              },
              //  header_15

              {
                name: "None",
                key: "line_disledge_nil",
                groupKey: "header_15",
                width: 10,
              },
              {
                name: "Displace",
                key: "line_disledge_new_exit_mark_at",
                groupKey: "header_15",
                width: 10,
              },
              {
                name: "Dislodge",
                key: "complete_dislodge",
                groupKey: "header_15",
                width: 10,
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
