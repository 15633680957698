import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginThunk } from "../../redux/authSlice";
import swalAlert from "../../utils/swalAlert";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const router = useHistory();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      if (user.role === "ADMIN") {
        router.push("/Admin");
      } else {
        router.push("/");
      }
    } else {
      return;
    }
  }, [user]);

  const onClickLogin = async () => {
    if (username && password) {
      try {
        setLoading(true);
        dispatch(loginThunk({ username, password }));
        setPassword("");
        setUsername("");
        setLoading(false);
      } catch (error) {
        await swalAlert.Fail({ text: error.message });
      }
    } else {
      setError(true);
      await swalAlert.Fail({ text: "โปรดกรอกไอดีและรหัสของท่าน" });
    }
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      onClickLogin();
    }
  };

  return (
    <div
      className=" h-screen relative overflow-hidden
    "
      style={{
        backgroundImage: `url(/Login/Bglogin.png)`,
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className=" max-w-lg w-full mx-auto pt-24 2xl:pt-40">
        <div className=" flex flex-col justify-center items-center  ">
          <div class="  md:w-full md:h-full grid justify-items-center  ">
            <img
              src="../Login/ALiST.png"
              className=" w-1/2"
              alt="logoLogin"
            ></img>
            <p className="text-xl text-#000000 font-semibold">
              Anesthesia Line Service Team
            </p>
          </div>

          <div class=" md:w-full flex flex-col gap-10 pt-10 ">
            <input
              type="text"
              placeholder="Username"
              className=" focus:outline-none  focus:ring-textblue text-textgrayalist w-full px-4 py-2 nm-inset-white-sm rounded-md "
              value={username}
              onKeyPress={handleKeypress}
              onChange={(e) => setUsername(e.target.value)}
            />

            <div className=" grid justify-items-center relative cursor-pointer">
              {" "}
              <input
                type={passwordShown ? "text" : "password"}
                placeholder="Password"
                onKeyPress={handleKeypress}
                className=" focus:outline-none  focus:ring-textblue text-textgrayalist  w-full px-4 py-2 nm-inset-white-sm rounded-md "
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordShown ? (
                <svg
                  onClick={togglePassword}
                  xmlns="http://www.w3.org/2000/svg"
                  className="md:h-5 md:w-5 w-5 h-5 absolute md:top-3 md:right-5 right-5 top-3 text-gray-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                    clipRule="evenodd"
                  />
                  <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                </svg>
              ) : (
                <svg
                  onClick={togglePassword}
                  xmlns="http://www.w3.org/2000/svg"
                  className="md:h-5 md:w-5 w-5 h-5 absolute md:top-3 md:right-5 right-5 top-3 text-gray-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  <path
                    fillRule="evenodd"
                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                    clipRule="evenodd"
                  />
                </svg>
              )}{" "}
            </div>
          </div>

          <div className=" mt-10  grid grid-cols-1 justify-items-center gap-10 w-full ">
            <button
              onClick={() => onClickLogin()}
              className="bg-white  h-10 w-1/3 rounded-md  items-center  font-normal  text-gray-500 text-center ring-bgbluealist ring-2 hover:bg-bgbluealist hover:text-white focus-outline-none"
            >
              Sign in
            </button>
            <button
              onClick={() => router.push("/ForgotPassword")}
              className="bg-white  h-10 w-1/3 rounded-md  items-center  font-normal  text-gray-500 text-center ring-bgbluealist ring-2 hover:bg-bgbluealist hover:text-white focus-outline-none"
            >
              Forgot Password
            </button>
          </div>
          <div className=" pt-24 2xl:pt-40 ">
            <div className="text-xl text-textblack">
              ALiST โทร. 41151 หรือ 065-3911216 ในวันและเวลาราชการ
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
