const XLSX = require("xlsx");

export const ExportCatheterLine = (submitExcelData, year) => {
  const wb = XLSX.utils.book_new();
  const ws_data = [
    [`จำนวนผู้ป่วยที่ได้รับการใส่สายทั้งหมด ปี${year.label}`],
    [`ปี${year.label}`, "PICC Line", "Central Line", "TOTAL"],
  ];
  Object.keys(submitExcelData).forEach((key) => {
    ws_data.push([
      key,
      submitExcelData[key].picc,
      submitExcelData[key].central,
      submitExcelData[key].total,
    ]);
  });

  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  XLSX.writeFile(wb, "sheetjs.xlsx");
};
