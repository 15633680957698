import axios from "../index";

// export const fetchBlogs = async () => {
//   const { data } = await axios.get("/blogs");
//   return data;
// };

export const fetchDocById = async (id) => {
  const { data } = await axios.get(`/doctorandnurses/${id}`);
  return data;
};
export const fetchDoctors = async () => {
  const { data } = await axios.get("/doctorandnurses");
  return data;
};

export const createDocAndNurse = async (data) => {
  await axios.post("/doctorandnurses", data);
};

export const updateDoctor = async (id, data) => {
  await axios.put(`/doctorandnurses/${id}`, data);
};

export const deleteDoctorById = async (id) => {
  await axios.delete(`/doctorandnurses/${id}`);
};
