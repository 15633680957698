import { useState } from "react";

import HeadInfo from "./HeadInfo";
const Detail = () => {
  return (
    <div>
      <div className="  w-full h-full p-5 ">
        <div className=" md:text-2xl text-xl  text-textblue font-bold ">
          Details
        </div>
        <div className=" divide-y divide-gray-300 p-5 ">
          <div className=" md:flex md:justify-between justify-items-center items-center py-5 ">
            <div className=" flex font-bold text-bgbluealist md:text-xl  md:justify-center items-center lg:gap-5 md:gap-10 hover:text-textblue">
              <img src="../icondetail.png" className=" w-10 h-full"></img>
              ครั้งที่ 1
            </div>
            <div className=" text-textblue md:text-xl font-bold mt-5 lg:mt-0 ">
              14 Jul 2020 - 15 Jul 2020
            </div>
          </div>

          <div className=" md:flex md:justify-between justify-items-center items-center py-5">
            <div className=" flex font-bold text-bgbluealist md:text-xl  md:justify-center items-center lg:gap-5 md:gap-10 hover:text-textblue">
              <div className=" flex font-bold text-bgbluealist md:text-xl  md:justify-center items-center lg:gap-5 md:gap-10 ">
                <img src="../icondetail.png" className=" w-10 h-full"></img>
              </div>
              ครั้งที่ 2
            </div>
            <div className=" text-textblue md:text-xl font-bold mt-5 lg:mt-0 ">
              1 Jul 2020 - 3 Jul 2020
            </div>
          </div>
          <div className=" md:flex md:justify-between justify-items-center items-center py-5">
            <div className=" flex font-bold text-bgbluealist md:text-xl  md:justify-center items-center lg:gap-5 md:gap-10 hover:text-textblue">
              <img src="../icondetail.png" className=" w-10 h-full"></img>
              ครั้งที่ 3
            </div>
            <div className=" text-textblue md:text-xl font-bold mt-5 lg:mt-0 "></div>
          </div>
          <div className=" md:flex md:justify-between justify-items-center items-center py-5">
            <div className=" flex font-bold text-bgbluealist md:text-xl  md:justify-center items-center lg:gap-5 md:gap-10 hover:text-textblue">
              <img src="../icondetail.png" className=" w-10 h-full"></img>
              ครั้งที่ 4
            </div>
            <div className=" text-textblue md:text-xl font-bold mt-5 lg:mt-0 "></div>
          </div>
        </div>
      </div>
    </div>
  );
};
const DashbordAll = () => {
  return (
    <div>
      <div className=" bg-bgbluesummary w-full h-auto md:p-10 p-5">
        <HeadInfo />
        <Detail />
      </div>
    </div>
  );
};
export default DashbordAll;
