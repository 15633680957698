import { format } from "date-fns/esm";
import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { titleStyle } from "../../hooks/useClassName";

const DatePickerField = ({
  name,
  value,
  setField = false,
  showTime,
  title,
  classNameTitle,
  disabled = false,
  dateType = "DateTime",
  setValue = false,
  setStateObject = false,
}) => {
  const onChange = (date) => {
    if (setValue && setStateObject) {
      setValue((prev) => {
        let temp = { ...prev };
        temp[name] = format(new Date(date), "yyyy-MM-dd");
        return { ...temp };
      });
    }
    if (setValue && !setStateObject) {
      setValue(date);
    }
    if (dateType === "DateTime" && setField) {
      setField(name)(date);
    }

    if (setField) {
      setField(name)(format(new Date(date), "yyyy-MM-dd"));
    }
    return;
  };
  const RenderPicker = forwardRef((props, ref) => {
    const { onClick, value, label } = props;

    return (
      <div className="w-full flex-1 mb-2 ">
        <div className={classNameTitle ? classNameTitle : titleStyle}>
          {label}
        </div>
        <div
          onClick={onClick}
          className="bg-white  my-0 p-2 mt-1 flex border border-gray-200 rounded px-2 appearance-none outline-none w-60 text-gray-800 mx-10"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          {value}
        </div>
      </div>
    );
  });
  return (
    <DatePicker
      locale="th"
      dateFormat={"dd/MM/yyyy" + (showTime ? " HH:mm " : "")}
      showTimeSelect={showTime}
      timeFormat="HH:mm"
      readOnly={disabled}
      timeIntervals={15}
      selected={
        value === undefined
          ? null
          : typeof value === "object"
          ? value
          : new Date(value)
      }
      onChange={onChange}
      customInput={<RenderPicker label={title} />}
    />
  );
};

export default DatePickerField;
