import { useState } from "react";
import { headerStyle, lineStyle } from "../../hooks/useClassName";
import TextField from "../field/TextField";

const ButtonAddContact = ({ action }) => {
  return (
    <div className="  gap-5 my-10">
      <div
        onClick={(e) => action(e)}
        className="cursor-pointer bg-blue-600  hover:bg-blue-700 
      text-white hover:shadow-lg shadow-md 
       w-60 p-2 rounded-md flex justify-center mx-10"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 my-auto mr-2"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
            clip-rule="evenodd"
          />
        </svg>
        Add More Contact
      </div>
    </div>
  );
};

export default ButtonAddContact;
