import {
  ageDayCalculator,
  timeDifference,
  timeDifferenceTotal,
} from "../../../components/functions/Functions";
import {
  calculateAverage,
  calculateMedian,
  calculatePercentile25,
  calculatePercentile75,
  calculateStandardDeviation,
} from "../utils/useFunction";
import FunctionCheckType from "./Report.FunctionCheckType";

const CountTotalDay = ({ list, setValue, year }) => {
  let A = 0; //1
  let B = 0; //2
  let C = 0; //3
  let D = 0; //4
  let E = 0; //5
  let F = 0; //6
  let G = 0; //7

  let A_adult = 0;
  let B_adult = 0;
  let C_adult = 0;
  let D_adult = 0;
  let E_adult = 0;
  let F_adult = 0;
  let G_adult = 0;

  //
  let A_kid = 0;
  let B_kid = 0;
  let C_kid = 0;
  let D_kid = 0;
  let E_kid = 0;
  let F_kid = 0;
  let G_kid = 0;

  let sumAll = [];
  let sumAdult = [];
  let sumKid = [];

  if (list?.length > 0) {
    for (let row of list) {
      const isAdult = Number(
        ageDayCalculator(
          new Date(row?.dob),
          new Date(row?.final_date_success)
        ) >= 6570
      );
      const isKid = Number(
        ageDayCalculator(
          new Date(row?.dob),
          new Date(row?.final_date_success)
        ) < 6570
      );
      if (row?.final_date_success && row?.line_is_no_loanger) {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);
        const totalday = timeDifferenceTotal(
          new Date(row?.line_is_no_loanger),
          new Date(row?.final_date_success)
        );

        if (year === "ALL") {
          if (
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC" &&
            isAdult
          ) {
            if (totalday >= 1 && totalday <= 7) {
              A_adult++;
            } else if (totalday >= 8 && totalday <= 14) {
              B_adult++;
            } else if (totalday >= 15 && totalday <= 30) {
              C_adult++;
            } else if (totalday >= 31 && totalday <= 90) {
              D_adult++;
            } else if (totalday >= 91 && totalday <= 180) {
              E_adult++;
            } else if (totalday >= 181 && totalday <= 365) {
              F_adult++;
            } else if (totalday > 365) {
              G_adult++;
            }
            sumAdult.push(Number(totalday));
          } else if (
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC" &&
            isKid
          ) {
            if (totalday >= 1 && totalday <= 7) {
              A_kid++;
            } else if (totalday >= 8 && totalday <= 14) {
              B_kid++;
            } else if (totalday >= 15 && totalday <= 30) {
              C_kid++;
            } else if (totalday >= 31 && totalday <= 90) {
              D_kid++;
            } else if (totalday >= 91 && totalday <= 180) {
              E_kid++;
            } else if (totalday >= 181 && totalday <= 365) {
              F_kid++;
            } else if (totalday > 365) {
              G_kid++;
            }
            sumKid.push(Number(totalday));
          }
          //
          if (
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (totalday >= 1 && totalday <= 7) {
              A++;
            } else if (totalday >= 8 && totalday <= 14) {
              B++;
            } else if (totalday >= 15 && totalday <= 30) {
              C++;
            } else if (totalday >= 31 && totalday <= 90) {
              D++;
            } else if (totalday >= 91 && totalday <= 180) {
              E++;
            } else if (totalday >= 181 && totalday <= 365) {
              F++;
            } else if (totalday > 365) {
              G++;
            }
            sumAll.push(Number(totalday));
          }
        }
        if (year === useYear) {
          if (
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC" &&
            isAdult
          ) {
            if (totalday >= 1 && totalday <= 7) {
              A_adult++;
            } else if (totalday >= 8 && totalday <= 14) {
              B_adult++;
            } else if (totalday >= 15 && totalday <= 30) {
              C_adult++;
            } else if (totalday >= 31 && totalday <= 90) {
              D_adult++;
            } else if (totalday >= 91 && totalday <= 180) {
              E_adult++;
            } else if (totalday >= 181 && totalday <= 365) {
              F_adult++;
            } else if (totalday > 365) {
              G_adult++;
            }
            sumAdult.push(Number(totalday));
          } else if (
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC" &&
            isKid
          ) {
            if (totalday >= 1 && totalday <= 7) {
              A_kid++;
            } else if (totalday >= 8 && totalday <= 14) {
              B_kid++;
            } else if (totalday >= 15 && totalday <= 30) {
              C_kid++;
            } else if (totalday >= 31 && totalday <= 90) {
              D_kid++;
            } else if (totalday >= 91 && totalday <= 180) {
              E_kid++;
            } else if (totalday >= 181 && totalday <= 365) {
              F_kid++;
            } else if (totalday > 365) {
              G_kid++;
            }
            sumKid.push(Number(totalday));
          }
          //
          if (
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (totalday >= 1 && totalday <= 7) {
              A++;
            } else if (totalday >= 8 && totalday <= 14) {
              B++;
            } else if (totalday >= 15 && totalday <= 30) {
              C++;
            } else if (totalday >= 31 && totalday <= 90) {
              D++;
            } else if (totalday >= 91 && totalday <= 180) {
              E++;
            } else if (totalday >= 181 && totalday <= 365) {
              F++;
            } else if (totalday > 365) {
              G++;
            }
            sumAll.push(Number(totalday));
          }
        }
        // if (year === "2023" && useYear === "2023") {
        //   if (
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC" &&
        //     isAdult
        //   ) {
        //     if (totalday >= 1 && totalday <= 7) {
        //       A_adult++;
        //     } else if (totalday >= 8 && totalday <= 14) {
        //       B_adult++;
        //     } else if (totalday >= 15 && totalday <= 30) {
        //       C_adult++;
        //     } else if (totalday >= 31 && totalday <= 90) {
        //       D_adult++;
        //     } else if (totalday >= 91 && totalday <= 180) {
        //       E_adult++;
        //     } else if (totalday >= 181 && totalday <= 365) {
        //       F_adult++;
        //     } else if (totalday > 365) {
        //       G_adult++;
        //     }
        //     sumAdult.push(Number(totalday));
        //   } else if (
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC" &&
        //     isKid
        //   ) {
        //     if (totalday >= 1 && totalday <= 7) {
        //       A_kid++;
        //     } else if (totalday >= 8 && totalday <= 14) {
        //       B_kid++;
        //     } else if (totalday >= 15 && totalday <= 30) {
        //       C_kid++;
        //     } else if (totalday >= 31 && totalday <= 90) {
        //       D_kid++;
        //     } else if (totalday >= 91 && totalday <= 180) {
        //       E_kid++;
        //     } else if (totalday >= 181 && totalday <= 365) {
        //       F_kid++;
        //     } else if (totalday > 365) {
        //       G_kid++;
        //     }
        //     sumKid.push(Number(totalday));
        //   }
        //   //
        //   if (
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     if (totalday >= 1 && totalday <= 7) {
        //       A++;
        //     } else if (totalday >= 8 && totalday <= 14) {
        //       B++;
        //     } else if (totalday >= 15 && totalday <= 30) {
        //       C++;
        //     } else if (totalday >= 31 && totalday <= 90) {
        //       D++;
        //     } else if (totalday >= 91 && totalday <= 180) {
        //       E++;
        //     } else if (totalday >= 181 && totalday <= 365) {
        //       F++;
        //     } else if (totalday > 365) {
        //       G++;
        //     }
        //     sumAll.push(Number(totalday));
        //   }
        // }
      }
    }
  }
  const useSumAll = sumAll.filter((value) => value !== 0);
  const useSumAdult = sumAdult.filter((value) => value !== 0);
  const useSumKid = sumKid.filter((value) => value !== 0);

  setValue({
    A: A,
    B: B,
    C: C,
    D: D,
    E: E,
    F: F,
    G: G,

    //
    A_adult: A_adult,
    B_adult: B_adult,
    C_adult: C_adult,
    D_adult: D_adult,
    E_adult: E_adult,
    F_adult: F_adult,
    G_adult: G_adult,

    //
    A_kid: A_kid,
    B_kid: B_kid,
    C_kid: C_kid,
    D_kid: D_kid,
    E_kid: E_kid,
    F_kid: F_kid,
    G_kid: G_kid,

    //

    mean: calculateAverage(sumAll),
    median: calculateMedian(sumAll),
    sd: calculateStandardDeviation(sumAll),
    min: Math.min(...useSumAll),
    max: Math.max(...useSumAll),
    per25: calculatePercentile25(sumAll),
    per75: calculatePercentile75(sumAll),
    number: sumAll?.length,
    //

    mean_ad: calculateAverage(sumAdult),
    median_ad: calculateMedian(sumAdult),
    sd_ad: calculateStandardDeviation(sumAdult),
    min_ad: Math.min(...useSumAdult),
    max_ad: Math.max(...useSumAdult),
    per25_ad: calculatePercentile25(sumAdult),
    per75_ad: calculatePercentile75(sumAdult),
    number_ad: sumAdult?.length,
    //
    mean_kid: calculateAverage(sumKid),
    median_kid: calculateMedian(sumKid),
    sd_kid: calculateStandardDeviation(sumKid),
    min_kid: Math.min(...useSumKid),
    max_kid: Math.max(...useSumKid),
    per25_kid: calculatePercentile25(sumKid),
    per75_kid: calculatePercentile75(sumKid),
    number_kid: sumKid?.length,
  });
};

export default CountTotalDay;
