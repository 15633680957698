import React, { useContext, useEffect, useState } from "react";
import { TaskContext } from "../../contexts/TaskContext";
import { titleStyle } from "../../hooks/useClassName";
import FormAttempt from "../form/FormAttempt";
import api from "../../api";
import Loading from "../loading/Loading";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getUserDepartment } from "../../utils/formatUserDepartment";

const AttemptStatus = ({ row, index, status, form, setField }) => {
  const [attempt, setAttempt] = useState(false);
  const [edit, setEdit] = useState(false);
  const [temp, setTemp] = useState({});

  console.log("TEMP :", temp);

  React.useEffect(() => {
    if (row && edit) {
      setTemp(row);
    } else {
      return;
    }
  }, [edit]);

  return (
    <div className="bg-white w-full h-full mt-5 rounded-md mx-auto">
      <div
        onClick={() => setAttempt(!attempt)}
        className=" md:text-xl text-base font-bold text-bgblue cursor-pointer p-2 flex justify-between items-center md:mx-10"
      >
        <div className="flex my-auto space-x-4 ">
          <div> Attempt no.{index + 1} </div>
          {edit ? (
            <div
              className="  bg-white  text-textgrayalist hover:text-bgbluealist  text-l lg:text-base rounded-md  px-4 py-1 font-medium hover:shadow-lg shadow-md cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();

                await api.put(`/attempts/${row?._id}`, {
                  ...temp,
                });
                {
                  temp?.status === "Successful" &&
                    (await api.put(`/tasks/${form?._id}`, {
                      final_date_success: temp?.date,
                    }));
                }

                alert("แก้ไข attempt สำเร็จ");
                setEdit(false);
              }}
            >
              Update
            </div>
          ) : (
            <div
              className="flex text-lg"
              onClick={(e) => {
                e.stopPropagation();
                setAttempt(true);
                setEdit(true);
              }}
            >
              {" "}
              แก้ไข{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className=" w-4 md:w-6 md:h-6 my-auto"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            </div>
          )}
        </div>
        <div
          className={
            status === "Unsuccessful"
              ? " md:text-base text-xs text-red-600 font-semibold "
              : "md:text-base text-xs text-green-600 font-semibold"
          }
        >
          {status}
        </div>
      </div>

      {attempt && (
        <div>
          <FormAttempt
            form={temp?._id ? temp : row}
            number={index + 1}
            disabled={edit ? false : true}
            setValue={setTemp}
            edit={edit}
            setField={setField}
          />
        </div>
      )}
    </div>
  );
};

const CurrentAttempt = ({ form, setField, LoadDataAttempt, data }) => {
  const { setForm, initialAttempts } = useContext(TaskContext);

  const [attempt, setAttempt] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const onClickAbortedAttempts = async (status) => {
    // ในเว็บถูกแก้เป็น save attempt
    setLoading(true);
    try {
      await api.post(`/attempts`, {
        task_id: form._id,
        status: status,
        attending: form?.attending,
        attending_name: form?.attending_name,
        date: form?.date,
        fellow: form?.fellow,
        fellow_name: form?.fellow_name,
        femoral: form?.femoral,
        le: form?.le,
        line_finalized: form?.line_finalized,
        lt: form?.lt,
        neck: form?.neck,
        note: form?.note,
        other_position: form?.other_position,
        other_position_name: form?.other_position_name,
        out_of_room: form?.out_of_room,
        puncture: form?.puncture,
        resident: form?.resident,
        resident_name: form?.resident_name,
        room_in: form?.room_in,
        rt: form?.rt,
        subcalvian: form?.subcalvian,
        ue: form?.ue,
        complications_pneumothorax: form?.complications_pneumothorax,
        complications_hemothorax: form?.complications_hemothorax,
        complications_arterial_puncture: form?.complications_arterial_puncture,
        complications_paresthesia: form?.complications_paresthesia,
        complications_nerve_injury: form?.complications_nerve_injury,
        complications_limb_ischemia_related_to_arterial_puncture:
          form?.complications_limb_ischemia_related_to_arterial_puncture,
        complications_wire: form?.complications_wire,
        other_complications: form?.other_complications,
        other_complications_name: form?.other_complications_name,
        check_attemp: form?.check_attemp,
        other: form?.other,
        other_name: form?.other_name,
        user_department: getUserDepartment(user?.user_department),
      });

      setForm((prev) => {
        return {
          ...prev,
          ...initialAttempts,
        };
      });
      LoadDataAttempt();
    } catch (error) {
      console.error("ERROR TO CREATE ATTEMPT : ", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="bg-white w-full  h-full mt-5 rounded-md mx-auto">
      <div
        onClick={() => setAttempt(!attempt)}
        className=" md:text-xl text-base font-bold text-bgblue cursor-pointer p-2 flex justify-between items-center mx-10"
      >
        Attempt no.{data?.length + 1}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
      {attempt && (
        <div>
          <FormAttempt
            form={form}
            setField={setField}
            onClickAbortedAttempts={onClickAbortedAttempts}
            number={data?.length + 1}
          />
        </div>
      )}
    </div>
  );
};

const Attempt = ({ form, setField }) => {
  //task_id ส่งมาเพื่อ หา อันเก่าแล้วเอาไป loop .map ไปแสดง
  const [data, setData] = useState([]);

  useEffect(() => {
    LoadDataAttempt();
  }, []);

  const LoadDataAttempt = async () => {
    const { data } = await api.get(`/attempts?task_id=${form._id}`);

    const filterAttempt = data.attempts.find((item) => {
      return item?.status === "Successful";
    });

    if (filterAttempt) {
      setField("attempts_final_obj")(filterAttempt);
    }
    // filterAttempt
    setData(data?.attempts);
  };

  const status_success =
    data?.slice(data?.length - 1, data?.length)[0]?.status === "Successful";

  return (
    <div className="mt-5 mx-10">
      <div className={"md:text-2xl text-xl text-textinput font-semibold my-5 "}>
        {" "}
        Attempt
      </div>
      {data?.map((row, index) => {
        if (row?.status) {
          return (
            <AttemptStatus
              key={index}
              row={row}
              index={index}
              status={row?.status}
              setField={setField}
              form={form}
            />
          );
        }
      })}

      {!status_success && (
        <CurrentAttempt
          form={form}
          setField={setField}
          LoadDataAttempt={LoadDataAttempt}
          data={data}
        />
      )}
    </div>
  );
};
export default Attempt;
