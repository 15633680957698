import FunctionCheckType from "./Report.FunctionCheckType";

const CountLabelCatheter = ({ list, setValue, year }) => {
  //a Picc
  //a หลังคือเดือน

  let aa = 0; // 01
  let ab = 0; // 02
  let ac = 0; // 03
  let ad = 0; // 04
  let ae = 0; // 05
  let af = 0; // 06
  let ag = 0; // 07
  let ah = 0; // 08
  let ai = 0; // 09
  let aj = 0; // 10
  let ak = 0; // 11
  let al = 0; // 12

  let ba = 0; // 01
  let bb = 0; // 02
  let bc = 0; // 03
  let bd = 0; // 04
  let be = 0; // 05
  let bf = 0; // 06
  let bg = 0; // 07
  let bh = 0; // 08
  let bi = 0; // 09
  let bj = 0; // 10
  let bk = 0; // 11
  let bl = 0; // 12

  if (list?.length > 0) {
    for (let row of list) {
      if (row?.final_date_success != "") {
        const month = row?.final_date_success ? row?.final_date_success : "";
        const thisMonth = String(month);
        const useMont = thisMonth.substring(5, 7);

        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);
        if (year === "ALL") {
          if (
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (useMont === "01") {
              aa++;
            } else if (useMont === "02") {
              ab++;
            } else if (useMont === "03") {
              ac++;
            } else if (useMont === "04") {
              ad++;
            } else if (useMont === "05") {
              ae++;
            } else if (useMont === "06") {
              af++;
            } else if (useMont === "07") {
              ag++;
            } else if (useMont === "08") {
              ah++;
            } else if (useMont === "09") {
              ai++;
            } else if (useMont === "10") {
              aj++;
            } else if (useMont === "11") {
              ak++;
            } else if (useMont === "12") {
              al++;
            }
          } else if (
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            if (useMont === "01") {
              ba++;
            } else if (useMont === "02") {
              bb++;
            } else if (useMont === "03") {
              bc++;
            } else if (useMont === "04") {
              bd++;
            } else if (useMont === "05") {
              be++;
            } else if (useMont === "06") {
              bf++;
            } else if (useMont === "07") {
              bg++;
            } else if (useMont === "08") {
              bh++;
            } else if (useMont === "09") {
              bi++;
            } else if (useMont === "10") {
              bj++;
            } else if (useMont === "11") {
              bk++;
            } else if (useMont === "12") {
              bl++;
            }
          }
        }
        if (year === useYear) {
          if (
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (useMont === "01") {
              aa++;
            } else if (useMont === "02") {
              ab++;
            } else if (useMont === "03") {
              ac++;
            } else if (useMont === "04") {
              ad++;
            } else if (useMont === "05") {
              ae++;
            } else if (useMont === "06") {
              af++;
            } else if (useMont === "07") {
              ag++;
            } else if (useMont === "08") {
              ah++;
            } else if (useMont === "09") {
              ai++;
            } else if (useMont === "10") {
              aj++;
            } else if (useMont === "11") {
              ak++;
            } else if (useMont === "12") {
              al++;
            }
          } else if (
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            if (useMont === "01") {
              ba++;
            } else if (useMont === "02") {
              bb++;
            } else if (useMont === "03") {
              bc++;
            } else if (useMont === "04") {
              bd++;
            } else if (useMont === "05") {
              be++;
            } else if (useMont === "06") {
              bf++;
            } else if (useMont === "07") {
              bg++;
            } else if (useMont === "08") {
              bh++;
            } else if (useMont === "09") {
              bi++;
            } else if (useMont === "10") {
              bj++;
            } else if (useMont === "11") {
              bk++;
            } else if (useMont === "12") {
              bl++;
            }
          }
        }
        // if (year === "2023" && useYear === "2023") {
        //   if (
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     if (useMont === "01") {
        //       aa++;
        //     } else if (useMont === "02") {
        //       ab++;
        //     } else if (useMont === "03") {
        //       ac++;
        //     } else if (useMont === "04") {
        //       ad++;
        //     } else if (useMont === "05") {
        //       ae++;
        //     } else if (useMont === "06") {
        //       af++;
        //     } else if (useMont === "07") {
        //       ag++;
        //     } else if (useMont === "08") {
        //       ah++;
        //     } else if (useMont === "09") {
        //       ai++;
        //     } else if (useMont === "10") {
        //       aj++;
        //     } else if (useMont === "11") {
        //       ak++;
        //     } else if (useMont === "12") {
        //       al++;
        //     }
        //   } else if (
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     if (useMont === "01") {
        //       ba++;
        //     } else if (useMont === "02") {
        //       bb++;
        //     } else if (useMont === "03") {
        //       bc++;
        //     } else if (useMont === "04") {
        //       bd++;
        //     } else if (useMont === "05") {
        //       be++;
        //     } else if (useMont === "06") {
        //       bf++;
        //     } else if (useMont === "07") {
        //       bg++;
        //     } else if (useMont === "08") {
        //       bh++;
        //     } else if (useMont === "09") {
        //       bi++;
        //     } else if (useMont === "10") {
        //       bj++;
        //     } else if (useMont === "11") {
        //       bk++;
        //     } else if (useMont === "12") {
        //       bl++;
        //     }
        //   }
        // }
      }

      setValue({
        jan: {
          picc: aa,
          central: ba,
        },
        feb: {
          picc: ab,
          central: bb,
        },
        mar: {
          picc: ac,
          central: bc,
        },
        apr: {
          picc: ad,
          central: bd,
        },
        may: {
          picc: ae,
          central: be,
        },
        jun: {
          picc: af,
          central: bf,
        },
        jul: {
          picc: ag,
          central: bg,
        },
        aug: {
          picc: ah,
          central: bh,
        },
        sep: {
          picc: ai,
          central: bi,
        },
        oct: {
          picc: aj,
          central: bj,
        },
        nov: {
          picc: ak,
          central: bk,
        },
        dec: {
          picc: al,
          central: bl,
        },
      });
    }
  }
};

export default CountLabelCatheter;
