import React, { memo, useState } from "react";
import { CelesTableView } from "../../components/attribute/CelesTableView";
import NavClick from "../../components/attribute/NavClick";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import api from "../../api";
import Loading from "../../components/loading/Loading";
import { getStatusByStatus, getStyleByStatus } from "../../hooks/useClassName";
import { CelesStandardPopUp } from "../../components/popup/CelesStandardPopUp";
import Dropdown from "../../components/field/Dropdown";
import TextField from "../../components/field/TextField";
import { useDebounceEffect } from "../../components/functions/Functions";
import {
  OptionECG,
  OptionsReasonKeep,
  OptionWard72years,
  OptionWard84years,
  OptionWardAccident,
  OptionWardBuilding,
  OptionWardChalerm,
  OptionWardDek,
  OptionWardHeart,
  OptionWardNavamin,
  OptionWardOther,
  OptionWardOtherRoom,
  OptionWardOutPatient,
  OptionWardPICU,
  OptionWardPrakan,
  OptionWardPraSri,
  OptionWardSalak,
  OptionWardSayamin,
  OptionWardShortStay,
} from "../../contexts/DataOptions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSweetAlert from "../../hooks/useSweetAlert";
import { FunctionCloneDeep } from "radium-omnibus";
import { useSelector } from "react-redux";
import PaginationMain from "../../components/pagination/Pagination.Main";
import { formatColorFlagNew } from "../home/render/Home.Function";
import HomeChartAndData from "./render/Home.ChartAndData";
import HomeSearchBar from "./render/Home.Searchbar";
import { useContext } from "react";
import { TaskContext } from "../../contexts/TaskContext";
import { original } from "@reduxjs/toolkit";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import userDepStore from "../../_store/userDepStore";
import {
  getUserDepartment,
  getUserDepartment2,
} from "../../utils/formatUserDepartment";
import { is } from "date-fns/locale";
import CheckBoxWithTextField from "../../components/field/CheckBoxWithTextField";
import FollowUpTable from "../../components/attribute/FollowUpTable";
import { set } from "lodash";
import FollowUpPopUp from "../../components/popup/FollowUpPopUp";

const RenderEachColor = ({ row, index, checkBox, setCheckBox, thisList }) => {
  const [value, setValue] = React.useState("");
  const [isEdit, setIsEdit] = React.useState(false);

  const onClickUpdateFlag = async (value, flag_id) => {
    try {
      await api.put(`/flags/${flag_id}`, { label: value });

      const checkUpdate = sessionStorage.getItem("updateList");

      let newList = [];

      if (checkUpdate) {
        newList = FunctionCloneDeep([...JSON.parse(checkUpdate)]);
      } else {
        newList = FunctionCloneDeep([...thisList]);
      }

      const findChangeList = newList.findIndex((i) => {
        return i._id === flag_id;
      });

      newList[findChangeList].label = value;

      sessionStorage.setItem("updateList", JSON.stringify(newList));

      setIsEdit(false);
    } catch (error) {
      console.error("ERROR :", error);
    }
  };

  React.useEffect(() => {
    setValue(row?.label);
  }, []);

  return (
    <>
      <div className="mx-0 md:mx-0">
        <input
          type="checkbox"
          name="numberflag"
          value={checkBox}
          checked={checkBox === index}
          className="ml-0 md:ml-36 lg:ml-56 xl:ml-56"
          onClick={(e) => {
            e.stopPropagation();
            setCheckBox(index);
          }}
        />
      </div>
      <div
        style={{
          backgroundColor: `${row?.color}`,
        }}
        className={`-ml-10  md:ml-0  w-28 md:w-40 lg:w-60 xl:w-60 `}
      ></div>
      <div>
        <input
          disabled={!isEdit}
          className="border-2 rounded-lg p-2 w-40 md:w-40 lg:w-60 xl:w-60"
          value={value}
          onChange={({ target: { value } }) => {
            value.stopPropagation();
            setValue(value);
          }}
        />
      </div>
      <div></div>
      {/* //KEY:IDONTKNOWWHATITIS */}
    </>
  );
};

const HomeModalBodyFlag = ({ task, listFlags = [], refetch }) => {
  const [open, setOpen] = React.useState(false);
  const [checkBox, setCheckBox] = React.useState(-1);
  const [idFlag, setIDFlag] = React.useState("");
  const [codeColor, setCodeColor] = React.useState("");
  const [thisList, setThisList] = React.useState([]);

  const { user } = useSelector((state) => state.auth);

  const isNurse = user?.user_department === "NURSE";

  React.useEffect(() => {
    if (task?.original?.flag_id) {
      const findWhere = listFlags?.findIndex((i) => {
        return i._id === task?.original?.flag_id;
      });
      // console.log("checkBox", { checkBox, task, findWhere });
      setCheckBox(findWhere);
    }
  }, [task]);

  React.useEffect(() => {
    let mount = true;
    if (thisList?.length === 0) {
      setThisList(listFlags);
    } else if (open) {
      const checkUpdate = sessionStorage.getItem("updateList");
      if (checkUpdate) {
        setThisList(JSON.parse(checkUpdate));
      }
    }
    return () => {
      mount = false;
    };
  }, [listFlags, open]);

  const onClickAddflag = async (formatColor) => {
    try {
      await api.put(`/tasks/${task?.original?.id}`, {
        flag_color: formatColor.codeColor,
        flag_id: formatColor.idFlag,
        flag_number: checkBox,
      });
    } catch (error) {
      console.log("ERROR :", error);
    }
  };

  return (
    <React.Fragment>
      <div
        className="flex justify-center items-center  mx-auto rounded-md p-2 cursor-pointer  text-white hover:bg-gray-200 bg-white  w-auto md:w-auto shadow-md"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        {task?.original?.flag_color && task?.original?.flag_color !== "none" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill={
              checkBox !== -1
                ? listFlags[checkBox]?.color
                : task?.original?.flag_id
            }
            className="w-4 h-4"
          >
            <path d="M3.5 2.75a.75.75 0 00-1.5 0v14.5a.75.75 0 001.5 0v-4.392l1.657-.348a6.449 6.449 0 014.271.572 7.948 7.948 0 005.965.524l2.078-.64A.75.75 0 0018 12.25v-8.5a.75.75 0 00-.904-.734l-2.38.501a7.25 7.25 0 01-4.186-.363l-.502-.2a8.75 8.75 0 00-5.053-.439l-1.475.31V2.75z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#CDCDCD"
            className="w-3 h-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        )}
      </div>
      {open && (
        <CelesStandardPopUp
          panel={
            <div className="relative  w-auto  h-auto rounded-xl  bg-white p-4 md:p-4">
              <div className="flex flex-col justify-center items-center    space-y-2 m-4">
                Flag
                <div className="grid grid-cols-4 gap-6 md:gap-2 lg:gap-2 xl:gap-2 py-2">
                  {thisList?.map((row, index) => {
                    return (
                      <RenderEachColor
                        key={index}
                        row={row}
                        index={index}
                        checkBox={checkBox}
                        setCheckBox={setCheckBox}
                        task={task}
                        refetch={refetch}
                        setOpen={setOpen}
                        thisList={thisList}
                      />
                    );
                  })}
                </div>
                <div className="grid grid-cols-2 gap-4 py-4">
                  <button
                    type="button"
                    className=" text-textgrayalist hover:text-bgblue  border-transparent border  text-base rounded-md  hover:border-blue-400 px-4 py-1  font-medium hover:shadow-lg shadow-md "
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(false);

                      onClickAddflag(
                        formatColorFlagNew(checkBox, setCodeColor, setIDFlag)
                      );
                    }}
                  >
                    Save
                  </button>

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(false);
                    }}
                    className=" text-textgrayalist hover:text-bgblue  border-transparent border  text-base rounded-md  hover:border-blue-400 px-4 py-1  font-medium hover:shadow-lg shadow-md "
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          }
          open={open}
          setOpen={setOpen}
        />
      )}
    </React.Fragment>
  );
};

const getstylebycall = (Call) => {
  return " text-blue-500 hover:text-blue-700 font-bold ";
  // return " text-gray-500";
};

const HookStatusTitle = (input) => {
  if (input === "ALL") {
    return "All Patient";
  } else if (input === "FOLLOW UP LIST") {
    return <div className="">FOLLOW UP LIST</div>;
  } else {
    return input;
  }
};

const HookStatusSearch = (nav, inputSearch) => {
  if (nav === "ALL") {
    return "?sort=final_date_success:1";
  } else if (nav === "WAITING LIST") {
    return `?status=in:waiting,waiting_full${
      inputSearch ? `&search=${inputSearch}` : ""
    }`;
  } else if (nav === `FOLLOW UP LIST`) {
    return `?status=on_iv_tube${inputSearch ? `&search=${inputSearch}` : ""}`;
  } else if (nav === `COMPLETED`) {
    return `?status=completed${inputSearch ? `&search=${inputSearch}` : ""}`;
  } else if (nav === `DECEASED`) {
    return `?status=deceased${inputSearch ? `&search=${inputSearch}` : ""}`;
  } else {
    return `?status=unfollow${inputSearch ? `&search=${inputSearch}` : ""}`;
  }
};

const TableView = ({
  dataDashboard,
  loading,
  setLoading,
  form,
  setField,
  isNurse,
  isHemato,
  userDept,
  isNurseWard,
}) => {
  const navName = [
    "ALL",
    "WAITING LIST",
    "FOLLOW UP LIST",
    "COMPLETED",
    "DECEASED",
    "CENTRAL LINE",
  ];
  const navName2 = ["FOLLOW UP LIST"];
  const navName3 = ["WAITING LIST"];
  const router = useHistory();
  const [updateId, setupdateId] = useState("");
  // const [deleteId, setdeleteId] = useState("");
  const [updateOpen, setUpdateOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");
  const [nav, setNav] = useState("");
  // const [loading, setLoading] = useState(true);
  const [tables, setTables] = useState([]);
  const [count, setCount] = useState(0);
  const [currentLocationOther, setCurrentLocationOther] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [updateNodeId, setUpdateNodeId] = useState("");
  const [updateNode, setUpdateNode] = useState(false);
  const [updateNodeText, setUpdateNodeText] = useState("");
  const [rukkai, setRukkai] = useState(false);
  const [optionWard, setOptionWard] = useState("");
  const [optionOther, setOptionOther] = useState("");
  const [otherWardName, setOtherWardName] = useState("");
  const [ward, setWard] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const { user } = useSelector((state) => state.auth);
  const [sort, setSort] = useState(false);
  const { confirm } = useSweetAlert();
  const [keepReport, setKeepReport] = useState(false);
  const [updateKeep, setUpdateKeep] = useState("");
  const [reasonKeep, setReason] = useState({ label: "", value: "" });
  const [reasonOtherKeep, setReasonOtherKeep] = useState("");
  const [followUpTable, setFollowUpTable] = useState([]);
  const [selectedFollowIds, setSelectedFollowIds] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [wellReceived, setWellReceived] = useState(false);

  // console.log("followUpTable", followUpTable);
  // console.log("selectedFollowIds", selectedFollowIds);

  const { userDepartmentFromSession } = userDepStore();

  const currentUserDept = user?.user_department;

  const superAN =
    user?.user_department === "super_AN" || user?.user_department === "AN";

  let limit = 50;

  const selectedNames = followUpTable
    .filter((follow) => selectedFollowIds[follow.id])
    .map((follow) => follow.name);

  const totalAbnormalFollow = selectedNames.length;

  const getFollowUpList = async () => {
    try {
      const { data } = await api.get(`/follows/department`, {
        params: {
          line_status_abnormal: true,
          acknowledge: true,
          user_department: superAN
            ? getUserDepartment2(userDepartmentFromSession)
            : getUserDepartment2(userDept),
        },
      });

      const followz = data?.follows;

      const followUpTable = await Promise.all(
        followz?.map(async (follow, index) => {
          const { data: taskData } = await api.get(`/tasks/${follow.task_id}`);
          const fullName = `${taskData?.task?.full_name} ${taskData?.task?.last_name}`;
          return {
            id: follow?._id,
            number: Number(index + 1),
            HN: taskData?.task?.hn,
            name: fullName,
            user_department: follow?.user_department,
            task_id: follow?.task_id,
            summary_type: follow?.summary_type,
          };
        })
      );

      const initIds = {};
      for (const follow of followUpTable) {
        initIds[follow.id] = false;
      }

      setSelectedFollowIds(initIds);
      setFollowUpTable(followUpTable);
    } catch (error) {
      console.error("Error fetching follow-up list:", error);
    }
  };

  const onConfirmAcknowlege = async () => {
    const selectedFollowIdsArray = Object.keys(selectedFollowIds).filter(
      (id) => selectedFollowIds[id]
    );

    for (const id of selectedFollowIdsArray) {
      try {
        await api.put(`/follows/${id} `, {
          acknowledge: false,
        });
      } catch (error) {
        console.error("Error acknowledging follow-up:", error);
      } finally {
        setWellReceived(false);
        getFollowUpList();
      }
    }
  };

  const handleCancel = () => {
    console.log("hello");
    setCheckAll(false);
    setSelectedFollowIds((prev) => {
      let temp = { ...prev };
      Object.keys(temp).forEach((key) => {
        temp[key] = false;
      });
      return { ...temp };
    });

    setWellReceived(false);
  };

  useEffect(() => {
    getFollowUpList();
  }, [userDepartmentFromSession]);

  useEffect(() => {
    const lastTab = sessionStorage.getItem("last-tab");
    if (lastTab && !isNurse && !isNurseWard && !isHemato) {
      setNav(lastTab);
    }
    if (isHemato) {
      setNav(navName3[0]);
      return;
    }
    if (isNurse || isNurseWard) {
      setNav(navName2[0]);
      return;
    } else {
      setNav(navName[2]);
    }
  }, []);

  const listFlags = [
    // 1
    {
      _id: "63bd371f8649caa59294bff9",
      color: "#FFCC00",
      label: "Withdrawal occlusion",
    },
    // 2
    {
      _id: "63bd372a8649caa59294bffd",
      color: "#FF0000",
      label: "Complete occlusion",
    },
    // 3
    {
      _id: "63bd37418649caa59294c005",
      color: "#FF0099",
      label: "สายรั่ว",
    },
    // 4
    {
      _id: "63bd37398649caa59294c003",
      color: "#66CCFF",
      label: "แผล/แขนมีปัญหา",
    },
    // 5
    {
      _id: "63bd37348649caa59294c001",
      color: "#0000FF",
      label: "Other",
    },
    // 6
    {
      _id: "63bd37578649caa59294c00b",
      color: "none",
      label: "none",
    },
  ];

  useEffect(() => {
    setPages(Math.ceil(dataDashboard.total / limit));
  }, [dataDashboard]);

  useUpdateEffect(() => {
    if (userDepartmentFromSession) {
      LoadData();
    }
  }, [userDepartmentFromSession]);

  useDebounceEffect(
    () => {
      let mounted = true;
      if (page) {
        LoadData();
      }
      return () => {
        mounted = false;
      };
    },
    [page, nav, sort, inputSearch],
    500
  );

  const upDateKeepForReport = async (id) => {
    if (!reasonKeep.value) {
      toast.error("กรุณาเลือกเหตุผล", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    try {
      await api.post(`/historys`, {
        name: user?.fullname,
        action: "นำข้อมูลออก",
        task_id: id,
        user_department: currentUserDept,
      });
      await api.put(`/tasks/${id}`, {
        status: "keep_for_report",
        cancel_date: new Date(),
        reason_keep: reasonKeep,
        reason_othrtkeep: reasonOtherKeep !== "" ? reasonOtherKeep : "",
      });
      setKeepReport(false);
      setReason("");
      setReasonOtherKeep("");
      LoadData();
    } catch (error) {
      console.error("ERROR :", error);
    } finally {
      setRukkai(true);
    }
  };

  useEffect(() => {
    if (rukkai) {
      toast.success("ดำเนินการเสร็จสิ้น", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {
        setRukkai(false);
      }, 3000);
    } else {
      return;
    }
  }, [rukkai]);

  useEffect(() => {
    if (updateId.length === 24) {
      setUpdateOpen(true);
    }
  }, [updateId]);

  useEffect(async () => {
    if (updateNodeId.length === 24) {
      const { data } = await api.get(`/tasks/${updateNodeId}`);
      console.log("running", data);
      if (data?.task?.other_description) {
        setUpdateNodeText(data.task.other_description);
      }
      setUpdateNode(true);
    }
  }, [updateNodeId]);

  const LoadData = async () => {
    try {
      setLoading(true);

      const checkUser = userDepartmentFromSession
        ? getUserDepartment(userDepartmentFromSession)
        : getUserDepartment(user?.user_department);

      const querySearch =
        nav === "ALL"
          ? [inputSearch ? `${`?search=${inputSearch}`}` : "?"]
          : `${HookStatusSearch(nav, inputSearch)}`;

      const sortbydate =
        nav === "WAITING LIST"
          ? "&sort=promise:1"
          : sort && nav !== "WAITING LIST"
          ? "&sort=final_date_success:1"
          : "&sort=final_date_success:-1";

      const isIR = getUserDepartment2(currentUserDept) === "IR";
      const isNavAll = nav === "ALL";

      const { data } = await api.get(
        `/tasks${querySearch}${sortbydate}&skip=${
          (page - 1) * limit
        }&limit=${limit}`,
        {
          params: {
            user_department: superAN
              ? checkUser
              : getUserDepartment2(currentUserDept),
            use_hn:
              isIR && inputSearch?.length === 8 && isNavAll ? true : false,
          },
        }
      );

      //find duplicated data
      const findDuplicated = data?.tasks?.filter((item, index) => {
        return (
          data?.tasks?.findIndex((i) => {
            return i?._id === item?._id;
          }) !== index
        );
      });

      console.log("findDuplicated", findDuplicated);

      setTables(
        data?.tasks?.map((task, index) => {
          const initialLine =
            task?.initital_line !== "อื่นๆ"
              ? task?.initital_line
              : task?.initital_line_other;

          return {
            id: task?._id,
            number: Number(index + 1),
            HN: task?.hn,
            name: task?.full_name + "  " + task?.last_name,
            // last_name: task?.last_name,
            last: task?.follow?.date_follow
              ? new Date(task?.follow?.date_follow).toLocaleDateString("th-TH")
              : "-",
            status: task?.status,
            // cxr: new Date(task?.x_ray_date).toLocaleDateString("th-TH"),
            cxr: task?.x_ray_date ? "มี" : "ไม่มี",
            // tel: task?.phone_number,
            tel: task?.ward?.phone
              ? task?.ward?.phone
              : task?.phone_number
              ? task?.phone_number
              : "-",
            due_date: task?.line_is_no_loanger,
            other_description: task?.other_description,
            user_department: task?.user_department,
            // start : task?.paper_date ,
            start: new Date(task?.createdAt).toLocaleDateString("th-TH"),
            date_indwelling: task?.final_date_success ? (
              new Date(task?.final_date_success).toLocaleDateString("th-TH")
            ) : (
              <>-</>
            ),
            // due: "-",
            location: task?.other_ward_name
              ? task?.other_ward_name
              : task?.ward?.label,
            current_location: task?.current_location,
            userName: task?.recorder,
            flag_color: task?.flag_color,
            flag_id: task?.flag_id,
            flag_number: task?.flag_number,
            note_follow: task?.note_follow,
            paper_time: task?.paper_time,
            paper_date: task?.paper_date
              ? new Date(task?.paper_date).toLocaleDateString("th-TH")
              : "-",
            promise: task?.promise
              ? new Date(task?.promise).toLocaleDateString("th-TH")
              : "-",

            keep: task?.reason_othrtkeep
              ? task?.reason_othrtkeep
              : task?.reason_keep?.label
              ? task?.reason_keep?.label
              : "",

            cancelDate:
              task?.status === "keep_for_report" &&
              (task?.cancel_date
                ? new Date(task?.cancel_date).toLocaleDateString("th-TH")
                : new Date(task?.updatedAt).toLocaleDateString("th-TH")),

            initialLine: initialLine,
          };
        })
      );

      setCount(data?.count);
    } catch (error) {
      console.error("ERROR TO LOAD  DATA : ", error);
    } finally {
      setLoading(false);
    }
  };

  const FunctionFormatBeToAd = (date) => {
    const splitDate = date.split("/");
    const ad = Number(splitDate[2]) - 543;
    return splitDate[1] + "/" + splitDate[0] + "/" + ad;
  };

  const CheckColorByFollowUpDate = (input) => {
    const thisDay = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
    const thatDay = new Date(new Date(input).setHours(0, 0, 0, 0)).getTime();
    const oneDay = 86400000;

    const howManyDays = (thisDay - thatDay) / oneDay;
    if (howManyDays >= 14) {
      return (
        <div className="bg-white rounded-md p-1 w-auto md:w-auto shadow-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#FF0000"
            class="w-5 h-5"
          >
            <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
            <path
              fill-rule="evenodd"
              d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      );
    } else if (howManyDays >= 7) {
      return (
        <div className="bg-white rounded-md p-1 w-auto md:w-auto shadow-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#FFCC00"
            class="w-5 h-5"
          >
            <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
            <path
              fill-rule="evenodd"
              d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      );
    } else if (howManyDays < 7) {
      return (
        <div className="bg-white rounded-md p-1 w-auto md:w-auto shadow-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#FFFF
        "
            class="w-5 h-5"
          >
            <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
            <path
              fill-rule="evenodd"
              d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      );
    }
  };

  const CheckCXR = (data) => {
    if (data === "มี") {
      return (
        <div className="bg-white rounded-md p-1 w-auto md:w-auto shadow-md">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#FFFF"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      );
    } else if (data === "ไม่มี") {
      return (
        <div className="bg-white rounded-md p-1 w-auto md:w-auto shadow-md">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#FF0000"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      );
    }
  };

  const columns = [
    {
      Header: "NO.",
      accessor: "number",
      Cell: ({ row }) => {
        return (
          <div className={"py-2 text-left "}>
            {row?.index + 1 + (page - 1) * limit}
          </div>
        );
      },
    },
    { Header: "HN", accessor: "HN" },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => {
        return <div className={"py-2 text-left "}>{row?.original?.name}</div>;
      },
    },
    { Header: "แผนก", accessor: "user_department" },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <div
            className={
              "flex justify-center items-center mx-auto p-2 rounded-md  " +
              getStyleByStatus(row.original.status)
            }
          >
            {getStatusByStatus(row.original.status)}
          </div>
          // </div>
        );
      },
    }, //3

    { Header: "เริ่มบันทึก", accessor: "start" },
    { Header: "นัดใส่สาย", accessor: "promise" },

    { Header: "รับใบปรึกษา", accessor: "paper_date" },
    { Header: "เวลา", accessor: "paper_time" },

    {
      Header: (
        // "ใส่สาย",
        <div
          className="cursor-pointer hover:text-indigo-700"
          onClick={() => {
            setSort(!sort);
          }}
        >
          ใส่สาย v
        </div>
      ),

      accessor: "date_indwelling",
      Cell: ({ row }) => {
        return (
          <div
            className={
              "flex justify-center items-center mx-auto p-2 rounded-md"
            }
          >
            {row.original.date_indwelling}
          </div>
        );
      },
    },

    {
      Header: "ถอดสาย",
      accessor: "due",
      Cell: ({ row }) => {
        if (row?.original?.status === "completed") {
          return (
            <div>
              {" "}
              {new Date(row?.original?.due_date).toLocaleDateString(
                "th-TH"
              )}{" "}
            </div>
          );
        } else if (row?.original?.status === "deceased") {
          return (
            <div>
              {" "}
              {new Date(row?.original?.due_date).toLocaleDateString(
                "th-TH"
              )}{" "}
            </div>
          );
        } else if (row?.original?.status === "cancelled") {
          return (
            <div>
              {" "}
              {new Date(row?.original?.due_date).toLocaleDateString(
                "th-TH"
              )}{" "}
            </div>
          );
        } else {
          return <div className=" w-50">-</div>;
        }
      },
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ row }) => {
        if (row?.original?.status === "on_iv_tube") {
          return (
            <div className={"flex gap-2"}>
              <div className=" m-auto flex justify-center font-bold">
                {row.original.location}
              </div>
              <div className=" lg:flex justify-end">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setupdateId(row?.original?.id);
                  }}
                  className="bg-white hover:bg-gray-200 p-2 rounded-md text-white cursor-pointer w-auto md:w-auto shadow-md "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#D3D3D3"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                  </svg>
                </div>{" "}
              </div>
            </div>
          );
        } else {
          if (
            row?.original?.status === "completed" ||
            row?.original?.status === "deceased" ||
            row?.original?.status === "unfollow" ||
            row?.original?.status === "keep_for_report"
          ) {
            return null;
          }

          return (
            <div className={"flex   gap-2 "}>
              <div className=" m-auto flex justify-center font-bold">
                {row.original.location}
              </div>
              <div className="flex justify-end">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setUpdateKeep(row?.original?.id);
                    setKeepReport(true);
                    // deleteLocation(row.original.id);
                    // clickDelete(row?.original?.id);
                  }}
                  className="  bg-white hover:bg-yellow-200 p-2 rounded-md text-white cursor-pointer w-auto md:w-auto shadow-md "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#FFCC00"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3"
                    />
                  </svg>
                </div>{" "}
              </div>
            </div>
          );
        }
      },
    },
    {
      Header: "Call",
      accessor: "tel",
      Cell: ({ row }) => {
        return (
          <div
            className={
              "flex justify-center items-center mx-auto p-2 rounded-md " +
              getstylebycall(row.original.tel)
            }
          >
            {row.original.tel}
          </div>
        );
      },
    }, //4
    {
      Header: "Last F/U",
      accessor: "last",
      Cell: ({ row }) => {
        return (
          <div
            className={
              "flex justify-center items-center mx-auto p-2 rounded-md "
            }
          >
            {row.original.last}
          </div>
        );
      },
    },

    {
      Header: "",
      accessor: "auto_flag",

      Cell: ({ row }) => {
        return (
          <div
            className={"flex justify-center items-center  mx-auto rounded-md  "}
          >
            {CheckColorByFollowUpDate(FunctionFormatBeToAd(row.original.last))}
          </div>
        );
      },
    },
    {
      Header: "CXR",
      accessor: "cxr",

      Cell: ({ row }) => {
        return (
          <div
            className={"flex justify-center items-center  mx-auto rounded-md "}
          >
            {CheckCXR(row.original.cxr)}
          </div>
        );
      },
    },
    ,
    {
      Header: " Flag ",
      accessor: "manual_flag",
      Cell: ({ row }) => {
        return <HomeModalBodyFlag task={row} listFlags={listFlags} />;
      },
    },
    {
      Header: "วันที่ยกเลิก",
      accessor: "cancel_column",
      Cell: ({ row }) => {
        return (
          <div
            className={
              "flex justify-center items-center mx-auto p-2 rounded-md "
            }
          >
            {row?.original?.cancelDate}
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "keep_column",
      Cell: ({ row }) => {
        return (
          <div
            className={
              "flex justify-center items-center mx-auto p-2 rounded-md "
            }
          >
            {row?.original?.keep}
          </div>
        );
      },
    },
    {
      Header: "ชนิดสาย",
      accessor: "initialLine",
      Cell: ({ row }) => {
        return (
          <div
            className={
              "flex justify-center items-center mx-auto p-2 rounded-md "
            }
          >
            {row?.original?.initialLine}
          </div>
        );
      },
    },

    {
      Header: "Other ",
      accessor: "other_description",
      Cell: ({ row }) => {
        return (
          <div
            className="rounded-md cursor-pointer p-2 duration-200 "
            onClick={(e) => {
              e.stopPropagation();
              setUpdateNodeId(row?.original?.id);
            }}
          >
            {row?.original?.other_description ? (
              <div className="text-sm   hover:text-indigo-700  ">
                {row?.original?.other_description}
              </div>
            ) : (
              <div className="text-sm  text-gray-200 hover:text-gray-500">
                กดเพื่อเพิ่ม
              </div>
            )}
          </div>
        );
      },
    },

    {
      Header: "Note",
      accessor: "note_follow",
      Cell: ({ row }) => {
        return (
          <div
            className={
              "flex justify-center items-center mx-auto p-2 rounded-md "
            }
          >
            {row.original.note_follow}
          </div>
        );
      },
    },
  ];

  const onClickUpdateLocation = async () => {
    try {
      await api.post(`/locations`, {
        current_location: ward,
        task_id: updateId,
        other_current_location_name: otherWardName ? otherWardName : "",
        user_department: currentUserDept,
      });

      await api.put(`/tasks/${updateId}`, {
        option_ward: optionWard,
        other_ward_name: otherWardName,
        option_ward_other: optionOther,
        ward: ward,
        current_location: ward?.value,
      });
      setUpdateOpen(false);
      setOptionOther("");
      setOptionWard("");
      setWard("");
      setupdateId("");

      LoadData();
    } catch (error) {
      console.error("ERROR TO UPDATE LOCATION : ", error);
    }
  };

  const onClickUpdateNote = async () => {
    try {
      await api.put(`/tasks/${updateNodeId}`, {
        other_description: updateNodeText,
      });
      setUpdateNodeId("");
      setUpdateNodeText("");
      setUpdateNode(false);
      LoadData();
    } catch (error) {
      console.error("ERROR TO SUBMIT NOTE  : ", error);
    }
  };

  const getOptionWard = () => {
    if (optionWard?.value === "72 ปี") {
      return OptionWard72years;
    } else if (optionWard?.value === "84 ปี") {
      return OptionWard84years;
    } else if (optionWard?.value === "เฉลิมพระเกียรติ") {
      return OptionWardChalerm;
    } else if (optionWard?.value === "พระศรี") {
      return OptionWardPraSri;
    } else if (optionWard?.value === "เด็ก") {
      return OptionWardDek;
    } else if (optionWard?.value === "นวมินทรบพิตร") {
      return OptionWardNavamin;
    } else if (optionWard?.value === "ตึกผู้ป่วยนอก") {
      return OptionWardOutPatient;
    } else if (optionWard?.value === "สยามินทร์") {
      return OptionWardSayamin;
    } else if (optionWard?.value === "ศูนย์โรคหัวใจ") {
      return OptionWardHeart;
    } else if (optionWard?.value === "สลาก") {
      return OptionWardSalak;
    } else if (optionWard?.value === "อุบัติเหตุ") {
      return OptionWardAccident;
    } else if (optionWard?.value === "Short stay") {
      return OptionWardShortStay;
    }
  };
  const getOptionOther = () => {
    if (optionOther?.value === "ประกันสังคม") {
      return OptionWardPrakan;
    } else if (optionOther?.value === "PICU") {
      return OptionWardPICU;
    } else if (optionOther?.value === "ห้องตรวจอื่นๆ") {
      return OptionWardOtherRoom;
    }
  };

  const CreateButton = () => {
    return (
      <div
        onClick={() => {
          router.push("/Info");
        }}
        className="cursor-pointer pl-0 md:pl-2 px-0 md:px-4 flex items-center text-xs md:text-base lg:text-xl font-bold text-bgbluealist gap-2 hover:underline "
      >
        Create
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class=" h-5 w-5 md:h-8 md:w-8"
          viewBox="0 0 20 20"
          fill="#146CE3"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    );
  };

  const selectColumnByNav = () => {
    if (nav === "WAITING LIST") {
      return columns.filter((column) => {
        return (
          column.accessor !== "due" &&
          column.accessor !== "date_indwelling" &&
          column.accessor !== "last" &&
          column.accessor !== "cxr" &&
          column.accessor !== "auto_flag" &&
          column.accessor !== "manual_flag" &&
          column.accessor !== "note_follow" &&
          column.accessor !== "start" &&
          column.accessor !== "keep_column" &&
          column.accessor !== "cancel_column"
        );
      });
    } else if (nav === "FOLLOW UP LIST") {
      return columns.filter((column) => {
        return (
          column.accessor !== "due" &&
          column.accessor !== "start" &&
          column.accessor !== "promise" &&
          column.accessor !== "paper_date" &&
          column.accessor !== "paper_time" &&
          column.accessor !== "keep_column" &&
          column.accessor !== "cancel_column" &&
          column.accessor !== "initialLine"
        );
      });
    } else if (nav === "COMPLETED" || nav === "DECEASED") {
      return columns.filter((column) => {
        return (
          column.accessor !== "start" &&
          column.accessor !== "location" &&
          column.accessor !== "cxr" &&
          column.accessor !== "auto_flag" &&
          column.accessor !== "manual_flag" &&
          column.accessor !== "start" &&
          column.accessor !== "promise" &&
          column.accessor !== "paper_date" &&
          column.accessor !== "paper_time" &&
          column.accessor !== "keep_column" &&
          column.accessor !== "cancel_column" &&
          column.accessor !== "initialLine"
        );
      });
    } else if (nav === "CENTRAL LINE") {
      return columns.filter((column) => {
        return (
          column.accessor !== "start" &&
          column.accessor !== "tel" &&
          column.accessor !== "location" &&
          column.accessor !== "due" &&
          column.accessor !== "last" &&
          column.accessor !== "manual_flag" &&
          column.accessor !== "paper_date" &&
          column.accessor !== "promise" &&
          column.accessor !== "note_follow" &&
          column.accessor !== "paper_time" &&
          column.accessor !== "keep_column" &&
          column.accessor !== "cancel_column" &&
          column.accessor !== "initialLine"
        );
      });
    } else if (nav === "ALL") {
      return columns.filter((column) => {
        return (
          column.accessor !== "tel" &&
          column.accessor !== "paper_time" &&
          column.accessor !== "initialLine"
        );
      });
    } else {
      return columns;
    }
  };

  const selectedNavName = isHemato
    ? navName3
    : isNurse || isNurseWard
    ? navName2
    : navName;

  return (
    <div className="bg-white ">
      <NavClick navName={selectedNavName} nav={nav} setNav={setNav} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <div className=" mt-5 w-full ">
          <div className=" flex justify-between items-center m-2 lg:m-5 ">
            <div className=" text-bgbluealist font-bold text-xs md:text-base lg:text-2xl  ">
              {HookStatusTitle(nav)}
            </div>
            <HomeSearchBar
              setInputSearch={setInputSearch}
              inputSearch={inputSearch}
            />
            {isNurse || isNurseWard ? null : <CreateButton />}
            {updateOpen && (
              <CelesStandardPopUp
                hidden
                panel={
                  <div className="relative  w-full h-full  rounded-xl  bg-white p-4 md:p-4  ">
                    <div className="flex flex-col justify-center items-center   m-4">
                      <div className=" text-xl md:text-xl  text-textinput font-semibold ">
                        {" "}
                        อัพเดต ตึกผู้ป่วย{" "}
                      </div>
                      <Dropdown
                        // title="ตึกผู้ป่วย"
                        // setField={setField}
                        setValue={setOptionWard}
                        name="option_ward"
                        value={optionWard}
                        options={OptionWardBuilding}
                        other_name={`other_ward_name`}
                        other_value={otherWardName}
                        setOtherValue={setOtherWardName}
                      />

                      <div>
                        {optionWard?.value === "ตึกอื่น ๆ" ? (
                          <div>
                            <Dropdown
                              placeholder={"หอผู้ป่วย"}
                              name="option_ward_other"
                              value={optionOther}
                              setValue={setOptionOther}
                              options={OptionWardOther}
                            />
                            {optionOther?.value && (
                              <Dropdown
                                // setField={setField}
                                name="ward"
                                value={ward}
                                setValue={setWard}
                                placeholder={"หอผู้ป่วย"}
                                options={getOptionOther()}
                              />
                            )}
                          </div>
                        ) : (
                          optionWard?.value &&
                          optionWard?.value !== "other_location" && (
                            <Dropdown
                              // setField={setField}

                              name="ward"
                              value={ward}
                              setValue={setWard}
                              placeholder={"หอผู้ป่วย"}
                              options={getOptionWard()}
                            />
                          )
                        )}
                      </div>

                      <div className=" grid grid-cols-2  gap-4 py-4 ">
                        <button
                          onClick={() => onClickUpdateLocation()}
                          type="button"
                          className=" text-textgrayalist hover:text-bgblue  border-transparent border  text-base rounded-md  hover:border-blue-400 px-4 py-1  font-medium hover:shadow-lg shadow-md "
                        >
                          Save
                        </button>

                        <button
                          onClick={() => {
                            setUpdateOpen(false);
                            setupdateId("");
                            setOptionOther("");
                            setOptionWard("");
                            setWard("");
                          }}
                          className=" text-textgrayalist hover:text-bgblue  border-transparent border  text-base rounded-md  hover:border-blue-400 px-4 py-1  font-medium hover:shadow-lg shadow-md "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                }
                open={updateOpen}
                setOpen={setUpdateOpen}
              />
            )}
            {updateNode && (
              <CelesStandardPopUp
                hidden
                panel={
                  <div className="relative  w-auto h-auto  rounded-xl  bg-white p-4 md:p-10">
                    <div className=" flex flex-col justify-center items-center  space-y-2 m-4">
                      <TextField
                        title={"ปัญหา / เรื่องราว"}
                        classNameTitle="md:text-3xl text-xl text-textinput font-semibold my-5 text-center"
                        value={updateNodeText}
                        setValue={setUpdateNodeText}
                      />

                      <div className=" grid grid-cols-2  gap-4 py-4 ">
                        <button
                          onClick={() => {
                            onClickUpdateNote();
                          }}
                          type="button"
                          className=" text-textgrayalist hover:text-bgblue  border-transparent border  text-base rounded-md  hover:border-blue-400 px-4 py-1  font-medium hover:shadow-lg shadow-md "
                        >
                          Save
                        </button>

                        <button
                          onClick={() => {
                            setUpdateNode(false);
                          }}
                          className=" text-textgrayalist hover:text-bgblue  border-transparent border  text-base rounded-md  hover:border-blue-400 px-4 py-1  font-medium hover:shadow-lg shadow-md "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                }
                open={updateNode}
                setOpen={setUpdateNode}
              />
            )}
            {keepReport && (
              <CelesStandardPopUp
                hidden
                panel={
                  <div className="relative  w-full h-full   rounded-xl  bg-white ">
                    <div className=" flex flex-col justify-center items-center  space-y-2 m-1">
                      <div className="justify-center">
                        <Dropdown
                          title="ไม่ดำเนินการต่อ"
                          classNameOther={
                            "text-base mx-0 md:mx-0 lg:mx-0 xl:mx-0    w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full"
                          }
                          classNameTitle={
                            "text-lg text-textinput font-semibold my-5 mx-10"
                          }
                          placeholder={"โปรดระบุ"}
                          setValue={setReason}
                          name="reason_keep"
                          value={reasonKeep}
                          options={OptionsReasonKeep}
                          other_name={`other_reason_keep_name`}
                          other_value={reasonOtherKeep}
                          setOtherValue={setReasonOtherKeep}
                        />
                        <div className="flex justify-center gap-2 mt-5 p-5">
                          {isNurse ? null : (
                            <button
                              onClick={() => {
                                upDateKeepForReport(updateKeep);
                              }}
                              type="button"
                              className=" text-textgrayalist hover:text-bgblue  border-transparent border  text-base rounded-md  hover:border-blue-400 px-4 py-1  font-medium hover:shadow-lg shadow-md "
                            >
                              Save
                            </button>
                          )}
                          <button
                            onClick={() => {
                              setKeepReport(false);
                              setReason("");
                              setReasonOtherKeep("");
                            }}
                            className=" text-textgrayalist hover:text-bgblue  border-transparent border  text-base rounded-md  hover:border-blue-400 px-4 py-1  font-medium hover:shadow-lg shadow-md "
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                open={keepReport}
                setOpen={() => {}}
              />
            )}

            <FollowUpPopUp
              wellReceived={wellReceived}
              setWellReceived={setWellReceived}
              totalAbnormalFollow={totalAbnormalFollow}
              selectedNames={selectedNames}
              onConfirmAcknowlege={onConfirmAcknowlege}
              handleCancel={handleCancel}
            />
          </div>
          {loading ? (
            <div className="">
              <Loading />
            </div>
          ) : (
            <React.Fragment>
              <div className="flex ">
                {nav === "FOLLOW UP LIST" && followUpTable.length > 0 && (
                  <div style={{ width: 2250 }}>
                    <FollowUpTable
                      followUpTable={followUpTable}
                      setFollowUpTable={setFollowUpTable}
                      selectedFollowIds={selectedFollowIds}
                      setSelectedFollowIds={setSelectedFollowIds}
                      setWellReceived={setWellReceived}
                      wellReceived={wellReceived}
                      checkAll={checkAll}
                      setCheckAll={setCheckAll}
                    />
                  </div>
                )}
              </div>
              <div className="flex ">
                <div
                  style={{
                    width: 450,
                  }}
                >
                  <CelesTableView
                    textcolor="text-textgrayalist font-bold"
                    hoverbg=" hover:bg-tablesection cursor-pointer"
                    bgseccion=""
                    bgcolor=" bg-tablehead text-white"
                    isLoading={false}
                    data={tables}
                    py={"1"}
                    columns={selectColumnByNav()?.slice(0, 3)}
                    // columns={columns}
                    rowAction={(row) => {
                      // console.log(`row`, row);
                      router?.push(`/info/${row?.original?.id}`);
                    }}
                  />
                </div>
                <div
                  style={{
                    width: 1800,
                  }}
                  className=" overflow-x-scroll"
                >
                  <CelesTableView
                    textcolor="text-textgrayalist font-bold  "
                    hoverbg=" hover:bg-tablesection cursor-pointer"
                    bgseccion=" "
                    bgcolor=" bg-tablehead text-white"
                    isLoading={false}
                    data={tables}
                    py={"1 "}
                    columns={selectColumnByNav()?.slice(
                      3,
                      selectColumnByNav().length
                    )}
                    // columns={columns}
                    rowAction={(row) => {
                      // console.log(`row`, row);

                      router?.push(`/info/${row?.original?.id}`);
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          )}

          {/* <Pagination /> */}
          <PaginationMain
            count={count}
            amount={limit}
            value={page}
            setValue={setPage}
          />
        </div>
      </div>
    </div>
  );
};

const Main = () => {
  const [dataDashboard, setDataDashboard] = useState({});
  const [loading, setLoading] = useState("");
  const { form, setField } = useContext(TaskContext);
  const { user } = useSelector((state) => state.auth);
  const [userDept, setUserDept] = useState(user?.user_department);

  // console.log("user", user);

  const isNurse = user?.user_department === "NURSE";
  const isHemato = user?.user_department === "HEMATO";
  const isNurseWard = user?.user_department === "NURSEWARD";
  const isAN =
    user?.user_department === "AN" || user?.user_department === "super_AN";

  const navigate = useHistory();

  // console.log("dataDashboard", dataDashboard);

  useEffect(() => {
    const checkUser = getUserDepartment(user?.user_department);
    // console.log("checkUser", checkUser);
    LoadDashboard(checkUser);
  }, []);

  const onBackButtonEvent = (e) => {
    const whyIhaveToDoThis = sessionStorage.getItem("last");
    navigate.push(`/Info/${whyIhaveToDoThis}`);
  };

  useEffect(() => {
    if (window !== undefined && window !== null) {
      window?.addEventListener("popstate", onBackButtonEvent);
      return () => {
        window?.removeEventListener("popstate", onBackButtonEvent);
      };
    }
  }, []);

  const LoadDashboard = async (userDeptKey) => {
    const { data } = await api.get("/dashboard/status", {
      params: {
        user_department: userDeptKey,
      },
    });
    setDataDashboard(data?.data);
  };

  //() => LoadDashboard("IR")

  // console.log("userDept", userDept);

  return (
    <div className="px-4 md:px-8 lg:px-16 pt-10 bg-bgwhite  ">
      {(!isNurse || user?.role === "ADMIN") && (
        <HomeChartAndData
          userDept={userDept}
          setUserDept={setUserDept}
          data={dataDashboard}
          isAN={isAN}
          LoadDashboard={LoadDashboard}
          isNurseWard={isNurseWard}
        />
      )}
      <TableView
        form={form}
        setField={setField}
        loading={loading}
        setLoading={setLoading}
        dataDashboard={dataDashboard}
        isNurse={isNurse}
        isHemato={isHemato}
        userDept={userDept}
        isNurseWard={isNurseWard}
      />
      {/* <CSVReader parserOptions={importOptions} onFileLoaded={onImportExcel} /> */}
    </div>
  );
};

export default Main;

const importOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
};
