import HeadInfo from "./HeadInfo";
import FromLocation from "../form/FromProcedure";
import Button from "../attribute/Button";
import api from "../../api";
import Loading from "../loading/Loading";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { TaskContext } from "../../contexts/TaskContext";
import { ageCalculator, getAge, UploadFileAws } from "../functions/Functions";

const Procedure = ({ form, setField, onClickSave, isNurse }) => {
  // console.log("FORM : ", form);

  const { initialAttempts, setForm } = useContext(TaskContext);
  const router = useHistory();
  const [loading, setLoading] = useState(false);
  // console.log("form", form);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <div>
      <div className="mt-10 ">
        <HeadInfo form={form} />
      </div>
      <div className="my-10">
        <FromLocation form={form} setField={setField} />
      </div>
      {isNurse ? null : (
        <Button
          // disabled={form?.status !== "waiting_full"}
          onClickSave={() =>
            onClickSave("PROCEDURE NOTE", form?.status !== "waiting_full")
          }
          // onClickCancel={() => onClickSave("CANCEL")}
          onClickCancel={() => router.push("/")}
        />
      )}
    </div>
  );
};
export default Procedure;
