import React, { useEffect } from "react";
import api from "../../../api";
import { useSelector } from "react-redux";

const getUserDepartment = (userDep) => {
  let result = "";
  if (userDep === "super_AN" || userDep === "AN" || userDep === "NURSE") {
    // setUserDept("AN");

    result = "AN";
  } else if (userDep === "special_IR" || userDep === "IR") {
    // setUserDept("IR");
    result = "IR";
  } else if (userDep === "speacial_TR" || userDep === "TR") {
    // setUserDept("TR");
    result = "TR";
  } else if (userDep === "special_PED" || userDep === "PED") {
    // setUserDept("PED");
    result = "PED";
  } else if (userDep === "All") {
    result = "All";
  }
  return result;
};

const LoadProduction = ({
  setLoading,
  setList2,
  setListFollows,
  setCount,
  setListAttempt,
  userSend,
  userDept,
}) => {
  const loadData = async (dataCount, setCount) => {
    try {
      const limit = 100;
      const countCeil = Math.ceil(dataCount.total / limit) - 1;
      let resultList = [];

      for (let index = 0; index <= countCeil; index++) {
        let newTasks = [];
        const { data } = await api.get(
          `/tasks?skip=${index * limit}&limit=${limit}&report=true`,
          {
            params: {
              user_department: "All",
            },
          }
        );
        let tasks = data?.tasks;
        for (const task of tasks) {
          const newTask = {
            ...task,
          };
          newTasks.push(newTask);
        }
        resultList.push(...newTasks);
      }
      setList2(resultList); // List = Task
      // setCount(dataCountState);
    } catch (error) {
      console.log("error", error);
    }
  };

  const LoadDashboard = async () => {
    try {
      const { data } = await api.get("/dashboard/status", {
        params: {
          user_department: "All",
        },
      });
      await loadData(data?.data, setCount);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  LoadDashboard();
};

export const ReportLoadData2 = ({
  setLoading,
  setList2,
  setListFollows,
  setCount,
  setListAttempt,
  userSend,
  userDept,
}) => {
  LoadProduction({
    setLoading,
    setList2,
    setListFollows,
    setCount,
    setListAttempt,
    userSend,
    userDept,
  });
};
