import { useState, createContext, useEffect, useMemo } from "react";
// import reportWorkerFile from "../pages/report/functions/reportWorker";

// create context
const AppContext = createContext({});

// context provider
const AppProvider = ({ children }) => {
  const [user, setUser] = useState({});
  // const reportWorker = useMemo(() => new Worker(reportWorkerFile), []);

  useEffect(() => {
    setUser({
      userName: localStorage.getItem("userName"),
      userId: localStorage.getItem("uid"),
    });
  }, [localStorage]);

  // useEffect(() => {
  //   reportWorker.postMessage("Start");
  // }, []);

  const value = { user, setUser };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
