import { dataTask } from "../../utils/mockData";
import { FunctionGroupingArrayByKey, FunctionCloneDeep } from "radium-omnibus";
import api from "../../api";
import { useEffect, useState } from "react";
const CheckPICCLine = (normal) => {
  const checkPICC = String(normal).toUpperCase().search("PICC");

  if (checkPICC !== -1) {
    return true;
  } else {
    return false;
  }
};
const CheckCentraline = (normal) => {
  const checkDVL = String(normal).toUpperCase().search("DLC");
  const checkCVL = String(normal).toUpperCase().search("CVL");

  if (checkDVL !== -1 || checkCVL !== -1) {
    return true;
  } else {
    return false;
  }
};

const TestBot = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    const { data } = await api.get("/tasks?limit=1000");
    setData(data?.tasks);
  };

  const FormatData = () => {
    let groupData = FunctionCloneDeep(FunctionGroupingArrayByKey(data, "hn"));
    groupData = groupData?.map((row) => {
      // const picc_count = 2;
      // const central_count = 2;

      const picc_count = row?.value?.filter((i) => {
        return CheckPICCLine(i?.catheter?.value);
      });
      const central_count = row?.value?.filter((i) => {
        return CheckCentraline(i?.catheter?.value);
      });

      let clonePicc = FunctionCloneDeep(picc_count);
      let cloneCentral = FunctionCloneDeep(central_count);

      let temp_small_picc = clonePicc?.map((i, j) => {
        return {
          ...i,
          id_picc: j + 1,
        };
      });

      let temp_small_central = cloneCentral?.map((i, j) => {
        return {
          ...i,
          id_picc: j + 1,
        };
      });

      return {
        ...row,
        value: row?.value?.map(async (jow, jindex) => {
          const findSelfPicc = temp_small_picc?.find((i) => {
            return i._id === jow?._id;
          });

          const findSelfCentral = temp_small_central?.find((i) => {
            return i._id === jow?._id;
          });

          let dream_value = {
            hn_count: jindex + 1,
            id_picc: findSelfPicc?.id_picc ? findSelfPicc?.id_picc : 0, //ดูว่าใน value มี picc กี่ครั้ง แล้ว ถ้ามีจำนวนมากกว่า 1 ให้เอา index ที่น้อยว่า ขึ้นก่อน
            id_central: findSelfCentral?.id_central
              ? findSelfCentral?.id_central
              : 0,
            id_picc_trigger: picc_count?.length > 1,
            id_central_trigger: central_count?.length > 1,
          };
          await api.put(`/tasks/${jow?._id}`, {
            ...dream_value,
          });

          return {
            ...jow,
            ...dream_value,
          };
        }),
      };
    });
    console.log("groupData", groupData);
  };

  // dataTask?.map((row) => {
  //   const isPICC = CheckPICCLine(row?.catheter?.value);

  //   console.log("isPICC", isPICC);
  // });
  return (
    <div
      onClick={() => {
        // FormatData();
      }}
    >
      Click{" "}
    </div>
  );
};
export default TestBot;
