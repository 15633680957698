import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountConsultFirst = ({ list, setValue, type, year }) => {
  //a หน้าคือผู้ใหญ่
  //a หลังคือเดือน

  let jan = 0; // 01
  let feb = 0; // 02
  let mar = 0; // 03
  let apr = 0; // 04
  let may = 0; // 05
  let jun = 0; // 06
  let jul = 0; // 07
  let aug = 0; // 08
  let sep = 0; // 09
  let oct = 0; // 10
  let nov = 0; // 11
  let dec = 0; // 12

  if (list?.length > 0) {
    for (let row of list) {
      if (row?.picture_consultation != "" && row?.paper_date != "") {
        const month = row?.paper_date ? row?.paper_date : "";
        const thisMonth = String(month);
        const useMont = thisMonth.substring(5, 7);
        const thisYear = row?.paper_date ? row?.paper_date : "";
        const useYear = thisYear.substring(0, 4);
        if (year === "ALL") {
          if (type === "NONE") {
            if (useMont === "01") {
              jan++;
            } else if (useMont === "02") {
              feb++;
            } else if (useMont === "03") {
              mar++;
            } else if (useMont === "04") {
              apr++;
            } else if (useMont === "05") {
              may++;
            } else if (useMont === "06") {
              jun++;
            } else if (useMont === "07") {
              jul++;
            } else if (useMont === "08") {
              aug++;
            } else if (useMont === "09") {
              sep++;
            } else if (useMont === "10") {
              oct++;
            } else if (useMont === "11") {
              nov++;
            } else if (useMont === "12") {
              dec++;
            }
          } else if (type === "PICC") {
            if (
              FunctionCheckType(
                row?.catheter?.label,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "PICC"
            ) {
              if (useMont === "01") {
                jan++;
              } else if (useMont === "02") {
                feb++;
              } else if (useMont === "03") {
                mar++;
              } else if (useMont === "04") {
                apr++;
              } else if (useMont === "05") {
                may++;
              } else if (useMont === "06") {
                jun++;
              } else if (useMont === "07") {
                jul++;
              } else if (useMont === "08") {
                aug++;
              } else if (useMont === "09") {
                sep++;
              } else if (useMont === "10") {
                oct++;
              } else if (useMont === "11") {
                nov++;
              } else if (useMont === "12") {
                dec++;
              }
            }
          } else if (type === "CENTRAL_LINE") {
            if (
              FunctionCheckType(
                row?.catheter?.label,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "CENTRAL_LINE"
            ) {
              if (useMont === "01") {
                jan++;
              } else if (useMont === "02") {
                feb++;
              } else if (useMont === "03") {
                mar++;
              } else if (useMont === "04") {
                apr++;
              } else if (useMont === "05") {
                may++;
              } else if (useMont === "06") {
                jun++;
              } else if (useMont === "07") {
                jul++;
              } else if (useMont === "08") {
                aug++;
              } else if (useMont === "09") {
                sep++;
              } else if (useMont === "10") {
                oct++;
              } else if (useMont === "11") {
                nov++;
              } else if (useMont === "12") {
                dec++;
              }
            }
          }
        }
        if (year === useYear) {
          if (type === "NONE") {
            if (useMont === "01") {
              jan++;
            } else if (useMont === "02") {
              feb++;
            } else if (useMont === "03") {
              mar++;
            } else if (useMont === "04") {
              apr++;
            } else if (useMont === "05") {
              may++;
            } else if (useMont === "06") {
              jun++;
            } else if (useMont === "07") {
              jul++;
            } else if (useMont === "08") {
              aug++;
            } else if (useMont === "09") {
              sep++;
            } else if (useMont === "10") {
              oct++;
            } else if (useMont === "11") {
              nov++;
            } else if (useMont === "12") {
              dec++;
            }
          } else if (type === "PICC") {
            if (
              FunctionCheckType(
                row?.catheter?.label,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "PICC"
            ) {
              if (useMont === "01") {
                jan++;
              } else if (useMont === "02") {
                feb++;
              } else if (useMont === "03") {
                mar++;
              } else if (useMont === "04") {
                apr++;
              } else if (useMont === "05") {
                may++;
              } else if (useMont === "06") {
                jun++;
              } else if (useMont === "07") {
                jul++;
              } else if (useMont === "08") {
                aug++;
              } else if (useMont === "09") {
                sep++;
              } else if (useMont === "10") {
                oct++;
              } else if (useMont === "11") {
                nov++;
              } else if (useMont === "12") {
                dec++;
              }
            }
          } else if (type === "CENTRAL_LINE") {
            if (
              FunctionCheckType(
                row?.catheter?.label,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "CENTRAL_LINE"
            ) {
              if (useMont === "01") {
                jan++;
              } else if (useMont === "02") {
                feb++;
              } else if (useMont === "03") {
                mar++;
              } else if (useMont === "04") {
                apr++;
              } else if (useMont === "05") {
                may++;
              } else if (useMont === "06") {
                jun++;
              } else if (useMont === "07") {
                jul++;
              } else if (useMont === "08") {
                aug++;
              } else if (useMont === "09") {
                sep++;
              } else if (useMont === "10") {
                oct++;
              } else if (useMont === "11") {
                nov++;
              } else if (useMont === "12") {
                dec++;
              }
            }
          }
        }
        // if (year === "2023") {
        //   if (type === "NONE" && useYear === "2023") {
        //     if (useMont === "01") {
        //       jan++;
        //     } else if (useMont === "02") {
        //       feb++;
        //     } else if (useMont === "03") {
        //       mar++;
        //     } else if (useMont === "04") {
        //       apr++;
        //     } else if (useMont === "05") {
        //       may++;
        //     } else if (useMont === "06") {
        //       jun++;
        //     } else if (useMont === "07") {
        //       jul++;
        //     } else if (useMont === "08") {
        //       aug++;
        //     } else if (useMont === "09") {
        //       sep++;
        //     } else if (useMont === "10") {
        //       oct++;
        //     } else if (useMont === "11") {
        //       nov++;
        //     } else if (useMont === "12") {
        //       dec++;
        //     }
        //   } else if (type === "PICC") {
        //     if (
        //       FunctionCheckType(
        //         row?.catheter?.label,
        //         row?.other_catheter_name,
        //         row?.check_catheter
        //       ) === "PICC" &&
        //       useYear === "2023"
        //     ) {
        //       if (useMont === "01") {
        //         jan++;
        //       } else if (useMont === "02") {
        //         feb++;
        //       } else if (useMont === "03") {
        //         mar++;
        //       } else if (useMont === "04") {
        //         apr++;
        //       } else if (useMont === "05") {
        //         may++;
        //       } else if (useMont === "06") {
        //         jun++;
        //       } else if (useMont === "07") {
        //         jul++;
        //       } else if (useMont === "08") {
        //         aug++;
        //       } else if (useMont === "09") {
        //         sep++;
        //       } else if (useMont === "10") {
        //         oct++;
        //       } else if (useMont === "11") {
        //         nov++;
        //       } else if (useMont === "12") {
        //         dec++;
        //       }
        //     }
        //   } else if (type === "CENTRAL_LINE") {
        //     if (
        //       FunctionCheckType(
        //         row?.catheter?.label,
        //         row?.other_catheter_name,
        //         row?.check_catheter
        //       ) === "CENTRAL_LINE" &&
        //       useYear === "2023"
        //     ) {
        //       if (useMont === "01") {
        //         jan++;
        //       } else if (useMont === "02") {
        //         feb++;
        //       } else if (useMont === "03") {
        //         mar++;
        //       } else if (useMont === "04") {
        //         apr++;
        //       } else if (useMont === "05") {
        //         may++;
        //       } else if (useMont === "06") {
        //         jun++;
        //       } else if (useMont === "07") {
        //         jul++;
        //       } else if (useMont === "08") {
        //         aug++;
        //       } else if (useMont === "09") {
        //         sep++;
        //       } else if (useMont === "10") {
        //         oct++;
        //       } else if (useMont === "11") {
        //         nov++;
        //       } else if (useMont === "12") {
        //         dec++;
        //       }
        //     }
        //   }
        // }
      }
    }
    setValue({
      jan: jan, // 01
      feb: feb, // 02
      mar: mar, // 03
      apr: apr, // 04
      may: may, // 05
      jun: jun, // 06
      jul: jul, // 07
      aug: aug, // 08
      sep: sep, // 09
      oct: oct, // 10
      nov: nov, // 11
      dec: dec, // 12
    });
  }
};

export default ReportCountConsultFirst;
