const { DateTime } = require("luxon");

export const getTotalDaysInMonth = (month, year) => {
  // Create a DateTime object for the given month and year
  const dateTime = DateTime.local(year, month, 1);

  // Get the total number of days in the month
  const totalDays = dateTime.daysInMonth;

  return totalDays;
};
