import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountInfectionAttempt = ({
  list = [],
  setValue = () => {},
  type = "",
  year = "",
  month = "",
}) => {
  let complications_pneumothorax = 0; // 01
  let complications_hemothorax = 0; // 02
  let complications_arterial_puncture = 0; // 03
  let complications_paresthesia = 0; // 04
  let complications_nerve_injury = 0; // 05
  let complications_limb_ischemia_related_to_arterial_puncture = 0; // 06
  let complications_wire = 0; // 07
  let other_complications = 0; // 07
  let total = 0;

  const formattedMonth = month.length === 1 ? `0${month}` : month;

  if (list?.length > 0) {
    if (formattedMonth !== "ALL") {
      list = list.filter((row) => {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);
        const useMonth = thisYear.substring(5, 7);
        return useYear === year && useMonth === formattedMonth;
      });
    }

    for (let row of list) {
      if (row?.final_date_success !== "") {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);

        let attempts = row?.attempts;

        if (year === "ALL" || year === useYear) {
          if (type === "NONE") {
            for (const row of attempts) {
              if (row?.status === "Successful") {
                total++;
              }
              if (row?.complications_pneumothorax) {
                complications_pneumothorax++;
              }
              if (row?.complications_hemothorax) {
                complications_hemothorax++;
              }
              if (row?.complications_arterial_puncture) {
                complications_arterial_puncture++;
              }
              if (row?.complications_paresthesia) {
                complications_paresthesia++;
              }
              if (row?.complications_nerve_injury) {
                complications_nerve_injury++;
              }
              if (row?.complications_wire) {
                complications_wire++;
              }
              // if (
              //   row?.complications_limb_ischemia_related_to_arterial_puncture
              // ) {
              //   complications_limb_ischemia_related_to_arterial_puncture++;
              // }
              if (row?.other_complications) {
                other_complications++;
              }
            }
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            for (const row of attempts) {
              if (row?.status === "Successful") {
                total++;
              }
              if (row?.complications_pneumothorax) {
                complications_pneumothorax++;
              }
              if (row?.complications_hemothorax) {
                complications_hemothorax++;
              }
              if (row?.complications_arterial_puncture) {
                complications_arterial_puncture++;
              }
              if (row?.complications_paresthesia) {
                complications_paresthesia++;
              }
              if (row?.complications_nerve_injury) {
                complications_nerve_injury++;
              }
              if (row?.complications_wire) {
                complications_wire++;
              }

              // if (
              //   row?.complications_limb_ischemia_related_to_arterial_puncture
              // ) {
              //   complications_limb_ischemia_related_to_arterial_puncture++;
              // }
              if (row?.other_complications) {
                other_complications++;
              }
            }
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            for (const row of attempts) {
              if (row?.status === "Successful") {
                total++;
              }
              if (row?.complications_pneumothorax) {
                complications_pneumothorax++;
              }
              if (row?.complications_hemothorax) {
                complications_hemothorax++;
              }
              if (row?.complications_arterial_puncture) {
                complications_arterial_puncture++;
              }
              if (row?.complications_paresthesia) {
                complications_paresthesia++;
              }
              if (row?.complications_nerve_injury) {
                complications_nerve_injury++;
              }
              if (row?.complications_wire) {
                complications_wire++;
              }

              // if (
              //   row?.complications_limb_ischemia_related_to_arterial_puncture
              // ) {
              //   complications_limb_ischemia_related_to_arterial_puncture++;
              // }
              if (row?.other_complications) {
                other_complications++;
              }
            }
          }
        }
        // if (year === useYear) {
        //   if (type === "NONE") {
        //     for (const row of attempts) {
        //       if (row?.status === "Successful") {
        //         total++;
        //       }
        //       if (row?.complications_pneumothorax) {
        //         complications_pneumothorax++;
        //       }
        //       if (row?.complications_hemothorax) {
        //         complications_hemothorax++;
        //       }
        //       if (row?.complications_arterial_puncture) {
        //         complications_arterial_puncture++;
        //       }
        //       if (row?.complications_paresthesia) {
        //         complications_paresthesia++;
        //       }
        //       if (row?.complications_nerve_injury) {
        //         complications_nerve_injury++;
        //       }
        //       // if (
        //       //   row?.complications_limb_ischemia_related_to_arterial_puncture
        //       // ) {
        //       //   complications_limb_ischemia_related_to_arterial_puncture++;
        //       // }
        //       if (row?.other_complications) {
        //         other_complications++;
        //       }
        //     }
        //   }
        //   if (
        //     type === "PICC" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     for (const row of attempts) {
        //       if (row?.status === "Successful") {
        //         total++;
        //       }
        //       if (row?.complications_pneumothorax) {
        //         complications_pneumothorax++;
        //       }
        //       if (row?.complications_hemothorax) {
        //         complications_hemothorax++;
        //       }
        //       if (row?.complications_arterial_puncture) {
        //         complications_arterial_puncture++;
        //       }
        //       if (row?.complications_paresthesia) {
        //         complications_paresthesia++;
        //       }
        //       if (row?.complications_nerve_injury) {
        //         complications_nerve_injury++;
        //       }
        //       // if (
        //       //   row?.complications_limb_ischemia_related_to_arterial_puncture
        //       // ) {
        //       //   complications_limb_ischemia_related_to_arterial_puncture++;
        //       // }
        //       if (row?.other_complications) {
        //         other_complications++;
        //       }
        //     }
        //   }
        //   if (
        //     type === "CENTRAL_LINE" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     for (const row of attempts) {
        //       if (row?.status === "Sucessful") {
        //         total++;
        //       }
        //       if (row?.complications_pneumothorax) {
        //         complications_pneumothorax++;
        //       }
        //       if (row?.complications_hemothorax) {
        //         complications_hemothorax++;
        //       }
        //       if (row?.complications_arterial_puncture) {
        //         complications_arterial_puncture++;
        //       }
        //       if (row?.complications_paresthesia) {
        //         complications_paresthesia++;
        //       }
        //       if (row?.complications_nerve_injury) {
        //         complications_nerve_injury++;
        //       }
        //       // if (
        //       //   row?.complications_limb_ischemia_related_to_arterial_puncture
        //       // ) {
        //       //   complications_limb_ischemia_related_to_arterial_puncture++;
        //       // }
        //       if (row?.other_complications) {
        //         other_complications++;
        //       }
        //     }
        //   }
        // }
        // if (year === "2023" && useYear === "2023") {
        //   if (type === "NONE") {
        //     for (const row of attempts) {
        //       if (row?.complications_pneumothorax) {
        //         complications_pneumothorax++;
        //       }
        //       if (row?.complications_hemothorax) {
        //         complications_hemothorax++;
        //       }
        //       if (row?.complications_arterial_puncture) {
        //         complications_arterial_puncture++;
        //       }
        //       if (row?.complications_paresthesia) {
        //         complications_paresthesia++;
        //       }
        //       if (row?.complications_nerve_injury) {
        //         complications_nerve_injury++;
        //       }
        //       // if (
        //       //   row?.complications_limb_ischemia_related_to_arterial_puncture
        //       // ) {
        //       //   complications_limb_ischemia_related_to_arterial_puncture++;
        //       // }
        //       if (row?.other_complications) {
        //         other_complications++;
        //       }
        //     }
        //   }
        //   if (
        //     type === "PICC" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     for (const row of attempts) {
        //       if (row?.complications_pneumothorax) {
        //         complications_pneumothorax++;
        //       }
        //       if (row?.complications_hemothorax) {
        //         complications_hemothorax++;
        //       }
        //       if (row?.complications_arterial_puncture) {
        //         complications_arterial_puncture++;
        //       }
        //       if (row?.complications_paresthesia) {
        //         complications_paresthesia++;
        //       }
        //       if (row?.complications_nerve_injury) {
        //         complications_nerve_injury++;
        //       }
        //       // if (
        //       //   row?.complications_limb_ischemia_related_to_arterial_puncture
        //       // ) {
        //       //   complications_limb_ischemia_related_to_arterial_puncture++;
        //       // }
        //       if (row?.other_complications) {
        //         other_complications++;
        //       }
        //     }
        //   }
        //   if (
        //     type === "CENTRAL_LINE" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     for (const row of attempts) {
        //       if (row?.complications_pneumothorax) {
        //         complications_pneumothorax++;
        //       }
        //       if (row?.complications_hemothorax) {
        //         complications_hemothorax++;
        //       }
        //       if (row?.complications_arterial_puncture) {
        //         complications_arterial_puncture++;
        //       }
        //       if (row?.complications_paresthesia) {
        //         complications_paresthesia++;
        //       }
        //       if (row?.complications_nerve_injury) {
        //         complications_nerve_injury++;
        //       }
        //       // if (
        //       //   row?.complications_limb_ischemia_related_to_arterial_puncture
        //       // ) {
        //       //   complications_limb_ischemia_related_to_arterial_puncture++;
        //       // }
        //       if (row?.other_complications) {
        //         other_complications++;
        //       }
        //     }
        //   }
        // }
      }
    }
    setValue({
      complications_pneumothorax: complications_pneumothorax, // 01
      complications_hemothorax: complications_hemothorax, // 02
      complications_arterial_puncture: complications_arterial_puncture, // 03
      complications_paresthesia: complications_paresthesia, // 04
      complications_nerve_injury: complications_nerve_injury, // 05
      complications_limb_ischemia_related_to_arterial_puncture:
        complications_limb_ischemia_related_to_arterial_puncture, // 06
      complications_wire: complications_wire, // 07
      other_complications: other_complications, // 07
      total: total,
    });
    return total;
  }
};

export default ReportCountInfectionAttempt;
