import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountReasonByStatatus = ({ list, setValue, year }) => {
  let alive = 0;
  let dead = 0;
  let checkStatusTask = [];

  if (list?.length > 0) {
    for (let row of list) {
      if (
        row?.final_date_success !== ""
        // FunctionCheckType(
        //   row?.catheter?.label,
        //   row?.other_catheter_name,
        //   row?.check_catheter
        // ) === "PICC"
      ) {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);

        if (year === "ALL" || year === useYear) {
          if (row?.status === "completed") {
            alive++;
            checkStatusTask.push(row?._id);
          }
          if (row?.status === "deceased") {
            dead++;
            checkStatusTask.push(row?._id);
          }
        }
        // if (year === useYear) {
        //   if (row?.status === "completed") {
        //     alive++;
        //   }
        //   if (row?.status === "deceased") {
        //     dead++;
        //   }
        // }
        // if (year === "2023" && useYear === "2023") {
        //   if (row?.status === "completed") {
        //     alive++;
        //   }
        //   if (row?.status === "deceased") {
        //     dead++;
        //   }
        // }

        // let follows = row?.follows;
        // for (const row of follows) {
        //   if (year === "ALL") {
        //     if (
        //       row?.summary_alive === true &&
        //       row?.summary_deceased === false
        //     ) {
        //       alive++;
        //     }
        //     if (
        //       row?.summary_deceased === true &&
        //       row?.summary_alive === false
        //     ) {
        //       dead++;
        //     }
        //   }
        //   if (year === "2022" && useYear === "2022") {
        //     if (
        //       row?.summary_alive === true &&
        //       row?.summary_deceased === false
        //     ) {
        //       alive++;
        //     }
        //     if (
        //       row?.summary_deceased === true &&
        //       row?.summary_alive === false
        //     ) {
        //       dead++;
        //     }
        //   }
        //   if (year === "2023" && useYear === "2023") {
        //     if (
        //       row?.summary_alive === true &&
        //       row?.summary_deceased === false
        //     ) {
        //       alive++;
        //     }
        //     if (
        //       row?.summary_deceased === true &&
        //       row?.summary_alive === false
        //     ) {
        //       dead++;
        //     }
        //   }
        // }
      }
    }
    setValue({
      alive: alive,
      dead: dead,
      total: alive + dead,
      alivePercent: Math.round((alive / (alive + dead)) * 100) + "%",
      deadPercent: Math.round((dead / (alive + dead)) * 100) + "%",
      checkStatusTask: checkStatusTask,
    });
  }
};

export default ReportCountReasonByStatatus;
