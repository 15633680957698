import React, { useState } from "react";
import DonutChart from "../../../components/chart/DonutChart";
import DashBord from "../../../components/dashbordsection/DashBord";
import classNames from "classnames";
import userDepStore from "../../../_store/userDepStore";
import { getUserDepartment } from "../../../utils/formatUserDepartment";

const HomeChartAndData = React.memo(
  ({ userDept, data, isAN, LoadDashboard, isNurseWard }) => {
    const [isActivated, setIsActivated] = useState(getUserDepartment(userDept));

    // console.log(`isActivated: `, isActivated);
    // console.log("userDept", userDept);

    const { setUserDepartmentFromSession } = userDepStore();

    const onChangeUserDept = async (value) => {
      setUserDepartmentFromSession(value);
      setIsActivated(value);
      await LoadDashboard(value);
    };

    return (
      <div>
        {isAN || isNurseWard ? (
          <div className=" flex gap-3 justify-center items-center mb-10">
            <button
              className={classNames(
                "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
                isActivated === "All"
                  ? "bg-blue-500 text-gray-900"
                  : "bg-blue-200"
              )}
              onClick={() => onChangeUserDept("All")}
            >
              All
            </button>
            <button
              className={classNames(
                "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
                isActivated === "AN"
                  ? "bg-blue-500 text-gray-900"
                  : "bg-blue-200"
              )}
              onClick={() => onChangeUserDept("AN")}
            >
              AN
            </button>
            <button
              className={classNames(
                "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
                isActivated === "IR"
                  ? "bg-blue-500 text-gray-900"
                  : "bg-blue-200"
              )}
              onClick={() => onChangeUserDept("IR")}
            >
              IR
            </button>
            <button
              className={classNames(
                "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
                isActivated === "TR"
                  ? "bg-blue-500 text-gray-900"
                  : "bg-blue-200"
              )}
              onClick={() => onChangeUserDept("TR")}
            >
              TR
            </button>
            <button
              className={classNames(
                "bg-blue-200 h-10 w-32 rounded-lg text-lg font-bold text-gray-700 flex justify-center items-center hover:bg-blue-500 hover:text-gray-900",
                isActivated === "PED"
                  ? "bg-blue-500 text-gray-900"
                  : "bg-blue-200"
              )}
              onClick={() => onChangeUserDept("PED")}
            >
              PED
            </button>
          </div>
        ) : null}
        <div className="grid md:grid-cols-2 grid-cols-1 gap-10 md:gap-x-10 lg:gap-x-40 lg:gap-y-5 mb-10  ">
          <div className=" text-2xl lg:text-4xl font-bold ">Dashboard</div>
          <div className=" text-lg lg:text-xl font-bold  md:mt-0 mt-5 ">
            จำนวนคนไข้ทั้งหมด {data?.total} คน
          </div>
          <div className=" bg-chartbg w-auto rounded-xl shadow-md  text-lg ">
            <div className=" pl-10 pt-10 font-bold text-base lg:text-xl">
              Tasks
            </div>
            <DonutChart dataChart={data} />
          </div>
          <div className="">
            <DashBord dataChart={data} />
          </div>
        </div>
      </div>
    );
  }
);

export default HomeChartAndData;
