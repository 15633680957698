import { useState, createContext, useEffect } from "react";
import { fetchDoctors } from "../api/doctorandnurse";
import { useSelector } from "react-redux";
import { getUserDepartment } from "../utils/formatUserDepartment";

// create context
const ListDoctorAndNurseContext = createContext({});

// context provider
const ListDoctorAndNurseProvider = ({ children }) => {
  const [listName, setListName] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const userDept = user?.user_department;

  useEffect(() => {
    loadDocAndNurse();
  }, [localStorage]);

  const mapDocAndNurse = (listName) => {
    return listName?.map((row, index) => {
      return {
        value: (row?.fullname).replace(" ", ""),
        label: row?.fullname,
        role: row?.role,
      };
    });
  };

  const loadDocAndNurse = async () => {
    try {
      setLoading(true);
      const { docandnurses } = await fetchDoctors();

      const filterDocAndNurse = docandnurses.filter(
        (row) => row?.user_department === getUserDepartment(userDept)
      );

      setListName(mapDocAndNurse(filterDocAndNurse));
    } catch (error) {
      console.error("ERORR :", error);
    } finally {
      setLoading(false);
    }
  };

  const value = { listName, setListName };

  return (
    <ListDoctorAndNurseContext.Provider value={value}>
      {children}
    </ListDoctorAndNurseContext.Provider>
  );
};

export { ListDoctorAndNurseContext, ListDoctorAndNurseProvider };
