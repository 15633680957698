import { subTitleStyle, titleStyle } from "../../hooks/useClassName";
import DatePickerField from "./DatePickerField";
import TextField from "./TextField";

const CheckBoxMulti = ({
  title,
  classNameTitle,
  name,
  value,
  setField,
  other_name,
  other_value,
  className = " flex justify-start items-center  space-x-2 rounded-xl md:mx-10 lg:mx-10 xl:mx-9 ",
  // classNameLabel = "md:text-lg text-sm font-normal text-textinput ",
  labelCustom = "md:text-lg xl:text-base font-normal text-textinput",
  label,
  disabled = false,
  useDynamicDisable,
  disableValue,
  setStateObject = false,
  setValue = false,
  classnameSelect = "grid md:grid-cols-2  lg:grid-cols-2  xl:grid-cols-2 my-5 md:mx-24 lg:mx-24 xl:mx-10",
  classNameCover = "flex justify-between px-10 md:px-2",
}) => {
  if (!name) return <div>โปรดใส่ Field ให้ครบ</div>;
  const last_array_name = name?.slice(name?.length - 1);
  const last_array_value = value?.slice(value?.length - 1);
  const ckeck_other_true =
    last_array_name[0]?.includes("other") && last_array_value[0] === true;

  return (
    <div>
      <div className={classNameTitle ? classNameTitle : titleStyle}>
        {title}
      </div>
      <div className={classNameCover}>
        <div className={classnameSelect}>
          {name?.map((row, index) => {
            const onChangeCheckbox = (e) => {
              const value = e.target.checked;
              if (setValue && setStateObject) {
                setValue((prev) => {
                  let temp = { ...prev };
                  temp[name[index]] = value;
                  return { ...temp };
                });
              } else if (setField) {
                setField(name[index])(value);
              } else {
                setValue(value);
              }
            };
            return (
              <div key={index} className={className}>
                <input
                  key={index}
                  type="checkbox"
                  name={row?.name}
                  checked={value[index]}
                  onChange={onChangeCheckbox}
                  disabled={
                    disabled
                      ? disabled
                      : useDynamicDisable
                      ? disableValue[index]
                      : disabled
                  }
                />
                <div className={labelCustom}>{label[index]}</div>
              </div>
            );
          })}
        </div>
      </div>
      {ckeck_other_true && (
        <TextField
          name={other_name}
          setField={setField}
          value={other_value}
          disabled={disabled}
          setValue={setValue}
          setStateObject={setStateObject}
        />
      )}
    </div>
  );
};
export default CheckBoxMulti;
