import { input } from "aws-amplify";
import { calculateAverage, calculateSum } from "./useFunction";

export const formatDataGender = (inputdata) => {
  const arrayForCal = [inputdata?.men, inputdata?.women];

  return {
    data: [
      {
        men: inputdata?.men,
        women: inputdata?.women,
        sum: calculateSum(arrayForCal),
      },
      {
        men:
          (
            (inputdata?.men / (inputdata?.men + inputdata?.women)) *
            100
          ).toFixed(1) + "%",
        women:
          (
            (inputdata?.women / (inputdata?.men + inputdata?.women)) *
            100
          ).toFixed(1) + "%",
        sum: "100%",
      },
    ],
  };
};
export const formatDataPaperAll = (inputdata) => {
  const arrayForCal = [
    inputdata?.jan,
    inputdata?.feb,
    inputdata?.mar,
    inputdata?.apr,
    inputdata?.may,
    inputdata?.jun,
    inputdata?.jul,
    inputdata?.aug,
    inputdata?.sep,
    inputdata?.oct,
    inputdata?.nov,
    inputdata?.dec,
  ];

  return {
    data: [
      {
        month_01: inputdata?.jan,
        month_02: inputdata?.feb,
        month_03: inputdata?.mar,
        month_04: inputdata?.apr,
        month_05: inputdata?.may,
        month_06: inputdata?.jun,
        month_07: inputdata?.jul,
        month_08: inputdata?.aug,
        month_09: inputdata?.sep,
        month_10: inputdata?.oct,
        month_11: inputdata?.nov,
        month_12: inputdata?.dec,
        sum: calculateSum(arrayForCal),
      },
    ],
  };
};
//
export const formatDataPaper = (inputdata) => {
  const arrayForCalAdult = [
    inputdata?.jan?.adult,
    inputdata?.feb?.adult,
    inputdata?.mar?.adult,
    inputdata?.apr?.adult,
    inputdata?.may?.adult,
    inputdata?.jun?.adult,
    inputdata?.jul?.adult,
    inputdata?.aug?.adult,
    inputdata?.sep?.adult,
    inputdata?.oct?.adult,
    inputdata?.nov?.adult,
    inputdata?.dec?.adult,
  ];

  const arrayForCalKid = [
    inputdata?.jan?.kid,
    inputdata?.feb?.kid,
    inputdata?.mar?.kid,
    inputdata?.apr?.kid,
    inputdata?.may?.kid,
    inputdata?.jun?.kid,
    inputdata?.jul?.kid,
    inputdata?.aug?.kid,
    inputdata?.sep?.kid,
    inputdata?.oct?.kid,
    inputdata?.nov?.kid,
    inputdata?.dec?.kid,
  ];

  return {
    data: [
      {
        jan_adult: inputdata?.jan?.adult,
        jan_kid: inputdata?.jan?.kid,

        feb_adult: inputdata?.feb?.adult,
        feb_kid: inputdata?.feb?.kid,

        mar_adult: inputdata?.mar?.adult,
        mar_kid: inputdata?.mar?.kid,

        apr_adult: inputdata?.apr?.adult,
        apr_kid: inputdata?.apr?.kid,

        may_adult: inputdata?.may?.adult,
        may_kid: inputdata?.may?.kid,

        jun_adult: inputdata?.jun?.adult,
        jun_kid: inputdata?.jun?.kid,

        jul_adult: inputdata?.jul?.adult,
        jul_kid: inputdata?.jul?.kid,

        aug_adult: inputdata?.aug?.adult,
        aug_kid: inputdata?.aug?.kid,

        sep_adult: inputdata?.sep?.adult,
        sep_kid: inputdata?.sep?.kid,

        oct_adult: inputdata?.oct?.adult,
        oct_kid: inputdata?.oct?.kid,

        nov_adult: inputdata?.nov?.adult,
        nov_kid: inputdata?.nov?.kid,

        dec_adult: inputdata?.dec?.adult,
        dec_kid: inputdata?.dec?.kid,

        sum_adult: calculateSum(arrayForCalAdult),
        sum_kid: calculateSum(arrayForCalKid),
      },
    ],
  };
};

export const formatDataCatheter = (inputdata) => {
  const arrayForCalAdult = [
    inputdata?.jan?.adult,
    inputdata?.feb?.adult,
    inputdata?.mar?.adult,
    inputdata?.apr?.adult,
    inputdata?.may?.adult,
    inputdata?.jun?.adult,
    inputdata?.jul?.adult,
    inputdata?.aug?.adult,
    inputdata?.sep?.adult,
    inputdata?.oct?.adult,
    inputdata?.nov?.adult,
    inputdata?.dec?.adult,
  ];

  const arrayForCalKid = [
    inputdata?.jan?.kid,
    inputdata?.feb?.kid,
    inputdata?.mar?.kid,
    inputdata?.apr?.kid,
    inputdata?.may?.kid,
    inputdata?.jun?.kid,
    inputdata?.jul?.kid,
    inputdata?.aug?.kid,
    inputdata?.sep?.kid,
    inputdata?.oct?.kid,
    inputdata?.nov?.kid,
    inputdata?.dec?.kid,
  ];
  return {
    data: [
      {
        jan_adult: inputdata?.jan?.adult,
        jan_kid: inputdata?.jan?.kid,

        feb_adult: inputdata?.feb?.adult,
        feb_kid: inputdata?.feb?.kid,

        mar_adult: inputdata?.mar?.adult,
        mar_kid: inputdata?.mar?.kid,

        apr_adult: inputdata?.apr?.adult,
        apr_kid: inputdata?.apr?.kid,

        may_adult: inputdata?.may?.adult,
        may_kid: inputdata?.may?.kid,

        jun_adult: inputdata?.jun?.adult,
        jun_kid: inputdata?.jun?.kid,

        jul_adult: inputdata?.jul?.adult,
        jul_kid: inputdata?.jul?.kid,

        aug_adult: inputdata?.aug?.adult,
        aug_kid: inputdata?.aug?.kid,

        sep_adult: inputdata?.sep?.adult,
        sep_kid: inputdata?.sep?.kid,

        oct_adult: inputdata?.oct?.adult,
        oct_kid: inputdata?.oct?.kid,

        nov_adult: inputdata?.nov?.adult,
        nov_kid: inputdata?.nov?.kid,

        dec_adult: inputdata?.dec?.adult,
        dec_kid: inputdata?.dec?.kid,
        sum_adult: calculateSum(arrayForCalAdult),
        sum_kid: calculateSum(arrayForCalKid),
      },
    ],
  };
};

export const formatTimeWaitCat = (inputdata) => {
  const array = [inputdata?.A, inputdata?.B, inputdata?.C];
  const total = inputdata?.A + inputdata?.B + inputdata?.C;
  return {
    data: [
      {
        A: inputdata?.A, // "น้อยกว่า 2 วัน"
        B: inputdata?.B, // "3-5 วัน"
        C: inputdata?.C, //  "6 วันขึ้นไป"
        sum: calculateSum(array),
        percentageA: ((inputdata.A / total) * 100).toFixed(1) + "%",
        percentageB: ((inputdata.B / total) * 100).toFixed(1) + "%",
        percentageC: ((inputdata.C / total) * 100).toFixed(1) + "%",
        All: "100%",
      },
    ],
  };
};

export const formatDataWeight = (inputdata) => {
  const arrayMode = inputdata?.mode.join(", ");

  return {
    data: [
      {
        mean: inputdata?.average,
        median: inputdata?.median,
        min: inputdata?.min,
        max: inputdata?.max,
        sd: inputdata?.sd,
        mode: arrayMode,
        number: inputdata?.count,
      },
    ],
  };
};

export const formatDataPatient = (inputdata) => {
  const total = Object.values(inputdata).reduce(
    (acc, curVal) => acc + curVal,
    0
  );
  const percentages = {
    typ1: ((inputdata?.type1 / total) * 100).toFixed(1) + "%",
    typ2: ((inputdata?.type2 / total) * 100).toFixed(1) + "%",
    typ3: ((inputdata?.type3 / total) * 100).toFixed(1) + "%",
    typ4: ((inputdata?.type4 / total) * 100).toFixed(1) + "%",
    typ5: ((inputdata?.type5 / total) * 100).toFixed(1) + "%",
    typ6: ((inputdata?.type6 / total) * 100).toFixed(1) + "%",
    typ7: ((inputdata?.type7 / total) * 100).toFixed(1) + "%",
  };
  const array = [
    inputdata?.type1,
    inputdata?.type2,
    inputdata?.type3,
    inputdata?.type4,
    inputdata?.type5,
    inputdata?.type6,
    inputdata?.type7,
  ];
  return {
    data: [
      {
        type1: inputdata?.type1,
        type2: inputdata?.type2,
        type3: inputdata?.type3,
        type4: inputdata?.type4,
        type5: inputdata?.type5,
        type6: inputdata?.type6,
        type7: inputdata?.type7,
        sum: calculateSum(array),
      },
      {
        type1: percentages?.typ1,
        type2: percentages?.typ2,
        type3: percentages?.typ3,
        type4: percentages?.typ4,
        type5: percentages?.typ5,
        type6: percentages?.typ6,
        type7: percentages?.typ7,
        sum: "100%",
      },
    ],
  };
};

export const formatDataCatPICCCEN = (inputdata) => {
  const arrayForCalPICC = [
    inputdata?.jan?.picc,
    inputdata?.feb?.picc,
    inputdata?.mar?.picc,
    inputdata?.apr?.picc,
    inputdata?.may?.picc,
    inputdata?.jun?.picc,
    inputdata?.jul?.picc,
    inputdata?.aug?.picc,
    inputdata?.sep?.picc,
    inputdata?.oct?.picc,
    inputdata?.nov?.picc,
    inputdata?.dec?.picc,
  ];

  const arrayForCalCentral = [
    inputdata?.jan?.central,
    inputdata?.feb?.central,
    inputdata?.mar?.central,
    inputdata?.apr?.central,
    inputdata?.may?.central,
    inputdata?.jun?.central,
    inputdata?.jul?.central,
    inputdata?.aug?.central,
    inputdata?.sep?.central,
    inputdata?.oct?.central,
    inputdata?.nov?.central,
    inputdata?.dec?.central,
  ];

  return {
    data: [
      {
        jan_picc: inputdata?.jan?.picc,
        jan_central: inputdata?.jan?.central,

        feb_picc: inputdata?.feb?.picc,
        feb_central: inputdata?.feb?.central,

        mar_picc: inputdata?.mar?.picc,
        mar_central: inputdata?.mar?.central,

        apr_picc: inputdata?.apr?.picc,
        apr_central: inputdata?.apr?.central,

        may_picc: inputdata?.may?.picc,
        may_central: inputdata?.may?.central,

        jun_picc: inputdata?.jun?.picc,
        jun_central: inputdata?.jun?.central,

        jul_picc: inputdata?.jul?.picc,
        jul_central: inputdata?.jul?.central,

        aug_picc: inputdata?.aug?.picc,
        aug_central: inputdata?.aug?.central,

        sep_picc: inputdata?.sep?.picc,
        sep_central: inputdata?.sep?.central,

        oct_picc: inputdata?.oct?.picc,
        oct_central: inputdata?.oct?.central,

        nov_picc: inputdata?.nov?.picc,
        nov_central: inputdata?.nov?.central,

        dec_picc: inputdata?.dec?.picc,
        dec_central: inputdata?.dec?.central,

        sum_picc: calculateSum(arrayForCalPICC),
        sum_central: calculateSum(arrayForCalCentral),
      },
    ],
  };
};

export const formatDataLabelCatheterType = (inputdata) => {
  const arrayPICC = [
    inputdata?.picc1,
    inputdata?.picc2,
    inputdata?.picc3,
    inputdata?.picc4,
    inputdata?.picc5,
  ];

  const arrayCentral = [
    inputdata?.central1,
    inputdata?.central2,
    inputdata?.central3,
    inputdata?.central4,
  ];

  return {
    data: [
      {
        picc1: inputdata?.picc1,
        picc2: inputdata?.picc2,
        picc3: inputdata?.picc3,
        picc4: inputdata?.picc4,
        picc5: inputdata?.picc5,

        central1: inputdata?.central1,
        central2: inputdata?.central2,
        central3: inputdata?.central3,
        central4: inputdata?.central4,

        sum_picc: calculateSum(arrayPICC),
        sum_central: calculateSum(arrayCentral),
      },
    ],
  };
};

export const formatDataIndications = (inputdata) => {
  const array = [
    inputdata?.tpn,
    inputdata?.prolonged_iv_meds,
    inputdata?.chemotherapy,
    inputdata?.vasopressor,
    inputdata?.frequent_blood_sampling,
    inputdata?.stem_cell_collection_plasma_exchange,
    inputdata?.stem_cell_transplantation,
    inputdata?.other_indication,
  ];

  return {
    data: [
      {
        type1: inputdata?.tpn,
        type2: inputdata?.prolonged_iv_meds,
        type3: inputdata?.chemotherapy,
        type4: inputdata?.vasopressor,
        type5: inputdata?.frequent_blood_sampling,
        type6: inputdata?.stem_cell_collection_plasma_exchange,
        type7: inputdata?.stem_cell_transplantation,
        type8: inputdata?.other_indication,

        sum: calculateSum(array),
      },
      {
        type1: ((inputdata?.tpn / calculateSum(array)) * 100).toFixed(1) + "%",
        type2:
          ((inputdata?.prolonged_iv_meds / calculateSum(array)) * 100).toFixed(
            1
          ) + "%",
        type3:
          ((inputdata?.chemotherapy / calculateSum(array)) * 100).toFixed(1) +
          "%",
        type4:
          ((inputdata?.vasopressor / calculateSum(array)) * 100).toFixed(1) +
          "%",
        type5:
          (
            (inputdata?.frequent_blood_sampling / calculateSum(array)) *
            100
          ).toFixed(1) + "%",
        type6:
          (
            (inputdata?.stem_cell_collection_plasma_exchange /
              calculateSum(array)) *
            100
          ).toFixed(1) + "%",

        type7:
          (
            (inputdata?.stem_cell_transplantation / calculateSum(array)) *
            100
          ).toFixed(1) + "%",
        type8:
          ((inputdata?.other_indication / calculateSum(array)) * 100).toFixed(
            1
          ) + "%",
        sum: "100%",
      },
    ],
  };
};

export const formatDataHeight = (inputdata) => {
  const arrayLeft = [
    inputdata?.lt_deep1,

    inputdata?.lt_deep2,

    inputdata?.lt_deep3,

    inputdata?.lt_deep4,

    inputdata?.lt_deep5,

    inputdata?.lt_deep6,

    inputdata?.lt_deep7,

    inputdata?.lt_deep8,

    inputdata?.lt_deep9,

    inputdata?.lt_deep10,
    inputdata?.lt_deep11,
    inputdata?.lt_deep12,
  ];
  const arrayRight = [
    inputdata?.rt_deep1,

    inputdata?.rt_deep2,

    inputdata?.rt_deep3,

    inputdata?.rt_deep4,

    inputdata?.rt_deep5,

    inputdata?.rt_deep6,

    inputdata?.rt_deep7,

    inputdata?.rt_deep8,

    inputdata?.rt_deep9,

    inputdata?.rt_deep10,
    inputdata?.rt_deep11,
    inputdata?.rt_deep12,
  ];
  return {
    data: [
      {
        lt_01: inputdata?.lt_deep1,
        lt_02: inputdata?.lt_deep2,
        lt_03: inputdata?.lt_deep3,
        lt_04: inputdata?.lt_deep4,
        lt_05: inputdata?.lt_deep5,
        lt_06: inputdata?.lt_deep6,
        lt_07: inputdata?.lt_deep7,
        lt_08: inputdata?.lt_deep8,
        lt_09: inputdata?.lt_deep9,
        lt_10: inputdata?.lt_deep10,
        lt_11: inputdata?.lt_deep11,
        lt_12: inputdata?.lt_deep12,
        //
        rt_01: inputdata?.rt_deep1,
        rt_02: inputdata?.rt_deep2,
        rt_03: inputdata?.rt_deep3,
        rt_04: inputdata?.rt_deep4,
        rt_05: inputdata?.rt_deep5,
        rt_06: inputdata?.rt_deep6,
        rt_07: inputdata?.rt_deep7,
        rt_08: inputdata?.rt_deep8,
        rt_09: inputdata?.rt_deep9,
        rt_10: inputdata?.rt_deep10,
        rt_11: inputdata?.rt_deep11,
        rt_12: inputdata?.rt_deep12,
        //
        sum_lt: calculateSum(arrayLeft),
        sum_rt: calculateSum(arrayRight),
      },
    ],
  };
};

export const formatDataAttemptByDoc = (inputdata) => {
  const calPerSuc = (suc = 0, un = 0) => {
    const all = suc + un;
    const per = parseFloat(((suc / all) * 100).toFixed(2));

    return all === 0 ? "-" : per;
  };

  const calPerUn = (suc = 0, un = 0) => {
    const all = suc + un;
    const per = parseFloat(((un / all) * 100).toFixed(2));

    return all === 0 ? "-" : per;
  };
  return {
    data: [
      {
        doc_sum_01: inputdata?.doctor_01_suc + inputdata?.doctor_01_un,
        doc_suc_01: inputdata?.doctor_01_suc,
        doc_per_suc_01: calPerSuc(
          inputdata?.doctor_01_suc,
          inputdata?.doctor_01_un
        ),
        doc_un_01: inputdata?.doctor_01_un,
        doc_per_un_01: calPerUn(
          inputdata?.doctor_01_suc,
          inputdata?.doctor_01_un
        ),
        doc_sum_02: inputdata?.doctor_02_suc + inputdata?.doctor_02_un,
        doc_suc_02: inputdata?.doctor_02_suc,
        doc_per_suc_02: calPerSuc(
          inputdata?.doctor_02_suc,
          inputdata?.doctor_02_un
        ),
        doc_un_02: inputdata?.doctor_02_un,
        doc_per_un_02: calPerUn(
          inputdata?.doctor_02_suc,
          inputdata?.doctor_02_un
        ),
        doc_sum_03: inputdata?.doctor_03_suc + inputdata?.doctor_03_un,
        doc_suc_03: inputdata?.doctor_03_suc,
        doc_per_suc_03: calPerSuc(
          inputdata?.doctor_03_suc,
          inputdata?.doctor_03_un
        ),
        doc_un_03: inputdata?.doctor_03_un,
        doc_per_un_03: calPerUn(
          inputdata?.doctor_03_suc,
          inputdata?.doctor_03_un
        ),
        doc_sum_04: inputdata?.doctor_04_suc + inputdata?.doctor_04_un,
        doc_suc_04: inputdata?.doctor_04_suc,
        doc_per_suc_04: calPerSuc(
          inputdata?.doctor_04_suc,
          inputdata?.doctor_04_un
        ),
        doc_un_04: inputdata?.doctor_04_un,
        doc_per_un_04: calPerUn(
          inputdata?.doctor_04_suc,
          inputdata?.doctor_04_un
        ),
        doc_sum_05: inputdata?.doctor_05_suc + inputdata?.doctor_05_un,
        doc_suc_05: inputdata?.doctor_05_suc,
        doc_per_suc_05: calPerSuc(
          inputdata?.doctor_05_suc,
          inputdata?.doctor_05_un
        ),
        doc_un_05: inputdata?.doctor_05_un,
        doc_per_un_05: calPerUn(
          inputdata?.doctor_05_suc,
          inputdata?.doctor_05_un
        ),
        doc_sum_06: inputdata?.doctor_06_suc + inputdata?.doctor_06_un,
        doc_suc_06: inputdata?.doctor_06_suc,
        doc_per_suc_06: calPerSuc(
          inputdata?.doctor_06_suc,
          inputdata?.doctor_06_un
        ),
        doc_un_06: inputdata?.doctor_06_un,
        doc_per_un_06: calPerUn(
          inputdata?.doctor_06_suc,
          inputdata?.doctor_06_un
        ),
        doc_sum_07: inputdata?.doctor_07_suc + inputdata?.doctor_07_un,
        doc_suc_07: inputdata?.doctor_07_suc,
        doc_per_suc_07: calPerSuc(
          inputdata?.doctor_07_suc,
          inputdata?.doctor_07_un
        ),
        doc_un_07: inputdata?.doctor_07_un,
        doc_per_un_07: calPerUn(
          inputdata?.doctor_07_suc,
          inputdata?.doctor_07_un
        ),
        doc_sum_08: inputdata?.doctor_08_suc + inputdata?.doctor_08_un,
        doc_suc_08: inputdata?.doctor_08_suc,
        doc_per_suc_08: calPerSuc(
          inputdata?.doctor_08_suc,
          inputdata?.doctor_08_un
        ),
        doc_un_08: inputdata?.doctor_08_un,
        doc_per_un_08: calPerUn(
          inputdata?.doctor_08_suc,
          inputdata?.doctor_08_un
        ),
        doc_sum_09: inputdata?.doctor_09_suc + inputdata?.doctor_09_un,
        doc_suc_09: inputdata?.doctor_09_suc,
        doc_per_suc_09: calPerSuc(
          inputdata?.doctor_09_suc,
          inputdata?.doctor_09_un
        ),
        doc_un_09: inputdata?.doctor_09_un,
        doc_per_un_09: calPerUn(
          inputdata?.doctor_09_suc,
          inputdata?.doctor_09_un
        ),
        //
        doc_sum_10: inputdata?.doctor_10_suc + inputdata?.doctor_10_un,
        doc_suc_10: inputdata?.doctor_10_suc,
        doc_per_suc_10: calPerSuc(
          inputdata?.doctor_10_suc,
          inputdata?.doctor_10_un
        ),
        doc_un_10: inputdata?.doctor_10_un,
        doc_per_un_10: calPerUn(
          inputdata?.doctor_10_suc,
          inputdata?.doctor_10_un
        ),
        doc_sum_11: inputdata?.doctor_11_suc + inputdata?.doctor_11_un,
        doc_suc_11: inputdata?.doctor_11_suc,
        doc_per_suc_11: calPerSuc(
          inputdata?.doctor_11_suc,
          inputdata?.doctor_11_un
        ),
        doc_un_11: inputdata?.doctor_11_un,
        doc_per_un_11: calPerUn(
          inputdata?.doctor_11_suc,
          inputdata?.doctor_11_un
        ),
        doc_sum_12: inputdata?.doctor_12_suc + inputdata?.doctor_12_un,
        doc_suc_12: inputdata?.doctor_12_suc,
        doc_per_suc_12: calPerSuc(
          inputdata?.doctor_12_suc,
          inputdata?.doctor_12_un
        ),
        doc_un_12: inputdata?.doctor_12_un,
        doc_per_un_12: calPerUn(
          inputdata?.doctor_12_suc,
          inputdata?.doctor_12_un
        ),
        doc_sum_13: inputdata?.doctor_13_suc + inputdata?.doctor_13_un,
        doc_suc_13: inputdata?.doctor_13_suc,
        doc_per_suc_13: calPerSuc(
          inputdata?.doctor_13_suc,
          inputdata?.doctor_13_un
        ),
        doc_un_13: inputdata?.doctor_13_un,
        doc_per_un_13: calPerUn(
          inputdata?.doctor_13_suc,
          inputdata?.doctor_13_un
        ),
        doc_sum_14: inputdata?.doctor_14_suc + inputdata?.doctor_14_un,
        doc_suc_14: inputdata?.doctor_14_suc,
        doc_per_suc_14: calPerSuc(
          inputdata?.doctor_14_suc,
          inputdata?.doctor_14_un
        ),
        doc_un_14: inputdata?.doctor_14_un,
        doc_per_un_14: calPerUn(
          inputdata?.doctor_14_suc,
          inputdata?.doctor_14_un
        ),
        doc_sum_15: inputdata?.doctor_15_suc + inputdata?.doctor_15_un,
        doc_suc_15: inputdata?.doctor_15_suc,
        doc_per_suc_15: calPerSuc(
          inputdata?.doctor_15_suc,
          inputdata?.doctor_15_un
        ),
        doc_un_15: inputdata?.doctor_15_un,
        doc_per_un_15: calPerUn(
          inputdata?.doctor_15_suc,
          inputdata?.doctor_15_un
        ),
        doc_sum_16: inputdata?.doctor_16_suc + inputdata?.doctor_16_un,
        doc_suc_16: inputdata?.doctor_16_suc,
        doc_per_suc_16: calPerSuc(
          inputdata?.doctor_16_suc,
          inputdata?.doctor_16_un
        ),
        doc_un_16: inputdata?.doctor_16_un,
        doc_per_un_16: calPerUn(
          inputdata?.doctor_16_suc,
          inputdata?.doctor_16_un
        ),
        doc_sum_17: inputdata?.doctor_17_suc + inputdata?.doctor_17_un,
        doc_suc_17: inputdata?.doctor_17_suc,
        doc_per_suc_17: calPerSuc(
          inputdata?.doctor_17_suc,
          inputdata?.doctor_17_un
        ),
        doc_un_17: inputdata?.doctor_17_un,
        doc_per_un_17: calPerUn(
          inputdata?.doctor_17_suc,
          inputdata?.doctor_17_un
        ),
        doc_sum_18: inputdata?.doctor_18_suc + inputdata?.doctor_18_un,
        doc_suc_18: inputdata?.doctor_18_suc,
        doc_per_suc_18: calPerSuc(
          inputdata?.doctor_18_suc,
          inputdata?.doctor_18_un
        ),
        doc_un_18: inputdata?.doctor_18_un,
        doc_per_un_18: calPerUn(
          inputdata?.doctor_18_suc,
          inputdata?.doctor_18_un
        ),
        doc_sum_19: inputdata?.doctor_19_suc + inputdata?.doctor_19_un,
        doc_suc_19: inputdata?.doctor_19_suc,
        doc_per_suc_19: calPerSuc(
          inputdata?.doctor_19_suc,
          inputdata?.doctor_19_un
        ),
        doc_un_19: inputdata?.doctor_19_un,
        doc_per_un_19: calPerUn(
          inputdata?.doctor_19_suc,
          inputdata?.doctor_19_un
        ),
        doc_sum_20: inputdata?.doctor_20_suc + inputdata?.doctor_20_un,
        doc_suc_20: inputdata?.doctor_20_suc,
        doc_per_suc_20: calPerSuc(
          inputdata?.doctor_20_suc,
          inputdata?.doctor_20_un
        ),
        doc_un_20: inputdata?.doctor_20_un,
        doc_per_un_20: calPerUn(
          inputdata?.doctor_20_suc,
          inputdata?.doctor_20_un
        ),
        doc_sum_21: inputdata?.doctor_21_suc + inputdata?.doctor_21_un,
        doc_suc_21: inputdata?.doctor_21_suc,
        doc_per_suc_21: calPerSuc(
          inputdata?.doctor_21_suc,
          inputdata?.doctor_21_un
        ),
        doc_un_21: inputdata?.doctor_21_un,
        doc_per_un_21: calPerUn(
          inputdata?.doctor_21_suc,
          inputdata?.doctor_21_un
        ),
        doc_sum_22: inputdata?.doctor_22_suc + inputdata?.doctor_22_un,
        doc_suc_22: inputdata?.doctor_22_suc,
        doc_per_suc_22: calPerSuc(
          inputdata?.doctor_22_suc,
          inputdata?.doctor_22_un
        ),
        doc_un_22: inputdata?.doctor_22_un,
        doc_per_un_22: calPerUn(
          inputdata?.doctor_22_suc,
          inputdata?.doctor_22_un
        ),
        doc_sum_23: inputdata?.doctor_23_suc + inputdata?.doctor_23_un,
        doc_suc_23: inputdata?.doctor_23_suc,
        doc_per_suc_23: calPerSuc(
          inputdata?.doctor_23_suc,
          inputdata?.doctor_23_un
        ),
        doc_un_23: inputdata?.doctor_23_un,
        doc_per_un_23: calPerUn(
          inputdata?.doctor_23_suc,
          inputdata?.doctor_23_un
        ),
        doc_sum_24: inputdata?.doctor_24_suc + inputdata?.doctor_24_un,
        doc_suc_24: inputdata?.doctor_24_suc,
        doc_per_suc_24: calPerSuc(
          inputdata?.doctor_24_suc,
          inputdata?.doctor_24_un
        ),
        doc_un_24: inputdata?.doctor_24_un,
        doc_per_un_24: calPerUn(
          inputdata?.doctor_24_suc,
          inputdata?.doctor_24_un
        ),
        doc_sum_25: inputdata?.doctor_25_suc + inputdata?.doctor_25_un,
        doc_suc_25: inputdata?.doctor_25_suc,
        doc_per_suc_25: calPerSuc(
          inputdata?.doctor_25_suc,
          inputdata?.doctor_25_un
        ),
        doc_un_25: inputdata?.doctor_25_un,
        doc_per_un_25: calPerUn(
          inputdata?.doctor_25_suc,
          inputdata?.doctor_25_un
        ),
      },
    ],
  };
};

//

export const formatDataAvgAttempt = (inputdata, useSumAll) => {
  return {
    data: [
      {
        doctor_01: inputdata?.doctor_01,
        doctor_02: inputdata?.doctor_02,
        doctor_03: inputdata?.doctor_03,
        doctor_04: inputdata?.doctor_04,
        doctor_05: inputdata?.doctor_05,
        doctor_06: inputdata?.doctor_06,
        doctor_07: inputdata?.doctor_07,
        doctor_08: inputdata?.doctor_08,
        doctor_09: inputdata?.doctor_09,
        doctor_10: inputdata?.doctor_10,
        doctor_11: inputdata?.doctor_11,
        doctor_12: inputdata?.doctor_12,
        doctor_13: inputdata?.doctor_13,
        doctor_14: inputdata?.doctor_14,
        doctor_15: inputdata?.doctor_15,
        doctor_16: inputdata?.doctor_16,
        doctor_17: inputdata?.doctor_17,
        doctor_18: inputdata?.doctor_18,
        doctor_19: inputdata?.doctor_19,
        doctor_20: inputdata?.doctor_20,
        doctor_21: inputdata?.doctor_21,
        doctor_22: inputdata?.doctor_22,
        doctor_23: inputdata?.doctor_23,
        doctor_24: inputdata?.doctor_24,
        doctor_25: inputdata?.doctor_25,
        doctor_26: inputdata?.doctor_26,
        avg: calculateAverage(useSumAll),
        min: Math.min(...useSumAll),
        max: Math.max(...useSumAll),
      },
    ],
  };
};
export const formatDataSideCat = (inputdata) => {
  const array_LT = [
    inputdata?.cat1_LT,
    inputdata?.cat2_LT,
    inputdata?.cat3_LT,
    inputdata?.cat4_LT,
    inputdata?.cat5_LT,
    inputdata?.cat6_LT,
    inputdata?.cat7_LT,
    inputdata?.cat8_LT,
  ];

  const array_RT = [
    inputdata?.cat1_RT,
    inputdata?.cat2_RT,
    inputdata?.cat3_RT,
    inputdata?.cat4_RT,
    inputdata?.cat5_RT,
    inputdata?.cat6_RT,
    inputdata?.cat7_RT,
    inputdata?.cat8_RT,
  ];
  return {
    data: [
      {
        cat1_LT: inputdata?.cat1_LT,
        cat2_LT: inputdata?.cat2_LT,
        cat3_LT: inputdata?.cat3_LT,
        cat4_LT: inputdata?.cat4_LT,
        cat5_LT: inputdata?.cat5_LT,
        cat6_LT: inputdata?.cat6_LT,
        cat7_LT: inputdata?.cat7_LT,
        cat8_LT: inputdata?.cat8_LT,
        //
        cat1_RT: inputdata?.cat1_RT,
        cat2_RT: inputdata?.cat2_RT,
        cat3_RT: inputdata?.cat3_RT,
        cat4_RT: inputdata?.cat4_RT,
        cat5_RT: inputdata?.cat5_RT,
        cat6_RT: inputdata?.cat6_RT,
        cat7_RT: inputdata?.cat7_RT,
        cat8_RT: inputdata?.cat8_RT,
        //
        sum_LT: calculateSum(array_LT),
        sum_RT: calculateSum(array_RT),
        cat1_LTpercent:
          ((inputdata?.cat1_LT / calculateSum(array_LT)) * 100).toFixed(1) +
          "%",
        cat2_LTpercent:
          ((inputdata?.cat2_LT / calculateSum(array_LT)) * 100).toFixed(1) +
          "%",
        cat3_LTpercent:
          ((inputdata?.cat3_LT / calculateSum(array_LT)) * 100).toFixed(1) +
          "%",
        cat4_LTpercent:
          ((inputdata?.cat4_LT / calculateSum(array_LT)) * 100).toFixed(1) +
          "%",
        cat5_LTpercent:
          ((inputdata?.cat5_LT / calculateSum(array_LT)) * 100).toFixed(1) +
          "%",
        cat6_LTpercent:
          ((inputdata?.cat6_LT / calculateSum(array_LT)) * 100).toFixed(1) +
          "%",
        cat7_LTpercent:
          ((inputdata?.cat7_LT / calculateSum(array_LT)) * 100).toFixed(1) +
          "%",
        cat8_LTpercent:
          ((inputdata?.cat8_LT / calculateSum(array_LT)) * 100).toFixed(1) +
          "%",
        //
        cat1_RTpercent:
          ((inputdata?.cat1_RT / calculateSum(array_RT)) * 100).toFixed(1) +
          "%",
        cat2_RTpercent:
          ((inputdata?.cat2_RT / calculateSum(array_RT)) * 100).toFixed(1) +
          "%",
        cat3_RTpercent:
          ((inputdata?.cat3_RT / calculateSum(array_RT)) * 100).toFixed(1) +
          "%",
        cat4_RTpercent:
          ((inputdata?.cat4_RT / calculateSum(array_RT)) * 100).toFixed(1) +
          "%",
        cat5_RTpercent:
          ((inputdata?.cat5_RT / calculateSum(array_RT)) * 100).toFixed(1) +
          "%",
        cat6_RTpercent:
          ((inputdata?.cat6_RT / calculateSum(array_RT)) * 100).toFixed(1) +
          "%",
        cat7_RTpercent:
          ((inputdata?.cat7_RT / calculateSum(array_RT)) * 100).toFixed(1) +
          "%",
        cat8_RTpercent:
          ((inputdata?.cat8_RT / calculateSum(array_RT)) * 100).toFixed(1) +
          "%",
      },
    ],
  };
};

export const formatConfrimation = (inputdata, inputdata2) => {
  const array = [
    inputdata?.po_1,
    inputdata?.po_2,

    inputdata?.po_3,
    inputdata?.po_4,
    inputdata?.po_5,
    inputdata?.po_6,
    inputdata?.po_7,
    inputdata?.po_8,
  ];
  return {
    data: [
      {
        po_1: inputdata?.po_1,
        po_2: inputdata?.po_2,
        po_3: inputdata?.po_3,
        po_4: inputdata?.po_4,
        po_5: inputdata?.po_5,
        po_6: inputdata?.po_6,
        po_7: inputdata?.po_7,
        po_8: inputdata?.po_8,
        total_po: inputdata?.total_po,
        percent_po1:
          ((inputdata?.po_1 / inputdata?.total_po) * 100).toFixed(1) + "%",
        percent_po2:
          ((inputdata?.po_2 / inputdata?.total_po) * 100).toFixed(1) + "%",
        percent_po3:
          ((inputdata?.po_3 / inputdata?.total_po) * 100).toFixed(1) + "%",
        percent_po4:
          ((inputdata?.po_4 / inputdata?.total_po) * 100).toFixed(1) + "%",
        percent_po5:
          ((inputdata?.po_5 / inputdata?.total_po) * 100).toFixed(1) + "%",
        percent_po6:
          ((inputdata?.po_6 / inputdata?.total_po) * 100).toFixed(1) + "%",
        percent_po7:
          ((inputdata?.po_7 / inputdata?.total_po) * 100).toFixed(1) + "%",
        percent_po8:
          ((inputdata?.po_8 / inputdata?.total_po) * 100).toFixed(1) + "%",
        total_percentpo: "100%",

        //
        con_good_flu: inputdata2?.con_good_flu,
        con_good_iecg: inputdata2?.con_good_iecg,
        con_good_land: inputdata2?.con_good_land,
        total_good: inputdata2?.total_good,
        percent_good_flu:
          ((inputdata2?.con_good_flu / inputdata2?.total_good) * 100).toFixed(
            1
          ) + "%",
        percent_good_iecg:
          ((inputdata2?.con_good_iecg / inputdata2?.total_good) * 100).toFixed(
            1
          ) + "%",
        percent_good_land:
          ((inputdata2?.con_good_land / inputdata2?.total_good) * 100).toFixed(
            1
          ) + "%",
        total_percentgood: "100%",

        //
        con_short_flu: inputdata2?.con_short_flu,
        con_short_iecg: inputdata2?.con_short_iecg,
        con_short_land: inputdata2?.con_short_land,
        total_short: inputdata2?.total_short,
        percent_short_flu:
          ((inputdata2?.con_short_flu / inputdata2?.total_short) * 100).toFixed(
            1
          ) + "%",
        percent_short_iecg:
          (
            (inputdata2?.con_short_iecg / inputdata2?.total_short) *
            100
          ).toFixed(1) + "%",
        percent_short_land:
          (
            (inputdata2?.con_short_land / inputdata2?.total_short) *
            100
          ).toFixed(1) + "%",
        total_percentshort: "100%",
        //
        con_long_flu: inputdata2?.con_long_flu,
        con_long_iecg: inputdata2?.con_long_iecg,
        con_long_land: inputdata2?.con_long_land,
        total_long: inputdata2?.total_long,
        percent_long_flu:
          ((inputdata2?.con_long_flu / inputdata2?.total_long) * 100).toFixed(
            1
          ) + "%",
        percent_long_iecg:
          ((inputdata2?.con_long_iecg / inputdata2?.total_long) * 100).toFixed(
            1
          ) + "%",
        percent_long_land:
          ((inputdata2?.con_long_land / inputdata2?.total_long) * 100).toFixed(
            1
          ) + "%",
        total_percentlong: "100%",

        //
        con_un_flu: inputdata2?.con_un_flu,
        con_un_iecg: inputdata2?.con_un_iecg,
        con_un_land: inputdata2?.con_un_land,
        total_un: inputdata2?.total_un,
        percent_un_flu:
          ((inputdata2?.con_un_flu / inputdata2?.total_un) * 100).toFixed(1) +
          "%",
        percent_un_iecg:
          ((inputdata2?.con_un_iecg / inputdata2?.total_un) * 100).toFixed(1) +
          "%",
        percent_un_land:
          ((inputdata2?.con_un_land / inputdata2?.total_un) * 100).toFixed(1) +
          "%",
        total_percentun: "100%",

        //
        in_ivc: inputdata?.in_ivc,
        no_ivc: inputdata?.no_ivc,
        //
        sum_position: calculateSum(array),
      },
    ],
  };
};
export const formatInfectionAt = (inputdata) => {
  const array = [
    inputdata?.complications_pneumothorax,
    inputdata?.complications_hemothorax,
    inputdata?.complications_arterial_puncture,
    inputdata?.complications_paresthesia,
    inputdata?.complications_nerve_injury,
    inputdata?.complications_limb_ischemia_related_to_arterial_puncture,
    inputdata?.other_complications,
  ];
  return {
    data: [
      {
        complications_pneumothorax: inputdata?.complications_pneumothorax,
        complications_hemothorax: inputdata?.complications_hemothorax,
        complications_arterial_puncture:
          inputdata?.complications_arterial_puncture,
        complications_paresthesia: inputdata?.complications_paresthesia,
        complications_nerve_injury: inputdata?.complications_nerve_injury,
        complications_limb_ischemia_related_to_arterial_puncture:
          inputdata?.complications_limb_ischemia_related_to_arterial_puncture,
        other_complications: inputdata?.other_complications,
        total: inputdata?.total,
        pneumothorax_percent:
          (
            (inputdata?.complications_pneumothorax / inputdata?.total) *
            100
          ).toFixed(1) + "%",
        hemothorax_percent:
          (
            (inputdata?.complications_hemothorax / inputdata?.total) *
            100
          ).toFixed(1) + "%",
        arterial_puncture_percent:
          (
            (inputdata?.complications_arterial_puncture / inputdata?.total) *
            100
          ).toFixed(1) + "%",
        paresthesia_percent:
          (
            (inputdata?.complications_paresthesia / inputdata?.total) *
            100
          ).toFixed(1) + "%",
        nerve_injury_percent:
          (
            (inputdata?.complications_nerve_injury / inputdata?.total) *
            100
          ).toFixed(1) + "%",
        limb_ischemia_related_to_arterial_puncture_percent:
          (
            (inputdata?.complications_limb_ischemia_related_to_arterial_puncture /
              inputdata?.total) *
            100
          ).toFixed(1) + "%",
        other_complications_percent:
          ((inputdata?.other_complications / inputdata?.total) * 100).toFixed(
            1
          ) + "%",
        total_percent: "100%",
        //
        sum: calculateSum(array),
      },
    ],
  };
};

export const formatTotalDay = (inputdata) => {
  return {
    data: [
      {
        A: inputdata?.A, //1
        B: inputdata?.B, //2
        C: inputdata?.C, //3
        D: inputdata?.D, //4
        E: inputdata?.E, //5
        F: inputdata?.F, //6
        G: inputdata?.G, //7

        //
        A_adult: inputdata?.A_adult,
        B_adult: inputdata?.B_adult,
        C_adult: inputdata?.C_adult,
        D_adult: inputdata?.D_adult,
        E_adult: inputdata?.E_adult,
        F_adult: inputdata?.F_adult,
        G_adult: inputdata?.G_adult,

        //
        A_kid: inputdata?.A_kid,
        B_kid: inputdata?.B_kid,
        C_kid: inputdata?.C_kid,
        D_kid: inputdata?.D_kid,
        E_kid: inputdata?.E_kid,
        F_kid: inputdata?.F_kid,
        G_kid: inputdata?.G_kid,
        //
        mean: inputdata?.mean,
        median: inputdata?.median,
        sd: inputdata?.sd,
        min: inputdata?.min,
        max: inputdata?.max,
        per25: inputdata?.per25,
        per75: inputdata?.per75,
        number: inputdata?.number,
        //
        mean_ad: inputdata?.mean_ad,
        median_ad: inputdata?.median_ad,
        sd_ad: inputdata?.sd_ad,
        min_ad: inputdata?.min_ad,
        max_ad: inputdata?.max_ad,
        per25_ad: inputdata?.per25_ad,
        per75_ad: inputdata?.per75_ad,
        number_ad: inputdata?.number_ad,
        //
        mean_kid: inputdata?.mean_kid,
        median_kid: inputdata?.median_kid,
        sd_kid: inputdata?.sd_kid,
        min_kid: inputdata?.min_kid,
        max_kid: inputdata?.max_kid,
        per25_kid: inputdata?.per25_kid,
        per75_kid: inputdata?.per75_kid,
        number_kid: inputdata?.number_kid,
      },
    ],
  };
};

export const formatReasonByStatus = (inputdata) => {
  const array = [inputdata?.alive, inputdata?.dead];
  return {
    data: [
      {
        alive: inputdata?.alive,
        dead: inputdata?.dead,
        sum: calculateSum(array),
        alive_percent: inputdata?.alivePercent,
        dead_percent: inputdata?.deadPercent,
      },
    ],
  };
};

export const formatReason = (inputdata) => {
  return {
    data: [
      {
        No_loanger_needed: inputdata?.No_loanger_needed,
        Death: inputdata?.Death,
        CLABSI: inputdata?.CLABSI,
        Mechanical_problems: inputdata?.Mechanical_problems,
        Self_dislodged: inputdata?.Self_dislodged,
        // Dislodged_by_others: inputdata?.Dislodged_by_others,
        Unresolved_occlusion: inputdata?.Unresolved_occlusion,
        Exit_site_infection: inputdata?.Exit_site_infection,
        other_reasons_for_discontinuation:
          inputdata?.other_reasons_for_discontinuation,
        Unintended_position: inputdata?.Unintended_position,
        loanger_percent:
          ((inputdata?.No_loanger_needed * 100) / inputdata?.total).toFixed(1) +
          "%",
        death_percent:
          ((inputdata?.Death * 100) / inputdata?.total).toFixed(1) + "%",
        CLABSI_percent:
          ((inputdata?.CLABSI * 100) / inputdata?.total).toFixed(1) + "%",
        Mechanical_problems_percent:
          ((inputdata?.Mechanical_problems * 100) / inputdata?.total).toFixed(
            1
          ) + "%",
        Self_dislodged_percent:
          ((inputdata?.Self_dislodged * 100) / inputdata?.total).toFixed(1) +
          "%",

        Unresolved_occlusion_percent:
          ((inputdata?.Unresolved_occlusion * 100) / inputdata?.total).toFixed(
            1
          ) + "%",
        Exit_site_infection_percent:
          ((inputdata?.Exit_site_infection * 100) / inputdata?.total).toFixed(
            1
          ) + "%",
        other_reasons_for_discontinuation_percent:
          (
            (inputdata?.other_reasons_for_discontinuation * 100) /
            inputdata?.total
          ).toFixed(1) + "%",
        Unintended_position_percent:
          ((inputdata?.Unintended_position * 100) / inputdata?.total).toFixed(
            1
          ) + "%",
        total_percent: "100%",
      },
    ],
  };
};

export const formatFollowVisit = (inputdata) => {
  const arrayForCalVisit = [
    inputdata?.jan?.visit,
    inputdata?.feb?.visit,
    inputdata?.mar?.visit,
    inputdata?.apr?.visit,
    inputdata?.may?.visit,
    inputdata?.jun?.visit,
    inputdata?.jul?.visit,
    inputdata?.aug?.visit,
    inputdata?.sep?.visit,
    inputdata?.oct?.visit,
    inputdata?.nov?.visit,
    inputdata?.dec?.visit,
  ];

  const arrayForCalPhone = [
    inputdata?.jan?.phone,
    inputdata?.feb?.phone,
    inputdata?.mar?.phone,
    inputdata?.apr?.phone,
    inputdata?.may?.phone,
    inputdata?.jun?.phone,
    inputdata?.jul?.phone,
    inputdata?.aug?.phone,
    inputdata?.sep?.phone,
    inputdata?.oct?.phone,
    inputdata?.nov?.phone,
    inputdata?.dec?.phone,
  ];
  return {
    data: [
      {
        jan_visit: inputdata?.jan?.visit,
        jan_phone: inputdata?.jan?.phone,

        feb_visit: inputdata?.feb?.visit,
        feb_phone: inputdata?.feb?.phone,

        mar_visit: inputdata?.mar?.visit,
        mar_phone: inputdata?.mar?.phone,

        apr_visit: inputdata?.apr?.visit,
        apr_phone: inputdata?.apr?.phone,

        may_visit: inputdata?.may?.visit,
        may_phone: inputdata?.may?.phone,

        jun_visit: inputdata?.jun?.visit,
        jun_phone: inputdata?.jun?.phone,

        jul_visit: inputdata?.jul?.visit,
        jul_phone: inputdata?.jul?.phone,

        aug_visit: inputdata?.aug?.visit,
        aug_phone: inputdata?.aug?.phone,

        sep_visit: inputdata?.sep?.visit,
        sep_phone: inputdata?.sep?.phone,

        oct_visit: inputdata?.oct?.visit,
        oct_phone: inputdata?.oct?.phone,

        nov_visit: inputdata?.nov?.visit,
        nov_phone: inputdata?.nov?.phone,

        dec_visit: inputdata?.dec?.visit,
        dec_phone: inputdata?.dec?.phone,

        sum_visit: calculateSum(arrayForCalVisit),
        sum_phone: calculateSum(arrayForCalPhone),
        //
        level01: inputdata?.level01,
        level01_percent:
          ((inputdata?.level01 * 100) / inputdata?.all_level).toFixed(1) + "%",
        level02: inputdata?.level02,
        level02_percent:
          ((inputdata?.level02 * 100) / inputdata?.all_level).toFixed(1) + "%",
        level03: inputdata?.level03,
        level03_percent:
          ((inputdata?.level03 * 100) / inputdata?.all_level).toFixed(1) + "%",
        level04: inputdata?.level04,
        level04_percent:
          ((inputdata?.level04 * 100) / inputdata?.all_level).toFixed(1) + "%",
      },
    ],
  };
};

export const formatRating = (inputdata) => {
  return {
    data: [
      {
        level01_relative: inputdata?.level01?.relative,
        level01_patient: inputdata?.level01?.patient,
        //
        level02_relative: inputdata?.level02?.relative,
        level02_patient: inputdata?.level02?.patient,
        //
        level03_relative: inputdata?.level03?.relative,
        level03_patient: inputdata?.level03?.patient,
        //
        level04_relative: inputdata?.level04?.relative,
        level04_patient: inputdata?.level04?.patient,
        //
        level05_relative: inputdata?.level05?.relative,
        level05_patient: inputdata?.level05?.patient,
        //
      },
    ],
  };
};

export const formatTimeAttmept = (inputdata) => {
  return {
    data: [
      {
        cd_10_mean: inputdata?.kid1_mean,
        cd_10_median: inputdata?.kid1_median,
        cd_10_min: inputdata?.kid1_min,
        cd_10_max: inputdata?.kid1_max,
        cd_10_sd: inputdata?.kid1_sd,
        cd_10_per_25: inputdata?.kid1_per25,
        cd_10_per_75: inputdata?.kid1_per75,
        cd_10_number: inputdata?.kid1_number,
        //
        cd_18_mean: inputdata?.kid2_mean,
        cd_18_median: inputdata?.kid2_median,
        cd_18_min: inputdata?.kid2_min,
        cd_18_max: inputdata?.kid2_max,
        cd_18_sd: inputdata?.kid2_sd,
        cd_18_per_25: inputdata?.kid2_per25,
        cd_18_per_75: inputdata?.kid2_per75,
        cd_18_number: inputdata?.kid2_number,
        //
        ad_mean: inputdata?.ad_mean,
        ad_median: inputdata?.ad_median,
        ad_min: inputdata?.ad_min,
        ad_max: inputdata?.ad_max,
        ad_sd: inputdata?.ad_sd,
        ad_per_25: inputdata?.ad_per25,
        ad_per_75: inputdata?.ad_per75,
        ad_number: inputdata?.ad_number,
      },
    ],
  };
};

export const formatSucAttempt = (inputdata) => {
  return {
    data: [
      {
        kid_01: inputdata?.kid_01,
        kid_02: inputdata?.kid_02,
        kid_03: inputdata?.kid_03,
        kid_04: inputdata?.kid_04,
        //
        ad_01: inputdata?.ad_01,
        ad_02: inputdata?.ad_02,
        ad_03: inputdata?.ad_03,
        ad_04: inputdata?.ad_04,
      },
    ],
  };
};

export const formatLinePatency = (inputdata) => {
  return {
    data: [
      {
        okall: inputdata?.okall,
        wo: inputdata?.wo,
        co: inputdata?.co,
        //
        count_co: inputdata?.count_co,
        count_wo: inputdata?.count_wo,
        total_woco: inputdata?.total_woco,
        //
        wo_observe: inputdata?.wo_observe,
        wo_rtpa_1: inputdata?.wo_rtpa,
        //
        wo_result_open: inputdata?.wo_result_open,
        wo_result_wo: inputdata?.wo_result_wo,
        wo_result_co: inputdata?.wo_result_co,
        //
        wo_rtpa: inputdata?.wo_rtpa,
        //
        co_result_open: inputdata?.co_result_open,
        co_result_wo: inputdata?.co_result_wo,
        co_result_co: inputdata?.co_result_co,
        //
        co_observe: inputdata?.co_observe,
        co_rtpa_1: inputdata?.co_rtpa,
        //
        co_rtpa: inputdata?.co_rtpa,

        // หมวด สายติดเชื้อ

        infection_nil: inputdata?.infection_nil,
        infection_bug_found: inputdata?.infection_bug_found,
        // หมวด แขนขาบวม
        limb_edema_no: inputdata?.limb_edema_no,
        limb_edema_yes: inputdata?.limb_edema_yes,
        //
        presence_of_dvt_no: inputdata?.presence_of_dvt_no,
        presence_of_dvt_yes: inputdata?.presence_of_dvt_yes,
        presence_of_dvt_na: inputdata?.presence_of_dvt_na,
        //
        manag_observation: inputdata?.manag_observation,
        manag_anticoagulation: inputdata?.manag_anticoagulation,
        manag_catheter_removal: inputdata?.manag_catheter_removal,
        // exit
        exit_site_ok: inputdata?.exit_site_ok,
        other_exit_site: inputdata?.other_exit_site,
        exit_site_infection: inputdata?.exit_site_infection,
        // line dis
        line_disledge_nil: inputdata?.line_disledge_nil,
        line_disledge_new_exit_mark_at:
          inputdata?.line_disledge_new_exit_mark_at,
        complete_dislodge: inputdata?.complete_dislodge,

        // สายรั่ว
        line_disledge_breakage_leak_port:
          inputdata?.line_disledge_breakage_leak_port,
        line_disledge_breakage_leak_port_no:
          inputdata?.line_disledge_breakage_leak_port_no,
        //
        Arrow5Fr2lumenPICC: inputdata?.Arrow5Fr2lumenPICC,
        Arrow4Fr1lumenPICC: inputdata?.Arrow4Fr1lumenPICC,
        Bard5Fr2LumenpowerPICC: inputdata?.Bard5Fr2LumenpowerPICC,
        Vygon3Fr1lumenPICC: inputdata?.Vygon3Fr1lumenPICC,
        other_final_catheter_in: inputdata?.other_final_catheter_in,
      },
    ],
  };
};
