import Dropdown from "./Dropdown";

const CheckBoxWithDropDown = ({
  title,
  name,
  value,
  disabled = false,
  setField = false,
  dropDownName,
  dropDownValue,
  options,
  other_name,
  other_value,
  classNameDropDown = "my-4",
  className = "flex justify-start items-center  space-x-2 rounded-xl my-4 mx-10",
  setValue = false,
  setStateObject = false,
}) => {
  const onChange = (e) => {
    const thisValue = e.target.checked;

    console.log("thisValue", {
      thisValue,
      title,
      name,
      value,
      disabled,
      setField,
      dropDownName,
      dropDownValue,
      options,
      other_name,
      other_value,
      setValue,
      setStateObject,
    });

    if (setValue && setStateObject) {
      setValue((prev) => {
        let temp = { ...prev };
        temp[name] = thisValue;
        return { ...temp };
      });
    } else if (setField) {
      setField(name)(thisValue);
    } else if (setValue && !setStateObject) {
      setValue(thisValue);
    } else {
      return;
    }
  };

  return (
    <div>
      <div className={className}>
        <input
          type="checkbox"
          name={name}
          checked={value}
          onChange={onChange}
          disabled={disabled}
        />
        <div className="md:text-lg text-base text-textblue ">{title}</div>
      </div>
      {value && (
        <div>
          <Dropdown
            disabled={disabled}
            placeholder="name"
            setField={setField}
            name={dropDownName}
            value={dropDownValue}
            className={classNameDropDown}
            options={options}
            other_name={other_name}
            other_value={other_value}
            setValue={setValue}
            setStateObject={setStateObject}
          />
        </div>
      )}
    </div>
  );
};
export default CheckBoxWithDropDown;
