import Dropdown from "../field/Dropdown";
import TextField from "../field/TextField";
import UploadImage from "../field/UploadImage";
import CheckBoxMulti from "../field/CheckBoxMulti";
import DatePickerField from "../field/DatePickerField";
import {
  headerStyle,
  lineStyle,
  titleStyle,
  WarningLabel,
} from "../../hooks/useClassName";
import Attempt from "../infomation/Attempt";
import {
  AttendingOptions,
  OptionFinalCatheterIn,
  OptionsCatheter,
  OptionTorso,
  RNOptions,
} from "../../contexts/DataOptions";
import { ListDoctorAndNurseContext } from "../../contexts/ListDoctorAndNurseContext";
import { filterRole } from "../../components/functions/Functions";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { WatDatePicker } from "thaidatepicker-react";
import dayjs from "dayjs";
import CheckBoxWithTextField from "../field/CheckBoxWithTextField";
import CheckBoxSelectOne from "../field/CheckboxSelectOne";
import CheckBoxMultiSelectone from "../field/CheckBoxMultiSelectone";
const FromProcedure = ({ form, setField }) => {
  const task_id = "0";
  const { listName } = useContext(ListDoctorAndNurseContext);
  const { user } = useSelector((state) => state.auth);

  React.useEffect(() => {
    // if (listName && form?.status === "waiting_full") {
    //   const findSelf = listName?.find((d) => {
    //     return d?.label === user?.fullname;
    //   });
    //   if (findSelf) {
    //     if (user?.role === "DOCTOR") {
    //       setField("doctor")(findSelf);
    //     }
    //     if (user?.role === "NURSE") {
    //       setField("nurse")(findSelf);
    //     }
    //   } else {
    //     return;
    //   }
    // } else {
    //   return;
    // }
  }, [listName]);

  const handleXray = (christDate, buddhistDate) => {
    // console.log(christDate)
    // console.log(buddhistDate);
    setField("x_ray_date")(christDate);
    // setSelectedDate(christDate)
    // setSelectedThaiDate(buddhistDate)
  };

  return (
    <div>
      <div className=" flex justify-between items-center my-5 px-10 md:mx-10 lg:mx-10 xl:mx-10">
        <div className=" md:text-2xl text-xl text-textinput font-semibold">
          Location
        </div>
        {/* <div className=" md:text-base text-xs text-textinput flex gap-2 ">
          Edit
          <img src="../EditVector.png" className=" md:w-4 md:h-4 w-3 h-3" />
        </div> */}
      </div>
      <div className="mt-2">
        <CheckBoxMulti
          setField={setField}
          name={["number", "beside", "other_location"]}
          value={[form?.number, form?.beside, form?.other_location]}
          label={["504.5", "Bedside", "Other"]}
          other_name="other_location_name"
          other_value={form?.other_location_name}
        />
      </div>
      <WarningLabel
        label={
          "  กดแถบ Attempt เพื่อกรอกรายละเอียด (วันที่ใส่สาย = วันที่ของ attempt ครั้งที่สำเร็จ)"
        }
      />
      <Attempt task_id={task_id} form={form} setField={setField} />
      {/* ประเภทสายจะเป็นตัวบอกว่าคนนี้อยู่ในกลุ่ม UNFOLLOW ไหม  */}
      <div className="flex ">
        <Dropdown
          title="Catheter"
          classNameTitle={
            "md:text-2xl text-xl text-textinput font-semibold my-5 mx-10"
          }
          setField={setField}
          name="catheter"
          value={form?.catheter}
          options={OptionsCatheter}
          other_name="other_catheter_name"
          other_value={form?.other_catheter_name}
          classNameOther={
            "text-base mx-10 md:mx-10 lg:mx-10 xl:mx-10 w-80 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full "
          }
        />
        {form?.catheter?.value === "other_catheter" && (
          <div className="mx-5 w-60 p-10 m-8 flex gap-3">
            <CheckBoxSelectOne
              title={""}
              setField={setField}
              value={form?.check_catheter}
              name="check_catheter"
              label={["picc", "central"]}
              className={
                "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2   md:gap-20  lg:gap-1 xl:gap-1 my-2 mx-10 md:mx-12 lg:mx-10 xl:mx-10"
              }
              classNameCustom="text-sm"
            />
          </div>
        )}
      </div>
      <Dropdown
        title="Torso"
        classNameTitle={
          "md:text-2xl text-xl text-textinput font-semibold my-5 mx-10"
        }
        name="check_torso"
        value={form?.check_torso}
        setField={setField}
        options={OptionTorso}
      />

      <CheckBoxMultiSelectone
        title="Final catheter in"
        name={["final_lt", "final_rt"]}
        label={["Lt", "Rt"]}
        value={[form?.final_lt, form?.final_rt]}
        setField={setField}
      />
      <Dropdown
        name="final_catheter_in"
        value={form?.final_catheter_in}
        setField={setField}
        other_name="other_final_catheter_in"
        other_value={form?.other_final_catheter_in}
        options={OptionFinalCatheterIn}
      />
      <CheckBoxMulti
        title="Insertion technique"
        name={["fuided_seldinger", "rewire", "percutaneous", "other_technique"]}
        label={["U/S guided, Seldinger", "Rewire", "Percutaneous", "Other"]}
        value={[
          form?.fuided_seldinger,
          form?.rewire,
          form?.percutaneous,
          form?.other_technique,
        ]}
        setField={setField}
        other_name="other_technique_name"
        other_value={form?.other_technique_name}
      />

      <CheckBoxMulti
        title="Confirmation"
        label={["iECG", "Fluoroscopy", "Landmark"]}
        name={["iecg", "fluoroscopy", "landmark"]}
        value={[form?.iecg, form?.fluoroscopy, form?.landmark]}
        useDynamicDisable
        disableValue={[
          form?.landmark,
          form?.landmark,
          form?.iecg || form?.fluoroscopy,
        ]}
        setField={setField}
      />

      {/* 
      <CheckBoxMulti
        title="Confirmation"
        label={["Landmark", "iECG", "Fluoroscopy"]}
        name={["landmark", "iecg", "fluoroscopy"]}
        value={[form?.landmark, form?.iecg, form?.fluoroscopy]}
        useDynamicDisable
        disableValue={[
          form?.iecg || form?.fluoroscopy,
          form?.landmark,
          form?.landmark,
        ]}
        setField={setField}
      /> */}

      {/* <TextField
        title="Mark"
        setField={setField}
        name="mark"
        value={form?.mark}
        // disabled={true}
        unit="cm."
        // type="number"
      /> */}

      <TextField
        title="Exit site mark"
        setField={setField}
        name="exit_site_mark"
        value={form?.exit_site_mark}
        unit="cm."
        type="number"
      />
      <TextField
        title="Indwelled"
        setField={setField}
        name="indwelled"
        value={form?.indwelled}
        unit="cm."
        type="number"
      />
      {/* <DatePickerField
        title="X-rays date"
        setField={setField}
        name="x_ray_date"
        dateType="Date"
        value={form?.x_ray_date ? new Date(form?.x_ray_date) : null}
      /> */}

      <div className="">
        <div className={titleStyle}> X-rays date</div>
        <div className="text-base w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full  mx-10">
          <WatDatePicker
            value={form?.x_ray_date ? form?.x_ray_date : null}
            onChange={handleXray}
            dateFormat={"yyyy-MM-dd"}
            displayFormat={"DD MMMM YYYY"}
            placeholder={"DD/MM/YYYY"}
            inputStyle={{ color: "black", width: "190px" }}
            clearable={true}
            minDate={"1920-01-01"}
            maxDate={dayjs().add(20, "day")}
            disabled={false}
            readOnly={false}
          />
        </div>
      </div>

      <Dropdown
        title=""
        name="tip_of_catheter"
        value={form?.tip_of_catheter}
        setField={setField}
        options={[
          { label: "-3", value: "-3" },
          { label: "-2", value: "-2" },
          { label: "-1", value: "-1" },
          { label: "0", value: "0" },
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
          // { label: "Other", value: "other" },
        ]}
        other_name="other_tip_of_catheter"
        other_value={form?.other_tip_of_catheter}
      />

      {/* <CheckBoxMulti
        title="Tip of catheter (Lower torso catheter)"
        label={["In IVC", "Not in IVC", "Unintended position"]}
        name={["in_ivc", "not_in_ivc", "other_tip_of_catheter_lower"]}
        value={[
          form?.in_ivc,
          form?.not_in_ivc,
          form?.other_tip_of_catheter_lower,
        ]}
        other_name="other_tip_of_catheter_lower_name"
        other_value={form?.other_tip_of_catheter_lower_name}
        setField={setField}
      /> */}

      <CheckBoxMulti
        title="Tip of catheter (Lower torso catheter)"
        label={["In IVC", "Not in IVC"]}
        name={["in_ivc", "not_in_ivc"]}
        value={[form?.in_ivc, form?.not_in_ivc]}
        setField={setField}
      />

      <CheckBoxMulti
        title="Unintended position"
        label={["Unintended position"]}
        name={["other_tip_of_catheter_lower"]}
        value={[form?.other_tip_of_catheter_lower]}
        other_name="other_tip_of_catheter_lower_name"
        other_value={form?.other_tip_of_catheter_lower_name}
        setField={setField}
      />

      <UploadImage
        title="Picture of exit site on insertion date"
        setField={setField}
        name="picture_of_exit_site"
        value={form?.picture_of_exit_site}
      />
      {/* <div className={lineStyle} /> */}
      <div className={headerStyle}>Other details related to procedure </div>
      <TextField
        setField={setField}
        name="other_person"
        value={form?.other_person}
      />
      <Dropdown
        title="Nurse"
        setField={setField}
        name="nurse"
        value={form?.nurse}
        options={filterRole(listName, "NURSE")}
        // options={RNOptions}
      />
      <div className={lineStyle} />
      <div className={headerStyle}>Post insertion CXR checked by</div>
      <Dropdown
        title="Doctor"
        setField={setField}
        name="doctor"
        value={form?.doctor}
        options={filterRole(listName, "DOCTOR")}
        // options={AttendingOptions}
      />

      {/* <div className={headerStyle}>Post insertion CXR checked by</div>
      <Dropdown
        title="Doctor"
        setField={setField}
        name="doctor"
        value={form?.doctor}
        options={filterRole(listName, "DOCTOR")}
        // options={AttendingOptions}
      />
      <Dropdown
        title="Nurse"
        setField={setField}
        name="nurse"
        value={form?.nurse}
        options={filterRole(listName, "NURSE")}
        // options={RNOptions}
      />
      <TextField
        title="Other"
        setField={setField}
        name="other_person"
        value={form?.other_person}
      /> */}
    </div>
  );
};
export default FromProcedure;
