import { ageDayCalculator } from "../../../components/functions/Functions";
import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountGender = ({ year, type, setValue, list }) => {
  //   let gender = { men: 0, women: 0 };

  // console.log("ReportCountGender", {
  //   year,
  //   type,
  //   list,
  // });

  let genderMen = 0;
  let genderWomen = 0;
  let checkTask = [];

  if (list?.length > 0) {
    for (let row of list) {
      const thisYear = row?.final_date_success ? row?.final_date_success : "";
      const useYear = thisYear.substring(0, 4);

      if (year === "ALL") {
        if (type === "PICC") {
          if (row?.gender && row?.final_date_success) {
            if (
              row?.gender === "ชาย" &&
              FunctionCheckType(
                row?.catheter?.value,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "PICC"
            ) {
              genderMen = genderMen + 1;
              // checkTask.push(row._id);
            } else if (
              row?.gender === "หญิง" &&
              FunctionCheckType(
                row?.catheter?.value,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "PICC"
            ) {
              genderWomen = genderWomen + 1;
              // checkTask.push(row._id);
            }
          }
        } else if (type === "CENTRAL_LINE") {
          if (row?.gender && row?.final_date_success) {
            if (
              row?.gender === "ชาย" &&
              FunctionCheckType(
                row?.catheter?.value,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "CENTRAL_LINE"
            ) {
              genderMen = genderMen + 1;
              checkTask.push(row._id);
            } else if (
              row?.gender === "หญิง" &&
              FunctionCheckType(
                row?.catheter?.value,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "CENTRAL_LINE"
            ) {
              genderWomen = genderWomen + 1;
            }
          }
        } else if (type === "NONE") {
          if (row?.gender && row?.final_date_success) {
            if (row?.gender === "ชาย") {
              genderMen = genderMen + 1;
            } else if (row?.gender === "หญิง") {
              genderWomen = genderWomen + 1;
            }
          }
        }
      }
      if (year === useYear) {
        if (type === "PICC") {
          if (
            row?.gender &&
            row?.final_date_success
            // &&
            // row?.createdAt > `2021-12-31T23:59:59.999Z` &&
            // row?.createdAt <= `2022-12-31T23:59:59.999Z`
          ) {
            if (
              row?.gender === "ชาย" &&
              FunctionCheckType(
                row?.catheter?.value,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "PICC"
            ) {
              genderMen = genderMen + 1;
            } else if (
              row?.gender === "หญิง" &&
              FunctionCheckType(
                row?.catheter?.value,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "PICC"
            ) {
              genderWomen = genderWomen + 1;
            }
          }
        } else if (type === "CENTRAL_LINE") {
          if (
            row?.gender &&
            row?.final_date_success
            // &&
            // row?.createdAt > `2021-12-31T23:59:59.999Z` &&
            // row?.createdAt <= `2022-12-31T23:59:59.999Z`
          ) {
            if (
              row?.gender === "ชาย" &&
              FunctionCheckType(
                row?.catheter?.value,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "CENTRAL_LINE"
            ) {
              genderMen = genderMen + 1;
            } else if (
              row?.gender === "หญิง" &&
              FunctionCheckType(
                row?.catheter?.value,
                row?.other_catheter_name,
                row?.check_catheter
              ) === "CENTRAL_LINE"
            ) {
              genderWomen = genderWomen + 1;
            } else {
            }
          }
        } else if (type === "NONE") {
          if (
            row?.gender &&
            row?.final_date_success
            // &&
            // row?.createdAt > `2021-12-31T23:59:59.999Z` &&
            // row?.createdAt <= `2022-12-31T23:59:59.999Z`
          ) {
            if (row?.gender === "ชาย") {
              genderMen = genderMen + 1;
            } else if (row?.gender === "หญิง") {
              genderWomen = genderWomen + 1;
            }
          }
        }
      }
      // if (year === "2023") {
      //   if (type === "PICC") {
      //     if (
      //       row?.gender &&
      //       useYear === "2023"
      //       // row?.createdAt > `2022-12-31T23:59:59.999Z` &&
      //       // row?.createdAt <= `2023-12-31T23:59:59.999Z`
      //     ) {
      //       if (
      //         row?.gender === "ชาย" &&
      //         FunctionCheckType(
      //           row?.catheter?.label,
      //           row?.other_catheter_name,
      //           row?.check_catheter
      //         ) === "PICC"
      //       ) {
      //         genderMen = genderMen + 1;
      //       } else if (
      //         row?.gender === "หญิง" &&
      //         FunctionCheckType(
      //           row?.catheter?.label,
      //           row?.other_catheter_name,
      //           row?.check_catheter
      //         ) === "PICC"
      //       ) {
      //         genderWomen = genderWomen + 1;
      //       }
      //     }
      //   } else if (type === "CENTRAL_LINE") {
      //     if (
      //       row?.gender &&
      //       useYear === "2023"
      //       // row?.createdAt > `2022-12-31T23:59:59.999Z` &&
      //       // row?.createdAt <= `2023-12-31T23:59:59.999Z`
      //     ) {
      //       if (
      //         row?.gender === "ชาย" &&
      //         FunctionCheckType(
      //           row?.catheter?.label,
      //           row?.other_catheter_name,
      //           row?.check_catheter
      //         ) === "CENTRAL_LINE"
      //       ) {
      //         genderMen = genderMen + 1;
      //       } else if (
      //         row?.gender === "หญิง" &&
      //         FunctionCheckType(
      //           row?.catheter?.label,
      //           row?.other_catheter_name,
      //           row?.check_catheter
      //         ) === "CENTRAL_LINE"
      //       ) {
      //         genderWomen = genderWomen + 1;
      //       }
      //     }
      //   } else if (type === "NONE") {
      //     if (
      //       row?.gender &&
      //       useYear === "2023"
      //       // row?.createdAt > `2022-12-31T23:59:59.999Z` &&
      //       // row?.createdAt <= `2023-12-31T23:59:59.999Z`
      //     ) {
      //       if (row?.gender === "ชาย") {
      //         genderMen = genderMen + 1;
      //       } else if (row?.gender === "หญิง") {
      //         genderWomen = genderWomen + 1;
      //       }
      //     }
      //   }
      // }
    }
    setValue({
      men: genderMen,
      women: genderWomen,
      checkTask: checkTask,
    });
  }
};

export default ReportCountGender;
