import { ageDayCalculator } from "../../../components/functions/Functions";

const ReportCountRating = ({ setValue, year, list }) => {
  //a หน้าคือ ญาติ
  //a หลังคือ บุคลากร

  let aa = 0; // 01
  let ab = 0; // 02
  let ac = 0; // 03
  let ad = 0; // 04
  let ae = 0; // 05

  let ba = 0; // 01
  let bb = 0; // 02
  let bc = 0; // 03
  let bd = 0; // 04
  let be = 0; // 05

  if (list?.length > 0) {
    for (let row of list) {
      const thisYear = row?.date_follow ? row?.date_follow : "";
      const useYear = thisYear.substring(0, 4);

      if (year === "ALL") {
        if (row?.relative_satisfaction === "1") {
          aa++;
        }
        if (row?.relative_satisfaction === "2") {
          ab++;
        }
        if (row?.relative_satisfaction === "3") {
          ac++;
        }
        if (row?.relative_satisfaction === "4") {
          ad++;
        }
        if (row?.relative_satisfaction === "5") {
          ae++;
        }

        //
        if (row?.patient_care_personnel_satisfaction === "1") {
          ba++;
        }
        if (row?.patient_care_personnel_satisfaction === "2") {
          bb++;
        }
        if (row?.patient_care_personnel_satisfaction === "3") {
          bc++;
        }
        if (row?.patient_care_personnel_satisfaction === "4") {
          bd++;
        }
        if (row?.patient_care_personnel_satisfaction === "5") {
          be++;
        }
      }
      if (year === useYear) {
        if (row?.relative_satisfaction === "1") {
          aa++;
        }
        if (row?.relative_satisfaction === "2") {
          ab++;
        }
        if (row?.relative_satisfaction === "3") {
          ac++;
        }
        if (row?.relative_satisfaction === "4") {
          ad++;
        }
        if (row?.relative_satisfaction === "5") {
          ae++;
        }

        //
        if (row?.patient_care_personnel_satisfaction === "1") {
          ba++;
        }
        if (row?.patient_care_personnel_satisfaction === "2") {
          bb++;
        }
        if (row?.patient_care_personnel_satisfaction === "3") {
          bc++;
        }
        if (row?.patient_care_personnel_satisfaction === "4") {
          bd++;
        }
        if (row?.patient_care_personnel_satisfaction === "5") {
          be++;
        }
      }
      // if (year === "2023") {
      //   if (useYear === "2023") {
      //     if (row?.relative_satisfaction === "1") {
      //       aa++;
      //     }
      //     if (row?.relative_satisfaction === "2") {
      //       ab++;
      //     }
      //     if (row?.relative_satisfaction === "3") {
      //       ac++;
      //     }
      //     if (row?.relative_satisfaction === "4") {
      //       ad++;
      //     }
      //     if (row?.relative_satisfaction === "5") {
      //       ae++;
      //     }

      //     //
      //     if (row?.patient_care_personnel_satisfaction === "1") {
      //       ba++;
      //     }
      //     if (row?.patient_care_personnel_satisfaction === "2") {
      //       bb++;
      //     }
      //     if (row?.patient_care_personnel_satisfaction === "3") {
      //       bc++;
      //     }
      //     if (row?.patient_care_personnel_satisfaction === "4") {
      //       bd++;
      //     }
      //     if (row?.patient_care_personnel_satisfaction === "5") {
      //       be++;
      //     }
      //   }
      // }
    }

    setValue({
      level01: {
        relative: aa,
        patient: ba,
      },
      level02: {
        relative: ab,
        patient: bb,
      },
      level03: {
        relative: ac,
        patient: bc,
      },
      level04: {
        relative: ad,
        patient: bd,
      },
      level05: {
        relative: ae,
        patient: be,
      },
    });
  }
};
export default ReportCountRating;
