import React from "react";
import Lottie from "lottie-react";
import LottieFile from "./LottieFile.json";

const Loading = ({ count }) => {
  return (
    <div className="">
      <Lottie animationData={LottieFile} className=" w-52 mx-auto " />
      <div className=" w-52 mx-auto text-xl text-center text-textblue font-bold">
        {" "}
        {count ? ` ${count} %` : ""}
      </div>
    </div>
  );
};
export default Loading;
