import FunctionCheckType from "./Report.FunctionCheckType";
import _ from "lodash";

const ReportCountAttemptByDoc = ({ list, setValue, year, type }) => {
  const result = list?.reduce((acc, row) => {
    const attendingNameList =
      row?.attempts?.map((row) => row?.attending_name?.label) || [];
    return [...acc, ...attendingNameList];
  }, []);

  const filteredResult = result.filter((name) => name !== "ทดสอบ");
  const doctorList = _.uniq(_.compact(filteredResult));
  const doctorListObj = doctorList?.map((row) => ({
    value: row,
    label: row,
  }));

  let doctorCounts = doctorListObj.reduce((acc, doctor) => {
    acc[doctor.value] = {
      doctor: doctor.value,
      countSuccess: 0,
      countUnsuccess: 0,
    };
    return acc;
  }, {});

  list.forEach((row) => {
    const thisYear = row?.final_date_success ? row?.final_date_success : "";
    const useYear = thisYear.substring(0, 4);
    const useType = FunctionCheckType(
      row?.catheter?.label,
      row?.other_catheter_name,
      row?.check_catheter
    );

    let attempts = row?.attempts;

    if (year === "ALL" || year === useYear) {
      if (type === "NONE" || type === useType) {
        for (const attempt of attempts) {
          const doctorValue = attempt?.attending_name?.label;
          if (doctorCounts.hasOwnProperty(doctorValue)) {
            if (attempt?.status === "Successful") {
              doctorCounts[doctorValue].countSuccess++;
            }
            if (attempt?.status === "Unsuccessful") {
              doctorCounts[doctorValue].countUnsuccess++;
            }
          }
        }
      }
    }
    // if (year === useYear) {
    //   if (type === "NONE") {
    //     for (const attempt of attempts) {
    //       const doctorValue = attempt?.attending_name?.label;
    //       if (doctorCounts.hasOwnProperty(doctorValue)) {
    //         if (attempt?.status === "Successful") {
    //           doctorCounts[doctorValue].countSuccess++;
    //         }
    //         if (attempt?.status === "Unsuccessful") {
    //           doctorCounts[doctorValue].countUnsuccess++;
    //         }
    //       }
    //     }
    //   }
    //   if (
    //     type === "PICC" &&
    //     FunctionCheckType(
    //       row?.catheter?.label,
    //       row?.other_catheter_name,
    //       row?.check_catheter
    //     ) === "PICC"
    //   ) {
    //     for (const attempt of attempts) {
    //       const doctorValue = attempt?.attending_name?.label;
    //       if (doctorCounts.hasOwnProperty(doctorValue)) {
    //         if (attempt?.status === "Successful") {
    //           doctorCounts[doctorValue].countSuccess++;
    //         }
    //         if (attempt?.status === "Unsuccessful") {
    //           doctorCounts[doctorValue].countUnsuccess++;
    //         }
    //       }
    //     }
    //   }
    //   if (
    //     type === "CENTRAL_LINE" &&
    //     FunctionCheckType(
    //       row?.catheter?.label,
    //       row?.other_catheter_name,
    //       row?.check_catheter
    //     ) === "CENTRAL_LINE"
    //   ) {
    //     for (const attempt of attempts) {
    //       const doctorValue = attempt?.attending_name?.label;
    //       if (doctorCounts.hasOwnProperty(doctorValue)) {
    //         if (attempt?.status === "Successful") {
    //           doctorCounts[doctorValue].countSuccess++;
    //         }
    //         if (attempt?.status === "Unsuccessful") {
    //           doctorCounts[doctorValue].countUnsuccess++;
    //         }
    //       }
    //     }
    //   }
    // }
  });

  const result2 = Object.values(doctorCounts);

  setValue(result2);
};

export default ReportCountAttemptByDoc;
