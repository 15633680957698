const XLSX = require("xlsx");

export const ExcelAttemptByDoc = (submitExcelData, year, type) => {
  const wb = XLSX.utils.book_new();

  // Header for the worksheet, adjust as necessary to match your data structure
  const ws_data = [
    [
      `attempt ทั้งหมด แยกตามแพทย์ผู้ใส่ แยกตามปี ${year?.label} ตามประเภทสาย ${type?.label}`,
    ],
    [
      "ชื่อ",
      "attempt ทั้งหมด",
      "attempt ที่สำเร็จ",
      "% ที่สำเร็จ",
      "attempt ที่ Fail",
      "% ที่ Fail",
    ],
  ];

  // Fill ws_data with rows from submitExcelData
  submitExcelData.forEach((item) => {
    ws_data.push([
      item.doctor,
      item.countAttemps,
      item.countSuccess,
      item.countSuccess_percent + "%", // Adding '%' to make it clear it's a percentage
      item.countUnsuccess,
      item.countUnsuccess_percent + "%", // Adding '%' to make it clear it's a percentage
    ]);
  });

  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  XLSX.utils.book_append_sheet(wb, ws, "Attempts Report");

  // Naming the file with year and type if provided, with fallbacks
  const fileName = `AttemptReport_${year?.label || "Year"}_${
    type?.label || "Type"
  }.xlsx`;
  XLSX.writeFile(wb, fileName);
};

// import ExcelExport from "export-xlsx";

// export const ExcelAttemptByDoc = async (
//   inputdata,
//   year,
//   type,
//   setLoading,
//   height
// ) => {
//   // const inputdata = { data: [{ test: 5 }] };
//   const SETTINGS_FOR_EXPORT = {
//     // Table settings
//     fileName: "ALiST_REPORT",
//     workSheets: [
//       {
//         sheetName: "Report",
//         startingRowNumber: 1,
//         gapBetweenTwoTables: 6,

//         tableSettings: {
//           data: {
//             headerGroups: [
//               //กลุ่มที่ 1
//               {
//                 name: `attempt ทั้งหมด แยกตามแพทย์ผู้ใส่ แยกตามปี ${year?.label} ตามประเภทสาย ${type?.label}`,
//                 key: "header_1",
//                 style: { fill: { fgColor: { rgb: "FF0000" } } },
//               },

//               {
//                 name: "จิตสุภา นิธิอุทัย",
//                 key: "doc_01",
//                 groupKey: "header_1",
//               },

//               {
//                 name: "ชยาภา ลัคนาจันทโชติ",
//                 groupKey: "header_1",
//                 key: "doc_02",
//               },

//               {
//                 name: "ฐานิกา เกียรติชัย",
//                 key: "doc_03",
//                 groupKey: "header_1",
//               },

//               {
//                 name: "ณัฐชัย เหมทานนท์",
//                 key: "doc_04",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ธัชวรรณ จิระติวานนท์",
//                 key: "doc_05",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "นนทิดา โรจนพิทยากร",
//                 key: "doc_06",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ประเสริฐ สวัสดิ์วิภาชัย",
//                 key: "doc_07",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ปราณี รัชตามุขยนันต์",
//                 key: "doc_08",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ภาวิณี ปางทิพย์อำไพ",
//                 key: "doc_09",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ปาริฉัตต์ โตวิกกัย",
//                 key: "doc_10",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ภัทราพรรณ วงศ์ศรีภูมิเทศ",
//                 key: "doc_11",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ภานพ หลิมรัตน์",
//                 key: "doc_12",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ภาวิต สมนึก",
//                 key: "doc_13",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "รัตนาภรณ์ ตันกูล",
//                 key: "doc_14",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "วรรธนันทน์ มะกรสาร",
//                 key: "doc_15",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "สมิตา ไพโรจน์ศักดิ์",
//                 key: "doc_16",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "สวิตา คณาวิฑูรย์",
//                 key: "doc_17",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "สหัสา หมั่นดี",
//                 key: "doc_18",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "สิริมา ภูวนกุลชัย",
//                 key: "doc_19",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "อภิชาต ศุภธรรมวิทย์",
//                 key: "doc_20",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "อรรณพ พิริยะแพทย์สม",
//                 key: "doc_21",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "อรวรรณ พงศ์รวีวรรณ",
//                 key: "doc_22",
//                 groupKey: "header_1",
//               },
//               //   {
//               //     name: "คณิตา กสิการ",
//               //     key: "doc_23",
//               //     groupKey: "header_1",
//               //   },
//               {
//                 name: "วาริยา วงศ์ชัยอุดมโชค",
//                 key: "doc_24",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ธนิทธิ ทิพย์อาภรณ์",
//                 key: "doc_25",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ชุติมา ลีวัชรารุ่งเจริญ",
//                 key: "doc_26",
//                 groupKey: "header_1",
//               },
//             ],
//             headerDefinition: [
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_01",
//                 groupKey: "doc_01",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_01",
//                 groupKey: "doc_01",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_01",
//                 groupKey: "doc_01",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_01",
//                 groupKey: "doc_01",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_01",
//                 groupKey: "doc_01",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_02",
//                 groupKey: "doc_02",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_02",
//                 groupKey: "doc_02",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_02",
//                 groupKey: "doc_02",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_02",
//                 groupKey: "doc_02",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_02",
//                 groupKey: "doc_02",
//               },

//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_03",
//                 groupKey: "doc_03",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_03",
//                 groupKey: "doc_03",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_03",
//                 groupKey: "doc_03",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_03",
//                 groupKey: "doc_03",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_03",
//                 groupKey: "doc_03",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_04",
//                 groupKey: "doc_04",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_04",
//                 groupKey: "doc_04",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_04",
//                 groupKey: "doc_04",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_04",
//                 groupKey: "doc_04",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_04",
//                 groupKey: "doc_04",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_05",
//                 groupKey: "doc_05",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_05",
//                 groupKey: "doc_05",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_05",
//                 groupKey: "doc_05",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_05",
//                 groupKey: "doc_05",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_05",
//                 groupKey: "doc_05",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_06",
//                 groupKey: "doc_06",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_06",
//                 groupKey: "doc_06",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_06",
//                 groupKey: "doc_06",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_06",
//                 groupKey: "doc_06",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_06",
//                 groupKey: "doc_06",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_07",
//                 groupKey: "doc_07",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_07",
//                 groupKey: "doc_07",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_07",
//                 groupKey: "doc_07",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_07",
//                 groupKey: "doc_07",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_07",
//                 groupKey: "doc_07",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_08",
//                 groupKey: "doc_08",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_08",
//                 groupKey: "doc_08",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_08",
//                 groupKey: "doc_08",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_08",
//                 groupKey: "doc_08",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_08",
//                 groupKey: "doc_08",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_09",
//                 groupKey: "doc_09",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_09",
//                 groupKey: "doc_09",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_09",
//                 groupKey: "doc_09",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_09",
//                 groupKey: "doc_09",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_09",
//                 groupKey: "doc_09",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_10",
//                 groupKey: "doc_10",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_10",
//                 groupKey: "doc_10",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_10",
//                 groupKey: "doc_10",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_10",
//                 groupKey: "doc_10",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_10",
//                 groupKey: "doc_10",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_11",
//                 groupKey: "doc_11",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_11",
//                 groupKey: "doc_11",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_11",
//                 groupKey: "doc_11",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_11",
//                 groupKey: "doc_11",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_11",
//                 groupKey: "doc_11",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_12",
//                 groupKey: "doc_12",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_12",
//                 groupKey: "doc_12",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_12",
//                 groupKey: "doc_12",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_12",
//                 groupKey: "doc_12",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_12",
//                 groupKey: "doc_12",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_13",
//                 groupKey: "doc_13",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_13",
//                 groupKey: "doc_13",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_13",
//                 groupKey: "doc_13",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_13",
//                 groupKey: "doc_13",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_13",
//                 groupKey: "doc_13",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_14",
//                 groupKey: "doc_14",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_14",
//                 groupKey: "doc_14",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_14",
//                 groupKey: "doc_14",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_14",
//                 groupKey: "doc_14",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_14",
//                 groupKey: "doc_14",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_15",
//                 groupKey: "doc_15",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_15",
//                 groupKey: "doc_15",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_15",
//                 groupKey: "doc_15",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_15",
//                 groupKey: "doc_15",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_15",
//                 groupKey: "doc_15",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_16",
//                 groupKey: "doc_16",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_16",
//                 groupKey: "doc_16",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_16",
//                 groupKey: "doc_16",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_16",
//                 groupKey: "doc_16",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_16",
//                 groupKey: "doc_16",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_17",
//                 groupKey: "doc_17",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_17",
//                 groupKey: "doc_17",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_17",
//                 groupKey: "doc_17",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_17",
//                 groupKey: "doc_17",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_17",
//                 groupKey: "doc_17",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_18",
//                 groupKey: "doc_18",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_18",
//                 groupKey: "doc_18",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_18",
//                 groupKey: "doc_18",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_18",
//                 groupKey: "doc_18",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_18",
//                 groupKey: "doc_18",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_19",
//                 groupKey: "doc_19",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_19",
//                 groupKey: "doc_19",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_19",
//                 groupKey: "doc_19",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_19",
//                 groupKey: "doc_19",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_19",
//                 groupKey: "doc_19",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_20",
//                 groupKey: "doc_20",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_20",
//                 groupKey: "doc_20",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_20",
//                 groupKey: "doc_20",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_20",
//                 groupKey: "doc_20",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_20",
//                 groupKey: "doc_20",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_21",
//                 groupKey: "doc_21",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_21",
//                 groupKey: "doc_21",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_21",
//                 groupKey: "doc_21",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_21",
//                 groupKey: "doc_21",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_21",
//                 groupKey: "doc_21",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_22",
//                 groupKey: "doc_22",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_22",
//                 groupKey: "doc_22",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_22",
//                 groupKey: "doc_22",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_22",
//                 groupKey: "doc_22",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_22",
//                 groupKey: "doc_22",
//               },
//               //
//               //   {
//               //     name: "attempt ทั้งหมด",
//               //     key: "doc_sum_23",
//               //     groupKey: "doc_23",
//               //   },
//               //   {
//               //     name: "attempt ที่สำเร็จ",
//               //     key: "doc_suc_23",
//               //     groupKey: "doc_23",
//               //   },
//               //   {
//               //     name: "% ที่สำเร็จ",
//               //     key: "doc_per_suc_23",
//               //     groupKey: "doc_23",
//               //   },
//               //   {
//               //     name: "attempt ที่ Fail",
//               //     key: "doc_un_23",
//               //     groupKey: "doc_23",
//               //   },
//               //   {
//               //     name: "% ที่ Fail",
//               //     key: "doc_per_un_23",
//               //     groupKey: "doc_23",
//               //   },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_24",
//                 groupKey: "doc_24",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_24",
//                 groupKey: "doc_24",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_24",
//                 groupKey: "doc_24",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_24",
//                 groupKey: "doc_24",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_24",
//                 groupKey: "doc_24",
//               },
//               //
//               {
//                 name: "attempt ทั้งหมด",
//                 key: "doc_sum_25",
//                 groupKey: "doc_25",
//               },
//               {
//                 name: "attempt ที่สำเร็จ",
//                 key: "doc_suc_25",
//                 groupKey: "doc_25",
//               },
//               {
//                 name: "% ที่สำเร็จ",
//                 key: "doc_per_suc_25",
//                 groupKey: "doc_25",
//               },
//               {
//                 name: "attempt ที่ Fail",
//                 key: "doc_un_25",
//                 groupKey: "doc_25",
//               },
//               {
//                 name: "% ที่ Fail",
//                 key: "doc_per_un_25",
//                 groupKey: "doc_25",
//               },
//               //
//             ],
//           },
//         },
//       },
//     ],
//   };

//   try {
//     setLoading(true);
//     const excelExport = new ExcelExport();
//     await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
//   } catch (error) {
//     console.log("error", error);
//   } finally {
//     setLoading(false);
//   }
// };
