import { ro } from "date-fns/locale";
import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountTorso = ({ list, setValue, type, year, month }) => {
  //   console.log("list2", list);
  //upper
  let con_good_flu = 0;
  let con_good_iecg = 0;
  let con_good_land = 0;

  //upper
  let con_short_flu = 0;
  let con_short_iecg = 0;
  let con_short_land = 0;

  //upper
  let con_long_flu = 0;
  let con_long_iecg = 0;
  let con_long_land = 0;

  //upper
  let con_un_flu = 0;
  let con_un_iecg = 0;
  let con_un_land = 0;
  let con_un_miss = 0;

  //upper
  let miss_upper = 0;

  //
  let lower_unflu = 0;
  let lower_uniecg = 0;
  let lower_unland = 0;
  //
  let in_ivc_iecg = 0;
  let no_ivc_iecg = 0;
  let in_ivc_flu = 0;
  let no_ivc_flu = 0;
  let in_ivc_land = 0;
  let no_ivc_land = 0;
  let in_ivc_miss = 0;
  let no_ivc_miss = 0;

  let miss_lower = 0;

  const formattedMonth = month.length === 1 ? `0${month}` : month;
  if (list?.length > 0) {
    //#filter final_date_success by month

    if (formattedMonth !== "ALL") {
      list = list.filter((row) => {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);
        const useMonth = thisYear.substring(5, 7);
        return useYear === year && useMonth === formattedMonth;
      });
    }

    for (let row of list) {
      const statusCheck =
        row?.status !== "waiting" &&
        row?.status !== "waiting_full" &&
        row?.status !== "keep_for_report";

      if (row?.final_date_success !== "" && statusCheck) {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);

        if (year === "ALL") {
          if (type === "NONE") {
            if (row?.check_torso?.value === "upper") {
              // กลุ่ม ดี
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.fluoroscopy
              ) {
                con_good_flu++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.iecg
              ) {
                con_good_iecg++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.landmark
              ) {
                con_good_land++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_good_land++;
              }

              // กลุ่ม ตื้น
              if (row?.tip_of_catheter?.value === "-3" && row?.fluoroscopy) {
                con_short_flu++;
              }
              if (row?.tip_of_catheter?.value === "-3" && row?.iecg) {
                con_short_iecg++;
              }
              if (row?.tip_of_catheter?.value === "-3" && row?.landmark) {
                con_short_land++;
              }
              if (
                row?.tip_of_catheter?.value === "-3" &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_short_land++;
              }
              // กลุ่ม ลึก
              if (row?.tip_of_catheter?.value === "3" && row?.fluoroscopy) {
                con_long_flu++;
              }
              if (row?.tip_of_catheter?.value === "3" && row?.iecg) {
                con_long_iecg++;
              }
              if (row?.tip_of_catheter?.value === "3" && row?.landmark) {
                con_long_land++;
              }
              if (
                row?.tip_of_catheter?.value === "3" &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_long_land++;
              }

              // กลุ่ม un
              if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
                con_un_flu++;
              }
              if (row?.other_tip_of_catheter_lower && row?.iecg) {
                con_un_iecg++;
              }
              if (row?.other_tip_of_catheter_lower && row?.landmark) {
                con_un_land++;
              }
              if (
                row?.other_tip_of_catheter_lower &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_un_land++;
              }
            }

            if (row?.check_torso?.value === "lower") {
              // กลุ่ม un
              if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
                lower_unflu++;
              }
              if (row?.other_tip_of_catheter_lower && row?.iecg) {
                lower_uniecg++;
              }
              if (row?.other_tip_of_catheter_lower && row?.landmark) {
                lower_unland++;
              }
              if (
                row?.other_tip_of_catheter_lower &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                lower_unland++;
              }

              if (row?.in_ivc && row?.iecg) {
                in_ivc_iecg++;
              }
              if (row?.not_in_ivc && row?.iecg) {
                no_ivc_iecg++;
              }
              if (row?.in_ivc && row?.fluoroscopy) {
                in_ivc_flu++;
              }
              if (row?.not_in_ivc && row?.fluoroscopy) {
                no_ivc_flu++;
              }
              if (row?.in_ivc && row?.landmark) {
                in_ivc_land++;
              }
              if (row?.not_in_ivc && row?.landmark) {
                no_ivc_land++;
              }
              if (
                row?.in_ivc &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                in_ivc_miss++;
              }
              if (
                row?.not_in_ivc &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                no_ivc_miss++;
              }
            }
            if (
              !row?.check_torso?.value &&
              !row?.tip_of_catheter?.value &&
              !row?.other_tip_of_catheter_lower
            ) {
              con_un_miss++;
            }
            if (!row?.tip_of_catheter && row?.check_torso?.value === "upper") {
              miss_upper++;
            }
            if (
              row?.check_torso?.value === "lower" &&
              !row?.in_ivc &&
              !row?.not_in_ivc &&
              !row?.other_tip_of_catheter_lower
            ) {
              miss_lower++;
            }
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (row?.check_torso?.value === "upper") {
              // กลุ่ม ดี
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.fluoroscopy
              ) {
                con_good_flu++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.iecg
              ) {
                con_good_iecg++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.landmark
              ) {
                con_good_land++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_good_land++;
              }

              // กลุ่ม ตื้น
              if (row?.tip_of_catheter?.value === "-3" && row?.fluoroscopy) {
                con_short_flu++;
              }
              if (row?.tip_of_catheter?.value === "-3" && row?.iecg) {
                con_short_iecg++;
              }
              if (row?.tip_of_catheter?.value === "-3" && row?.landmark) {
                con_short_land++;
              }
              if (
                row?.tip_of_catheter?.value === "-3" &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_short_land++;
              }
              // กลุ่ม ลึก
              if (row?.tip_of_catheter?.value === "3" && row?.fluoroscopy) {
                con_long_flu++;
              }
              if (row?.tip_of_catheter?.value === "3" && row?.iecg) {
                con_long_iecg++;
              }
              if (row?.tip_of_catheter?.value === "3" && row?.landmark) {
                con_long_land++;
              }
              if (
                row?.tip_of_catheter?.value === "3" &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_long_land++;
              }

              // กลุ่ม un
              if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
                con_un_flu++;
              }
              if (row?.other_tip_of_catheter_lower && row?.iecg) {
                con_un_iecg++;
              }
              if (row?.other_tip_of_catheter_lower && row?.landmark) {
                con_un_land++;
              }
              if (
                row?.other_tip_of_catheter_lower &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_un_land++;
              }
            }

            if (row?.check_torso?.value === "lower") {
              // กลุ่ม un
              if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
                lower_unflu++;
              }
              if (row?.other_tip_of_catheter_lower && row?.iecg) {
                lower_uniecg++;
              }
              if (row?.other_tip_of_catheter_lower && row?.landmark) {
                lower_unland++;
              }
              if (
                row?.other_tip_of_catheter_lower &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                lower_unland++;
              }

              if (row?.in_ivc && row?.iecg) {
                in_ivc_iecg++;
              }
              if (row?.not_in_ivc && row?.iecg) {
                no_ivc_iecg++;
              }
              if (row?.in_ivc && row?.fluoroscopy) {
                in_ivc_flu++;
              }
              if (row?.not_in_ivc && row?.fluoroscopy) {
                no_ivc_flu++;
              }
              if (row?.in_ivc && row?.landmark) {
                in_ivc_land++;
              }
              if (row?.not_in_ivc && row?.landmark) {
                no_ivc_land++;
              }
              if (
                row?.in_ivc &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                in_ivc_miss++;
              }
              if (
                row?.not_in_ivc &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                no_ivc_miss++;
              }
            }
            if (
              !row?.check_torso?.value &&
              !row?.tip_of_catheter?.value &&
              !row?.other_tip_of_catheter_lower
            ) {
              con_un_miss++;
            }
            if (!row?.tip_of_catheter && row?.check_torso?.value === "upper") {
              miss_upper++;
            }
            if (
              row?.check_torso?.value === "lower" &&
              !row?.in_ivc &&
              !row?.not_in_ivc &&
              !row?.other_tip_of_catheter_lower
            ) {
              miss_lower++;
            }
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            if (row?.check_torso?.value === "upper") {
              // กลุ่ม ดี
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.fluoroscopy
              ) {
                con_good_flu++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.iecg
              ) {
                con_good_iecg++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.landmark
              ) {
                con_good_land++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_good_land++;
              }

              // กลุ่ม ตื้น
              if (row?.tip_of_catheter?.value === "-3" && row?.fluoroscopy) {
                con_short_flu++;
              }
              if (row?.tip_of_catheter?.value === "-3" && row?.iecg) {
                con_short_iecg++;
              }
              if (row?.tip_of_catheter?.value === "-3" && row?.landmark) {
                con_short_land++;
              }
              if (
                row?.tip_of_catheter?.value === "-3" &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_short_land++;
              }
              // กลุ่ม ลึก
              if (row?.tip_of_catheter?.value === "3" && row?.fluoroscopy) {
                con_long_flu++;
              }
              if (row?.tip_of_catheter?.value === "3" && row?.iecg) {
                con_long_iecg++;
              }
              if (row?.tip_of_catheter?.value === "3" && row?.landmark) {
                con_long_land++;
              }
              if (
                row?.tip_of_catheter?.value === "3" &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_long_land++;
              }

              // กลุ่ม un
              if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
                con_un_flu++;
              }
              if (row?.other_tip_of_catheter_lower && row?.iecg) {
                con_un_iecg++;
              }
              if (row?.other_tip_of_catheter_lower && row?.landmark) {
                con_un_land++;
              }
              if (
                row?.other_tip_of_catheter_lower &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_un_land++;
              }
            }

            if (row?.check_torso?.value === "lower") {
              // กลุ่ม un
              if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
                lower_unflu++;
              }
              if (row?.other_tip_of_catheter_lower && row?.iecg) {
                lower_uniecg++;
              }
              if (row?.other_tip_of_catheter_lower && row?.landmark) {
                lower_unland++;
              }
              if (
                row?.other_tip_of_catheter_lower &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                lower_unland++;
              }

              if (row?.in_ivc && row?.iecg) {
                in_ivc_iecg++;
              }
              if (row?.not_in_ivc && row?.iecg) {
                no_ivc_iecg++;
              }
              if (row?.in_ivc && row?.fluoroscopy) {
                in_ivc_flu++;
              }
              if (row?.not_in_ivc && row?.fluoroscopy) {
                no_ivc_flu++;
              }
              if (row?.in_ivc && row?.landmark) {
                in_ivc_land++;
              }
              if (row?.not_in_ivc && row?.landmark) {
                no_ivc_land++;
              }
              if (
                row?.in_ivc &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                in_ivc_miss++;
              }
              if (
                row?.not_in_ivc &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                no_ivc_miss++;
              }
            }
            if (
              !row?.check_torso?.value &&
              !row?.tip_of_catheter?.value &&
              !row?.other_tip_of_catheter_lower
            ) {
              con_un_miss++;
            }
            if (!row?.tip_of_catheter && row?.check_torso?.value === "upper") {
              miss_upper++;
            }
            if (
              row?.check_torso?.value === "lower" &&
              !row?.in_ivc &&
              !row?.not_in_ivc &&
              !row?.other_tip_of_catheter_lower
            ) {
              miss_lower++;
            }
          }
        }
        if (year === useYear) {
          if (type === "NONE") {
            if (row?.check_torso?.value === "upper") {
              // กลุ่ม ดี
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.fluoroscopy
              ) {
                con_good_flu++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.iecg
              ) {
                con_good_iecg++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.landmark
              ) {
                con_good_land++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_good_land++;
              }

              // กลุ่ม ตื้น
              if (row?.tip_of_catheter?.value === "-3" && row?.fluoroscopy) {
                con_short_flu++;
              }
              if (row?.tip_of_catheter?.value === "-3" && row?.iecg) {
                con_short_iecg++;
              }
              if (row?.tip_of_catheter?.value === "-3" && row?.landmark) {
                con_short_land++;
              }
              if (
                row?.tip_of_catheter?.value === "-3" &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_short_land++;
              }
              // กลุ่ม ลึก
              if (row?.tip_of_catheter?.value === "3" && row?.fluoroscopy) {
                con_long_flu++;
              }
              if (row?.tip_of_catheter?.value === "3" && row?.iecg) {
                con_long_iecg++;
              }
              if (row?.tip_of_catheter?.value === "3" && row?.landmark) {
                con_long_land++;
              }
              if (
                row?.tip_of_catheter?.value === "3" &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_long_land++;
              }

              // กลุ่ม un
              if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
                con_un_flu++;
              }
              if (row?.other_tip_of_catheter_lower && row?.iecg) {
                con_un_iecg++;
              }
              if (row?.other_tip_of_catheter_lower && row?.landmark) {
                con_un_land++;
              }
              if (
                row?.other_tip_of_catheter_lower &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_un_land++;
              }
            }

            if (row?.check_torso?.value === "lower") {
              // กลุ่ม un
              if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
                lower_unflu++;
              }
              if (row?.other_tip_of_catheter_lower && row?.iecg) {
                lower_uniecg++;
              }
              if (row?.other_tip_of_catheter_lower && row?.landmark) {
                lower_unland++;
              }
              if (
                row?.other_tip_of_catheter_lower &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                lower_unland++;
              }

              if (row?.in_ivc && row?.iecg) {
                in_ivc_iecg++;
              }
              if (row?.not_in_ivc && row?.iecg) {
                no_ivc_iecg++;
              }
              if (row?.in_ivc && row?.fluoroscopy) {
                in_ivc_flu++;
              }
              if (row?.not_in_ivc && row?.fluoroscopy) {
                no_ivc_flu++;
              }
              if (row?.in_ivc && row?.landmark) {
                in_ivc_land++;
              }
              if (row?.not_in_ivc && row?.landmark) {
                no_ivc_land++;
              }
              if (
                row?.in_ivc &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                in_ivc_miss++;
              }
              if (
                row?.not_in_ivc &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                no_ivc_miss++;
              }
            }
            if (
              !row?.check_torso?.value &&
              !row?.tip_of_catheter?.value &&
              !row?.other_tip_of_catheter_lower
            ) {
              con_un_miss++;
            }
            if (!row?.tip_of_catheter && row?.check_torso?.value === "upper") {
              miss_upper++;
            }
            if (
              row?.check_torso?.value === "lower" &&
              !row?.in_ivc &&
              !row?.not_in_ivc &&
              !row?.other_tip_of_catheter_lower
            ) {
              miss_lower++;
            }
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (row?.check_torso?.value === "upper") {
              // กลุ่ม ดี
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.fluoroscopy
              ) {
                con_good_flu++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.iecg
              ) {
                con_good_iecg++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.landmark
              ) {
                con_good_land++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_good_land++;
              }

              // กลุ่ม ตื้น
              if (row?.tip_of_catheter?.value === "-3" && row?.fluoroscopy) {
                con_short_flu++;
              }
              if (row?.tip_of_catheter?.value === "-3" && row?.iecg) {
                con_short_iecg++;
              }
              if (row?.tip_of_catheter?.value === "-3" && row?.landmark) {
                con_short_land++;
              }
              if (
                row?.tip_of_catheter?.value === "-3" &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_short_land++;
              }
              // กลุ่ม ลึก
              if (row?.tip_of_catheter?.value === "3" && row?.fluoroscopy) {
                con_long_flu++;
              }
              if (row?.tip_of_catheter?.value === "3" && row?.iecg) {
                con_long_iecg++;
              }
              if (row?.tip_of_catheter?.value === "3" && row?.landmark) {
                con_long_land++;
              }
              if (
                row?.tip_of_catheter?.value === "3" &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_long_land++;
              }

              // กลุ่ม un
              if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
                con_un_flu++;
              }
              if (row?.other_tip_of_catheter_lower && row?.iecg) {
                con_un_iecg++;
              }
              if (row?.other_tip_of_catheter_lower && row?.landmark) {
                con_un_land++;
              }
              if (
                row?.other_tip_of_catheter_lower &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_un_land++;
              }
            }

            if (row?.check_torso?.value === "lower") {
              // กลุ่ม un
              if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
                lower_unflu++;
              }
              if (row?.other_tip_of_catheter_lower && row?.iecg) {
                lower_uniecg++;
              }
              if (row?.other_tip_of_catheter_lower && row?.landmark) {
                lower_unland++;
              }
              if (
                row?.other_tip_of_catheter_lower &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                lower_unland++;
              }

              if (row?.in_ivc && row?.iecg) {
                in_ivc_iecg++;
              }
              if (row?.not_in_ivc && row?.iecg) {
                no_ivc_iecg++;
              }
              if (row?.in_ivc && row?.fluoroscopy) {
                in_ivc_flu++;
              }
              if (row?.not_in_ivc && row?.fluoroscopy) {
                no_ivc_flu++;
              }
              if (row?.in_ivc && row?.landmark) {
                in_ivc_land++;
              }
              if (row?.not_in_ivc && row?.landmark) {
                no_ivc_land++;
              }
              if (
                row?.in_ivc &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                in_ivc_miss++;
              }
              if (
                row?.not_in_ivc &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                no_ivc_miss++;
              }
            }
            if (
              !row?.check_torso?.value &&
              !row?.tip_of_catheter?.value &&
              !row?.other_tip_of_catheter_lower
            ) {
              con_un_miss++;
            }
            if (!row?.tip_of_catheter && row?.check_torso?.value === "upper") {
              miss_upper++;
            }
            if (
              row?.check_torso?.value === "lower" &&
              !row?.in_ivc &&
              !row?.not_in_ivc &&
              !row?.other_tip_of_catheter_lower
            ) {
              miss_lower++;
            }
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            if (row?.check_torso?.value === "upper") {
              // กลุ่ม ดี
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.fluoroscopy
              ) {
                con_good_flu++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.iecg
              ) {
                con_good_iecg++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                row?.landmark
              ) {
                con_good_land++;
              }
              if (
                (row?.tip_of_catheter?.value === "-1" ||
                  row?.tip_of_catheter?.value === "0" ||
                  row?.tip_of_catheter?.value === "1" ||
                  row?.tip_of_catheter?.value === "-2" ||
                  row?.tip_of_catheter?.value === "2") &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_good_land++;
              }

              // กลุ่ม ตื้น
              if (row?.tip_of_catheter?.value === "-3" && row?.fluoroscopy) {
                con_short_flu++;
              }
              if (row?.tip_of_catheter?.value === "-3" && row?.iecg) {
                con_short_iecg++;
              }
              if (row?.tip_of_catheter?.value === "-3" && row?.landmark) {
                con_short_land++;
              }
              if (
                row?.tip_of_catheter?.value === "-3" &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_short_land++;
              }
              // กลุ่ม ลึก
              if (row?.tip_of_catheter?.value === "3" && row?.fluoroscopy) {
                con_long_flu++;
              }
              if (row?.tip_of_catheter?.value === "3" && row?.iecg) {
                con_long_iecg++;
              }
              if (row?.tip_of_catheter?.value === "3" && row?.landmark) {
                con_long_land++;
              }
              if (
                row?.tip_of_catheter?.value === "3" &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_long_land++;
              }

              // กลุ่ม un
              if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
                con_un_flu++;
              }
              if (row?.other_tip_of_catheter_lower && row?.iecg) {
                con_un_iecg++;
              }
              if (row?.other_tip_of_catheter_lower && row?.landmark) {
                con_un_land++;
              }
              if (
                row?.other_tip_of_catheter_lower &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                con_un_land++;
              }
            }

            if (row?.check_torso?.value === "lower") {
              // กลุ่ม un
              if (row?.other_tip_of_catheter_lower && row?.fluoroscopy) {
                lower_unflu++;
              }
              if (row?.other_tip_of_catheter_lower && row?.iecg) {
                lower_uniecg++;
              }
              if (row?.other_tip_of_catheter_lower && row?.landmark) {
                lower_unland++;
              }
              if (
                row?.other_tip_of_catheter_lower &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                lower_unland++;
              }

              if (row?.in_ivc && row?.iecg) {
                in_ivc_iecg++;
              }
              if (row?.not_in_ivc && row?.iecg) {
                no_ivc_iecg++;
              }
              if (row?.in_ivc && row?.fluoroscopy) {
                in_ivc_flu++;
              }
              if (row?.not_in_ivc && row?.fluoroscopy) {
                no_ivc_flu++;
              }
              if (row?.in_ivc && row?.landmark) {
                in_ivc_land++;
              }
              if (row?.not_in_ivc && row?.landmark) {
                no_ivc_land++;
              }
              if (
                row?.in_ivc &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                in_ivc_miss++;
              }
              if (
                row?.not_in_ivc &&
                !row?.landmark &&
                !row?.iecg &&
                !row?.fluoroscopy
              ) {
                no_ivc_miss++;
              }
            }
            if (
              !row?.check_torso?.value &&
              !row?.tip_of_catheter?.value &&
              !row?.other_tip_of_catheter_lower
            ) {
              con_un_miss++;
            }
            if (!row?.tip_of_catheter && row?.check_torso?.value === "upper") {
              miss_upper++;
            }
            if (
              row?.check_torso?.value === "lower" &&
              !row?.in_ivc &&
              !row?.not_in_ivc &&
              !row?.other_tip_of_catheter_lower
            ) {
              miss_lower++;
            }
          }
        }
      }
    }
    setValue({
      // upper
      con_good_flu: con_good_flu,
      con_good_iecg: con_good_iecg,
      con_good_land: con_good_land,
      total_good: con_good_flu + con_good_iecg + con_good_land,

      // upper
      con_short_flu: con_short_flu,
      con_short_iecg: con_short_iecg,
      con_short_land: con_short_land,
      total_short: con_short_flu + con_short_iecg + con_short_land,

      // upper
      con_long_flu: con_long_flu,
      con_long_iecg: con_long_iecg,
      con_long_land: con_long_land,
      total_long: con_long_flu + con_long_iecg + con_long_land,

      // upper
      con_un_flu: con_un_flu,
      con_un_iecg: con_un_iecg,
      con_un_land: con_un_land,
      con_un_miss: con_un_miss,
      total_un: con_un_flu + con_un_iecg + con_un_land,

      miss_upper: miss_upper,
      miss_lower: miss_lower,

      // lower
      lower_unflu: lower_unflu,
      lower_uniecg: lower_uniecg,
      lower_unland: lower_unland,
      total_lower_un: lower_unflu + lower_uniecg + lower_unland,

      //lower
      in_ivc_iecg: in_ivc_iecg,
      no_ivc_iecg: no_ivc_iecg,
      in_ivc_flu: in_ivc_flu,
      no_ivc_flu: no_ivc_flu,
      in_ivc_land: in_ivc_land,
      no_ivc_land: no_ivc_land,
      in_ivc_miss: in_ivc_miss,
      no_ivc_miss: no_ivc_miss,
      total_in_ivc: in_ivc_iecg + in_ivc_flu + in_ivc_land + in_ivc_miss,
    });
  }
};

export default ReportCountTorso;
