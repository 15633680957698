import { ageDayCalculator } from "../../../components/functions/Functions";
import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountPatientType = ({ year, type, setValue, list }) => {
  //   let gender = { men: 0, women: 0 };

  let type1 = 0;
  let type2 = 0;
  let type3 = 0;
  let type4 = 0;
  let type5 = 0;
  let type6 = 0;
  let type7 = 0;

  if (list?.length > 0) {
    for (let row of list) {
      const thisYear = row?.final_date_success ? row?.final_date_success : "";
      const useYear = thisYear.substring(0, 4);

      if (year === "ALL") {
        if (
          type === "PICC" &&
          FunctionCheckType(
            row?.catheter?.label,
            row?.other_catheter_name,
            row?.check_catheter
          ) === "PICC"
        ) {
          if (row?.patient_type?.value) {
            if (row?.patient_type?.value === "อายุรกรรม") {
              type1++;
            } else if (row?.patient_type?.value === "ศัลยกรรม") {
              type2++;
            } else if (row?.patient_type?.value === "สูตินรีเวช") {
              type3++;
            } else if (row?.patient_type?.value === "กุมารเวชศาสตร์") {
              type4++;
            } else if (row?.patient_type?.value === "หออภิบาล") {
              type5++;
            } else if (row?.patient_type?.value === "ผู้ป่วยนอก") {
              type6++;
            } else if (row?.patient_type?.value === "other_patient_type") {
              type7++;
            }
          }
        } else if (
          type === "CENTRAL_LINE" &&
          FunctionCheckType(
            row?.catheter?.label,
            row?.other_catheter_name,
            row?.check_catheter
          ) === "CENTRAL_LINE"
        ) {
          if (row?.patient_type?.value) {
            if (row?.patient_type?.value === "อายุรกรรม") {
              type1++;
            } else if (row?.patient_type?.value === "ศัลยกรรม") {
              type2++;
            } else if (row?.patient_type?.value === "สูตินรีเวช") {
              type3++;
            } else if (row?.patient_type?.value === "กุมารเวชศาสตร์") {
              type4++;
            } else if (row?.patient_type?.value === "หออภิบาล") {
              type5++;
            } else if (row?.patient_type?.value === "ผู้ป่วยนอก") {
              type6++;
            } else if (row?.patient_type?.value === "other_patient_type") {
              type7++;
            }
          }
        } else if (type === "NONE") {
          if (row?.patient_type?.value) {
            if (row?.patient_type?.value === "อายุรกรรม") {
              type1++;
            } else if (row?.patient_type?.value === "ศัลยกรรม") {
              type2++;
            } else if (row?.patient_type?.value === "สูตินรีเวช") {
              type3++;
            } else if (row?.patient_type?.value === "กุมารเวชศาสตร์") {
              type4++;
            } else if (row?.patient_type?.value === "หออภิบาล") {
              type5++;
            } else if (row?.patient_type?.value === "ผู้ป่วยนอก") {
              type6++;
            } else if (row?.patient_type?.value === "other_patient_type") {
              type7++;
            }
          }
        }
      }
      if (year === useYear) {
        if (
          type === "PICC" &&
          FunctionCheckType(
            row?.catheter?.label,
            row?.other_catheter_name,
            row?.check_catheter
          ) === "PICC"
        ) {
          if (row?.patient_type?.value) {
            if (row?.patient_type?.value === "อายุรกรรม") {
              type1++;
            } else if (row?.patient_type?.value === "ศัลยกรรม") {
              type2++;
            } else if (row?.patient_type?.value === "สูตินรีเวช") {
              type3++;
            } else if (row?.patient_type?.value === "กุมารเวชศาสตร์") {
              type4++;
            } else if (row?.patient_type?.value === "หออภิบาล") {
              type5++;
            } else if (row?.patient_type?.value === "ผู้ป่วยนอก") {
              type6++;
            } else if (row?.patient_type?.value === "other_patient_type") {
              type7++;
            }
          }
        } else if (
          type === "CENTRAL_LINE" &&
          FunctionCheckType(
            row?.catheter?.label,
            row?.other_catheter_name,
            row?.check_catheter
          ) === "CENTRAL_LINE"
        ) {
          if (row?.patient_type?.value) {
            if (row?.patient_type?.value === "อายุรกรรม") {
              type1++;
            } else if (row?.patient_type?.value === "ศัลยกรรม") {
              type2++;
            } else if (row?.patient_type?.value === "สูตินรีเวช") {
              type3++;
            } else if (row?.patient_type?.value === "กุมารเวชศาสตร์") {
              type4++;
            } else if (row?.patient_type?.value === "หออภิบาล") {
              type5++;
            } else if (row?.patient_type?.value === "ผู้ป่วยนอก") {
              type6++;
            } else if (row?.patient_type?.value === "other_patient_type") {
              type7++;
            }
          }
        } else if (type === "NONE") {
          if (row?.patient_type?.value) {
            if (row?.patient_type?.value === "อายุรกรรม") {
              type1++;
            } else if (row?.patient_type?.value === "ศัลยกรรม") {
              type2++;
            } else if (row?.patient_type?.value === "สูตินรีเวช") {
              type3++;
            } else if (row?.patient_type?.value === "กุมารเวชศาสตร์") {
              type4++;
            } else if (row?.patient_type?.value === "หออภิบาล") {
              type5++;
            } else if (row?.patient_type?.value === "ผู้ป่วยนอก") {
              type6++;
            } else if (row?.patient_type?.value === "other_patient_type") {
              type7++;
            }
          }
        }
      }
      // if (year === "2023" && useYear === "2023") {
      //   if (
      //     type === "PICC" &&
      //     FunctionCheckType(
      //       row?.catheter?.label,
      //       row?.other_catheter_name,
      //       row?.check_catheter
      //     ) === "PICC"
      //   ) {
      //     if (row?.patient_type?.value) {
      //       if (row?.patient_type?.value === "อายุรกรรม") {
      //         type1++;
      //       } else if (row?.patient_type?.value === "ศัลยกรรม") {
      //         type2++;
      //       } else if (row?.patient_type?.value === "สูตินรีเวช") {
      //         type3++;
      //       } else if (row?.patient_type?.value === "กุมารเวชศาสตร์") {
      //         type4++;
      //       } else if (row?.patient_type?.value === "หออภิบาล") {
      //         type5++;
      //       } else if (row?.patient_type?.value === "ผู้ป่วยนอก") {
      //         type6++;
      //       } else if (row?.patient_type?.value === "other_patient_type") {
      //         type7++;
      //       }
      //     }
      //   } else if (
      //     type === "CENTRAL_LINE" &&
      //     FunctionCheckType(
      //       row?.catheter?.label,
      //       row?.other_catheter_name,
      //       row?.check_catheter
      //     ) === "CENTRAL_LINE"
      //   ) {
      //     if (row?.patient_type?.value) {
      //       if (row?.patient_type?.value === "อายุรกรรม") {
      //         type1++;
      //       } else if (row?.patient_type?.value === "ศัลยกรรม") {
      //         type2++;
      //       } else if (row?.patient_type?.value === "สูตินรีเวช") {
      //         type3++;
      //       } else if (row?.patient_type?.value === "กุมารเวชศาสตร์") {
      //         type4++;
      //       } else if (row?.patient_type?.value === "หออภิบาล") {
      //         type5++;
      //       } else if (row?.patient_type?.value === "ผู้ป่วยนอก") {
      //         type6++;
      //       } else if (row?.patient_type?.value === "other_patient_type") {
      //         type7++;
      //       }
      //     }
      //   } else if (type === "NONE") {
      //     if (row?.patient_type?.value) {
      //       if (row?.patient_type?.value === "อายุรกรรม") {
      //         type1++;
      //       } else if (row?.patient_type?.value === "ศัลยกรรม") {
      //         type2++;
      //       } else if (row?.patient_type?.value === "สูตินรีเวช") {
      //         type3++;
      //       } else if (row?.patient_type?.value === "กุมารเวชศาสตร์") {
      //         type4++;
      //       } else if (row?.patient_type?.value === "หออภิบาล") {
      //         type5++;
      //       } else if (row?.patient_type?.value === "ผู้ป่วยนอก") {
      //         type6++;
      //       } else if (row?.patient_type?.value === "other_patient_type") {
      //         type7++;
      //       }
      //     }
      //   }
      // }
    }
    setValue({
      type1: type1,
      type2: type2,
      type3: type3,
      type4: type4,
      type5: type5,
      type6: type6,
      type7: type7,
    });
  }
};

export default ReportCountPatientType;
