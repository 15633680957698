import ExcelExport from "export-xlsx";

export const ExcelConfrimation = async (inputdata, year, type, setLoading) => {
  // const inputdata = { data: [{ test: 5 }] };
  console.log("inputdata", inputdata);
  const SETTINGS_FOR_EXPORT = {
    // Table settings
    fileName: "ALiST_REPORT",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `รายงานตำแหน่งของสายหลังการถ่าย X-ray ปอด แยกตามปี ${year?.label} ตามประเภทสาย ${type?.label}`,
                key: "header_1",
                style: { fill: { fgColor: { rgb: "FF0000" } } },
              },
              {
                name: `Tip of catheter (Upper torso catheter)`,
                groupKey: "header_1",
                key: "upper",
              },
              {
                name: `Tip of catheter (Upper torso catheter)`,
                groupKey: "header_1",
                key: "lower",
                width: 80,
              },
              {
                name: `ตำแหน่ง`,
                groupKey: "upper",
                key: "position",
              },
              {
                name: `กลุ่มดี (-1 , 0 , +1) `,
                groupKey: "upper",
                key: "group_1",
              },
              {
                name: `กลุ่มตื้น (-2 ,-3)`,
                groupKey: "upper",
                key: "group_2",
              },
              {
                name: `กลุ่มลึก  (+2 ,+3 )`,
                groupKey: "upper",
                key: "group_3",
              },
              {
                name: `กลุ่ม Unintended Position`,
                groupKey: "upper",
                key: "group_4",
              },
            ],
            headerDefinition: [
              {
                name: `-3`,
                groupKey: "position",
                key: "po_1",
              },
              {
                name: `-3 Percentage`,
                groupKey: "position",
                key: "percent_po1",
              },
              {
                name: `-2`,
                groupKey: "position",
                key: "po_2",
              },
              {
                name: `-2 Percentage`,
                groupKey: "position",
                key: "percent_po2",
              },

              {
                name: `-1`,
                groupKey: "position",
                key: "po_3",
              },
              {
                name: `-1 Percentage`,
                groupKey: "position",
                key: "percent_po3",
              },
              {
                name: `0`,
                groupKey: "position",
                key: "po_4",
              },
              {
                name: `0 Percentage`,
                groupKey: "position",
                key: "percent_po4",
              },
              {
                name: `1`,
                groupKey: "position",
                key: "po_5",
              },
              {
                name: `1 Percentage`,
                groupKey: "position",
                key: "percent_po5",
              },
              {
                name: `2`,
                groupKey: "position",
                key: "po_6",
              },
              {
                name: `2 Percentage`,
                groupKey: "position",
                key: "percent_po6",
              },
              {
                name: `3`,
                groupKey: "position",
                key: "po_7",
              },
              {
                name: `3 Percentage`,
                groupKey: "position",
                key: "percent_po7",
              },
              {
                name: `Unintended Position`,
                groupKey: "position",
                key: "po_8",
              },
              {
                name: `Unintended Position Percentage`,
                groupKey: "position",
                key: "percent_po8",
              },
              {
                name: `รวม`,
                groupKey: "position",
                key: "sum_position",
              },
              {
                name: `Total Percentage`,
                groupKey: "position",
                key: "total_percentpo",
              },

              //
              {
                name: `Fluoroscopy`,
                groupKey: "group_1",
                key: "con_good_flu",
              },
              {
                name: `Fluoroscopy Percentage`,
                groupKey: "group_1",
                key: "percent_good_flu",
              },
              {
                name: `IECG`,
                groupKey: "group_1",
                key: "con_good_iecg",
              },
              {
                name: `IECG Percentage`,
                groupKey: "group_1",
                key: "percent_good_iecg",
              },
              {
                name: `Landmark`,
                groupKey: "group_1",
                key: "con_good_land",
              },
              {
                name: `Landmark Percentage`,
                groupKey: "group_1",
                key: "percent_good_land",
              },
              {
                name: `จำนวนกลุ่มดีทั้งหมด`,
                groupKey: "group_1",
                key: "total_good",
              },

              //
              {
                name: `Fluoroscopy`,
                groupKey: "group_2",
                key: "con_short_flu",
              },
              {
                name: `Fluoroscopy Percentage`,
                groupKey: "group_2",
                key: "percent_short_flu",
              },
              {
                name: `IECG`,
                groupKey: "group_2",
                key: "con_short_iecg",
              },
              {
                name: `IECG Percentage`,
                groupKey: "group_2",
                key: "percent_short_iecg",
              },
              {
                name: `Landmark`,
                groupKey: "group_2",
                key: "con_short_land",
              },
              {
                name: `Landmark Percentage`,
                groupKey: "group_2",
                key: "percent_short_land",
              },
              {
                name: `จำนวนกลุ่มตื้นทั้งหมด`,
                groupKey: "group_2",
                key: "total_short",
              },
              //
              {
                name: `Fluoroscopy`,
                groupKey: "group_3",
                key: "con_long_flu",
              },
              {
                name: `Fluoroscopy Percentage`,
                groupKey: "group_3",
                key: "percent_long_flu",
              },
              {
                name: `IECG`,
                groupKey: "group_3",
                key: "con_long_iecg",
              },
              {
                name: `IECG Percentage`,
                groupKey: "group_3",
                key: "percent_long_iecg",
              },
              {
                name: `Landmark`,
                groupKey: "group_3",
                key: "con_long_land",
              },
              {
                name: `Landmark Percentage`,
                groupKey: "group_3",
                key: "percent_long_land",
              },
              {
                name: `จำนวนกลุ่มลึกทั้งหมด`,
                groupKey: "group_3",
                key: "total_long",
              },
              //
              {
                name: `Fluoroscopy`,
                groupKey: "group_4",
                key: "con_un_flu",
              },
              {
                name: `Fluoroscopy Percentage`,
                groupKey: "group_4",
                key: "percent_un_flu",
              },
              {
                name: `IECG`,
                groupKey: "group_4",
                key: "con_un_iecg",
              },
              {
                name: `IECG Percentage`,
                groupKey: "group_4",
                key: "percent_un_iecg",
              },
              {
                name: `Landmark`,
                groupKey: "group_4",
                key: "con_un_land",
              },
              {
                name: `Landmark Percentage`,
                groupKey: "group_4",
                key: "percent_un_land",
              },
              {
                name: `จำนวนกลุ่ม Unintended Position ทั้งหมด`,
                groupKey: "group_4",
                key: "total_un",
              },
              //

              {
                name: `in_ivc`,
                groupKey: "lower",
                key: "in_ivc",
              },
              {
                name: `no_ivc`,
                groupKey: "lower",
                key: "no_ivc",
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
