export const formatLimb = (ue, le, neck, subclavian, femoral, other) => {
  const thisLimb = [];
  if (ue) {
    thisLimb?.push("UE");
  }
  if (le) {
    thisLimb?.push("LE");
  }
  if (neck) {
    thisLimb?.push("Neck");
  }
  if (subclavian) {
    thisLimb?.push("Subclavian");
  }
  if (femoral) {
    thisLimb?.push("Femoral");
  }
  if (other) {
    thisLimb?.push(other);
  }

  let useLimb = "";
  thisLimb?.map((row) => {
    useLimb = useLimb + row;
  });
  //   console.log("useLimb", useLimb);
  return useLimb;
};

export const diffTime = (room_in, out_of_room) => {
  const useTotalTime =
    room_in &&
    out_of_room &&
    Math.abs(
      new Date().setHours(room_in?.split(":")[0], room_in?.split(":")[1]) -
        new Date().setHours(
          out_of_room?.split(":")[0],
          out_of_room?.split(":")[1]
        )
    ) / 60000;
  return Number(useTotalTime);
};
