import { calculateAverage } from "../utils/useFunction";

const XLSX = require("xlsx");

export const ExcelAvgAttempt = (submitExcelData, year, type) => {
  const wb = XLSX.utils.book_new();

  const filteredAverageTimes = submitExcelData?.map(
    (doctor) => doctor.averageCheckTime
  );

  const useSumAll = filteredAverageTimes.filter((value) => value !== 0);

  const maxAverageCheckTime = Math.max(...useSumAll);
  const minAverageCheckTime = Math.min(...useSumAll);
  const averageOfAll = calculateAverage(useSumAll);

  // Header for the worksheet, adjust as necessary to match your data structure
  const ws_data = [
    [
      `เวลาเฉลี่ยของทุก attempt แยกตามแพทย์ผู้ใส่ แยกตามปี ${year?.label} ตามประเภทสาย ${type?.label}`,
    ],
    ["ชื่อ", "เวลาเฉลี่ยของทุก attempt"],
  ];

  // Fill ws_data with rows from submitExcelData
  submitExcelData.forEach((item) => {
    ws_data.push([item.doctor, item.averageCheckTime]);
  });

  ws_data.push(["", ""]); // Add an empty row for visual separation
  ws_data.push(["max", maxAverageCheckTime]);
  ws_data.push(["min", minAverageCheckTime]);
  ws_data.push(["ค่าเฉลี่ย", averageOfAll]);

  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  XLSX.utils.book_append_sheet(wb, ws, "Attempts Report");

  // Naming the file with year and type if provided, with fallbacks
  const fileName = `AttemptReport_${year?.label || "Year"}_${
    type?.label || "Type"
  }.xlsx`;
  XLSX.writeFile(wb, fileName);
};

// import ExcelExport from "export-xlsx";

// export const ExcelAvgAttempt = async (inputdata, year, type, setLoading) => {
//   // const inputdata = { data: [{ test: 5 }] };
//   const SETTINGS_FOR_EXPORT = {
//     // Table settings
//     fileName: "ALiST_REPORT",
//     workSheets: [
//       {
//         sheetName: "Report",
//         startingRowNumber: 1,
//         gapBetweenTwoTables: 6,

//         tableSettings: {
//           data: {
//             headerGroups: [
//               //กลุ่มที่ 1
//               {
//                 name: ` เวลาเฉลี่ยของทุก attempt แยกตามแพทย์ผู้ใส่ ของปี ${year?.label} ตามประเภทสาย ${type?.label}`,
//                 key: "header_1",
//                 style: { fill: { fgColor: { rgb: "FF0000" } } },
//               },
//             ],
//             headerDefinition: [
//               {
//                 name: "จิตสุภา นิธิอุทัย",
//                 key: "doctor_01",
//                 groupKey: "header_1",
//               },

//               {
//                 name: "ชยาภา ลัคนาจันทโชติ",
//                 groupKey: "header_1",
//                 key: "doctor_02",
//               },

//               {
//                 name: "ฐานิกา เกียรติชัย",
//                 key: "doctor_03",
//                 groupKey: "header_1",
//               },

//               {
//                 name: "ณัฐชัย เหมทานนท์",
//                 key: "doctor_04",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ธัชวรรณ จิระติวานนท์",
//                 key: "doctor_05",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "นนทิดา โรจนพิทยากร",
//                 key: "doctor_06",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ประเสริฐ สวัสดิ์วิภาชัย",
//                 key: "doctor_07",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ปราณี รัชตามุขยนันต์",
//                 key: "doctor_08",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ภาวิณี ปางทิพย์อำไพ",
//                 key: "doctor_09",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ปาริฉัตต์ โตวิกกัย",
//                 key: "doctor_10",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ภัทราพรรณ วงศ์ศรีภูมิเทศ",
//                 key: "doctor_11",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ภานพ หลิมรัตน์",
//                 key: "doctor_12",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ภาวิต สมนึก",
//                 key: "doctor_13",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "รัตนาภรณ์ ตันกูล",
//                 key: "doctor_14",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "วรรธนันทน์ มะกรสาร",
//                 key: "doctor_15",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "สมิตา ไพโรจน์ศักดิ์",
//                 key: "doctor_16",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "สวิตา คณาวิฑูรย์",
//                 key: "doctor_17",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "สหัสา หมั่นดี",
//                 key: "doctor_18",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "สิริมา ภูวนกุลชัย",
//                 key: "doctor_19",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "อภิชาต ศุภธรรมวิทย์",
//                 key: "doctor_20",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "อรรณพ พิริยะแพทย์สม",
//                 key: "doctor_21",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "อรวรรณ พงศ์รวีวรรณ",
//                 key: "doctor_22",
//                 groupKey: "header_1",
//               },
//               //   {
//               //     name: "คณิตา กสิการ",
//               //     key: "doctor_23",
//               //     groupKey: "header_1",
//               //   },
//               {
//                 name: "วาริยา วงศ์ชัยอุดมโชค",
//                 key: "doctor_24",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ธนิทธิ ทิพย์อาภรณ์",
//                 key: "doctor_25",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ชุติมา ลีวัชรารุ่งเจริญ",
//                 key: "doctor_26",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "ค่าเฉลี่ย",
//                 key: "avg",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "min",
//                 key: "min",
//                 groupKey: "header_1",
//               },
//               {
//                 name: "max",
//                 key: "max",
//                 groupKey: "header_1",
//               },
//             ],
//           },
//         },
//       },
//     ],
//   };

//   try {
//     setLoading(true);
//     const excelExport = new ExcelExport();
//     await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
//   } catch (error) {
//     console.log("error", error);
//   } finally {
//     setLoading(false);
//   }
// };
