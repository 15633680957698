import { useCallback, useContext, useEffect, useState } from "react";
import Dropdown from "../../components/field/Dropdown";
import TextField from "../../components/field/TextField";
import {
  OptionWard72years,
  OptionWard84years,
  OptionWardAccident,
  OptionWardBuilding,
  OptionWardChalerm,
  OptionWardDek,
  OptionWardHeart,
  OptionWardNavamin,
  OptionWardOther,
  OptionWardOtherRoom,
  OptionWardOutPatient,
  OptionWardPICU,
  OptionWardPraSri,
  OptionWardPrakan,
  OptionWardSalak,
  OptionWardSayamin,
  OptionWardShortStay,
  options_department,
  options_department_in_dropdown,
  options_role,
  options_role_in_dropdown,
} from "../../contexts/DataOptions";
import { Auth } from "aws-amplify";
import swalAlert from "../../utils/swalAlert";
import api from "../../api";
import { createDocAndNurse, fetchDoctors } from "../../api/doctorandnurse";
import { ListDoctorAndNurseContext } from "../../contexts/ListDoctorAndNurseContext";
import { filterRole } from "../../components/functions/Functions";

export const AdminMain = () => {
  const { listName } = useContext(ListDoctorAndNurseContext);

  const [form, setForm] = useState({});
  const [formAdd, setFormAdd] = useState({});
  const [formView, setFormView] = useState({});
  const [loading, setLoading] = useState(false);
  const [optionOther, setOptionOther] = useState("");
  const [otherWardName, setOtherWardName] = useState("");
  const [optionWard, setOptionWard] = useState("");

  const [ward, setWard] = useState("");

  console.log("formAdmin", form);

  console.log("otherWardName", otherWardName);

  console.log("optionWard", optionWard);

  console.log("ward", ward);

  console.log("formAdd", formAdd);

  const getOptionWard = () => {
    if (optionWard?.value === "72 ปี") {
      return OptionWard72years;
    } else if (optionWard?.value === "84 ปี") {
      return OptionWard84years;
    } else if (optionWard?.value === "เฉลิมพระเกียรติ") {
      return OptionWardChalerm;
    } else if (optionWard?.value === "พระศรี") {
      return OptionWardPraSri;
    } else if (optionWard?.value === "เด็ก") {
      return OptionWardDek;
    } else if (optionWard?.value === "นวมินทรบพิตร") {
      return OptionWardNavamin;
    } else if (optionWard?.value === "ตึกผู้ป่วยนอก") {
      return OptionWardOutPatient;
    } else if (optionWard?.value === "สยามินทร์") {
      return OptionWardSayamin;
    } else if (optionWard?.value === "ศูนย์โรคหัวใจ") {
      return OptionWardHeart;
    } else if (optionWard?.value === "สลาก") {
      return OptionWardSalak;
    } else if (optionWard?.value === "อุบัติเหตุ") {
      return OptionWardAccident;
    } else if (optionWard?.value === "Short stay") {
      return OptionWardShortStay;
    }
  };

  const getOptionOther = () => {
    if (optionOther?.value === "ประกันสังคม") {
      return OptionWardPrakan;
    } else if (optionOther?.value === "PICU") {
      return OptionWardPICU;
    } else if (optionOther?.value === "ห้องตรวจอื่นๆ") {
      return OptionWardOtherRoom;
    }
  };

  const setField = useCallback(
    (field) => (e) => {
      const value = e && e.target ? e.target.value : e;
      setForm((currentForm) => ({
        ...currentForm,
        [field]: value,
      }));
    },
    [setForm]
  );
  const setFieldAdd = useCallback(
    (field) => (e) => {
      const value = e && e.target ? e.target.value : e;
      setFormAdd((currentForm) => ({
        ...currentForm,
        [field]: value,
      }));
    },
    [setFormAdd]
  );

  const onClickCreate = async () => {
    // if (form?.password === form?.re_password) {
    if (
      form?.username &&
      form?.password &&
      form?.fullname &&
      form?.role &&
      form?.email &&
      form?.phone_number &&
      form?.department
    ) {
      try {
        setLoading(true);

        const format_phone_number = `+66${form.phone_number.slice(
          1,
          form.phone_number.length
        )}`;

        const checkValidateProfile = await api.get(
          `/profiles?username=${form.username}`
        );

        if (checkValidateProfile.data.profiles.length > 0) {
          await swalAlert.Warning({ text: "บัญชีนี้มีอยู่แล้ว" });
        } else {
          await Auth.signUp({
            username: `${form.username}`,
            password: `${form.password}`,
            attributes: {
              email: `${form.email}`,
              phone_number: `${format_phone_number}`,
              ["custom:fullname"]: `${form.fullname}`,
              ["custom:role"]: `${form.role.value}`,
            },
          });
          await api.post(
            // "https://1kvz0xfhbi.execute-api.ap-southeast-1.amazonaws.com/dev/profiles",
            "/profiles",
            {
              fullname: form.fullname,
              role: form.role.value,
              username: form.username,
              email: form.email,
              phone_number: format_phone_number,
              user_department: form.department.value,
              building: {
                label: optionWard?.label,
                value: optionWard?.value,
              },

              current_location: {
                label: ward?.label,
                value: ward?.value,
                phone: ward?.phone,
              },
            }
          );
          await swalAlert.Success({ text: "สมัครสมาชิกสำเร็จ" });

          setForm({ username: "" });
          setForm({ password: "" });
          setForm({ fullname: "" });
          setForm({ role: "" });
          setForm({ email: "" });
          setForm({ phone_number: "" });
          setForm({ department: "" });

          setLoading(false);
        }
      } catch (error) {
        console.error("error", error);
        await swalAlert.Fail({
          text:
            error?.response?.data?.message ||
            error?.message ||
            "Something went wrong",
        });
      } finally {
        setLoading(false);
      }
    } else {
      await swalAlert.Fail({
        text: "กรุณากรอกข้อมูลที่สำคัญให้ครบถ้วน",
      });
    }
  };

  const onClickAdd = async () => {
    try {
      setLoading(true);
      await createDocAndNurse({
        ...formAdd,
        user_department: formAdd?.user_department?.value,
      });
      await swalAlert.Success({ text: "เพิ่มรายชื่อสำเร็จ" });
      setFormAdd({});
    } catch (error) {
      console.error("EROOR :", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative">
      <div className="w-full rounded-md  grid grid-cols-2  absolute  left-1/2 transform -translate-x-1/2">
        <div className=" border mx-auto border-bgbluealist rounded-md p-10 m-10 w-96 grid grid-cols-1   ">
          <TextField
            title="Username"
            setField={setField}
            name="username"
            value={form?.username}
          />
          <TextField
            title="Password"
            setField={setField}
            name="password"
            type="password"
            value={form?.password}
            placeholder="รหัสผ่านอย่างน้อย 8 ตัว"
          />
          <TextField
            title="Fullname"
            setField={setField}
            name="fullname"
            value={form?.fullname}
          />
          <Dropdown
            title={"Role"}
            name="role"
            options={options_role}
            value={form?.role}
            setField={setField}
          />
          <Dropdown
            title={"Department"}
            name="department"
            options={options_department}
            value={form?.department}
            setField={setField}
          />
          <TextField
            title="Phone Number (ใช้สำหรับลืมรหัสผ่าน)"
            setField={setField}
            name="phone_number"
            value={form?.phone_number}
          />
          <TextField
            title="E-Mail"
            setField={setField}
            name="email"
            value={form?.email}
          />
          {form?.department?.value === "NURSEWARD" && (
            <Dropdown
              // title="ตึกผู้ป่วย"

              name="building"
              value={form?.building}
              setField={setField}
              options={OptionWardBuilding}
              other_name={`other_ward_name`}
              other_value={otherWardName}
              setValue={setOptionWard}
              setOtherValue={setOtherWardName}
            />
          )}
          <div>
            {optionWard?.value === "ตึกอื่น ๆ" ? (
              <div>
                <Dropdown
                  placeholder={"หอผู้ป่วย"}
                  name="current_location"
                  setField={setField}
                  value={form?.current_location}
                  setValue={setOptionOther}
                  options={OptionWardOther}
                />
                {optionOther?.value && (
                  <Dropdown
                    // setField={setField}
                    name="current_location"
                    value={form?.current_location}
                    setField={setField}
                    setValue={setWard}
                    placeholder={"หอผู้ป่วย"}
                    options={getOptionOther()}
                  />
                )}
              </div>
            ) : (
              optionWard?.value &&
              optionWard?.value !== "other_location" && (
                <Dropdown
                  // setField={setField}

                  name="current_location"
                  value={form?.current_location}
                  setField={setField}
                  setValue={setWard}
                  placeholder={"หอผู้ป่วย"}
                  options={getOptionWard()}
                />
              )
            )}
          </div>
          <button
            onClick={() => onClickCreate()}
            className="text-white bg-bgbluealist py-2 rounded-md my-10 w-full"
          >
            Create User
          </button>
        </div>
        <div className="border mx-auto border-bgbluealist rounded-md p-10 m-10 w-96 grid grid-cols-1  ">
          รายชื่อที่เพิ่มแล้ว
          <Dropdown
            title={"หน้าที่"}
            name="getrole"
            options={options_role}
            value={formAdd?.getrole}
            setField={setFieldAdd}
          />
          <Dropdown
            title={"รายชื่อ"}
            name="list"
            options={filterRole(listName, formAdd?.getrole?.value)}
            // value={form?.role}
            // setField={setField}
          />
          เพิ่มชื่อใหม่
          <TextField
            title="ชื่อ-นามสกุล"
            setField={setFieldAdd}
            name="fullname"
            value={formAdd?.fullname}
          />
          <TextField
            title="เลขประจำตัว"
            setField={setFieldAdd}
            name="number"
            value={formAdd?.numer}
          />
          <Dropdown
            title="หน้าที่"
            name="role"
            options={options_role_in_dropdown}
            value={formAdd?.role?.value}
            setField={setFieldAdd}
          />
          <Dropdown
            title="แผนก"
            name="user_department"
            options={options_department_in_dropdown}
            value={formAdd?.user_department}
            setField={setFieldAdd}
          />
          <button
            onClick={() => onClickAdd()}
            className="text-white bg-bgbluealist py-2 rounded-md my-10 w-full"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};
