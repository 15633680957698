import ExcelExport from "export-xlsx";

export const ExcelSucAttempt = async (inputdata, year, type, setLoading) => {
  // const inputdata = { data: [{ test: 5 }] };
  const SETTINGS_FOR_EXPORT = {
    // Table settings
    fileName: "ALiST_REPORT",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: ` รายงานจำนวนครั้งในการทำหัตถการ ของปีของปี ${year?.label} ประเภทสาย  ${type?.label} `,
                key: "header_01",
                style: { fill: { fgColor: { rgb: "FF0000" } } },
              },
              {
                name: "Children",
                key: "children",
                groupKey: "header_01",
              },
              {
                name: "Adult",
                key: "adult",
                groupKey: "header_01",
              },
            ],
            headerDefinition: [
              //
              {
                name: "1 attempt",
                key: "kid_01",
                groupKey: "children",
              },
              {
                name: "2 attempts",
                key: "kid_02",
                groupKey: "children",
              },
              {
                name: "3 attempts",
                key: "kid_03",
                groupKey: "children",
              },
              {
                name: "4 attempts",
                key: "kid_04",
                groupKey: "children",
              },
              //

              {
                name: "1 attempt",
                key: "ad_01",
                groupKey: "adult",
              },
              {
                name: "2 attempts",
                key: "ad_02",
                groupKey: "adult",
              },
              {
                name: "3 attempts",
                key: "ad_03",
                groupKey: "adult",
              },
              {
                name: "4 attempts",
                key: "ad_04",
                groupKey: "adult",
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
