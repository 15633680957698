import ExcelExport from "export-xlsx";

export const ExceltCatLabelName = async (inputdata, year, setLoading) => {
  // const inputdata = { data: [{ test: 5 }] };
  const SETTINGS_FOR_EXPORT = {
    fileName: "ALiST_REPORT",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: ` นับชนิดของสายน้ำเกลือแยกตามชื่อสายที่ใส่ ของปี${year?.label}    `,
                key: "header_01",
              },
              {
                name: `PICC`,
                groupKey: "header_01",
                key: "type_01",
              },
              {
                name: `CENTRAL LINE`,
                groupKey: "header_01",
                key: "type_02",
              },
              {
                name: `รวม`,
                groupKey: "header_01",
                key: "type_03",
              },
            ],

            headerDefinition: [
              //
              {
                name: `Arrow 5 Fr 2-lumen PICC`,
                groupKey: "type_01",
                key: "picc1",
              },
              {
                name: `Arrow 4 Fr 1-lumen PICC`,
                groupKey: "type_01",
                key: "picc2",
              },
              {
                name: `Bard 5 Fr 2-Lumen power PICC`,
                groupKey: "type_01",
                key: "picc3",
              },
              {
                name: `Vygon 3 Fr 1-lumen PICC`,
                groupKey: "type_01",
                key: "picc4",
              },
              {
                name: `Other`,
                groupKey: "type_01",
                key: "picc5",
              },
              //

              {
                name: `Arrow 7 Fr 2-lumen CVL, antimicrobials coated`,
                groupKey: "type_02",
                key: "central1",
              },
              {
                name: `Arrow 7 Fr 3-lumen CVL, antimicrobials coated`,
                groupKey: "type_02",
                key: "central2",
              },
              {
                name: `12 Fr DLC`,
                groupKey: "type_02",
                key: "central3",
              },
              {
                name: `Other`,
                groupKey: "type_02",
                key: "central4",
              },
              {
                name: `PICC`,
                groupKey: "type_03",
                key: "sum_picc",
              },
              {
                name: `CENTAL`,
                groupKey: "type_03",
                key: "sum_central",
              },
            ],
          },
        },
      },
    ],
  };
  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
