import FunctionCheckType from "./Report.FunctionCheckType";

const ReportCountSideCat = ({ list, setValue, type, year, month }) => {
  let cat1_LT = 0;
  let cat2_LT = 0;
  let cat3_LT = 0;
  let cat4_LT = 0;
  let cat5_LT = 0;
  let cat6_LT = 0;
  let cat7_LT = 0;
  let cat8_LT = 0;

  let cat1_RT = 0;
  let cat2_RT = 0;
  let cat3_RT = 0;
  let cat4_RT = 0;
  let cat5_RT = 0;
  let cat6_RT = 0;
  let cat7_RT = 0;
  let cat8_RT = 0;

  let sum_LT = 0;
  let sum_RT = 0;

  let piccCheck = [];
  let centralCheck = [];

  let missingAll = 0;
  let piccMissing = 0;
  let centralMissing = 0;

  const formattedMonth = month.length === 1 ? `0${month}` : month;

  if (list?.length > 0) {
    if (formattedMonth !== "ALL") {
      list = list.filter((row) => {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);
        const useMonth = thisYear.substring(5, 7);
        return useYear === year && useMonth === formattedMonth;
      });
    }

    for (let row of list) {
      if (row?.final_date_success !== "") {
        const thisYear = row?.final_date_success ? row?.final_date_success : "";
        const useYear = thisYear.substring(0, 4);

        if (year === "ALL" || year === useYear) {
          if (type === "NONE") {
            if (
              row?.final_catheter_in?.value === "Basilicvein" &&
              row?.final_lt
            ) {
              cat1_LT++;
            }
            if (
              row?.final_catheter_in?.value === "Brachialvein" &&
              row?.final_lt
            ) {
              cat2_LT++;
            }
            if (
              row?.final_catheter_in?.value === "Cephalicvein" &&
              row?.final_lt
            ) {
              cat3_LT++;
            }
            if (
              row?.final_catheter_in?.value === "Internaljugularvein" &&
              row?.final_lt
            ) {
              cat4_LT++;
            }
            if (
              row?.final_catheter_in?.value === "Subclavianvein" &&
              row?.final_lt
            ) {
              cat5_LT++;
            }
            if (
              row?.final_catheter_in?.value === "Femoralvein" &&
              row?.final_lt
            ) {
              cat6_LT++;
            }
            if (
              row?.final_catheter_in?.value === "Distalfemoralvein" &&
              row?.final_lt
            ) {
              cat7_LT++;
            }
            if (
              row?.final_catheter_in?.value === "other_final_cather_in" &&
              row?.final_lt
            ) {
              cat8_LT++;
            }
            //
            if (
              row?.final_catheter_in?.value === "Basilicvein" &&
              row?.final_rt
            ) {
              cat1_RT++;
            }
            if (
              row?.final_catheter_in?.value === "Brachialvein" &&
              row?.final_rt
            ) {
              cat2_RT++;
            }
            if (
              row?.final_catheter_in?.value === "Cephalicvein" &&
              row?.final_rt
            ) {
              cat3_RT++;
            }
            if (
              row?.final_catheter_in?.value === "Internaljugularvein" &&
              row?.final_rt
            ) {
              cat4_RT++;
            }
            if (
              row?.final_catheter_in?.value === "Subclavianvein" &&
              row?.final_rt
            ) {
              cat5_RT++;
            }
            if (
              row?.final_catheter_in?.value === "Femoralvein" &&
              row?.final_rt
            ) {
              cat6_RT++;
            }
            if (
              row?.final_catheter_in?.value === "Distalfemoralvein" &&
              row?.final_rt
            ) {
              cat7_RT++;
            }
            if (
              row?.final_catheter_in?.value === "other_final_cather_in" &&
              row?.final_rt
            ) {
              cat8_RT++;
            }
            if (!row?.final_catheter_in && row?.final_date_success) {
              missingAll++;
            }
          }
          if (
            type === "PICC" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "PICC"
          ) {
            if (
              row?.final_catheter_in?.value === "Basilicvein" &&
              row?.final_lt
            ) {
              cat1_LT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Brachialvein" &&
              row?.final_lt
            ) {
              cat2_LT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Cephalicvein" &&
              row?.final_lt
            ) {
              cat3_LT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Internaljugularvein" &&
              row?.final_lt
            ) {
              cat4_LT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Subclavianvein" &&
              row?.final_lt
            ) {
              cat5_LT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Femoralvein" &&
              row?.final_lt
            ) {
              cat6_LT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Distalfemoralvein" &&
              row?.final_lt
            ) {
              cat7_LT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "other_final_cather_in" &&
              row?.final_lt
            ) {
              cat8_LT++;
              // piccCheck.push(row?._id);
            }
            //
            if (
              row?.final_catheter_in?.value === "Basilicvein" &&
              row?.final_rt
            ) {
              cat1_RT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Brachialvein" &&
              row?.final_rt
            ) {
              cat2_RT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Cephalicvein" &&
              row?.final_rt
            ) {
              cat3_RT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Internaljugularvein" &&
              row?.final_rt
            ) {
              cat4_RT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Subclavianvein" &&
              row?.final_rt
            ) {
              cat5_RT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Femoralvein" &&
              row?.final_rt
            ) {
              cat6_RT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Distalfemoralvein" &&
              row?.final_rt
            ) {
              cat7_RT++;
              // piccCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "other_final_cather_in" &&
              row?.final_rt
            ) {
              cat8_RT++;
              // piccCheck.push(row?._id);
            }
            if (!row?.final_catheter_in && row?.final_date_success) {
              missingAll++;
            }
          }
          if (
            type === "CENTRAL_LINE" &&
            FunctionCheckType(
              row?.catheter?.value,
              row?.other_catheter_name,
              row?.check_catheter
            ) === "CENTRAL_LINE"
          ) {
            if (
              row?.final_catheter_in?.value === "Basilicvein" &&
              row?.final_lt
            ) {
              cat1_LT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Brachialvein" &&
              row?.final_lt
            ) {
              cat2_LT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Cephalicvein" &&
              row?.final_lt
            ) {
              cat3_LT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Internaljugularvein" &&
              row?.final_lt
            ) {
              cat4_LT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Subclavianvein" &&
              row?.final_lt
            ) {
              cat5_LT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Femoralvein" &&
              row?.final_lt
            ) {
              cat6_LT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Distalfemoralvein" &&
              row?.final_lt
            ) {
              cat7_LT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "other_final_cather_in" &&
              row?.final_lt
            ) {
              cat8_LT++;
              // centralCheck.push(row?._id);
            }
            //
            if (
              row?.final_catheter_in?.value === "Basilicvein" &&
              row?.final_rt
            ) {
              cat1_RT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Brachialvein" &&
              row?.final_rt
            ) {
              cat2_RT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Cephalicvein" &&
              row?.final_rt
            ) {
              cat3_RT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Internaljugularvein" &&
              row?.final_rt
            ) {
              cat4_RT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Subclavianvein" &&
              row?.final_rt
            ) {
              cat5_RT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Femoralvein" &&
              row?.final_rt
            ) {
              cat6_RT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "Distalfemoralvein" &&
              row?.final_rt
            ) {
              cat7_RT++;
              // centralCheck.push(row?._id);
            }
            if (
              row?.final_catheter_in?.value === "other_final_cather_in" &&
              row?.final_rt
            ) {
              cat8_RT++;
              // centralCheck.push(row?._id);
            }
            if (!row?.final_catheter_in && row?.final_date_success) {
              missingAll++;
            }
          }
        }
        // if (year === useYear) {
        //   if (type === "NONE") {
        //     if (
        //       row?.final_catheter_in?.value === "Basilicvein" &&
        //       row?.final_lt
        //     ) {
        //       cat1_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Brachialvein" &&
        //       row?.final_lt
        //     ) {
        //       cat2_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Cephalicvein" &&
        //       row?.final_lt
        //     ) {
        //       cat3_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Internaljugularvein" &&
        //       row?.final_lt
        //     ) {
        //       cat4_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Subclavianvein" &&
        //       row?.final_lt
        //     ) {
        //       cat5_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Femoralvein" &&
        //       row?.final_lt
        //     ) {
        //       cat6_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Distalfemoralvein" &&
        //       row?.final_lt
        //     ) {
        //       cat7_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "other_final_cather_in" &&
        //       row?.final_lt
        //     ) {
        //       cat8_LT++;
        //     }
        //     //
        //     if (
        //       row?.final_catheter_in?.value === "Basilicvein" &&
        //       row?.final_rt
        //     ) {
        //       cat1_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Brachialvein" &&
        //       row?.final_rt
        //     ) {
        //       cat2_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Cephalicvein" &&
        //       row?.final_rt
        //     ) {
        //       cat3_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Internaljugularvein" &&
        //       row?.final_rt
        //     ) {
        //       cat4_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Subclavianvein" &&
        //       row?.final_rt
        //     ) {
        //       cat5_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Femoralvein" &&
        //       row?.final_rt
        //     ) {
        //       cat6_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Distalfemoralvein" &&
        //       row?.final_rt
        //     ) {
        //       cat7_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "other_final_cather_in" &&
        //       row?.final_rt
        //     ) {
        //       cat8_RT++;
        //     }
        //   }
        //   if (
        //     type === "PICC" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     if (
        //       row?.final_catheter_in?.value === "Basilicvein" &&
        //       row?.final_lt
        //     ) {
        //       cat1_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Brachialvein" &&
        //       row?.final_lt
        //     ) {
        //       cat2_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Cephalicvein" &&
        //       row?.final_lt
        //     ) {
        //       cat3_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Internaljugularvein" &&
        //       row?.final_lt
        //     ) {
        //       cat4_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Subclavianvein" &&
        //       row?.final_lt
        //     ) {
        //       cat5_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Femoralvein" &&
        //       row?.final_lt
        //     ) {
        //       cat6_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Distalfemoralvein" &&
        //       row?.final_lt
        //     ) {
        //       cat7_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "other_final_cather_in" &&
        //       row?.final_lt
        //     ) {
        //       cat8_LT++;
        //     }
        //     //
        //     if (
        //       row?.final_catheter_in?.value === "Basilicvein" &&
        //       row?.final_rt
        //     ) {
        //       cat1_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Brachialvein" &&
        //       row?.final_rt
        //     ) {
        //       cat2_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Cephalicvein" &&
        //       row?.final_rt
        //     ) {
        //       cat3_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Internaljugularvein" &&
        //       row?.final_rt
        //     ) {
        //       cat4_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Subclavianvein" &&
        //       row?.final_rt
        //     ) {
        //       cat5_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Femoralvein" &&
        //       row?.final_rt
        //     ) {
        //       cat6_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Distalfemoralvein" &&
        //       row?.final_rt
        //     ) {
        //       cat7_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "other_final_cather_in" &&
        //       row?.final_rt
        //     ) {
        //       cat8_RT++;
        //     }
        //   }
        //   if (
        //     type === "CENTRAL_LINE" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     if (
        //       row?.final_catheter_in?.value === "Basilicvein" &&
        //       row?.final_lt
        //     ) {
        //       cat1_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Brachialvein" &&
        //       row?.final_lt
        //     ) {
        //       cat2_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Cephalicvein" &&
        //       row?.final_lt
        //     ) {
        //       cat3_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Internaljugularvein" &&
        //       row?.final_lt
        //     ) {
        //       cat4_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Subclavianvein" &&
        //       row?.final_lt
        //     ) {
        //       cat5_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Femoralvein" &&
        //       row?.final_lt
        //     ) {
        //       cat6_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Distalfemoralvein" &&
        //       row?.final_lt
        //     ) {
        //       cat7_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "other_final_cather_in" &&
        //       row?.final_lt
        //     ) {
        //       cat8_LT++;
        //     }
        //     //
        //     if (
        //       row?.final_catheter_in?.value === "Basilicvein" &&
        //       row?.final_rt
        //     ) {
        //       cat1_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Brachialvein" &&
        //       row?.final_rt
        //     ) {
        //       cat2_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Cephalicvein" &&
        //       row?.final_rt
        //     ) {
        //       cat3_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Internaljugularvein" &&
        //       row?.final_rt
        //     ) {
        //       cat4_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Subclavianvein" &&
        //       row?.final_rt
        //     ) {
        //       cat5_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Femoralvein" &&
        //       row?.final_rt
        //     ) {
        //       cat6_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Distalfemoralvein" &&
        //       row?.final_rt
        //     ) {
        //       cat7_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "other_final_cather_in" &&
        //       row?.final_rt
        //     ) {
        //       cat8_RT++;
        //     }
        //   }
        // }
        // if (year === "2023" && useYear === "2023") {
        //   if (type === "NONE") {
        //     if (
        //       row?.final_catheter_in?.value === "Basilicvein" &&
        //       row?.final_lt
        //     ) {
        //       cat1_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Brachialvein" &&
        //       row?.final_lt
        //     ) {
        //       cat2_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Cephalicvein" &&
        //       row?.final_lt
        //     ) {
        //       cat3_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Internaljugularvein" &&
        //       row?.final_lt
        //     ) {
        //       cat4_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Subclavianvein" &&
        //       row?.final_lt
        //     ) {
        //       cat5_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Femoralvein" &&
        //       row?.final_lt
        //     ) {
        //       cat6_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Distalfemoralvein" &&
        //       row?.final_lt
        //     ) {
        //       cat7_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "other_final_cather_in" &&
        //       row?.final_lt
        //     ) {
        //       cat8_LT++;
        //     }
        //     //
        //     if (
        //       row?.final_catheter_in?.value === "Basilicvein" &&
        //       row?.final_rt
        //     ) {
        //       cat1_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Brachialvein" &&
        //       row?.final_rt
        //     ) {
        //       cat2_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Cephalicvein" &&
        //       row?.final_rt
        //     ) {
        //       cat3_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Internaljugularvein" &&
        //       row?.final_rt
        //     ) {
        //       cat4_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Subclavianvein" &&
        //       row?.final_rt
        //     ) {
        //       cat5_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Femoralvein" &&
        //       row?.final_rt
        //     ) {
        //       cat6_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Distalfemoralvein" &&
        //       row?.final_rt
        //     ) {
        //       cat7_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "other_final_cather_in" &&
        //       row?.final_rt
        //     ) {
        //       cat8_RT++;
        //     }
        //   }
        //   if (
        //     type === "PICC" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "PICC"
        //   ) {
        //     if (
        //       row?.final_catheter_in?.value === "Basilicvein" &&
        //       row?.final_lt
        //     ) {
        //       cat1_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Brachialvein" &&
        //       row?.final_lt
        //     ) {
        //       cat2_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Cephalicvein" &&
        //       row?.final_lt
        //     ) {
        //       cat3_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Internaljugularvein" &&
        //       row?.final_lt
        //     ) {
        //       cat4_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Subclavianvein" &&
        //       row?.final_lt
        //     ) {
        //       cat5_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Femoralvein" &&
        //       row?.final_lt
        //     ) {
        //       cat6_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Distalfemoralvein" &&
        //       row?.final_lt
        //     ) {
        //       cat7_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "other_final_cather_in" &&
        //       row?.final_lt
        //     ) {
        //       cat8_LT++;
        //     }
        //     //
        //     if (
        //       row?.final_catheter_in?.value === "Basilicvein" &&
        //       row?.final_rt
        //     ) {
        //       cat1_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Brachialvein" &&
        //       row?.final_rt
        //     ) {
        //       cat2_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Cephalicvein" &&
        //       row?.final_rt
        //     ) {
        //       cat3_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Internaljugularvein" &&
        //       row?.final_rt
        //     ) {
        //       cat4_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Subclavianvein" &&
        //       row?.final_rt
        //     ) {
        //       cat5_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Femoralvein" &&
        //       row?.final_rt
        //     ) {
        //       cat6_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Distalfemoralvein" &&
        //       row?.final_rt
        //     ) {
        //       cat7_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "other_final_cather_in" &&
        //       row?.final_rt
        //     ) {
        //       cat8_RT++;
        //     }
        //   }
        //   if (
        //     type === "CENTRAL_LINE" &&
        //     FunctionCheckType(
        //       row?.catheter?.value,
        //       row?.other_catheter_name,
        //       row?.check_catheter
        //     ) === "CENTRAL_LINE"
        //   ) {
        //     if (
        //       row?.final_catheter_in?.value === "Basilicvein" &&
        //       row?.final_lt
        //     ) {
        //       cat1_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Brachialvein" &&
        //       row?.final_lt
        //     ) {
        //       cat2_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Cephalicvein" &&
        //       row?.final_lt
        //     ) {
        //       cat3_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Internaljugularvein" &&
        //       row?.final_lt
        //     ) {
        //       cat4_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Subclavianvein" &&
        //       row?.final_lt
        //     ) {
        //       cat5_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Femoralvein" &&
        //       row?.final_lt
        //     ) {
        //       cat6_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Distalfemoralvein" &&
        //       row?.final_lt
        //     ) {
        //       cat7_LT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "other_final_cather_in" &&
        //       row?.final_lt
        //     ) {
        //       cat8_LT++;
        //     }
        //     //
        //     if (
        //       row?.final_catheter_in?.value === "Basilicvein" &&
        //       row?.final_rt
        //     ) {
        //       cat1_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Brachialvein" &&
        //       row?.final_rt
        //     ) {
        //       cat2_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Cephalicvein" &&
        //       row?.final_rt
        //     ) {
        //       cat3_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Internaljugularvein" &&
        //       row?.final_rt
        //     ) {
        //       cat4_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Subclavianvein" &&
        //       row?.final_rt
        //     ) {
        //       cat5_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Femoralvein" &&
        //       row?.final_rt
        //     ) {
        //       cat6_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "Distalfemoralvein" &&
        //       row?.final_rt
        //     ) {
        //       cat7_RT++;
        //     }
        //     if (
        //       row?.final_catheter_in?.value === "other_final_cather_in" &&
        //       row?.final_rt
        //     ) {
        //       cat8_RT++;
        //     }
        //   }
        // }
      }
    }
  }
  setValue({
    cat1_LT: cat1_LT,
    cat2_LT: cat2_LT,
    cat3_LT: cat3_LT,
    cat4_LT: cat4_LT,
    cat5_LT: cat5_LT,
    cat6_LT: cat6_LT,
    cat7_LT: cat7_LT,
    cat8_LT: cat8_LT,

    cat1_RT: cat1_RT,
    cat2_RT: cat2_RT,
    cat3_RT: cat3_RT,
    cat4_RT: cat4_RT,
    cat5_RT: cat5_RT,
    cat6_RT: cat6_RT,
    cat7_RT: cat7_RT,
    cat8_RT: cat8_RT,
    sum_LT1RT1: cat1_LT + cat1_RT,
    sum_LT2RT2: cat2_LT + cat2_RT,
    sum_LT3RT3: cat3_LT + cat3_RT,
    sum_LT4RT4: cat4_LT + cat4_RT,
    sum_LT5RT5: cat5_LT + cat5_RT,
    sum_LT6RT6: cat6_LT + cat6_RT,
    sum_LT7RT7: cat7_LT + cat7_RT,
    sum_LT8RT8: cat8_LT + cat8_RT,

    sum_LT:
      cat1_LT +
      cat2_LT +
      cat3_LT +
      cat4_LT +
      cat5_LT +
      cat6_LT +
      cat7_LT +
      cat8_LT,
    sum_RT:
      cat1_RT +
      cat2_RT +
      cat3_RT +
      cat4_RT +
      cat5_RT +
      cat6_RT +
      cat7_RT +
      cat8_RT,
    sum_All:
      cat1_LT +
      cat2_LT +
      cat3_LT +
      cat4_LT +
      cat5_LT +
      cat6_LT +
      cat7_LT +
      cat8_LT +
      cat1_RT +
      cat2_RT +
      cat3_RT +
      cat4_RT +
      cat5_RT +
      cat6_RT +
      cat7_RT +
      cat8_RT,
    // piccCheck: piccCheck,
    // centralCheck: centralCheck,
    missingAll: missingAll,
    piccMissing: piccMissing,
    centralMissing: centralMissing,
  });
};

export default ReportCountSideCat;
