const Button = ({ onClickSave, onClickCancel, disabled = false }) => {
  return (
    <div className="  mx-auto w-1/2 lg:w-1/4 grid grid-cols-2  gap-4 lg:gap-10 py-10">
      <button
        disabled={disabled}
        onClick={onClickSave ? onClickSave : () => {}}
        className={`${
          disabled
            ? " bg-gray-500 "
            : " border-transparent border hover:border-bgbluealist    "
        }  bg-white  text-textgrayalist hover:text-bgbluealist  text-sm lg:text-base rounded-md  px-4 py-1 font-medium hover:shadow-lg shadow-md cursor-pointer`}
      >
        Save
      </button>
      <div
        onClick={onClickCancel ? onClickCancel : () => {}}
        className="bg-white text-textgrayalist hover:text-bgbluealist border-transparent border hover:border-bgbluealist text-sm lg:text-base rounded-md  px-4 py-1 font-medium hover:shadow-lg shadow-md cursor-pointer text-center"
      >
        Cancel
      </div>
    </div>
  );
};

export default Button;
