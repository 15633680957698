import ExcelExport from "export-xlsx";

export const ExcelFollowVisit = async (inputdata, year, setLoading) => {
  // console.log("inputdata", inputdata);
  const SETTINGS_FOR_EXPORT_PAPER = {
    fileName: "ALiST_REPORT_PAPER",
    workSheets: [
      {
        sheetName: "Report",
        startingRowNumber: 1,
        gapBetweenTwoTables: 6,

        tableSettings: {
          data: {
            headerGroups: [
              //กลุ่มที่ 1
              {
                name: `รายงานจำนวนครั้งที่ผู้ป่วยได้รับการเยี่ยม แยกเป็นการโทรฯติดตามหรือการเยี่ยมจริง ของปี "${year?.label}"`,
                key: "header_01",
              },
              {
                name: `รายงานระยะห่างของการเยี่ยมแต่ละครั้ง กรณีอายุสายน้อยกว่า 3 เดือน "${year?.label}"`,
                key: "header_02",
              },
              {
                name: `มกราคม`,
                groupKey: "header_01",
                key: "month_01",
              },
              {
                name: `กุมภาพันธ์`,
                groupKey: "header_01",
                key: "month_02",
              },
              {
                name: `มีนาคม`,
                groupKey: "header_01",
                key: "month_03",
              },
              {
                name: `เมษายน`,
                groupKey: "header_01",
                key: "month_04",
              },
              {
                name: `พฤษภาคม`,
                groupKey: "header_01",
                key: "month_05",
              },
              {
                name: `มิถุนายน`,
                groupKey: "header_01",
                key: "month_06",
              },
              {
                name: `กรกฎาคม`,
                groupKey: "header_01",
                key: "month_07",
              },
              {
                name: `สิงหาคม`,
                groupKey: "header_01",
                key: "month_08",
              },
              {
                name: `กันยายน`,
                groupKey: "header_01",
                key: "month_09",
              },
              {
                name: `ตุลาคม`,
                groupKey: "header_01",
                key: "month_10",
              },
              {
                name: `พฤศจิกายน`,
                groupKey: "header_01",
                key: "month_11",
              },
              {
                name: `ธันวาคม`,
                groupKey: "header_01",
                key: "month_12",
              },
              {
                name: `รวม`,
                groupKey: "header_01",
                key: "total",
              },
            ],

            headerDefinition: [
              {
                name: "visit",
                key: "jan_visit",
                groupKey: "month_01",
                width: 7,
              },
              {
                name: "phone",
                key: "jan_phone",
                groupKey: "month_01",
                width: 7,
              },
              {
                name: "visit",
                key: "feb_visit",
                groupKey: "month_02",
                width: 7,
              },
              {
                name: "phone",
                key: "feb_phone",
                groupKey: "month_02",
                width: 7,
              },
              {
                name: "visit",
                key: "mar_visit",
                groupKey: "month_03",
                width: 7,
              },
              {
                name: "phone",
                key: "mar_phone",
                groupKey: "month_03",
                width: 7,
              },
              {
                name: "visit",
                key: "apr_visit",
                groupKey: "month_04",
                width: 7,
              },
              {
                name: "phone",
                key: "apr_phone",
                groupKey: "month_04",
                width: 7,
              },
              {
                name: "visit",
                key: "may_visit",
                groupKey: "month_05",
                width: 7,
              },
              {
                name: "phone",
                key: "may_phone",
                groupKey: "month_05",
                width: 7,
              },
              {
                name: "visit",
                key: "jun_visit",
                groupKey: "month_06",
                width: 7,
              },
              {
                name: "phone",
                key: "jun_phone",
                groupKey: "month_06",
                width: 7,
              },
              {
                name: "visit",
                key: "jul_visit",
                groupKey: "month_07",
                width: 7,
              },
              {
                name: "phone",
                key: "jul_phone",
                groupKey: "month_07",
                width: 7,
              },
              {
                name: "visit",
                key: "aug_visit",
                groupKey: "month_08",
                width: 7,
              },
              {
                name: "phone",
                key: "aug_phone",
                groupKey: "month_08",
                width: 7,
              },
              {
                name: "visit",
                key: "sep_visit",
                groupKey: "month_09",
                width: 7,
              },
              {
                name: "phone",
                key: "sep_phone",
                groupKey: "month_09",
                width: 7,
              },
              {
                name: "visit",
                key: "oct_visit",
                groupKey: "month_10",
                width: 7,
              },
              {
                name: "phone",
                key: "oct_phone",
                groupKey: "month_10",
                width: 7,
              },
              {
                name: "visit",
                key: "nov_visit",
                groupKey: "month_11",
                width: 7,
              },
              {
                name: "phone",
                key: "nov_phone",
                groupKey: "month_11",
                width: 7,
              },
              {
                name: "visit",
                key: "dec_visit",
                groupKey: "month_12",
                width: 7,
              },
              {
                name: "phone",
                key: "dec_phone",
                groupKey: "month_12",
                width: 7,
              },
              {
                name: "visit",
                key: "sum_visit",
                groupKey: "total",
                width: 7,
              },
              {
                name: "phone",
                key: "sum_phone",
                groupKey: "total",
                width: 7,
              },
              //
              {
                name: "0-7 วัน",
                key: "level01",
                groupKey: "header_02",
                width: 7,
              },
              {
                name: "0-7 วัน",
                key: "level01_percent",
                groupKey: "header_02",
                width: 7,
              },
              {
                name: "8-14 วัน",
                key: "level02",
                groupKey: "header_02",
                width: 7,
              },
              {
                name: "8-14 วัน",
                key: "level02_percent",
                groupKey: "header_02",
                width: 7,
              },
              {
                name: "15-21 วัน",
                key: "level03",
                groupKey: "header_02",
                width: 7,
              },
              {
                name: "15-21 วัน",
                key: "level03_percent",
                groupKey: "header_02",
                width: 7,
              },
              {
                name: ">21 วัน",
                key: "level04",
                groupKey: "header_02",
                width: 7,
              },
              {
                name: ">21 วัน",
                key: "level04_percent",
                groupKey: "header_02",
                width: 7,
              },
            ],
          },
        },
      },
    ],
  };

  try {
    setLoading(true);
    const excelExport = new ExcelExport();
    await excelExport.downloadExcel(SETTINGS_FOR_EXPORT_PAPER, [inputdata]);
  } catch (error) {
    console.log("error", error);
  } finally {
    setLoading(false);
  }
};
